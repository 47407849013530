import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getImage } from "../../../../unified/image_service";
import { setErrorMessage, setSuccessMessage } from "../../../../unified/reducer";
import { generateAccessToken } from "../../../../profile/reducer";
import axios from "axios";
import { api_url, fb_whatsapp_app_id, fb_whatsapp_config_id, fb_whatsapp_solution_id } from "../../../../utils/server";
import { RootState } from "../../../../store";

declare global {
    interface Window {
      FB: any; // Assuming 'FB' has any type
    }
}

const IntegrationWhatsapp: React.FC = () => {
    // const { personalInfo } = useSelector((state: any) => state?.profileManageSettingState);
    const dispatch = useDispatch();
    // const history = useNavigate();
    // const { whats_app_connected_users, } = useSelector((state: any) => state?.workspaceManagementState);
    const {
        whats_app_connected_users,
        // userPermissions,
    }: any = useSelector((state: RootState) => state?.login);
    const whatsapp_connection_flag = whats_app_connected_users?.length;

    const [isWhatsappConnected, setIsWhatsappConnected] = useState<any>(false);
    useEffect(() => { 
        console.log({ whatsapp_connection_flag });
        if (whatsapp_connection_flag) {
            setIsWhatsappConnected(true);
        } else {
            setIsWhatsappConnected(false);
        }
    }, [whatsapp_connection_flag]);

    // const path = window.location.pathname;
    // const redirect = auth_url;
    // const fb_whatsapp_access_token = sessionStorage.getItem("fb_whatsapp_access_token");
    // const [loading, setLoading]: any = React.useState(false);
    // useEffect(() => {
    //   if (whats_app_connected_users?.length) {
    //     console.log({ whats_app_connected_users });
    //     // const workspaceId = sessionStorage.getItem('wuid') || localStorage.getItem('wuid') || "";
    //     // dispatch(getWhatsAppConnectedUsers(workspaceId));
    //   }
    // }, [whats_app_connected_users?.length]);
    
    // Whatsapp connection credential ====
    useEffect(() => {
        const initializeFacebookSDK = () => {
        if (window.FB) {
            window.FB.init({
            // // appId: '{YOUR-FACEBOOK-APP-ID}',
            appId: fb_whatsapp_app_id || '254750246571176',
            cookie: true,
            xfbml: true,
            version: 'v18.0'
            });
        }
        };
    
        const loadFacebookSDK = () => {
            const script = document.createElement('script');
            script.src = "https://connect.facebook.net/en_US/sdk.js";
            script.async = true;
            script.defer = true;
            script.crossOrigin = "anonymous";
            script.onload = initializeFacebookSDK;
            document.body.appendChild(script);
        };
    
        if (!window.FB) {
            loadFacebookSDK();
        } else {
            // console.log({window});
            initializeFacebookSDK();
        }
    }, []);
    
    const handleWhatsAppConnect = () => {
        sessionStorage.setItem("isLoading", "true");
        // sessionStorage.setItem("app_connect", "whatsapp-connection");

        // const redirectUri = `${window.location.origin}/workspace/integrations`;
        
        // console.log({ FB: window.FB });
        // Launch Facebook login
        if (window.FB) {
            // Track WhatsApp onboarding start event
            // ReactPixel.trackCustom('WhatsAppOnboardingStart', {
            //   appId: 'your-facebook-app-id',
            //   feature: 'whatsapp_embedded_signup'
            // });
            // // fbq && fbq('trackCustom', 'WhatsAppOnboardingStart', {appId: 'your-facebook-app-id', feature: 'whatsapp_embedded_signup'});
            // // If FB is available, directly call FB.login
            window.FB.login(function (response: any, error: any) {
                // console.log({response, error});
                
                if (response?.authResponse) {
                    const code = response?.authResponse?.code;
                    console.log('User authResponse code:', code);
                    // The returned code must be transmitted to your backend
                    // which will perform a server-to-server call from there to WhatsApp servers for an access token

                } else {
                    console.log('User cancelled login or did not fully authorize.', {response});
                    dispatch(setErrorMessage("Connection Cancelled"));
                }
            }, {
                // Your login options here
                config_id: fb_whatsapp_config_id || '939680554350603', // configuration ID goes here
                response_type: 'code',    // must be set to 'code' for System User access token
                override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
                extras: {
                    sessionInfoVersion: 2, // must be set to 2 for WhatsApp Business API
                    setup: {
                        // Prefilled data can go here
                        // solutionID: '{YOUR-SOLUTION-ID}'
                        solutionID: fb_whatsapp_solution_id || '1106455250783359', // solution ID goes here
                    }
                }
            });
        } else {
            console.log('Facebook SDK not initialized yet.');
        // You might want to handle this case accordingly
        }
        
    }
    
    const sessionInfoListener = (event: any) => {
        console.log('message event received:', event);
        if (event?.origin !== "https://www.facebook.com") {
            return;
        }
        try {
            const data = JSON.parse(event?.data || "{}");
            console.log('event data: ==> ', data);
            if (data?.type === 'WA_EMBEDDED_SIGNUP') {
                // if user finishes the Embedded Signup flow
                if (data?.event === 'FINISH') {
                const { phone_number_id, waba_id } = data?.data;
                // const phone_number_id = "208327472375018";
                // const waba_id = "210212982185873";
                // authorized to token request
                axios.post(
                    `${api_url}/api/v1.2/WhatsApp/RegisterPhoneNumber`,
                    {
                        workspaceId: sessionStorage.getItem('wuid') || localStorage.getItem('wuid') || "",
                        phoneNumberId: phone_number_id,
                        wabaId: waba_id
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                            'Content-Type': 'application/json'
                        }
                    }
                ).then((response: any) => {
                    console.log({ response });
                    dispatch(setSuccessMessage("Connected Successfully"));
                    
                }).catch((error: any) => {
                    // console.log('error: ', error);
                    const { response } = error;
                    const { status }: any = response;
                    if((status === 403 || status === 401)) {
                        dispatch(generateAccessToken());
                    }
                });
                }
                // if user cancels the Embedded Signup flow
                else {
                    const { current_step } = data?.data;
                }
            }
        } catch (error: any) {
            // Don’t parse info that’s not a JSON
            console.log({error});
            dispatch(setErrorMessage("Connection Failed"));
            
            console.log('Non JSON Response', event.data);
        }
    };
    
    useEffect(() => {
        window.addEventListener('message', sessionInfoListener);
        return () => {
        window.removeEventListener('message', sessionInfoListener);
        };
    }, []);


    // const handleCancel = () => {
    //     // setReadyToSelectGoogleLocation(false);
    // }
    return (
        <React.Fragment>
            <div 
                title={isWhatsappConnected ? "Connected" : ""}
                className={`integrations-container ${isWhatsappConnected ? "connected-integration-container" : ""}`}
                onClick={() => {
                    !isWhatsappConnected && handleWhatsAppConnect();
                }}
            >
                <div className="header-container">
                    <img src={getImage("Whatsapp")} alt="integration-icon" />
                    <div className="title">Whatsapp</div>
                </div>
                <div className="description">
                    Connect WhatsApp account to manage Message and Promotions.
                </div>
            </div>
        </React.Fragment>
    );
};

export default IntegrationWhatsapp;