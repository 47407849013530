import React, { useEffect } from 'react';
import SeoTab from './VideoFormTabs/SeoTab';
import Versions from './VideoFormTabs/Versions';
import Properties from './VideoFormTabs/Properties';
import BiopageTab from './VideoFormTabs/BiopageTab';
import { useDispatch, useSelector } from 'react-redux';
import { setShowingSaveButton } from '../mediaReducer';

const content_tabs = [
    {
        label: 'Biopage',
        component: <BiopageTab />,
        isShowSaveButton: true,
    },
    {
        label: 'SEO',
        component: <SeoTab/>,
        isShowSaveButton: true,
    },
    {
        label: 'Versions',
        component: <Versions/>,
        isShowSaveButton: false,
    },
    {
        label: 'Properties',
        component: <Properties/>,
        isShowSaveButton: true,
    }
];

export default function ContentSeoVideoTabs() {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = React.useState<any>(content_tabs[0]);
    const {updatable_content} = useSelector((state: any) => state.mediaState);
    useEffect(() => {
        dispatch(setShowingSaveButton(activeTab?.isShowSaveButton));
    }, [activeTab?.isShowSaveButton]);

    return (
        <React.Fragment>
            <div className='seo-content-container'>
                <div className='seo-content-tabs'>
                    {
                        content_tabs.map((tab, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`seo-content-tab ${activeTab.label === tab.label ? 'seo-content-tab-active' : ''}`}
                                    onClick={() => {
                                        setActiveTab(tab);
                                    }}
                                >
                                    {tab.label}
                                </div>
                            );
                        })
                    }
                </div>
                <div className='seo-content-body'>
                    {activeTab.component}
                </div>
            </div>
        </React.Fragment>
    );
}
