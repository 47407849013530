import Constants from "../../../constants";

const initialState = {
    all_bot_details: [],
    agents: [],
    editable_agent_id: null,
}

export const setEditableAgent = (data: any = null) => {
    return {
        type: Constants.SET_EDITABLE_AGENT,
        payload: data,
    };
}


export const resetAgentTab = (data?: any) => {
    return {
        type: "RESET_AGENT_TAB",
        payload: data
    };
}

// get bot settings
export const getBotDetailsSettings = (data?: any) => {
    console.log({ from_data: data });
    const clapupUserId = localStorage.getItem('cuid') || sessionStorage.getItem('cuid') || null;
    const workspaceId = localStorage.getItem('wuid') || sessionStorage.getItem('wuid') || null;
    const roleId = localStorage.getItem('roleId') || sessionStorage.getItem('roleId') || null;
    return {
        type: Constants.GET_BOT_DETAILS_SETTINGS,
        payload: {
            clapupUserId,
            roleId,
            workspaceId,
        }
    };
}


export const setBotDetailsSettings = (data: any) => {

    return {
        type: Constants.SET_BOT_DETAILS_SETTINGS,
        payload: data
    };
}

// update bot settings
export const updateBotDetailsSettings = (data: any = null) => {
    return {
        type: Constants.UPDATE_BOT_DETAILS_SETTINGS,
        payload: data
    };
}


export const getBotDetailsAnnouncements = () => {
    return {
        type: Constants.GET_BIO_PAGE_ANNOUNCEMENTS,
        payload: {
            clapupUserId: sessionStorage.getItem("cuid"),
            roleId: sessionStorage.getItem("roleId"),
            workspaceId: sessionStorage.getItem("wuid"),
        }
    };
}


const agentReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case Constants.SET_EDITABLE_AGENT:
            return {
                ...state,
                editable_agent_id: action.payload,
            };

        case "RESET_AGENT_TAB":
            return {
                ...state,
                editable_agent_id: null,
            };

        case "RESET_EMBED_RECORDS":
            return {
                ...state,
                ...action.payload,
            };

        case Constants.SET_BOT_DETAILS_SETTINGS:
            return {
                ...state,
                all_bot_details: action.payload,
            };

        case Constants.LOGOUT:
            return {
                ...state,
                agents: [],
                editable_agent_id: null,
                GeneralSettings: null,
                SocialLinks: null,
                CustomLinks: null,
                Announcements: null,
                isLoading: false,
                error: null,
                embed_video_records: [],
                embed_video_next_date: [],
                embed_image_records: [],
                embed_image_next_date: [],
            };

        default:
            return state;
    }
}

export default agentReducer;