import React, { useEffect, useState } from 'react';
// import prev_icon from '../../../../assets/icons/previous-icon.svg';
// import next_icon from '../../../../assets/icons/next-icon.svg';
import emptyBox from '../../../../assets/img/Empty.svg';
import clapup_bot_icon from '../../../../assets/icons/bot-icons/clapup-bot-icon.svg';
import user_avatar from '../../../../assets/icons/user-avatar.svg';
import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
// import start from '../../../../assets/icons/star-icon.svg';
import back_btn from '../../../../assets/icons/back.svg';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
// import ConversationDetails from '../AllDetails/ConversationsDetails';
import { useSelector } from 'react-redux';
// import { setSuccessMessage } from '../../../../dashboard/reducer';
import CustomCountdown from '../../../CustomCountdown/CustomCountdown';
import BlinkingOneDot from '../../../Loaders/BlinkingOneDot/BlinkingOneDot';
import MessagesDetails from '../AllDetails/MessagesDetails';
import { convertISODate } from '../../../../utils/convertingFunction';
import { getGenerateApiImageByName } from '../../../../unified/image_service';

export default function BotMemberConversation(props: any) {
  // const dispatch = useDispatch();
  const [messages, setMessages] = useState<any[]>([]);
  const [isCountDownOn, setIsCountDownOn] = useState(false);
  // const query_string = window.location.search;
  const {
    conversation, type, statDetails,
    // handleReplaceConversation,
    getImage, handlePrevPath,
    // isReply, setIsReply, textRef, handleReply, conversationId, 
    setReplyText, isGoForDetails, handleDetailChange } = props;
  const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
  const { workspaces } = useSelector((state: any) => state?.headerState);
  const workSpaceName: any = workspaces?.find(
    (w: any) => w?.workSpaceId === localStorage.getItem("wuid")
  )?.workSpaceName ||  "";
  const { reviewerPhoteUrl } = conversation || {};
  // console.log("bot_stat_Conversations Data ===> ", {conversation, workspaceSettings, workspaces, workSpaceName, id: localStorage.getItem("wuid")});
  const [inputValue, setInputValue] = useState('');

  // const handleKeyPress = (e: any) => {
  //   if (e.key === 'Enter' && !e.shiftKey) {
  //     e.preventDefault(); // Prevent the default behavior (form submission)
  //     handleSubmit();
  //   }
  // };

  // const handleInputChange = (e: any) => {
  //   setInputValue(e.target.value);
  // };

  // const handleSubmit = () => {
  //   // Perform your submit logic here
  //   console.log('Submitted:', inputValue);
  //   // Reset the input value or update your state as needed
  //   setReplyText(inputValue);
  //   setInputValue('');
  // };

  useEffect(() => {
    if (conversation?.ticketNo && conversation?.messages?.length) {
      // const bot_messages: any[] = conversation?.messages?.reduce((uniqueArray: any, currentItem: any) => {
      //   if (!uniqueArray.some((item: any) => item.createdOn === currentItem.createdOn)) {
      //       uniqueArray.push(currentItem);
      //   }
      //   return uniqueArray;
      // }, []);
      
      // console.log({ messages_all: conversation?.messages });
      // setMessages(bot_messages?.reverse());
      setMessages(conversation?.messages?.reverse());
    } else {
      setMessages([]);
    }
  }, [conversation?.ticketNo, conversation?.userName]);

  return (
    <React.Fragment>
      <div className="conversation-section-container-lg h-100">
        <div className="conversation-header">
          <div className="person-container">
            <div className="person-1">
              {
                reviewerPhoteUrl
                ? <img
                    className="user-avatar"
                    src={reviewerPhoteUrl || user_avatar}
                    alt="avatar"
                  />
                : 
                  <img
                    className="user-avatar"
                    src={getGenerateApiImageByName(conversation?.userName)}
                    alt="avatar"
                  />
              }
              <span className="description">
                {conversation?.userName?.substr(0, 10) || "User Name"}{" "}
                {/* {workspaceSettings?.workspaceName  || workSpaceName ||  "WS Name"} */}
              </span>
            </div>
            <div className="person-2">
              {
                workspaceSettings?.logo
                ? <img
                    src={workspaceSettings?.logo || user_avatar_2}
                    alt="user-2"
                  />
                : <img
                    className="user-avatar"
                    src={getGenerateApiImageByName(workspaceSettings?.workspaceName  || workSpaceName)}
                    alt="avatar"
                  />
              }
              <span className="user-2">
                {workspaceSettings?.workspaceName  || workSpaceName ||  "WS Name"}
              </span>
            </div>
          </div>
          <div className="conversation-features-media"
            style={{justifyContent: 'flex-end', width: '40%' }}
          >
            <div className="features">
              <div className='count-down-container'>
                <BlinkingOneDot
                  isStarted={isCountDownOn}
                />
                <CustomCountdown
                  customClass={"countdown"}
                  onChange={(val: string) => {
                    // console.log(val);
                  }}
                  isStarted={isCountDownOn}
                />
              </div>
              {/* <img
                onClick={() =>
                  handleReplaceConversation("prev", conversation?.SlNo)
                }
                src={prev_icon}
                alt="prev"
                title='Previous'
              />
              <img
                onClick={() =>
                  handleReplaceConversation("next", conversation?.SlNo)
                }
                src={next_icon}
                alt="next"
                title='Next'
              />
              <img
                onClick={() => dispatch(setSuccessMessage("Refreshed"))}
                src={refresh_icon}
                alt="refresh"
                title='Refresh'
              /> */}
            </div>
            <div className="media">
              <img onClick={() => setIsCountDownOn(!isCountDownOn)} src={getImage(type)} alt="type-icon" />
            </div>
          </div>
        </div>
        {
          messages?.length
          ?
          <div className="conversation-section-video-feedback">
            <Scrollbars
              style={{
                paddingRight: 12,
                display: "flex",
                flexDirection: "column-reverse",
                overflow: 'auto'
              }}
              autoHide
              autoHideTimeout={500}
              autoHideDuration={500}
              autoHeight
              // autoHeightMin={250}
              // autoHeightMax={250}
              // autoHeightMin={480}
              autoHeightMax={"62vh"}
              thumbMinSize={10}
              universal={true}
              id="scrollableDiv"
            >
              <div className="ai-bot-conversation-body" >
                {/* This will implement it feeds.tsx file */}
                {
                  messages.map((item: any, idx: any) => (
                    item?.userName === "assistant"
                    ?
                    <div className="conversation-person-2" key={idx}>
                      <div className="details">
                          <img
                            className="user-avatar"
                            src={clapup_bot_icon}
                            alt="bot-icon"
                          />
                          <div className="description">
                            {/* <p style={{ whiteSpace: 'pre-line', margin: 0 }}>{"Hi Kuber Mannur, \nThank you for your message. Creating account in our platform is very easy. You can do this from user management. Pls don't forget to ping message. Creating account in our platform is very easy. You can do this from user management. Pls don't forget to ping us at support@tezla.com for any assistance."}</p> */}
                            <p style={{ whiteSpace: 'pre-line', margin: 0 }}>{item?.message}</p>
                          </div>
                        </div>
                        <p className="text-end mt-2 date">{"Replied - " + moment(convertISODate(item?.createdOn)).format("DD MMM YYYY, hh:mm:ss A") + " by AI" }</p>
                    </div>
                    :
                    <div className="conversation-person-1" key={idx}>
                      <div className="details">
                        {
                          reviewerPhoteUrl
                          ? <img
                              className="user-avatar"
                              src={reviewerPhoteUrl || user_avatar}
                              alt="avatar"
                            />
                          : <img
                              className="user-avatar"
                              src={getGenerateApiImageByName(item?.userName)}
                              alt="avatar"
                            />
                        }
                        <div className="description">
                          <div
                            className={`d-flex align-items-center user-ratings-container`}
                          >
                            <div className="user_name me-2 ">
                              {item?.userName || "User Name"}
                            </div>
                          </div>
                          {/* <div className="client-container mb-2">
                            {
                              moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + (recieverCompany ? (" - " + (recieverCompany || "Company Name")) : "")
                            }
                          </div> */}
                          {/* <p style={{ whiteSpace: 'pre-line' }}>{reviewComment}</p> */}
                          {/* <p style={{ whiteSpace: 'pre-line' }}>As the brand, so is the service. They are extremely well verse with in quality and keeping up with their users. Prices are definitely high, but quality comes with a price.</p> */}
                          <p style={{ whiteSpace: 'pre-line' }}>{item?.message}</p>
                        </div>
                      </div>
                      <p className="text-start mt-2 date">
                          {"Messaged - " + moment(convertISODate(item?.createdOn)).format("DD MMM YYYY, hh:mm:ss A")}
                      </p>
                    </div>
                  ))
                }
                {/* <div className='py-5'></div> */}
              </div>
            </Scrollbars>
          </div>
          :
          <div className="empty-feeds-section">
            <div
              className="empty-wall-section position-absolute"
              style={{ top: "45%" }}
            >
              <div>
                <img src={emptyBox} alt="" />
                <h4>Empty Feeds</h4>
              </div>
            </div>

          </div>
        }
        <div className="conversation-footer">
          {/* <p className="text-start my-2 px-3 sender">
            Sending as {workspaceSettings?.workspaceSettings?.workspaceName  || workSpaceName ||  "WS Name"}
          </p> */}
          <div className="message-box mb-3">
            <div className="message">
              <div className="text-container click-reply-des"
                style={{display: 'flex', flexDirection: 'row', alignItems: "center", padding: '15px'}}
              >
                <span>This conversation is managed by Bot</span>
                {/* <textarea
                  ref={textRef}
                  disabled={true}
                  className="bg-transparent"
                  placeholder="This conversation is managed by Bot"
                  value={inputValue || ""}           
                  onChange={handleInputChange}
                  onKeyDown={handleKeyPress}
                  // onChange={(e: any) => {
                  //   const { value } = e?.target;
                  //   setReplyText(value);
                  // }}
                  style={{ resize: "none", width: '100%', }}
                  autoFocus={isReply} 
                /> */}

                {/* <div className="reply-btn">
                  <button
                    style={{
                      background: `${isReply ? "#E4E7EC" : "#E4E7EC"}`,
                    }}
                    onClick={() => {
                      if (isReply) {
                        handleReply();
                      } else {
                        setIsReply(true);
                      }
                    }}
                    type="button"
                  >
                    {isReply
                      ? "Save"
                      : isReply
                      ? "Send"
                      : isReply
                      ? "Edit"
                      : "Reply"}
                  </button>
                  <img
                    onClick={() => setIsReply(false)}
                    src={isReply ? lock_icon : unlock_icon}
                    alt="unlock"
                  />
                </div> */}
                {/* <p className={`click-reply-des ${isReply ? "d-none" : ""}`}>
                  Click reply and start adding your reply.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="conversation-container-mobile">
        <div className="conversation-header">
          <div className="header-top">
            {/* Back Button */}
            <img
              onClick={handlePrevPath}
              className="back"
              src={back_btn}
              alt="back"
            />
            <img
              className="provider"
              src={getImage(type)}
              alt="type-messages"
            />
          </div>
          <div className="header-body">
            <div className="bot-body-left">
              <div className="first-ws">
                {
                  reviewerPhoteUrl
                  ? <img
                      className="user-avatar"
                      src={reviewerPhoteUrl || user_avatar}
                      alt="avatar"
                    />
                  : <span className='border text-light image-logo rounded-circle d-flex align-items-center justify-content-center p-1' style={{fontSize: "0.75rem", background: '#14B0BF'}}>
                      {
                        (conversation?.userName || "User Name")?.split(" ")?.slice(0, 2)?.length === 2
                        ? (conversation?.userName || "User Name")?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                        : (conversation?.userName || "User Name")?.substr(0, 2).toUpperCase()
                      }
                    </span>
                }
                <div className="user-info">
                  <p className="user-name">
                    {(conversation?.userName)?.substr(0, 6) || "User Name"}
                  </p>
                </div>
              </div>
                <div className="second-ws">
                  {
                    workspaceSettings?.logo
                    ? <img
                        className="image-logo"
                        src={workspaceSettings?.logo || user_avatar_2}
                        alt="user-2"
                      />
                    : <span className='border rounded-circle text-light image-logo d-flex align-items-center justify-content-center' style={{ fontSize: "0.75rem", background: '#14B0BF'}}>
                        {
                          workSpaceName?.split(" ")?.slice(0, 2)?.length === 2
                          ? workSpaceName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                          : workSpaceName?.substr(0, 2).toUpperCase()
                        }
                      </span>
                  }
                  <p className="ws-name">
                    {workSpaceName?.substr(0, 7) ||
                      "WS Name"}
                  </p>
                </div>
              </div>
              <div className="conversation-features-media"
                style={{justifyContent: 'center', width: '40%' }}
              >
                <div className="features">
                  <div className='count-down-container'>
                    <BlinkingOneDot
                      isStarted={isCountDownOn}
                    />
                    <CustomCountdown
                      customClass={"countdown"}
                      onChange={(val: string) => {
                        // console.log(val);
                      }}
                      isStarted={isCountDownOn}
                    />
                  </div>
                </div>
                <div className="media">
                  <img onClick={() => setIsCountDownOn(!isCountDownOn)} src={getImage(type)} alt="type-icon" />
                </div>
              </div>
            {/* <div className="body-right">
            
            <img
              onClick={() => dispatch(setSuccessMessage("Refreshed"))}
              src={getImage(type)}
              alt="type-icon"
              title='Bot'
            />
          </div> */}
          </div>
        
        {/* Messages and Details */}
        <div className="header-bottom">
          <span
            onClick={() => isGoForDetails && handleDetailChange()}
            style={{ color: !isGoForDetails ? "#1570EF" : "" }}
          >
            Messages
          </span>
          <span
            onClick={() => !isGoForDetails && handleDetailChange()}
            style={{ color: isGoForDetails ? "#1570EF" : "" }}
          >
            Details
          </span>
        </div>
        </div>
        {isGoForDetails ? (
          <div className="conversation-details-mobile-container">
            <MessagesDetails type={type} statDetails={statDetails} />
          </div>
        ) : (
          <>
            <Scrollbars
              style={{
                paddingRight: 10,
                display: "flex",
                flexDirection: "column-reverse",
              }}
              // style={{ paddingRight: 10 }}
              autoHide
              autoHideTimeout={500}
              autoHideDuration={500}
              autoHeight
              // autoHeightMin={250}
              // autoHeightMax={250}
              // autoHeightMin={480}
              autoHeightMax={"auto"}
              thumbMinSize={10}
              universal={true}
              id="scrollableDiv"
            >
              <div className="conversation-body" style={{height: "60vh", paddingRight: '1%', overflow: 'auto'}}>
                {/* This will implement it feeds.tsx file */}
                {
                  messages?.map((item: any, idx: any) => (
                    item?.userName === "assistant"
                    ?
                    <div className="conversation-person-2" key={idx}>
                      <div className="details">
                          <img
                            className="user-avatar"
                            src={clapup_bot_icon}
                            alt="bot-icon"
                          />
                          <div className="description">
                            {/* <p style={{ whiteSpace: 'pre-line', margin: 0 }}>{"Hi Kuber Mannur, \nThank you for your message. Creating account in our platform is very easy. You can do this from user management. Pls don't forget to ping message. Creating account in our platform is very easy. You can do this from user management. Pls don't forget to ping us at support@tezla.com for any assistance."}</p> */}
                            <p style={{ whiteSpace: 'pre-line', margin: 0 }}>{item?.message}</p>
                          </div>
                        </div>
                        {/* <p className="text-end mt-2 date">{"Replied - " + item?.createdOn + " by " + "AI"}</p> */}
                        <p className="text-end mt-2 date">{"Replied - " + moment(convertISODate(item?.createdOn)).format("DD MMM YYYY, hh:mm:ss A") + " by AI" }</p>
                    </div>
                    :
                    <div className="conversation-person-1" key={idx}>
                      <div className="details">
                        {
                          reviewerPhoteUrl
                          ? <img
                              className="user-avatar"
                              src={reviewerPhoteUrl || user_avatar}
                              alt="avatar"
                            />
                          : <img
                              className="user-avatar"
                              src={getGenerateApiImageByName(item?.userName)}
                              alt="avatar"
                            />
                        }
                        <div className="description">
                          <div
                            className={`d-flex align-items-center user-ratings-container`}
                          >
                            <div className="user_name me-2 ">
                              {item?.userName || "User Name"}
                            </div>
                          </div>
                          {/* <div className="client-container mb-2">
                            {
                              moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + (recieverCompany ? (" - " + (recieverCompany || "Company Name")) : "")
                            }
                          </div> */}
                          {/* <p style={{ whiteSpace: 'pre-line' }}>{reviewComment}</p> */}
                          {/* <p style={{ whiteSpace: 'pre-line' }}>As the brand, so is the service. They are extremely well verse with in quality and keeping up with their users. Prices are definitely high, but quality comes with a price.</p> */}
                          <p style={{ whiteSpace: 'pre-line' }}>{item?.message}</p>
                        </div>
                      </div>
                      <p className="text-start mt-2 date">
                          {/* {"Messaged - " + item?.createdOn} */}
                          {"Messaged - " + moment(convertISODate(item?.createdOn)).format("DD MMM YYYY, hh:mm:ss A")}
                      </p>
                    </div>
                    
                  ))
                }
                conversation
                
                {/* <div className='py-5'></div> */}
              </div>
            </Scrollbars>
            {/* <div className="conversation-body">
            </div> */}
            <div className="bot-conversation-footer">
              {/* <p className="text-start my-2 px-3 sender">
                Sending as {workspaceSettings?.workspaceSettings?.workspaceName  || workSpaceName ||  "WS Name"}
              </p> */}
              <div className="message-box mb-3">
                <div className="message">
                  <div className="text-container click-reply-des">
                    <span>This conversation is managed by Bot</span>
                    {/* <textarea
                      ref={textRef}
                      className="bg-transparent"
                      placeholder="This conversation is managed by Bot"
                      value={inputValue || ""}           
                      onChange={handleInputChange}
                      onKeyDown={handleKeyPress}
                      // onChange={(e: any) => {
                      //   const { value } = e?.target;
                      //   setReplyText(value);
                      // }}
                      style={{ resize: "none", width: '100%' }}
                      autoFocus={isReply} 
                    /> */}
                    {/* <div className="reply-btn">
                      <button
                        style={{
                          background: `${isReply ? "#E4E7EC" : "#E4E7EC"}`,
                        }}
                        onClick={() => {
                          if (isReply) {
                            handleReply();
                          } else {
                            setIsReply(true);
                          }
                        }}
                        type="button"
                      >
                        {isReply
                          ? "Save"
                          : isReply
                          ? "Send"
                          : isReply
                          ? "Edit"
                          : "Reply"}
                      </button>
                      <img
                        onClick={() => setIsReply(false)}
                        src={isReply ? lock_icon : unlock_icon}
                        alt="unlock"
                      />
                    </div> */}
                    {/* <p className={`click-reply-des ${isReply ? "d-none" : ""}`}>
                      Click reply and start adding your reply.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}
