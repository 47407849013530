import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Select, TimePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import EditingTabs from '../EditingTabs';
import HeaderForm from './EditionForms/HeaderForm';
// import { getImage, getTemplateImage } from '../../../../dashboard/image_service';
import './MasterEdition.scss';
import BodyForm from './EditionForms/BodyForm';
import FooterForm from './EditionForms/FooterForm';
import CallToActionButtons from './EditionForms/CallToActionButtons';
import OptionOutButton from './EditionForms/OptionOutButton';
import QuickReplyButtons from './EditionForms/QuickReplyButtons';
import { RootState } from '../../../../../../store';

const MasterEdition: React.FC<any> = () => {
    const {
        toggle_template_edition, isEditableTemplate
    }: any = useSelector((state: RootState) => state?.promotionsState);
    const dispatch = useDispatch();
    
    return (
        <React.Fragment>
            <div className='master-edition'>
                <div className='lg-device sticky-top'>
                    <EditingTabs/>
                </div>
                <div className='master-form' style={{ pointerEvents: toggle_template_edition?.templateId && !isEditableTemplate ? "none" : "auto" }}>
                    <HeaderForm/>
                </div>
                
                <div className='master-form' style={{ pointerEvents: toggle_template_edition?.templateId && !isEditableTemplate ? "none" : "auto" }}>
                    <BodyForm/>
                </div>

                <div className='master-form' style={{ pointerEvents: toggle_template_edition?.templateId && !isEditableTemplate ? "none" : "auto" }}>
                    <FooterForm/>
                </div>
                
                <div className='master-form' style={{ pointerEvents: toggle_template_edition?.templateId && !isEditableTemplate ? "none" : "auto" }}>
                    <CallToActionButtons/>
                </div>
                
                <div className='master-form' style={{ pointerEvents: toggle_template_edition?.templateId && !isEditableTemplate ? "none" : "auto" }}>
                    <QuickReplyButtons/>
                </div>
                
                <div className='master-form' style={{ pointerEvents: toggle_template_edition?.templateId && !isEditableTemplate ? "none" : "auto" }}>
                    <OptionOutButton/>
                </div>
            </div>
        </React.Fragment>
    )
};

export default MasterEdition;
