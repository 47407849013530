import React from "react";
import { useNavigate } from "react-router-dom";
import { getImage } from '../../../unified/image_service';

interface LearnNParticipateCardProps {
    card?: any;
}

const LearnNParticipateCard: React.FC<LearnNParticipateCardProps> = ({ card }: any) => {
    const navigate = useNavigate();
    const handleRoute = (route: any) => {
        if(!route){
            return;
        }
        window.open(route, '_blank');  // Open in new tab
    }
    return (
        <React.Fragment>
            <div className="custom-card">
                {/* <div className='card-image'> */}
                    <img className='card-image' src={getImage("dashboard-card-learn-and-participate")} alt='dashboard-icon'/>
                {/* </div> */}
                <div className='card-body'>
                    <div className='title-description'>
                        <h2 className='header-title'>
                            {card?.title}
                        </h2>
                        <div className='special-description lg-device'>
                            {card?.description}
                        </div>
                        <div className='description sm-device'>
                            {card?.description}
                            {/* {card?.description?.substr(0, 38)+"..."} */}
                        </div>
                        <div className='description'>
                            {card?.description?.substr(0, 44)+"..."}
                        </div>
                    </div>
                    <div className='button-container'>
                        <button
                            onClick={() => handleRoute(card?.route)}
                        >
                            {card?.button_text}
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default LearnNParticipateCard;