import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getImage, getGenerateApiImageByName } from "../../../../unified/image_service";
import { setUserProfileTab, updateProfileImage, updateProfileInfoSetting } from "../userProfileManageReducer";
import { convertImageToBase64 } from "../../../../utils/convertingFunction";

// import { workspace_routes } from "../page-routes";
// import { ChildPageTabs, MobilePageTabs, ParentPageTabs } from "../Page.Tabs";

const Profile: React.FC = () => {
    // const parent_routes = workspace_routes;
    const { personalInfo } = useSelector((state: any) => state?.profileManageSettingState);
    const dispatch = useDispatch();
    
    const [profileFirstName, setProfileFirstName] = React.useState('');
    const [profileLastName, setProfileLastName] = React.useState('');
    const [imageURL, setImageURL] = useState<any>("");
    const [profileEmail, setProfileEmail] = React.useState('');
    const [hostGuestAccountType, setHostGuestAccountType] = React.useState('Host');
    useEffect(() => {
        const { email, firstname, lastname, accountType, photo }: any = personalInfo || {};
        
        firstname && setProfileFirstName(firstname);
        lastname && setProfileLastName(lastname);
        email && setProfileEmail(email);
        accountType && setHostGuestAccountType(accountType);
        photo && setImageURL(photo);
    }, [personalInfo]);
    

    const updateProfileInfo = (key: any, value: any) => {
        if(key !== "photo"){
            dispatch(updateProfileInfoSetting({
                ColumnName: key,
                ColumnValue: value,
                clapupUserId: localStorage.getItem('cuid') || sessionStorage.getItem('cuid')
            }));
        }else{
            dispatch(updateProfileImage({
                photo: value,
                clapupUserId: localStorage.getItem('cuid') || sessionStorage.getItem('cuid'),
            }));
        }
        // dispatch(setProfileInfo(data));
    }

    const handleImageChange = (e: any) => {
        // file local path
        // const image_path
        const file = e.target.files[0];
        const path = e.target.value;
        const image_url = URL.createObjectURL(file);
        console.log({ path, file });
        setImageURL(image_url);
        
        // ======== 1st way to convert image to base64 ========
        convertImageToBase64(file).then((base64Url: any) => {
            // console.log({base64Url});
            // setLogoBaseUrl(base64Url);
            updateProfileInfo("photo", base64Url);
            // handleLogoSubmit(base64Url);

        })
        .catch((error: any) => {
            console.log({error});
        });

        // ======== 2nd way to convert image to base64 ========
        // const formData = new FormData();
        // const body = {
        //     "code": Number(new Date().getTime() + (1000*1000)),
        //     "AccessKey": "746def78-473d-4b9a-b230976d1e62-6dd3-46de",
        //     "workspaceId": sessionStorage.getItem('wuid'),
        // }
        // formData.append('file', file);
        // formData.append('body', btoa(JSON.stringify(body)));
        // console.log({ formBody: body });
        // axios((auth_url) + "/api/v1/upload-workspace-image", {
        //     method: 'POST',
        //     data: formData,
        // }).then(response => {
        //     console.log({response});
        //     // setImageURL(response?.data?.data?.url);
        // }).catch(error => {
        //     console.log({error});
        // });
        
    }

    const handleDeleteProfilePicture = () => {
        console.log("Came for deleting logo...");
        updateProfileInfo("photo", "")
    }

    const {userPermissions} = useSelector((state: any) => state?.login);
    const history = useNavigate();
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "workspaceSettings") : null;
        // const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "generalSettings-Account") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions, history]);
    return (
        <React.Fragment>
            <div className="settings-form-item">
                <div className="title">First Name</div>
                <div className="action-body">
                    <div className="input-container">
                        <input
                            className=""
                            defaultValue={profileFirstName || ""}
                            onChange={(e: any) => {
                                const { value } = e.target;
                                setProfileFirstName(value);
                            }}
                            placeholder={"First Name"}
                        />
                        <img
                            onClick={() => updateProfileInfo('FirstName', profileFirstName)}
                            className="tik"
                            src={getImage("fill-success-tik")}
                            alt="tik"
                        />
                    </div>
                </div>
            </div>
            <div className="settings-form-item">
                <div className="title">Last Name</div>
                <div className="action-body">
                    <div className="input-container">
                        <input
                            className=""
                            defaultValue={profileLastName || ""}
                            onChange={(e: any) => {
                                const { value } = e.target;
                                setProfileLastName(value);
                            }}
                            placeholder={"Last Name"}
                        />
                        <img
                            onClick={() => updateProfileInfo('LastName', profileLastName)}
                            className="tik"
                            src={getImage("fill-success-tik")}
                            alt="tik"
                        />
                    </div>
                </div>
            </div>
            <div className="settings-form-item">
                <div className="title">Email</div>
                <div className="action-body">
                    <div className="input-container">
                        <input
                            className="disable-input"
                            type="email"
                            required={true}
                            readOnly={true}
                            onChange={(e: any) => {
                                const { value } = e.target;
                                setProfileEmail(value);
                            }}
                            defaultValue={profileEmail || "example@gmail.ccom"}
                            placeholder={"Email"}
                        />
                        {/* <img
                            onClick={() => updateProfileInfo('Email', profileEmail)}
                            className="tik"
                            src={getImage("fill-success-tik")}
                            alt="tik"
                        /> */}
                    </div>
                </div>
            </div>
            <div className="settings-form-item">
                <div className="title">Profile Image</div>
                <div className="action-body">
                    <div className="input-container">
                        <div className="logo-container">
                            <img
                                className='logo' 
                                src={imageURL || getGenerateApiImageByName("none")} 
                                alt={"logo"} 
                            />
                            <div className="action-container">
                                <div
                                    onClick={() => handleDeleteProfilePicture()}
                                >
                                    Delete
                                </div>
                                <input
                                    id="image" type="file"
                                    // accept='*'
                                    accept='image/png, image/jpg, image/jpeg, image/svg'
                                    onChange={(e: any) => {
                                        // console.log({ img: e?.target?.files[0] });
                                        // setImageURL(URL.createObjectURL(e?.target?.files[0]));
                                        // setImage(e?.target?.files[0]?.name);
                                        handleImageChange(e);
                                        // if (e?.target?.files[0]?.size < 25000) {
                                        // }

                                    }}
                                    className="d-none"
                                />
                                <label htmlFor="image">Upload New</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="settings-form-item">
                <div className="title">Account Type</div>
                <div className="action-body">
                    <div className="input-container">
                        <input
                            className="disable-input"
                            readOnly={true}
                            value={hostGuestAccountType || "Guest Account"}
                            onChange={(e: any) => {
                                const { value } = e.target;
                                setHostGuestAccountType(value);
                            }}
                            placeholder={"Account"}
                        />
                        {/* <img
                            onClick={() => updateProfileInfo('AccountType', hostGuestAccountType)}
                            className="tik"
                            src={getImage("fill-success-tik")}
                            alt="tik"
                        /> */}
                    </div>
                </div>
            </div>
            <div className="extra"></div>
        </React.Fragment>
    );
};

export default Profile;