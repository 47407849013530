import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Select, TimePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// import { getImage, getTemplateImage } from '../../../../dashboard/image_service';
import { RootState } from '../../../../../store';
import EditingTabs from './EditingTabs';
import ActionButtons from './ActionButtons';
import GeneralPreviewEdition from './GeneralPreviewEdition/GeneralPreviewEdition';
import MasterEdition from './MasterEdition/MasterEdition';
import './EditingTemplate.scss';
import { searchTemplateText } from '../../../reducer';

// type EditingTemplateProps = {
//     onCancel?: () => void;
//     onParentCallback?: (data: any, file?: any) => void;
// };

const EditingTemplate: React.FC<any> = () => {
    const {editing_for_master_non_general_preview, search_template_text}: any = useSelector((state: RootState) => state?.promotionsState);
    const dispatch = useDispatch();
    useEffect(() => {
        if (search_template_text) {
            dispatch(searchTemplateText(""));
        }
    },[search_template_text]);


    return (
        <React.Fragment>
            <div className='sm-device commerce-template-edition-section'>
                <EditingTabs/>
                {
                    editing_for_master_non_general_preview
                    ?   <MasterEdition/>
                    :   <GeneralPreviewEdition/>
                }
                <div className='sm-save-edition-container'>
                    <ActionButtons />
                </div>
            </div>
            <div className='lg-device commerce-template-edition-section'>
                <MasterEdition/>
                <GeneralPreviewEdition/>
            </div>
        </React.Fragment>
    )
};

export default EditingTemplate;
