import React, { useState, useEffect, useRef } from 'react';
type CountDownProps = {
  onChange?: (time: string) => void;
  isStarted?: boolean;
  customStyle?: any;
  customClass?: any;
};

const CustomCountdown: React.FC<CountDownProps> = ({ onChange, isStarted, customStyle, customClass }: any) => {
    const [timer, setTimer] = useState("00:00:00");
    const intervalRef = useRef<any>(undefined);
  
    const startTimer = () => {
      intervalRef.current = setInterval(() => {
        setTimer((prevTimer) => {
            const [prevHours, prevMinutes, prevSeconds] = prevTimer.split(":").map(Number);
    
            // Calculate the next time
            const nextSeconds = prevSeconds + 1;
            const nextMinutes = prevMinutes + Math.floor(nextSeconds / 60);
            const nextHours = prevHours + Math.floor(nextMinutes / 60);
    
            // Format the next time
            const formattedHours = String(nextHours).padStart(2, "0");
            const formattedMinutes = String(nextMinutes % 60).padStart(2, "0");
            const formattedSeconds = String(nextSeconds % 60).padStart(2, "0");
            onChange && onChange(`${formattedHours}:${formattedMinutes}:${formattedSeconds}`)
            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        });
      }, 1000);
    };
  
    const clearTimer = () => {
      clearInterval(intervalRef.current);
      setTimer("00:00:00");
    };
  
    useEffect(() => {
      if (isStarted) {
        startTimer();
      } else {
        clearTimer();
      }
      return () => clearInterval(intervalRef.current);
    }, [isStarted]);
  
    const onClickReset = () => {
      clearTimer();
      startTimer();
    };
  
    return (
      <div style={{...customStyle}} className={`${customClass}`}>
        {timer}
      </div>
    );
  };

export default CustomCountdown;