import { takeEvery, put, call, all, delay } from 'redux-saga/effects';
import axios from "axios";
import { api_url } from '../../../utils/server';
import { generateAccessToken } from '../../../profile/reducer';
import { getTeamList, setTeamEmails, toggleUpdatedTeamContent, setSettingUsers, setSettingTeamChannels, setTeamList, setSettingTeamUserList, setSettingTeamChannelList, getSettingTeamUserAndChannelList } from "./teamsReducer";
import Constants from '../../../constants';
import { setErrorMessage, setSuccessMessage } from '../../../unified/reducer';

// Worker Saga:
function* findTeamList(action: any): Generator<any> {
    try {
        const { clapupUserId, workspaceId } = action.payload || {};
        if (!clapupUserId || !workspaceId) {
            // yield // yield put({ type: Constants.LOGOUT });
            return;
        }
        let url = api_url + `/api/v1.2/teams/FetchTeamDetails?workspaceId=${workspaceId}&clapupUserId=${clapupUserId}&teamId=`;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });
        // console.log("setting_teams: ", { data, status });
        if (status === 200) {
            const { teamDetails } = data || {};
            yield put(setTeamList(teamDetails));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* getTeamUsersAndChannels(action: any): Generator<any> {
    try {
        const { clapupUserId, workspaceId, teamId } = action.payload || {};
        if (!clapupUserId || !workspaceId) {
            // yield put({ type: Constants.LOGOUT });
            return;
        }

        if (!teamId) {
            yield put(setErrorMessage("Team id is required"));
            return;
        }
        let url = api_url + `/api/v1.2/teams/FetchTeamUsersAndChannelDetails?workspaceId=${workspaceId}&clapupUserId=${clapupUserId}&teamId=${teamId}`;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });
        // console.log("setting_teams: ", { data, status });
        if (status === 200) {
            const { teamChannels, teamUsers } = data || {};
            yield put(setSettingTeamUserList(teamUsers));
            yield put(setSettingTeamChannelList(teamChannels));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* findSettingUserList(action: any): Generator<any> {
    try {
        const { workspaceId } = action.payload || {};
        if (!workspaceId) {
            // yield put({ type: Constants.LOGOUT });
            return;
        }
        let url = api_url + `/api/v1.2/teams/GetWorkspaceUsers?workspaceId=${workspaceId}`;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });
        // console.log("setting_team_users: ", { data, status });
        if (status === 200) {
            const { users } = data || {};
            yield put(setSettingUsers(users));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* findSettingTeamChannelList(action: any): Generator<any> {
    try {
        const { workspaceId } = action.payload || {};
        if (!workspaceId) {
            // yield put({ type: Constants.LOGOUT });
            return;
        }
        let url = api_url + `/api/v1.2/teams/GetChannelDetailsForTeamMapping?workspaceId=${workspaceId}`;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });
        // console.log("setting_team_users: ", { data, status });
        if (status === 200) {
            const { channels } = data || {};
            yield put(setSettingTeamChannels(channels));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* getTeamEmailsByTeamId(action: any): Generator<any> {
    try {
        const { workspaceId, teamId } = action.payload || {};
        if (!workspaceId) {
            // yield put({ type: Constants.LOGOUT });
            return;
        }
        let url = api_url + `/api/v1.2/teams/GetEmails?workspaceId=${workspaceId}&teamId=${teamId}`;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });
        // console.log("setting_team_users: ", { data, status });
        if (status === 200) {
            const { emails } = data || {};
            yield put(setTeamEmails(emails));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* createTeam(action: any): Generator<any> {
    try {
        const { clapupUserId, workspaceId, roleId } = action.payload || {};
        // console.log({ data_object: payload });
        if (!clapupUserId || !workspaceId || !roleId) {
            yield // yield put({ type: Constants.LOGOUT });
            return;
        }
        const payload = {
            ...action.payload,
            clapupUserId,
            workspaceId,
            roleId,
        };
        let url = api_url + `/api/v1.2/teams/CreateTeam`;
        const { status }: any = yield call(axios.post, url, payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setSuccessMessage("Created Successfully"));
            yield put(getTeamList());
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* addTeamMembers(action: any): Generator<any> {
    try {
        const { workspaceId, roleId, teamId } = action.payload || {};
        // console.log({ data_object: payload });
        if (!workspaceId || !roleId) {
            yield // yield put({ type: Constants.LOGOUT });
            return;
        }
        if (!teamId) {
            yield put(setErrorMessage("Team id is required!"));
            return;
        }
        const payload = {
            ...action.payload,
            workspaceId,
            roleId,
        };
        let url = api_url + `/api/v1.2/teams/MapUsersToTeam`;
        const { status }: any = yield call(axios.post, url, payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setSuccessMessage("Added Successfully"));
            yield put(getSettingTeamUserAndChannelList(teamId));
            delay(1000);
            yield put(toggleUpdatedTeamContent(true));
            yield put(getTeamList());

        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* removeTeamMember(action: any): Generator<any> {
    try {
        const { teamId } = action.payload || {};
        // console.log({ data_object: payload });
        // if (!workspaceId || !roleId) {
        //     yield // yield put({ type: Constants.LOGOUT });
        //     return;
        // }
        if (!teamId) {
            yield put(setErrorMessage("Team id is required!"));
            return;
        }
        const payload = {
            ...action.payload
        };
        let url = api_url + `/api/v1.2/teams/RemoveUsersFromTeam`;
        const { status }: any = yield call(axios.post, url, payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setSuccessMessage("Removed Successfully"));
            yield put(getSettingTeamUserAndChannelList(teamId));
            delay(1000);
            yield put(toggleUpdatedTeamContent(true));
            yield put(getTeamList());

        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* addTeamMChannels(action: any): Generator<any> {
    try {
        const { workspaceId, teamId } = action.payload || {};
        // console.log({ data_object: payload });
        if (!workspaceId) {
            yield // yield put({ type: Constants.LOGOUT });
            return;
        }
        if (!teamId) {
            yield put(setErrorMessage("Team id is required!"));
            return;
        }
        const payload = {
            ...action.payload,
            workspaceId,
        };
        let url = api_url + `/api/v1.2/teams/MapChannelsToTeam`;
        const { status }: any = yield call(axios.post, url, payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setSuccessMessage("Added Successfully"));
            yield put(getSettingTeamUserAndChannelList(teamId));
            delay(1000);
            yield put(toggleUpdatedTeamContent(true));
            yield put(getTeamList());
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* removeTeamMChannel(action: any): Generator<any> {
    try {
        const { clapupUserId, teamId, channelId } = action.payload || {};
        // console.log({ data_object: payload });
        if (!clapupUserId) {
            yield // yield put({ type: Constants.LOGOUT });
            return;
        }
        if (!teamId) {
            yield put(setErrorMessage("Team id is required!"));
            return;
        }
        if (!channelId) {
            yield put(setErrorMessage("Channel id is required!"));
            return;
        }
        const payload = {
            ...action.payload,
            clapupUserId,
        };
        let url = api_url + `/api/v1.2/teams/RemoveChannel`;
        const { status }: any = yield call(axios.post, url, payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setSuccessMessage("Removed Successfully"));
            yield put(getSettingTeamUserAndChannelList(teamId));
            delay(1000);
            yield put(toggleUpdatedTeamContent(true));
            yield put(getTeamList());
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* updateTeamSettings(action: any): Generator<any> {
    try {
        const { clapupUserId, workspaceId, teamId } = action.payload || {};
        // console.log({ data_object: payload });
        if (!clapupUserId || !workspaceId) {
            yield // yield put({ type: Constants.LOGOUT });
            return;
        }
        if (!teamId) {
            yield put(setErrorMessage("Team id is required!"));
            return;
        }

        let url = api_url + `/api/v1.2/teams/UpdateTeamSettings`;
        const { status }: any = yield call(axios.post, url, action.payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setSuccessMessage("Saved Successfully"));
            yield put(getTeamList());
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* updateChannelSettings(action: any): Generator<any> {
    try {
        const { clapupUserId, workspaceId, channelId, teamId } = action.payload || {};
        // console.log({ data_object: payload });
        if (!clapupUserId || !workspaceId) {
            yield // yield put({ type: Constants.LOGOUT });
            return;
        }
        if (!channelId) {
            yield put(setErrorMessage("Channel id is required!"));
            return;
        }
        const payload = {
            ...action.payload
        };
        delete payload.teamId;

        let url = api_url + `/api/v1.2/teams/UpdateChannelSettings`;
        const { status }: any = yield call(axios.post, url, payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setSuccessMessage("Saved Successfully"));
            yield put(getSettingTeamUserAndChannelList(teamId));
            delay(1000);
            yield put(toggleUpdatedTeamContent(true));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* deleteTeamById(action: any): Generator<any> {
    try {
        const { clapupUserId, workspaceId, teamId } = action.payload || {};
        // console.log({ data_object: payload });
        if (!clapupUserId || !workspaceId) {
            yield // yield put({ type: Constants.LOGOUT });
            return;
        }
        if (!teamId) {
            yield put(setErrorMessage("Team id is required!"));
            return;
        }

        let url = api_url + `/api/v1.2/teams/DeleteTeam`;
        const { status }: any = yield call(axios.post, url, action.payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setSuccessMessage("Deleted Successfully"));
            yield put(getTeamList());
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}


// Watcher Saga:
function* teamsSaga() {

    yield all([
        takeEvery(Constants.REQUEST_FOR_GETTING_TEAM_USERS, findSettingUserList),
        takeEvery(Constants.GET_TEAM_LIST, findTeamList),
        takeEvery(Constants.GET_TEAM_USER_AND_CHANNEL_LIST, getTeamUsersAndChannels),
        takeEvery(Constants.REQUEST_FOR_GETTING_TEAM_CHANNELS, findSettingTeamChannelList),
        takeEvery(Constants.REQUEST_FOR_ADDING_TEAM_MEMBERS, addTeamMembers),
        takeEvery(Constants.REQUEST_FOR_ADDING_TEAM_CHANNELS, addTeamMChannels),
        takeEvery(Constants.REQUEST_FOR_CREATING_TEAM, createTeam),
        takeEvery(Constants.REQUEST_FOR_UPDATING_TEAM, updateTeamSettings),
        takeEvery(Constants.REQUEST_FOR_GETTING_TEAM_EMAILS, getTeamEmailsByTeamId),
        takeEvery("REQ_FOR_DELETING_TEAM", deleteTeamById),
        takeEvery("REMOVE_TEAM_MEMBER", removeTeamMember),
        takeEvery("REMOVE_TEAM_CHANNEL", removeTeamMChannel),
        takeEvery("UPDATE_TEAM_CHANNEL_SETTING", updateChannelSettings),

    ]);
}

export default teamsSaga;

