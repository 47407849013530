import React, { useEffect, useState } from 'react';
import image_icon from '../../../../assets/icons/file-image-icon.svg';
// import dots from '../../../assets/icons/dots.svg';
import dots from '../../../../assets/icons/more-action.svg';
import global from '../../../../assets/icons/global.svg';
// import global from '../../../assets/icons/global-pro.svg';
import lock from '../../../../assets/icons/lock.svg';
// import global from '../../../assets/icons/global-pro-2.svg';
// import lock from '../../../assets/icons/lock-pro.svg';
// import delete_icon from '../../../assets/icons/delete-btn.svg';
// import switch_icon from '../../../../assets/icons/switch-off.svg';
// import switch_icon_active from '../../../../assets/icons/switch-on.svg';
// import '../Content.scss';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import moment from 'moment';
// import { MoreOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import {
  // setInfoMessage,
  setErrorMessage, setSuccessMessage
} from '../../../../unified/reducer';
// import ContentImageAndVideo from './ContentImageAndVideo';
import Dialog from '../../../Dialogs/Dialog';
import {
  // removeContentsFromFolder, setFolderContents, getOneTimeFolderContents, setOneTimeFolderContents,
  setDefaultContentIdx, setSelectedDeletableContents,
  getFolderContents, setIsUpdatedContent, updateFolderContent,
  deleteContents, 
  setShowingUploadingProgress,
  // setIsContentUploadedError, getContentVersions,
  setUploadProgressCount
} from '../../mediaReducer';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'react-bootstrap';
import { convertISODate } from '../../../../utils/convertingFunction';
import { download2Content, modifyString } from '../../../../utils/mediaService';
// import CustomDropdown from '../../../CustomDropdown/CustomDropdown';
const SmallDeviceContents = ({activeFolder, setEditableContent, setNewVersionVideoFile, handleUploadNewVersionFile}: any) => {
  const {
    searched_content_text,
    folder_contents, contentNextDate, is_updated_folder_content,
    selected_deletable_contents,
    // default_content_idx, show_uploading_progress,
  }: any = useSelector((state: RootState) => state?.mediaState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route_path = window.location.pathname;
  const query_string = window.location.search;
  const path = query_string.replace('?', '')?.replace('&', "=");
  // const path_array = path.split("=");
  const [showDropdown, setShowDropdown] = React.useState<any>(null);
  // const [selectedImages, setSelectedImages] = React.useState<any[]>([]);
  const [isSelectAll, setIsSelectAll] = React.useState<boolean>(false);
  const [updatableContent, setUpdatableContent] = useState<any>(null);
  const [isStateChange, setIsStateChange] = useState<any>(null);

  // useEffect(() => {
  //   console.log({ path_array });
    
  //   if (query_string?.includes("=folders") && default_content_idx !== null) {
  //     dispatch(setDefaultContentIdx(null));
  //     dispatch(setSelectedDeletableContents([]));
  //   } else if (query_string?.includes("=contents") && path_array?.length > 3 && folder_contents?.length) {
  //     try {
  //       const idx = Number(path_array[3]);
  //       if (contents?.length > idx || contents?.length === idx) {
  //         if (contents[idx]?.id) {
  //           dispatch(setDefaultContentIdx(idx));
  //           // get all versions
  //           // console.log({content_id: contents[idx]?.id});
            
  //           dispatch(getContentVersions(contents[idx]?.id));
  //         }else {
  //           navigate(`${route_path}?${activeFolder}=folders`);
  //         }
  //         // dispatch(setSelectedDeletableContents([contents[idx]?.id]));
  //       } else {
  //         navigate(`${route_path}?${activeFolder}=folders`);
  //       }
  //     } catch (error) {
  //       console.log({ error });
  //       navigate(`${route_path}?${activeFolder}=folders`);
  //     }
  //   } else if (query_string?.includes("=contents") && path_array?.length < 4 && folder_contents?.length) { 
  //     navigate(`${route_path}?${activeFolder}=folders`);
  //   }
  // }, [query_string, default_content_idx]);

  const [contents, setContents] = useState<any[]>(folder_contents);
  useEffect(() => {
    if (searched_content_text) {
      const filtered_contents = folder_contents?.filter((content: any) => content?.contentFileName?.toLowerCase().includes(searched_content_text.toLowerCase()));
      setContents(filtered_contents);
    } else {
      if(folder_contents?.length) {
        setContents(folder_contents); 
      }
    }
  // }, [query_string]);
  }, [searched_content_text]);
  
  useEffect(() => {
    // console.log({my_contents: folder_contents});
    
    // { imageUrl: "https://i.ibb.co/g6B7s9h/FB-IMG-1660979005560.jpg", createdOn: "2021-05-12T06:30:00.000Z", createdBy: "Sreekanth KM", id: 1, state: true, type: 'video' },
    // if (is_updated_folder_content) {
    //   console.log({ activeFolder });
    //   // dispatch(getOneTimeFolderContents({ folderName: activeFolder }));
      
    //   // dispatch(setOneTimeFolderContents([
    //   //   { imageUrl: "https://i.ibb.co/g6B7s9h/FB-IMG-1660979005560.jpg", createdOn: "2021-05-12T06:30:00.000Z", createdBy: "Sreekanth KM", id: 1, state: true, type: "video" },
    //   //   { imageUrl: "https://i.ibb.co/0c94WM1/sign-in-logo.png", createdOn: "2021-06-12T07:30:00.000Z", createdBy: "Sreekanth KM", id: 2, type: "image" },
    //   //   { imageUrl: "https://i.ibb.co/Np2RYHK/lws-logo-copy.png", createdOn: "2021-07-12T08:30:00.000Z", createdBy: "Sreekanth KM", id: 3, type: "image" },
    //   //   { imageUrl: "https://i.ibb.co/XCqDWb0/cse-logo.png", createdOn: "2022-08-12T09:30:00.000Z", createdBy: "Sreekanth KM", id: 4, type: "image" },
    //   //   { imageUrl: "https://i.ibb.co/QHDQr55/cat-two.jpg", createdOn: "2022-08-12T09:30:00.000Z", createdBy: "MMHK", id: 5, type: "image" },
    //   //   { imageUrl: "https://i.ibb.co/g6B7s9h/FB-IMG-1660979005560.jpg", createdOn: "2021-05-12T06:30:00.000Z", createdBy: "Sreekanth KM", id: 6, state: true, type: "image"},
    //   //   { imageUrl: "https://i.ibb.co/0c94WM1/sign-in-logo.png", createdOn: "2021-06-12T07:30:00.000Z", createdBy: "Sreekanth KM", id: 7, type: "image" },
    //   //   { imageUrl: "https://i.ibb.co/Np2RYHK/lws-logo-copy.png", createdOn: "2021-07-12T08:30:00.000Z", createdBy: "Sreekanth KM", id: 8, type: "image" },
    //   //   { imageUrl: "https://i.ibb.co/XCqDWb0/cse-logo.png", createdOn: "2022-08-12T09:30:00.000Z", createdBy: "Sreekanth KM", id: 9, type: "image" },
    //   //   { imageUrl: "https://i.ibb.co/QHDQr55/cat-two.jpg", createdOn: "2022-08-12T09:30:00.000Z", createdBy: "MMHK", id: 10, type: "image" },
    //   //   { imageUrl: "https://i.ibb.co/g6B7s9h/FB-IMG-1660979005560.jpg", createdOn: "2021-05-12T06:30:00.000Z", createdBy: "Sreekanth KM", id: 11, state: true,  type: "image" },
    //   //   { imageUrl: "https://i.ibb.co/0c94WM1/sign-in-logo.png", createdOn: "2021-06-12T07:30:00.000Z", createdBy: "Sreekanth KM", id: 12, type: "image" },
    //   //   { imageUrl: "https://i.ibb.co/Np2RYHK/lws-logo-copy.png", createdOn: "2021-07-12T08:30:00.000Z", createdBy: "Sreekanth KM", id: 13, type: "image" },
    //   //   { imageUrl: "https://i.ibb.co/XCqDWb0/cse-logo.png", createdOn: "2022-08-12T09:30:00.000Z", createdBy: "Sreekanth KM", id: 14, type: "image" },
    //   //   { imageUrl: "https://i.ibb.co/QHDQr55/cat-two.jpg", createdOn: "2022-08-12T09:30:00.000Z", createdBy: "MMHK", id: 15, type: "image" },
    //   // ]));
    // }
    if(folder_contents?.length || is_updated_folder_content) {
      dispatch(setIsUpdatedContent(false));
      setUpdatableContent(null);
      setIsStateChange(null);
      setShowDropdown(null);
      setTimeout(() => {
        setContents(folder_contents);
      }, 1000);
    }
  }, [folder_contents?.length, is_updated_folder_content]);

  useEffect(() => {
    // console.log({selected_deletable_contents});
    
    if (selected_deletable_contents?.length) {
      setIsSelectAll(true);
      // dispatch(setSelectedDeletableContents(selectedImages));
    } else {
      setIsSelectAll(false);
      if (query_string?.includes("=folders")) {
        dispatch(setSelectedDeletableContents([]));
      }
    }
    
    // if(isSelectAll && !selected_deletable_contents?.length) {
    //   setSelectedImages([]);
    // }
    
  }, [selected_deletable_contents?.length]);

  const handleSelectAll = (e: any) => {
    const { checked } = e.target;
    if (checked) {
      const ids = folder_contents?.map((item: any) => item?.id);
      // setSelectedImages(ids);
      dispatch(setSelectedDeletableContents(ids));
    } else {
      // setSelectedImages([]);
      dispatch(setSelectedDeletableContents([]));
    }
    
    console.log({checked});
    setIsSelectAll(checked);
  };

  const handleSelectImage = (e: any, imageId: any) => {
    if (e) {
      // setSelectedImages([...selectedImages, imageId]);
      dispatch(setSelectedDeletableContents([...selected_deletable_contents, imageId]));
    } else {
      // setSelectedImages(selectedImages.filter((item: any) => item !== imageId));
      dispatch(setSelectedDeletableContents(selected_deletable_contents.filter((item: any) => item !== imageId)));
    }
  };

  const [deleteFolderContentId, setDeleteFolderContentId] = useState(null);
  const handleDeleteFolderContent = (contentId: any) => {
    console.log({contentId});
    
    if (!contentId) {
      setDeleteFolderContentId(null);
    } else {
      dispatch(deleteContents([contentId]));
      // dispatch(setSelectedDeletableContents([]));
      // dispatch(setInfoMessage('File deleted successfully.'));
      setTimeout(() => {
        setDeleteFolderContentId(null);
        setShowDropdown(null);
      }, 2000);
    }
  };
  
  const handleUpdateContent = (content?: any, key?: string) => {
    if (!content) {
      setUpdatableContent(null);
    } else {
      const payloadObject: any = {
        contentId: content?.id,
        clapupUserId: sessionStorage.getItem('cuid'),
        workspaceId: sessionStorage.getItem('wuid'),
        roleId: sessionStorage.getItem('roleId')
      };

      if (key === "sticky") {
        payloadObject.stageName = "sticky";
        payloadObject.stageValue = content?.isSticky ? "0" : "1";
        content.isSticky = !content?.isSticky;
      } else if(key === "biopage") {
        payloadObject.stageName = "biopage";
        payloadObject.stageValue = content?.addedToBioPage ? "0" : "1";
        content.addedToBioPage = !content?.addedToBioPage;
      } else if (key === "state") {
        payloadObject.stageName = "publish";
        payloadObject.stageValue = content?.stage === "publish" ? "0" : "1";
        content.stage = content?.stage === "publish" ? null : "publish";
      }
      console.log({ payloadObject, content});
      dispatch(updateFolderContent(payloadObject, content));
    }
  };

  const handleStateChange = (content?: any) => {
    console.log({content});
    if (!content) {
      setIsStateChange(null);
    } else {

      handleUpdateContent(content, "state");
      // dispatch(setInfoMessage('File state changed successfully.'));
      setIsStateChange(null);
    }
  };

  const [searchText, setSearchText] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [hasMore, setHasMore] = React.useState<boolean>(true);

  const fetchMoreData = () => {
    setIsLoading(true);
    if (!contentNextDate) {
        setHasMore(false);
        setIsLoading(false);
        return;
    }
    setTimeout(() => {
      // // setContents(contents.concat(contents));
      const contentPayload: any = {
        folderName: activeFolder,
        nextDate: contentNextDate,
      };
      dispatch(getFolderContents(contentPayload));
      setIsLoading(false);
    }, 1500);
  };

  // console.log({ contents });

  return (
    <React.Fragment>
      {
        deleteFolderContentId ?
          <Dialog
            open={deleteFolderContentId}
            parentCallback={(id: any) => handleDeleteFolderContent(id)}
            classes='dialog-container-content'
            title='Delete File?'
            warning_dialog_content='If you click Yes, file will be deleted forever. This action is irreversible.'
            footer={true}
            left_btn_hover='No, Cancel'
            right_btn_hover='Yes, Delete'
          />
        : updatableContent ?
          <Dialog
            open={updatableContent}
              parentCallback={(id: any) => {
                // console.log({id});
                if (id) {
                  handleUpdateContent(updatableContent, "biopage");
                } else {
                  setUpdatableContent(null);
                }
              }}
            classes='dialog-container-content'
            title='Continue?'
            right_button_text='Yes, Continue'
            warning_dialog_content={
              !updatableContent?.addedToBioPage
              ? 'If you click Yes, image will be added to your biopage gallery section.'
              : 'If you click Yes, image will be removed from your biopage gallery section.'
            }
            footer={true}
          />
        : isStateChange ?
            <Dialog
              open={isStateChange}
              parentCallback={(content: any) => {
                console.log({isStateChange, content});
                if (content) {
                  handleStateChange(content);
                } else {
                  setIsStateChange(null);
                }
              }}
              classes='dialog-container-content'
              title='Continue?'
              right_button_text='Yes, Continue'
              warning_dialog_content={
                isStateChange?.stage === "publish"
                ? 'If you click Yes, image will become private and will not be available through a unique url.'
                : 'If you click Yes, image will become public and will be available through a unique url.'
              }
              footer={true}
            />
        : null
      }
      
      <InfiniteScroll
        dataLength={contents?.length}
        // className="border"
        scrollThreshold={0.9}
        style={{ overflow: "scroll", paddingRight: 2 }}
        next={() => {
            console.log(
              `prev -> ${contents?.length}, next -> ${contents?.length + 20}`
            );
            fetchMoreData();
        }}
        inverse={false}
        hasMore={hasMore}
        loader={
          isLoading && contents?.length > 9 ? (
            <div className="d-flex justify-content-center align-items-center mt-1">
            <Spinner
                size="sm"
                animation="border"
                variant="primary"
            />
            </div>
          ) : null
        }
        scrollableTarget="scrollableDiv"
        // endMessage={
        //   <p style={{ textAlign: "center" }}>
        //     <b>Yay! You have seen it all</b>
        //   </p>
        // }
        height={"68vh"} // 43.5vh
      >
        <table className="table table-borderless content-table">
          <thead className='content-thead sticky-top special-lg-md-device'>
            <tr>
              <th style={{width: '20%', textAlign: 'start'}}>Name</th>
              <th className='special-lg-md-device' style={{width: '20%'}}>Updated Date</th>
              <th className='special-lg-md-device' style={{width: '20%'}}>Created By</th>
              <th style={{width: '5%', color: 'transparent'}}>Ellipse</th>
              <th className='special-lg-md-device' style={{width: '9%'}}>State</th>
              <th className='special-lg-md-device' style={{ width: '6%', textAlign: 'center' }}>
                <input
                  type='checkbox'
                  className='form-check-input'
                  checked={isSelectAll}
                  onChange={handleSelectAll}
                />
              </th>
            </tr>
            <tr>
              <th className='h-0 p-0'></th>
              <th className='h-0 p-0'></th>
              <th className='h-0 p-0'></th>
              <th className='h-0 p-0'></th>
              <th className='h-0 p-0'></th>
              <th className='h-0 p-0'></th>
              <th className='h-0 p-0'></th>
              <th className='h-0 p-0'></th>
            </tr>
          </thead>
          <tbody>
            {
              contents?.map((content: any, index: number) => (
                <tr key={index} style={{verticalAlign: 'middle'}}>
                  <td className='special-sm-device' style={{cursor: 'pointer'}}>
                    <div className="table-image-container"
                      onClick={() => {
                        navigate(`${route_path}?${activeFolder}=contents&idx=${index}`);
                        dispatch(setDefaultContentIdx(index));
                        setShowDropdown(null);
                      }}
                    >
                      <img className='content-img' src={image_icon} alt="content-icon" />
                      <div className='title-container'>
                        <div className='content-name'>
                          {
                            content?.contentFileName?.length > 15
                            ? modifyString(content?.contentFileName, 10, 5, "..")
                            : (content?.contentFileName || `content-${(index + 1)}.ex`) 
                          }
                          {/* {modifyString(content?.contentFileName, 5, 5, "..") || `image${(index + 1)}.jpg`} */}
                        </div>
                        <div className='content-date'>
                          <span className='special-gen-sm-device'>{moment(convertISODate(content?.updatedOn || content?.createdOn)).utc().format("DD MMM, YYYY")} By {content?.createdBy || "N/A"}</span>
                          <span className='special-ex-sm-device'>{moment(convertISODate(content?.updatedOn || content?.createdOn)).utc().format("DD MMM, YYYY")}, {content?.createdBy || "N/A"}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  
                  <td className='special-lg-md-device' style={{width: '20%', padding: '0 15px', cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`${route_path}?${activeFolder}=contents&idx=${index}`);
                      dispatch(setDefaultContentIdx(index));
                      setShowDropdown(null);
                    }}
                  >
                    <div className="table-image-container"
                      title={content?.contentFileName}
                    >
                      <img src={image_icon} alt="folder-icon" />
                      <span>{modifyString(content?.contentFileName, 5, 5, "..") || `image${(index+1)}.jpg`}</span>
                    </div>
                  </td>

                  <td className='special-lg-md-device' style={{ width: '18%', cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`${route_path}?${activeFolder}=contents&idx=${index}`);
                      dispatch(setDefaultContentIdx(index));
                      setShowDropdown(null);
                    }}
                  >
                    <span className='md-sm-device'>{moment(convertISODate(content?.updatedOn || content?.createdOn)).utc().format("DD MMM YYYY")}</span>
                  </td>

                  <td className='special-lg-md-device' style={{ width: '20%', cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`${route_path}?${activeFolder}=contents&idx=${index}`);
                      dispatch(setDefaultContentIdx(index));
                      setShowDropdown(null);
                    }}
                  >{content?.createdBy || "N/A"}</td>
                  
                  {/* <td style={{width: '5%'}} className="more-action"> */}
                  <td className="more-action">
                    <Dropdown
                      trigger={['click']}
                      placement="bottomRight"
                      overlay={
                          <div className='content-dropdown'>
                            <p
                              onClick={() => {
                                // console.log({ download_content: content });
                                navigate(`${route_path}?${activeFolder}=contents&idx=${index}`);
                                dispatch(setDefaultContentIdx(index));
                                setShowDropdown(null);
                              }}
                            >
                              View File
                            </p>
                            <p
                              onClick={async() => {
                                // console.log({ download_content: content });
                                const contentUrl = await download2Content(content);
                                // console.log({contentUrl});
                                if(contentUrl) {
                                  setShowDropdown(null);
                                } else {
                                  dispatch(setErrorMessage("Something went wrong, please try again letter"))
                                }
                              }}
                            >
                              Download
                            </p>
                            <label htmlFor={"content-"+content?.id}
                              onClick={() => {
                                // console.log("Event for uploading New Version");
                                setEditableContent(content);
                              }}
                            >
                              Upload New Version
                            </label>
                            <input
                              id={"content-"+content?.id} type="file"
                              accept={content?.subCategory === 'GalleryVideos' ? 'video/*' : 'image/png, image/jpg, image/jpeg, image/svg'}
                              onChange={async(e: any) => {
                                // console.log({ img: e?.target?.files[0] });
                                dispatch(setShowingUploadingProgress(true));
                                dispatch(setUploadProgressCount(1));
                                try {
                                  const file = e.target.files[0];
                                  const { type } = file;
                                  setShowDropdown(null);

                                  if (type?.includes("video")) {
                                    setNewVersionVideoFile(file);
                                  } else {
                                    handleUploadNewVersionFile(file);
                                  }
                                  // setTim
                                } catch (err: any) {
                                  console.log({ err });
                                  dispatch(setErrorMessage("Something went wrong"));
                                }

                              }}
                              className="d-none"
                            />

                            <p
                              className='special-sm-device'
                              onClick={() => {
                                setIsStateChange(content);
                                setShowDropdown(null);
                              }}
                            >
                              {content?.stage === "publish" ? "Un-publish" : "Publish"}
                            </p>
                            
                            {
                              content?.stage === "publish"
                              ? <p
                                  // className='sm-device'
                                  onClick={() => {
                                    // setUpdatableContent(content);
                                    if (content?.stage === "publish") {
                                      setUpdatableContent(content);
                                      setShowDropdown(null);
                                    } else {
                                      dispatch(setErrorMessage("Error. Please make it public"))
                                    }
                                  }}
                                >
                                  {content?.addedToBioPage ? "Remove from" : "Add to"} Biopage
                                </p>
                              : null
                            }
                            
                            {
                              content?.stage === "publish"
                              ? <>
                                  <p
                                    onClick={() => {
                                      // console.log({my_content: content})
                                      if(content?.contentUrl){
                                        navigator.clipboard.writeText(content?.contentUrl?.replaceAll('@', "/") || "Content URL N/A");
                                        dispatch(setSuccessMessage("URL Copied"));
                                        setShowDropdown(null);
                                      } else {
                                        dispatch(setErrorMessage("Error. Content URL N/A"))
                                      }
                                    }}
                                  >
                                    Copy URL
                                  </p>
                                  
                                  {
                                    content?.addedToBioPage
                                    ? <p
                                        onClick={() => {
                                        handleUpdateContent(content, "sticky");
                                        setShowDropdown(null);
                                        }}
                                      >
                                        {content?.isSticky ? "Remove" : "Make"} Sticky
                                      </p>
                                    : null
                                  }
                                  
                              </>
                              : null
                            }
                            <p
                              onClick={() => {
                                setDeleteFolderContentId(content?.id);
                                handleSelectImage(true, content?.id);
                                setShowDropdown(null);
                              }}
                            >
                              Delete
                            </p>
                          </div>
                      }
                      open={content?.id === showDropdown}
                      onOpenChange={(e: any) => {
                        if (e) {
                          setShowDropdown(content?.id);
                          setEditableContent(content);
                        } else {
                          setShowDropdown(null);
                          setEditableContent(null);
                        }
                      }}
  
                    >
                      
                      <img 
                        // onClick={() => setShowClear(!showClear)}
                        style={{ cursor: 'pointer' }}
                        src={dots}
                        alt="dots"
                      />
                      {/* <MoreOutlined /> */}
                    </Dropdown>
                  </td>

                  <td className='special-lg-md-device' style={{width: '10%'}}>
                    <img
                      style={{cursor: 'pointer'}}
                      onClick={() => setIsStateChange(content)}
                      src={content?.stage === "publish" ? global : lock}
                      alt="global-lock-icon"
                    />
                  </td>

                  <td className='special-lg-md-device' style={{width: '4%'}}>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      checked={selected_deletable_contents.some((item: any) => item === content?.id)}
                      onChange={(e) => handleSelectImage(e.target.checked, content?.id)}
                    />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
            
      </InfiniteScroll>
    </React.Fragment>
  )
}

export default SmallDeviceContents;

