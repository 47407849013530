import React, {useState, useEffect} from "react";
// import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getImage } from "../../../unified/image_service";
import { toggleGotNewSettingTeamList, setEditableTeam, requestForGettingTeamEmails, requestForDeletingTeam, getSettingTeamUserAndChannelList } from "./teamsReducer";
import CommonModalCustomWidth from "../../../components/CommonModal/CommonModalCustomWidth";
import AddTeam from "./Components/AddTeam";
import Dialog from "../../../components/Dialogs/Dialog";
import { toggleShowMobileSettingsNavBar } from "../../../signup-login/loginReducer";

const TeamList: React.FC = () => {
    const { is_got_new_setting_team_list, setting_teams: teams } = useSelector((state: any) => state?.teamState);
    const { is_show_mobile_settings_nav_bar } = useSelector((state: any) => state?.login);
    const dispatch = useDispatch();
    // const history = useNavigate();
    const [isReadyToAddTeam, setIsReadyToAddTeam] = useState<any>(false);
    const [isDeletingTeam, setIsDeletingTeam] = useState<any>(null);

    const handleCancel = () => {
        setIsReadyToAddTeam(null);
        setIsDeletingTeam(null);
    }

    const handleDeleteTeam = (teamId: any) => {
        console.log({ team_id_for_deleting: teamId });
        if (!teamId) {
            setIsDeletingTeam(null);
            return;
        }
        const workspaceId = localStorage.getItem('wuid') || sessionStorage.getItem('wuid') || null;
        const clapupUserId = localStorage.getItem('cuid') || sessionStorage.getItem('cuid') || null;
        const data_payload = {
            workspaceId,
            clapupUserId,
            teamId: teamId
        };
        dispatch(requestForDeletingTeam(data_payload));
    }
    
    const handleEditableTeam = (team: any) => {
        // console.log({ team_for_editing: team });
        dispatch(setEditableTeam(team));
        // const clapupUserId = localStorage.getItem('cuid') || sessionStorage.getItem('cuid') || null;
        const workspaceId = localStorage.getItem('wuid') || sessionStorage.getItem('wuid') || null;
        const data_payload = {
            workspaceId,
            teamId: team?.teamId
        }
        dispatch(requestForGettingTeamEmails(data_payload));
        dispatch(getSettingTeamUserAndChannelList(team?.teamId));
    }

    const handleRowClick = (team_id: number) => {
        handleEditableTeam(team_id);
    };

    useEffect(() => {
        // console.log({ setting_teams: teams, is_got_new_setting_team_list });
        if (is_got_new_setting_team_list) {
            dispatch(toggleGotNewSettingTeamList(false));
            handleCancel();
        }
    }, [is_got_new_setting_team_list]);

    return (
        <React.Fragment>
            <CommonModalCustomWidth 
                modal_visible={isReadyToAddTeam} 
                setModalVisible={setIsReadyToAddTeam}
                centered={true}
                // okText="Send"
                // cancelText="Cancel"
                padding={"0"}
                class_name="team-setting-form-container"
                footer={null}
            >
                <AddTeam handleCancel={handleCancel} />
            </CommonModalCustomWidth>

            {/* Delete Team Member */}
            <Dialog
                open={isDeletingTeam}
                parentCallback={(flag: any) => handleDeleteTeam(flag)}
                classes='dialog-container-content'
                title='Delete Team?'
                warning_dialog_content={`If you click Yes,  the team will be deleted forever. This action is irreversible.` }
                footer={true}
                left_button_text='No, Cancel'
                right_button_text='Yes, Delete'
            />
            
            <div className="button-container">
                <img className="mobile-setting-nav-bar"
                    onClick={() => dispatch(toggleShowMobileSettingsNavBar(!is_show_mobile_settings_nav_bar))}
                    src={getImage("mobile-nav-icon")}
                    alt='hum-burger'
                />
                <button onClick={() => setIsReadyToAddTeam(true)}>
                    <div>Add Team</div>
                    <img src={getImage("add-plus")} alt="invite-icon" />
                </button>
            </div>
            <div className="body-container">
                <div className="team-list-settings-container">
                    <div className="settings-header-title">
                        <h3>Teams</h3>
                    </div>
                    <div className="team-list-table">
                        <div className="table-responsive">
                            <table className="table">
                                <thead><tr><th></th><th></th><th></th><th></th></tr></thead>
                                <tbody>
                                    {teams?.map((team: any, idx: number) => (
                                        <tr key={idx}>
                                            <td>
                                                <div
                                                    className="table-row-content"
                                                    onClick={() => handleRowClick(team)}
                                                >
                                                    <div className="team-title-container">
                                                        <div className="team-icon" style={{ backgroundColor: team?.teamColor }}></div>
                                                        <div className="team-name">{
                                                            team?.teamName?.length < 17
                                                            ? team?.teamName
                                                            : team?.teamName?.substr(0, 13) + "..."
                                                        }</div>
                                                    </div>
                                                    <div>{team?.userCount} Members</div>
                                                    <div>{team?.channelCount} Channels</div>
                                                    <img
                                                        className="delete-icon"
                                                        src={getImage("delete-icon")}
                                                        alt="Delete"
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevents triggering the row click
                                                            setIsDeletingTeam(team?.teamId);
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                      </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="extra"></div>
        </React.Fragment>
    );
};

export default TeamList;