import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import Button from '../../../../CustomButton/ButtonContainer';
import { getImage } from '../../../../../unified/image_service';
import Dialog from '../../../../Dialogs/Dialog';
import { toggleModalTemplateMasterVariables, toggleForEditing, saveTemplateDetails, toggleEditableTemplate, updateTemplateDataBeforeSubmit } from '../../../reducer';
import TemplateVariablesForm from './TemplateVariableForm/TemplateVariablesForm';
import { setErrorMessage, setSuccessMessage } from '../../../../../unified/reducer';
// import Button from '../../../../button/button-container';

const ActionButtons: React.FC<any> = () => {
    const {
        toggle_modal_template_master_variables,
        toggle_for_editing, 
        toggle_template_edition, call_to_action_templates, quick_reply_buttons_template,
        is_already_saved, isEditableTemplate,
    }: any = useSelector((state: RootState) => state?.promotionsState);
    const dispatch = useDispatch();

    const handleSave = () => {
        console.log({ toggle_template_edition, call_to_action_templates, quick_reply_buttons_template });
        
        const { templateBody, templateHeader, templateHeaderType }: any = toggle_template_edition;
        if (!templateHeaderType) {
            dispatch(setErrorMessage("Please select a header option!"));
            return;
        }

        if (!templateBody?.trim()) {
            dispatch(setErrorMessage("Please fill body field!"));
            return;
        }

        if (templateHeaderType?.toLowerCase() === "text") { 
            if (!templateHeader) {
                dispatch(setErrorMessage("Please fill header field!"));
                return;
            }
        }

        // if (templateHeaderType?.toLowerCase() === "image") { 
        //     // checking .jpg and .png and .jpeg check in the templateHeader string for last time
        //     const isJPG = templateHeader?.endsWith(".jpg");
        //     const isJPEG = templateHeader?.endsWith(".jpeg");
        //     const isPNG = templateHeader?.endsWith(".png");
        //     console.log({ isJPG, isJPEG, isPNG });
        //     if (!isJPG && !isJPEG && !isPNG) {
        //         dispatch(setErrorMessage("Invalid Image URL!"));
        //         return;
        //     }
        // }
        
        // if (templateHeaderType?.toLowerCase() === "document") { 
        //     // checking .pdf check in the templateHeader string for last time
        //     const isPDF = templateHeader?.endsWith(".pdf");
        //     console.log({ isPDF });
        //     if (!isPDF) {
        //         dispatch(setErrorMessage("Invalid Document URL!"));
        //         return;
        //     }
        // }
        
        // if (templateHeaderType?.toLowerCase() === "video") { 
        //     // checking .mp4 check in the templateHeader string for last time
        //     const isMP4 = templateHeader?.endsWith(".mp4");
        //     console.log({ isMP4 });
        //     if (!isMP4) {
        //         dispatch(setErrorMessage("Invalid Video URL!"));
        //         return;
        //     }
        // }

        // Call To Action Templates should have unique text!
        const callToActionButtonTextCounts: any = {};
        let isEmptyCallToActionText: any = null;
        // Loop through the array of objects
        call_to_action_templates?.forEach((ctaObject: any) => {
            const ctaText = ctaObject?.buttonText + ctaObject?.actionType + ctaObject?.countryCode + ctaObject?.phoneNumber + ctaObject?.url + ctaObject?.urlSlug;
            const actionType = ctaObject?.actionType;
            // If ctaText is empty, set isEmptyCallToActionText to true
            if (!ctaObject?.buttonText) {
                isEmptyCallToActionText = "Button Text is required!";
                return;
            }
            if (actionType === "phoneNumber") {
                if (!ctaObject?.countryCode || !ctaObject?.phoneNumber) {
                    isEmptyCallToActionText = "Phone Number and Country Code are required!";
                    return;
                }
            } else {
                if (ctaObject?.urlType === "Static") {
                    if (!ctaObject?.url) {
                        isEmptyCallToActionText = "URL is required!";
                        return;
                    }
                } else {
                    if(!ctaObject?.url || !ctaObject?.urlSlug) {
                        isEmptyCallToActionText = "URL and URL Slug are required!";
                        return;
                    }
                }
            }
            // If ctaText already exists in the counts object, increment its count
            if (callToActionButtonTextCounts[ctaText]) {
                callToActionButtonTextCounts[ctaText]++;
            } else {
                // Otherwise, initialize count to 1
                callToActionButtonTextCounts[ctaText] = 1;
            }
        });

        // If isEmptyCallToActionText is true, show an error message
        if (isEmptyCallToActionText) {
            dispatch(setErrorMessage(isEmptyCallToActionText));
            return;
        }

        if (Object.values(callToActionButtonTextCounts)?.some((count: any) => count > 1)) { 
            dispatch(setErrorMessage("Call To Action Templates should have unique text!"));
            return;
        }

        if (call_to_action_templates?.length) { 
            const website_data = call_to_action_templates.filter((item: any) => item?.actionType !== "phoneNumber" || !item?.phoneNumber)
            if(website_data?.length) {
                const website_data_url = website_data.filter((item: any) => !item?.url || !new RegExp('^(http|https)://').test(item?.url));
                if(website_data_url?.length) {
                    dispatch(setErrorMessage("URL is required!"));
                    return;
                }
            }
        }

        // Quick Reply Buttons should have unique text!
        const replyButtonTextCounts: any = {};
        let isEmptyReplyButtonText = false;
        // Loop through the array of objects
        quick_reply_buttons_template?.forEach((buttonObject: any) => {
            const buttonText = buttonObject?.buttonText;
            // If buttonText is empty, set isEmptyReplyButtonText to true
            if (!buttonText) {
                isEmptyReplyButtonText = true;
                return;
            }
            // If buttonText already exists in the counts object, increment its count
            if (replyButtonTextCounts[buttonText]) {
                replyButtonTextCounts[buttonText]++;
            } else {
                // Otherwise, initialize count to 1
                replyButtonTextCounts[buttonText] = 1;
            }
        });

        // If isEmptyReplyButtonText is true, show an error message
        if (isEmptyReplyButtonText) {
            dispatch(setErrorMessage("Quick Reply Buttons Text Is Required!"));
            return;
        }
        
        if (Object.values(replyButtonTextCounts)?.some((count: any) => count > 1)) { 
            dispatch(setErrorMessage("Quick Reply Buttons should have unique text!"));
            return;
        }

        console.log({ toggle_template_edition, call_to_action_templates, quick_reply_buttons_template });

        // return;
        dispatch(saveTemplateDetails({
            toggle_template_edition,
            call_to_action_templates,
            quick_reply_buttons_template
        }));
        // dispatch(setSuccessMessage("Saved Successfully!"));
    };

    const { templateHeader, headerSampleData, templateHeaderType, bodySampleData, templateBody } = toggle_template_edition;
    const generateVariablesObject = (string: string, sample_string?: string ) => { 
        const regex = /{{(.*?)}}/g;
        // Set to store unique variable names
        const variableNamesSet = new Set();

        // Match variables and add their names to the set
        let match;
        while ((match = regex.exec(string)) !== null) {
            const variableName = match[1]?.trim()?.replaceAll(" ", "_");
            variableNamesSet.add(variableName); // Add variable name to the set
        }

        // Set to store unique variables with their corresponding objects
        const variablesSet = new Set();

        // Iterate over the set of variable names and construct corresponding objects
        variableNamesSet.forEach(variableName => {
            const variableObject = { variableName, variableValue: "" };
            variablesSet.add(variableObject); // Add object to the set
        });

        // Set variable values based on the variable names in the sample_string
        sample_string?.split(', ').forEach((pair: any) => {
            const [name, value] = pair?.split('=');
            variablesSet.forEach((variableObject: any) => {
                if (variableObject?.variableName === name) {
                    variableObject.variableValue = value;
                }
            });
        });
        return Array.from(variablesSet);
    };

    const handleSubmitTemplateVariables = (data?: any) => {
        const dynamic_call_to_action_website_url_object = call_to_action_templates?.find((cta: any) => cta?.urlType?.toLowerCase() === "dynamic");
        // console.log({ templateHeader, templateBody, dynamic_call_to_action_website_url_object });
        let headerVariablesArray: any = templateHeader;
        if (templateHeaderType?.toLowerCase() === "text") {
            headerVariablesArray = generateVariablesObject(templateHeader, headerSampleData || "");
        }
        const bodyVariablesArray: any = generateVariablesObject(templateBody, bodySampleData || "");
        // console.log({ headerVariablesArray, bodyVariablesArray, dynamic_call_to_action_website_url_object });

        if (!headerVariablesArray?.length && !bodyVariablesArray?.length && !dynamic_call_to_action_website_url_object) {
            const variable_payload: any = {
                templateId: toggle_template_edition?.templateId,
                workspaceId: toggle_template_edition?.workspaceId || sessionStorage.getItem('wuid'), 
                headerSampleData: "",
                bodySampleData: "",
                urlExample: "", // dynamic website url {{slug}}
                submitType: toggle_template_edition?.submitType === "insert" ? "insert" : "edit", // "edit" or "insert" and it will be taken by toggle_template_edition
            };
            console.log({ variable_payload });
            // return;
            dispatch(updateTemplateDataBeforeSubmit({toggle_template_edition, variable_payload}));
        } else {
            dispatch(toggleModalTemplateMasterVariables(true));
        }
    };

    return (
        <React.Fragment>
            {/* Submit Template Variables Modal Form */}
            <Dialog
                open={toggle_modal_template_master_variables}
                onCancel={() => dispatch(toggleModalTemplateMasterVariables(false))}
                parentCallback={(flag?: any) => dispatch(toggleModalTemplateMasterVariables(flag))}
                classes='dialog-container-commerce-form'
                // header='Add Campaign'
                closeIcon={getImage("form-close-icon")}
                footer={false}
            >
                <TemplateVariablesForm 
                    onCancel={() => dispatch(toggleModalTemplateMasterVariables(false))}
                    onParentCallback={(data?: any, file?: any) => {
                        // handleCreateNewTemplate(data, file);
                        // console.log({ data });
                        dispatch(toggleModalTemplateMasterVariables(false));
                        // dispatch(toggleTemplateEdition(true));
                        dispatch(setSuccessMessage("Submitted Successfully!"));
                        
                    }}      
                />
            </Dialog>

            {/* Edit Template Modal */}
            <Dialog
                open={toggle_for_editing}
                onCancel={() => dispatch(toggleForEditing(false))}
                parentCallback={(flag?: any) => {
                    // handleRemoveTemplate(flag);
                    dispatch(toggleForEditing(false));
                    dispatch(toggleEditableTemplate(true));
                    // dispatch(setErrorMessage("Deleted Successfully!"));
                }}
                classes='dialog-container-content'
                header='Continue?'
                warning_dialog_content={"If you continue, you have to submit for approval again. You cannot use this template until it’s approved."}
                footer={true}
                left_button_text='No, Cancel'
                right_button_text='Yes, Continue'
                // hover={true}
            />

            <div className='save-edition'>
                <div className='content-buttons'>
                    {
                        isEditableTemplate
                        ?
                            <Button
                                disabled={!toggle_template_edition?.templateId}
                                classes="mx-2 secondary-btn template-btn"
                                children={<div className='children-btn'>
                                    <img src={getImage("global-line")} alt="global-line" />
                                    <p>Submit</p>
                                </div>}
                                onClick={() => {
                                    handleSubmitTemplateVariables();
                                    // dispatch(handleSubmitTemplate(true));
                                    // old
                                    // dispatch(toggleModalTemplateMasterVariables(true));
                                }}
                            />
                        :
                        <Button
                            classes="mx-2 secondary-btn template-btn"
                            children={<div className='children-btn'>
                                <img src={getImage("edit-icon")} alt="edit-icon" />
                                <p>Edit</p>
                            </div>}
                            onClick={() => {
                                // dispatch(handleSubmitTemplate(true));
                                dispatch(toggleForEditing(true));
                            }}
                        />
                    }
                </div>
                
                <div className='content-buttons'>
                    <Button
                        classes="mx-2 secondary-btn template-btn"
                        // disabled={is_already_saved ? true : false}
                        children={<div className='children-btn'>
                            <img src={getImage("save-icon")} alt="save-icon" />
                            <p>Save</p>
                        </div>}
                        onClick={() => {
                            handleSave();
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    )
};

export default ActionButtons;
