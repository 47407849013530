import React from 'react';
import './Announcement.scss';
import { Button, DatePicker, Form, Input, TimePicker } from 'antd';
import { convertImageToBase64 } from '../../../../utils/convertingFunction';
import { useDispatch } from 'react-redux';
import { setErrorMessage } from '../../../../unified/reducer';
import moment from 'moment';
import { extractImageNameFromUrl } from '../../../../unified/image_service';

type LinkProps = {
    id?: number;
    displayTitle?: "";
    linkUrl?: "";
    announcementText?: "";
    from?: any;
    to?: any;
    fromDate?: any;
    toDate?: any;
    logoName?: '';
    logo?: any;
    link_item?: any;
    onCancel?: () => void;
    onParentCallback?: (data: any, file?: any) => void;
};

const AnnouncementForm: React.FC<LinkProps> = ({link_item, id, displayTitle, linkUrl, announcementText, from, to, logoName, logo, onCancel, onParentCallback }) => {
    const dispatch = useDispatch();
    const [curDisplayTitle, setCurDisplayTitle] = React.useState(displayTitle);
    const [curAnnouncementText, setCurAnnouncementText] = React.useState(announcementText);
    // const [curUrl, setCurUrl] = React.useState(linkUrl);
    const [startedDate, setStartedDate] = React.useState<any>(undefined);
    const [endedDate, setEndedDate] = React.useState<any>(undefined);
    const [curFromDate, setCurFromDate] = React.useState<any>(from || undefined);
    const [curToDate, setCurToDate] = React.useState<any>(to || undefined);
    const [curFromTime, setCurFromTime] = React.useState<any>(link_item?.startTime || undefined);
    const [curToTime, setCurToTime] = React.useState<any>(link_item?.endTime || undefined);
    // 
    const [curUrl, setCurUrl] = React.useState(linkUrl);
    const [image, setImage] = React.useState<any>(null);
    const handleSubmit = () => {
        const data = {
            clapupUserId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
            id: link_item?.id || 0,
            // iconUrl: image?.base64Url || logo, // we have to work in future
            displayText: curDisplayTitle,
            linkUrl: curUrl,
            logoUrl: link_item?.logoUrl || "",
            announcementText: curAnnouncementText,
            startTime: curFromTime,
            endTime: curToTime,
            startDate: curFromDate,
            endDate: curToDate,
            mode: link_item ? "update" : "insert",
            contentId: link_item?.contentId || "",
            uniqueNo: link_item?.uniqueNo || null,
        }
        console.log({link_item, data});
        onParentCallback && onParentCallback(data, image?.file);
    };

    const handleImageChange = (e: any) => {
        // file local path
        // const image_path
        const file = e.target.files[0];
        // const path = e.target.value;
        const { name } = file;
        // console.log({path});
        // setImageFile(file);
        setImage({file, name});

        // ======== 1st way to convert image to base64 ========
        // convertImageToBase64(file).then((base64Url: any) => {
        //     // console.log({base64Url});
        //     // console.log({base64Url, name});
        //     setImage({base64Url, name});
            
        // }).catch((error: any) => {
        //     console.log({ error });
        //     dispatch(setErrorMessage("Something went wrong while converting image to base64"))
        // });

        // ======== 2nd way to convert image to base64 ========
        // const formData = new FormData();
        // const body = {
        //     "code": Number(new Date().getTime() + (1000*1000)),
        //     "AccessKey": "746def78-473d-4b9a-b230976d1e62-6dd3-46de",
        //     "workspaceId": sessionStorage.getItem('wuid'),
        // }
        // formData.append('file', file);
        // formData.append('body', btoa(JSON.stringify(body)));
        // console.log({ formBody: body });
        // axios((auth_linkUrl) + "/api/v1/upload-workspace-image", {
        //     method: 'POST',
        //     data: formData,
        // }).then(response => {
        //     console.log({response});
        //     // setImageURL(response?.data?.data?.linkUrl);
        // }).catch(error => {
        //     console.log({error});
        // });
        
    }

    const handleChange = (value: any, setState: any) => {
        setState(value);
    }

    // const handleDateChange = (dateString: any, state?: any, setState?: any) => {
    //     console.log({ dateString });
    //     setState(dateString);
    // }

    const handleTimeChange = (time: any, timeString: any, state?: any, setState?: any) => {
        // console.log({ time, timeString });
        setState(timeString);
    }

    // Function to disable dates before the selected date
    const disabledFromDate = (current: any) => {
        if (!curToDate) {
        // No date selected, allow all dates
            return false;
        }
        return current && current > endedDate?.startOf('day');
    };

    // Function to disable dates after the selected date
    const disabledToDate = (current: any) => {
        if (!curFromDate) {
        // No date selected, allow all dates
            return false;
        }
        try {
            console.log({ current, curFromDate });
            
            return current && current < startedDate?.startOf('day');
        } catch (error) {
            console.log({ error });
        }
    };

    return (
        <div className='form-link-container'>
            <form
                id='link-form'
                className='form'
            >
                <div
                    className='input-group'
                >
                    <input
                        type="text" name={"displayTitle"}
                        autoFocus={true}
                        autoComplete='off'
                        autoSave='off'
                        onChange={(e: any) => setCurDisplayTitle(e.target.value)}
                        defaultValue={curDisplayTitle}
                        placeholder={"Enter the display title"}
                        
                    />
                </div>
                <div
                    className='input-group'
                >
                    <input
                        type="text" name={"linkUrl"}
                        autoComplete='off'
                        autoSave='off'
                        onChange={(e: any) => setCurUrl(e.target.value)}
                        defaultValue={curUrl}
                        placeholder={"Enter the Link URL"}
                        style={{margin: '3.5% 0'}}
                    />
                </div>

                <div
                    className='input-group'
                >
                    <input
                        type="text" name={"logo"}
                        autoComplete='off'
                        autoSave='off'
                        readOnly={true}
                        placeholder={"Upload the icon in SVG"}
                        value={image?.name ? image?.name : extractImageNameFromUrl(link_item?.logoUrl)}
                    />
                    <input
                        // disabled={image ? false : true}
                        id="image"
                        type="file"
                        accept="image/*"
                        onChange={(e: any) => {
                            // console.log({ img: e?.target?.files[0] });
                            // setImage(e?.target?.files[0]?.name);
                            handleImageChange(e); 
                        }}
                        className="form-input-field d-none w-100 my-1"
                        placeholder="Biopage Image"
                    />
                    <label className='image-label' htmlFor="image">Upload</label>
                </div>
            </form>

            <Input.TextArea
                className='form-textarea'
                rows={3}
                placeholder='Add your announcement text'
                onChange={(e: any) => handleChange(e.target.value, setCurAnnouncementText)}
                defaultValue={curAnnouncementText}
            />

            <div className='date-time-container'>
                <div className='from-date-time'>
                    <p>From</p>
                    <div className='date-time'>
                        <DatePicker
                            // onChange={(date: any, dateString: string) => handleDateChange(dateString, curFromTime, setCurFromTime)}
                            // defaultValue={curFromTime?.date ? moment(new Date(curFromTime?.date), 'YYYY-MM-DD') : undefined}
                            className='date-picker'
                            style={{width: (curFromTime || link_item?.startTime) ? "65%": "100%"}}
                            placeholder='Select the date'
                            disabledDate={disabledFromDate}
                            value={curFromDate ? moment(new Date(curFromDate), 'YYYY-MM-DD') : link_item?.startDate ? moment(new Date(link_item?.startDate), 'YYYY-MM-DD') : undefined}
                            onChange={(date: any, dateString: string) => {
                                // console.log({ date, dateString });
                                setStartedDate(date);
                                setCurFromDate(dateString);
                                // handleDateChange(dateString, curFromDate, setCurFromDate)
                            }}
                        />
                        {/* Selected Current Time */}
                        <TimePicker
                            onChange={(time: any, timeString: string) => handleTimeChange(time, timeString, curFromTime, setCurFromTime)}
                            placeholder='Time'
                            className='time-picker'
                            use12Hours format="hh:mm:ss A"
                            suffixIcon={null}
                            // defaultPickerValue={moment(new Date(), 'hh:mm:ss, A')}
                            defaultValue={link_item?.startTime ? moment(link_item?.startTime, 'hh:mm:ss, A') : curFromTime?.time ? moment((curFromTime?.time), 'hh:mm:ss, A') : undefined}
                        />

                    </div>
                </div>
                <div className='to-date-time'>
                    <p>To</p>
                    <div className='date-time'>
                        <DatePicker
                            // onChange={(date: any, dateString: string) => handleDateChange(dateString, curToTime, setCurToTime)}
                            // defaultValue={curToTime?.date ? moment(new Date(curToTime?.date), 'YYYY-MM-DD') : undefined}
                            className='date-picker'
                            style={{width: curToTime || link_item?.endTime ? "65%": "100%"}}
                            placeholder='Select the date'
                            disabledDate={disabledToDate}
                            value={curToDate ? moment(new Date(curToDate), 'YYYY-MM-DD') : link_item?.endDate ? moment(new Date(link_item?.endDate), 'YYYY-MM-DD') : undefined }
                            onChange={(date: any, dateString: string) => {
                                // console.log({ date, dateString });
                                setEndedDate(date);
                                setCurToDate(dateString);
                                // handleDateChange(dateString, curToTime, setCurToTime)
                            }}
                        />
                        {/* Selected Current Time */}
                        <TimePicker
                            onChange={(time: any, timeString: string) => handleTimeChange(time, timeString, curToTime, setCurToTime)}
                            placeholder='Time'
                            className='time-picker'
                            use12Hours format="hh:mm:ss A"
                            defaultPickerValue={moment(new Date(), 'hh:mm:ss, A')}
                            suffixIcon={null}
                            // defaultPickerValue={moment(new Date(), 'hh:mm:ss, ')}
                            defaultValue={link_item?.endTime ? moment(link_item?.endTime, 'hh:mm:ss, A') :  curToTime?.time ? moment((curToTime?.time), 'hh:mm:ss, A') : undefined}
                        />

                    </div>
                </div>
            </div>

            <div className='form-button-container'>
                <button
                    type='button'
                    className='cancel-button'
                    onClick={onCancel}
                >
                    { id ? "Cancel" : "No, Cancel" }
                </button>
                <button
                    disabled={
                        link_item || (curDisplayTitle && curUrl && curAnnouncementText && (curFromDate && curFromTime) && (curToDate && curToTime))
                        ? false : true
                    }
                    className='create-button'
                    onClick={() => handleSubmit()}
                    type='button'
                >
                    { id ? "Save" : "Yes, Create" }
                </button>
            </div>

        </div>
    )
};

export default AnnouncementForm;
