import Constants from "../../constants";

const initialState = {
    // bio-page system folders
    bio_page_folders: [],
    // folder
    is_showing_create_folder: false,
    all_folders: [],
    folderNextDate: null,
    // content
    updatable_content: {
        clapupUserId: sessionStorage.getItem('cuid'),
        workspaceId: sessionStorage.getItem('wuid'),
        roleId: sessionStorage.getItem('roleId'),
        contentId: "",
        properties_Keywords: "",
        sEO_UrlSlug: "",
        sEO_Desc: "",
        sEO_Title: "",
        bioPage_Caption: "",
        bioPage_Title: "",
        bioPage_Story: ""
    },
    show_uploading_progress: false,
    uploadingProgressCount: 0,
    folder_contents: [],
    contentNextDate: null,
    is_reverted_version_successfully: false,
    is_updated_folder_content: false,
    selected_deletable_contents: [],
    content_versions: {
        currentVersion: {},
        previousVersions: []
    },
    default_content_idx: null,
    showing_save_button: true,
    is_content_uploading_error: false,
    delete_message: null,
    folder_sort_type: null,
    content_sort_type: null,
}

export const setUpdatableContentBeforeSaving = (data?: any) => {
    return {
        type: "SET_UPDATABLE_CONTENT_BEFORE_SAVING",
        payload: {
            clapupUserId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
            ...data
        },
    }
};

export const updateContentBeforeSaving = (data?: any) => {
    return {
        type: "UPDATE_CONTENT_BEFORE_SAVING",
        payload: {
            key: data.key,
            value: data.value
        },
    }
};

export const saveContentDetails = (data?: any, content_idx?: any) => {
    return {
        type: Constants.SAVE_CONTENT_DETAILS,
        payload: { data, content_idx },
    }
};

export const setContentDetails = (data?: any, content_idx?: any) => {
    return {
        type: Constants.SET_CONTENT_DETAILS,
        payload: { data, content_idx },
    }
};

export const setIsContentUploadedError = (data: any) => {
    return {
        type: "SET_IS_CONTENT_UPLOADED_ERROR",
        payload: data
    }
}

export const setUploadProgressCount = (data: any) => {
    return {
        type: "SET_UPLOADING_PROGRESS_COUNT",
        payload: data
    }
}

export const setShowingUploadingProgress = (data: any) => {
    return {
        type: "SET_SHOW_UPLOADING_PROGRESS",
        payload: data
    }
}

export const setShowingSaveButton = (data: any) => {
    return {
        type: "SET_SHOWING_SAVE_BUTTON",
        payload: data
    }
}

// ======================= Bio Page Folder - Start =====================
export const getBioPageFolders = (data?: any) => {
    return {
        type: Constants.GET_BIO_PAGE_FOLDERS,
        payload: {
            clapupUserId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
            createdOn: new Date().toISOString().replace('Z', ''),
            isFolder: true,
            folderPath: ""
        }
    }
};

export const setBioPageFolders = (data?: any) => {
    return {
        type: Constants.SET_BIO_PAGE_FOLDERS,
        payload: data
    }
};

// ======================= Bio Page Folder - End =====================
// ======================= Folder - Start =====================

export const toggleCreateFolderModal = (data: boolean) => {
    return {
        type: "TOGGLE_CREATE_FOLDER_MODAL",
        payload: data
    }
}

export const createContentFolder = (data: any, folderNextDate: any) => {
    return {
        type: Constants.CREATE_FOLDER,
        payload: data,
        folderNextDate,
    };
}

export const addNewFolder = (data: any) => {
    return {
        type: Constants.ADD_NEW_FOLDER,
        payload: data
    }
};

export const getFirstTimeFolders = (data?: any) => {
    return {
        type: Constants.GET_FIRST_TIME_FOLDERS,
        payload: {
            clapupUserId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
            createdOn: new Date().toISOString().replace('Z', ''),
            isFolder: true,
            folderPath: ""
        }
    }
}

export const setFirstTimeFolders = (data: any) => {
    return {
        type: Constants.SET_FIRST_TIME_FOLDERS,
        payload: data
    }
}

export const getFolders = (data?: any) => {
    return {
        type: Constants.GET_FOLDERS,
        payload: {
            clapupUserId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
            createdOn: data?.nextDate,
            isFolder: true,
            folderPath: ""
        }
    }
};

export const setFolders = (data?: any) => {
    return {
        type: Constants.SET_ALL_FOLDERS,
        payload: data
    }
};

// it will be taken last
export const deleteFolders = (folderIds?: any) => {
    return {
        type: Constants.DELETE_FOLDERS,
        payload: {
            payload_object: {
                clapupUserId: sessionStorage.getItem('cuid'),
                workspaceId: sessionStorage.getItem('wuid'),
                roleId: sessionStorage.getItem('roleId'),
                // comma separated string content ids to delete
                contentId: folderIds?.map((id: any) => id).join(','),
                isFolderDelete: true,
            },
            folder_ids: folderIds
        }
    }
};

export const removeFolders = (data?: any, message = "Deleted Successfully") => {
    return {
        type: Constants.REMOVE_FOLDERS,
        payload: data,
        message
    }
};

export const setSearchFolderText = (data?: any) => {
    return {
        type: Constants.SET_SEARCH_FOLDER_TEXT,
        payload: data
    }
};

// =================== Folder - End   ====================

// =================== Content (video / image) - Start ====================

export const setSearchContentText = (data: any) => {
    return {
        type: Constants.SET_SEARCH_CONTENT_TEXT,
        payload: data
    }
}

export const setDefaultContentIdx = (data: any) => {
    return {
        type: Constants.SET_DEFAULT_CONTENT_INDEX,
        payload: data,
    }
}

export const setSelectedDeletableContents = (data: any) => {
    return {
        type: Constants.SET_SELECTED_DELETABLE_CONTENTS,
        payload: data
    }
}

export const getOneTimeFolderContents = (data?: any) => {
    return {
        type: Constants.GET_ONE_TIME_FOLDER_CONTENTS,
        payload: {
            clapupUserId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
            createdOn: new Date().toISOString().replace('Z', ''),
            isFolder: false,
            folderPath: data?.folderName || ""
        }
    }
};

export const setOneTimeFolderContents = (data: any) => {
    return {
        type: Constants.SET_ONE_TIME_FOLDER_CONTENTS,
        payload: data
    }
};

// Scrolling to get more content data
export const getFolderContents = (data?: any) => {
    return {
        type: Constants.GET_FOLDER_CONTENTS,
        payload: {
            clapupUserId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
            createdOn: data?.nextDate,
            isFolder: false,
            folderPath: data?.folderName || ""
        }
    }
};

export const setFolderContents = (data?: any) => {
    return {
        type: Constants.SET_FOLDER_CONTENTS,
        payload: data
    }
};


// content

export const setSortFolderListSortType = (data?: any) => {
    return {
        type: "SET_SORTING_FOLDER_LIST_TYPE",
        payload: data
    }
}

export const setSortContentListSortType = (data?: any) => {
    return {
        type: "SET_SORTING_CONTENT_LIST_TYPE",
        payload: data
    }
}

export const createFolderContent = (data?: any, file?: any) => {
    return {
        type: Constants.CREATE_FOLDER_CONTENT,
        payload: data,
        contentFile: file,
    }
};

export const updateFolderContentNewVersion = (data?: any, file?: any, content_data?: any) => {
    return {
        type: Constants.UPDATE_FOLDER_CONTENT_NEW_VERSION,
        payload: data,
        contentFile: file,
        content_data
    }
};

export const uploadContentFileByAwsLink = (data?: any) => {
    return {
        type: Constants.AWS_PRE_ASSIGN_LINK,
        payload: data
    }
}

export const setFolderContent = (data?: any) => {
    return {
        type: Constants.SET_FOLDER_CONTENT,
        payload: data
    }
};

export const setIsUpdatedContent = (data?: any) => {
    return {
        type: "SET_IS_UPDATED_CONTENT",
        payload: data
    }
};

export const updateFolderContent = (payloadObject?: any, data?: any) => {
    return {
        type: Constants.UPDATE_FOLDER_CONTENT,
        payload: payloadObject,
        data
    }
};

export const setUpdateFolderContent = (data?: any) => {
    return {
        type: Constants.SET_UPDATE_FOLDER_CONTENT,
        payload: data
    }
};


export const deleteContents = (contentIds?: any) => {
    return {
        type: Constants.DELETE_FOLDER_CONTENTS,
        payload: {
            payload_object: {
                clapupUserId: sessionStorage.getItem('cuid'),
                workspaceId: sessionStorage.getItem('wuid'),
                roleId: sessionStorage.getItem('roleId'),
                // comma separated string content ids to delete
                contentId: contentIds?.map((id: any) => id).join(','),
                isFolderDelete: false,
            },
            content_ids: contentIds
        }
    }
};

export const removeContentsFromFolder = (data?: any, message = "Deleted Successfully") => {
    return {
        type: Constants.REMOVE_CONTENTS_FROM_FOLDER,
        payload: data,
        message,
    }
};

export const showDeleteMessage = (data?: any) => {
    return {
        type: "REMOVE_DELETE_MESSAGE",
        payload: data
    }
};

export const getContentVersions = (data?: any) => {
    return {
        type: Constants.GET_CONTENT_VERSIONS,
        payload: data
    }
};

export const setContentVersions = (data?: any) => {
    return {
        type: Constants.SET_CONTENT_VERSIONS,
        payload: data
    }
};

export const requestToRevertVersion = (data?: any) => {
    return {
        type: Constants.REQUEST_TO_REVERT_VERSION,
        payload: data
    }
};

export const setRevertVersionSuccessfully = (data?: any) => {
    return {
        type: "SET_REVERT_VERSION_SUCCESSFULLY",
        payload: data
    }
};



// =================== Content (video / image) - End ====================

const mediaManagementReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case Constants.SET_SEARCH_FOLDER_TEXT:
            return { ...state, searched_folder_text: action.payload };
        case Constants.SET_SEARCH_CONTENT_TEXT:
            return { ...state, searched_content_text: action.payload };

        case Constants.SET_CONTENT_DETAILS:
            const { data, content_idx } = action.payload;
            const { folder_contents } = state;
            const updatedFolderContents = [...folder_contents];
            updatedFolderContents[content_idx] = { ...updatedFolderContents[content_idx], ...data };
            return { ...state, folder_contents: updatedFolderContents };

        case "SET_UPDATABLE_CONTENT_BEFORE_SAVING":
            return { ...state, updatable_content: action.payload };

        case "UPDATE_CONTENT_BEFORE_SAVING":
            return {
                ...state,
                updatable_content: {
                    ...state.updatable_content,
                    [action.payload.key]: action.payload.value
                }
            };

        case Constants.SET_DEFAULT_CONTENT_INDEX:
            return { ...state, default_content_idx: action.payload };

        case Constants.SET_SELECTED_DELETABLE_CONTENTS:
            return {
                ...state,
                selected_deletable_contents: action.payload
            };

        case Constants.SET_ONE_TIME_FOLDER_CONTENTS:
            return { ...state, folder_contents: action.payload?.contents, contentNextDate: action.payload?.contentNextDate };

        case Constants.SET_FOLDER_CONTENTS:
            return { ...state, folder_contents: [...state.folder_contents, ...action.payload?.contents], contentNextDate: action.payload?.contentNextDate };

        case "SET_SORTING_FOLDER_LIST_TYPE":
            return {
                ...state,
                folder_sort_type: action.payload,
            }

        case "SET_SORTING_CONTENT_LIST_TYPE":
            return {
                ...state,
                content_sort_type: action.payload,
            }
        case Constants.SET_FOLDER_CONTENT:
            return { ...state, folder_contents: [action.payload, ...state.folder_contents] };

        case Constants.SET_UPDATE_FOLDER_CONTENT:
            return {
                ...state,
                folder_contents: state?.folder_contents?.map((item: any) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }
                    return item;
                })
            };

        case Constants.REMOVE_CONTENTS_FROM_FOLDER:
            return {
                ...state,
                // folder_contents: state.folder_contents.filter((item: any) => item.id !== action.payload)
                // payload is an array of id
                folder_contents: state.folder_contents.filter((item: any) => !action.payload.includes(item.id)),
                selected_deletable_contents: [],
                delete_message: action.message,
            };

        case Constants.REMOVE_FOLDERS:
            // return { ...state, all_folders: state.all_folders.filter((item: any) => item.id !== action.payload) };
            return {
                ...state,
                all_folders: state.all_folders.filter((item: any) => !action.payload.includes(item.id)),
                delete_message: action.message,
            };

        case Constants.ADD_NEW_FOLDER:
            return { ...state, all_folders: [action.payload, ...state.all_folders] }

        case Constants.SET_FIRST_TIME_FOLDERS:
            return { ...state, all_folders: action.payload?.folders, folderNextDate: action.payload?.contentNextDate };

        case Constants.SET_ALL_FOLDERS:
            return {
                ...state,
                all_folders: [...action?.payload?.folders, ...state?.all_folders],
                folderNextDate: action?.payload?.folderNextDate
            };

        case Constants.SET_BIO_PAGE_FOLDERS:
            return { ...state, bio_page_folders: action.payload.folders, bio_page_folder_nextDate: action.payload.folderNextDate };

        case "SET_IS_UPDATED_CONTENT":
            return { ...state, is_updated_folder_content: action.payload }

        case "SET_IS_CONTENT_UPLOADED_ERROR":
            return { ...state, is_content_uploading_error: action.payload }

        case "TOGGLE_CREATE_FOLDER_MODAL":
            return { ...state, is_showing_create_folder: action.payload }

        case "SET_SHOWING_SAVE_BUTTON":
            return { ...state, showing_save_button: action.payload }

        case "SET_SHOW_UPLOADING_PROGRESS":
            return { ...state, show_uploading_progress: action.payload }

        case "SET_UPLOADING_PROGRESS_COUNT":
            return { ...state, uploadingProgressCount: action.payload }

        case "REMOVE_DELETE_MESSAGE":
            return { ...state, delete_message: null }
        case Constants.SET_CONTENT_VERSIONS:
            return { ...state, content_versions: action.payload }
        case "SET_REVERT_VERSION_SUCCESSFULLY":
            return { ...state, is_reverted_version_successfully: action.payload }
        case Constants.LOGOUT:
            return {
                ...state,
                bio_page_folders: [],
                all_folders: [],
                folderNextDate: null,
                folder_contents: [],
                contentNextDate: null,
                is_reverted_version_successfully: false,
                updatable_content: {
                    clapupUserId: sessionStorage.getItem('cuid'),
                    workspaceId: sessionStorage.getItem('wuid'),
                    roleId: sessionStorage.getItem('roleId'),
                    contentId: "",
                    properties_Keywords: "",
                    sEO_UrlSlug: "",
                    sEO_Desc: "",
                    sEO_Title: "",
                    bioPage_Caption: "",
                    bioPage_Title: "",
                    bioPage_Story: ""
                },
                is_updated_folder_content: false,
                selected_deletable_contents: [],
                content_versions: {
                    currentVersion: {},
                    previousVersions: []
                },
                default_content_idx: null,
                showing_save_button: true,
                show_uploading_progress: false,
                is_showing_create_folder: false,
                is_content_uploading_error: false,
                uploadingProgressCount: 0,
                delete_message: null,
                folder_sort_type: null,
                content_sort_type: null,
            };
        default:
            return state;
    }
}

export default mediaManagementReducer;