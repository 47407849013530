import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { getImage } from "../../../../unified/image_service";
import { DebounceInput } from "react-debounce-input";
import { toggleUpdatedTeamContent,requestForAddingTeamChannel } from "../teamsReducer";

const AddChannel: React.FC<any> = ({handleCancel}: any) => {
    const { is_updated_team_content, editable_team, setting_channels, team_channels } = useSelector((state: any) => state?.teamState);
    const dispatch = useDispatch();
    const [selectedTeamChannels, setSelectedTeamChannels] = useState<any>([]);
    const [channels, setChannels] = useState<any>(setting_channels);
    // const [channels, setChannels] = useState<any>([
    //     { id: 1, channelName: "Sreekanth", emailId: "sreekanth850@gmail.com", isSelected: false },
    //     { id: 2, channelName: "MMHK", emailId: "mmhk@gmail.com", isSelected: true },
    //     { id: 3, channelName: "Daina", emailId: "daina@gmail.com", isSelected: false },
    //     { id: 1, channelName: "Sreekanth", emailId: "sreekanth850@gmail.com", isSelected: false },
    //     { id: 2, channelName: "MMHK", emailId: "mmhk@gmail.com", isSelected: true },
    //     { id: 3, channelName: "Daina", emailId: "daina@gmail.com", isSelected: false },
    //     { id: 1, channelName: "Sreekanth", emailId: "sreekanth850@gmail.com", isSelected: false },
    //     { id: 2, channelName: "MMHK", emailId: "mmhk@gmail.com", isSelected: true },
    //     { id: 3, channelName: "Daina", emailId: "daina@gmail.com", isSelected: false },
    //     { id: 1, channelName: "Sreekanth", emailId: "sreekanth850@gmail.com", isSelected: false },
    //     { id: 2, channelName: "MMHK", emailId: "mmhk@gmail.com", isSelected: true },
    //     { id: 3, channelName: "Daina", emailId: "daina@gmail.com", isSelected: false },
    //     { id: 1, channelName: "Sreekanth", emailId: "sreekanth850@gmail.com", isSelected: false },
    //     { id: 2, channelName: "MMHK", emailId: "mmhk@gmail.com", isSelected: true },
    //     { id: 3, channelName: "Daina", emailId: "daina@gmail.com", isSelected: false },
    // ]);
    useEffect(() => {
        // console.log({ team_channels, setting_users });
        if (team_channels?.length) {
            const selected_channels = team_channels?.map((channel: any) => channel?.channelId);
            setSelectedTeamChannels(selected_channels);
        }
    }, [editable_team?.teamId]);

    useEffect(() => {
        if (is_updated_team_content) {
            dispatch(toggleUpdatedTeamContent(false));
            handleCancel();
        }
    }, [is_updated_team_content]);
    
    const handleAdd = () => {
        // if (!selectedTeamChannels?.length) {
        //     dispatch(setErrorMessage("Please select a channel"));
        //     return;
        // }
        const workspaceId = localStorage.getItem('wuid') || sessionStorage.getItem('wuid') || null;
        // const roleId = localStorage.getItem('roleId') || sessionStorage.getItem('roleId') || null;
        const data_payload = {
            workspaceId: workspaceId || "",
            channelIds: selectedTeamChannels.join(',') || "",
            teamId: editable_team?.teamId,
        }
        console.log("Came for adding member...", { data_payload });
        dispatch(requestForAddingTeamChannel(data_payload));
    }
    
    const handleSelectTeamChannels = (id: any) => {
        const already_selected = selectedTeamChannels?.find((channel_id: any) => channel_id === id);
        // console.log({ already_selected, selectedTeamChannels, id });
        if (already_selected) {
            const filteredSelectedTeamChannels = selectedTeamChannels?.filter((channel_id: any) => channel_id !== id);
            setSelectedTeamChannels(filteredSelectedTeamChannels);
        } else {
            const channels = [...selectedTeamChannels, id];
            setSelectedTeamChannels(channels);
        }
    }

    const handleSearchForTableColumn = (value: any) => {
        // console.log("value ==== ", value);
        const isEmailCheck = value?.includes('@');
        // const isNameCheck = value.includes(' ');
        // const isNumberCheck = value.includes('0') || value.includes('1') || value.includes('2') || value.includes('3') || value.includes('4') || value.includes('5') || value.includes('6') || value.includes('7') || value.includes('8') || value.includes('9');
        if(isEmailCheck){
            const data = setting_channels?.filter((item: any) => item?.emailId?.includes(value));
            setChannels(data);
            
        } else{
            const data = setting_channels?.filter((item: any) => {
                return item?.channelName?.toLowerCase().includes(value?.toLowerCase());
            });
        
            if(!data?.length){
                const data = setting_channels?.filter((item: any) => item?.emailId?.includes(value));
                setChannels(data);
            }else{
                setChannels(data);
            }
        }
    }
    return (
        <React.Fragment>
            <div className="add-channel-setting-table-form">
                <h3>Add Channels</h3>
                <div className="settings-form-item">
                    <DebounceInput
                        type="text" 
                        placeholder={"Search channels to add to team"}
                        minLength={0}
                        debounceTimeout={1000}
                        onChange={(event: any) => handleSearchForTableColumn(event.target.value)} 
                    />
                </div>

                <h4>Available Channels</h4>
                <div className="channel-table">
                    <div className='table-responsive'>
                        <table className="table m-0">
                            <thead className="d-none"><tr><th>Email</th><th>Name</th><th></th></tr></thead>
                            <tbody>
                                {channels?.map((channel: any, idx: number) => (
                                <tr key={idx}>
                                    <td>
                                        <img src={getImage("gmail-icon-circle")} alt="channel" />
                                        <div>{channel?.emailId}</div>
                                    </td>
                                    <td>
                                        <img
                                            src={getImage(
                                                selectedTeamChannels?.find((channel_id: any) => channel_id === channel?.channelId )
                                                ? "checked-icon"
                                                : "un-checked-icon"
                                            )}
                                            alt="check-uncheck-icon"
                                            style={{ cursor: 'pointer' }} 
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevents triggering the row click
                                                handleSelectTeamChannels(channel?.channelId);
                                            }}
                                        />
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="action-buttons">
                    <div
                        className="text-button"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </div>
                    <div
                        className="text-button"
                        onClick={() => handleAdd()}
                    >
                        Add
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddChannel;