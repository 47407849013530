import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from '../../store';
import { changePage, setGlobalMessageType } from '../../unified/reducer';
import { useDispatch } from 'react-redux';
import { getImage } from '../../unified/image_service';

const RightBottomMessagesTabs = () => {
    const route_path = window.location.pathname;
    const query_string = window.location.search;
    const {isReplyingOn, pageRoute, global_message_type}: any = useSelector((state: RootState) => state?.unified);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (route_path?.includes("messages")) {
            sessionStorage.setItem('message_type', "whats-app-messages");
            // localStorage.setItem('message_type', tab.key);
            dispatch(setGlobalMessageType("whats-app-messages"));
        }
        
        if (route_path.includes("whatsapp")) {
            sessionStorage.setItem('message_type', "whatsapp");
            // localStorage.setItem('message_type', tab.key);
            dispatch(setGlobalMessageType("whatsapp"));
        }
    }, [route_path])
    
    const messages_tabs: any[] = [
        {
            name: "Whatsapp",
            route: "whatsapp",
            key: "whats-app-messages",
            icon: "right-side-message",
        },
        {
            name: "AI Bot",
            route: "messages",
            key: "bot-messages",
            icon: "right-side-bot",
        }
    ];

    const current_message_type = sessionStorage.getItem('message_type');
    useEffect(() => {
        if (!global_message_type && !current_message_type) {
            sessionStorage.setItem('message_type', 'whats-app-messages');
            // localStorage.setItem('message_type', 'whats-app-messages');
            // sessionStorage.setItem('message_type', 'bot-messages');
            dispatch(setGlobalMessageType("whats-app-messages"));
        } else {
            const found_message_type = messages_tabs.find((tab: any) => (tab.key === global_message_type || tab.key === current_message_type));
            if (!found_message_type) {
                sessionStorage.setItem('message_type', 'whats-app-messages');
                // localStorage.setItem('message_type', 'whats-app-messages');
                dispatch(setGlobalMessageType("whats-app-messages"));
            } else {
                setGlobalMessageType(global_message_type || current_message_type);
                dispatch(setGlobalMessageType(global_message_type || current_message_type));
            }
        }
    }, [global_message_type, current_message_type]);

    return (
        <React.Fragment>
            <div className="right-tab-messages">
                {
                    messages_tabs?.map((tab: any, index: number) => {
                        return (
                            <div key={index}>
                                <button
                                    title={tab?.name}
                                    onClick={() => {
                                        console.log("Reply: ", tab.key);
                                        // sessionStorage.setItem('message_type', tab.key);
                                        // // localStorage.setItem('message_type', tab.key);
                                        // dispatch(setGlobalMessageType(tab.key));
                                        isReplyingOn
                                        ? dispatch(changePage(`/${tab.route}?all=conversations`))
                                        : navigate(`/${tab.route}?all=conversations`);
                                    }}
                                    // className={`inbox-button${(global_message_type === tab.key) ? "-active" : ""}`}
                                    className={`inbox-button${(route_path?.includes(tab.route)) ? "-active" : ""}`}
                                    type="button"
                                >
                                    {/* Image */}
                                    <img src={getImage(tab?.icon)} alt={tab?.name} />
                                </button>
                                <div className='btn-title'>{ tab?.name }</div>
                            </div>
                        );
                    })
                }

            </div>
            {/* Mobile - Tabs */}
            {
                !query_string?.includes('cov=')
                ?
                <div className="bottom-tab-messages">
                    {
                        messages_tabs.map((tab: any, index: number) => {
                            return (
                                <div key={index} className='btn-container'>
                                    <button
                                        title={tab?.name}
                                        onClick={() => {
                                            // sessionStorage.setItem('message_type', tab.key);
                                            // // localStorage.setItem('message_type', tab.key);
                                            // dispatch(setGlobalMessageType(tab.key));
                                            // isReplyingOn
                                            // ? dispatch(changePage("/messages?all=conversations"))
                                            // : navigate("/messages?all=conversations");
                                            isReplyingOn
                                            ? dispatch(changePage(`/${tab.route}?all=conversations`))
                                            : navigate(`/${tab.route}?all=conversations`);
                                        }}
                                        // className={`inbox-button${(global_message_type === tab.key) ? "-active" : ""}`}
                                        className={`inbox-button${(route_path?.includes(tab.route)) ? "-active" : ""}`}
                                        type="button"
                                    >
                                        {/* Image */}
                                        <img src={getImage(tab?.icon)} alt={tab?.name} />
                                    </button>
                                    <div className='btn-title'>{ tab?.name }</div>
                                </div>
                            );
                        })
                    }
                    {/* <button
                        onClick={() => {
                            // navigate("/unified?all=conversations");
                            // isReplyingOn
                            // ? dispatch(changePage("/unified?all=conversations"))
                            // : navigate("/unified?all=conversations");
                        }}
                        className={`inbox-button${
                            (query_string?.includes("conversation")) ? "-active" : ""
                        }`}
                        type="button"
                    }
                    {/* <button
                        onClick={() => {
                            // navigate("/unified?all=conversations");
                            // isReplyingOn
                            // ? dispatch(changePage("/unified?all=conversations"))
                            // : navigate("/unified?all=conversations");
                        }}
                        className={`inbox-button${
                            (query_string?.includes("conversation")) ? "-active" : ""
                        }`}
                        type="button"
                    >
                        WH
                    </button>
                
                    <button
                        onClick={() => {
                            // navigate("/unified?all=wall");
                            // isReplyingOn
                            // ? dispatch(changePage("/unified?all=wall")) 
                            // : navigate("/unified?all=wall")
                        }}
                        className={`inbox-button${
                            (query_string?.includes("wall")) ? "-active" : ""
                        }`}
                        type="button"
                    >
                        Bot
                    </button> */}

                </div>
                : null
            }
        </React.Fragment>
    );
};

export default RightBottomMessagesTabs;