// import React from 'react';
import Routing from './Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css'; // Import Quill Snow theme
import 'react-quill/dist/quill.bubble.css'; // Import Quill Bubble theme
import Success from './components/Notifications/Success';
import Error from './components/Notifications/Error';
import './App.scss';
// import UnPermission from './pages/un-permission/UnPermission';

const App = () => {
  // const { userPermissions } = useSelector((state: any) => state?.login);
  // const [isPermissible, setIsPermissible] = React.useState(true);
  // React.useEffect(() => {
  //     const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "dashboard") : null;
  //     if( permission?.value === 0){
  //         setIsPermissible(false);
  //     }
  // }, [userPermissions?.length]);
  return (
    <div className="App">
      <Routing />
      <Success />
      <Error />
    </div>
  );
}

export default App;
