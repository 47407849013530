import React from 'react';
// import './dashboard.scss';
import Promotions from '../components/Promotions/Promotions';
import RightBottomCommerceTabs from '../components/Layout/RightBottomCommerceTabs';
import { useSelector } from 'react-redux';
import UnPermission from '../pages/un-permission/UnPermission';

const CampaignTemplate = () => {
    const { userPermissions } = useSelector((state: any) => state?.login);
    const [isPermissible, setIsPermissible] = React.useState(true);
    React.useEffect(() => {
        // console.log({ userPermissions });
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "Promotions") : null;
        if( permission?.value === 0){
            setIsPermissible(false);
        }
    }, [userPermissions?.length]);
    return (
        <React.Fragment>
            {
                isPermissible
                ?
                <>
                    {/* <TopTabs/> */}
                    <RightBottomCommerceTabs />
                    <Promotions />
                </>
                : <>
                    {/* <TopTabs/> */}
                    <UnPermission/>
                </>
            
            }
        </React.Fragment>
    )
}

export default CampaignTemplate;