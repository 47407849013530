import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getImage } from "../../../../unified/image_service";
import queryString from 'query-string';
// import CommonModalCustomWidth from "../../../../components/CommonModal/CommonModalCustomWidth";
// import PageSelectionTable from "./PageSelectionTable";
import { RootState } from "../../../../store";
import { setIntegrationFacebookPageList, toggleShowFbPageMapModal } from "../../WorkspaceSettings/workspaceReducer";
import { fb_app_id } from "../../../../utils/server";
import { triggerFacebookConnection } from "../../../../signup-login/loginReducer";
declare global {
    interface Window {
      FB: any; // Assuming 'FB' has any type
      fbAsyncInit: () => void;
    }
  }

const IntegrationFacebook: React.FC = () => {
    const dispatch = useDispatch();
    // const { personalInfo } = useSelector((state: any) => state?.profileManageSettingState);
    // const { integration_tab } = useSelector((state: any) => state?.integrationState);
    const history = useNavigate();
    const query_string = window.location.search;
    const {
        fb_page_list,
        facebook_connected_mapped_pages,
        show_fb_page_map_modal
    } = useSelector((state: any) => state?.workspaceManagementState);
    // const twitterRequestToken = useSelector((state: RootState) => state?.login?.requestTokens);
    const {
        integration_type,
        facebook_connection_data,
        integrationConnections,
        // userPermissions,
    }: any = useSelector((state: RootState) => state?.login);

    const fb_connection_flag = integrationConnections?.length > 1 && integrationConnections[2]?.UserId;

    const [isFBConnected, setIsFBConnected] = useState<any>(false);
    useEffect(() => { 
        console.log({ fb_connection_flag });
        if (fb_connection_flag) {
            setIsFBConnected(true);
        } else {
            setIsFBConnected(false);
        }
    }, [fb_connection_flag]);

    // const handleCancel = () => {
    //     setReadyToSelectGoogleLocation(false);
    // }
    // const handleTabChange = (tab?: any) => {
    //     dispatch(setIntegrationTab(tab));
    // }

    // FB all page list
    useEffect(() => {
        if (facebook_connection_data?.facebookUserId) {
          // integration_type === "facebook" && setFacebookModalVisible(true);
          const { pageDetails } = facebook_connection_data?.integrationDetails;
          dispatch({ type: "SET_INTEGRATION_TYPE", payload: "" });
          if (!pageDetails?.length) {
            return;
          }
          console.log({facebook_connection_data, integration_type});
    
          const fb_page_list = pageDetails?.map((item: any, idx: number) => {
            // facebook_connection_data?.map((item: any, idx: number) => {
              return {
                ...item,
                id: item?.pageid,
                idx: idx + 1,
              };
          }) || [];

          // setFacebookModalVisible(true);
          // setFacebookModalVisible(true);
          dispatch(setIntegrationFacebookPageList(fb_page_list));
          // get_integration_connections();
        }
    
    }, [dispatch, facebook_connection_data?.facebookUserId]);
    
    // FB connection credential
    useEffect(() => {
        try{
            // const query = query_string.replace('?ws_settings=', '');
            // console.log({ connection_type });
            // console.log({path, query});
            // const { access_token, code }: any = queryString.parse(
            const { code }: any = queryString.parse(
                window.location.href.split("?")[1]
            );
            if (code) {
                console.log({ code });
                // const redirectUri = `${window.location.origin}/workspace/integrations`;
                const redirectUri = `${window.location.origin}/settings/integrations`;
                const workspaceId = localStorage.getItem("wuid") || sessionStorage.getItem("wuid")|| null;
                const clapupUserId = localStorage.getItem("cuid") || sessionStorage.getItem("cuid")|| null;
                if (!workspaceId || !clapupUserId) {
                    return;
                }
                const dataObj: any = {
                    code,
                    redirectUri: encodeURIComponent(redirectUri),
                    workspaceId,
                    clapupUserId,
                };
                console.log({ dataObj });
                dispatch(triggerFacebookConnection(dataObj));
                // dispatch(toggleShowFbPageMapModal(true));
                history("/settings/integrations");
            }
        } catch (err: any) {
            console.log("Error fb connection: ", err);
        }
    }, [dispatch, query_string]);
    
      // FB connection event function
      const handleFacebookConnect = () => {
        sessionStorage.setItem("isLoading", "true");
        sessionStorage.setItem("app_connect", "facebook-connection");
        // console.log("Connect to Facebook");
        // setIntegrationType("facebook-connection");
    
        // const redirectUri = `${window.location.origin}/workspace/integration/facebook`;
        const redirectUri = `${window.location.origin}/settings/integrations`;
        // const redirectUri = `${window.location.origin}/workspace?ws_settings=integrations`;
        console.log({ redirectUri });
        // axios.get(api_url + `/api/v1.2/facebook/FacebookAuthUrl?redirectUrl=${redirectUri}`)
        // .then((res: any) => {
        //   if(res?.data) {
        //     window.location.href = res?.data;
        //     // window.location.href = `https://www.facebook.com/v12.0/dialog/oauth?app_id=1133792337115010&redirect_uri=${redirectUri}&response_type=token,signed_request,graph_domain&sdk=joey&version=v2.3&scope=email`;
        //   }
        // })
    
        const appId = fb_app_id || "254750246571176";
        // const configId = "3387082161438539";
        // const fb_auth_url = `https://www.facebook.com/v12.0/dialog/oauth?app_id=${appId}&config_id=${configId}&redirect_uri=${redirectUri}&response_type=token,signed_request,graph_domain&sdk=joey&version=v2.3&scope=email`;
        // const fb_auth_url = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=email,public_profile,pages_show_list,pages_read_user_content,pages_manage_engagement`;
        // const fb_auth_url = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=email,public_profile,pages_show_list,pages_read_engagement,pages_read_user_content,pages_manage_engagement`;
        const fb_auth_url = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=email,public_profile,pages_show_list,pages_read_engagement,pages_read_user_content,pages_manage_engagement`;
        console.log({ fb_host: fb_auth_url });
        
        window.location.href = fb_auth_url
    
    };
    return (
        <React.Fragment>
            {/* <CommonModalCustomWidth 
                modal_visible={is_ready_to_select_google_location} 
                setModalVisible={(flag: any) => setReadyToSelectGoogleLocation(flag)}
                centered={true}
                // okText="Send"
                // cancelText="Cancel"
                padding={"0"}
                class_name="select-location-setting-table-container"
                footer={null}
            >
                <PageSelectionTable handleCancel={handleCancel} />
            </CommonModalCustomWidth> */}
            <div 
                title={isFBConnected ? "Connected" : ""}
                className={`integrations-container ${isFBConnected ? "connected-integration-container" : ""}`}
                onClick={() => {
                    !isFBConnected && handleFacebookConnect();
                }}
            >
                <div className="header-container">
                    <img src={getImage("Facebook")} alt="integration-icon" />
                    <div className="title">Facebook</div>
                </div>
                <div className="description">
                    Connect Facebook for managing Messages, Comments etc.
                </div>
            </div>
        </React.Fragment>
    );
};

export default IntegrationFacebook;