import React, { useEffect } from "react";
// import * as signalR from '@microsoft/signalr';
// import { HubConnectionBuilder, HttpTransportType, LogLevel } from '@microsoft/signalr';
import FeedsComponent from "../components/Feeds/feeds";
// import FeedsComponent from "../components/feeds-test/feeds";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
    // resetUnifiedInbox,
    toggleFirstTimeGetInboxData, toggleFirstTimeGetWallInboxData,
    changePage, 
    setUnifiedInboxPayload, setWallUnifiedInboxPayload,
    triggerUnified, 
    // triggerWallUnified
} from "./reducer";
// import { useNavigate } from "react-router-dom";
// import TopTabs from "../components/layout/TopTabs";
// import MobileBottomTabs from '../components/layout/MobileBottomTabs';
import UnPermission from "../pages/un-permission/UnPermission";
// import { signalR_api_url } from "../utils/server";
// import connection from "../utils/signalRconnection";
const UnifiedComponent: React.FC = () => {
    const dispatch = useDispatch();
    const route_path = window?.location?.pathname;
    const query_string = window.location.search;
    const query_tag = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "qa" : "all";

    useEffect(() => {
        // dispatch(resetUnifiedInbox());
        
        if (!query_string?.includes("&cov=") && route_path == "/unified") {
            if (!query_string?.includes("=wall")) {
                console.log({query_string});
                dispatch(changePage(`/unified?${query_tag}=conversations`));
            }
            else {
                dispatch(changePage(`/unified?${query_tag}=wall`));
            }
        }
        dispatch({ type: "FILTER_BY_SEARCH_TEXT", payload: "" });
    }, [dispatch, route_path]);
    const { unifiedData: data, wallUnifiedData, isWallUnifiedData, searchText, searchStartDate, searchEndDate, searchFilterStatus } = useSelector((state: RootState) => state?.unified);
    const tag = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "qa" : "all";
    const {
        // Inbox
        InboxAll_InboxAll, InboxAll_InboxAllnextDate,
        InboxReviews_InboxReviews, InboxReviews_InboxReviewsNextDate,
        InboxComments_InboxComments, InboxComments_InboxCommentsNextDate,
        InboxMentions_InboxMentions, InboxMentions_InboxMentionsNextDate,
        InboxQandA_InboxQandA, InboxQandA_InboxQandANextDate,
    }: any = data|| {};
    
    const unifiedData:
        any[] = tag === "all"
        ? InboxAll_InboxAll
        : tag === "reviews"
        ? InboxReviews_InboxReviews
        : tag === "comments"
        ? InboxComments_InboxComments
        : tag === "mentions"
        ? InboxMentions_InboxMentions
        : tag === "qa"
        ? InboxQandA_InboxQandA
        : [];
    
    const unifiedDataKey:
        any = tag === "all"
        ? ['InboxAll_InboxAll', 'InboxAll_InboxAllnextDate', 'lastSerialNoInboxAll']
        : tag === "reviews"
        ? ['InboxReviews_InboxReviews', 'InboxReviews_InboxReviewsNextDate', 'lastSerialNoReviewsAll']
        : tag === "comments"
        ? ['InboxComments_InboxComments', 'InboxComments_InboxCommentsNextDate', 'lastSerialNoCommentsAll']
        : tag === "mentions"
        ? ['InboxMentions_InboxMentions', 'InboxMentions_InboxMentionsNextDate', 'lastSerialNoMentionsAll']
        : tag === "qa"
        ? ['InboxQandA_InboxQandA', 'InboxQandA_InboxQandANextDate', 'lastSerialNoQandAAll' ]
        : ['InboxAll_InboxAll', 'InboxAll_InboxAllnextDate', 'lastSerialNoInboxAll'];
        
    

    const nextDate: any[]
        = tag === "all"
        ? InboxAll_InboxAllnextDate
        : tag === "reviews"
        ? InboxReviews_InboxReviewsNextDate
        : tag === "comments"
        ? InboxComments_InboxCommentsNextDate
        : tag === "mentions"
        ? InboxMentions_InboxMentionsNextDate
        : tag === "qa"
        ? InboxQandA_InboxQandANextDate
        : [];

    
    const unifiedPayload:any = {
        clapupUserId: sessionStorage.getItem('cuid'),
        roleId: sessionStorage.getItem('roleId'),
        workspaceId: sessionStorage.getItem('wuid'),
        // mappedGoogleUserId: sessionStorage.getItem('muid'),
        googleReviewsCreatedOn: new Date().toISOString().replace('Z', ''),
        facebookReviewsCreatedOn: new Date().toISOString().replace('Z', ''),
        // twitterMentionsCreatedOn: new Date().toISOString().replace('Z', ''),
        customerFeedbackCreatedOn: new Date().toISOString().replace('Z', ''),
        videoTestimonialCreatedOn: new Date().toISOString().replace('Z', ''),
        googleQuestionsCreatedOn: new Date().toISOString().replace('Z', ''),
        facebookCommentsCreatedOn: new Date().toISOString().replace('Z', ''),
        facebookMentionsCreatedOn: new Date().toISOString().replace('Z', ''),
        lastSerialNoInboxAll: 0,
        lastSerialNoReviewsAll: 0,
        lastSerialNoCommentsAll: 0,
        lastSerialNoMentionsAll: 0,
        lastSerialNoQandAAll: 0,
        // includeGoogleReviews: 1,
        // includeFacebookReviews: 1,
        // includeTwitterMentions: 1,
        // includeCustomerFeedback: 1,
        // includeVideoTestimonials: 1,
        // includeGoogleQandA: 1,
        // includeFacebookComments: 1,
        // includeFacebookMentions: 1,
        // search
        providerType: "inbox",
        searchText,
        fromDate: searchStartDate?.replace('Z', ''), 
        toDate: searchEndDate?.replace('Z', ''), 
        readStatus: searchFilterStatus,
    }

    const { is_first_time_unified_inbox, is_first_time_wall_unified_inbox } = useSelector((state: any) => state?.unified);
    // const [isFirstTime, setIsFirstTime] = React.useState(false);
    useEffect(() => {
        dispatch(toggleFirstTimeGetInboxData(true));
    }, [dispatch]);

    useEffect(() => {
        // console.log('unifiedPayload useEffect ===============> ', unifiedPayload);
        console.log({is_first_time_unified_inbox, length: data, searchText, searchStartDate, searchEndDate, searchFilterStatus});
        if(is_first_time_unified_inbox && !data && !searchText && !searchStartDate && !searchFilterStatus){
            // setIsFirstTime(true);
            dispatch(toggleFirstTimeGetInboxData(false));
            handleFirstTimeTriggerUnified(setUnifiedInboxPayload, true);
        }
    }, [is_first_time_unified_inbox]);

    const handleFirstTimeTriggerUnified = (callBack = setWallUnifiedInboxPayload, _?: any, line?: any) => {
        
        const dataObj = {
            searchText,
            fromDate: searchStartDate?.replace('Z', ''), 
            toDate: searchEndDate?.replace('Z', ''), 
            readStatus: searchFilterStatus,
            ..._,
            isFirstTime: _ ? true : false,
            key: unifiedDataKey[0],
            nextKey: unifiedDataKey[1],
            [unifiedDataKey[2]]: _?.isFirstTime ? 0 : unifiedData?.length ? unifiedData?.length : 0,
            lastSerialNo: _?.isFirstTime ? 0 : unifiedData?.length ? unifiedData?.length : 0,
        };
        

        if(tag === "reviews"){
            if(unifiedData?.length && !searchText && !searchStartDate && !searchEndDate && !searchFilterStatus){
                // unifiedPayload.googleQuestionsCreatedOn = "";
                // unifiedPayload.twitterMentionsCreatedOn = "";
                delete unifiedPayload.googleQuestionsCreatedOn;
                delete unifiedPayload.twitterMentionsCreatedOn;
            }
            // unifiedPayload.includeGoogleReviews = 1;
            // unifiedPayload.includeFacebookReviews = 1;
            // unifiedPayload.includeCustomerFeedback = 1;
            // unifiedPayload.includeVideoTestimonials = 1;
            // unifiedPayload.includeFacebookComments = 1;
            // unifiedPayload.includeTwitterMentions = 0;
            // unifiedPayload.includeGoogleQandA = 0;
            unifiedPayload.tag = "reviews";
            // unifiedPayload.providerType = "inboxReviews";
            const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null};
            dispatch(callBack(payload));
        } else if(tag === "qa"){
            if(unifiedData?.length && !searchText && !searchStartDate && !searchEndDate && !searchFilterStatus){
                delete unifiedPayload.googleReviewsCreatedOn;
                delete unifiedPayload.facebookReviewsCreatedOn;
                delete unifiedPayload.customerFeedbackCreatedOn;
                delete unifiedPayload.videoTestimonialCreatedOn;
                delete unifiedPayload.facebookCommentsCreatedOn;
                delete unifiedPayload.facebookMentionsCreatedOn;
                delete unifiedPayload.twitterMentionsCreatedOn;
            }
            unifiedPayload.tag = "QandA";
            // unifiedPayload.providerType = "inboxQandA";
            const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null};
            dispatch(callBack(payload));
        } else if(tag === "comments"){
            if(unifiedData?.length && !searchText && !searchStartDate && !searchEndDate && !searchFilterStatus){
                delete unifiedPayload.googleReviewsCreatedOn;
                delete unifiedPayload.facebookReviewsCreatedOn;
                delete unifiedPayload.facebookMentionsCreatedOn;
                delete unifiedPayload.customerFeedbackCreatedOn;
                delete unifiedPayload.videoTestimonialCreatedOn;
                delete unifiedPayload.googleQuestionsCreatedOn;
                delete unifiedPayload.twitterMentionsCreatedOn;
            }
            unifiedPayload.tag = "comments";
            // unifiedPayload.providerType = "inboxComments";
            const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null}
            dispatch(callBack(payload));
        } else if(unifiedData?.length && tag === "mentions"){
            if(!searchText && !searchStartDate && !searchEndDate && !searchFilterStatus){
                delete unifiedPayload.googleReviewsCreatedOn;
                delete unifiedPayload.facebookReviewsCreatedOn;
                delete unifiedPayload.facebookCommentsCreatedOn;
                delete unifiedPayload.customerFeedbackCreatedOn;
                delete unifiedPayload.videoTestimonialCreatedOn;
                delete unifiedPayload.googleQuestionsCreatedOn;
            }
            unifiedPayload.tag = "mentions";
            // unifiedPayload.providerType = "mentions";
            const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null};
            dispatch(callBack(payload));
        } else {
            unifiedPayload.tag = "all";
            // unifiedPayload.providerType = "inbox";
            const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null}
            dispatch(callBack(payload));
        }
    }

    const handleTriggerUnified = (callBack = triggerUnified, _?: any, line?: any) => {
        
        const dataObj = {
            searchText,
            fromDate: searchStartDate?.replace('Z', ''), 
            toDate: searchEndDate?.replace('Z', ''), 
            readStatus: searchFilterStatus,
            ..._,
            isFirstTime: _ ? true : false,
            key: unifiedDataKey[0],
            nextKey: unifiedDataKey[1],
            [unifiedDataKey[2]]: _?.isFirstTime ? 0 : unifiedData?.length ? unifiedData?.length : 0,
            lastSerialNo: _?.isFirstTime ? 0 : unifiedData?.length ? unifiedData?.length : 0,
        };

        console.log({my_data: dataObj});
        

        if(tag === "reviews"){
            if(unifiedData?.length && !searchText && !searchStartDate && !searchEndDate && !searchFilterStatus){
                // unifiedPayload.googleQuestionsCreatedOn = "";
                // unifiedPayload.twitterMentionsCreatedOn = "";
                delete unifiedPayload.googleQuestionsCreatedOn;
                delete unifiedPayload.twitterMentionsCreatedOn;
            }
            // unifiedPayload.includeGoogleReviews = 1;
            // unifiedPayload.includeFacebookReviews = 1;
            // unifiedPayload.includeCustomerFeedback = 1;
            // unifiedPayload.includeVideoTestimonials = 1;
            // unifiedPayload.includeFacebookComments = 1;
            // unifiedPayload.includeTwitterMentions = 0;
            // unifiedPayload.includeGoogleQandA = 0;
            unifiedPayload.tag = "reviews";
            // unifiedPayload.providerType = "inboxReviews";
            const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null};
            dispatch(callBack(payload));
        } else if(tag === "qa"){
            if(unifiedData?.length && !searchText && !searchStartDate && !searchEndDate && !searchFilterStatus){
                delete unifiedPayload.googleReviewsCreatedOn;
                delete unifiedPayload.facebookReviewsCreatedOn;
                delete unifiedPayload.customerFeedbackCreatedOn;
                delete unifiedPayload.videoTestimonialCreatedOn;
                delete unifiedPayload.facebookCommentsCreatedOn;
                delete unifiedPayload.facebookMentionsCreatedOn;
                delete unifiedPayload.twitterMentionsCreatedOn;
            }
            unifiedPayload.tag = "QandA";
            // unifiedPayload.providerType = "inboxQandA";
            const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null};
            dispatch(callBack(payload));
        } else if(tag === "comments"){
            if(unifiedData?.length && !searchText && !searchStartDate && !searchEndDate && !searchFilterStatus){
                delete unifiedPayload.googleReviewsCreatedOn;
                delete unifiedPayload.facebookReviewsCreatedOn;
                delete unifiedPayload.facebookMentionsCreatedOn;
                delete unifiedPayload.customerFeedbackCreatedOn;
                delete unifiedPayload.videoTestimonialCreatedOn;
                delete unifiedPayload.googleQuestionsCreatedOn;
                delete unifiedPayload.twitterMentionsCreatedOn;
            }
            unifiedPayload.tag = "comments";
            // unifiedPayload.providerType = "inboxComments";
            const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null}
            dispatch(callBack(payload));
        } else if(unifiedData?.length && tag === "mentions"){
            if(!searchText && !searchStartDate && !searchEndDate && !searchFilterStatus){
                delete unifiedPayload.googleReviewsCreatedOn;
                delete unifiedPayload.facebookReviewsCreatedOn;
                delete unifiedPayload.facebookCommentsCreatedOn;
                delete unifiedPayload.customerFeedbackCreatedOn;
                delete unifiedPayload.videoTestimonialCreatedOn;
                delete unifiedPayload.googleQuestionsCreatedOn;
            }
            unifiedPayload.tag = "mentions";
            // unifiedPayload.providerType = "mentions";
            const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null};
            dispatch(callBack(payload));
        } else {
            unifiedPayload.tag = "all";
            // unifiedPayload.providerType = "inbox";
            const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null}
            dispatch(callBack(payload));
        }
    }

    const wallPayload: any = {
        clapupUserId: sessionStorage.getItem('cuid'),
        roleId: sessionStorage.getItem('roleId'),
        workspaceId: sessionStorage.getItem('wuid'),
        lastSerialNoGoogleAll: 0,
        lastSerialNoGoogleReviews: 0,
        lastSerialNoGoogleQandA: 0,
        lastSerialNoFacebookAll: 0,
        lastSerialNoFacebookReviews: 0,
        lastSerialNoFacebookComments: 0,
        lastSerialNoFacebookMentions: 0,
        lastSerialNoCFReviews: 0,
        lastSerialNoVTReviews: 0,
        lastSerialNoInboxWall: 0,
        lastSerialNoCFWall: 0,
        lastSerialNoVTWall: 0,
        lastSerialNoGoogleWall: 0,
        lastSerialNoFacebookWall: 0,
        key: "InboxWall",
        nextKey: 'InboxWall_nextDate',
        googleReviewsCreatedOn: new Date().toISOString().replace('Z', ''),
        facebookReviewsCreatedOn: new Date().toISOString().replace('Z', ''),
        // twitterMentionsCreatedOn: new Date().toISOString().replace('Z', ''),
        customerFeedbackCreatedOn: new Date().toISOString().replace('Z', ''),
        videoTestimonialCreatedOn: new Date().toISOString().replace('Z', ''),
        googleQuestionsCreatedOn: new Date().toISOString().replace('Z', ''),
        facebookCommentsCreatedOn: new Date().toISOString().replace('Z', ''),
        facebookMentionsCreatedOn: new Date().toISOString().replace('Z', ''),
    }

    const handleTriggerWallUnified = (callBack = setWallUnifiedInboxPayload, _?: any) => {
        dispatch(callBack({ ...wallPayload }));
    }

    useEffect(() => {
        if(is_first_time_wall_unified_inbox){
            console.log({is_first_time_wall_unified_inbox});
            // setIsFirstTime(true);
            dispatch(toggleFirstTimeGetWallInboxData(false));
            handleTriggerWallUnified(setWallUnifiedInboxPayload, { wallType: route_path?.includes("unified") ? "inbox" : 'general' });
        }
    }, [is_first_time_wall_unified_inbox]);

    useEffect(() => {
        // const tag = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "googleQandA" : "all";
        if (isWallUnifiedData && query_string?.includes("wall")) {
            console.log("query_string === wall => ",{ query_string, isWallUnifiedData, wallUnifiedData});
            dispatch(toggleFirstTimeGetWallInboxData(true));
        }
        
    }, [dispatch, query_string, isWallUnifiedData]);

    const {userPermissions} = useSelector((state: any) => state?.login);
    const [isPermissible, setIsPermissible] = React.useState(true);
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "unifiedInbox") : null;
        console.log({userPermissions});
        
        if (permission?.value === 0) {
            setIsPermissible(false);
        }
    }, [userPermissions?.length]);

    const handleSearchData = (key?: any, value?: any) => {
        console.log("key, value ======> ", { key, value });
        let dataObj = {
            searchText,
            fromDate: searchStartDate,
            toDate: searchEndDate,
            readStatus: searchFilterStatus,
            isFirstTime: true,
        };
        if(key === "readStatus"){
            dispatch({ type: "FILTER_BY_READ_STATUS", payload: value });
            dataObj.readStatus = value;
            console.log({dataObj});
            handleTriggerUnified(triggerUnified, dataObj);
        } else if(key === "searchText"){
            dispatch({ type: "FILTER_BY_SEARCH_TEXT", payload: value });
            dataObj.searchText = value;
            console.log({dataObj});
            handleTriggerUnified(triggerUnified, dataObj);
        } else{
            dispatch({ type: "FILTER_BY_SEARCH_FROM_TO_DATE", payload: value });
            dataObj = {
                ...dataObj,
                ...value
            };
            console.log({dataObj});
            handleTriggerUnified(triggerUnified, dataObj);
        }
    };
    
    // console.log({nextDate: nextDate, data});
    
    return (
        <React.Fragment>
            {/* <TopTabs /> */}
            {
                isPermissible
                    ?
                    <FeedsComponent
                        handleTriggerData={handleTriggerUnified}
                        handleSearchData={handleSearchData}
                        triggerData={triggerUnified}
                        feedsContent={unifiedData}
                        type={'unified'}
                    />
                    : <UnPermission/>
            }
        </React.Fragment>
    )
}

export default UnifiedComponent;

// Old version

// import React, { useEffect } from "react";
// import Layout from "../components/layout/layout";
// import FeedsComponent from "../components/feeds/feeds";
// // import FeedsComponent from "../components/feeds-test/feeds";
// import { useDispatch, useSelector } from "react-redux";
// import { RootState } from "../store";
// import { resetUnifiedInbox, triggerUnified, triggerWallUnified } from "./reducer";
// import { useNavigate } from "react-router-dom";
// const UnifiedComponent: React.FC = () => {
//     const {unifiedData, wallUnifiedData, isWallUnifiedData, nextDate, searchText, searchStartDate, searchEndDate, searchFilterStatus} = useSelector((state: RootState) => state?.unified);
//     const dispatch = useDispatch();
//     const query_string = window.location.search;
//     const route_path = window?.location?.pathname;
//     useEffect(() => {
//         dispatch(resetUnifiedInbox());
//     }, [dispatch, route_path]);

//     useEffect(() => {
//         // console.log('unifiedData useEffect ===============> ', unifiedData);
//         // console.log('unifiedPayload useEffect ===============> ', unifiedPayload);
//         console.log({searchText, searchStartDate, searchEndDate, searchFilterStatus});
        
//         // const tag = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "googleQandA" : "all";
//         if(!unifiedData?.length && !searchText && !searchStartDate && !searchFilterStatus){
//             console.log("unifiedData?.length   25  ========", unifiedData?.length);
//             // if ((query_string?.includes("conversations"))) {
//             // }
//             handleTriggerUnified(triggerUnified, true, "26");
//         } else if(unifiedData?.length && !searchText && !searchStartDate && !searchFilterStatus){
//             // if (unifiedData?.length && (query_string?.includes("conversations") && !query_string?.includes("&"))) {
//             console.log("unifiedData?.length   32  ========", unifiedData?.length);
//             // if (query_string?.includes("&")) {
//             //     console.log("unifiedData?.length   32  ========", unifiedData?.length);
//             //     handleTriggerUnified(triggerUnified, true, "32");
//             // } 
//             // else
//             if (!query_string) {
//                 console.log("unifiedData?.length 36  ========", unifiedData?.length);
//                 handleTriggerUnified(triggerUnified, true, "35");
//             }
//             // handleTriggerUnified(triggerUnified, true, "35");
//         } else {
//             const dataObj = {
//                 searchText,
//                 fromDate: searchStartDate,
//                 toDate: searchEndDate,
//                 readStatus: searchFilterStatus,
//                 isFirstTime: true,
//             };
//             handleTriggerUnified(triggerUnified, dataObj, "36");
//         }
//     }, [dispatch, route_path, query_string, searchText, searchStartDate, searchEndDate, searchFilterStatus]);

//     const unifiedPayload:any = {
//         clapupUserId: sessionStorage.getItem('cuid'),
//         roleId: sessionStorage.getItem('roleId'),
//         workspaceId: sessionStorage.getItem('wuid'),
//         mappedGoogleUserId: sessionStorage.getItem('muid'),
//         googleReviewsCreatedOn: new Date().toISOString().replace('Z', ''),
//         facebookReviewsCreatedOn: new Date().toISOString().replace('Z', ''),
//         // twitterMentionsCreatedOn: new Date().toISOString().replace('Z', ''),
//         customerFeedbackCreatedOn: new Date().toISOString().replace('Z', ''),
//         videoTestimonialCreatedOn: new Date().toISOString().replace('Z', ''),
//         googleQuestionsCreatedOn: new Date().toISOString().replace('Z', ''),
//         facebookCommentsCreatedOn: new Date().toISOString().replace('Z', ''),
//         facebookMentionsCreatedOn: new Date().toISOString().replace('Z', ''),

//         includeGoogleReviews: 1,
//         includeFacebookReviews: 1,
//         includeTwitterMentions: 1,
//         includeCustomerFeedback: 1,
//         includeVideoTestimonials: 1,
//         includeGoogleQandA: 1,
//         includeFacebookComments: 1,
//         includeFacebookMentions: 1,
//         // search
//         providerType: "inbox",
//         searchText,
//         fromDate: searchStartDate?.replace('Z', ''), 
//         toDate: searchEndDate?.replace('Z', ''), 
//         readStatus: searchFilterStatus,
//         // old
//         // googleQandACreatedOn: new Date().toISOString(),
//         // clapupId: sessionStorage.getItem('cuid'),
//         // mappedUserId: sessionStorage.getItem('muid'),
//         // traverseOrder: 1,
//     }

//     const handleTriggerUnified = (callBack = triggerWallUnified, _?: any, line?: any) => {
//         const tag = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "qa" : "all";
//         console.log("====IsFirstTime===> ", _);
//         console.log("======Next line=====> ", line);
        
//         const dataObj = {
//             searchText,
//             fromDate: searchStartDate,
//             toDate: searchEndDate,
//             readStatus: searchFilterStatus,
//             ..._,
//             isFirstTime: _ ? true : false,
//         };
//         // if(_){
//         //     dispatch({type: "SET_SPINNER"});
//         // }
//         if(tag === "reviews"){
//             if(!searchText && !searchStartDate && !searchEndDate && !searchFilterStatus){
//                 // unifiedPayload.googleQuestionsCreatedOn = "";
//                 // unifiedPayload.twitterMentionsCreatedOn = "";
//                 delete unifiedPayload.googleQuestionsCreatedOn;
//                 delete unifiedPayload.twitterMentionsCreatedOn;
//             }
//             unifiedPayload.includeGoogleReviews = 1;
//             unifiedPayload.includeFacebookReviews = 1;
//             unifiedPayload.includeCustomerFeedback = 1;
//             unifiedPayload.includeVideoTestimonials = 1;
//             unifiedPayload.includeFacebookComments = 1;
//             unifiedPayload.includeTwitterMentions = 0;
//             unifiedPayload.includeGoogleQandA = 0;
//             unifiedPayload.tag = "reviews";
//             unifiedPayload.providerType = "inboxReviews";
//             const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null};
//             console.log("====unifiedPayload===", payload);
//             dispatch(callBack(payload));
//         } 
//         else if(tag === "qa"){
//             if(!searchText && !searchStartDate && !searchEndDate && !searchFilterStatus){
//                 delete unifiedPayload.googleReviewsCreatedOn;
//                 delete unifiedPayload.facebookReviewsCreatedOn;
//                 delete unifiedPayload.customerFeedbackCreatedOn;
//                 delete unifiedPayload.videoTestimonialCreatedOn;
//                 delete unifiedPayload.facebookCommentsCreatedOn;
//                 delete unifiedPayload.facebookMentionsCreatedOn;
//                 delete unifiedPayload.twitterMentionsCreatedOn;
//             }
//             unifiedPayload.includeGoogleReviews = 0;
//             unifiedPayload.includeFacebookReviews = 0;
//             unifiedPayload.includeCustomerFeedback = 0;
//             unifiedPayload.includeVideoTestimonials = 0;
//             unifiedPayload.includeFacebookComments = 0;
//             unifiedPayload.includeFacebookMentions = 0;
//             unifiedPayload.includeTwitterMentions = 0;
//             unifiedPayload.includeGoogleQandA = 1;
//             unifiedPayload.tag = "qa";
//             unifiedPayload.providerType = "inboxQandA";
//             const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null};
//             console.log("====unifiedPayload===", payload);
//             dispatch(callBack(payload));
//         } 
//         else if(tag === "comments"){
//             if(!searchText && !searchStartDate && !searchEndDate && !searchFilterStatus){
//                 delete unifiedPayload.googleReviewsCreatedOn;
//                 delete unifiedPayload.facebookReviewsCreatedOn;
//                 delete unifiedPayload.facebookMentionsCreatedOn;
//                 delete unifiedPayload.customerFeedbackCreatedOn;
//                 delete unifiedPayload.videoTestimonialCreatedOn;
//                 delete unifiedPayload.googleQuestionsCreatedOn;
//                 delete unifiedPayload.twitterMentionsCreatedOn;
//             }
//             unifiedPayload.includeGoogleReviews = 0;
//             unifiedPayload.includeFacebookReviews = 0;
//             unifiedPayload.includeCustomerFeedback = 0;
//             unifiedPayload.includeVideoTestimonials = 0;
//             unifiedPayload.includeFacebookComments = 1;
//             unifiedPayload.includeFacebookMentions = 0;
//             unifiedPayload.includeGoogleQandA = 0;
//             unifiedPayload.includeTwitterMentions = 0;
//             unifiedPayload.tag = "comments";
//             unifiedPayload.providerType = "inboxComments";
//             const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null}
//             console.log("====unifiedPayload===", payload);
//             dispatch(callBack(payload));
//         } 
//         else if(tag === "mentions"){
//             if(!searchText && !searchStartDate && !searchEndDate && !searchFilterStatus){
//                 delete unifiedPayload.googleReviewsCreatedOn;
//                 delete unifiedPayload.facebookReviewsCreatedOn;
//                 delete unifiedPayload.facebookCommentsCreatedOn;
//                 delete unifiedPayload.customerFeedbackCreatedOn;
//                 delete unifiedPayload.videoTestimonialCreatedOn;
//                 delete unifiedPayload.googleQuestionsCreatedOn;
//             }
//             unifiedPayload.includeGoogleReviews = 0;
//             unifiedPayload.includeFacebookReviews = 0;
//             unifiedPayload.includeCustomerFeedback = 0;
//             unifiedPayload.includeVideoTestimonials = 0;
//             unifiedPayload.includeFacebookComments = 0;
//             unifiedPayload.includeGoogleQandA = 0;
//             unifiedPayload.includeFacebookMentions = 1;
//             unifiedPayload.includeTwitterMentions = 1;
//             unifiedPayload.tag = "mentions";
//             unifiedPayload.providerType = "inboxMentions";
//             const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null};
//             console.log("====unifiedPayload===", payload);
//             dispatch(callBack(payload));
//         } 
//         else if(nextDate?.length){
//             unifiedPayload.includeGoogleReviews = 1;
//             unifiedPayload.includeFacebookReviews = 1;
//             unifiedPayload.includeTwitterMentions = 1;
//             unifiedPayload.includeCustomerFeedback = 1;
//             unifiedPayload.includeVideoTestimonials = 1;
//             unifiedPayload.includeFacebookComments = 1;
//             unifiedPayload.includeFacebookMentions = 1;
//             unifiedPayload.includeGoogleQandA = 1;
//             unifiedPayload.providerType = "inbox";
//             const payload = {...unifiedPayload, ...dataObj, nextDate};
//             console.log("====unifiedPayload===", payload);
//             dispatch(callBack(payload));
//         }else{
//             unifiedPayload.includeGoogleReviews = 1;
//             unifiedPayload.includeFacebookReviews = 1;
//             unifiedPayload.includeTwitterMentions = 1;
//             unifiedPayload.includeCustomerFeedback = 1;
//             unifiedPayload.includeVideoTestimonials = 1;
//             unifiedPayload.includeFacebookComments = 1;
//             unifiedPayload.includeFacebookMentions = 1;
//             unifiedPayload.includeGoogleQandA = 1;
//             unifiedPayload.providerType = "inbox";
//             const payload = {...unifiedPayload, ...dataObj}
//             console.log("====unifiedPayload===", payload);
//             dispatch(callBack(payload));
//         }
//     }

//     // useEffect(() => {
//     //     console.log("query_string", query_string, " ==== unifiedData?.length", unifiedData?.length);
//     //     if ((!unifiedData?.length && query_string?.includes("conversations") || !query_string?.includes("?"))) {
//     //         console.log("unifiedData?.length========", unifiedData?.length);
//     //         handleTriggerUnified(triggerUnified, true, "222");
//     //     }
        
//     // }, [dispatch, unifiedData?.length, query_string]);
    
//     useEffect(() => {
//         // const tag = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "googleQandA" : "all";
//         console.log("query_string === wall => ", query_string);
//         if (isWallUnifiedData) {
//             handleTriggerUnified(triggerWallUnified, { wallType: route_path?.includes("unified") ? "inbox" : 'general' }, "240");
//             // dispatch(triggerWallUnified({...unifiedPayload, hello: "hello", wallType: route_path?.includes("unified") ? "inbox" : 'general'}));
//         }
        
//     }, [dispatch, query_string, isWallUnifiedData]);
//     // console.log("Unified component", inbox?.length)
//     const {userPermissions} = useSelector((state: any) => state?.login);
//     const history = useNavigate();
    
//     React.useEffect(() => {
//         const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "unifiedInbox") : null;
//         if(permission){
//             permission?.value === 0 && history('/not-found');
//         }
//     }, [userPermissions]);
//     return (
//         <React.Fragment>
//             <FeedsComponent
//                 handleTriggerData={handleTriggerUnified}
//                 triggerData={triggerUnified}
//                 feedsContent={unifiedData}
//                 type={'unified'}
//             />
//         </React.Fragment>
//     )
// }

// export default UnifiedComponent;