import React, {useEffect, useState} from 'react';
import './Dashboard.scss';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setup_cards } from '../../components/Layout/global-menu/menu.config';
import SetupCard from './Components/SetupCard';
import LearnNParticipateCard from './Components/LearnNParticipateCard';
import DashboardTeamCard from './Components/DashboardTeamCard';
import DashboardFeatures from './Components/DashboardFeatures';
import Workspace from '../../components/Layout/header/components/workspace';
import { getImage } from '../../unified/image_service';
import { openMobileDrawer } from '../../profile/reducer';
import UnPermission from '../un-permission/UnPermission';

interface DashboardProps {
    // children?: React.ReactNode;
}

const Dashboard: React.FC<DashboardProps> = () => {
    const {userPermissions} = useSelector((state: any) => state?.login);
    const history = useNavigate();
    const dispatch = useDispatch();

    
    const learn_n_participate_cards = [
        {
            title: "Knowledgebase",
            description: "Explore comprehensive tutorials and documentations to  learn more about our platform.",
            button_text: "Take me to Docs",
            route: " https://docs.clapup.io",
        },
        {
            title: "Feature Request",
            description: "Submit your idea for new features and become an integral part of our product team.",
            button_text: "Submit Features",
            route: "https://feedback.clapup.io",
        },
    ];

    const { dashboardTeams: dashboard_team_cards, dashboardFeatures: features} = useSelector((state: any) => state?.workspaceManagementState);
    // const dashboard_team_cards = [
    //     {
    //         color: "#F04438",
    //         teamName: "HR Management",
    //         members: "25",
    //     },
    //     {
    //         color: "#07225F",
    //         teamName: "Sales and MarKeting",
    //         members: "30",
    //     },
    //     {
    //         color: "#039855",
    //         teamName: "HR Management",
    //         members: "15",
    //     },
    //     {
    //         color: "#F79009",
    //         teamName: "Sales and Marketing",
    //         members: "28",
    //     },
    // ];

    // These will be used only for sign up
    // const features = [
    //     {
    //         title: "Team Inbox",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files. You can start trying this now."
    //     },
    //     {
    //         title: "File Collaboration",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    //     {
    //         title: "Tagging",
    //         description: "Team box will enable organization to create team and collaborate on inboxes, bots and files."
    //     },
    // ];
    const [userDetails, setUserDetails] = useState<any>(null);
    useEffect(() => {
        try {
            const user_details = JSON.parse(localStorage.getItem("accessed_user_details") || "{}");
            if (user_details?.name) {
                setUserDetails(user_details);
            }
        } catch (err: any) {
            console.log({ err });
        }
    }, [userDetails?.name]);

    const [isPermissible, setIsPermissible] = React.useState(true);
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "dashboard") : null;
        if( permission?.value === 0){
            setIsPermissible(false);
        }
    }, [userPermissions?.length]);
    return (
        <React.Fragment>
            {
                isPermissible ?
                <div className="clap-up-dashboard">
                    <div className='mobile-dashboard-nav-container'>
                        <div className='mobile-nav'>
                            <img onClick={() => dispatch(openMobileDrawer())} src={getImage("mobile-nav-icon")} alt='hum-burger' />
                            <div>
                                <Workspace isFromSearch={true} height={40} width={40} />
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-container">
                        <div className="dashboard-header">
                            <h3>Welcome {userDetails?.name || "User"},</h3>
                        </div>
                        <div className="dashboard-body">
                            <h4>Welcome {userDetails?.name || "User"},</h4>
                            <h5 className="body-title">Quick Setup</h5>
                            <div className="dashboard-body-container">
                                <div className="dashboard-card-container">
                                    <div className="setup-card-container">
                                        {
                                            setup_cards?.map((card: any, idx: number) => <SetupCard key={idx} card={card} />)
                                        }
                                    </div>
                                    <div className="participate-card-container">
                                        <div className='title'>Learn and Participate</div>
                                        <div className="card-container">
                                            {
                                                learn_n_participate_cards?.map((card: any, idx: number) => <LearnNParticipateCard key={idx} card={card} />)
                                            }
                                        </div>
                                    </div>
                                    <div className="team-card-container">
                                        <div className='title'>Your Teams</div>
                                        <div className="card-container">
                                            {
                                                dashboard_team_cards?.map((card: any, idx: number) => <DashboardTeamCard key={idx} card={card} />)
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-feature-container">
                                    <div className="title">
                                        <div>New Features Released</div>
                                        <img src={getImage("feature-icon")} alt="dashboard-icon" />
                                    </div>
                                    <DashboardFeatures features={features}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : <UnPermission/>
            }
        </React.Fragment>
    );
};

export default Dashboard;