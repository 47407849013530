import React, { useEffect } from 'react';
import save_icon from '../../../../../../assets/icons/settings/save-icon.svg';
import nested_folder_icon from '../../../../../../assets/icons/settings/nested-folder.svg';
import arrow_down_icon from '../../../../../../assets/icons/settings/arrow-down.svg';
import upload_icon from '../../../../../../assets/icons/settings/upload-icon.svg';
import video_icon from '../../../../../../assets/icons/settings/embed-video-icon.svg';
import edit_icon from '../../../../../../assets/icons/settings/edit-icon.svg';
import delete_icon from '../../../../../../assets/icons/settings/biopage/delete-icon.svg';
import './../../CustomLinks.scss';
import { convertImageToBase64 } from '../../../../../../utils/convertingFunction';
import { useDispatch } from 'react-redux';
import { setErrorMessage } from '../../../../../../unified/reducer';
import { modifyString } from '../../../../../../utils/mediaService';

type LinkProps = {
    link_item?: any;
    id?: number;
    label?: "";
    displayText?: "";
    url?: "";
    logoName?: '';
    logo?: any;
    children?: [];
    onCancel?: () => void;
    onParentCallback?: (data: any, file?: any) => void;
};

const EmbedVideoLinkForm: React.FC<LinkProps> = ({link_item, id, displayText, label, logo, logoName, children, onCancel, onParentCallback }) => {
    const dispatch = useDispatch();
    const [curChild, setCurChild] = React.useState<any>(null);
    const [curDisplayText, setCurDisplayText] = React.useState(displayText || "");
    const [curLabel, setCurLabel] = React.useState(label || "");
    const [curYouTubeLink, setCurYouTubeLink] = React.useState("");
    const [curChildren, setCurChildren] = React.useState<any>(children || []);
    const [image, setImage] = React.useState<any>({base64Url: logo, name: logoName});
    
    useEffect(() => {
        console.log({ children });
    }, [children]);
        
    const handleSubmit = () => {
        // const dummy_images = [
        //     "https://i.ibb.co/Z1KQVMG/facebook.png",
        //     "https://i.ibb.co/cxx56hX/google.png",
        //     "https://i.ibb.co/xmgRtQN/instagram.png",
        //     "https://i.ibb.co/fXCyV0S/mail.png",
        //     "https://i.ibb.co/z4LsPft/pinterest-icon.png",
        //     "https://i.ibb.co/g3BWMkW/twitter.png",
        // ];

        // const dummy_image = dummy_images[Math.floor(Math.random() * dummy_images.length)];

        const videoLinksByComma: any = curChildren?.map((child: any) => child?.youtubeLink).join(', ');

        // // console.log({label, url, image});
        const data = {
            clapupUserId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
            displayText: curDisplayText,
            id: link_item?.id || 0,
            linkUrl: '',
            label: curLabel,
            // // iconUrl: image?.base64Url || logo, // we have to work in future
            iconUrl: link_item?.iconUrl || "",
            iconName: image?.name || logoName,
            parentId: 0,
            linkType: 4,
            mediaType: '',
            contentIds: '',
            mode: link_item ? "update" : "insert",
            linkCategory: "customlinks",
            videoLink: videoLinksByComma,
            contentId: link_item?.contentId || "",
            uniqueNo: link_item?.uniqueNo || null,
            
        }

        // console.log({data});
        
        onParentCallback && onParentCallback(data, image?.file);
    };
    
    const handleAdd = (data = curYouTubeLink) => {
        const newData = {
            // id: "embed-video-link-" + Number(new Date()),
            idx: curChildren.length,
            youtubeLink: data,
        };
        setCurChildren([...curChildren, newData]);
        setCurYouTubeLink("");
    };
    
    const handleUpdate = () => {
        const data = {
            ...curChild,
            youtubeLink: curYouTubeLink,
        }

        const filteredChildren = curChildren?.filter((child: any) => child.idx !== curChild?.idx);
        setCurChildren([...filteredChildren, data]?.sort((a: any, b: any) => a.idx - b.idx));        
        setCurYouTubeLink("");
        setCurChild(null);
    };

    const handleImageChange = (e: any) => {
        // file local path
        // const image_path
        const file = e?.target?.files[0];
        const { name }: any = file || {};
        // console.log({path});
        // setImageFile(file);
        setImage({file, name: name?.replaceAll(" ", "")});

        // ======== 1st way to convert image to base64 ========
        // convertImageToBase64(file).then((base64Url: any) => {
        //     // console.log({base64Url});
        //     // console.log({base64Url, name});
        //     setImage({base64Url, name});
            
        // }).catch((error: any) => {
        //     console.log({ error });
        //     dispatch(setErrorMessage("Something went wrong while converting image to base64"))
        // });
        
    }

    const handleDeleteChild = (idx: any) => {
        const filteredChildren = curChildren.filter((child: any) => child.idx !== idx);
        setCurChildren(filteredChildren);
    };
    
    const handleChange = (e: any, setState: any) => {
        setState(e.target.value);
    };

    return (
        <div>
            <div className='form-link-container form-component' 
                style={{
                    height: curChildren?.length > 2 ? '60vh': '100%',
                }}
            >
                <div className='scroll-hover'>
                    <form
                        id='link-form'
                        className='form'
                        style={{
                            padding: curChildren?.length > 2 ? '1.5% 3% 10% 3%': '3.5% 3% 6% 3%',
                        }}
                    >
                        <div
                            className='input-group'
                        >
                            <input
                                type="text" name={"displayText"}
                                autoFocus={true}
                                autoComplete='off'
                                autoSave='off'
                                value={curDisplayText || ""}
                                onChange={(e: any) => handleChange(e, setCurDisplayText)}
                                placeholder={"Enter the Display Text"}
                                style={{margin: '0 0 3.5% 0'}}
                            />
                        </div>

                        <div
                            className='input-group'
                        >
                            <input
                                type="text" name={"label"}
                                autoComplete='off'
                                autoSave='off'
                                onChange={(e: any) => handleChange(e, setCurLabel)}
                                value={curLabel || ""}
                                placeholder={"Enter the label"}
                                style={{margin: '0 0 3.5% 0'}}
                            />
                        </div>
                        
                        <div
                            className='input-group'
                        >
                            <input
                                type="text" name={"logo"}
                                autoComplete='off'
                                autoSave='off'
                                readOnly={true}
                                placeholder={"Upload the link icon"}
                                style={{margin: '0 0 3.5% 0'}}
                                value={image?.name || ''}
                            />
                            <input
                                // disabled={image ? false : true}
                                id="image"
                                type="file"
                                accept="image/svg+xml, image/png, image/jpeg, image/jpg"
                                onChange={(e: any) => {
                                    // console.log({ img: e?.target?.files[0] });
                                    // setImage(e?.target?.files[0]?.name);
                                    handleImageChange(e); 
                                }}
                                className="form-input-field d-none w-100 my-1" placeholder="Biopage Image"
                            />
                            <label className='image-label' htmlFor="image">Upload</label>
                        </div>
                        <div
                            className='input-group'
                        >
                            <input
                                type="text" name={"logo"}
                                autoComplete='off'
                                autoSave='off'
                                placeholder={"Add YouTube Link"}
                                onChange={(e: any) => handleChange(e, setCurYouTubeLink)}
                                value={curYouTubeLink || ""}
                            />
                            
                            <span
                                className='input-label'
                                style={{
                                    cursor: (curDisplayText && curLabel && image?.name && curYouTubeLink) ? 'pointer' : 'not-allowed',
                                }}
                                onClick={() => {
                                    if(curDisplayText && curLabel && image?.name && curYouTubeLink) {
                                        curChild !== null ? handleUpdate() : handleAdd(curYouTubeLink);
                                    }
                                }}
                            >
                                {
                                    curChild !== null ? 'Update' : 'Add'
                                }
                            </span>
                        </div>
                    </form>
                    
                    {
                        curChildren?.length
                        ?   <div className='child-list-container'>
                                {
                                    curChildren?.map((child: any, index: number) => { 
                                        return (
                                            <div key={index} className='child-item'>
                                                <div className='input-container'>
                                                    <div className='logo-text-label-container'>
                                                        <div className='logo-text'>
                                                            <img
                                                                // src={image?.base64Url || logo}
                                                                src={video_icon}
                                                                alt="item-icon"
                                                                className={``}
                                                            />
                                                            <p className='input-value'>{modifyString(child?.youtubeLink, 23)}</p>
                                                        </div>
                                                        {/* <p>{child.label}</p> */}
                                                    </div>
                                                    <div className='edit-delete-container'>
                                                        <img
                                                            src={edit_icon}
                                                            alt="edit-icon"
                                                            className={``}
                                                            onClick={() => {
                                                                setCurChild(child);
                                                                setCurYouTubeLink(child?.youtubeLink);
                                                            }}
                                                        />
                                                        <img
                                                            src={delete_icon}
                                                            alt="delete-icon"
                                                            className={``}
                                                            onClick={() => {
                                                                handleDeleteChild(child?.idx);
                                                            }}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        : null
                    }
                </div>
            </div>

            <div className='form-button-container'
                style={{
                    paddingRight: curChildren?.length > 2 ? '1%': '0',
                }}
            >
                <button
                    type='button'
                    className='cancel-button'
                    onClick={onCancel}
                >Cancel</button>
                <button
                    disabled={(curDisplayText && curLabel && (image.name || logoName)) ? false : true}
                    className='create-button'
                    onClick={() => handleSubmit()}
                    type='button'
                >Save</button>
            </div>
        </div>
    )
};

export default EmbedVideoLinkForm;
