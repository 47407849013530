import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { getImage, getGenerateApiImageByName } from "../../../../unified/image_service";
import { updateChannelSetting } from "../teamsReducer";

const ChannelSettings: React.FC = () => {
    const dispatch = useDispatch();
    const {editable_team, editable_team_channel } = useSelector((state: any) => state?.teamState);
    const [AutoBCCEmailId, setAutoBCCEmailId] = useState<any>(null);
    const [IsolateActivities, setIsolateActivities] = useState<any>(null);
    const [TrackSentEmail, setTrackSentEmail] = useState<any>(null);
    useEffect(() => {
        const { autoBCCEmailId, isolateActivities, trackSentEmail } = editable_team_channel || {};
        setAutoBCCEmailId(autoBCCEmailId);
        setTrackSentEmail(trackSentEmail ? "1" : "0");
        setIsolateActivities(isolateActivities ? "1" : "0");
    }, [editable_team_channel?.channelId]);
    
    const handleUpdateChannelSettings = (fieldName: any, fieldValue: any) => {
        const clapupUserId = localStorage.getItem('cuid') || sessionStorage.getItem('cuid') || null;
        const workspaceId = localStorage.getItem('wuid') || sessionStorage.getItem('wuid') || null;
        // const roleId = localStorage.getItem('roleId') || sessionStorage.getItem('roleId') || null;
        const data_payload = {
            workspaceId: workspaceId || "",
            clapupUserId: clapupUserId || "",
            channelId: editable_team_channel?.channelId,
            fieldName: fieldName,
            fieldValue: fieldValue,
            teamId: editable_team?.teamId,// not for api field
        };
        dispatch(updateChannelSetting(data_payload));
    }

    return (
        <React.Fragment>
            <div className="notification-settings-form-item">
                <div className="title-description-container">
                    <div className="title">Auto BCC</div>
                    <div className="description">Enter the email address you want to use for Auto BCC. Once set, every compose and reply from this email will have a BCC.</div>
                </div>
                <div className="action-body">
                    <div className="label-input-container">
                        <label style={{color: "transparent"}}>{"Non Label"}</label>
                        <input
                            className=""
                            value={AutoBCCEmailId || ""}
                            onChange={(e) => setAutoBCCEmailId(e.target.value)}
                            placeholder={"Type the email you want to set Bcc"}
                        />
                        <img
                            className="tik"
                            onClick={() => handleUpdateChannelSettings("AutoBCCEmailId", AutoBCCEmailId)}
                            src={getImage("fill-success-tik")}
                            alt="tik"
                        />
                    </div>
                </div>
            </div>
            <div className="notification-settings-form-item">
                <div className="title-description-container">
                    <div className="title">Track Sent Emails</div>
                    <div className="description">Whenever possible, we will try to track the open and read status of the email you replied.</div>
                </div>
                <div className="action-body">
                    <img
                        onClick={() => {
                            const value = TrackSentEmail === "1" ? "0" : "1";
                            setTrackSentEmail(value);
                            handleUpdateChannelSettings("TrackSentEmail", value);
                        }}
                        className="switch-icon"
                        src={getImage(TrackSentEmail === "1" ? "active-notification" : "in-active-notification")} 
                        alt="tik"
                    />
                </div>
            </div>
            <div className="notification-settings-form-item">
                <div className="title-description-container">
                    <div className="title">Isolate Activities</div>
                    <div className="description">If you choose isolate activities, all actions will be private to the users who have access to this account only. Else, if any communication involving other teams will be shared across the team that have access.</div>
                </div>
                <div className="action-body">
                    <img
                        onClick={() => {
                            const value = IsolateActivities === "1" ? "0" : "1";
                            setIsolateActivities(value);
                            handleUpdateChannelSettings("IsolateActivities", value);
                        }}
                        className="switch-icon"
                        src={getImage(IsolateActivities === "1" ? "active-notification" : "in-active-notification")} 
                        alt="tik"
                    />
                </div>
            </div>
            <div className="extra"></div>
        </React.Fragment>
    );
};

export default ChannelSettings;