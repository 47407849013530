// mobile icons
import mobile_dashboard_icon from "../../../assets/mobile-icons/dashboard-2-line.svg";
import mobile_inbox_icon from "../../../assets/mobile-icons/mobile-inbox-icon.svg";
import mobile_message_inbox_icon from "../../../assets/mobile-icons/message-inbox.svg";
import mobile_promotion_icon from "../../../assets/mobile-icons/promotion-icon.svg";
import mobile_link_manager_icon from "../../../assets/icons/team-1.3.svg";
import mobile_gallery_icon from "../../../assets/mobile-icons/gallery-icon.svg";
import mobile_feedback_icon from "../../../assets/mobile-icons/feedback-line.svg";
import mobile_video_icon from "../../../assets/mobile-icons/video-line-icon.svg";
import mobile_google_icon from "../../../assets/mobile-icons/google-line-icon.svg";
import mobile_facebook_icon from "../../../assets/mobile-icons/facebook-line-icon.svg";
import mobile_settings_icon from "../../../assets/icons/team-1.3.svg";
// big screen icons
// import mobile_twitter_icon from "../../../assets/mobile-icons/twitter-line-icon.svg";
// import dashboard_icon from "../../../assets/icons/dashboard-2-line.svg";
// import inbox_icon from "../../../assets/icons/inbox-line.svg";
// import promotion_icon from "../../../assets/icons/commerce-mike.svg";
import dashboard_icon from "../../../assets/icons/dashboard-1.3.svg";
import inbox_icon from "../../../assets/icons/inbox-1.3.svg";
import gallery_icon from "../../../assets/icons/gallery-1.3.svg";
import message_inbox_icon from "../../../assets/icons/bot-inbox-line.svg";
import promotion_icon from "../../../assets/icons/promotions-1.3.svg";
import link_manager_icon from "../../../assets/icons/team-1.3.svg";
import settings_icon from "../../../assets/icons/settings-1.3.svg";
import wall_icon from "../../../assets/icons/wall-1.3.svg";
import feedback_icon from "../../../assets/icons/feedback-line.svg";
import video_icon from "../../../assets/icons/video-line-icon.svg";
import google_icon from "../../../assets/icons/google-line-icon.svg";
import facebook_icon from "../../../assets/icons/facebook-line-icon.svg";

// ==== settings routes ======
import workspace_settings_icon from "../../../assets/icons/pro-settings/workspace-settings-1.33.svg";
import user_management_icon from "../../../assets/icons/pro-settings/user-management-1.33.svg";
import team_settings_icon from "../../../assets/icons/pro-settings/team-1.33.svg";
import bio_page_settings_icon from "../../../assets/icons/pro-settings/biopage-settings-1.33.svg";
import integration_settings_icon from "../../../assets/icons/pro-settings/integration-settings-1.33.svg";
import bot_management_icon from "../../../assets/icons/pro-settings/bot-management-1.33.svg";
import account_settings_icon from "../../../assets/icons/pro-settings/account-settings-1.33.svg";
import user_profile_icon from "../../../assets/icons/pro-settings/user-profile-1.33.svg";

// ======== components of settings ========
import WorkspaceSettings from "../../../pages/Settings/WorkspaceSettings/WorkspaceSettings";
import IntegrationsSettings from "../../../pages/Settings/Integrations/IntegrationsSettings";
import UserManagementSettings from "../../../pages/Settings/UserManagementSettings/UserManagementSettings";
import BioPageSettings from "../../../pages/Settings/BioPageSettings/BioPageSettings";
import Teams from "../../../pages/Settings/Teams/Teams";
import Agents from "../../../pages/Settings/AgentSettings/Agent";
import AccountSettings from "../../../pages/Settings/AccountSettings/AccountSettings";
import UserProfile from "../../../pages/Settings/UserProfile/UserProfile";


const menu_routes = [
    {
        // This will be in menu_routes on the top first
        id: 1,
        isWall: true,
        title: "Dashboard",
        icon: dashboard_icon,
        mobile_icon: mobile_dashboard_icon,
        key: "dashboard",
        root: true,
        permissionName: "dashboard",
        url: "/dashboard",
    },
    {
        id: 2,
        isWall: true,
        title: "Review Inbox",
        icon: inbox_icon,
        // mobile_icon: inbox_icon,
        mobile_icon: mobile_inbox_icon, // mobile icon is not available
        key: "unified",
        permissionName: "unifiedInbox",
        url: "/unified",
    },
    // New requirement
    {
        id: 8,
        title: "Gallery",
        icon: gallery_icon,
        mobile_icon: mobile_gallery_icon,
        key: "gallery",
        permissionName: "gallery",
        url: "/gallery",
    },
    // it will be work on inbox section
    // {
    //     id: 9,
    //     mobile_title: "Message Inbox",
    //     title: "Messages",
    //     icon: message_inbox_icon,
    //     mobile_icon: mobile_message_inbox_icon,
    //     key: "whatsapp",
    //     sub_key: "messages",
    //     permissionName: "dashboard",
    //     // url: "/messages",
    //     url: "/whatsapp",
    // },
    {
        id: 10,
        title: "Promotions",
        icon: promotion_icon,
        mobile_icon: mobile_promotion_icon,
        key: "promotions",
        permissionName: "dashboard",
        url: "/promotions",
    },
    {
        id: 11,
        title: "Link Manager",
        icon: link_manager_icon,
        mobile_icon: mobile_link_manager_icon,
        key: "LinkManager",
        permissionName: "unifiedInbox",
        url: "/link-management",
    },
    {
        id: 12,
        title: "Wall",
        icon: wall_icon,
        mobile_icon: mobile_inbox_icon,
        key: "wall",
        permissionName: "unifiedInbox",
        url: "/unified?all=wall",
    },


];

const wall_menu_routes = [
    {
        // This will be in menu_routes on the top first
        id: 1,
        isWall: true,
        title: "Dashboard",
        icon: dashboard_icon,
        mobile_icon: mobile_dashboard_icon,
        key: "dashboard",
        root: true,
        permissionName: "dashboard",
        url: "/dashboard",
    },
    {
        id: 2,
        isWall: true,
        title: "Review Inbox",
        icon: inbox_icon,
        mobile_icon: inbox_icon,
        // mobile_icon: mobile_inbox_icon, // mobile icon is not available
        key: "unified",
        permissionName: "unifiedInbox",
        url: "/unified",
    },
    // New requirement
    {
        id: 3,
        isWall: true,
        title: "Feedback",
        icon: feedback_icon,
        mobile_icon: mobile_feedback_icon,
        key: "feedback",
        permissionName: "customerFeedback",
        url: "/customer-feedback",
    },
    {
        id: 4,
        isWall: true,
        title: "Video Testimonial",
        icon: video_icon,
        mobile_icon: mobile_video_icon,
        key: "video",
        permissionName: "videoTestimonial",
        url: "/video-testimonial",
    },
    {
        id: 5,
        isWall: true,
        title: "Google",
        icon: google_icon,
        mobile_icon: mobile_google_icon,
        key: "google",
        permissionName: "google",
        url: "/google",
    },
    {
        id: 6,
        isWall: true,
        title: "Facebook",
        icon: facebook_icon,
        mobile_icon: mobile_facebook_icon,
        key: "facebook",
        permissionName: "facebook",
        url: "/facebook",
    },

    // {
    //     id: 7,
    //     title: "Twitter",
    //     icon: twitter_icon,
    //     key: "twitter",
    //     permissionName: "twitter",
    //     url: "/twitter",
    // },

];

const settings_routes = [
    {
        // This will be in menu_routes on the top first
        id: 1,
        title: "Settings",
        icon: settings_icon,
        mobile_icon: mobile_settings_icon,
        key: "settings",
        permissionName: "dashboard",
        url: "/settings/workspace/general-settings",
        // url: "/workspace/general-settings",
    },
];

const settings_menu_routes = [
    {
        id: 1,
        title: "Workspace Settings",
        icon: workspace_settings_icon,
        mobile_icon: workspace_settings_icon,
        key: "workspace-settings",
        permissionName: "dashboard",
        component: WorkspaceSettings,
        url: "/settings/workspace/general-settings",
    },
    {
        id: 2,
        title: "User Management",
        icon: user_management_icon,
        mobile_icon: user_management_icon,
        key: "user-management",
        permissionName: "dashboard",
        component: UserManagementSettings,
        url: "/settings/user-management",
    },
    {
        id: 3,
        title: "Teams",
        icon: team_settings_icon,
        mobile_icon: team_settings_icon,
        key: "teams",
        permissionName: "dashboard",
        component: Teams,
        url: "/settings/teams",
    },
    {
        id: 4,
        title: "Biopage Settings",
        icon: bio_page_settings_icon,
        mobile_icon: bio_page_settings_icon,
        key: "biopage-settings",
        permissionName: "dashboard",
        component: BioPageSettings,
        url: "/settings/biopage-settings",
    },
    {
        id: 5,
        title: "Integrations",
        icon: integration_settings_icon,
        mobile_icon: integration_settings_icon,
        key: "integration-settings",
        permissionName: "dashboard",
        component: IntegrationsSettings,
        url: "/settings/integrations",
    },
    {
        id: 6,
        title: "Agents",
        icon: bot_management_icon,
        mobile_icon: bot_management_icon,
        key: "agents",
        permissionName: "dashboard",
        component: Agents,
        url: "/settings/agents",
    },
    {
        id: 7,
        title: "Account Settings",
        icon: account_settings_icon,
        mobile_icon: account_settings_icon,
        key: "account-settings",
        permissionName: "dashboard",
        component: AccountSettings,
        url: "/settings/account-settings",
    },
    {
        id: 8,
        title: "User Profile",
        icon: user_profile_icon,
        mobile_icon: user_profile_icon,
        key: "user-profile",
        permissionName: "dashboard",
        component: UserProfile,
        url: "/settings/user-profile",
    },
];

const setup_cards = [
    {
        title: "Set up your Workspace",
        description: "Configure your workspace and start working with your team members.",
        route: "/settings/workspace/general-settings",
        button_text: "Workspace Settings",
    },
    {
        title: "Create Teams",
        description: "Teams enable your users to collaborate on files and inboxes to work seamlessly.",
        route: "/settings/teams",
        button_text: "Go to Teams",
    },
    {
        title: "Manage Integrations",
        description: "Inbox is the place for managing messages and collaborate on messages with your tram mates.",
        route: "/settings/integrations",
        button_text: "Go to Integrations",
    },
    {
        title: "Go to Inbox",
        description: "Inbox is the place for managing messages and collaborate on messages with your tram mates.",
        route: "/unified",
        button_text: "Take me to Inbox",
    },
    {
        title: "Manage Notifications",
        description: "Enable notifications for your favourite channels and get it while you are aways.",
        route: "/settings/user-profile",
        button_text: "Go to Notifications",
    },
    {
        title: "Set up my profile",
        description: "Set up  user profile by adding an avatar so that your team mates can identify you quickly.",
        route: "/settings/user-profile",
        button_text: "Setup my Profile",
    },
];

export { setup_cards, settings_routes, settings_menu_routes, menu_routes, wall_menu_routes };