import React from "react";
// import IntegrationEmail from "./IntegrationEmail";
// import IntegrationTwitter from "./IntegrationTwitter";
import IntegrationGoogle from "./IntegrationGoogle";
import IntegrationFacebook from "./IntegrationFacebook";
import IntegrationWhatsapp from "./IntegrationWhatsapp";
import "./IntegrationManagement.scss";

const IntegrationManagement: React.FC = () => {
    return (
        <React.Fragment>
            <div className="integration-settings-container">
                {/* <IntegrationEmail /> */}
                <IntegrationGoogle />
                <IntegrationFacebook />
                {/* <IntegrationTwitter /> */}
                <IntegrationWhatsapp/>
            </div>
        </React.Fragment>
    );
};

export default IntegrationManagement;