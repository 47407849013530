import React from 'react';
import { Table } from 'antd';
import './AntTable.scss';
const AntTable = ({table_columns, table_data, height, width, rowSelection_data, is_bordered = true, loading, selectedRowKeys, handleSelectedRows}: any) => {
    // console.log({ table_data });
    // const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
    // React.useEffect(() => {
    //     const selectedRowKeys = table_data?.map((item: any) => item?.isActive && item?.id);
    //     setSelectedRowKeys(selectedRowKeys);
    // }, [table_data]);

    // console.log({ selectedRowKeys });
    return (
      <React.Fragment>
        <Table
          // style={{fontSize: "8px"}}
          loading={loading}
          columns={table_columns}
          dataSource={table_data}
          pagination={false}
          bordered={false}
          rowKey={(record: any) => record.id}
          rowSelection={
            rowSelection_data || {
              type: "checkbox",
              onChange: (selectedRowKeys: any, selectedRows: any) => {
                console.log(
                  `selectedRowKeys: ${selectedRowKeys}`,
                  "selectedRows: ",
                  selectedRows
                );
                // setSelectedRowKeys(selectedRowKeys);
                handleSelectedRows(selectedRowKeys);
              },
            //   by default checked rows are selected
              selectedRowKeys,
            }
          }
          // rowSelection={rowSelection_data || {
          //     type: 'checkbox',
          //     onChange: (selectedRowKeys: any, selectedRows: any) => {
          //         console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          //     },
          // }}
          // rowClassName={() => "rowClassName1"}
          // scroll={{ x: width || 540 }}
          // style={{height: height}}
          scroll={{ x: width || 540, y: height || 320 }}
        />
      </React.Fragment>
    );
};

export default AntTable;