import React, { useEffect } from 'react';
// import S3 from 'aws-sdk/clients/s3.js';
// import AWS from 'aws-sdk';
import search from '../../assets/icons/search-icon.svg';
import next_path from '../../assets/icons/next-path.svg';
// import back_path from '../../assets/icons/back-path.svg';
import back_path from '../../assets/icons/back-path-pro.svg';
import upload_icon from '../../assets/icons/upload.svg';
import download_icon from '../../assets/icons/download-icon.svg';
import save_icon from '../../assets/icons/settings/save-icon.svg';
import delete_icon from '../../assets/icons/delete-btn.svg';
import Button from '../CustomButton/ButtonContainer';
import { DebounceInput } from 'react-debounce-input';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import Folders from './FolderManagement/Folders';
import { setErrorMessage, setSuccessMessage } from '../../unified/reducer';
import Contents from './ContentManagement/Contents';
import { Select } from 'antd';
import './Media.scss';
import {
  createFolderContent, createContentFolder,
  setShowingUploadingProgress, setUploadProgressCount,
  toggleCreateFolderModal, setIsContentUploadedError, getOneTimeFolderContents, setOneTimeFolderContents,
  saveContentDetails,
  showDeleteMessage, deleteContents, updateFolderContentNewVersion,
  setSearchFolderText, setSearchContentText,
  setSortFolderListSortType, setSortContentListSortType
} from './mediaReducer';
import FolderForm from './FolderManagement/FolderForm';
import { Progress } from 'antd';
// import { convertImageToBase64 } from '../../utils/convertingFunction';
import Dialog from '../Dialogs/Dialog';
import {
  // downloadContent,
  download2Content,
  modifyString
} from '../../utils/mediaService';
import VideoDurationCheck from './ContentManagement/VideoDurationCheck';
// import MobileBottomTabs from '../layout/MobileBottomTabs';
import Search from '../Search/SearchComponent';
import Workspace from '../Layout/header/components/workspace';
// import { aws_access_key, aws_secret_key, cloudFlareR2ServiceUrl } from '../../utils/server';
// import { aws_access_key, aws_account_id, aws_secret_key } from '../../utils/server';
// import { Spinner } from 'react-bootstrap';
export default function Media() {
  const {
    default_content_idx,
    bio_page_folders,
    all_folders,
    folderNextDate,
    selected_deletable_contents,
    folder_contents,
    // showing_save_button,
    is_showing_create_folder,
    show_uploading_progress,
    is_content_uploading_error,
    uploadingProgressCount,
    updatable_content,
    delete_message,
    folder_sort_type,
    content_sort_type,
  }: any = useSelector((state: RootState) => state?.mediaState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route_path = window.location.pathname;
  const query_string = window.location.search;
  const path = query_string.replace('?', '')?.replace('&', "=");
  const path_array = path.split("=");
  const [activeFolder, setActiveFolder] = React.useState<any>(null);
  useEffect(() => {
    console.log({folder_contents, all_folders, bio_page_folders});
    
    if (all_folders?.length) {
      let isFoundFolderPath: any = null; 
      // bio_page_folders?.find((folder: any) => folder?.folderName === path_array[0]?.replaceAll("%20", " "));
      let isSystemFolderPath = bio_page_folders?.find((folder: any) => folder?.folderName === path_array[0]?.replaceAll("%20", " "));
      !isSystemFolderPath && (isFoundFolderPath = all_folders?.find((folder: any) => folder?.folderName === path_array[0]?.replaceAll("%20", " ")));
      // console.log({ content_path_array: path_array, all_folders, curPath: path_array[0]?.replaceAll("%20", " "), isFoundFolderPath });
      console.log({isSystemFolderPath, isFoundFolderPath});
      if (path_array[0] !== "all" && (isSystemFolderPath || isFoundFolderPath) && (query_string?.includes("=folders") || query_string?.includes("=contents"))) {
        
        setTimeout(() => {
          // setActiveFolder(path_array[0]?.replaceAll("%20", ""));
          const folder_object: any = {
            folderName: (isSystemFolderPath || isFoundFolderPath)?.folderName,
            displayName: (isSystemFolderPath || isFoundFolderPath)?.displayName,
            isFromSystem: isSystemFolderPath ? true : false
          };
          setActiveFolder(folder_object);
        }, 1500);
        // যদি Folder এর ছবি / ভিডিও না থাকে, তাহলে ১ম বার Contents এর জন্য ডাটা লোড করবে
        if (!folder_contents?.length) {
          dispatch(getOneTimeFolderContents({ folderName: path_array[0]?.replaceAll("%20", "") }));
        }
      } else {
        setActiveFolder(null);
        dispatch(setOneTimeFolderContents({ contents: [], contentNextDate: null }));
        navigate(`${route_path}?all=folders`);
      }
    }
  }, [query_string, all_folders?.length]);
  
  
  const handleSearch = (searched_text: any) => {
    console.log({ searched_text, activeFolder });
    if(activeFolder) {
      dispatch(setSearchContentText(searched_text));
    } else {
      dispatch(setSearchFolderText(searched_text));
    }
  };

  // const user_info = JSON.parse(
  //   sessionStorage.getItem("accessed_user_details") || "{}"
  // );

  const handleCreateFolder = ({ folderName, displayName }: any) => {
    if (folderName && displayName) {
      const check_in_bio_page = bio_page_folders?.find((folder: any) => folder?.folderName === folderName || folder?.displayName === displayName);
      if (check_in_bio_page) { 
        dispatch(setErrorMessage("Error. Folder already exists"));
        return;
      }
      const check_in_all_folders = all_folders?.find((folder: any) => folder?.folderName === folderName || folder?.displayName === displayName);
      if (check_in_all_folders) { 
        dispatch(setErrorMessage("Error. Folder already exists"));
        return;
      }
      const folderPayload = {
        clapupUserId: sessionStorage.getItem('cuid'),
        workspaceId: sessionStorage.getItem('wuid'),
        roleId: sessionStorage.getItem('roleId'),
        contentType: "GalleryFolder",
        contentText: JSON.stringify({folderName, displayName}),
        folderPath: folderName,
        subCategory: "GalleryFolder"
      };
      console.log({ folderPayload });
      
      dispatch(createContentFolder(folderPayload, folderNextDate));
    } else {
      dispatch(toggleCreateFolderModal(false));
    }
  };

  // const [isShowingUploadFile, setIsShowingUploadFile] = React.useState<boolean>(false);
  // const [uploadingTimeCount, setUploadingTimeCount] = React.useState<number>(0);
  // const [uploadError, setUploadFileError] = React.useState<string>("");
  useEffect(() => {
    // console.log({ uploadingTimeCount, isShowingUploadFile });
    // if (uploadError) {
    //   setTimeout(() => {
    //     dispatch(setErrorMessage(uploadError));
    //     setUploadFileError("");
    //     // setIsShowingUploadFile(false);
    //     // setUploadingTimeCount(0);
    //     dispatch(setShowingUploadingProgress(false));
    //     dispatch(setUploadProgressCount(0));
    //   }, 3000);
    // } else
    if (show_uploading_progress && uploadingProgressCount && uploadingProgressCount < 99) {
      setTimeout(() => {
        // setUploadingTimeCount((uploadingTimeCount + 1));
        dispatch(setUploadProgressCount((uploadingProgressCount + 1)));
      }, 100);
    }

    if((!show_uploading_progress && uploadingProgressCount) || is_content_uploading_error ) {
      setTimeout(() => {
        dispatch(setUploadProgressCount(0));
        setEditableContent(null);
        setTimeout(() => {dispatch(setIsContentUploadedError(false))}, 1000);
      }, 6000);
    }
    
    // if (is_content_uploading_error) {
    //   setTimeout(() => {
    //     dispatch(setUploadProgressCount(0));
    //     setTimeout(() => {dispatch(setIsContentUploadedError(false))}, 1000);
    //   }, 5000);
    // }

  }, [show_uploading_progress, uploadingProgressCount]);

  const handleUploadFile = async(file: any, duration?: any, message?: string) => {
    setVideoFile(null);
    if (file) {
      const { name: fileName, size, type } = file;
      const sizeInBytes = size;
      // Convert bytes to kilobytes (KB)
      const sizeInKB = sizeInBytes / 1024;
      // Convert bytes to megabytes (MB)
      const sizeInMB = sizeInKB / 1024;
      let fileSize = "";
      // if (sizeInMB > 15) {
      //   dispatch(setShowingUploadingProgress(false));
      //   dispatch(setIsContentUploadedError(true));
      //   dispatch(setErrorMessage(`Error. Maximum file size is 15 MB`));
      //   return;
      // }
      if (sizeInMB > 1) {
        fileSize = sizeInMB.toFixed(2) + " MB";
      } else {
        fileSize = sizeInKB.toFixed(2) + " KB";
      }

      const contentPayload: any = {
        clapupUserId: sessionStorage.getItem('cuid'),
        roleId: sessionStorage.getItem('roleId'),
        workspaceId: sessionStorage.getItem('wuid'),
        contentType: type?.includes("image") ? "GalleryImages" : "GalleryVideos",
        contentText: "",
        isFolder: "0",
        folderPath: path_array[0]?.replaceAll("%20", ""),
        contentFileName: fileName.replaceAll(" ", ""),
        fileSize,
        isAvailableinGallery: true,
        subCategory: type?.includes("image") ? "GalleryImages" : "GalleryVideos",
        isCreationCompleted: true
      };
      console.log({ contentPayload, file });
      try { 
        dispatch(createFolderContent(contentPayload, file));

        // const s3_bucket = {
        //   endpoint: `https://${aws_account_id}.r2.cloudflarestorage.com`,
        //   accessKeyId: `${aws_access_key}`,
        //   secretAccessKey: `${aws_secret_key}`,
        //   signatureVersion: 'v4',
        // };
        
        // const s3 = new S3(s3_bucket);
        // await navigator.clipboard.writeText(JSON.stringify(s3_bucket));
        // const aws_link = await s3.getSignedUrlPromise('putObject', s3_bucket);
        // if (aws_link) {
        //   dispatch(createFolderContent(contentPayload, file, aws_link));
        //   // setTimeout(() => {
        //     //   setTimeout(() => {
        //       //     dispatch(setShowingUploadingProgress(false));
        //       //   }, 2000);
        //       //   dispatch(setUploadProgressCount(0));
        //   // }, 3000);
        //   // dispatch(setUploadProgressCount(100));
        //   // dispatch(setShowingUploadingProgress(false));
        // } else {
        //   setTimeout(() => {
        //     dispatch(setShowingUploadingProgress(false));
        //     dispatch(setIsContentUploadedError(true));
        //     dispatch(setErrorMessage(`Your content is not uploaded`));
        //   }, 3000);
        // }

      } catch (err: any) {
        console.log({ message: err?.message });
        
        setTimeout(() => {
          dispatch(setShowingUploadingProgress(false));
          dispatch(setIsContentUploadedError(true));
          dispatch(setErrorMessage(err?.message || `Your content is not uploaded`));
        }, 3000);
        
      }
    } else {
      dispatch(setShowingUploadingProgress(false));
      dispatch(setIsContentUploadedError(true));
      dispatch(setErrorMessage(message || `Your content is not uploaded`));
    }
  };
  
  const [newVersionVideoFile, setNewVersionVideoFile] = React.useState<any>(null);
  const [editableContent, setEditableContent] = React.useState<any>(null);

  const handleUploadNewVersionFile = async(file: any, duration = 0, message?: string, ) => {
    setNewVersionVideoFile(null);
    if (file) {
      const { name: fileName, size, type } = file;
      const sizeInBytes = size;
      // Convert bytes to kilobytes (KB)
      const sizeInKB = sizeInBytes / 1024;
      // Convert bytes to megabytes (MB)
      const sizeInMB = sizeInKB / 1024;
      let fileSize = "";
      // if (sizeInMB > 15) {
      //   dispatch(setShowingUploadingProgress(false));
      //   dispatch(setIsContentUploadedError(true));
      //   dispatch(setErrorMessage(`Error. Maximum file size is 15 MB`));
      //   return;
      // }
      if (sizeInMB > 1) {
        fileSize = sizeInMB.toFixed(2) + " MB";
      } else {
        fileSize = sizeInKB.toFixed(2) + " KB";
      }

      const contentPayload: any = {
        clapupUserId: sessionStorage.getItem('cuid'),
        roleId: sessionStorage.getItem('roleId'),
        workspaceId: sessionStorage.getItem('wuid'),
        contentId: editableContent?.id,
        contentFileName: fileName.replaceAll(" ", ""),
        contentSize: fileSize,
      };
      try { 
        console.log({ contentPayload, file, duration: Math.round(duration) + " sec" });
        dispatch(updateFolderContentNewVersion(contentPayload, file, editableContent));
        // dispatch(setShowingUploadingProgress(false));
        // dispatch(setIsContentUploadedError(true));

      } catch (err: any) {
        console.log({ message: err?.message });
        
        setTimeout(() => {
          dispatch(setShowingUploadingProgress(false));
          dispatch(setIsContentUploadedError(true));
          setEditableContent(null);
          dispatch(setErrorMessage(err?.message || `Your content is not uploaded`));
        }, 3000);
        
      }
    } else {
      dispatch(setShowingUploadingProgress(false));
      dispatch(setIsContentUploadedError(true));
      setEditableContent(null);
      dispatch(setErrorMessage(message || `Your content is not uploaded`));
    }
  };

  const [isDeleteFolderContents, setIsDeleteFolderContents] = React.useState<boolean>(false);

  const handleDeleteFolderContents = (callBackFlag: any) => {
    if (callBackFlag) {
      // console.log({ selected_deletable_contents });
      dispatch(deleteContents(selected_deletable_contents));
      // dispatch(setSelectedDeletableContents([]));
      // dispatch(setSuccessMessage(`${selected_deletable_contents?.length > 1 ? "Files" : "File"} deleted successfully`));
      // setIsDeleteFolderContents(false);
    } else {
      setIsDeleteFolderContents(false);
    }
  };
  
  useEffect(() => {
    if (delete_message) {
      dispatch(setSuccessMessage(delete_message));
      setIsDeleteFolderContents(false);
      setTimeout(() => {
        dispatch(showDeleteMessage(null));
      }, 3000);
    }
  }, [delete_message]);

  const [currentContentData, setCurrentContentData] = React.useState<any>("");

  useEffect(() => {
    if (query_string?.includes('=contents') && activeFolder && path_array?.length > 3 && folder_contents?.length) {
      try {
        const idx = Number(path_array[3]);
        if (folder_contents?.length > idx || folder_contents?.length === idx) {
          if (folder_contents[idx]?.id) {
            // Configure AWS SDK with your credentials
            const data: any = folder_contents[idx];
            setCurrentContentData({
              ...data,
              contentUrl: data?.subCategory === "GalleryVideos" ? data?.videoUrl : data?.imageUrl
            });


            // download url
            // const { id: contentId, folderPath, contentTypeId, workspaceId, url: aws_pre_assigned_url, contentFileName, uniqueNo  } = data;
            // const lastDotIndex: any = contentFileName?.lastIndexOf('.');
            // const cloudFlarePath = `${folderPath}/` + contentFileName.slice(0, lastDotIndex) + `_${uniqueNo}.` + contentFileName.slice(lastDotIndex + 1);
            
            // AWS.config.update({
            //   accessKeyId: aws_access_key,
            //   secretAccessKey: aws_secret_key,
            // });

            // const s3 = new AWS.S3({
            //   endpoint: cloudFlareR2ServiceUrl,
            // });
            
            // // Create a request to get the object
            // const params: any = {
            //   Bucket: localStorage.getItem('wuid'),
            //   Key: cloudFlarePath,
            // };
            
            // // console.log({content_data: data, params});
            // s3.getObject(params, (err, result: any) => {
            //   if (err) {
            //     console.error('Error downloading file:', {content_data: data, params, err});
            //   } else {
            //     // Create a Blob from the Buffer
            //     const blob = new Blob([result.Body], { type: 'application/octet-stream' });
                
            //     // Create a download link
            //     const url = URL.createObjectURL(blob)
            //       // + `.` + contentFileName.slice(lastDotIndex + 1);
            //     console.log({ contentFileName, aws_url: url });
            //     data["contentUrl"] = url;
            //     setCurrentContentData({...data});
            //   }
            // });
          } else {
            navigate(`${route_path}?${activeFolder?.folderName}=folders`);
          }
          // dispatch(setSelectedDeletableContents([contents[idx]?.id]));
        } else {
          navigate(`${route_path}?${activeFolder?.folderName}=folders`);
        }
      } catch (error) {
        console.log({ error });
        navigate(`${route_path}?all=folders`);
        // navigate(`${route_path}?${activeFolder}=folders`);
      }
    }
  }, [query_string, activeFolder?.folderName, path_array?.length, folder_contents?.length]);

  // console.log({showing_save_button});
  // console.log({uploadingProgressCount, show_uploading_progress});
  const handleSave = (data: any) => { 
    console.log({ data, updatable_content, default_content_idx });
    // return;
    dispatch(saveContentDetails({...updatable_content, contentId: data?.id}, default_content_idx));

  };

  const handleSortFolderType = (value: any) => { 
    dispatch(setSortFolderListSortType(value));
  };

  const handleSortContentType = (value: any) => { 
    dispatch(setSortContentListSortType(value));
  };

  useEffect(() => { 
    if (!query_string?.includes('=contents')) {
      handleSortContentType(null);
    } else {
      handleSortFolderType(null);
    }
  }, [query_string]);
  // using for checking duration of the video
  const [videoFile, setVideoFile] = React.useState<any>(null);
  // console.log({ activeFolder, preview_flag: default_content_idx >= 0 && folder_contents?.length > default_content_idx });
  return (
    <React.Fragment>
      {/* New Video content ----- Start */}
      {
        videoFile
        ? <VideoDurationCheck videoFile={videoFile} handleUploadFile={handleUploadFile}/>
        : null
      }
      {/* New Video content ----- End */}

      {/* Previous Video content for new version ----- Start */}
      {
        newVersionVideoFile
        ? <VideoDurationCheck videoFile={newVersionVideoFile} handleUploadFile={handleUploadNewVersionFile}/>
        : null
      }
      {/* Previous Video content for new version ----- End */}

      {/* Content Uploading Time */}
      {uploadingProgressCount ?
        <Dialog open={true} crossIcon={false}>
          <div className='loader-container'>
            <h3 className='loader-title'>File being uploaded</h3>
            <div className='loader-body'>
              <Progress
                percent={is_content_uploading_error ? 30 : !show_uploading_progress ? 100 : uploadingProgressCount}
                size="small"
                status={is_content_uploading_error ? "exception" : !show_uploading_progress ? "success" : uploadingProgressCount !== 100 ? "active" : "exception"}
                // strokeColor={"#108ee9"}
                showInfo={true}
                trailColor={"#385682"}
              // strokeColor={"#00b300"}
              />
            </div>
          </div>
        </Dialog>
        : null
      }

      {/* Folder Creation Time */}
      {
        is_showing_create_folder ?
          <Dialog open={is_showing_create_folder} classes={"form-dialog-container"}
            parentCallback={() => {
              dispatch(toggleCreateFolderModal(!is_showing_create_folder));
            }}
            crossIcon={false}
          >
          <FolderForm parentCallback={handleCreateFolder}/>
        </Dialog>
        : null
      }
      
      {/* Delete Folder */}
      {isDeleteFolderContents ?
        <Dialog
          open={isDeleteFolderContents}
          parentCallback={(flag: any) => handleDeleteFolderContents(flag)}
          classes='dialog-container-content'
          title='Delete Files?'
          warning_dialog_content={`If you click Yes, ${selected_deletable_contents?.length > 1 ? "files" : "file"} will be deleted forever. This action is irreversible.` }
          footer={true}
          left_button_text='No, Cancel'
          right_button_text='Yes, Delete'
        />
        : null}
      
      
      {/* Gallery */}
      {/* <MobileBottomTabs/> */}
      {/* { 
        query_string?.includes('=contents')
        ?   <div className='mobile-content-navbar'>
              <div className='file-view-back-path ms-2'>
                <img
                  className='content-path-back'
                  onClick={() => {
                    // navigate(`${route_path}?${activeFolder?.folderName}=folders`)
                    navigate(`${route_path}?${activeFolder?.folderName}=folders`)
                  }}
                  src={back_path}
                  alt="content-path-icon"
                />
              </div>
              <Workspace marginTop={10} height={26} width={'auto'} />
              
            </div>
        : null
      } */}

      <div className="gallery-section">
        <div className={"content-section position-sticky top-0"}>
          <div className={`content-search-section ${query_string?.includes('=contents') ? "preview-section" : ""}`}>
            <div className="search-wrap">
              {
                query_string?.includes('=contents') ? null
                : activeFolder ?
                  <div className="search-container">
                    <DebounceInput
                      minLength={0}
                      debounceTimeout={500}
                      onChange={(e: any) => handleSearch(e.target.value)}
                      type="search"
                      placeholder={"Search"}
                    />
                    {/* <input onChange={(e: any) => handleSearch(e.target.value)} type="search" alt="" placeholder="Search" /> */}
                    <img className="search-icon" src={search} alt="search" />
                  </div>
                  : path?.includes('all') ?
                      <div className="search-container">
                        <DebounceInput
                          minLength={0}
                          debounceTimeout={500}
                          onChange={(e: any) => handleSearch(e.target.value)}
                          type="search"
                          placeholder={"Search"}
                        />
                        {/* <input onChange={(e: any) => handleSearch(e.target.value)} type="search" alt="" placeholder="Search" /> */}
                        <img className="search-icon" src={search} alt="search" />
                      </div>
                  : null
              }
            </div>
            
            <div className='content-buttons'>
              {activeFolder ? (
                <div className='content-button-container'>
                  <img
                    onClick={() => {
                      if (selected_deletable_contents?.length || default_content_idx !== null) {
                        setIsDeleteFolderContents(true);
                      } else {
                        dispatch(setErrorMessage("Please select files to delete"));
                      }
                    }}
                    className='bulk-delete-icon'
                    src={delete_icon} alt=""
                  />
                  {
                    query_string?.includes('=contents')
                    ? <>
                        <button
                          type='button'
                          className="content-btn"
                          onClick={() => {
                            handleSave(currentContentData);
                          }}
                        >
                          <p>Save</p>
                          <img src={save_icon} alt="" />
                        </button>
                        {/* {
                          showing_save_button
                          ? <button
                              type='button'
                              className="content-btn"
                              onClick={() => {
                                handleSave(currentContentData);
                              }}
                            >
                              <p>Save</p>
                              <img src={save_icon} alt="" />
                            </button>
                          : null
                        } */}
                        {/* "https://media.w3.org/2010/05/sintel/trailer_hd.mp4" */}
                        <div className='text-decoration-none'
                          onClick={async() => {
                            // const contentUrl = await downloadContent(currentContentData);
                            // const contentUrl: any = await download2Content("https://images.clapup.io/website/Clapup-dashboard.png");
                            const contentUrl: any = await download2Content(currentContentData);
                            if(contentUrl) {
                              console.log({contentUrl});
                            } else {

                              dispatch(setErrorMessage("Something went wrong, please try again letter"))
                            }
                          }}
                          // download={currentContentData?.contentFileName}
                          // href={currentContentData?.contentUrl}
                          // href={"https://dc474190-f363-4e8e-9949-8cdac65b4d44.7e93201b14c845ca2c0a69a67557e543.r2.cloudflarestorage.com/kuber-test/mmhk40_13.png?X-Amz-Expires=3600&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=bd755bf91cf43678d86ec58a1059cdb9%2F20231129%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231129T132902Z&X-Amz-SignedHeaders=host&X-Amz-Signature=b5b978dadf7b552474637fbd1db628f17c2e1ef3dcc31f067e2e7e529bd6fd15"}
                          // target='_blank'
                        >
                          <div className="content-btn">
                            <p>Download</p>
                            <img src={download_icon} alt="" />
                          </div>
                        </div>
                      </>
                    : <>
                    <label htmlFor={`content`}>
                      <div className={`content-btn ${activeFolder?.isFromSystem ? "disabled" : ""}`}
                        onClick={() => {
                          if (activeFolder?.isFromSystem) {
                            dispatch(setErrorMessage("Error. You can't upload content in system folder"));
                          }
                        }}
                      >
                        <p>Upload</p>
                        <img src={upload_icon} alt="" />
                      </div>
                    </label>
                    <input
                      id="content" type="file"
                      disabled={activeFolder?.isFromSystem ? true : false}
                      accept='video/*, image/png, image/jpg, image/jpeg, image/svg'
                      onChange={async(e: any) => {
                        // console.log({ img: e?.target?.files[0] });

                        dispatch(setShowingUploadingProgress(true));
                        dispatch(setUploadProgressCount(1));
                        try {
                          const file = e.target.files[0];
                          const { type } = file;

                          if (type?.includes("video")) {
                            setVideoFile(file);
                          } else {
                            handleUploadFile(file);
                          }
                          

                          // const reader = new FileReader();
    
                          // reader.onloadend = () => {
                          //   const base64String = reader.result;
                          //   setTimeout(() => {
                          //     handleUploadFile(base64String as any);
                          //   }, 5000);
                          // };
                          // reader.readAsDataURL(file);
                          // throw new Error("Error, try again later");
                        } catch (err: any) {
                          console.log({ err });
                          dispatch(setErrorMessage("Something went wrong"));
                        }

                      }}
                      className="d-none"
                    />
                    </>
                  }
                </div>
              ) : path?.includes('all') ? (
                  <Button
                    classes="secondary-btn create-folder-btn"
                    children={<p>Create Folder</p>}
                    onClick={() => {
                      dispatch(toggleCreateFolderModal(true));
                    }}
                  />
              ) : null}
              
            </div>
          </div>

          {
            query_string?.includes('=contents')
            ? null
            :
            <div className="mobile-search-container">
              <Search
                parentCallback={handleSearch}
                parentKey="gallery"
                search_placeholder={
                  !activeFolder ? "Search Folders" : "Search Files"
                }
              />

              {
                activeFolder && !query_string?.includes('=contents')
                ? <div className='file-back-path'>
                    <img
                      className='content-path-back'
                      onClick={() => {
                        // navigate(`${route_path}?${activeFolder?.folderName}=folders`)
                        navigate(`${route_path}?all=folders`)
                      }}
                      src={back_path}
                      alt="content-path-icon"
                    />
                    {/* <img className='content-path-icon' src={back_path} alt="content-path-icon" /> */}
                    {/* <span className='content-path-text'>Back</span> */}
                  </div>
                : null
              }
              {/* <DebounceInput
                minLength={0}
                debounceTimeout={500}
                onChange={(e: any) => handleSearch(e.target.value)}
                type="search"
                placeholder={"Search"}
              /> */}
              {/* <input onChange={(e: any) => handleSearch(e.target.value)} type="search" alt="" placeholder="Search" /> */}
              {/* <img className="search-icon" src={search} alt="search" /> */}
            </div>
          }

          <div className='mobile-content-buttons'>
            {activeFolder ? (
              // <div style={{display: 'flex', gap: "10%"}}>
              <>
                {
                  query_string?.includes('=contents') && default_content_idx >= 0 && folder_contents?.length > default_content_idx
                  ?
                    <div className='special-sm-device'>
                      <img
                        className='content-path-back'
                        onClick={() => {
                          // navigate(`${route_path}?${activeFolder?.folderName}=folders`)
                          navigate(`${route_path}?${activeFolder?.folderName}=folders`)
                        }}
                        src={back_path}
                        alt="content-path-icon"
                      />
                    </div>
                  : null
                }
                {/* {
                  query_string?.includes('=contents') && default_content_idx >= 0 && folder_contents?.length > default_content_idx
                    ? <div className="special-sm-device">
                      {modifyString(folder_contents[default_content_idx]?.contentFileName, 3, 4, "..")}
                    </div>
                    : null
                } */}
                <img
                  onClick={() => {
                    if (selected_deletable_contents?.length || default_content_idx !== null) {
                      setIsDeleteFolderContents(true);
                    } else {
                      dispatch(setErrorMessage("Please select files to delete"));
                    }
                  }}
                  className='bulk-delete-icon special-lg-md-device'
                  src={delete_icon} alt=""
                />
                {
                  query_string?.includes('=contents')
                    ? <>
                      <img
                        onClick={() => {
                          if (selected_deletable_contents?.length || default_content_idx !== null) {
                            setIsDeleteFolderContents(true);
                          } else {
                            dispatch(setErrorMessage("Please select files to delete"));
                          }
                        }}
                        // className='bulk-delete-icon special-lg-md-device'
                        className='bulk-delete-icon'
                        src={delete_icon} alt=""
                      />
                      <button
                        type='button'
                        className="content-btn"
                        onClick={() => {
                          handleSave(currentContentData);
                        }}
                      >
                        <p>Save</p>
                        <img src={save_icon} alt="" />
                      </button>
                      {/* {
                        showing_save_button
                        ? <button
                            type='button'
                            className="content-btn"
                            onClick={() => {
                              handleSave(currentContentData);
                            }}
                          >
                            <p>Save</p>
                            <img src={save_icon} alt="" />
                          </button>
                        : null
                      } */}
                      {/* "https://media.w3.org/2010/05/sintel/trailer_hd.mp4" */}
                      <div className='text-decoration-none'
                        onClick={async() => {
                          // const contentUrl = await downloadContent(currentContentData);
                          // const contentUrl: any = await download2Content("https://images.clapup.io/website/Clapup-dashboard.png");
                          const contentUrl: any = await download2Content(currentContentData);
                          if(contentUrl) {
                            console.log({contentUrl});
                          } else {

                            dispatch(setErrorMessage("Something went wrong, please try again letter"))
                          }
                        }}
                        // download={currentContentData?.contentFileName}
                        // href={currentContentData?.contentUrl}
                        // href={"https://dc474190-f363-4e8e-9949-8cdac65b4d44.7e93201b14c845ca2c0a69a67557e543.r2.cloudflarestorage.com/kuber-test/mmhk40_13.png?X-Amz-Expires=3600&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=bd755bf91cf43678d86ec58a1059cdb9%2F20231129%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231129T132902Z&X-Amz-SignedHeaders=host&X-Amz-Signature=b5b978dadf7b552474637fbd1db628f17c2e1ef3dcc31f067e2e7e529bd6fd15"}
                        // target='_blank'
                      >
                        <div className="content-btn">
                          <p>Download</p>
                          <img src={download_icon} alt="" />
                        </div>
                      </div>
                    </>
                  : <>
                    <Select
                      className='content-select'
                      onChange={(value: any) => { 
                        console.log({content_list_sort_value: value, content_sort_type});
                        handleSortContentType(value);
                      }}
                      value={content_sort_type || null}
                      placeholder="Select"
                    >
                      <Select.Option value="Name">Name</Select.Option>
                      <Select.Option value="Date">Date</Select.Option>
                    </Select>
                    <label htmlFor={`content`}>
                      <div className={`content-btn ${activeFolder?.isFromSystem ? "disabled" : ""}`}
                        onClick={() => {
                          if (activeFolder?.isFromSystem) {
                            dispatch(setErrorMessage("Error. You can't upload content in system folder"));
                          }
                        }}
                      >
                        <p>Upload</p>
                        <img src={upload_icon} alt="" />
                      </div>
                    </label>
                    <input
                      id="content" type="file"
                    disabled={activeFolder?.isFromSystem ? true : false}
                    accept='video/*, image/png, image/jpg, image/jpeg, image/svg'
                    onChange={async(e: any) => {
                      // console.log({ img: e?.target?.files[0] });

                      dispatch(setShowingUploadingProgress(true));
                      dispatch(setUploadProgressCount(1));
                      try {
                        const file = e.target.files[0];
                        const { type } = file;

                        if (type?.includes("video")) {
                          setVideoFile(file);
                        } else {
                          handleUploadFile(file);
                        }
                        

                        // const reader = new FileReader();
  
                        // reader.onloadend = () => {
                        //   const base64String = reader.result;
                        //   setTimeout(() => {
                        //     handleUploadFile(base64String as any);
                        //   }, 5000);
                        // };
                        // reader.readAsDataURL(file);
                        // throw new Error("Error, try again later");
                      } catch (err: any) {
                        console.log({ err });
                        dispatch(setErrorMessage("Something went wrong"));
                      }

                    }}
                    className="d-none"
                  />
                  </>
                }
              </>
            ) : path?.includes('all') ? (
                <>
                  <Select
                    className='content-select'
                    onChange={(value: any) => { 
                      console.log({folder_list_sort_value: value, folder_sort_type});
                      handleSortFolderType(value);
                    }}
                    value={ folder_sort_type || null}
                    placeholder="Select"
                  >
                    <Select.Option value="Name">Name</Select.Option>
                    <Select.Option value="Date">Date</Select.Option>
                  </Select>
                  <Button
                    classes="secondary-btn create-folder-btn"
                    children={<p>Create Folder</p>}
                    onClick={() => {
                      dispatch(toggleCreateFolderModal(true));
                    }}
                  />
                </>
            ) : null}
          
            {/* File Name */}
          </div>
          {
            query_string?.includes('=contents') && default_content_idx >= 0 && folder_contents?.length > default_content_idx
            ? <div className="special-sm-device full-file-name">
                {/* {modifyString(folder_contents[default_content_idx]?.contentFileName, 3, 4, "..")} */}
                {folder_contents[default_content_idx]?.contentFileName}
              </div>
            : null
          }

          <div className={`content-wall ${query_string?.includes('=contents') ? "preview-section mobile-content-wall" : ""}`}>
            <div className={`${query_string?.includes('=contents') ? "preview-" : ""}content-path`}>
              {
                !query_string?.includes('=contents') && activeFolder ?
                  <span className='content-path-text'
                    onClick={() => {
                      navigate(`${route_path}?all=folders`)
                    }}
                  >
                    All Files
                  </span>
              : query_string?.includes('=contents') && activeFolder
                  ? <div>
                      <img
                        className='content-path-back'
                        onClick={() => {
                          navigate(`${route_path}?${activeFolder?.folderName}=folders`)
                        }}
                        src={back_path}
                        alt="content-path-icon"
                      />
                    {/* <img className='content-path-icon' src={back_path} alt="content-path-icon" /> */}
                    {/* <span className='content-path-text'>Back</span> */}
                    </div>
                  : path?.includes('all') ?
                    <div className='content-path-text'
                      onClick={() => {
                        navigate(`${route_path}?all=folders`)
                      }}
                    >
                      All Files
                    </div>
                  : null
              }

              {
                (activeFolder?.folderName && !query_string?.includes('=contents')) ? (
                  <>
                    {/* <span className='content-path-icon'> { ">" }</span> */}
                    <img className='content-path-icon' src={next_path} alt="content-path-icon" />
                    <span className='content-path-text'>{activeFolder?.displayName}</span>
                  </>
                ) : null
              }
            </div>

            {
              activeFolder?.folderName && folder_contents?.length
              ? <Contents handleUploadNewVersionFile={handleUploadNewVersionFile} setNewVersionVideoFile={setNewVersionVideoFile} setEditableContent={setEditableContent} activeFolder={activeFolder?.folderName} />
              : path?.includes('all') ? <Folders/>
              : null
              
              // : <div className='d-flex justify-content-center align-items-center' style={{height: '70vh'}}>
              //     <Spinner
              //         // size="md"
              //         animation="border"
              //         variant="primary"
              //     />
              //   </div>
            }
              
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
