import React from 'react';
import { Input } from 'antd';
import { RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { updateContentBeforeSaving } from '../../mediaReducer';
export default function SeoTab() {
  const dispatch = useDispatch();
  const { updatable_content, folder_contents, default_content_idx }: any = useSelector((state: RootState) => state?.mediaState);
  // const { default_content_idx, folder_contents }: any = useSelector((state: RootState) => state?.mediaState);
  // const content = folder_contents[default_content_idx];
  // const content_payload = {
  //   contentId: content?.id,
  //   sEO_Title: content?.sEO_Title,
  //   sEO_UrlSlug: content?.sEO_UrlSlug,
  //   sEO_Desc: content?.sEO_Desc,
  //   bioPage_Title: content?.bioPage_Title,
  //   bioPage_Caption: content?.bioPage_Caption,
  //   bioPage_Story: content?.bioPage_Story,
  //   properties_Keywords: content?.properties_Keywords,
  // };
  // console.log({updatable_content_payload});
  return (
    <div className='SEO-seo-container scroll-content'>
        <div className='form-group'>
          <label className="form-label">URL Slug</label>
          <Input
            className='form-input'
            placeholder='Bio Page Url Slug (For Adding to biopage)'
            onChange={(e: any) => {
              const value = e.target.value;
              dispatch(updateContentBeforeSaving({
                key: "sEO_UrlSlug",
                value: value?.toLowerCase().replace(/\s/g, "_"),
              }))
            }}
            value={updatable_content?.sEO_UrlSlug}
          />
        </div>
        <div className='form-group'>
          <label className="form-label">Page Title</label>
          <Input
            className='form-input'
            placeholder='Add Meta Title'
            onChange={(e: any) => {
              const value = e.target.value;
              dispatch(updateContentBeforeSaving({
                key: "sEO_Title",
                value,
              }));
            }}
            value={updatable_content?.sEO_Title}
          />
        </div>
        <div className='form-group'>
          <label className="form-label">Page Description</label>
          <Input.TextArea
              className='form-textarea'
              rows={6}
              placeholder='Meta Description This will be shown as snippet in search engines'
              onChange={(e: any) => {
                const value = e.target.value;
                dispatch(updateContentBeforeSaving({
                  key: "sEO_Desc",
                  value,
                }));
              }}
              value={updatable_content?.sEO_Desc}
          />
        </div>
    </div>
  )
}
