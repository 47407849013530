import React, {useState, useEffect} from "react";
import "./TeamsManagement.scss";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getImage } from "../../../../unified/image_service";
import { setEditableTeamChannel, toggleUpdatedTeamContent, triggerRemoveTeamChannel } from "../teamsReducer";
import ChannelSettings from "./ChannelSettings";
import AddChannel from "./AddChannel";
import CommonModalCustomWidth from "../../../../components/CommonModal/CommonModalCustomWidth";
import { setReadyToAddChannel } from "../teamsReducer";
import Dialog from "../../../../components/Dialogs/Dialog";
// import { setChannelTab, setEditableChannel } from "../teamsReducer";

const Channels: React.FC = () => {
    const { editable_team_channel, is_updated_team_content, editable_team, is_ready_to_add_channel, team_channels: channels} = useSelector((state: any) => state?.teamState);
    const dispatch = useDispatch();
    const [isDeleteTeamChannel, setIsDeleteTeamChannel] = useState<any>(null);
    // const [channels, setChannels] = useState<any>([
    //     { channelId: 1, inbox: 'Review Inbox', channelName: "mmhk@gmail.com" },
    //     { channelId: 2, inbox: 'Marketing channel', channelName: "mmhk@gmail.com" },
    //     { channelId: 3, inbox: 'Human Resource', channelName: "mmhk@gmail.com" },
    // ]);

    useEffect(() => {
        if (is_updated_team_content) {
            dispatch(toggleUpdatedTeamContent(false));
            handleCancel();
        }
    }, [is_updated_team_content]);

    const handleCancel = () => {
        dispatch(setReadyToAddChannel(false));
        setIsDeleteTeamChannel(null);
    }

    const handleDeleteTeamChannel= (channel?: any) => {
        if (!channel) {
            setIsDeleteTeamChannel(null);
            return;
        }
        const clapupUserId = localStorage.getItem('cuid') || sessionStorage.getItem('cuid') || null;
        // const workspaceId = localStorage.getItem('wuid') || sessionStorage.getItem('wuid') || null;
        // const roleId = localStorage.getItem('roleId') || sessionStorage.getItem('roleId') || null;
        console.log("Came for removing channel...", { clapupUserId, channel });
        const data_payload = {
            clapupUserId,
            teamId: editable_team?.teamId,
            channelId: channel?.channelId
        }
        dispatch(triggerRemoveTeamChannel(data_payload));
    }
    
    const handleEditableChannel = (channel: any) => {
        console.log({channel_for_editing: channel})
        dispatch(setEditableTeamChannel(channel));
    }

    const handleRowClick = (channel: any) => {
        handleEditableChannel(channel);
    };

    return (
        <React.Fragment>
            <CommonModalCustomWidth 
                modal_visible={is_ready_to_add_channel} 
                setModalVisible={(flag: any) => dispatch(setReadyToAddChannel(flag))}
                centered={true}
                // okText="Send"
                // cancelText="Cancel"
                padding={"0"}
                class_name="add-channel-setting-table-container"
                footer={null}
            >
                <AddChannel handleCancel={handleCancel} />
            </CommonModalCustomWidth>

            {/* Delete Team Member */}
                <Dialog
                    open={isDeleteTeamChannel}
                    parentCallback={(flag: any) => handleDeleteTeamChannel(flag)}
                    classes='dialog-container-content'
                    title='Remove Channel?'
                    warning_dialog_content={`If you click Yes,  the channel will be removed. This action is irreversible.` }
                    footer={true}
                    left_button_text='No, Cancel'
                    right_button_text='Yes, Delete'
                />
            {
                editable_team_channel ?
                    <ChannelSettings/>
                :   channels?.length ?
                    <div className="channel-list-table">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="table-header-row-content">
                                                <div className="channel-header-title-container">Name</div>
                                                <div className="inbox-channel">Inbox</div>
                                                <div>Action</div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {channels?.map((channel: any, idx: number) => (
                                        <tr key={idx}>
                                            <td>
                                                <div
                                                    className="table-row-content"
                                                    onClick={() => handleRowClick(channel)}
                                                >
                                                    <div className="channel-title-container">
                                                        <img src={getImage("gmail-icon-circle")} alt="gmail" />
                                                        {/* <div className="channel-icon" >
                                                            <img src={getImage("gmail-icon-circle")} alt="gmail" />
                                                        </div> */}
                                                        <div className="channel-title">
                                                            <div>{channel?.channelName}</div>
                                                            <div className="channel-provider">Gmail</div>
                                                        </div>
                                                    </div>
                                                    <div className="inbox-channel">{channel?.inbox || "N/A"}</div>
                                                    <div
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevents triggering the row click
                                                            setIsDeleteTeamChannel(channel);
                                                        }}
                                                    >
                                                        Remove
                                                    </div>
                                                    {/* <img
                                                        className="delete-icon"
                                                        src={getImage("delete-icon")}
                                                        alt="Delete"
                                                        
                                                    /> */}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                :   null
            }
            <div className="extra"></div>
        </React.Fragment>
    );
};

export default Channels;