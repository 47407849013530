import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSuccessMessage } from "../../unified/reducer";
import "./Notifications.scss";
import { getImage } from "../../unified/image_service";
import un_fill_success_tik from '../../assets/icons/pro-settings/un-fill-success-tik-1.33.svg';


// type Props = {
//   message?: string;
//   isShow?: boolean;
//   removeDialog?: any;
// };

const Success = () => {
  const dispatch = useDispatch();
  const { success_message } = useSelector(
      (state: any) => state?.unified
  );
  useEffect(() => {
    if (success_message) {
    // if (!success_message) {
      setTimeout(() => {
        // dispatch(setSuccessMessage("Success Message"));
        dispatch(setSuccessMessage(null));
      }, 3000);
    }
  }, [success_message]);

  return success_message ? (
    <div className={"dialog"}>
      <div className={`dialog-container`}>
        <div
          // onClick={() => dispatch(setSuccessMessage(null))}
          className={`success-dialog`}
        >
          {/* <img src={getImage("un-fill-success-tik")} alt={"fill-success"}/> */}
          <img src={un_fill_success_tik} alt={"fill-success"}/>
          <p className="success-message">
            {
              success_message || "Success message here!"
            }
          </p>
        </div>
      </div>
    </div>
  ) : null;
}
export default Success;
