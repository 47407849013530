import { takeEvery, put, call, all, delay } from 'redux-saga/effects';
import axios from "axios";
import Constants from '../../../constants';
import { getBioPageAnnouncements, getBioPageLinks, getBioPageSettings, getBioPageTemplates, toggleSelectedTemplate, setBioPageTemplates, setBioPageAnnouncements, setBioPageLinks, setBioPageSettings, setEmbedImageRecords, setEmbedVideoRecords, setFirstTimeEmbedImageRecords, setFirstTimeEmbedVideoRecords, setUpdatedBioPageSettingsData } from './bioPageReducer';
import { generateAccessToken } from '../../../profile/reducer';
import { api_url } from '../../../utils/server';
import { setErrorMessage, setSuccessMessage } from '../../../unified/reducer';

// Create Content
export const uploadIconFileData = async(data: any) => {
    try {
        const {
            contentFile,
            // isForBioPage,
            contentType
        } = data;
        if (!contentFile) {
            return { success: true, message: "File not found" };
        }
        const common_payload: any = {
            clapupUserId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
        };
        const url = api_url + "/api/v1.2/gallery/CreateContent";
        const { name: fileName, size, type }: any = contentFile;
        const sizeInBytes = size;
        // Convert bytes to kilobytes (KB)
        const sizeInKB = sizeInBytes / 1024;
        // Convert bytes to megabytes (MB)
        const sizeInMB = sizeInKB / 1024;
        let fileSize = "";
        // if (sizeInMB > 15) {
        //   dispatch(setShowingUploadingProgress(false));
        //   dispatch(setIsContentUploadedError(true));
        //   dispatch(setErrorMessage(`Error. Maximum file size is 15 MB`));
        //   return;
        // }
        if (sizeInMB > 1) {
          fileSize = sizeInMB.toFixed(2) + " MB";
        } else {
          fileSize = sizeInKB.toFixed(2) + " KB";
        }
        let content_payload: any = {
            contentType: contentType || "ImageAssets",
            folderPath: contentType || "ImageAssets",
            subCategory: contentType || "ImageAssets",
            contentText: "",
            isFolder: "0",
            contentFileName: fileName.replaceAll(" ", ""),
            fileSize,
            isAvailableinGallery: false,
            isCreationCompleted: true,
            ...common_payload,
        };
        if (type?.includes("video")) {
            content_payload["contentType"] = "VideoAssets";
            content_payload["folderPath"] = 'VideoAssets';
            content_payload["subCategory"] = "VideoAssets";
        }
       
        // if (isForBioPage) {
        //     content_payload["contentType"] = "BiopageLogo";
        //     content_payload["folderPath"] = 'BiopageLogo';
        //     content_payload["subCategory"] = "BiopageLogo";
        // }

        const {
            data: content_response,
            status: content_status
        }: any = await axios.post( url, content_payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });

        if (content_status !== 200) {
            return { success: false, message: "Create content error" };
        }

        const {
            id: contentId, folderPath,
            contentTypeId, workspaceId,
            url: aws_pre_assigned_url,
            contentFileName, uniqueNo
        } = content_response;
        const lastDotIndex: any = contentFileName?.lastIndexOf('.');
        // const new_image_file_name = `${folderPath}/` + contentFileName.slice(0, lastDotIndex) + `_${uniqueNo}.` + contentFileName.slice(lastDotIndex + 1);
        let mimetype = contentFileName?.slice(lastDotIndex + 1);
        if (mimetype) {
            if (mimetype?.toLowerCase() === "jpg") {
                mimetype = "image/jpeg";
            }
            else if (mimetype?.toLowerCase() === "jpeg") {
                mimetype = "image/jpeg";
            }
            else if (mimetype?.toLowerCase() === "png") {
                mimetype = "image/png";
            }
            else if (mimetype?.toLowerCase() === "svg") {
                mimetype = "image/svg+xml"
            }
            else if (mimetype?.toLowerCase() === "mp4") {
              mimetype = "video/mp4";
            }
            else if (mimetype?.toLowerCase() === "mkv") {
                mimetype = "video/x-matroska";
            }
            else if (mimetype?.toLowerCase() === "webm") {
                mimetype = "video/webm";
            }
            else if (mimetype?.toLowerCase() === "avi") {
              mimetype = "video/x-msvideo";
            }
            else if (mimetype?.toLowerCase() === "mov") {
              mimetype = "video/quicktime"
            }
            else if (mimetype?.toLowerCase() === "wmv") {
              mimetype = "video/x-ms-wmv"
            };
        }


        
        const { status: aws_status }: any = await axios.put(aws_pre_assigned_url, contentFile, {
            headers: {
                'Content-Type': `${mimetype}`, // Set the Content-Type based on the file type
            },
        });

        if (aws_status !== 200) {
            return { success: false, message: "AWS error" };
        }        
        const cloudFlareR2Path = `${folderPath}@${contentFileName.slice(0, lastDotIndex) + `_${uniqueNo}.` + contentFileName.slice(lastDotIndex + 1)}`;
        const cloud_flare_url = api_url + "/api/v1.2/gallery/UpdateCloudFlareR2UploadStatusAfterContentCreation";
        const { data: cloud_data, status: cloud_status }: any = await axios.post(
            cloud_flare_url,
            { workspaceId, contentId, cloudFlareR2Path },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        // const status = 200;
        if (cloud_status !== 200) {
            return { success: false, message: "Cloudflare status error" };
        }
        
        const stage_url = api_url + "/api/v1.2/gallery/UpdateContentStages";
        const { data: stage_response, status: stage_status }: any = await axios.post(
            stage_url,
            {
                ...common_payload,
                workspaceId,
                contentId,
                stageName: "publish",
                stageValue: "1"
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        if (stage_status !== 200) {
            return { success: false, message: "Update stage error" };
        }
        

        const { data: bio_page_stage_response, status: bio_page_stage_status }: any = await axios.post(
            stage_url,
            {
                ...common_payload,
                workspaceId,
                contentId,
                stageName: "biopage",
                stageValue: "1"
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        if (bio_page_stage_status !== 200) {
            return { success: false, message: "Update bio-page stage error" };
        }
        
        const currentContent: any = {
            ...content_response,
            presignedUrl: cloud_data?.presignedUrl,
            ...stage_response,
            contentId
        };

        return {
            success: true,
            iconUrl: stage_response?.url,
            data: currentContent,
            message: "Published in Biopage"
        };
        

    } catch (error) {
        console.log('error', error);
        return { success: false, message: "Server error" };
    }
}

// Update Content
export const updateIconFileData = async(data: any) => {
    try {
        const { contentFile, content, contentType } = data;
        const { contentId, uniqueNo } = content;

        if (!contentFile) {
            return { success: true, message: "File not found" };
        }
        const common_payload: any = {
            clapupUserId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
        };
        const url = api_url + "/api/v1.2/gallery/UploadNewVersion";
        const { name: fileName, size, type } = contentFile;
        const sizeInBytes = size;
        // Convert bytes to kilobytes (KB)
        const sizeInKB = sizeInBytes / 1024;
        // Convert bytes to megabytes (MB)
        const sizeInMB = sizeInKB / 1024;
        let fileSize = "";
        // if (sizeInMB > 15) {
        //   dispatch(setShowingUploadingProgress(false));
        //   dispatch(setIsContentUploadedError(true));
        //   dispatch(setErrorMessage(`Error. Maximum file size is 15 MB`));
        //   return;
        // }
        if (sizeInMB > 1) {
          fileSize = sizeInMB.toFixed(2) + " MB";
        } else {
          fileSize = sizeInKB.toFixed(2) + " KB";
        }
        const content_payload: any = {
            ...common_payload,
            contentId,
            contentFileName: fileName.replaceAll(" ", ""),
            contentSize: fileSize
        };
        const {
            data: content_response,
            status: content_status
        }: any = await axios.post( url, content_payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });

        if (content_status !== 200) {
            return { success: false, message: "Create content error" };
        }

        const { url: aws_pre_assigned_url } = content_response;
        const contentFileName = fileName.replaceAll(" ", "");

        const lastDotIndex: any = contentFileName?.lastIndexOf('.');
        let mimetype = contentFileName?.slice(lastDotIndex + 1);
        if (mimetype) {
            if (mimetype?.toLowerCase() === "jpg") {
                mimetype = "image/jpeg";
            }
            else if (mimetype?.toLowerCase() === "jpeg") {
                mimetype = "image/jpeg";
            }
            else if (mimetype?.toLowerCase() === "png") {
                mimetype = "image/png";
            }
            else if (mimetype?.toLowerCase() === "svg") {
                mimetype = "image/svg+xml"
            }
            else if (mimetype?.toLowerCase() === "mp4") {
              mimetype = "video/mp4";
            }
            else if (mimetype?.toLowerCase() === "mkv") {
                mimetype = "video/x-matroska";
            }
            else if (mimetype?.toLowerCase() === "webm") {
                mimetype = "video/webm";
            }
            else if (mimetype?.toLowerCase() === "avi") {
              mimetype = "video/x-msvideo";
            }
            else if (mimetype?.toLowerCase() === "mov") {
              mimetype = "video/quicktime"
            }
            else if (mimetype?.toLowerCase() === "wmv") {
              mimetype = "video/x-ms-wmv"
            };
        }
        
        const { status: aws_status }: any = await axios.put(aws_pre_assigned_url, contentFile, {
            headers: {
                'Content-Type': `${mimetype}`, // Set the Content-Type based on the file type
            },
        })

        if (aws_status !== 200) {
            return { success: false, message: "AWS error" };
        }
        const folder_path = contentType || "ImageAssets";

        const cloudFlareR2Path = `${folder_path}@${contentFileName.slice(0, lastDotIndex) + `_${uniqueNo}.` + contentFileName.slice(lastDotIndex + 1)}`;
        const cloud_flare_url = api_url + "/api/v1.2/gallery/UpdateContentAfterNewVersionUpload";
        const { data: cloud_data, status: cloud_status }: any = await axios.post(
            cloud_flare_url,
            { workspaceId: content_payload?.workspaceId , contentId, cloudFlareR2Path },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        // const status = 200;
        if (cloud_status !== 200) {
            return { success: false, message: "Cloudflare status error" };
        }
        const update_stage_payload = {
            ...common_payload,
            contentId,
            stageName: "publish",
            stageValue: "1"
        };
        const stage_url = api_url + "/api/v1.2/gallery/UpdateContentStages";
        const { data: stage_response, status: stage_status }: any = await axios.post(
            stage_url,
            update_stage_payload,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        if (stage_status !== 200) {
            return { success: false, message: "Update stage error" };
        }

        const { data: bio_page_stage_response, status: bio_page_stage_status }: any = await axios.post(
            stage_url,
            {
                ...common_payload,
                contentId,
                stageName: "biopage",
                stageValue: "1"
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        if (bio_page_stage_status !== 200) {
            return { success: false, message: "Update bio-page stage error" };
        }

        const currentContent: any = {
            ...content_response,
            presignedUrl: cloud_data?.presignedUrl,
            ...stage_response
        };

        return {
            success: true,
            iconUrl: stage_response?.url,
            data: currentContent
        };

    } catch (error) {
        console.log('error', error);
        return { success: false, message: "Server error" };
    }
}

// Worker Saga:
function* getBioPageSettingsData(action: any = {}): Generator<any> {
    try {
        const url = api_url + "/api/v1.2/Clapup/GetWorkspaceBioPageDetails";
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setBioPageSettings(data || []));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* updateBioPageSettingsData(action: any): Generator<any> {
    // console.log({workspaceSettingPayload: action?.payload});
    
    try {
        const url = api_url + "/api/v1.2/Clapup/UpdateWorkspaceBioPageDetails";
        const {status}: any = yield call(axios.post, url, action?.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put((getBioPageSettings()));
            // yield put(setUpdatedBioPageSettingsData());
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Worker Saga:
function* getBioPageLinksData(action: any = {}): Generator<any> {
    const { clapupUserId, roleId, workspaceId } = action?.payload;
    if (!clapupUserId || !roleId || !workspaceId) {
        // // yield put({ type: Constants.LOGOUT });
        return;
    }
    try {
        const url = api_url + "/api/v1.2/Clapup/GetWorkSpaceBioPageLinkDetails";
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        
        // console.log({ SocialLinks: data, status });
        if (status === 200) {
            yield put(setBioPageLinks(data?.Table));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Worker Saga:
function* addEditDeleteBioPageSocialLinksData(action: any = {}): Generator<any> {
    try {
        const { mode, contentId, uniqueNo } = action?.payload || {};

        delete action.payload.contentId;
        delete action.payload.uniqueNo;
        const promise = mode === "insert"
            ? uploadIconFileData({ contentFile: action?.contentFile, contentType: "SocialLink" })
            : mode === "update"
            ? updateIconFileData({ contentFile: action?.contentFile, content: {contentId, uniqueNo}, contentType: "SocialLink" })
            : Promise.resolve({ success: true, iconUrl: null, data: null });
        
        const { success, iconUrl, data: content_data, message }: any = yield Promise.resolve(promise);

        if (!success) {
            console.log('message', message);
            yield put(setErrorMessage(message || "Something went wrong"));
            return;
        }
        // console.log({content_data});
        let payload: any = {
            ...action.payload
        };
        iconUrl && (payload.iconUrl = iconUrl);
        // iconUrl && (payload.iconUrl = iconUrl?.replaceAll("@", "/"));
        const common_payload: any = {
            clapupId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
        };
        let url = api_url + "/api/v1.2/Clapup/UpdateWorkspaceBioPageLinkSettingsDetails";
        if (mode === "delete") { 
            url = api_url + "/api/v1.2/gallery/DeleteContents";
            payload = {
                contentId: payload?.id,
                isFolderDelete: false,
                ...common_payload
            }
        }
        const { status }: any = yield call(axios.post, url, payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        // console.log({ SocialLinks: data, status });
        
        if (status === 200 || status === 204) {
            yield put(getBioPageLinks());
            yield delay(2000);
            mode === "insert"
            ? (yield put(setSuccessMessage("Added Successfully")))
            : mode === "update"
            ? (yield put(setSuccessMessage("Saved Successfully")))
            : (yield put(setSuccessMessage("Deleted Successfully")));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

const getCustomLinkContentType = (link_type: any) => { 
    switch (link_type) {
        case 1:
            return "SimpleLink";
        case 2:
            return "NestedLink";
        case 3:
            return "EmbedVideo";
        case 4:
            return "EmbedVideoLink";
        case 5:
            return "EmbedImage";
        default:
            return null;
    }
}; 

function* addEditDeleteBioPageSocialAndCustomLinksData(action: any = {}): Generator<any> {
    try {
        const { mode, contentId, uniqueNo, linkType } = action?.payload || {};
        let contentType = getCustomLinkContentType(linkType);
        // console.log({ payload: action?.payload, contentType });
        // return;
        delete action.payload.contentId;
        delete action.payload.uniqueNo;
        const promise = mode === "insert"
            ? uploadIconFileData({ contentFile: action?.contentFile, contentType: contentType })
            : mode === "update"
            ? updateIconFileData({ contentFile: action?.contentFile, content: {contentId, uniqueNo}, contentType })
            : Promise.resolve({ success: true, iconUrl: null, data: null });
        
        const { success, iconUrl, data: content_data, message }: any = yield Promise.resolve(promise);

        if (!success) {
            console.log('message', message);
            yield put(setErrorMessage(message || "Something went wrong"));
            return;
        }
        // console.log({content_data});
        let payload: any = {
            ...action.payload
        };
        iconUrl && (payload.iconUrl = iconUrl);
        // iconUrl && (payload.iconUrl = iconUrl?.replaceAll("@", "/"));
        
        const common_payload: any = {
            clapupId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
        };
        let url = api_url + "/api/v1.2/Clapup/UpdateWorkspaceBioPageLinkSettingsDetails";
        if (mode === "delete") { 
            url = api_url + "/api/v1.2/gallery/DeleteContents";
            // console.log({contentId});
            
            payload = {
                contentId: contentId,
                isFolderDelete: false,
                ...common_payload
            }
            // console.log({ payload });
            
            // yield put(getBioPageLinks());
            // return;
        }

        const { status }: any = yield call(axios.post, url, payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        // console.log({ SocialLinks: data, status });
        
        if (status === 200 || status === 204) {
            yield put(getBioPageLinks());
            yield delay(2000);
            mode === "insert"
            ? (yield put(setSuccessMessage("Added Successfully")))
            : mode === "update"
            ? (yield put(setSuccessMessage("Saved Successfully")))
            : (yield put(setSuccessMessage("Deleted Successfully")));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* addEditDeleteBioPageAnnouncementData(action: any = {}): Generator<any> {
    try {
        const { mode, contentId, uniqueNo } = action?.payload || {};
        // delete action.payload.contentId;
        delete action.payload.uniqueNo;
        const promise = mode === "insert"
            ? uploadIconFileData({ contentFile: action?.contentFile, contentType: "Announcement" })
            : mode === "update"
            ? updateIconFileData({ contentFile: action?.contentFile, content: {contentId, uniqueNo}, contentType: "Announcement" })
            : Promise.resolve({ success: true, iconUrl: null, data: null });

        const { success, iconUrl: logoUrl, data: content_data, message }: any = yield Promise.resolve(promise);
        if (!success) {

            console.log('message', message);
            yield put(setErrorMessage(message || "Something went wrong"));
            return;
        }
        // console.log({ content_data });
        const { contentId: createdContentId } = content_data || {};
        
        let payload: any = {
            ...action.payload
        };
        logoUrl && (payload.logoUrl = logoUrl);
        createdContentId && (payload.contentId = createdContentId);
        // console.log({announcement_payload: payload})
        const common_payload: any = {
            clapupId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
        };
        let url = api_url + "/api/v1.2/Clapup/UpdateAnnouncementDetails";
        if (mode === "delete") { 
            url = api_url + "/api/v1.2/gallery/DeleteContents";
            // console.log({contentId});
            
            payload = {
                contentId: contentId,
                isFolderDelete: false,
                ...common_payload
            }
            // console.log({ payload });
            
            // yield put(getBioPageLinks());
            // return;
        }
        const { status }: any = yield call(axios.post, url, payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });

        // console.log({ announcement: data, status });
        
        if (status === 200 || status === 204) {
            // yield put(getBioPageAnnouncements());
            yield put(getBioPageLinks());
            yield delay(2000);
            mode === "insert"
            ? (yield put(setSuccessMessage("Added Successfully")))
            : mode === "update"
            ? (yield put(setSuccessMessage("Saved Successfully")))
            : (yield put(setSuccessMessage("Deleted Successfully")));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* getBioPageAnnouncementData(action: any = {}): Generator<any> {
    try {
        const url = api_url + "/api/v1.2/Clapup/GetAnnouncementDetails";
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setBioPageAnnouncements(data?.announcements));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* getBioPageEmbedVideoRecordsData(action: any = {}): Generator<any> {
    const { isForScrolling, searchText } = action?.payload || {};
    delete action.payload.isForScrolling;
    let url = api_url + "/api/v1.2/Clapup/SearchMediaFiles";
    if (!searchText) {
        url = api_url + "/api/v1.2/Clapup/GetWorkSpaceBioPageSettingsMediaEmbedFiles";
        delete action.payload.searchText;
        delete action.payload.settingsId;
    }
    try {
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        // console.log({data});
        
        if (status === 200 || status === 204) {
            isForScrolling
            ? yield put(setEmbedVideoRecords(data, "scroll"))
            : yield put(setEmbedVideoRecords(data, "search"));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* getFirstTimeBioPageEmbedVideoRecordsData(action: any = {}): Generator<any> {
    let url = api_url + "/api/v1.2/Clapup/GetWorkSpaceBioPageSettingsMediaEmbedFiles";

    try {
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200 || status === 204) {
            yield put(setFirstTimeEmbedVideoRecords(data, "scroll"));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* getFirstTimeBioPageEmbedImageRecordsData(action: any = {}): Generator<any> {
    let url = api_url + "/api/v1.2/Clapup/GetWorkSpaceBioPageSettingsMediaEmbedFiles";

    try {
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200 || status === 204) {
            yield put(setFirstTimeEmbedImageRecords(data, "scroll"));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* getBioPageEmbedImageRecordsData(action: any = {}): Generator<any> {
    const { isForScrolling, searchText } = action?.payload || {};
    delete action.payload.isForScrolling;
    let url = api_url + "/api/v1.2/Clapup/SearchMediaFiles";
    if (!searchText) {
        // url = api_url + "/api/v1.2/Clapup/GetMediaFileDetails";
        url = api_url + "/api/v1.2/Clapup/GetWorkSpaceBioPageSettingsMediaEmbedFiles";
        delete action.payload.searchText;
        delete action.payload.settingsId;
    }
    try {
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        
        // console.log({data});
        if (status === 200 || status === 204) {
            isForScrolling
            ? yield put(setEmbedImageRecords(data, "scroll"))
            : yield put(setEmbedImageRecords(data, "search"));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* getBioPageTemplateListData(action: any = {}): Generator<any> {
    if (!action?.payload?.workspaceId) {
        return;
    }
    let url = api_url + `/api/v1.2/Clapup/GetCssTemplateDetails?workspaceId=${action.payload.workspaceId}`;
    try {
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        
        // console.log({data});
        if (status === 200 || status === 204) {
            yield put(setBioPageTemplates(data?.templates))
            yield put(toggleSelectedTemplate(true))
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* updateForSelectingBioPageTemplate(action: any = {}): Generator<any> {
    let url = api_url + "/api/v1.2/Clapup/UpdateCssFile";
    const payload = {
        ...action.payload
    }
    if (!payload?.workspaceId || !payload?.templateId) {
        return;
    }
    try {
        const { status }: any = yield call(axios.post, url, payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        
        // console.log({data});
        if (status === 200 || status === 204) {
            yield put(getBioPageTemplates());
            // yield put(getBioPageSettings());
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Watcher Saga:
function* bioPageManagementSaga() {
    
    yield all([
        takeEvery(Constants.SELECT_BIO_PAGE_TEMPLATE, updateForSelectingBioPageTemplate),
        takeEvery(Constants.GET_BIO_PAGE_TEMPLATES, getBioPageTemplateListData),
        takeEvery(Constants.GET_BIO_PAGE_SETTINGS, getBioPageSettingsData),
        takeEvery(Constants.UPDATE_BIO_PAGE_SETTINGS, updateBioPageSettingsData),
        takeEvery(Constants.GET_BIO_PAGE_LINKS, getBioPageLinksData),
        takeEvery(Constants.ADD_EDIT_DELETE_SOCIAL_LINKS, addEditDeleteBioPageSocialLinksData),
        takeEvery(Constants.ADD_EDIT_DELETE_SOCIAL_CUSTOM_LINKS, addEditDeleteBioPageSocialAndCustomLinksData),
        takeEvery(Constants.GET_BIO_PAGE_ANNOUNCEMENTS, getBioPageAnnouncementData),
        takeEvery(Constants.ADD_EDIT_DELETE_ANNOUNCEMENT, addEditDeleteBioPageAnnouncementData),
        takeEvery(Constants.GET_FIRST_TIME_EMBED_VIDEO_RECORDS, getFirstTimeBioPageEmbedVideoRecordsData),
        takeEvery(Constants.GET_EMBED_VIDEO_RECORDS, getBioPageEmbedVideoRecordsData),
        takeEvery(Constants.GET_FIRST_TIME_EMBED_IMAGE_RECORDS, getFirstTimeBioPageEmbedImageRecordsData),
        takeEvery(Constants.GET_EMBED_IMAGE_RECORDS, getBioPageEmbedImageRecordsData),
    ]);
}

export default bioPageManagementSaga;