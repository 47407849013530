import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getImage } from "../../../../unified/image_service";
// import { setIntegrationTab } from "../reducer";
// import CommonModalCustomWidth from "../../../../components/CommonModal/CommonModalCustomWidth";
// import LocationSelectionTable from "./LocationSelectionTable";
import { RootState } from "../../../../store";
import {
    setGoogleConnectionLocations,
    // getConnectedMapGoogleLocations, toggleGoggleModalVisible, triggerMapGoogleConnectionLocations
    setGoogleLocationPayload,
} from "../../WorkspaceSettings/workspaceReducer";
import { api_url, auth_url } from "../../../../utils/server";
import axios from "axios";
import { generateAccessToken } from "../../../../profile/reducer";
// import { triggerDisconnectIntegrationConnection } from "../../../../signup-login/loginReducer";

const IntegrationGoogle: React.FC = () => {
    const dispatch = useDispatch();
    // const [is_ready_to_select_google_location, setReadyToSelectGoogleLocation] = useState<any>(false);
    // const { personalInfo } = useSelector((state: any) => state?.profileManageSettingState);
    // const { integration_tab } = useSelector((state: any) => state?.integrationState);
    // const {userPermissions} = useSelector((state: any) => state?.login);
    // const history = useNavigate();

    const {
        integration_type,
        google_connection_data,
        integrationConnections,
    }: any = useSelector((state: RootState) => state?.login);

    const {
        google_locations,
        // google_modal_visible,
        // google_connection_location_data,
        // google_connected_mapped_locations
    } = useSelector((state: any) => state?.workspaceManagementState);
    
    // ======= After Connection Showing Google Location List => Start ======
    // const [google_table_data, setGoogleTableData]: any = React.useState([]);
    // const [google_table_columns, setGoogleTableColumns]: any = React.useState([]);
    const user = JSON.parse(localStorage.getItem("integrated_user_details") || "{}");

    // const [selectedGoogleLocationIds, setSelectedGoogleLocationIds] = React.useState([]);
    // const handleGoogleSelectedLocation = (data: any) => {
    //     console.log("handleGoogleSelectedLocation ====> ", data);
    //     setSelectedGoogleLocationIds(data);
    // };

    // const handleGoogleLocationsContinue = () => {
    //     console.log("handleGoogleLocationsContinue ====> ", selectedGoogleLocationIds);
    //     if(selectedGoogleLocationIds?.length){
    //         const locationIds = selectedGoogleLocationIds?.join(",");
    //         const workspaceId = sessionStorage.getItem("wuid");
    //         const googleLocationsMapWithWorkspaceObject = {
    //             locationIds,
    //             workspaceId,
    //         }
    //         console.log({ googleLocationsMapWithWorkspaceObject });
    //         dispatch(triggerMapGoogleConnectionLocations(googleLocationsMapWithWorkspaceObject));
    //         // setGoogleModalVisible(false);
    //         handleToggleModalVisible();
    //     }
    // }

    useEffect(() => {
        if (!google_locations?.length || (integration_type === "google" && google_connection_data?.data === "success")) {
            const social_location_obj = {
                gmbAccountId: user?.id,
                workSpaceId: localStorage.getItem("wuid") || sessionStorage.getItem("wuid") || null,
            };
            // sessionStorage.removeItem("app_connect");
            dispatch(setGoogleLocationPayload(social_location_obj));
            // google_connection_data?.data === "success" && setGoogleModalVisible(true);
            dispatch({
                type: "SET_INTEGRATION_TYPE",
                payload: "google-locations-search",
            });
        } else if (
            integration_type === "google-locations-search" &&
            google_locations?.length
        ) {

            const google_location_data = google_locations?.map((item: any, idx: number) => {
                return {
                    ...item,
                    id: item?.locationId,
                    idx: idx + 1,
                };
            }) || [];
            dispatch(setGoogleConnectionLocations(google_location_data));

            console.log({ google_location_data });

            // const selectedRowKeys = table_data?.map(
            //     (item: any) => item?.isActive && item?.id
            // );

            // selectedRowKeys && setSelectedGoogleLocationIds(selectedRowKeys);
            // setGoogleTableData(table_data);
            // google_connection_data?.data === "success" && dispatch(toggleGoggleModalVisible(true));
            dispatch({ type: "SET_INTEGRATION_TYPE", payload: "" });
        }
    }, [integration_type, google_locations?.length]);


    // const handleToggleModalVisible = () => {
    //     dispatch(toggleGoggleModalVisible(false));
    // }

    // ======= After Connection Showing Google Location List => End ======


    // ======== Connect with Google => start =====
    const [connectionData, setConnection] = React.useState();

    const redirect = auth_url;
    const google_connected_data = integrationConnections?.length &&
    integrationConnections[0]?.UserId;
    // && google_connected_mapped_locations?.length;
    const [isGoogleConnected, setIsGoogleConnected] = useState<any>(false);
    useEffect(() => { 
        console.log({ google_connected_data });
        if (google_connected_data) {
            setIsGoogleConnected(true);
        } else {
            setIsGoogleConnected(false);
        }
    }, [google_connected_data]);
    
    const handleGoogleConnect = () => {
        sessionStorage.setItem('isLoading', 'true');
        sessionStorage.setItem('google_connect', 'google-connection');
        localStorage.setItem('appAccessType', 'google-connection');
        axios.get(
            `${api_url}/api/v1.2/clapup/getKeys?provider=Google`,
            {
                headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
                }
            }
        ).then((response: any) => {
            // console.log({ response });
            const {
                clientId,
                // clientSecret
            } = response?.data;
            // sessionStorage.setItem("client_details", JSON.stringify({ clientId, clientSecret }));
            sessionStorage.setItem("client_details", JSON.stringify({ clientId }));
            axios.post(
            `${redirect}/auth/google/url?code=${window.location.host}/profile`,
            {
                clientId,
                // clientSecret
            }
            )
            .then((res: any) => {
                console.log('res data  =====47====>', res);
                setConnection(res?.data);
            })
            .catch((err: any) => {
                console.log('err: ', err);
            });
            
        }).catch((error: any) => {
            console.log('error: ', error);
            const { response } = error;
            const { status }: any = response;
            if((status === 403 || status === 401)) {
            dispatch(generateAccessToken());
            }
        });
    
    }
    

    useEffect(() => {
        // "https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https%3A%2F%2Flocalhost%3A4000%2Fprofile&client_id=651003914717-81c4av16ki35tcfmv89ig7ku204j57q2.apps.googleusercontent.com&include_granted_scopes=true&access_type=offline&response_type=code&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fplus.business.manage"
        if (connectionData) {
            let googleURL: any = connectionData;
            console.log('connection-url ===== ===== ==> ', {googleURL});
            window.location.href = googleURL;
        }

    }, [ dispatch, connectionData]);


    // const get_integration_connections = () => {
    //   const workspaceId = sessionStorage.getItem('wuid');
    //   dispatch(triggerGetIntegrationConnections(workspaceId));
    // }

    // ======== Connect with Google => end =====


    // ==== Disconnect Function ====
    // const handleDisconnect = (Provider?: any, ProviderUserId?: String) => {
    //     console.log('ConnectWarning ==>', {Provider, ProviderUserId});
    //     dispatch(triggerDisconnectIntegrationConnection({ Provider, ProviderUserId, WorkspaceId: sessionStorage.getItem('wuid') }));
    //     setTimeout(() => {
    //         dispatch(getConnectedMapGoogleLocations());
    //         // dispatch(getFacebookConnectionPages());
    //         setIsDialogShow(false);
    //         // dispatch(getTwitterConnectionData());
    //     }, 1000);
    //     // get_integration_connections();
        
    // }
    // ==== Disconnect Function -> End ====


    // ======== Connected Location Management => start =====
    // use callback function for google location services
    // const [connectedMappedGoogleLocations, setConnectedMappedGoogleLocations] = useState<any>([]);
    // const [connectedMappedGoogleLocationsColumns, setConnectedMappedGoogleLocationsColumns] = useState<any>([]);
    // const [googleLocaitonDetails, setGoogleLocaitonDetails] = useState<any>(null);
    // useEffect(() => {
    // console.log("google_locations ====> ", google_locations);

    // if (google_connected_mapped_locations?.length) {
    //     const table_data = google_connected_mapped_locations?.map(
    //     (item: any, idx: number) => {
    //         return {
    //         ...item,
    //         id: item?.locationId,
    //         idx: idx + 1,
    //         };
    //     }
    //     );
    //     // setSelectedGoogleLocationIds(selectedRowKeys);
    //     setConnectedMappedGoogleLocations(table_data);
    // }
    // }, [google_connected_mapped_locations?.length, google_connection_location_data]);

    // const showGoogleLocationDetails = (googleObject?: any) => {
    //     console.log({ connectedMappedGoogleLocations });
    //     console.log("showGoogleLocationDetails ====> ", googleObject);
    //     setConnectedMappedGoogleLocationsColumns([
    //         {
    //         title: "#",
    //         dataIndex: "idx",
    //         key: "idx",
    //         align: "left",
    //         },
    //         {
    //         title: "Locations",
    //         dataIndex: "locationName",
    //         key: "locationName",
    //         align: "right",
    //         responsive: ['md'],
    //         //   render: (text: string, data: any) => {text},
    //         },
    //     ]);
    //     setGoogleLocaitonDetails(googleObject);
    // };

    // ======== Connected Location Management => End =====


    // use callback function for google location services
    

    // // continue button for google location services
    // useEffect(() => {
    //   console.log("Google connection established ==== 317 =====>", google_connection_location_data);
    //   // google_connection_location_data => success => true
    //   if(google_connection_location_data === "success"){
    //     setGoogleModalVisible(false);
    //   }
    //   // setGoogleModalVisible(false);
    // }, [google_connection_location_data]);


    // const [isDialogShow, setIsDialogShow] = useState<any>(null);
    // const handleDialogCallback = (flag?: any) => {
    //     console.log({ flag });
    //     if (!flag) {
    //       setIsDialogShow(null);
    //     } else {
    //       if (isDialogShow === "Google" && integrationConnections?.length) {
    //         handleDisconnect("Google", integrationConnections[0].UserId);
    //       }
    //       // else {
    //       //   handleDisconnect("Twitter", integrationConnections[1].UserId);
    //       // }
    //       setTimeout(() => {
    //         setIsDialogShow(null);
    //       }, 1500);
    //     }
    // };

    // const handleCancel = () => {
    //     setReadyToSelectGoogleLocation(false);
    // }
    
    return (
        <React.Fragment>
            {/* <CommonModalCustomWidth 
                modal_visible={is_ready_to_select_google_location} 
                setModalVisible={(flag: any) => setReadyToSelectGoogleLocation(flag)}
                centered={true}
                // okText="Send"
                // cancelText="Cancel"
                padding={"0"}
                class_name="select-location-setting-table-container"
                footer={null}
            >
                <LocationSelectionTable handleCancel={handleCancel} />
            </CommonModalCustomWidth> */}
            <div
                title={isGoogleConnected ? "Connected" : ""}
                className={`integrations-container ${isGoogleConnected ? "connected-integration-container" : ""}`}
                onClick={() => !isGoogleConnected && handleGoogleConnect()}
            >
                <div className="header-container">
                    <img src={getImage("Google")} alt="integration-icon" />
                    <div className="title">Google</div>
                </div>
                <div className="description">
                    Connect Google Business Profile and manage reviews and Q & A.
                </div>
            </div>
        </React.Fragment>
    );
};

export default IntegrationGoogle;