import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import { DatePicker, Input, Select, TimePicker } from 'antd';
import { setErrorMessage } from '../../../../../unified/reducer';
import { getCampaignImage } from '../../../../../unified/image_service';
import { RootState } from '../../../../../store';
import { setCampaignDetailsPayload } from '../../../reducer';

type ContactFormProps = {
    onCancel?: () => void;
    onParentCallback?: (data: any) => void;
};

const ContactForm: React.FC<any> = ({onCancel, onParentCallback }) => {
    const dispatch = useDispatch();
    const {campaign_details_payload, time_zones}: any = useSelector((state: RootState) => state?.promotionsState);
    const { campaign_contact_table_data, selected_contacts, selected_date, selected_time, selected_time_zone } = campaign_details_payload;
    const [contacts, setContacts] = useState<any[]>([]);
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
    useEffect(() => { 
        // if (campaign_details_payload?.selected_contacts?.length) {
        if (!contacts?.length) {
            const all_contacts = campaign_contact_table_data?.map((contact: any) => {
                const is_selected = selected_contacts?.find((sc: any) => contact?.phone_number === sc.phone_number);
                return {
                    ...contact,
                    isSelected: is_selected
                }
            });
            setIsAllSelected(campaign_contact_table_data?.length === selected_contacts?.length);
            setContacts(all_contacts?.length ? all_contacts : []);
        }
    }, [campaign_details_payload?.campaign_contact_table_data?.length]);

    const handleSubmit = () => {
        // const data = {
        //     clapupUserId: sessionStorage.getItem('cuid'),
        //     workspaceId: sessionStorage.getItem('wuid'),
        //     roleId: sessionStorage.getItem('roleId'),
        // }
        // console.log({ data});
        if (!selected_contacts?.length) {
            dispatch(setErrorMessage("Please select at least one contact"));
            return;
        }
        if (!selected_date) {
            dispatch(setErrorMessage("Please select date"));
            return;
        }
        if (!selected_time) {
            dispatch(setErrorMessage("Please select time"));
            return;
        }
        if (!selected_time_zone) {
            dispatch(setErrorMessage("Please select time zone"));
            return;
        }

        onParentCallback && onParentCallback();
    };

    const handleAllSelected = (value: boolean) => {
        setIsAllSelected(value);
        const current_all_contacts: any[] = contacts?.map((contact: any) => ({
            ...contact,
            isSelected: contact?.selectable ? value : false
        }));
        const main_all_contacts: any[] = campaign_contact_table_data?.map((contact: any) => {
            const selected_item = current_all_contacts?.find((sc: any) => contact?.phone_number === sc.phone_number);
            return {
                ...contact,
                isSelected: selected_item?.isSelected
            }

            // ...contact,
            // isSelected: contact?.selectable ? value : false
        });
        setContacts(current_all_contacts);
        dispatch(setCampaignDetailsPayload({
            ...campaign_details_payload,
            campaign_contact_table_data: main_all_contacts,
            selected_contacts: value ? main_all_contacts?.filter((item: any) => item?.isSelected) : []
        }));
    }

    const handleRemoveSelectContact = (value: boolean, phone_number: number) => { 
        const filtered_contacts = contacts?.map((contact: any) => {
            if (contact?.phone_number === phone_number) {
                return { ...contact, isSelected: value };
            }
            return contact;
        });
        const filtered_selected_contacts = selected_contacts?.filter((contact: any) => contact?.phone_number !== phone_number);
        setContacts(filtered_contacts);
        dispatch(setCampaignDetailsPayload({
            ...campaign_details_payload,
            campaign_contact_table_data: filtered_contacts,
            selected_contacts: filtered_selected_contacts
        }));
        const is_all_selected = filtered_contacts?.find((contact: any) => contact?.isSelected === false);
        setIsAllSelected(is_all_selected ? false : true); 
    };

    const handleSelectContact = (value: boolean, phone_number: number) => {
        if (!value) {
            handleRemoveSelectContact(value, phone_number);
            return;
        }
        const editable_contact = selected_contacts?.find((contact: any) => contact?.phone_number === phone_number);
        const filtered_contacts = contacts?.map((contact: any) => {
            if (contact?.phone_number === phone_number) {
                return { ...contact, isSelected: value };
            }
            return contact;
        });
        const selected_contact = filtered_contacts?.find((contact: any) => contact?.phone_number === phone_number);
        setContacts(filtered_contacts);
        dispatch(setCampaignDetailsPayload({
            ...campaign_details_payload,
            campaign_contact_table_data: filtered_contacts,
            selected_contacts:  editable_contact
                                ? selected_contacts?.map((contact: any) => {
                                    if (contact?.phone_number === phone_number) {
                                        return { ...contact, isSelected: value };
                                    }
                                    return contact;
                                })
                                : selected_contacts?.length
                                ? [...selected_contacts, selected_contact] 
                                : [selected_contact]
        }));
        const is_all_selected = filtered_contacts?.find((contact: any) => contact?.isSelected === false);
        setIsAllSelected(is_all_selected ? false : true); 
    };

    const handleContactSearch = (search_string = "") => { 
        // console.log({campaign_contact_table_data, search_string})
        const filtered_contacts = campaign_contact_table_data?.filter((contact: any) => {
            const keys = Object.keys(contact);
            return keys?.find((key: any) => contact[key]?.toString()?.toLowerCase()?.includes(search_string?.toLowerCase()));
        });
        setContacts(filtered_contacts);
    };

    return (
        <React.Fragment>
            <div className='form-input-container'>
                <p className='header-title'>Select Contacts</p>
                
                <Input
                    style={{ width: '100%', margin: '1rem auto' }}
                    placeholder='Search for Contacts'
                    addonBefore={<SearchOutlined />}
                    addonAfter={null}
                    size='middle'
                    onChange={(e: any) => {
                        // console.log(e.target.value);
                        handleContactSearch(e.target.value);
                    }}
                />

                <div className='select-contact-table'>
                    <table className="table table-borderless m-0">
                        <thead className='table-thead sticky-top' style={{top: 0, zIndex: 999}}>
                            <tr>
                                {
                                    Object.keys(contacts?.length ? contacts[0] : {})?.map((column: any, index: number) => (
                                        (column !== "isSelected" && column !== "selectable") &&
                                        <th key={index}>{column}</th>
                                    ))
                                }
                                {/* <th>Phone Number</th>
                                <th>Phone Number</th>
                                <th>Phone Number</th>
                                <th>Phone Number</th>
                                */}
                                <th style={{ textAlign: 'end' }}>
                                    <img 
                                        style={{marginRight: '0.8rem', cursor: 'pointer'}}
                                        src={isAllSelected ? getCampaignImage("square-active-check") : getCampaignImage("square-inactive-check")}
                                        onClick={() => handleAllSelected(!isAllSelected)}
                                        alt="select-icon"
                                    />
                                </th>
                            </tr>
                            <tr>
                                <th style={{ padding: '0.25rem 0'}}></th>
                                <th style={{ padding: '0.25rem 0'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            contacts?.map((contact: any, index: number) => {
                                const keys = Object.keys(contact);
                                return  <tr key={index}>
                                    {
                                        keys?.map((key: any, idx: number) => (
                                            (key !== "isSelected" && key !== "selectable") && 
                                            <td key={idx}>{contact[key]}</td>
                                        ))
                                    }
                                    <td style={{ textAlign: 'end' }}>
                                        <img
                                            style={{ marginRight: '0.8rem', cursor: contact?.selectable ? 'pointer' : 'not-allowed'}}
                                            src={contact?.isSelected ? getCampaignImage("square-active-check") : getCampaignImage("square-inactive-check")}
                                            onClick={() => {
                                                if (contact?.selectable) {
                                                    handleSelectContact(!contact?.isSelected, contact?.phone_number);
                                                    return;
                                                }
                                                dispatch(setErrorMessage("The contact is invalid to select!"))
                                            }}
                                            alt="select-icon"
                                        />
                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                </div>

                <div className='date-time-container'>
                    <div className='from-date-time'>
                        <p className='text-start'>Schedule Broadcast</p>
                        <div className='date-time'>
                            <DatePicker
                                // onChange={(date: any, dateString: string) => handleDateChange(dateString, curFromTime, setCurFromTime)}
                                // defaultValue={curFromTime?.date ? moment(new Date(curFromTime?.date), 'YYYY-MM-DD') : undefined}
                                className='date-picker'
                                // style={{width: (curFromTime || link_item?.startTime) ? "65%": "100%"}}
                                style={{width: "65%"}}
                                placeholder='Select the date'
                                // disabledDate={disabledFromDate}
                                // value={campaign_details_payload?.selected_date ? moment(campaign_details_payload?.selected_date) : moment("2024-04-18T04:04:00.000")}
                                value={campaign_details_payload?.selected_date ? moment(campaign_details_payload?.selected_date) : undefined}
                                onChange={(date: any, dateString: string) => {
                                    // console.log({ date, dateString });
                                    dispatch(setCampaignDetailsPayload({
                                        ...campaign_details_payload,
                                        // .replace('Z', '')
                                        selected_date: dateString ? new Date(dateString)?.toISOString() : null,
                                    }));
                                }}
                            />
                            {/* Selected Current Time */}
                            <TimePicker
                                onChange={(time: any, timeString: string) => {
                                    // console.log({ time, timeString });
                                    dispatch(setCampaignDetailsPayload({
                                        ...campaign_details_payload,
                                        // .replace('Z', '')
                                        selected_time: timeString ? timeString : null,
                                    }));
                                }}
                                placeholder='Time'
                                className='time-picker'
                                use12Hours format="hh:mm:ss A"
                                suffixIcon={null}
                                // defaultPickerValue={moment(new Date(), 'hh:mm:ss, A')}
                                // defaultValue={link_item?.startTime ? moment(link_item?.startTime, 'hh:mm:ss, A') : curFromTime?.time ? moment((curFromTime?.time), 'hh:mm:ss, A') : undefined}
                                // value={campaign_details_payload?.selected_time ? moment(campaign_details_payload?.selected_time, "hh:mm:ss A") : moment("2024-04-18T04:04:00.000", "hh:mm:ss A")}
                                value={campaign_details_payload?.selected_time ? moment(campaign_details_payload?.selected_time, "hh:mm:ss A") : undefined}
                            />

                        </div>
                    </div>
                    <div className='time-zone'>
                        <p className='unknown-text' style={{color: 'transparent'}}>{"0"}</p>
                        <div className='w-100'>
                            <Select
                                className='time-picker w-100 text-start'
                                placeholder='Time zone'
                                showSearch
                                allowClear
                                listHeight={150}
                                onChange={(value: any) => {
                                    // console.log({ value });
                                    dispatch(setCampaignDetailsPayload({
                                        ...campaign_details_payload,
                                        // .replace('Z', '')
                                        selected_time_zone: value ? value : null,
                                    }));
                                }}
                                value={campaign_details_payload?.selected_time_zone || null}
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    // [
                                    //     "(GMT+06:00) Asia/Dhaka",
                                    //     "(GMT+06:00) Asia/Almaty",
                                    //     "(GMT+06:00) Asia/Bishkek",
                                    //     "(GMT+06:00) Asia/Qyzylorda",
                                    //     "(GMT+06:00) Asia/Thimphu",
                                    //     "(GMT+03:00) America/Sao Paulo",
                                    //     "(GMT+03:00) America/Argentina/Buenos_Aires",
                                    // ]
                                    time_zones?.map((item: any, index: number) => (
                                        <Select.Option key={index} value={item?.id}>{item?.displayName}</Select.Option>
                                    ))
                                }
                                {/* <Select.Option value="immediately">Immediately</Select.Option>
                                <Select.Option value="scheduled">Scheduled</Select.Option> */}
                            </Select>
                        </div>
                    </div>
                </div>

                <div className='form-button-container'>
                    <button
                        type='button'
                        className='cancel-button'
                        onClick={onCancel}
                    >
                        Back
                    </button>
                    <button
                        // disabled={}
                        className='create-button'
                        onClick={() => handleSubmit()}
                        type='button'
                    >
                        {"Next" }
                    </button>
                </div>
            </div>
        </React.Fragment>
    )
};

export default ContactForm;
