import React, {useState, useEffect} from "react";
// import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getImage } from "../../../../unified/image_service";
import Dialog from "../../../../components/Dialogs/Dialog";
import {
    getIntegrationConnectedAccounts,
    triggerDisconnectIntegrationConnection,
    // triggerDisconnectConnectedAccount,
    // triggerGetIntegrationConnections,
} from "../../../../signup-login/loginReducer";
import {
    triggerMapFacebookConnectionPages,
    // getConnectedMapGoogleLocations, setGoogleLocationPayload,
    // toggleGoggleModalVisible,
    triggerMapGoogleConnectionLocations
} from "../../WorkspaceSettings/workspaceReducer";

// import { getConnectedMapGoogleLocations } from "../../../workspace-management/workSpaceReducer";
// import CommonModalCustomWidth from "../../../../components/CommonModal/CommonModalCustomWidth";
// import { setReadyToAddChannel } from "../reducer";
import CommonModalCustomWidth from "../../../../components/CommonModal/CommonModalCustomWidth";
import LocationSelectionTable from "./LocationSelectionTable";
import "./IntegrationManagement.scss";
import PageSelectionTable from "./PageSelectionTable";

const ConnectedAccounts: React.FC = () => {
    const [is_ready_to_select_google_location, setReadyToSelectGoogleLocation] = useState<any>(false);
    const [is_ready_to_select_facebook_page, setReadyToSelectFacebookPage] = useState<any>(false);
    const [isDialogShow, setIsDialogShow] = useState<any>(null);
    const {  connectedAccounts: connected_accounts, login_error } = useSelector((state: any) => state?.login);
    const dispatch = useDispatch();
    // const history = useNavigate();
    const { success_message } = useSelector((state: any) => state?.unified);
    useEffect(() => { 
        if (success_message) {
            console.log({ connected_accounts });
            handleCancel();
        }
    }, [success_message]);

    const handleDisconnect = (provider?: any, providerUserId?: any) => {
        console.log({ provider, providerUserId });
        
        const workspaceId = localStorage.getItem('wuid') || sessionStorage.getItem('wuid') || null;
        if (!workspaceId) {
            return;
        }
        dispatch(triggerDisconnectIntegrationConnection({
            provider,
            providerUserId: providerUserId,
            workspaceId: workspaceId
        }));
        // if (provider?.toLowerCase() === "google" || provider?.toLowerCase() === "facebook") {
        //     dispatch(triggerDisconnectIntegrationConnection({
        //         provider,
        //         providerUserId: providerUserId,
        //         workspaceId: workspaceId
        //     }));
        // } else {
        //     const clapupUserId = localStorage.getItem('cuid') || sessionStorage.getItem('cuid') || null;
        //     const data_payload = {
        //         clapupUserId,
        //         channelId: providerUserId
        //     }
        //     dispatch(triggerDisconnectConnectedAccount(data_payload));
        // }
        
    }

    const handleDialogCallback = (account?: any) => {
        console.log({ account });
        if (!account) {
            handleCancel();
        } else {
            if (account?.provider?.toLowerCase() === "google" && account) {
                handleDisconnect("Google", account?.channelId);
            }
            else if (account?.provider?.toLowerCase() === "facebook" && account) {
                handleDisconnect("Facebook", account?.channelId);
            }
            else if(account?.provider?.toLowerCase() === "gmail" && account) {
                handleDisconnect("Gmail", account?.channelId);
            }
            //   handleDisconnect("Twitter", integrationConnections[1].UserId);
            
            //   setTimeout(() => {
            //     setIsDialogShow(null);
            //   }, 1500);
        }
    };

    const handleCancel = () => {
        // dispatch(setReadyToAddChannel(false));
        setIsDialogShow(null);
        setReadyToSelectGoogleLocation(false);
        setReadyToSelectFacebookPage(false);
    }

    // Google Location Mapped ====== Start
    const handleGoogleLocationsContinue = (selectedGoogleLocationIds: any) => {
        console.log("handleGoogleLocationsContinue ====> ", selectedGoogleLocationIds);
        if(selectedGoogleLocationIds?.length){
            const locationIds = selectedGoogleLocationIds?.join(",");
            const workspaceId = localStorage.getItem("wuid") || sessionStorage.getItem("wuid") || null;
            const googleLocationsMapWithWorkspaceObject = {
                locationIds,
                workspaceId,
            }
            console.log({ googleLocationsMapWithWorkspaceObject });
            dispatch(triggerMapGoogleConnectionLocations(googleLocationsMapWithWorkspaceObject));
            // setGoogleModalVisible(false);
            // handleToggleModalVisible();
            setTimeout(() => handleCancel(), 2000);
        }
    }
    // Google Location Mapped ====== End
    
    // FB Location Mapped ====== Start
    const handleFacebookPagesContinue = (selectedFacebookPageIds: any) => {
        console.log("selectedFacebookPageIdsContinue ====> ", selectedFacebookPageIds);
        const pageIds = selectedFacebookPageIds?.join(",");
        const workspaceId = sessionStorage.getItem("wuid");
        const facebookPageMapWithWorkspaceObject = {
            pageIds,
            workspaceId,
            facebookAccountId: sessionStorage.getItem("fb_user_id")
        };
        console.log({ facebookPageMapWithWorkspaceObject });
        dispatch(triggerMapFacebookConnectionPages(facebookPageMapWithWorkspaceObject));
        // dispatch(toggleShowFbPageMapModal(false));
        setTimeout(() => handleCancel(), 2000);
    };
    // FB Location Mapped ====== End
    // console.log({ connected_accounts });

    return (
        <React.Fragment>
            <Dialog
                open={isDialogShow}
                parentCallback={(flag?: any) => handleDialogCallback(flag)}
                classes='dialog-container-content'
                title='Disconnect?'
                warning_dialog_content={
                    "If you continue, all your data related to Google Business Profile will be deleted from our system"
                    // isDialogShow?.provider === "google"
                    // ? "If you continue, all your data related to Google Business Profile will be deleted from our system"
                    // : null
                }
                footer={true}
                right_button_text='Yes, Continue'
                left_button_text='No, Cancel'
                // hover={true}
            />

            {/* show  google location list */}
            <CommonModalCustomWidth 
                modal_visible={is_ready_to_select_google_location} 
                setModalVisible={(flag: any) => setReadyToSelectGoogleLocation(flag)}
                centered={true}
                // okText="Send"
                // cancelText="Cancel"
                padding={"0"}
                class_name="select-location-setting-table-container"
                footer={null}
            >
                <LocationSelectionTable handleGoogleLocationsContinue={handleGoogleLocationsContinue} handleCancel={handleCancel} />
            </CommonModalCustomWidth>
            {/* show  google location list */}
            
            {/* show  facebook page list */}
            <CommonModalCustomWidth 
                modal_visible={is_ready_to_select_facebook_page} 
                setModalVisible={(flag: any) => setReadyToSelectFacebookPage(flag)}
                centered={true}
                // okText="Send"
                // cancelText="Cancel"
                padding={"0"}
                class_name="select-location-setting-table-container"
                footer={null}
            >
                <PageSelectionTable handleGoogleLocationsContinue={handleFacebookPagesContinue} handleCancel={handleCancel} />
            </CommonModalCustomWidth>
            {/* show  facebook page list */}

            <div className="connected-account-list-table">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>
                                    <div className="table-header-row-content">
                                        <div className="connected-account-header-title-container">Name</div>
                                        <div>Routing</div>
                                        <div>Action</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {connected_accounts?.map((account: any, idx: number) => (
                                <tr key={idx}>
                                    <td>
                                        <div
                                            className="table-row-content"
                                            onClick={() => {
                                                if(account?.provider === "google"){
                                                    // setReadyToSelectGoogleLocation(true);
                                                } else if(account?.provider === "facebook"){
                                                    // setReadyToSelectFacebookPage(true);
                                                }
                                            }}
                                            // onClick={() => handleRowClick(connected-account?.channelId)}
                                        >
                                            <div className="connected-account-title-container">
                                                <div className="connected-account-icon" >
                                                    <img
                                                        src={getImage(
                                                            account?.provider === "gmail" ? "gmail-icon-circle" 
                                                            : account?.provider === "google" ? "Google" 
                                                            : account?.provider === "whatsapp" ? "Whatsapp" 
                                                            : account?.provider === "facebook" ? "Facebook" 
                                                            : "N/A"
                                                        )} 
                                                        alt="provider-icon"
                                                    />
                                                </div>
                                                <div className="connected-account-title">
                                                    <div>{account?.email || account?.phoneNumber || account?.channelName}</div>
                                                    <div className="account-provider">{
                                                        account?.provider === "google" ? "Google"
                                                        : account?.provider === "gmail" ? "Gmail"
                                                        : account?.provider === "whatsapp" ? "Whatsapp" 
                                                        : account?.provider === "facebook" ? "Facebook" 
                                                        : "N/A"
                                                    }</div>
                                                </div>
                                            </div>
                                            <div>{account?.routing || "N/A"}</div>
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevents triggering the row click
                                                    // handleDisconnect(account?.channelId);
                                                    setIsDialogShow(account);
                                                }}
                                            >
                                                Disconnect
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="extra"></div>
        </React.Fragment>
    );
};

export default ConnectedAccounts;