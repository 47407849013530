import React, { useState, useEffect } from "react";
import { Select, Input, InputNumber } from "antd";
import './ColorPicker.scss';

const { Option } = Select;

interface ColorPickerProps {
    onChange?: any;
    value?: any;
    color_values?: any;
    palette_label?: any;
    color_picker_container_class_name: any;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ onChange, value, palette_label, color_values, color_picker_container_class_name }: any) => {
    const [colorFormat, setColorFormat] = useState('HEX');
    const [colorValue, setColorValue] = useState('');
    const [transparency, setTransparency] = useState(100);

    useEffect(() => { 
        setColorValue(value || "");
    }, [value]);

    // useEffect(() => {
    //     // console.log({ colorValue, colorFormat, transparency });
    //     onChange(colorValue);
    // }, [colorValue]);

    const [colors, setColors] = useState<any[]>([]);
    useEffect(() => { 
        setColors(
            colorFormat === "RGB" ? color_values?.RGB
                : colorFormat === "HSL" ? color_values?.HSL
                    : color_values?.HEX
        );
    }, [colorFormat]);
  
    const handleColorFormatChange = (value: string) => {
        setColorFormat(value);
        setColorValue("");
    };

    return (
        <div className={`${color_picker_container_class_name}`}>
            {/* Parent div wrapping Select and Input components */}
            <div className={`color-picker-inputs`}>
                <Select
                    value={colorFormat || "HEX"}
                    onChange={handleColorFormatChange}
                    placeholder={"Select"}
                    className={`color-format-select`}
                >
                    <Option value="HEX">HEX</Option>
                    <Option value="HSL" disabled>HSL</Option>
                    <Option value="RGB" disabled>RGB</Option>
                </Select>

                <Input
                    value={colorValue}
                    style={{background: colorValue || "transparent"}}
                    // onChange={(e) => setColorValue(e.target.value)}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={`${colorFormat} VALUE`}
                    className={`color-value-input`}
                />

                <InputNumber
                    min={0}
                    max={100}
                    formatter={(value: any) => value ? `${value}%` : ""}
                    parser={(value: any) => value?.replace('%', '')}
                    onChange={(value) => setTransparency(value)}
                    value={transparency}
                    placeholder="100%"
                    readOnly={true}
                    className={`transparency-input`}
                />
            </div>

            <div className="color-palette-container">
                {
                    palette_label
                    ? <div className="palate-label">{palette_label}</div>
                    : null
                }
                {/* Color Palette */}
                <div className="color-palette">
                    {colors?.map((color: any, idx: any) => (
                        <div key={idx} onClick={() => onChange(color)} className="color-circle" style={{ backgroundColor: color }} />
                    ))}
                </div>
            </div>
            {/* Color Palette */}
            {/* <div className="color-palette">
                {colors?.map((color: any, idx: any) => (
                    <div key={idx} onClick={() => setColorValue(color)} className="color-circle" style={{ backgroundColor: color }} />
                ))}
            </div> */}
        </div>
    );
};

export default ColorPicker;
