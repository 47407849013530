import React from 'react';
import prev_icon from '../../../../assets/icons/previous-icon.svg';
import next_icon from '../../../../assets/icons/next-icon.svg';
import refresh_icon from '../../../../assets/icons/refresh-icon-unified.svg';
import user_avatar from '../../../../assets/icons/user-avatar.svg';
import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
import start from '../../../../assets/icons/star-icon.svg';
import star_half from '../../../../assets/icons/star-half-icon.svg';
import lock_icon from '../../../../assets/icons/lock-icon.svg';
import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
import back_btn from '../../../../assets/icons/back.svg';
import reply_icon from '../../../../assets/icons/reply-icon.svg';
import edit_icon from '../../../../assets/icons/edit-icon.svg';
import send_icon from '../../../../assets/icons/send-icon.svg';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import ConversationDetails from '../AllDetails/ConversationsDetails';
import { useDispatch, useSelector } from 'react-redux';
import { setSuccessMessage } from '../../../../unified/reducer';
import ConversationHeaderSD from './ConversationHeaderSD';
import ConversationHeader from './ConversationHeader';
export default function TwitterConversations(props: any) {
  const dispatch = useDispatch();
  const {conversation, handleReplaceConversation, getImage, handlePrevPath, isReply, setIsReply, setReplyText, textRef, handleReply, conversationId, isGoForDetails, handleDetailChange} = props;
  const {person_1, person_2, properties, details, from} = conversation || {};
  const { reviewedbyUserName, reviewerDisplayName, reviewerPhoteUrl, recieverName, mentionedbyUserName, mentionedbyUserImageUrl, reviewtype, createdOn, reviewComment, provider, starRating, ratingStars, likeCount, retweetCount, locationName, pagename, recieverCompany, mentionedbyUserEmailId, reviewComment2 } = conversation || {};
  const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
  const { workspaces } = useSelector((state: any) => state?.headerState);
  const workSpaceName: any = workspaces?.find(
    (w: any) => w?.workSpaceId === localStorage.getItem("wuid")
  )?.workSpaceName || "";
  console.log({ conversation });
  
  return (
    <React.Fragment>
      <div className="conversation-section-container-lg h-100">
        {/* <div className="conversation-header">
          <div className="person-container">
            <div className="person-1">
            `{
                reviewerPhoteUrl
                ? <img
                    className="user-avatar"
                    src={reviewerPhoteUrl || user_avatar}
                    alt="avatar"
                  />
                : <div className='user-avatar'>
                    {
                      recieverName?.split(" ")?.slice(0, 2)?.length === 2
                      ? recieverName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                      : recieverName?.substr(0, 2).toUpperCase()
                    }
                  </div>
              }
              <span className="description">
                {reviewerDisplayName?.substr(0, 6) ||
                  reviewedbyUserName?.substr(0, 6) ||
                  mentionedbyUserName?.substr(0, 6) ||
                  recieverName?.substr(0, 6) ||
                  "User Name"}{" "}
                <span className="review">
                  {"mentioned"}
                </span>{" "}
                {locationName ||
                  pagename ||
                  workspaceSettings?.workspaceName}
              </span>
            </div>
            <div className="person-2">
              <img
                src={workspaceSettings?.logo || user_avatar_2}
                alt="user-2"
              />
              <span className="user-2">
                {workspaceSettings?.workspaceName || "WS Name"}
              </span>
            </div>
          </div>
          <div className="conversation-features-media">
            <div className="features">
              <img
                onClick={() =>
                  handleReplaceConversation("prev", conversation?.SlNo)
                }
                src={prev_icon}
                alt="prev"
                title='Previous'
              />
              <img
                onClick={() =>
                  handleReplaceConversation("next", conversation?.SlNo)
                }
                src={next_icon}
                alt="next"
                title='Next'
              />
              <img
                onClick={() => dispatch(setSuccessMessage("Refreshed"))}
                src={refresh_icon}
                alt="refresh"
                title='Refresh'
              />
            </div>
            <div className="media">
              <img src={getImage(provider)} alt="google" />
            </div>
          </div>
        </div> */}
        <ConversationHeader
          header_title={" mentioned "}
          workSpaceName={workSpaceName}
          workspaceSettings={workspaceSettings}
          conversation={conversation}
          handleReplaceConversation={handleReplaceConversation}
          prev_icon={prev_icon}
          next_icon={next_icon}
          refresh_icon={refresh_icon}
          user_avatar_2={user_avatar_2}
          user_avatar={user_avatar}
        />
        <div className="conversation-section">
          <Scrollbars
            style={{
              paddingRight: 10,
              display: "flex",
              flexDirection: "column-reverse",
            }}
            // style={{ paddingRight: 10 }}
            autoHide
            autoHideTimeout={500}
            autoHideDuration={500}
            autoHeight
            // autoHeightMin={250}
            // autoHeightMax={250}
            // autoHeightMin={480}
            autoHeightMax={"100%"}
            thumbMinSize={10}
            universal={true}
          >
            <div className="conversation-body" id="scrollableDiv">
              {/* This will implement it feeds.tsx file */}
              {/* <InfiniteScroll
                dataLength={2}
                className="scrolling"
                next={() => console.log("next")}
                inverse={true}
                hasMore={false}
                loader={<h4 className="text-center">Loading...</h4>}
                scrollableTarget="scrollableDiv"
                height={320}
                
              > */}
              <div className="conversation-person-1">
                <div className="details">
                  {
                    reviewerPhoteUrl
                    ? <img
                        className="user-avatar"
                        src={reviewerPhoteUrl || user_avatar}
                        alt="avatar"
                      />
                    : <div className='user-avatar'>
                        {
                          recieverName?.split(" ")?.slice(0, 2)?.length === 2
                          ? recieverName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                          : recieverName?.substr(0, 2).toUpperCase()
                        }
                      </div>
                  }
                  <span className="description">
                    <span
                      className={`d-flex align-items-center user-ratings-container`}
                    >
                      <span className="d-block user_name me-2 ">
                        {reviewerDisplayName ||
                          reviewedbyUserName ||
                          mentionedbyUserName ||
                          recieverName ||
                          "User Name"}
                      </span>
                      {starRating || ratingStars ? (
                        <div className="">
                          <span className="rating-social-email">
                            <div className="py-2">
                              {starRating || ratingStars ? (
                                <div>
                                  {Array.from(
                                    Array(
                                      Math.round(starRating || ratingStars)
                                    ).keys()
                                  ).map((_: any, index: number) => {
                                    return (
                                      <img
                                        className="start"
                                        key={index}
                                        src={start}
                                        alt="star"
                                      />
                                    );
                                  })}
                                  {Array.from(
                                    Array(
                                      5 -
                                        Math.round(
                                          starRating || ratingStars
                                        )
                                    ).keys()
                                  ).map((_: any, index: number) => {
                                    return (
                                      <img
                                        className="star"
                                        key={index}
                                        src={star_half}
                                        alt="star"
                                      />
                                    );
                                  })}
                                  <span className="ms-2">
                                    {starRating || ratingStars}/5
                                  </span>
                                </div>
                              ) : person_1?.social_link ? (
                                <span className="">
                                  {person_1?.social_link}
                                </span>
                              ) : (
                                <span className="">
                                  {person_1?.user_email}
                                </span>
                              )}
                            </div>
                          </span>
                        </div>
                      ) : null}
                    </span>

                    <span className="d-block client-container my-2">
                      {"mentionedbyUserEmailId" || "@client"}
                    </span>
                    <p>{reviewComment}</p>
                    <div className="d-flex align-items-center">
                      <img
                        style={{ height: 15, width: 15 }}
                        className="d-block me-3"
                        src={getImage("retweet")}
                        alt="retweet"
                      />
                      <span className="d-flex align-items-center">
                        <img
                          style={{ height: 10, width: 10 }}
                          className="d-block"
                          src={getImage("heart")}
                          alt="heart"
                        />
                        {retweetCount !== null ? (
                          <span className="mx-1">
                            {retweetCount === 0
                              ? 0
                              : retweetCount === 1
                              ? `${retweetCount} like`
                              : `${retweetCount} Retweets`}
                          </span>
                        ) : null}
                      </span>
                      {/* {
                          likeCount && <span className="mx-2 likes" style={{fontSize: 10}}>{
                            person_1?.likes === 1 ? person_1?.likes + " Like" : person_1?.likes + " Likes"
                          }</span>
                        } */}
                    </div>
                  </span>
                </div>
                <p className="text-start mt-2 date">
                  {"Twitter Mention"}
                  {" - "}
                  <span>
                    {
                      // new Date(createdOn).toDateString() +
                      moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + (workspaceSettings?.workspaceName || "WS Name")
                      // moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + ("Tezla Inc")
                      // moment(details?.requested_time).format('Y-m-d')
                    }
                  </span>
                </p>
              </div>
              {workspaceSettings && (
                <div className="conversation-person-2">
                  <div className="details">
                    <img
                      className="user-avatar"
                      src={workspaceSettings?.logo || user_avatar_2}
                      alt="user_avatar"
                    />
                    <span className="description">
                      <p>{reviewComment}</p>
                      
                    </span>
                  </div>
                  <p className="text-end mt-2 date">
                    {person_2?.date_title || "Replied"} -{" "}
                    <span>
                      {
                        moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + (workspaceSettings?.workspaceName || "WS Name")
                        // moment(details?.requested_time).format('Y-m-d')
                      }
                    </span>
                  </p>
                </div>
              )}

              {/* </InfiniteScroll> */}
            </div>
          </Scrollbars>
        </div>
        <div className="conversation-footer">
          <p className="text-start my-2 px-3 sender">
            Sending as {workspaceSettings?.workspaceName || "WS Name"}
          </p>
          <div className="message-box">
            <div className="message">
              <div className="text-container">
                <textarea
                  ref={textRef}
                  className=""
                  defaultValue={person_2?.message}
                  placeholder="Write here..."
                  onChange={(e: any) => {
                    const { value } = e?.target;
                    setReplyText(value);
                  }}
                  style={{ resize: "none" }}
                  autoFocus={isReply}
                />
              </div>
              <div className="reply-btn">
                <button
                  style={{
                    background: `${isReply ? "#E4E7EC" : "#E4E7EC"}`,
                  }}
                  onClick={() => {
                    if (isReply) {
                      handleReply();
                    } else {
                      setIsReply(true);
                    }
                  }}
                  type="button"
                >
                  {person_2?.message && isReply
                    ? "Save"
                    : isReply
                    ? "Send"
                    : person_2?.message
                    ? "Edit"
                    : "Reply"}
                </button>
                <img
                  onClick={() => setIsReply(false)}
                  src={isReply ? lock_icon : unlock_icon}
                  alt="unlock"
                />
              </div>
              <p className={`click-reply-des ${isReply ? "d-none" : ""}`}>
                Click reply and start adding your reply.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="conversation-container-mobile">
        <div className="conversation-header">
          <div className="header-top">
            <img
              onClick={handlePrevPath}
              className="back"
              src={back_btn}
              alt="back"
            />
            <img
              className="provider"
              src={getImage(provider)}
              alt="provider"
            />
          </div>
          {/* <div className="header-body">
            <div className="body-left">
              <div className="first-ws">
                {
                  reviewerPhoteUrl
                  ? <img
                      className="user-avatar"
                      src={reviewerPhoteUrl || user_avatar}
                      alt="avatar"
                    />
                  : <span className='user-avatar'>
                      {
                        recieverName?.split(" ")?.slice(0, 2)?.length === 2
                        ? recieverName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                        : recieverName?.substr(0, 2).toUpperCase()
                      }
                    </span>
                }
                <div className="user-info">
                  <p className="user-name">
                    {reviewerDisplayName?.substr(0, 6) ||
                      reviewedbyUserName?.substr(0, 6) ||
                      mentionedbyUserName?.substr(0, 6) ||
                      "Client"}
                  </p>
                  <span className="review-type">
                    {"mentioned"}
                  </span>
                  <p className="ws-name">
                    {workspaceSettings?.workspaceName?.substr(0, 6) ||
                      "WS Name"}
                  </p>
                </div>
              </div>
              <div className="second-ws">
                <img
                  className="image-logo"
                  src={workspaceSettings?.logo || user_avatar_2}
                  alt="user_avatar"
                />
                <p className="ws-name">
                  {workspaceSettings?.workspaceName?.substr(0, 7) ||
                    "WS Name"}
                </p>
              </div>
            </div>
            <div className="body-right">
              <img
                onClick={() =>
                  handleReplaceConversation("prev", conversation?.SlNo)
                }
                src={prev_icon}
                alt="prev"
                title='Previous'
              />
              <img
                onClick={() =>
                  handleReplaceConversation("next", conversation?.SlNo)
                }
                src={next_icon}
                alt="next"
                title='Next'
              />
              <img
                onClick={() => dispatch(setSuccessMessage("Refreshed"))}
                src={refresh_icon}
                alt="refresh"
                title='Refresh'
              />
            </div>
          </div> */}
          <ConversationHeaderSD
            header_title={" mentioned "}
            workSpaceName={workSpaceName}
            workspaceSettings={workspaceSettings}
            conversation={conversation}
            handleReplaceConversation={handleReplaceConversation}
            prev_icon={prev_icon}
            next_icon={next_icon}
            refresh_icon={refresh_icon}
            user_avatar_2={user_avatar_2}
            user_avatar={user_avatar}
          />
          <div className="header-bottom">
            <span
              onClick={() => isGoForDetails && handleDetailChange()}
              style={{ color: !isGoForDetails ? "#1570EF" : "" }}
            >
              Messages
            </span>
            <span
              onClick={() => !isGoForDetails && handleDetailChange()}
              style={{ color: isGoForDetails ? "#1570EF" : "" }}
            >
              Details
            </span>
          </div>
        </div>
        {isGoForDetails ? (
          <div className="conversation-details-mobile-container">
            <ConversationDetails conversationId={conversationId} />
          </div>
        ) : (
          <>
            <div className="conversation-body">
              <Scrollbars
                style={{
                  paddingRight: 10,
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                // style={{ paddingRight: 10 }}
                autoHide
                autoHideTimeout={500}
                autoHideDuration={500}
                autoHeight
                // autoHeightMin={250}
                // autoHeightMax={250}
                // autoHeightMin={480}
                autoHeightMax={"100%"}
                thumbMinSize={10}
                universal={true}
              >
                <div className="conversation-body" id="scrollableDiv">
                  {/* This will implement it feeds.tsx file */}

                  <div className="conversation-person-1">
                    <div className="details">
                      {
                        reviewerPhoteUrl
                        ? <img
                            className="user-avatar"
                            src={reviewerPhoteUrl || user_avatar}
                            alt="avatar"
                          />
                        : <div className='user-avatar'>
                            {
                              recieverName?.split(" ")?.slice(0, 2)?.length === 2
                              ? recieverName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                              : recieverName?.substr(0, 2).toUpperCase()
                            }
                          </div>
                      }
                      <span className="description">
                        <div
                          className={`d-flex align-items-center user-ratings-container`}
                        >
                          <div className=" user_name me-2">
                            {mentionedbyUserName ||
                              recieverName ||
                              "User Name"}
                          </div>
                          {starRating || ratingStars ? (
                            <div className="">
                              <div className="rating-social-email">
                                <div className="py-2">
                                  {starRating || ratingStars ? (
                                    <div>
                                      {Array.from(
                                        Array(
                                          Math.round(
                                            starRating || ratingStars
                                          )
                                        ).keys()
                                      ).map((_: any, index: number) => {
                                        return (
                                          <img
                                            className="start"
                                            key={index}
                                            src={start}
                                            alt="star"
                                          />
                                        );
                                      })}
                                      {Array.from(
                                        Array(
                                          5 -
                                            Math.round(
                                              starRating || ratingStars
                                            )
                                        ).keys()
                                      ).map((_: any, index: number) => {
                                        return (
                                          <img
                                            className="star"
                                            key={index}
                                            src={star_half}
                                            alt="star"
                                          />
                                        );
                                      })}
                                      <span className="ms-2">
                                        {starRating || ratingStars}/5
                                      </span>
                                    </div>
                                  ) : person_1?.social_link ? (
                                    <span className="">
                                      {person_1?.social_link}
                                    </span>
                                  ) : (
                                    <span className="">
                                      {person_1?.user_email}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <span className="d-block client-container my-2">
                          {mentionedbyUserEmailId || "@kuber_mannur"}
                        </span>
                        <p>{reviewComment}</p>
                        <div className="d-flex align-items-center">
                          <img
                            style={{ height: 15, width: 15 }}
                            className="d-block me-3"
                            src={getImage("retweet")}
                            alt="retweet"
                          />
                          <span className="d-flex align-items-center">
                            <img
                              style={{ height: 10, width: 10 }}
                              className="d-block"
                              src={getImage("heart")}
                              alt="heart"
                            />
                            {retweetCount !== null ? (
                              <span className="mx-1">
                                {retweetCount === 0
                                  ? 0
                                  : retweetCount === 1
                                  ? `${retweetCount} like`
                                  : `${retweetCount} Retweets`}
                              </span>
                            ) : null}
                          </span>
                        </div>
                      </span>
                    </div>
                    <p className="text-start mt-2 date">
                      {"Twitter Mention"}{" "}
                      -{" "}
                      <span>
                        {
                          // new Date(createdOn).toDateString() +
                          moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + (workspaceSettings?.workspaceName || "WS Name")
                          // moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + ("Tezla Inc")
                          // moment(details?.requested_time).format('Y-m-d')
                        }
                      </span>
                    </p>
                  </div>
                  {workspaceSettings && (
                    // {reviewComment2 && (
                    <div className="conversation-person-2">
                      <div className="details">
                        <img
                          className="user-avatar"
                          src={workspaceSettings?.logo || user_avatar_2}
                          alt="user_avatar"
                        />
                        <span className="description">
                          <p>{reviewComment}</p>
                        </span>
                      </div>
                      <p className="text-end mt-2 date">
                        {person_2?.date_title || "Replied"} -{" "}
                        <span>
                          {
                            moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + (workspaceSettings?.workspaceName || "WS Name")
                            // moment(details?.requested_time).format('Y-m-d')
                          }
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </Scrollbars>
            </div>
            <div className="conversation-footer">
              <div
                style={{ padding: "0 10px" }}
                className="d-flex align-items-center justify-content-between my-2"
              >
                <p className="text-start sender">
                  Sending as {workspaceSettings?.workspaceName || "WS Name"}
                </p>
                <img
                  onClick={() => setIsReply(false)}
                  src={isReply ? lock_icon : unlock_icon}
                  alt="unlock"
                />
              </div>
              <div className="message-box">
                <div className="message">
                  <div className="text-container">
                    <textarea
                      ref={textRef}
                      className=""
                      defaultValue={person_2?.message}
                      placeholder="Write here..."
                      onChange={(e: any) => {
                        const { value } = e?.target;
                        setReplyText(value);
                      }}
                      style={{ resize: "none" }}
                      autoFocus={isReply}
                    />
                  </div>
                  <div className="send-reply-action">
                    <div
                      onClick={() => {
                        if (isReply) {
                          handleReply();
                        } else {
                          setIsReply(true);
                        }
                      }}
                      // type="button"
                    >
                      {person_2?.message || isReply ? (
                        <img src={send_icon} alt="save-account" />
                      ) : person_2?.message ? (
                        <img src={edit_icon} alt="edit" />
                      ) : (
                        <img src={reply_icon} alt="reply" />
                      )}
                    </div>
                  </div>
                  <p
                    className={`click-reply-des ${isReply ? "d-none" : ""}`}
                  >
                    Click reply and start adding your reply.
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}
