import React, {useState, useEffect} from "react";
// import { useNavigate } from "react-router-dom";
import "./TeamsManagement.scss";
import { useSelector, useDispatch } from "react-redux";
import { getImage } from "../../../../unified/image_service";
import AddUser from "./AddUser";
import CommonModalCustomWidth from "../../../../components/CommonModal/CommonModalCustomWidth";
import { setReadyToTeamMemberUser, toggleUpdatedTeamContent, triggerRemoveTeamMember } from "../teamsReducer";
import Dialog from "../../../../components/Dialogs/Dialog";

const TeamMembers: React.FC = () => {
    const dispatch = useDispatch();
    const { is_ready_to_add_team_member_user, is_updated_team_content, editable_team, team_users: teamMembers } = useSelector((state: any) => state?.teamState);
    // const [teamMembers, setTeamMembers] = useState<any>([]);
    // useEffect(() => {
    //     if (editable_team?.teamId) {
    //         const team_members = setting_membered_users?.filter((item: any) => item?.teamId === editable_team?.teamId);
    //         dispatch(setTeamMembersByTeamId(team_members));
    //     }
    // }, [editable_team?.teamId]);

    
    useEffect(() => {
        if (is_updated_team_content) {
            dispatch(toggleUpdatedTeamContent(false));
            handleCancel();
        }
    }, [is_updated_team_content]);

    const [isDeleteTeamMember, setIsDeleteTeamMember] = useState<any>(null);
    const handleDeleteTeamMember = (member_id?: any) => {
        if (!member_id) {
            setIsDeleteTeamMember(null);
            return;
        }
        // const clapupUserId = localStorage.getItem('cuid') || sessionStorage.getItem('cuid') || null;
        // const workspaceId = localStorage.getItem('wuid') || sessionStorage.getItem('wuid') || null;
        // const roleId = localStorage.getItem('roleId') || sessionStorage.getItem('roleId') || null;
        console.log("Came for deleting member...", { member_id });
        const data_payload = {
            teamId: editable_team?.teamId,
            clapupUserIds: member_id
        }
        dispatch(triggerRemoveTeamMember(data_payload));
    }


    const handleCancel = () => {
        dispatch(setReadyToTeamMemberUser(false));
    }

    // console.log({ setting_membered_users: teamMembers });
    return (
        <React.Fragment>
            <CommonModalCustomWidth 
                modal_visible={is_ready_to_add_team_member_user} 
                setModalVisible={(flag: any) => dispatch(setReadyToTeamMemberUser(flag))}
                centered={true}
                // okText="Send"
                // cancelText="Cancel"
                padding={"0"}
                class_name="add-user-setting-table-container "
                footer={null}
            >
                <AddUser handleCancel={handleCancel} />
            </CommonModalCustomWidth>
            {/* Delete Team Member */}
            <Dialog
                open={isDeleteTeamMember}
                parentCallback={(flag: any) => handleDeleteTeamMember(flag)}
                classes='dialog-container-content'
                title='Delete Member?'
                warning_dialog_content={`If you click Yes,  the member will be deleted forever. This action is irreversible.` }
                footer={true}
                left_button_text='No, Cancel'
                right_button_text='Yes, Delete'
            />
            <div className="team-member-table">
                <div className='table-responsive'>
                    <table className="table">
                        <thead className="d-none"><tr><th>Email</th><th>Role</th><th></th></tr></thead>
                        <tbody>
                            {teamMembers?.map((member: any, idx: number) => (
                            <tr key={idx}>
                                <td>{member?.email}</td>
                                <td>{member?.roleName}</td>
                                <td>
                                <img
                                    src={getImage("delete-icon")}
                                    alt="Delete"
                                    style={{ cursor: 'pointer' }} 
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevents triggering the row click
                                        setIsDeleteTeamMember(member?.id);
                                    }}
                                />
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="extra"></div>
        </React.Fragment>
    );
};

export default TeamMembers;