import React, {useState} from "react";
// import { useNavigate } from "react-router-dom";

interface DashboardFeaturesProps {
    features?: any;
}

const DashboardFeatures: React.FC<DashboardFeaturesProps> = ({ features }: any) => {
    return (
        <React.Fragment>
            <div className={"feature-container"}>
                {
                    features?.map((feature: any, idx: number) => (
                        <div key={idx} className="feature-body">
                            <div className="feature-title">{ feature?.featureName}</div>
                            <div className="feature-description">{feature?.featureDesc}</div>
                        </div>
                    ))
                }
            </div>
        </React.Fragment>
    );
};

export default DashboardFeatures;