import React from "react";
// import { useSelector } from "react-redux";
// import { RootState } from "../store";
// import { changePage, setGlobalMessageType, triggerGetBotData } from "./reducer";
// import { useNavigate } from "react-router-dom";
// import TopTabs from "../components/layout/TopTabs";
import RightBottomMessagesTabs from "../components/Layout/RightBottomMessagesTabs";
import BotMessagesManagementComponent from "./messages-components/bot-messages";
import WhatsAppMessagesManagementComponent from "./messages-components/whats-app-messages";
// import UnPermission from "../pages/un-permission/UnPermission";
const AllMessagesManagementComponent: React.FC = () => {
    // const { global_message_type } = useSelector((state: RootState) => state?.unified);
    // const dispatch = useDispatch();
    const route_path = window?.location?.pathname;
    // const query_string = window.location.search;
    
    // const history = useNavigate();
    // const {userPermissions} = useSelector((state: any) => state?.login);
    // const [isPermissible, setIsPermissible] = React.useState(true);
    // React.useEffect(() => {
    //     console.log({userPermissions});
    //     const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "Messages") : null;
    //     if( permission?.value === 0){
    //         setIsPermissible(false);
    //     }
    // }, [userPermissions?.length]);
    

    // console.log({global_message_type, data});
    
    return (
        <React.Fragment>
            {/* <TopTabs /> */}
            <RightBottomMessagesTabs />
            {
                route_path?.includes('messages')
                ? <BotMessagesManagementComponent/>
                : route_path?.includes('whatsapp') 
                ? <WhatsAppMessagesManagementComponent />
                : null
            }
        </React.Fragment>
    )
}

export default AllMessagesManagementComponent;

