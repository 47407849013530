import React, { useEffect } from 'react';
// import emptyBox from '../../../../assets/img/Empty.svg';
import prev_icon from '../../../../assets/icons/previous-icon.svg';
import next_icon from '../../../../assets/icons/next-icon.svg';
import refresh_icon from '../../../../assets/icons/refresh-icon-unified.svg';
import user_avatar from '../../../../assets/icons/user-avatar.svg';
import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
import start from '../../../../assets/icons/star-icon.svg';
// import star_half from '../../../../assets/icons/star-half-icon.svg';
import lock_icon from '../../../../assets/icons/lock-icon.svg';
import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
import back_btn from '../../../../assets/icons/back.svg';
import reply_icon from '../../../../assets/icons/reply-icon.svg';
import edit_icon from '../../../../assets/icons/edit-icon.svg';
import send_icon from '../../../../assets/icons/send-icon.svg';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import ConversationDetails from '../AllDetails/ConversationsDetails';
import { RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSuccessMessage,
  // toggleIsInReplyingMode,
  toggleReplyingOn
} from '../../../../unified/reducer';
import ConversationHeaderSD from './ConversationHeaderSD';
import ConversationHeader from './ConversationHeader';
import { getGenerateApiImageByName } from '../../../../unified/image_service';
export default function GoogleConversations(props: any) {
  const dispatch = useDispatch();
  const route_path = window?.location?.pathname;
  const query_string = window.location.search;
  const query_tag = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "qa" : "all";

  const {
    // conversationDetailsData,
    unifiedData,
    isReplyingOn, isInReplyingMode
  }: any = useSelector((state: RootState) => state?.unified);
  const {sender, conversation, handleReplaceConversation, getImage, handlePrevPath, isReply, setIsReply, setReplyText, textRef, handleReply, conversationId, isGoForDetails, handleDetailChange} = props;
  const { tag, replyText } = conversation || {};
  const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
  const { workspaces } = useSelector((state: any) => state?.headerState);
  const workSpaceName: any = workspaces?.find(
    (w: any) => w?.workSpaceId === localStorage.getItem("wuid")
  )?.workSpaceName || "";
  const {
    reviewedbyUserName, reviewerDisplayName,
    reviewerPhoteUrl, mentionedbyUserName,
    createdOn,
    reviewReplyComment, reviewComment, provider,
    starRating, ratingStars,
    // reviewReplyUpdatedon,
    // likeCount, locationName, recieverCompany
  } = conversation || {};
  // console.log({google_conversation: conversation, conversationDetailsData});
  useEffect(() => {
    console.log({ email_sender: sender });
  }, [sender]);
    
  const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: Parameters<F>) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // console.log({value: event.target.value});
    const value = event?.target?.value || "";
    setReplyText(value);
  };

  const debouncedTextChange = debounce(handleTextChange, 500);

  const {
    InboxAll_googleQandAConversation
  } = unifiedData || {};

  const all_details: any[] =
  (route_path?.includes('unified') && query_tag !== 'reviews')
  ? InboxAll_googleQandAConversation
  : [];
  
  const conversationDetailsData: any [] = all_details?.filter((item: any) => item?.QuestionId === conversation?.reviewId);

  console.log({conversationDetailsData});
  
  return (
    <React.Fragment>
      <div className="conversation-section-container-lg h-100">
        {/* <div className="conversation-header">
          <div className="person-container">
            <div className="person-1">
              {
                reviewerPhoteUrl
                ? <img
                    className="user-avatar"
                    src={reviewerPhoteUrl || user_avatar}
                    alt="avatar"
                  />
                : <div className='user-avatar'>
                    {
                      reviewerDisplayName?.split(" ")?.slice(0, 2)?.length === 2
                      ? reviewerDisplayName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                      : reviewerDisplayName?.substr(0, 2).toUpperCase()
                    }
                  </div>
              }
              <span className="description">
                {reviewerDisplayName?.substr(0, 6) || "User Name"}{" "}
                <span className="review">
                  {
                    tag?.includes("googleQandA")
                    ? " asked a question to "
                    : "reviewed"
                  }
                </span>{" "}
                {workspaceSettings?.workspaceName || workSpaceName}
              </span>
            </div>
            <div className="person-2">
              {
                workspaceSettings?.logo
                ? <img
                    // className="user-avatar"
                    src={workspaceSettings?.logo || user_avatar_2}
                    alt="user_avatar"
                  />
                : <span className='user-avatar' style={{padding: '4%', fontSize: "0.75rem", background: '#14B0BF'}}>
                    {
                      workSpaceName?.split(" ")?.slice(0, 2)?.length === 2
                      ? workSpaceName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                      : workSpaceName?.substr(0, 2).toUpperCase()
                    }
                  </span>
              }
              <span className="user-2">
                {workspaceSettings?.workspaceName  || workSpaceName || "WS Name"}
              </span>
            </div>
          </div>
          <div className="conversation-features-media">
            <div className="features">
              <img
                onClick={() =>
                  handleReplaceConversation("prev", conversation?.SlNo)
                }
                src={prev_icon}
                alt="prev"
                title='Previous'
              />
              <img
                onClick={() =>
                  handleReplaceConversation("next", conversation?.SlNo)
                }
                src={next_icon}
                alt="next"
                title='Next'
              />
              <img
                onClick={() => dispatch(setSuccessMessage("Refreshed"))}
                src={refresh_icon}
                alt="refresh"
                title='Refresh'
              />
            </div>
            <div className="media">
              <img src={getImage(provider)} alt="google" />
            </div>
          </div>
        </div> */}
        <ConversationHeader
          header_title={
            tag?.includes("googleQandA")
            ? " asked a question to "
            : " reviewed "
          }
          workSpaceName={workSpaceName}
          workspaceSettings={workspaceSettings}
          conversation={conversation}
          handleReplaceConversation={handleReplaceConversation}
          prev_icon={prev_icon}
          next_icon={next_icon}
          refresh_icon={refresh_icon}
          user_avatar_2={user_avatar_2}
          user_avatar={user_avatar}
        />
        <div className="conversation-section">
          <Scrollbars
            style={{
              paddingRight: 10,
              display: "flex",
              flexDirection: "column-reverse",
            }}
            // style={{ paddingRight: 10 }}
            autoHide
            autoHideTimeout={500}
            autoHideDuration={500}
            autoHeight
            // autoHeightMin={250}
            // autoHeightMax={250}
            // autoHeightMin={480}
            autoHeightMax={"100%"}
            thumbMinSize={10}
            universal={true}
          >
            <div className="conversation-body" style={{flexDirection: "column"}} id="scrollableDiv">
              {/* This will implement it feeds.tsx file */}
              {/* <InfiniteScroll
                dataLength={2}
                className="scrolling"
                next={() => console.log("next")}
                inverse={true}
                hasMore={false}
                loader={<h4 className="text-center">Loading...</h4>}
                scrollableTarget="scrollableDiv"
                height={320}
                
              > */}
              <div className="conversation-person-1">
                <div className="details">
                  {
                    reviewerPhoteUrl
                    ? <img
                        className="user-avatar"
                        src={reviewerPhoteUrl || user_avatar}
                        alt="avatar"
                      />
                    : <img
                          className="user-avatar"
                          src={getGenerateApiImageByName((reviewerDisplayName || mentionedbyUserName || (provider?.includes("facebook") ? "FB" : "USER NAME"))?.substr(0, 2).toUpperCase())}
                          alt="avatar"
                      />
                  }
                  <div className="description">
                    <div
                      className={`d-flex align-items-center user-ratings-container`}
                    >
                      <div className="d-flex user_name me-2 ">
                        {reviewerDisplayName || "User Name"}
                      </div>
                    </div>
                    {
                      starRating && (
                        <div className="rating-social-email">
                          <div>
                            {Array.from(Array(Number(starRating)).keys()).map((_: any, index: number) => {
                              return (
                                <img
                                  className="star"
                                  key={index}
                                  src={start}
                                  alt="star"
                                />
                              );
                            })}
                          </div>
                          <div className="rating">
                            {starRating || ratingStars}/5
                          </div>
                        </div>
                      )
                    }
                    
                    <p>{reviewComment}</p>
                  </div>
                </div>
                <p className="text-start mt-2 date">
                  {"Review"}{" "}
                  -{" "}
                  <span>
                    {
                      moment(createdOn).format("DD MMM YYYY, hh:mm:ss A")
                    }
                  </span>
                </p>
              </div>
              {(reviewReplyComment) ? (
                  <div className="conversation-person-2">
                    <div className="details">
                      {/* <img
                        className="user-avatar"
                        src={workspaceSettings?.logo || user_avatar_2}
                        alt="user_avatar"
                      /> */}
                      {
                        workspaceSettings?.logo
                        ? <img
                            className="user-avatar"
                            src={workspaceSettings?.logo || user_avatar_2}
                            alt="user_avatar"
                          />
                        : <img
                              className="user-avatar"
                              src={getGenerateApiImageByName(workSpaceName)}
                              alt="avatar"
                          />
                      }
                      <span className="description">
                        <p>{reviewReplyComment}</p>
                      </span>
                    </div>
                    <p className="text-end mt-2 date">
                      {"Replied"} - {" "}
                      <span>
                        {
                          moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + (workspaceSettings?.workspaceName || workSpaceName || "WS Name")
                          // moment(details?.requested_time).format('Y-m-d')
                        }
                      </span>
                    </p>
                  </div>
              ) : conversationDetailsData?.length ? (
                  conversationDetailsData?.map((conversation: any, idx: number) => (
                    <div className="conversation-person-2" key={idx}>
                      <div className="details">
                        <img
                          className="user-avatar"
                          src={conversation?.AuthorPhotoUrl || user_avatar_2}
                          alt="user_avatar"
                        />
                        <span className="description">
                          <p>{conversation?.Answer}</p>
                        </span>
                      </div>
                      <p className="text-end mt-2 date">
                        {"Replied"} - {" "}
                        <span>
                          {
                            moment(conversation?.UpdatedTime).format("DD MMM YYYY, hh:mm:ss A") + " by " + (conversation?.AuthorDisplayName || "Author")
                            // moment(details?.requested_time).format('Y-m-d')
                          }
                        </span>
                      </p>
                    </div>
                  ))
                ) : null
              }

              {/* </InfiniteScroll> */}
            </div>
          </Scrollbars>
        </div>
        <div className="conversation-footer">
          <p className="text-start my-2 px-3 sender">
            {
              sender
              ? `${sender} is typing...`
              : `Sending as ${workspaceSettings?.workspaceName || workSpaceName || "WS Name"}`
            }
          </p>
          <div className="message-box">
            <div className="message">
              <div className="text-container">
                <textarea
                  ref={textRef}
                  className=""
                  defaultValue={replyText}
                  placeholder="Write here..."
                  onChange={debouncedTextChange}
                  style={{ resize: "none" }}
                  autoFocus={isReply}
                />
              </div>
              <div className="reply-btn">
                <button
                  style={{
                    background: `${isReply ? "#E4E7EC" : "#E4E7EC"}`,
                  }}
                  onClick={() => {
                    if (isReply) {
                      handleReply();
                    } else {
                      setIsReply(true);
                      dispatch(toggleReplyingOn(true));
                      // dispatch(toggleIsInReplyingMode(true));
                    }
                  }}
                  type="button"
                  disabled={sender ? true : false}
                >
                  {
                    // replyText && isReply
                    // ? "Save" : 
                    isReply
                    ? "Send"
                    : replyText
                    ? "Edit"
                    : "Reply"}
                </button>
                <img
                  onClick={() => {
                    setIsReply(false);
                    dispatch(toggleReplyingOn(false));
                    // dispatch(toggleIsInReplyingMode(false));
                  }}
                  src={(sender || isReplyingOn || isInReplyingMode) ? lock_icon : unlock_icon}
                  alt="lock-unlock"
                />
              </div>
              <p className={`click-reply-des ${(isReply && !sender) ? "d-none" : ""}`}>
                {
                  sender
                  ? "You can not reply now. Editor is locked"
                  : "Click reply and start adding your reply."
                }
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* mobile device */}
      <div className="conversation-container-mobile">
        <div className="conversation-header">
          <div className="header-top">
            <img
              onClick={handlePrevPath}
              className="back"
              src={back_btn}
              alt="back"
            />
            <img
              className="provider"
              src={getImage(provider)}
              alt="provider"
            />
          </div>
          {/* <div className="header-body">
            <div className="body-left">
              <div className="first-ws">
                {
                  reviewerPhoteUrl
                  ? <img
                      className="image-logo"
                      src={reviewerPhoteUrl || user_avatar}
                      alt="avatar"
                    />
                  : <span className='border text-light image-logo d-flex align-items-center justify-content-center p-1'>
                      {
                        reviewerDisplayName?.split(" ")?.slice(0, 2)?.length === 2
                        ? reviewerDisplayName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                        : reviewerDisplayName?.substr(0, 2).toUpperCase()
                      }
                    </span>
                }
                <div className="user-info">
                  <p className="user-name">
                    {reviewerDisplayName?.substr(0, 6) ||
                      reviewedbyUserName?.substr(0, 6) ||
                      mentionedbyUserName?.substr(0, 6) ||
                      "Client"}
                  </p>
                  <span className="review-type">
                    {
                      tag?.includes("googleQandA")
                      ? " asked a question to"
                      : "reviewed"
                    }
                  </span>
                  <p className="ws-name">
                    {workspaceSettings?.workspaceName?.substr(0, 6) || workSpaceName?.substr(0, 6) ||
                      "WS Name"}
                  </p>
                </div>
              </div>
              <div className="second-ws">
                {
                  workspaceSettings?.logo
                  ? <img
                      className="image-logo"
                      src={workspaceSettings?.logo || user_avatar_2}
                      alt="user_avatar"
                    />
                  : <span className='border rounded-circle text-light image-logo d-flex align-items-center justify-content-center' style={{padding: '2%', fontSize: "0.75rem", background: '#14B0BF'}}>
                      {
                        workSpaceName?.split(" ")?.slice(0, 2)?.length === 2
                        ? workSpaceName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                        : workSpaceName?.substr(0, 2).toUpperCase()
                      }
                    </span>
                }
                <p className="ws-name">
                  {workspaceSettings?.workspaceName?.substr(0, 7) || workSpaceName?.substr(0, 7) ||
                    "WS Name"}
                </p>
              </div>
            </div>
            <div className="body-right">
              <img
                onClick={() =>
                  handleReplaceConversation("prev", conversation?.SlNo)
                }
                src={prev_icon}
                alt="prev"
                title='Previous'
              />
              <img
                onClick={() =>
                  handleReplaceConversation("next", conversation?.SlNo)
                }
                src={next_icon}
                alt="next"
                title='Next'
              />
              <img
                onClick={() => dispatch(setSuccessMessage("Refreshed"))}
                src={refresh_icon}
                alt="refresh"
                title='Refresh'
              />
            </div>
          </div> */}
          <ConversationHeaderSD
            header_title={
              tag?.includes("googleQandA")
              ? " asked a question to "
              : " reviewed "
            }
            workSpaceName={workSpaceName}
            workspaceSettings={workspaceSettings}
            conversation={conversation}
            handleReplaceConversation={handleReplaceConversation}
            prev_icon={prev_icon}
            next_icon={next_icon}
            refresh_icon={refresh_icon}
            user_avatar_2={user_avatar_2}
            user_avatar={user_avatar}
          />
          <div className="header-bottom">
            <span
              onClick={() => isGoForDetails && handleDetailChange()}
              style={{ color: !isGoForDetails ? "#1570EF" : "" }}
            >
              Messages
            </span>
            <span
              onClick={() => !isGoForDetails && handleDetailChange()}
              style={{ color: isGoForDetails ? "#1570EF" : "" }}
            >
              Details
            </span>
          </div>
        </div>
        {isGoForDetails ? (
          <div className="conversation-details-mobile-container">
            <ConversationDetails conversationId={conversationId} />
          </div>
        ) : (
          <>
            <div className="conversation-body">
              <Scrollbars
                style={{
                  paddingRight: 10,
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                // style={{ paddingRight: 10 }}
                autoHide
                autoHideTimeout={500}
                autoHideDuration={500}
                autoHeight
                // autoHeightMin={250}
                // autoHeightMax={250}
                // autoHeightMin={480}
                autoHeightMax={"100%"}
                thumbMinSize={10}
                universal={true}
              >
                <div className="conversation-body" style={{flexDirection: 'column'}} id="scrollableDiv">
                  {/* This will implement it feeds.tsx file */}

                  <div className="conversation-person-1">
                    <div className="details">
                      {
                        reviewerPhoteUrl
                        ? <img
                            className="user-avatar"
                            src={reviewerPhoteUrl || user_avatar}
                            alt="avatar"
                          />
                        : <img
                              className="user-avatar"
                              src={getGenerateApiImageByName(reviewerDisplayName)}
                              alt="avatar"
                          />
                      }
                      <div className="description">
                        <div
                          className={`d-flex align-items-center user-ratings-container`}
                        >
                          <div className="user_name me-2">
                            {reviewerDisplayName || "User Name"}
                          </div>
                        </div>
                        {
                          starRating && (
                            <div className="rating-social-email">
                              <div>
                                {Array.from(Array(Number(starRating)).keys()).map((_: any, index: number) => {
                                  return (
                                    <img
                                      className="star"
                                      key={index}
                                      src={start}
                                      alt="star"
                                    />
                                  );
                                })}
                              </div>
                              <div className="rating">
                                {starRating || ratingStars}/5
                              </div>
                            </div>
                          )
                        }
                        <p>{reviewComment}</p>
                      </div>
                    </div>
                    <p className="text-start mt-2 date">
                      {"Review "} - {" "}
                      <span>
                        {
                          // new Date(createdOn).toDateString() +
                          moment(createdOn).format("DD MMM YYYY, hh:mm:ss A")
                          // moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + (workspaceSettings?.workspaceName || "WS Name")
                          // moment(details?.requested_time).format('Y-m-d')
                        }
                      </span>
                    </p>
                  </div>
                    {
                      reviewReplyComment ? (
                      // {reviewComment2 && (
                      <div className="conversation-person-2">
                        <div className="details">
                          {
                            workspaceSettings?.logo
                            ? <img
                                className="user-avatar"
                                src={workspaceSettings?.logo || user_avatar_2}
                                alt="user-2"
                              />
                            : <img
                                  className="user-avatar"
                                  src={getGenerateApiImageByName(workSpaceName)}
                                  alt="avatar"
                              />
                          }
                          {/* <img
                            className="user-avatar"
                            src={workspaceSettings?.logo || user_avatar_2}
                            alt="user_avatar"
                          /> */}
                          <span className="description">
                            <p>{reviewReplyComment}</p>
                          </span>
                        </div>
                        <p className="text-end mt-2 date">
                          {"Replied"} -{" "}
                          <span>
                            {
                              moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + (workspaceSettings?.workspaceName || workSpaceName || "WS Name")
                              // moment(details?.requested_time).format('Y-m-d')
                            }
                          </span>
                        </p>
                      </div>
                      ) : conversationDetailsData?.length ? (
                        conversationDetailsData?.map((conversation: any, idx: number) => (
                          <div className="conversation-person-2" key={idx}>
                            <div className="details">
                              <img
                                className="user-avatar"
                                src={conversation?.AuthorPhotoUrl || user_avatar_2}
                                alt="user_avatar"
                              />
                              <span className="description">
                                <p>{conversation?.Answer}</p>
                              </span>
                            </div>
                            <p className="text-end mt-2 date">
                              {"Replied"} - {" "}
                              <span>
                                {
                                  moment(conversation?.UpdatedTime).format("DD MMM YYYY, hh:mm:ss A") + " by " + (conversation?.AuthorDisplayName || "Author")
                                  // moment(details?.requested_time).format('Y-m-d')
                                }
                              </span>
                            </p>
                          </div>
                        ))
                      ) : null
                    }
                </div>
              </Scrollbars>
            </div>
            <div className="conversation-footer">
              <div
                style={{ padding: "0 10px" }}
                className="d-flex align-items-center justify-content-between my-2"
              >
                <p className="text-start sender">
                  {
                    sender
                    ? `${sender} is typing...`
                    : `Sending as ${workspaceSettings?.workspaceName || workSpaceName || "WS Name"}`
                  }
                </p>
                <img
                  onClick={() => {
                    setIsReply(false);
                    dispatch(toggleReplyingOn(false));
                    // dispatch(toggleIsInReplyingMode(false));
                  }}
                  src={(sender || isReplyingOn || isInReplyingMode) ? lock_icon : unlock_icon}
                  alt="lock-unlock"
                />
              </div>
              <div className="message-box">
                <div className="message">
                  <div className="text-container">
                    <textarea
                      ref={textRef}
                      className=""
                      defaultValue={""}
                      placeholder="Write here..."
                      onChange={debouncedTextChange}
                      style={{ resize: "none" }}
                      autoFocus={isReply}
                    />
                  </div>
                  <div className="send-reply-action">
                    <div
                      onClick={() => {
                        if (!sender) {
                          if (isReply) {
                            handleReply();
                          } else {
                            setIsReply(true);
                            dispatch(toggleReplyingOn(true));
                            // dispatch(toggleIsInReplyingMode(true));
                          }
                        }
                      }}
                      // type="button"
                      // disabled={sender ? true : false}
                    >
                      {replyText || isReply ? (
                        <img src={send_icon} alt="save-account" />
                      ) : replyText ? (
                        <img src={edit_icon} alt="edit" />
                      ) : (
                        <img src={reply_icon} alt="reply" />
                      )}
                    </div>
                  </div>
                  <p
                    className={`click-reply-des ${(isReply && !sender) ? "d-none" : ""}`}
                  >
                      {
                        sender
                        ? "You can not reply now. Editor is locked"
                        : "Click reply and start adding your reply."
                      }
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}
