// contentApi.js

import AWS from 'aws-sdk';

// import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";

import {
    aws_account_id, aws_access_key, aws_secret_key,
    // cloudFlareR2ServiceUrl,
    api_url
} from './server';

// import { fromIni } from "@aws-sdk/credential-providers";
// import {
//     getSignedUrl,
//     S3RequestPresigner,
// } from "@aws-sdk/s3-request-presigner";
// import { formatUrl } from "@aws-sdk/util-format-url";

import axios from 'axios';

// const createPresignedUrlWithoutClient = async ({ Region: region, Bucket: bucket, Key: key }: any) => {
//     const url = parseUrl(`https://${bucket}.s3.${region}.amazonaws.com/${key}`);
//     const presigner = new S3RequestPresigner({
//         credentials: fromIni(),
//         region,
//         sha256: Hash.bind(null, "sha256"),
//     });

//     const signedUrlObject = await presigner.presign(new HttpRequest(url));
//     return formatUrl(signedUrlObject);
// };

const getContentUrl = async (data: any) => {
    const {
        folderPath,
        contentFileName,
        uniqueNo,
        workspaceId,
        // url: aws_presigned_url,
    } = data;

    const lastDotIndex = contentFileName?.lastIndexOf('.');
    const path_key = `${folderPath}/` + contentFileName.slice(0, lastDotIndex) + `_${uniqueNo}.` + contentFileName.slice(lastDotIndex + 1);

    const s3BucketConfig = {
        endpoint: `https://${aws_account_id}.r2.cloudflarestorage.com`,
        accessKeyId: aws_access_key,
        secretAccessKey: aws_secret_key,
        signatureVersion: 'v4',
    };

    const s3 = new AWS.S3(s3BucketConfig);

    const s3Params = {
        Bucket: workspaceId,
        Key: path_key,
    };

    return new Promise((resolve, reject) => {
        s3.getObject(s3Params, (err: any, result: any) => {
            if (err) {
                console.error('Error downloading file:', { content_data: data, s3Params, err });
                // reject(err);
                resolve("");
            } else {
                try {
                    const blob = new Blob([result?.Body], { type: 'application/octet-stream' });
                    const url = URL.createObjectURL(blob);
                    resolve(url);
                } catch (error) {
                    console.error('Error creating download link:', error);
                    // reject(error);
                    resolve("");
                }
            }
        });
    });
};

// Old ==== Perfect
// const downloadContent = async (data: any) => {
//     try {
//         const contentUrl = await getContentUrl(data);
//         if (contentUrl) {
//             console.log({ imageUrlBlob: contentUrl });

//             // Create a hidden anchor element
//             const anchor: any = document.createElement('a');
//             anchor.href = contentUrl;
//             anchor.download = data?.contentFileName; // Specify a default filename for the downloaded file

//             // Append the anchor to the document body
//             document.body.appendChild(anchor);

//             // Simulate a click on the anchor to trigger the download
//             anchor.click();

//             // Remove the anchor from the document body
//             document.body.removeChild(anchor);
//             console.log({ contentUrl });
//         }
//         return contentUrl;
//     } catch (err) {
//         console.error(err);
//         return "";
//     }
// };

// // New one
const downloadContent = async (data: any) => {
    try {
        // const imageUrl = 'https://3potterstemp-cdn.clapup.me/kuber-test/MehediClapup3_12.jpg';

        // const response = await axios.get(imageUrl, { responseType: 'arraybuffer' });
        // console.log({ response });

        // const contentUrl = await getContentUrl(data);
        const contentUrl = await getContentUrl(data);
        if (contentUrl) {
            console.log({ imageUrlBlob: contentUrl });

            // Create a hidden anchor element
            const anchor: any = document.createElement('a');
            anchor.href = contentUrl;
            anchor.download = data?.contentFileName; // Specify a default filename for the downloaded file

            // Append the anchor to the document body
            document.body.appendChild(anchor);

            // Simulate a click on the anchor to trigger the download
            anchor.click();

            // Remove the anchor from the document body
            document.body.removeChild(anchor);
            console.log({ contentUrl });
        }
        return contentUrl;
    } catch (err) {
        console.error(err);
        return "";
    }
};


// // Kuber Dada ==== Perfect
// const download2Content = async (data?: any) => {
//     console.log({ content_data: data });
//     try {
//         // Replace "@" with "/" in the presigned URL
//         let imageUrl = data?.presignedUrl?.replaceAll("@", "/");

//         // Check if the URL has expired and regenerate if necessary
//         if (new Date(data?.urlExpiresOn) < new Date()) {
//             console.log({ content_data: data });
//             const presigned_res = await generatePresignedUrl(data);
//             imageUrl = presigned_res?.presignedUrl?.replaceAll("@", "/");
//         }

//         // Fetch the file from the presigned URL
//         const response = await fetch(imageUrl);

//         // Ensure the response is okay
//         if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         // Extract headers for potential use (logging here)
//         const contentType = response.headers.get('Content-Type');
//         const contentDisposition = response.headers.get('Content-Disposition');
//         const contentLength = response.headers.get('Content-Length');
//         console.log({ contentType, contentDisposition, contentLength });

//         // Convert the response into a Blob
//         const blob = await response.blob();

//         // Create a link element for downloading
//         const link = document.createElement('a');
//         link.href = URL.createObjectURL(blob);

//         // Set the filename for the download from the response headers or fallback to a default
//         let filename = data?.contentFileName || 'downloaded-file.png';
//         if (contentDisposition) {
//             const filenameMatch: any = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
//             if (filenameMatch.length > 1) {
//                 filename = filenameMatch[1].replace(/['"]/g, '');
//             }
//         }
//         link.download = filename;

//         // Append the link to the document body
//         document.body.appendChild(link);

//         // Trigger the click event on the link
//         link.click();

//         // Remove the link from the document body
//         document.body.removeChild(link);

//         return true;
//     } catch (error) {
//         console.error('Error downloading file:', error);
//         return false;
//     }
// };

// Kuber Dada - 2 ==== Perfect
const download2Content = async (data?: any) => {
    console.log({ content_data: data });
    try {
        // Replace "@" with "/" in the presigned URL
        let contentUrl = data?.presignedUrl?.replaceAll("@", "/");
        let filename = data?.contentFileName || 'downloaded-file.png';
        // Create an anchor element
        const link = document.createElement('a');
        link.href = contentUrl;
        link.download = filename;

        // Append the anchor to the body (required for Firefox)
        document.body.appendChild(link);

        // Trigger the download by simulating a click
        link.click();

        // Remove the anchor from the body
        document.body.removeChild(link)

        return true;
    } catch (error) {
        console.error('Error downloading file:', error);
        return false;
    }
};

// old one
// const download2Content = async (data?: any) => {
//     console.log({ content_data: data });
//     try {
//         let imageUrl = data?.presignedUrl?.replaceAll("@", "/");
//         if (new Date(data?.urlExpiresOn) < new Date()) {
//             console.log({ content_data: data });
//             const presigned_res = await generatePresignedUrl(data);
//             imageUrl = presigned_res?.presignedUrl?.replaceAll("@", "/");;
//         }
//         const response = await fetch(imageUrl?.replaceAll("@", "/"));
//         const blob: any = await response.blob();
//         // Create a File object
//         // const blob = new Blob(
//         //     [
//         //         imageUrl?.replaceAll("@", "/")
//         //     ],
//         //     {
//         //         type: data?.category?.toLowerCase() === "videos"
//         //             ? "video/mp4"
//         //             : "image/*"
//         //     }
//         // );
//         // const file = new File([blob], 'downloaded-file.png', { type: blob.type });

//         // console.log({ file });
//         // Create a link element
//         const link = document.createElement('a');
//         link.href = URL.createObjectURL(blob);
//         // link.href = URL.createObjectURL(file);
//         link.download = data?.contentFileName || `downloaded-file.png`; // Set the desired file name
//         // link.download = "download-file.jpg"; // testing
//         // Append the link to the document
//         document.body.appendChild(link);

//         // Trigger the click event on the link
//         link.click();

//         // Remove the link from the document
//         document.body.removeChild(link);
//         return true;
//     } catch (error) {
//         console.error('Error downloading file:', error);
//         return false;
//     }

// };

// Kuber Dada ==== working
const generatePresignedUrl = async (content?: any) => {
    const contentId = content?.id;

    try {
        // const response: any = await fetch(
        //     "https://images.clapup.io/website/Clapup-dashboard.png",
        //     {
        //         method: "GET",
        //         mode: 'no-cors',
        //     }
        // );
        // if I want to use axios with mode: "no-cors"
        const { data, status } = await axios.get(
            `${api_url}/api/v1.2/gallery/GeneratePresignedUrls?contentId=${contentId}`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        if (status === 200) {
            return data;
        } else if ((status === 403 || status === 401)) {
            window.location.reload();
        } else {
            return null;
        }
    } catch (err: any) {

        console.log({ err: err?.message });
        return null;
    }
};

// Kuber Dada ==== working
const getGeneratedVideoPresignedUrlByContentIdVideoTestimonial = async (contentId?: any) => {

    try {
        // const response: any = await fetch(
        //     "https://images.clapup.io/website/Clapup-dashboard.png",
        //     {
        //         method: "GET",
        //         mode: 'no-cors',
        //     }
        // );
        // if I want to use axios with mode: "no-cors"
        const { data, status } = await axios.get(
            `${api_url}/api/v1.2/gallery/GetContentDetails?contentId=${contentId}`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        if (status === 200) {
            return data;
        } else if ((status === 403 || status === 401)) {
            window.location.reload();
        } else {
            return null;
        }
    } catch (err: any) {

        console.log({ err: err?.message });
        return null;
    }
};

const modifyCurrentString = (originalString = "", firstLen = 0, isDot = "...") => {
    // // Define a regular expression pattern to capture the dynamic prefix
    // const pattern: any = /^(.*?-)(\d+)(\..*)$/;

    // // Use the regular expression to match and capture the parts of the string
    // const match = originalString.match(pattern);

    // // Check if the captured dynamic prefix has a length greater than 7
    // if (match && match[1].length > 7) {
    if (originalString.length > firstLen) {
        // // Modify the string with a shortened prefix
        const firstPart = originalString.slice(0, firstLen);
        // const lastPart = originalString.slice(-lastLen);
        return `${firstPart}${isDot || "..."}${""}`;
        // return match[1].substring(0, match[1].length - 2) + ".." + match[3];
    } else {
        // // If the length is not greater than 12, return the original string
        return originalString;
    }
};

const modifyString = (originalString = "", firstLen = 5, lastLen = 6, isDot = "...") => {
    // // Define a regular expression pattern to capture the dynamic prefix
    // const pattern: any = /^(.*?-)(\d+)(\..*)$/;

    // // Use the regular expression to match and capture the parts of the string
    // const match = originalString.match(pattern);

    // // Check if the captured dynamic prefix has a length greater than 7
    // if (match && match[1].length > 7) {
    if (originalString.length > 12 && isDot) {
        // // Modify the string with a shortened prefix
        const firstFive = originalString.slice(0, firstLen);
        const lastFive = originalString.slice(-lastLen);
        return `${firstFive}${isDot}${lastFive}`;
        // return match[1].substring(0, match[1].length - 2) + ".." + match[3];
    } else {
        // // If the length is not greater than 12, return the original string
        return originalString;
    }
};

// get any file size by url
const getFileSizeByURL = async (url: string): Promise<string | null> => {
    try {
        // const response = await fetch(url, { method: 'HEAD' });
        // Perform a HEAD request to get the file metadata
        const response: any = await axios.head(url);

        // Get the Content-Length header to determine the file size

        if (response?.status !== 200) {
            throw new Error('Network response was not ok');
        }

        // const contentLength = response.headers.get('Content-Length');

        const contentLength = response.headers['content-length'];
        // console.log({ response });

        // if (!contentLength) {
        //     throw new Error('Content-Length header is not available');
        // }

        // Convert the file size to a human-readable format
        const sizeInBytes: number = contentLength ? parseInt(contentLength, 10) : 0;
        // const sizeInBytes: number = parseInt(contentLength, 10);

        // Convert bytes to kilobytes (KB)
        const sizeInKB: number = parseFloat((sizeInBytes / 1024).toFixed(2));

        // Convert bytes to megabytes (MB)
        const sizeInMB: number = parseFloat((sizeInKB / 1024).toFixed(2));
        let fileSize: string = ""; // Change the type of fileSize to string
        // if (sizeInMB > 15) {
        //   dispatch(setShowingUploadingProgress(false));
        //   dispatch(setIsContentUploadedError(true));
        //   dispatch(setErrorMessage(`Error. Maximum file size is 15 MB`));
        //   return;
        // }
        if (sizeInMB > 1) {
            fileSize = sizeInMB.toFixed(2) + " MB";
        } else {
            fileSize = sizeInKB.toFixed(2) + " KB";
        }

        return `${fileSize}`;
    } catch (error) {
        console.error('Error fetching file size:', error);
        return null;
    }
};

export { getFileSizeByURL, modifyCurrentString, modifyString, getContentUrl, downloadContent, download2Content, generatePresignedUrl, getGeneratedVideoPresignedUrlByContentIdVideoTestimonial };
