import React from 'react';
// import save_icon from '../../../../../../assets/icons/settings/save-icon.svg';
// import arrow_down_icon from '../../../../../../assets/icons/settings/arrow-down.svg';
// import upload_icon from '../../../../../../assets/icons/settings/upload-icon.svg';
import nested_folder_icon from '../../../../../../assets/icons/settings/nested-folder.svg';
import edit_icon from '../../../../../../assets/icons/settings/edit-icon.svg';
import delete_icon from '../../../../../../assets/icons/settings/biopage/delete-icon.svg';

const NestedLink: React.FC<any> = ({ item, setShowCustomNestedLink, setShowEditCustom, setShowDeleteCustom}: any) => {

    return (
        <div className="form-group" title={"Nested Link"}>
            <label>{item?.linkLabel}</label>
            <div className='input-container'>
                {
                    <div className='logo-url-container'>
                        <img
                            src={item?.iconUrl?.replaceAll("@", "/")}
                            alt="item-icon"
                            className={``}
                        />
                        <p className='input-value'>{item?.linkDisplayText}</p>
                    </div>
                }
                <div className='edit-delete-container'>
                    <img
                        title='Add a Nested item'
                        src={nested_folder_icon}
                        alt="nested-folder-icon"
                        className={``}
                        onClick={() => {
                            setShowCustomNestedLink(item);
                        }}
                    />

                    <img
                        title='Edit item'
                        src={edit_icon}
                        alt="edit-icon"
                        className={``}
                        onClick={() => {
                            setShowEditCustom(item);
                        }}
                    />
                    <img
                        title='Delete item'
                        src={delete_icon}
                        alt="delete-icon"
                        className={``}
                        onClick={() => {
                            setShowDeleteCustom(item);
                        }}

                    />
                </div>
            </div>
        </div>
    );
};

export default NestedLink;
