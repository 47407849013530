import { takeEvery, put, call, all, delay } from 'redux-saga/effects';
import Constants from '../constants';
import axios from "axios";
import { generateAccessToken, getNotification } from './reducer';
// import { setAccessToken } from '../login/loginReducer';
import {
    api_url,
    // auth_url
} from '../utils/server';
import Cookies from 'js-cookie';

function* getNotifications(action: any): Generator<any> {

    // console.log("getNotifications action===>", action.payload)
    let getPayload = action.payload || action;
    try {
        const { data, status }: any = yield call(axios.post, api_url + `/api/v1/clapup/getNotificationSettings?userId=${getPayload}`, {}, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        // const { data }: any = yield call(axios.post, `https://dev.clapup.me/api/v1/clapup/getNotificationSettings?userId=${getPayload}`);
        if (status === 200) {   
            // console.log('getNotification==>', data)
            yield put(getNotification(data));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* setNotifications(action: any): Generator<any> {

    console.log("setNotifications action===>", action.payload);
    try {
        const { data, status }: any = yield call(axios.post, api_url + `/api/v1/clapup/setNotificationPreferences`, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {   
            if (data === "success") {
                yield getNotifications(action.payload.clapupUserId);
            }
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* generateToken(action: any): Generator<any> {
    try {
        
        const { data, status }: any = yield call(axios.post, api_url + `/api/v1.2/ClapupIdentity/GenerateJwtToken`,
            action.payload,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    // 'Cookie': document.cookie,
                    // 'Authorization': `Bearer ${Cookies.get('access_token')}`,
                    "Authorization": `refreshToken ${localStorage.getItem('refresh_token') || Cookies.get('access_token')}`,
                    // "refreshToken": `Bearer ${localStorage.getItem('refresh_token')}`,
                },
            // "refreshToken": localStorage.getItem('refresh_token'),
            }
        );
        console.log({token_data: data});
        if (status !== 200 || !data?.jwtToken || !data?.refreshToken) {
            throw new Error('Failed to generate access token');
        }
        localStorage.setItem("access_token", data?.jwtToken);
        localStorage.setItem("refresh_token", data?.refreshToken);
        const cookieOptions: any = {
            // expires: expirationDate,
            httpOnly: false,
            secure: true,
            sameSite: 'strict'
        };
        Cookies.set('access_token', data?.jwtToken, cookieOptions);
        Cookies.set('refresh_token', data?.refreshToken, cookieOptions);
        delay(2000);
        window.location.reload();
        // yield put(setAccessToken(data));

    } catch (error) {
        console.log('generateAccessToken error', error);
        yield put({ type: "LOGOUT" });
        delay(2000);
        window.location.reload();
    }
}


//watcher saga
function* profileSaga() {
    yield all([
        takeEvery(Constants.TRIGGER_NOTIFICATIONS, getNotifications),
        takeEvery(Constants.TRIGGER_SET_NOTIFICATION, setNotifications),
        takeEvery(Constants.GENERATE_ACCESS_TOKEN, generateToken),
    ]);
}

export default profileSaga;