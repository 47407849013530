import React from 'react';
import '../Conversations.scss';
import user_avatar from '../../../../assets/icons/user-avatar.svg';
import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
import start from '../../../../assets/icons/star-icon.svg';
import star_half from '../../../../assets/icons/star-half-icon.svg';
import google_logo from '../../../../assets/icons/google-logo.svg';
import twitter_logo from '../../../../assets/icons/twitter-icon.svg';
import facebook_logo from '../../../../assets/icons/facebook-reviews.svg';
import feedback_logo from '../../../../assets/icons/feedback-line-1.2.svg';
import video_logo from '../../../../assets/icons/video-chat-line-1.2.svg';
import lock_icon from '../../../../assets/icons/lock-icon.svg';
import fb_recommendation from '../../../../assets/icons/fb-recommendation-star.svg';
import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
import like_icon from '../../../../assets/icons/feather-thumbs-up.svg';
import heart_icon from '../../../../assets/icons/heart-icon.svg';
import retweet_icon from '../../../../assets/icons/retweet-icon.svg';
import { useEffect, useState } from 'react';
// import moment from 'moment';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setConversationDetailsData } from '../../../../unified/reducer';
import GoogleDetails from '../Details/GoogleDetails';
import FacebookDetails from '../Details/FacebookDetails';
import TwitterDetails from '../Details/TwitterDetails';
import FeedbackDetails from '../Details/FeedbackDetails';
import VideoDetails from '../Details/VideoDetails';
import { RootState } from '../../../../store';
import BotConversationDetails from '../Details/BotConversationDetails';
import { getImage } from '../../../../unified/image_service';
// import { Session } from 'inspector';


interface Props {
  statDetails?: any;
  conversationId?: string;
  searchText?: string;
  type?: string;
}

const ConversationDetails: React.FC<Props> = ({ conversationId, type, statDetails }) => {
  const query_string = window.location.search;
  const tag = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "qa" : "all";
  const {
    unifiedData: data,
    // unifiedDetailsData,
    conversationDetailsData,
  } = useSelector((state: any) => state?.unified);
  // const [unifiedDetailsData, setUnifiedDetailsData] = useState<any>(null);
  let unifiedDetailsData: any = null;
  
  const {
    InboxAll_InboxAll, InboxAll_InboxAllnextDate,
    InboxReviews_InboxReviews, InboxReviews_InboxReviewsNextDate,
    InboxComments_InboxComments, InboxComments_InboxCommentsNextDate,
    InboxMentions_InboxMentions, InboxMentions_InboxMentionsNextDate,
    InboxQandA_InboxQandA, InboxQandA_InboxQandANextDate,
    // statistics
    InboxStatistics_GoogleQandA, InboxStatistics_GoogleReviews,
    InboxStatistics_CustomerFeedback, InboxStatistics_VideoTestimonial,
    
  }: any = data || {};
  const unifiedData: any[] = tag === "all"
      ? InboxAll_InboxAll
      : tag === "reviews"
        ? InboxReviews_InboxReviews
      : tag === "comments"
        ? InboxComments_InboxComments
      : tag === "mentions"
        ? InboxMentions_InboxMentions
      : tag === "qa"
        ? InboxQandA_InboxQandA
      : [];
  
          
  // const {currentWorkspace: curWorkspace} = useSelector((state: any) => state?.headerState);
  const {workspaceSettings} = useSelector(
      (state: any) => state?.workspaceManagementState
  );
  const { workspaces } = useSelector((state: any) => state?.headerState);
  const workSpaceName: any = workspaces?.find(
    (w: any) => w?.workSpaceId === localStorage.getItem("wuid")
  )?.workSpaceName || "";


  // const [conversation, setConversation] = useState<any>(null);
  
  const dispatch = useDispatch();
  const conversation = unifiedData?.find((conversation: any) => conversation?.reviewId === conversationId);
  if(conversation) {
    // setConversation(conversation);
    const dataObj: any = {
        clapupUserId: sessionStorage.getItem('cuid'),
        roleId: sessionStorage.getItem('roleId'),
        workspaceId: sessionStorage.getItem('wuid'),
        reviewId: conversation?.reviewId,
    }
      
    if (conversation?.provider?.includes('google')) {
      
      // dataObj.locationId = conversation?.locationId;
      // dataObj.reviewerDisplayName = conversation?.reviewerDisplayName;
      // console.log({dataObj});
      if (conversation?.tag !== 'reviews') { 
        // console.log({ conversation, stat: data?.InboxStatistics_GoogleReviews, workspaceSettings });
        // console.log({conversation, stat: data?.InboxStatistics_GoogleQandA});
        unifiedDetailsData = InboxStatistics_GoogleQandA?.find((item: any) => item?.QuestionAuthorDisplayName === conversation?.reviewerDisplayName) || null;
        // unifiedDetailsData = InboxStatistics_GoogleQandA?.find((item: any) => item?.locationName === conversation?.reviewerDisplayName) || null;
        // console.log({unifiedDetailsData});
        // setUnifiedDetailsData(unifiedDetailsData);
      } else {
        // console.log({conversation, stat: data?.InboxStatistics_GoogleReviews});
        unifiedDetailsData = InboxStatistics_GoogleReviews?.find((item: any) => item?.ReviewerDisplayName === conversation?.reviewerDisplayName) || null;
        // console.log({workspaceSettings, conversation, stat: data?.InboxStatistics_GoogleReviews, unifiedDetailsData});
        // setUnifiedDetailsData(unifiedDetailsData);
      }
        // dispatch(triggerGoogleReviewsUnifiedDetails(dataObj));
    } else if(conversation?.provider?.includes('facebook')) {
      // dataObj.pageId = conversation?.reviewPageId;
      // dataObj.reviewerName = conversation?.reviewedbyUserName;
      const tag = conversation?.tag;
      if (tag === 'comments') {
        // console.log({conversation, stat: data?.InboxStatistics_FBComments});
        unifiedDetailsData = data?.InboxStatistics_FBComments?.find((item: any) => item?.commentedby === conversation?.reviewerDisplayName);
        // console.log({stat_unified: unifiedDetailsData, conversation});
        // dispatch(triggerFacebookCommentsUnifiedDetails(dataObj));
      } else if (tag === 'mentions') {
        // console.log({ conversation, workspaceSettings });
        unifiedDetailsData = data?.InboxStatistics_FBMentions?.find((item: any) => item?.MentionByUserName === conversation?.reviewerDisplayName);
        // console.log({stat_unified: unifiedDetailsData, conversation});
        // dispatch(triggerFacebookMentionsUnifiedDetails(dataObj));
      } else if (tag === 'reviews') {
        unifiedDetailsData = data?.InboxStatistics_FBReviews?.find((item: any) => item?.reviewedbyusername === conversation?.reviewerDisplayName);
        // console.log({stat_unified: unifiedDetailsData, conversation});
        // dispatch(triggerFacebookReviewsUnifiedDetails(dataObj));
      }
    } else if(conversation?.provider?.includes('twitter')) {
        // will be added later
        // dispatch(triggerTwitterReviewsUnifiedDetails(dataObj));
    // } else if(conversation?.provider === 'customerFeedback') {
    } else if (conversation?.provider?.includes('customer')) {
      // console.log({conversation, stat: data?.InboxStatistics_CustomerFeedback});
      unifiedDetailsData = InboxStatistics_CustomerFeedback?.find((item: any) => item?.RecieverEmailId === conversation?.reviewerEmail);
      // console.log({stat_unified: unifiedDetailsData});
      // setUnifiedDetailsData(unifiedDetailsData);
      // dispatch(triggerCustomerFeedbackReviewsUnifiedDetails(dataObj));
    } else if(conversation?.provider?.includes('video')) {
      // console.log({conversation, stat: data?.InboxStatistics_VideoTestimonial});
      unifiedDetailsData = InboxStatistics_VideoTestimonial?.find((item: any) => item?.RecieverEmailId === conversation?.reviewerEmail);
      // console.log({stat_unified: unifiedDetailsData});
      // dispatch(triggerVideoTestimonialReviewsUnifiedDetails(dataObj));
    }
  }
  useEffect(() => {        
      // console.log({dataObj_con: unifiedData, data});
      if(conversation && unifiedDetailsData) {
        dispatch(setConversationDetailsData({conversation, unifiedDetailsData}))
      }
  }, [dispatch, conversationId, query_string]);

  const {provider} = conversation || conversationDetailsData?.conversation || {};
  
  // console.log({my_conversation_details: conversation || conversationDetailsData})
  return (
    <React.Fragment>
      {
        type === "bot-messages"
        ? <BotConversationDetails workspaceSettings={{...workspaceSettings, workspaceName: workSpaceName}} getImage={getImage} statDetails={statDetails || {}}/>
        : provider?.includes('google')
        ? <GoogleDetails workspaceSettings={{...workspaceSettings, workspaceName: workSpaceName}} getImage={getImage} conversation={conversation || conversationDetailsData?.conversation} unifiedDetailsData={unifiedDetailsData || conversationDetailsData?.unifiedDetailsData || {}}/>
        : provider?.includes('facebook')
        ? <FacebookDetails workspaceSettings={{...workspaceSettings, workspaceName: workSpaceName}} getImage={getImage} conversation={conversation || conversationDetailsData?.conversation} unifiedDetailsData={unifiedDetailsData || conversationDetailsData?.unifiedDetailsData || {}}/>
        : provider?.includes('twitter')
        ? <TwitterDetails workspaceSettings={{...workspaceSettings, workspaceName: workSpaceName}} getImage={getImage} conversation={conversation || conversationDetailsData?.conversation} unifiedDetailsData={unifiedDetailsData || conversationDetailsData?.unifiedDetailsData || {}}/>
        : provider?.includes('customer')
        ? <FeedbackDetails workspaceSettings={{...workspaceSettings, workspaceName: workSpaceName}} getImage={getImage} conversation={conversation || conversationDetailsData?.conversation} unifiedDetailsData={unifiedDetailsData || conversationDetailsData?.unifiedDetailsData || {}}/>
        : provider?.includes('video')
        ? <VideoDetails workspaceSettings={{...workspaceSettings, workspaceName: workSpaceName}} getImage={getImage} conversation={conversation || conversationDetailsData?.conversation} unifiedDetailsData={unifiedDetailsData || conversationDetailsData?.unifiedDetailsData || {}}/>
        : null
      }
    </React.Fragment>
  );
}

export default ConversationDetails;

{/* <>
  Mobile
  <div className="w-100 h-100 conversation-details-mobile">
    <div className="inbox-wall w-100">
      {conversation && reviewer && conversation ? (
        <div className="right-feed-card rounded">
          <div className="d-flex align-items-start p-2">
            <img
              className="user-avatar"
              src={
                reviewerPhoteUrl || mentionedbyUserImageUrl || user_avatar
              }
              alt="user_avatar"
            />
            <div className="user-name-provider-container">
              <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                {provider === "twitter" || averageRating ? (
                  <div>
                    <span className="user_name">
                      {reviewerName ||
                        Reviewer ||
                        mentionedbyUserName ||
                        recieverName || 
                        "Client"}
                    </span>
                    <span className="rating-social-email">
                      <div>
                        {"averageRating" in reviewer ? (
                          <div>
                            {Array.from(
                              Array(
                                Math.floor(parseFloat(averageRating))
                              ).keys()
                            ).map((item: any, index: number) => {
                              return (
                                <img
                                  className="start"
                                  key={index}
                                  src={start}
                                  alt="star"
                                />
                              );
                            })}
                            {Array.from(
                              Array(
                                5 - Math.floor(parseFloat(averageRating))
                              ).keys()
                            ).map((item: any, index: number) => {
                              return (
                                <img
                                  className="star"
                                  key={index}
                                  src={star_half}
                                  alt="star"
                                />
                              );
                            })}
                            <span className="ms-2">
                              {Number(averageRating)}/5
                            </span>
                          </div>
                        ) : provider === "twitter" ? (
                          <span className="">{"@twitter_link"}</span>
                        ) : null}
                      </div>
                    </span>
                  </div>
                ) : (
                  <span className="user_name mt-2">
                    {reviewerName ||
                      Reviewer ||
                      mentionedbyUserName ||
                      // recieverName ||
                      "User name"}
                  </span>
                )}
              </div>
              <img
                className="social-logo"
                src={getImage(provider)}
                alt={provider}
              />
            </div>
          </div>
          <div className="footer w-100">
            <div className="d-flex align-items-start justify-content-between">
              <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                <span className="value">
                  {daysFromFirstReview || Reviews || reviews || Days || 0}
                </span>
                <span className="title">
                  {"daysFromFirstReview" in reviewer
                    ? "Days"
                    : "Reviews" in reviewer || "reviews" in reviewer
                    ? "Reviews"
                    : "Days" in reviewer
                    ? "Days"
                    : "Title-1"}
                </span>
              </div>
              <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                <span className="value">
                  {totalReviews ||
                    reviewerReviewCount ||
                    Comments || comments ||
                    Mentions || mentions ||
                    0}
                </span>
                <span className="title">
                  {"reviewerReviewCount" in reviewer ||
                  "totalReviews" in reviewer
                    ? "Reviews"
                    : "Comments" in reviewer || "comments" in reviewer
                    ? "Comments"
                    : "Mentions" in reviewer || "mentions" in reviewer
                    ? "Mentions"
                    : "Title-2"}
                </span>
              </div>
              <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                <span className="value">
                  {averageRating || Today || Mentions || mentions || 0}
                </span>
                <span className="title">
                  {"averageRating" in reviewer
                    ? "Avg Rating"
                    : "Today" in reviewer
                    ? "Today"
                    : "Mentions" in reviewer || "mentions" in reviewer
                    ? "Mentions"
                    : "Title-3"}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="right-feed-card rounded d-flex align-items-center justify-content-center">
          <p className="nothing-show">Nothing to show here</p>
        </div>
      )}
    </div>
    <div className="inbox-wall w-100">
      {conversation && location || page ? (
        <div className="right-feed-card rounded">
          <div className="d-flex align-items-start p-2">
            <img
              className="user-avatar"
              src={workspaceSettings?.logo || user_avatar_2}
              alt="user_avatar"
            />
            <div className="user-name-provider-container">
              <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                <span className="user_name">
                  {workspaceSettings?.workspaceName || "Tezla Inc"}
                </span>
                <span className="rating-social-email">
                  {(provider === "twitter" ||
                    locationAverageReviewRating ||
                    ratingStars ||
                    locationAverageRating) && (
                    <div>
                      {locationAverageReviewRating ||
                      ratingStars ||
                      locationAverageRating ? (
                        <div>
                          {Array.from(
                            Array(
                              Math.floor(
                                parseFloat(
                                  locationAverageReviewRating ||
                                    ratingStars ||
                                    locationAverageRating
                                )
                              )
                            ).keys()
                          ).map((item: any, index: number) => {
                            return (
                              <img
                                className="start"
                                key={index}
                                src={start}
                                alt="star"
                              />
                            );
                          })}
                          {Array.from(
                            Array(
                              5 -
                                Math.floor(
                                  parseFloat(
                                    locationAverageReviewRating ||
                                      ratingStars ||
                                      locationAverageRating
                                  )
                                )
                            ).keys()
                          ).map((item: any, index: number) => {
                            return (
                              <img
                                className="star"
                                key={index}
                                src={star_half}
                                alt="star"
                              />
                            );
                          })}
                          <span className="ms-2">
                            {Number(
                              locationAverageReviewRating ||
                                ratingStars ||
                                locationAverageRating
                            )}
                            /5
                          </span>
                        </div>
                      ) : provider === "twitter" ? (
                        <span>{"@twitter_link"}</span>
                      ) : null}
                    </div>
                  )}
                </span>
              </div>
              <img
                className="social-logo"
                src={getImage(provider)}
                alt={provider}
              />
            </div>
          </div>
          <div className="footer w-100 mt-1">
            <div className="d-flex align-items-start justify-content-between">
              <div className="d-flex flex-column align-items-center justify-content-center w-100">
                <span className="value">
                  {Number(
                    locationReviewCount || reviewCount || requestCount
                  ) ||
                    totalMentionCount ||
                    0}
                </span>
                <span className="title">
                  {"locationReviewCount" in (location || page) ||
                  "reviewCount" in (location || page)
                    ? "Reviews"
                    : "requestCount" in (location || page)
                    ? "Request"
                    : "totalMentionCount" in (location || page)
                    ? "Mentions"
                    : "Title-1"}
                </span>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center w-100">
                <span className="value">
                  {Number(todaysCount || responseCount) ||
                    todaysTotalMentionCount ||
                    0}
                </span>
                <span className="title">
                  {"todaysCount" in (location || page)
                    ? "Today"
                    : "responseCount" in (location || page)
                    ? "Response"
                    : "averageResponse" in (location || page)
                    ? "Resp. Rate"
                    : "todaysTotalMentionCount" in (location || page)
                    ? "Today"
                    : "Title-2"}
                </span>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center w-100">
                <span className="value">
                  {Number(
                    locationAverageReviewRating ||
                    followerCount ||
                    followersCount ||
                    Followers ||
                    averageResponse
                  ) || 0}
                </span>
                <span className="title">
                  {"locationAverageReviewRating" in (location || page)
                    ? "Avg Rating"
                    : ("followerCount" in (location || page) ||
                      "followersCount" in (location || page) ||
                      "Followers" in (location || page))
                    ? "Followers"
                    : "averageResponse" in (location || page)
                    ? "Resp. Rate"
                    : "Title-3"}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="right-feed-card rounded my-3 d-flex align-items-center justify-content-center">
          <p className="nothing-show">Nothing to show here</p>
        </div>
      )}
    </div>
    <div className="properties-wall w-100">
      {
        // "properties" in unifiedDetailsData || provider === "videoTestimonial" || provider === "twitter" ? (
          conversation && "details" in unifiedDetailsData ? (
          <div className="right-feed-card rounded p-2">
            <h4 className="text-start header-title">Properties</h4>
            <div className="content d-flex align-items-start justify-content-between">
              <div className="type-to-lock d-flex flex-column">
                <span>Type:</span>
                <span>To:</span>
                <span className="d-block">Lock:</span>
              </div>
              <div className="type-to-lock-value">
                <span>
                  {provider === "google"
                    ? "Google Review"
                    : provider?.includes("facebook")
                    ? `Facebook ${tag === "comments" ? "Comment" :  tag === "mentions" ? "Mention" : "Review"}`
                    : provider === "customerFeedBack"
                    ? "Customer Feedback"
                    : provider === "videoTestimonial"
                    ? "Video Testimonial"
                    : provider === "twitter"
                    ? "Twitter Mention"
                    : "Type Title"}
                </span>
                <span>
                  {workspaceSettings?.workspaceName || "Workspace Name"}
                </span>
                {
                  <span className="lock-unlock d-flex align-items-center justify-content-center pt-1 mt-1">
                    {provider === "customerFeedBack" ||
                    provider === "videoTestimonial" ? (
                      <span>N/A</span>
                    ) : properties?.replied ? (
                      <span>
                        Locked <img src={lock_icon} alt="locked" />
                      </span>
                    ) : properties?.lock_title ? (
                      <span>{properties?.lock_title}</span>
                    ) : (
                      <span>
                        Unlocked <img src={unlock_icon} alt="unlocked" />
                      </span>
                    )}
                  </span>
                }
              </div>
              <div className="type-to-lock">
                {provider === "customerFeedBack" ||
                provider ===
                  "videoTestimonial" ? null : properties?.hasOwnProperty(
                    "replied"
                  ) || provider === "twitter" ? (
                  properties?.replied ? (
                    <span className="replied">Replied</span>
                  ) : (
                    <span className="not-replied">Not Replied</span>
                  )
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div className="right-feed-card rounded my-3 d-flex align-items-center justify-content-center">
            <p className="nothing-show">Nothing to show </p>
          </div>
        )
      }
    </div>
    <div className="details-wall w-100">
      {conversation && details ? (
        <div className="right-feed-card rounded p-2">
          <h4 className="text-start header-title">Details</h4>
          <div className="content d-flex align-items-start" style={{gap: '5%'}}>
            <div className="req-res">
              <span>
                {details.hasOwnProperty("MentionedOn")
                  ? "Mentioned"
                  : details.hasOwnProperty("CreatedOn")
                  ? "Created"
                  : details.hasOwnProperty("commentedOn")
                  ? "Commented"
                  : details.hasOwnProperty("RequestedDate")
                  ? "Requested"
                  : details.hasOwnProperty("reviewedOn")
                  ? "Reviewed"
                  : null}
                :
              </span>
              <span>
                {details.hasOwnProperty("SubmittedOn")
                  ? "Submitted"
                  : details.hasOwnProperty("repliedOn")
                  ? "Replied"
                  : null}
                :
              </span>
              <span>Res. Time:</span>
              <span>
                {details.hasOwnProperty("requestorEmail")
                  ? "Email"
                  : details.hasOwnProperty("repliedBy")
                  ? "Replied By"
                  : null}
                :
              </span>
            </div>
            <div className="req-res-value">
              <span>
                {RequestedDate
                  ? moment(RequestedDate).format("ll") +
                    " " +
                    moment(RequestedDate).format("LT")
                  : // new Date(details?.requested_time).toDateString() + " " +
                  // new Date(details?.requested_time).toLocaleTimeString()
                  reviewedOn
                  ? moment(reviewedOn).format("ll") +
                    " " +
                    moment(reviewedOn).format("LT")
                  : // new Date(details?.reviewed_time).toDateString() + " " +
                  // new Date(details?.reviewed_time).toLocaleTimeString()
                  MentionedOn
                  ? moment(MentionedOn).format("ll") +
                    " " +
                    moment(MentionedOn).format("LT")
                  : // new Date(MentionedOn).toDateString() + " " +
                    // new Date(MentionedOn).toLocaleTimeString()
                    " --- "}
              </span>
              <span>
                {SubmittedOn
                  ? moment(SubmittedOn).format("ll") +
                    " " +
                    moment(SubmittedOn).format("LT")
                  : // new Date(details?.submitted_time).toDateString() + " " +
                  // new Date(details?.submitted_time).toLocaleTimeString()
                  repliedOn
                  ? moment(repliedOn).format("ll") +
                    " " +
                    moment(repliedOn).format("LT")
                  : // new Date(replied_time).toDateString() + " " +
                    // new Date(replied_time).toLocaleTimeString()
                    " --- "}
              </span>
              <span className="d-block">
                {ResponseTime
                  ? ResponseTime
                  : // moment(ResponseTime).format('ll') + " " + moment(ResponseTime).format('LT')
                    // moment(details?.ResponseTime).calendar()
                    // new Date(details?.response_time).toDateString() + " " +
                    // new Date(details?.response_time).toLocaleTimeString()
                    " --- "}
              </span>
              {requestorEmail ? (
                <span>{requestorEmail}</span>
              ) : repliedBy ? (
                <span className="replier">
                  <img src={user_avatar} alt="avatar" />
                  {repliedBy ? repliedBy : "---"}
                </span>
              ) : (
                <span>{"---"}</span>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="right-feed-card rounded my-3 d-flex align-items-center justify-content-center">
          <p className="nothing-show">Nothing to show here</p>
        </div>
      )}
    </div>
  </div>
  Laptop
  <div className="w-100 h-100 conversation-details-lg">
    <div className="inbox-wall w-100">
      {conversation && reviewer ? (
        <div className="right-feed-card rounded">
          <div className="d-flex align-items-start p-2">
            <img
              className="user-avatar"
              src={
                reviewerPhoteUrl || mentionedbyUserImageUrl || user_avatar
              }
              alt="user_avatar"
            />
            <div className="user-name-provider-container">
              <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                {provider === "twitter" || averageRating ? (
                  <div>
                    <span className="user_name">
                      {reviewerName ||
                        Reviewer ||
                        mentionedbyUserName ||
                        recieverName ||
                        "Client"}
                    </span>
                    <span className="rating-social-email">
                      <div>
                        {"averageRating" in reviewer ? (
                          <div>
                            {Array.from(
                              Array(
                                Math.floor(parseFloat(averageRating))
                              ).keys()
                            ).map((item: any, index: number) => {
                              return (
                                <img
                                  className="start"
                                  key={index}
                                  src={start}
                                  alt="star"
                                />
                              );
                            })}
                            {Array.from(
                              Array(
                                5 - Math.floor(parseFloat(averageRating))
                              ).keys()
                            ).map((item: any, index: number) => {
                              return (
                                <img
                                  className="star"
                                  key={index}
                                  src={star_half}
                                  alt="star"
                                />
                              );
                            })}
                            <span className="ms-2">
                              {Number(averageRating)}/5
                            </span>
                          </div>
                        ) : provider === "twitter" ? (
                          <span className="">{"@twitter_link"}</span>
                        ) : null}
                      </div>
                    </span>
                  </div>
                ) : (
                  <span className="user_name mt-2">
                    {reviewerName ||
                      Reviewer ||
                      mentionedbyUserName ||
                      // recieverName ||
                      "User name"}
                  </span>
                )}
              </div>
              <img
                className="social-logo"
                src={getImage(provider)}
                alt={provider}
              />
            </div>
          </div>
          <div className="footer w-100">
            <div className="d-flex align-items-start justify-content-between">
              <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                <span className="value">
                  {daysFromFirstReview || Reviews || reviews || Days || days || 0}
                </span>
                <span className="title">
                  {"daysFromFirstReview" in reviewer
                    ? "Days"
                    : "Reviews" in reviewer || "reviews" in reviewer
                    ? "Reviews"
                    : "Days" in reviewer || "days" in reviewer
                    ? "Days"
                    : "Title-1"}
                </span>
              </div>
              <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                <span className="value">
                  {totalReviews ||
                    reviewerReviewCount ||
                    Comments || comments ||
                    Mentions || mentions || 0}
                </span>
                <span className="title">
                  {"reviewerReviewCount" in reviewer ||
                  "totalReviews" in reviewer
                    ? "Reviews"
                    : "Comments" in reviewer || "comments" in reviewer
                    ? "Comments"
                    : "Mentions" in reviewer || "mentions" in reviewer
                    ? "Mentions"
                    : "Title-2"}
                </span>
              </div>
              <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                <span className="value">
                  {averageRating || Today || Mentions || mentions || 0}
                </span>
                <span className="title">
                  {"averageRating" in reviewer
                    ? "Avg Rating"
                    : "Today" in reviewer
                    ? "Today"
                    : "Mentions" in reviewer || "mentions" in reviewer
                    ? "Mentions"
                    : "Title-3"}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="right-feed-card rounded mt-0 mb-3 d-flex align-items-center justify-content-center">
          <p className="nothing-show">Nothing to show here</p>
        </div>
      )}
    </div>
    <div className="inbox-wall w-100">
      {conversation && location || page ? (
        <div className="right-feed-card rounded">
          <div className="d-flex align-items-start p-2">
            <img
              className="user-avatar"
              src={workspaceSettings?.logo || user_avatar_2}
              alt="user_avatar"
            />
            <div className="user-name-provider-container">
              <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                <span className="user_name">
                  {workspaceSettings?.workspaceName || "Tezla Inc"}
                </span>
                <span className="rating-social-email">
                  {(provider === "twitter" ||
                    locationAverageReviewRating ||
                    ratingStars ||
                    locationAverageRating) && (
                    <div>
                      {locationAverageReviewRating ||
                      ratingStars ||
                      locationAverageRating ? (
                        <div>
                          {Array.from(
                            Array(
                              Math.floor(
                                parseFloat(
                                  locationAverageReviewRating ||
                                    ratingStars ||
                                    locationAverageRating
                                )
                              )
                            ).keys()
                          ).map((item: any, index: number) => {
                            return (
                              <img
                                className="start"
                                key={index}
                                src={start}
                                alt="star"
                              />
                            );
                          })}
                          {Array.from(
                            Array(
                              5 -
                                Math.floor(
                                  parseFloat(
                                    locationAverageReviewRating ||
                                      ratingStars ||
                                      locationAverageRating
                                  )
                                )
                            ).keys()
                          ).map((item: any, index: number) => {
                            return (
                              <img
                                className="star"
                                key={index}
                                src={star_half}
                                alt="star"
                              />
                            );
                          })}
                          <span className="ms-2">
                            {Number(
                              locationAverageReviewRating ||
                                ratingStars ||
                                locationAverageRating
                            )}
                            /5
                          </span>
                        </div>
                      ) : provider === "twitter" ? (
                        <span>{"@twitter_link"}</span>
                      ) : null}
                    </div>
                  )}
                </span>
              </div>
              <img
                className="social-logo"
                src={getImage(provider)}
                alt={provider}
              />
            </div>
          </div>
          <div className="footer w-100 mt-1">
            <div className="d-flex align-items-start justify-content-between">
              <div className="d-flex flex-column align-items-center justify-content-center w-100">
                <span className="value">
                  {Number(
                    locationReviewCount || reviewCount || requestCount
                  ) ||
                    totalMentionCount ||
                    0}
                </span>
                <span className="title">
                  {"locationReviewCount" in (location || page) ||
                  "reviewCount" in (location || page)
                    ? "Reviews"
                    : "requestCount" in (location || page)
                    ? "Request"
                    : "totalMentionCount" in (location || page)
                    ? "Mentions"
                    : "Title-1"}
                </span>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center w-100">
                <span className="value">
                  {Number(todaysCount || responseCount) ||
                    todaysTotalMentionCount ||
                    0}
                </span>
                <span className="title">
                  {"todaysCount" in (location || page)
                    ? "Today"
                    : "responseCount" in (location || page)
                    ? "Response"
                    : "averageResponse" in (location || page)
                    ? "Resp. Rate"
                    : "todaysTotalMentionCount" in (location || page)
                    ? "Today"
                    : "Title-2"}
                </span>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center w-100">
                <span className="value">
                  {Number(
                    locationAverageReviewRating ||
                    followerCount ||
                    followersCount ||
                    Followers ||
                    averageResponse
                  ) || 0}
                </span>
                <span className="title">
                  {"locationAverageReviewRating" in (location || page)
                    ? "Avg Rating"
                    : ("followerCount" in (location || page) ||
                      "followersCount" in (location || page) ||
                      "Followers" in (location || page)) ? "Followers"
                    : "averageResponse" in (location || page) ? "Resp. Rate"
                    : "Title-3"}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="right-feed-card rounded my-3 d-flex align-items-center justify-content-center">
          <p className="nothing-show">Nothing to show here</p>
        </div>
      )}
    </div>
    <div className="properties-wall w-100">
      {
        // "properties" in unifiedDetailsData || provider === "videoTestimonial" || provider === "twitter" ? (
          conversation && "details" in unifiedDetailsData ? (
          <div className="right-feed-card rounded p-2">
            <h4 className="text-start header-title">Properties</h4>
            <div className="content d-flex align-items-start justify-content-between">
              <div className="type-to-lock d-flex flex-column">
                <span>Type:</span>
                <span>To:</span>
                <span className="d-block">Lock:</span>
              </div>
              <div className="type-to-lock-value">
                <span>
                  {provider === "google"
                    ? "Google Review"
                    :  provider?.includes("facebook")
                    ? `Facebook ${tag === "comments" ? "Comment" :  tag === "mentions" ? "Mention" : "Review"}`
                    : provider === "customerFeedBack"
                    ? "Customer Feedback"
                    : provider === "videoTestimonial"
                    ? "Video Testimonial"
                    : provider === "twitter"
                    ? "Twitter Mention"
                    : "Type Title"}
                </span>
                <span>
                  {workspaceSettings?.workspaceName || "Workspace Name"}
                </span>
                {
                  <span className="lock-unlock d-flex align-items-center justify-content-center pt-1 mt-1">
                    {provider === "customerFeedBack" ||
                    provider === "videoTestimonial" ? (
                      <span>N/A</span>
                    ) : properties?.replied ? (
                      <span>
                        Locked <img src={lock_icon} alt="locked" />
                      </span>
                    ) : properties?.lock_title ? (
                      <span>{properties?.lock_title}</span>
                    ) : (
                      <span>
                        Unlocked <img src={unlock_icon} alt="unlocked" />
                      </span>
                    )}
                  </span>
                }
              </div>
              <div className="type-to-lock">
                {provider === "customerFeedBack" ||
                provider ===
                  "videoTestimonial" ? null : properties?.hasOwnProperty(
                    "replied"
                  ) || provider === "twitter" ? (
                  properties?.replied ? (
                    <span className="replied">Replied</span>
                  ) : (
                    <span className="not-replied">Not Replied</span>
                  )
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div className="right-feed-card rounded my-3 d-flex align-items-center justify-content-center">
            <p className="nothing-show">Nothing to show </p>
          </div>
        )
      }
    </div>
    <div className="details-wall w-100">
      {conversation && details ? (
        <div className="right-feed-card rounded p-2">
          <h4 className="text-start header-title">Details</h4>
          <div className="content d-flex align-items-start" style={{gap: "5%"}}>
            <div className="req-res">
              <span>
                {details.hasOwnProperty("MentionedOn")
                  ? "Mentioned"
                  : details.hasOwnProperty("CreatedOn")
                  ? "Created"
                  : details.hasOwnProperty("commentedOn")
                  ? "Commented"
                  : details.hasOwnProperty("RequestedDate")
                  ? "Requested"
                  : details.hasOwnProperty("reviewedOn")
                  ? "Reviewed"
                  : null}
                :
              </span>
              <span>
                {details.hasOwnProperty("SubmittedOn")
                  ? "Submitted"
                  : details.hasOwnProperty("repliedOn")
                  ? "Replied"
                  : null}
                :
              </span>
              <span>Res. Time:</span>
              <span>
                {details.hasOwnProperty("requestorEmail")
                  ? "Email"
                  : details.hasOwnProperty("repliedBy")
                  ? "Replied By"
                  : null}
                :
              </span>
            </div>
            <div className="req-res-value">
              <span>
                {RequestedDate
                  ? moment(RequestedDate).format("ll") +
                    " " +
                    moment(RequestedDate).format("LT")
                  : // new Date(details?.requested_time).toDateString() + " " +
                  // new Date(details?.requested_time).toLocaleTimeString()
                  reviewedOn
                  ? moment(reviewedOn).format("ll") +
                    " " +
                    moment(reviewedOn).format("LT")
                  : // new Date(details?.reviewed_time).toDateString() + " " +
                  // new Date(details?.reviewed_time).toLocaleTimeString()
                  MentionedOn
                  ? moment(MentionedOn).format("ll") +
                    " " +
                    moment(MentionedOn).format("LT")
                  : // new Date(MentionedOn).toDateString() + " " +
                    // new Date(MentionedOn).toLocaleTimeString()
                    " --- "}
              </span>
              <span>
                {SubmittedOn
                  ? moment(SubmittedOn).format("ll") +
                    " " +
                    moment(SubmittedOn).format("LT")
                  : // new Date(details?.submitted_time).toDateString() + " " +
                  // new Date(details?.submitted_time).toLocaleTimeString()
                  repliedOn
                  ? moment(repliedOn).format("ll") +
                    " " +
                    moment(repliedOn).format("LT")
                  : // new Date(replied_time).toDateString() + " " +
                    // new Date(replied_time).toLocaleTimeString()
                    " --- "}
              </span>
              <span className="d-block">
                {ResponseTime
                  ? ResponseTime
                  : // moment(ResponseTime).format('ll') + " " + moment(ResponseTime).format('LT')
                    // moment(details?.ResponseTime).calendar()
                    // new Date(details?.response_time).toDateString() + " " +
                    // new Date(details?.response_time).toLocaleTimeString()
                    " --- "}
              </span>
              {requestorEmail ? (
                <span>{requestorEmail}</span>
              ) : repliedBy ? (
                <span className="replier">
                  <img src={user_avatar} alt="avatar" />
                  {repliedBy ? repliedBy : "---"}
                </span>
              ) : (
                <span>{"---"}</span>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="right-feed-card rounded my-3 d-flex align-items-center justify-content-center">
          <p className="nothing-show">Nothing to show here</p>
        </div>
      )}
    </div>
  </div>
</> */}