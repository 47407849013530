import './Dialog.scss';
import { getImage } from '../../unified/image_service';
interface DialogProps {
    open?: any;
    wrapper_styles?: any;
    styles?: any;
    parentCallback?: any;
    children?: React.ReactNode;
    crossIcon?: boolean;
    closeIcon?: any;
    header?: any;
    title?: string;
    footer?: boolean;
    error_dialog_content?: any;
    warning_dialog_content?: any;
    success_dialog_content?: any;
    classes?: string;
    right_button_text?: string;
    left_button_text?: string;
    left_btn_hover?: string;
    right_btn_hover?: string;
    hover?: boolean;
    onCancel?: any;
}

const Dialog: React.FC<DialogProps> = ({
    open, parentCallback, onCancel, children, crossIcon, 
    // close custom icon
    closeIcon,
    header, footer, title, warning_dialog_content, right_button_text, left_button_text,
    error_dialog_content, success_dialog_content, classes, hover, left_btn_hover, right_btn_hover,
    wrapper_styles, styles,
}) => {
    return (
        <>
            {
                open
                    ? <div className={`dialog-overlay ${warning_dialog_content && "bg-transparen"}`}
                    >
                        <div className={`dialog-container ${classes}`} style={{...styles}}>
                            {
                                (crossIcon) && <span className="close" onClick={() => parentCallback(false)}>
                                    <img src={getImage("form-close-icon")}  alt="" />
                                </span>
                            }
                            
                            {
                                (closeIcon)
                                &&  <img className="close-icon"
                                        onClick={() => parentCallback(false)}
                                        src={closeIcon} alt=""
                                    />
                            }

                            {
                                (title) && <div className="dialog-header">
                                    <h3 className='text-center'>{title}</h3>
                                </div>
                            }
                            
                            {
                                (header) && <div className="dialog-header">
                                    <h3 className='text-center'>{header}</h3>
                                </div>
                            }
                            
                            {children && <div
                                className="dialog-wrapper"
                                style={{
                                    paddingBottom: !footer ? "0" : "5%",
                                    ...wrapper_styles
                                }}
                            >
                                {children}
                            </div>}

                            {
                                warning_dialog_content 
                                ? <div className="warning-dialog-content">
                                    <div className='header-content'>
                                        <img src={getImage("warning-icon")} alt="" />
                                        <p>Warning</p>
                                    </div>
                                    <p className='content-body'>{warning_dialog_content}</p>
                                </div>
                                : null
                            }
                            
                            {
                                (footer) && <div className="dialog-footer">
                                    <button type="button"
                                        className={`btn ${hover && "left-btn-hover"} ${right_btn_hover}`} 
                                        onClick={() => {
                                            !onCancel && parentCallback(false);
                                            onCancel && onCancel();
                                        }}
                                    >
                                        {left_button_text || "No, Cancel"}
                                    </button>
                                    <button type="button"
                                        className={`btn ${hover && "right-btn-hover"} ${left_btn_hover}`} 
                                        onClick={() => parentCallback(open)} 
                                    >
                                        {right_button_text || "Yes, Create"}
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                : null
            }
        </>
    )

};

export default Dialog;