import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSuccessMessage } from '../../../../unified/reducer';
import { getGenerateApiImageByName, getImage } from '../../../../unified/image_service';

interface ConversationHeaderProps {
    conversation: any;
    workspaceSettings?: any;
    workSpaceName?: any;
    handleReplaceConversation: (data: any) => void;
    prev_icon?: any;
    next_icon?: any;
    refresh_icon?: any;
    user_avatar_2?: any;
    user_avatar?: any;
    header_title?: string;
}

const ConversationHeader: React.FC<ConversationHeaderProps> = ({header_title, user_avatar, user_avatar_2, conversation, workspaceSettings, workSpaceName, handleReplaceConversation, prev_icon, next_icon, refresh_icon }: any) => {
    const dispatch = useDispatch();
    const { reviewerDisplayName, reviewerPhoteUrl, mentionedbyUserName, mentionedbyUserImageUrl, requestedDate,  provider } = conversation || {};
    return (
        <React.Fragment>
            <div className="conversation-header">
                <div className="person-container">
                    <div className="person-1">
                    {
                        reviewerPhoteUrl || mentionedbyUserImageUrl
                        ? <img
                            className="user-avatar"
                            src={reviewerPhoteUrl || mentionedbyUserImageUrl || user_avatar}
                            alt="avatar"
                        />
                        : 
                        <img
                            className="user-avatar"
                            src={getGenerateApiImageByName((reviewerDisplayName || mentionedbyUserName || (provider?.includes("facebook") ? "FB" : "USER NAME"))?.substr(0, 2).toUpperCase())}
                            alt="avatar"
                        />
                        // <div className='user-name-avatar'>
                        //     {
                        //     (reviewerDisplayName || mentionedbyUserName || (provider?.includes("facebook") ? "FB" : "USER NAME"))?.split(" ")?.slice(0, 2)?.length === 2
                        //     ? (reviewerDisplayName || mentionedbyUserName || (provider?.includes("facebook") ? "FB" : "USER NAME"))?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                        //     : (reviewerDisplayName || mentionedbyUserName || (provider?.includes("facebook") ? "FB" : "USER NAME"))?.substr(0, 2).toUpperCase()
                        //     }
                        // </div>
                    }
                    <div className="description">
                        {reviewerDisplayName || mentionedbyUserName || (provider?.includes("facebook") ? "FB" : "USER NAME")}
                        <span className="review">
                            {header_title}
                        </span>
                        {workspaceSettings?.workspaceName || workSpaceName}
                    </div>
                    </div>
                    <div className="person-2">
                    {/* <img
                        src={workspaceSettings?.logo}
                        alt="user-2"
                    /> */}
                    {
                        workspaceSettings?.logo
                        ? <img
                            // className="user-avatar"
                            src={workspaceSettings?.logo || user_avatar_2}
                            alt="user_avatar"
                        />
                        : 
                            <img
                                className="user-avatar"
                                src={getGenerateApiImageByName(workSpaceName)}
                                alt="avatar"
                            />
                        // <div className='user-name-avatar'>
                        //     {
                        //     workSpaceName?.split(" ")?.slice(0, 2)?.length === 2
                        //     ? workSpaceName?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                        //     : workSpaceName?.substr(0, 2).toUpperCase()
                        //     }
                        // </div>
                    }
                    <div className="user-2">
                        {workspaceSettings?.workspaceName || workSpaceName || "WS Name"}
                    </div>
                    </div>
                </div>
                <div className="conversation-features-media">
                    <div className="features">
                    <img
                        onClick={() =>
                        handleReplaceConversation("prev", conversation)
                        }
                        src={prev_icon}
                        alt="prev"
                        title='Previous'
                    />
                    <img
                        onClick={() =>
                        handleReplaceConversation("next", conversation)
                        }
                        src={next_icon}
                        alt="next"
                        title='Next'
                    />
                    <img
                        onClick={() => dispatch(setSuccessMessage("Refreshed"))}
                        src={refresh_icon}
                        alt="refresh"
                        title='Refresh'
                    />
                    </div>
                    <div className="media">
                        <img src={getImage(provider)} alt="google" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ConversationHeader;