import React, { useEffect } from 'react';
import user_avatar from '../../../../assets/icons/user-avatar.svg';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { setErrorMessage, toggleClosedWhatsApp } from '../../../../unified/reducer';
import { getGenerateApiImageByName } from '../../../../unified/image_service';

export default function WhatsAppMessageCard(props: any) {
  const { getImage, inbox, handleSeeOtherWhatsappConversation, type, activePage, last, isFromModalBox  } = props;
  const dispatch = useDispatch();
  const {
    isClosedWhatsAppInboxThread,
    // isReplyingOn,
    // There will be a ticketNo which will match and lock-icon will come
    // if lock-icon will will come the receiver will not reply text 
    whats_app_sender_state
  } = useSelector((state: RootState) => state?.unified);
  const query_string = window.location.search;
  const status_tag = query_string?.includes("open") ? "open"
  : query_string?.includes("in-progress") ? "inProgress"
  : query_string?.includes("closed") ? "closed" 
  : query_string?.includes("spam") ? "spam" 
  : "all";
  const path = query_string.replace('?', '')?.replaceAll('&', "=");
  const path_array = path.split("=");
  const [isOpenedDropdown, setIsOpenedDropdown] = React.useState(false);
  const [threads, setThreads] = React.useState<any[]>([]);
  // const [active_inbox_user, setActiveInboxUser] = React.useState<any>(null);
  // const [active_thread, setActiveInboxThread] = React.useState<any>(null);
  useEffect(() => {
    // console.log({inbox, threads});
    
    if (!threads?.length) {
      if (status_tag === "all") { 
        setThreads(inbox?.userWiseMessageDetailsSubs?.length ? inbox?.userWiseMessageDetailsSubs : []);
      } else {
        const filtered_threads = inbox?.userWiseMessageDetailsSubs?.filter((thread: any) => thread?.chatStatus === status_tag);
        // console.log({filtered_threads, status_tag});
        
        setThreads(filtered_threads);
      }
    }
  }, [isOpenedDropdown]);
  
  useEffect(() => {
    if (status_tag === "all") {
      setThreads(inbox?.userWiseMessageDetailsSubs?.length ? inbox?.userWiseMessageDetailsSubs : []);
    } else {
      const filtered_threads = inbox?.userWiseMessageDetailsSubs?.filter((thread: any) => thread?.chatStatus === status_tag);
      // console.log({filtered_threads, status_tag});
      setThreads(filtered_threads);
    }
    
  }, [query_string]);
  
  useEffect(() => {
    // console.log({isClosedWhatsAppInboxThread});
    
    if (isClosedWhatsAppInboxThread) {
      const filtered_threads = inbox?.userWiseMessageDetailsSubs?.map((thread: any) => {
        return {
          ...thread,
          chatStatus: thread?.ticketNo === isClosedWhatsAppInboxThread ? "closed" : thread?.chatStatus,
        }
      
      });
      setThreads(filtered_threads);
      dispatch(toggleClosedWhatsApp(false));
    }
    
  }, [isClosedWhatsAppInboxThread]);

  useEffect(() => {
    if (threads?.length) {
      if (path_array?.length > 5) {
        const [_0, _1, _2, conversation_id, _4, user] = path_array;
        const active_user = user;
        if (active_user) {
          // setActiveInboxUser(active_user);
          const active_thread = threads?.find((thread: any) => thread?.ticketNo === conversation_id);
          // console.log({ active_thread, active_user, inbox});
          if (active_thread) {
            // setActiveInboxThread(active_thread);
            setIsOpenedDropdown(true);
          }
          
        }
      }
    }
  }, [threads?.length]);

  // useEffect(() => {
  //   console.log({ whats_app_sender_state, inbox });
  // }, [whats_app_sender_state]);
  // console.log({ threads, status_tag });
  const first_thread_by_tag: any = threads?.length ? threads[0] : {};
  
  return (
    <React.Fragment>
      {
        threads?.length
        ?
        <div className='feeds-card'>
          {/* <div className={`feed-card-hover feed-card-active`}> */}
          <div className={`feed-card-hover ${query_string?.includes(inbox?.userName) ? "feed-card-active" : ""}`}>
            <div className="feeds-card-header d-flex justify-content-between align-items-start"
              style={{cursor: 'pointer'}}
              onClick={() => {
                if (threads?.length) {
                  const seeing_conversation_object = {
                    userEmail: inbox?.userEmail,
                    ...threads[0],
                    userName: inbox?.userName,
                    threadUserName: threads[0]?.userName,
                  };
                  console.log({ seeing_conversation_object });
                  if (query_string?.includes(seeing_conversation_object?.ticketNo)) {
                    return;
                  }
                  handleSeeOtherWhatsappConversation(seeing_conversation_object);
                  // setActiveInboxUser(inbox?.userName);
                  // setActiveInboxThread(threads[0]);
                } else {
                  dispatch(setErrorMessage("No conversations available"));
                }
              }}
            >
              {
                inbox?.reviewerPhoteUrl || inbox?.imageUrl
                ? <img
                    className="user-avatar"
                    src={inbox?.reviewerPhoteUrl || inbox?.imageUrl || user_avatar}
                    alt="avatar"
                  />
                : 
                  <img
                    className="user-avatar"
                    src={getGenerateApiImageByName(inbox?.userName)}
                    alt="avatar"
                  />
              }
              <div
                className="feeds-card-header-content d-flex justify-content-between align-items-start"
                style={{ width: "100%" }}
              >
                <div>
                  <div className="name-rating d-flex m-0 align-items-center">
                    <h6 className="user-name">
                      {inbox?.userName?.substr(0, 13) || "Client Name"}
                    </h6>
                    
                  </div>
                  <p className="text-start date">
                    { 
                      first_thread_by_tag && first_thread_by_tag?.createdOn
                      ? moment(first_thread_by_tag?.createdOn).format("DD MMM YYYY, hh:mm:ss A")
                      : null
                    }
                  </p>
                  {
                    isFromModalBox
                    ? <p
                        className="description mb-2"
                        style={{color: (first_thread_by_tag && first_thread_by_tag?.color )|| "black"}}
                      >
                        {
                          first_thread_by_tag && first_thread_by_tag?.lastMessage
                          ? first_thread_by_tag?.lastMessage?.substring(0, 26) + "..."
                          : null
                        }
                        {/* Lorem Ipsum is simply dummy text of the printing and also typesetting industry. Lorem Ipsum has been into the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. */}
                      </p>
                    : <>
                      <p
                        className="text-start description mb-2 normal-description"
                        // onClick={() => {
                        //   if (activePage !== "conversations") {
                        //     dispatch(setBoxId(inbox?.SlNo));
                        //   }
                        // }}
                        style={{ cursor: 'pointer', color: (first_thread_by_tag && first_thread_by_tag?.color )|| "black"}}
                      >
                        {
                          first_thread_by_tag && first_thread_by_tag?.lastMessage
                          ? first_thread_by_tag?.lastMessage?.substring(0, 22) + ".."
                          : null
                        }
                        <span>Read More</span>
                      </p>
                      <p
                        className="text-start description mb-2 lg-description"
                        style={{ cursor: 'pointer', color: (first_thread_by_tag && first_thread_by_tag?.color )|| "black"}}
                        // onClick={() => {
                        //   if (activePage !== "conversations"){
                        //     dispatch(setBoxId(inbox?.SlNo));
                        //   }
                        // }}
                      >
                        {
                          first_thread_by_tag && first_thread_by_tag?.lastMessage
                          ? first_thread_by_tag?.lastMessage?.substring(0, 36) + ".."
                          : null
                        }
                        <span>Read More</span>
                      </p>
                    </>
                  }
                </div>

                <img
                  className='provider-icon'
                  src={getImage(type)}
                  alt="provider"
                />
              </div>
            </div>
            <div className="feeds-card-footer w-100" style={{padding: "1.38% 3.5%"}}>
              <div className="footer-content w-100">
                <div className="left-emoji">
                  <img
                    className='exclamation-icon'
                    src={getImage("bot_exclamation_icon")}
                    alt="exclamation"
                  />
                  <div className="left-body-emoji">
                    {
                      !isOpenedDropdown
                      ? <img
                          className='exclamation-icon'
                          src={getImage(`rating-${threads?.length ? (threads[0]?.averageRating || 0) : 0}`)}
                          alt="smile"
                        />
                      : null
                    }
                    <div className="client">
                      {threads?.length ? threads[0]?.userName : ""}
                    </div>
                  </div>
                </div>
                
                <div className='d-flex align-items-center' style={{ gap: '10px' }}>
                  {
                    inbox?.userName === whats_app_sender_state?.sender_inbox_userName
                    ? <img src={getImage("thread_lock_icon")} alt="thread-lock" />
                    : threads?.length && threads[0]?.chatStatus === "closed"
                    ? <img src={getImage("tik_right_icon")} alt="tik-right" />
                    : null
                  }
                  {
                    !isOpenedDropdown
                    ? <img
                        onClick={() => setIsOpenedDropdown(true)}
                        style={{ cursor: 'pointer' }}
                        src={getImage("bot_dropdown_inactive")}
                        alt="dropdown"
                      />
                    : <img
                        onClick={() => setIsOpenedDropdown(false)}
                        style={{ cursor: 'pointer' }}
                        src={getImage("bot_dropdown_active")}
                        alt="dropdown"
                      />
                  }
                </div>
                  {/* : 
                    <div className='d-flex align-items-center' style={{ gap: '10px' }}>
                      {
                        threads?.length && threads[0]?.chatStatus === "closed"
                        ? <img src={getImage("tik_right_icon")} alt="tik-right" />
                        : null
                      }
                      <img
                        onClick={() => setIsOpenedDropdown(false)}
                        style={{ cursor: 'pointer' }}
                        src={getImage("bot_dropdown_active")}
                        alt="dropdown"
                      />
                    </div>
                } */}
              </div>
            </div>
          </div>
          {
            isOpenedDropdown
            ? 
            <div className='treads'>
              {
                threads?.map((thread: any, idx: number) => (
                  // <div key={idx} className={`tread-container feed-card-hover feed-card-active`}>
                  <div key ={idx} className = {`tread-container feed-card-hover ${query_string?.includes(thread?.ticketNo) ? "feed-card-active" : ""} ${idx === 0 ? "d-none" : ""}`}
                    onClick={() => {
                      const seeing_conversation_object = {
                        userEmail: inbox?.userEmail,
                        ...thread,
                        userName: inbox?.userName,
                        threadUserName: thread?.userName,
                      };
                      console.log({ seeing_conversation_object });
                      if (query_string?.includes(seeing_conversation_object?.ticketNo)) {
                        return;
                      }
                      handleSeeOtherWhatsappConversation(seeing_conversation_object);
                      // setActiveInboxUser(inbox?.userName);
                      // setActiveInboxThread(thread);
                    }}
                  >
                    <div className="feeds-card-header d-flex justify-content-between align-items-start"
                      style={{cursor: 'pointer'}}
                    >
                      {
                        inbox?.reviewerPhoteUrl || inbox?.imageUrl
                        ? <img
                            className="user-avatar"
                            src={inbox?.reviewerPhoteUrl || inbox?.imageUrl || user_avatar}
                            alt="avatar"
                          />
                        : 
                          <img
                            className="user-avatar"
                            src={getGenerateApiImageByName(inbox?.userName)}
                            alt="avatar"
                          />
                      }
                      <div
                        className="feeds-card-header-content d-flex justify-content-between align-items-start"
                        style={{ width: "100%" }}
                      >
                        <div>
                          <div className="name-rating d-flex m-0 align-items-center">
                            <h6 className="user-name">
                              {inbox?.userName?.substr(0, 13) || "Client Name"}
                            </h6>
                            
                          </div>
                          <p className="text-start date">
                            { 
                              thread?.createdOn
                              ? moment(thread?.createdOn).format("DD MMM YYYY, hh:mm:ss A")
                              : null
                            }

                          </p>
                          {
                            isFromModalBox
                            ? <p
                                className="description mb-2"
                                style={{color: thread?.color || "black"}}
                              >
                                {
                                  thread?.lastMessage
                                  ? thread?.lastMessage?.substring(0, 26) + ".."
                                  : null
                                }
                                {/* Lorem Ipsum is simply dummy text of the printing and also typesetting industry. Lorem Ipsum has been into the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. */}
                              </p>
                            : <>
                              <p
                                className="text-start description mb-2 normal-description"
                                // onClick={() => {
                                //   if (activePage !== "conversations") {
                                //     dispatch(setBoxId(inbox?.SlNo));
                                //   }
                                // }}
                                style={{cursor: 'pointer', color: thread?.color || "black"}}
                              >
                                {
                                  thread?.lastMessage
                                  ? thread?.lastMessage?.substring(0, 22) + ".."
                                  : null
                                }
                                <span>Read More</span>
                              </p>
                              <p
                                className="text-start description mb-2 lg-description"
                                style={{ cursor: 'pointer', color: thread?.color || "black" }}
                                // onClick={() => {
                                //   if (activePage !== "conversations"){
                                //     dispatch(setBoxId(inbox?.SlNo));
                                //   }
                                // }}
                              >
                                {
                                  thread?.lastMessage
                                  ? thread?.lastMessage?.substring(0, 36) + ".."
                                  : null
                                }
                                <span>Read More</span>
                              </p>
                            </>
                          }
                        </div>
      
                        <img
                          className='provider-icon'
                          src={getImage(type)}
                          alt="provider"
                        />
                      </div>
                    </div>
                    <div className="feeds-card-footer w-100" style={{padding: "1.38% 3.5%"}}>
                      <div className="footer-content w-100">
                        <div className="left-emoji">
                          <img
                            className='exclamation-icon'
                            src={getImage(`rating-${thread?.averageRating || 0}`)}
                            alt="rating-smile"
                          />
                          {/* <div className="left-body-emoji">
                            <span className="d-block client">Your Bot Name</span>
                          </div> */}
                        </div>
                        {
                          thread?.chatStatus === "closed"
                          ? <img src={getImage("tik_right_icon")} alt="tik-right" />
                          : null
                        }
                        
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
            : null
          }
        </div>
        : null
      }
    </React.Fragment>
  )
}
