import Constants from "../../../constants";

const initialState = {
    locations: [],
    integration_tab: "Integrations",
    is_ready_to_selection_location: false,
}

// After the invitation, convert to clap up user....
// export const getTeamList = (data: any = null) => {
//     return {
//         type: Constants.GET_TEAM_LIST,
//         payload: data,
//     };
// }

export const resetIntegrationTab = (data?: any) => {
    return {
        type: "RESET_INTEGRATION_TAB",
        payload: data,
    };
}

export const setIntegrationTab = (data: any = null) => {
    return {
        type: Constants.SET_INTEGRATION_TAB,
        payload: data,
    };
}


export const setReadyToSelectionTable = (data: any = null) => {
    return {
        type: Constants.SET_READY_TO_SELECT_LOCATION,
        payload: data,
    };
}


const integrationReducer = (state: any = initialState, action: any) => {
    // console.log({user_reducer_action_data: action});

    switch (action.type) {
        case Constants.SET_READY_TO_SELECT_LOCATION:
            return { ...state, is_ready_to_selection_location: action.payload };
        case Constants.SET_INTEGRATION_TAB:
            return { ...state, integration_tab: action.payload };
        case "RESET_INTEGRATION_TAB":
            return {
                ...state,
                is_ready_to_selection_location: action.payload,
                integration_tab: "Integrations",
            };

        case Constants.LOGOUT:
            return {
                ...state,
                locations: [],
                integration_tab: "Integrations",
                is_ready_to_selection_location: false,
            };

        default:
            return state;
    }
}

export default integrationReducer;