import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./BioPageSettings.scss";
import { getImage, getGenerateApiImageByName } from "../../../unified/image_service";
import { requestForSelectingBioPageTemplate, toggleSelectedTemplate, updateBioPageSettings, getBioPageSettings } from "./bioPageReducer";
// import { setSuccessMessage } from "../../../Unified/reducer";
import CommonModalCustomWidth from "../../../components/CommonModal/CommonModalCustomWidth";
import TemplateSelection from "./BiopageTemplates/TemplateSelection";
import { toggleShowMobileSettingsNavBar } from "../../../signup-login/loginReducer";
import { setErrorMessage, setSuccessMessage } from "../../../unified/reducer";
import { uploadIconFileData } from "./bioPageSaga";

const BioPageSettings: React.FC = () => {
    // const parent_routes = workspace_routes;
    // const { personalInfo } = useSelector((state: any) => state?.profileManageSettingState);
    const dispatch = useDispatch();
    // const path_name = window.location.pathname;
    const { GeneralSettings, is_selected_bio_page_template, bioPageTemplates } = useSelector((state: any) => state?.bioPageSettingState);

    // Workspace Name
    // const { workspaceSettings, isUpdatedWorkspace } = useSelector((state: any) => state?.workspaceManagementState);
    const [bioPageName, setBioPageName] = useState<any>(null);
    const [logoURL, setLogoURL] = useState<any>("");
    const [faviconURL, setFaviconURL] = useState<any>("");
    const [siteDescription, setSiteDescription] = useState<any>(null);
    const [seoTitle, setSeoTitle] = useState<any>(null);
    const [metaDescription, setMetaDescription] = useState<any>(null);
    const [bioPageDomain, setBioPageDomain] = useState<any>(null);
    const [bioPageTermsAndConditionUrl, setBioPageTermsAndConditionUrl] = useState<any>(null);
    const [activateField, setActivateField] = useState<any>("");

    useEffect(() => {
        if (GeneralSettings) {
            const {
                bioPageBrandName, logoUrl, faviconUrl,
                seoTitle, bioPageSiteDescription, bioPageMetaDescription,
                bioPageDomain, bioPageTermsAndConditionUrl 
                
            } = GeneralSettings || {};
            setBioPageName(bioPageBrandName || "");
            setLogoURL(logoUrl?.replaceAll("@", "/"));
            setFaviconURL(faviconUrl?.replaceAll("@", "/"));
            setSeoTitle(seoTitle);
            setSiteDescription(bioPageSiteDescription);
            setMetaDescription(bioPageMetaDescription);
            setBioPageDomain(bioPageDomain);
            setBioPageTermsAndConditionUrl(bioPageTermsAndConditionUrl);
        }
    }, [dispatch, GeneralSettings]);
    // Bio page template name
    const [isReadyToSelectTemplate, setIsReadyToSelectTemplate] = useState<boolean>(false);
    const handleSubmitSelectedTemplate = (data?: any) => {
        console.log({ data });
        dispatch(requestForSelectingBioPageTemplate(data?.id));
    }
    
    useEffect(() => { 
        if (is_selected_bio_page_template) {
            dispatch(toggleSelectedTemplate(false));
            setActivateField("");
            setIsReadyToSelectTemplate(!isReadyToSelectTemplate);
            // dispatch(setSuccessMessage("Settings saved"));
            handleCancel();        
        }
    }, [is_selected_bio_page_template]);

    const handleCancel = () => {
        setIsReadyToSelectTemplate(false);
    }

    let current_default_template = bioPageTemplates?.find((item: any) => (item?.isCurrentTemplate));
    if (!current_default_template) {
        current_default_template = bioPageTemplates?.find((item: any) => (item?.isDefault));
    }

    const handleUpdateBioPageSettingField = (columnName: any, columnValue: any) => {
        // console.log({columnValue});
        if(!columnValue){
            dispatch(setErrorMessage("Value is required"));
            return;
        }
        const dataObject = {
            columnName,
            columnValue,
            clapupId: sessionStorage.getItem('cuid'),
            workSpaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
            linktype: "BiopageGeneralSettings"
        }
        console.log({dataObject});
        dispatch(updateBioPageSettings(dataObject));
    }


    const handleImageChange = async(e: any, logo_key: string, setImageURL: any) => {
        // file local path
        // const image_path
        const file = e.target.files[0];
        // const path = e.target.value;
        // console.log({path, file});
        // setImageFile(file);
        const {
            // data, success, message,
            iconUrl
        } = await uploadIconFileData({ contentFile: file, contentType: logo_key });


        // console.log("image-response ===> ", {data, success, message, iconUrl});
        if (iconUrl) {
            dispatch(setSuccessMessage("Saved Successfully"));
            setImageURL(iconUrl);
            dispatch(getBioPageSettings());
        } else {
            dispatch(setErrorMessage("Server Error"));
        }

        // ======== 1st way to convert image to base64 ========
        // convertImageToBase64(file).then((base64Url: any) => {
        //     // console.log({base64Url});
        //     setLogoBaseUrl(base64Url);
        //     // handleLogoSubmit(base64Url);

        // })
        // .catch((error: any) => {
        //     console.log({error});
        // });

        // ======== 2nd way to convert image to base64 ========
        // const formData = new FormData();
        // const body = {
        //     "code": Number(new Date().getTime() + (1000*1000)),
        //     "AccessKey": "746def78-473d-4b9a-b230976d1e62-6dd3-46de",
        //     "workspaceId": sessionStorage.getItem('wuid'),
        // }
        // formData.append('file', file);
        // formData.append('body', btoa(JSON.stringify(body)));
        // console.log({ formBody: body });
        // axios((auth_url) + "/api/v1/upload-workspace-image", {
        //     method: 'POST',
        //     data: formData,
        // }).then(response => {
        //     console.log({response});
        //     // setLogoURL(response?.data?.data?.url);
        // }).catch(error => {
        //     console.log({error});
        // });
        
    }

    const handleDeleteBioPageLogoOrFavicon = () => {
        console.log("Came for deleting logo...");
    }

    const handleUpdateActivateField = () => {
        if(!activateField){
            return;
        }
        
        activateField === "bioPageBrandName" && handleUpdateBioPageSettingField("bioPageBrandName", bioPageName);
        activateField === "bioPageSiteDescription" && handleUpdateBioPageSettingField("bioPageSiteDescription", siteDescription);
        activateField === "seoTitle" && handleUpdateBioPageSettingField("seoTitle", seoTitle);
        activateField === "bioPageMetaDescription" && handleUpdateBioPageSettingField("bioPageMetaDescription", metaDescription);
        activateField === "bioPageDomain" && handleUpdateBioPageSettingField("bioPageDomain", bioPageDomain);
        activateField === "bioPageTermsAndConditionUrl" && handleUpdateBioPageSettingField("bioPageTermsAndConditionUrl", bioPageTermsAndConditionUrl);
    };

    const { userPermissions, is_show_mobile_settings_nav_bar } = useSelector((state: any) => state?.login);
    const history = useNavigate();
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "workspaceSettings") : null;
        // const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "generalSettings-Account") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions, history]);

    return (
        <React.Fragment>
            
            <CommonModalCustomWidth 
                modal_visible={isReadyToSelectTemplate} 
                setModalVisible={setIsReadyToSelectTemplate}
                centered={true}
                // okText="Send"
                // cancelText="Cancel"
                padding={"0"}
                class_name="modal_container"
                footer={null}
            >
                <TemplateSelection isReadyToSelectTemplate={isReadyToSelectTemplate} handleCancel={handleCancel} handleSubmitSelectedTemplate={handleSubmitSelectedTemplate}/>
            </CommonModalCustomWidth>
            {/* Template Selection Modal */}
            <div className="button-container">
                <img className="mobile-setting-nav-bar"
                    onClick={() => dispatch(toggleShowMobileSettingsNavBar(!is_show_mobile_settings_nav_bar))}
                    src={getImage("mobile-nav-icon")}
                    alt='hum-burger'
                />
                <button onClick={() => handleUpdateActivateField()}>Save Changes</button>
            </div>
            <div className="body-container">
                <div className="bio-page-settings-container">
                    <div className="settings-header-title">
                        <h3>Biopage Settings</h3>
                    </div>
                    <div className="settings-form-item">
                        <div className="title">Biopage Name</div>
                        <div className="action-body">
                            <div className="input-container">
                                <input
                                    className=""
                                    value={bioPageName || ""}
                                    onChange={(e: any) => {
                                        setBioPageName(e.target.value);
                                        // setActivateField("bioPageBrandName");
                                    }}
                                    placeholder={"Biopage Name"}
                                />
                                <img
                                    onClick={() => handleUpdateBioPageSettingField("bioPageBrandName", bioPageName)}
                                    className="tik"
                                    src={getImage("fill-success-tik")}
                                    alt="tik"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="settings-form-textarea-item">
                        <div className="title">Biopage Description</div>
                        <div className="action-body">
                            <div className="input-container">
                                <textarea
                                    className=""
                                    defaultValue={siteDescription || ""}
                                    onChange={(e: any) => {
                                        setSiteDescription(e.target.value);
                                        // setActivateField("bioPageSiteDescription");
                                    }}
                                    placeholder={"Thank you for visiting our biopage. You can go through our various links."}
                                />
                                <img
                                    onClick={() => handleUpdateBioPageSettingField("bioPageSiteDescription", siteDescription)}
                                    className="tik"
                                    src={getImage("fill-success-tik")}
                                    alt="tik"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="settings-form-template-item">
                        <div className="title">Biopage Template</div>
                        <div className="action-body">
                            <div className="input-container">
                                <div className="logo-container">
                                    <div className="template-logo-container">
                                        {
                                            current_default_template?.templateUrl
                                            ?
                                            <img
                                                className='logo' 
                                                src={current_default_template?.templateUrl || getGenerateApiImageByName(current_default_template?.templateName || "Default Template")} 
                                                alt={"template-url"} 
                                            />
                                            : null
                                        }
                                        <div className="title-container">{current_default_template?.templateName || "Default Template"}</div>
                                    </div>
                                    <div className="action-container">
                                        <div onClick={() => {setIsReadyToSelectTemplate(!isReadyToSelectTemplate)}}>Select Template</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="settings-form-item">
                        <div className="title">Biopage Logo</div>
                        <div className="action-body">
                            <div className="input-container">
                                <div className="logo-container">
                                    <img
                                        className='logo' 
                                        src={logoURL || getGenerateApiImageByName("Biopage Loco")} 
                                        alt={"logo"} 
                                    />
                                    <div className="action-container">
                                        <div
                                            // onClick={() => handleDeleteBioPageLogoOrFavicon()}
                                        >
                                            Delete
                                        </div>
                                        <input
                                            id="image" type="file"
                                            // accept='*'
                                            accept='image/png, image/jpg, image/jpeg, image/svg'
                                            onChange={(e: any) => {
                                                // console.log({ img: e?.target?.files[0] });
                                                setLogoURL(URL.createObjectURL(e?.target?.files[0]));
                                                // setImage(e?.target?.files[0]?.name);
                                                handleImageChange(e, "BiopageLogo", setLogoURL);
                                                // if (e?.target?.files[0]?.size < 25000) {
                                                // }

                                            }}
                                            className="d-none"
                                        />
                                        <label htmlFor="image">Upload New</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="settings-form-item">
                        <div className="title">Fav Icon</div>
                        <div className="action-body">
                            <div className="input-container">
                                <div className="logo-container">
                                    <img
                                        className='logo' 
                                        src={faviconURL || getGenerateApiImageByName("Fav icon")} 
                                        alt={"logo"} 
                                    />
                                    <div className="action-container">
                                        <div
                                            // onClick={() => handleDeleteBioPageLogoOrFavicon()}
                                        >
                                            Delete
                                        </div>
                                        <input
                                            id="image" type="file"
                                            // accept='*'
                                            accept='image/png, image/jpg, image/jpeg, image/svg'
                                            onChange={(e: any) => {
                                                // console.log({ img: e?.target?.files[0] });
                                                setFaviconURL(URL.createObjectURL(e?.target?.files[0]));
                                                // setImage(e?.target?.files[0]?.name);
                                                handleImageChange(e, "BiopageFavicon", setFaviconURL);
                                                // if (e?.target?.files[0]?.size < 25000) {
                                                // }

                                            }}
                                            className="d-none"
                                        />
                                        <label htmlFor="image">Upload New</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="settings-form-textarea-item">
                        <div className="title">SEO Title</div>
                        <div className="action-body">
                            <div className="input-container">
                                <textarea
                                    className=""
                                    defaultValue={seoTitle || ""}
                                    onChange={(e: any) => {
                                        setSeoTitle(e.target.value);
                                        // setActivateField("seoTitle");
                                    }}
                                    placeholder={"Get the best food nearby you in minutes with doorsteps delivery."}
                                />
                                <img
                                    onClick={() => handleUpdateBioPageSettingField("seoTitle", seoTitle)}
                                    className="tik"
                                    src={getImage("fill-success-tik")}
                                    alt="tik"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="settings-form-textarea-item">
                        <div className="title">SEO Description</div>
                        <div className="action-body">
                            <div className="input-container">
                                <textarea
                                    className=""
                                    defaultValue={metaDescription || ""}
                                    onChange={(e: any) => {
                                        setMetaDescription(e.target.value);
                                        // setActivateField("bioPageMetaDescription");
                                    }}
                                    placeholder={"Get the best food nearby you in minutes with doorsteps delivery."}
                                />
                                <img
                                    onClick={() => handleUpdateBioPageSettingField("bioPageMetaDescription", metaDescription)}
                                    className="tik"
                                    src={getImage("fill-success-tik")}
                                    alt="tik"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="settings-form-item">
                        <div className="title">Custom Domain</div>
                        <div className="action-body">
                            <div className="input-container">
                                <input
                                    className=""
                                    defaultValue={bioPageDomain || ""}
                                    onChange={(e: any) => setBioPageDomain(e.target.value)}
                                    placeholder={"biopage.clapup.io"}
                                />
                                <img
                                    onClick={() => handleUpdateBioPageSettingField("bioPageDomain", bioPageDomain)}
                                    className="tik"
                                    src={getImage("un-fill-success-tik")}
                                    alt="tik"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="settings-form-item">
                        <div className="title">Terms and Conditions URL</div>
                        <div className="action-body">
                            <div className="input-container">
                                <input
                                    className=""
                                    defaultValue={bioPageTermsAndConditionUrl || ""}
                                    onChange={(e: any) => {
                                        setBioPageTermsAndConditionUrl(e.target.value);
                                        // setActivateField("bioPageTermsAndConditionUrl");
                                    }}
                                    placeholder={"biopage.clapup.io/terms-and-conditions"}
                                    
                                />
                                <img
                                    onClick={() => handleUpdateBioPageSettingField("bioPageTermsAndConditionUrl", bioPageTermsAndConditionUrl)}
                                    className="tik"
                                    src={getImage("fill-success-tik")}
                                    alt="tik"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="extra"></div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default BioPageSettings;