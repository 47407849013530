import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { useNavigate } from 'react-router';
// import collapse from '../../assets/icons/collapse-btn.svg';
import SignUpModal from './SignUpModal';
import './SignUpModalForm.scss';
import { createNewWorkspace } from '../../pages/Settings/WorkspaceSettings/workspaceReducer';
import { getImage } from '../../unified/image_service';
import { findWorkspaceList } from '../Layout/header/headerReducer';
interface FormProps {
    open?: boolean;
    parentCallback?: any;
};

const SignUpModalForm: React.FC<FormProps> = ({ open, parentCallback }: any) => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState<number>(0);
  const [host, setHost] = useState<string>("");
  const [workspace, setWorkspace] = useState<string>("");

    const navigate = useNavigate();
    // const [visible, setVisible] = useState<boolean>(false);
    const {newWorkspaceResult} = useSelector((state: RootState) => state?.workspaceManagementState);
    useEffect(() => {
        // setVisible(open);
        if(newWorkspaceResult?.length) {
            console.log('newWorkspaceResult ======> ', newWorkspaceResult);
            const data: any = newWorkspaceResult[0];
            const { CreatedBy, ID, roleId, WorkSpaceName  } = data;
            CreatedBy && sessionStorage.setItem('cuid', CreatedBy);
            ID && sessionStorage.setItem('wuid', ID);
            roleId && sessionStorage.setItem("roleId", roleId);
            // sessionStorage.setItem("roleName", newWorkspaceResult[0]?.roleName);
            WorkSpaceName && sessionStorage.setItem("workspaceName", WorkSpaceName);
            // find workspace list...
            if (CreatedBy) {
                localStorage.removeItem("signup_loading");
                dispatch(findWorkspaceList());
                navigate("/dashboard", { replace: true });
                // setVisible(false);
                parentCallback();
            }
        }
    }, [newWorkspaceResult?.length]);

  const handleNext = () => {
    setCurrent((current + 1));
  };
  
  const handleBack = () => {
    setCurrent((current - 1));
  };

  const handleFinish = () => {
    // console.log({ host, workspace });
    const user_details = sessionStorage.getItem("accessed_user_details");
    const user = JSON.parse(user_details || "{}");
    const { email } = user;
    const dataObj = {
      workSpaceName: workspace,
      agencyId: "0",
      workspaceType: "Basic",
      businessName: workspace,
      // superAdminEmail: "mmhk.itsforyou@gmail.com", // "Email Id of the signup user",
      superAdminEmail: email, // "Email Id of the signup user",
      createdBy: "",
      subdomainName: host, // "The name entered in the hostname column in the modal popup"
    };
    console.log("dataObj ====> ", dataObj);
    // return;
    sessionStorage.removeItem("client_details");
    dispatch(createNewWorkspace(dataObj));
  };

  const [setting_accounts, _] = useState<any>([
    {title: "Setting up workspace" },
    {title: "Setting up storage " },
    {title: "Setting up CDN" },
    {title: "Setting up video streaming service" },
    {title: "Setting up team service" },
  ]);

  // const current_element = {
  //   key_0: <SignUpModal class_name={`app-custom-modal`} open={open} parentCallback={parentCallback}>
  //             <div className={`modal-container`}>
  //               <div className='header'>
  //                 <p className='title'>Hostname for Biopage</p>
  //               </div>
  //               <div className='sub-title'>
  //                 <p className='number'>1</p>
  //                 <span className=''>Hostname</span>
  //               </div>
  //               <p className='description'>One it’s set, your biopage will be available on “hostname.clapup.in”. Use a unique name that closely represent your brand. If it’s already taken you can try with other names. Word limit is 20, and no special character or any spaces are allowed. You can later configure your own domain by contacting our support.</p>
  //               <div className='input-container'>
  //                 <input 
  //                   type='text' placeholder='eg: your company (microsoft)' 
  //                   autoFocus
  //                   value={host || ""}
  //                   onChange={(e) => setHost(e.target.value)} 
  //                 />
  //               </div>
  //               <div className='button-container'>
  //                 <button style={{cursor: `${!host ? "not-allowed" : "pointer"}`}} disabled={!host} className='next' onClick={() => handleNext()}>Next</button>
  //               </div>
  //             </div>
  //           </SignUpModal>,
  //   key_1: <SignUpModal class_name={`app-custom-modal`} open={open} parentCallback={parentCallback}>
  //             <div className={`modal-container`}>
  //               <div className='header'>
  //                 <p className='title'>Setting Workspace</p>
  //               </div>
  //               <div className='sub-title'>
  //                 <p className='number'>2</p>
  //                 <span className=''>Workspace Name</span>
  //               </div>
  //               <p className='description'>Workspace is the space is like your organization. It’s where, you can access all our modules. Once it’s set, you can start inviting your users. Give a name representing your brand. You can add your brand logo and edit this information later from workspace settings. No spaces or special character are allowed.</p>
  //               <div className='input-container'>
  //                 <input 
  //                   type='text' placeholder='eg: your company (microsoft)' 
  //                   autoFocus
  //                   value={workspace || ""}
  //                   onChange={(e) => setWorkspace(e.target.value)} 
  //                 />
  //               </div>
  //               <div className='button-container'>
  //                 <button className='back' onClick={() => handleBack()}>Back</button>
  //                 <button style={{cursor: `${!workspace ? "not-allowed" : "pointer"}`}} disabled={!workspace} className='next' onClick={() => handleNext()}>Next</button>
  //               </div>
  //             </div>
  //           </SignUpModal>,
  //   key_2:  <SignUpModal class_name={`app-custom-modal`} open={open} parentCallback={parentCallback}>
  //             <div className={`modal-container`}>
  //               <div className='header'>
  //                 <p className='title'>Setting up your account</p>
  //               </div>
  //               <div className="setting-up-account-table">
  //                   <div className='table-responsive'>
  //                       <table className="table m-0">
  //                           <thead className="d-none"><tr><th>Title</th><th>Tik</th></tr></thead>
  //                           <tbody>
  //                               {setting_accounts?.map((setting_account: any, idx: number) => (
  //                               <tr key={idx}>
  //                                   <td>
  //                                       <div>{setting_account?.title}</div>
  //                                   </td>
  //                                   <td>
  //                                       <img className="tik" src={getImage("fill-success-tik")} alt="tik"/>
  //                                   </td>
  //                               </tr>
  //                               ))}
  //                           </tbody>
  //                       </table>
  //                   </div>
  //               </div>
  //               <div className='button-container'>
  //                 <button className='back' onClick={() => handleBack()}>Back</button>
  //                 <button className='finish' onClick={() => handleFinish()}>Proceed</button>
  //               </div>
  //             </div>
  //           </SignUpModal>,
  // }
      
  return (
    <React.Fragment>

      {/* {
        current_element[`key_${current === 0 ? "0" : current === 1 ? "1" : "2"}`]
      } */}

      {/* new - codes */}
      <div className='custom-modal-wrap'>
      {(current == 0 || current == 1) ?
          <div className='custom-modal'>
            {current == 0 &&
              <div className={`modal-container`}>
                <div className="container-div">
                  <div className='header'>
                    <p className='title'>Hostname for Biopage</p>
                  </div>
                  <div className='sub-title'>
                    <p className='number'>1</p>
                    <span className=''>Hostname</span>
                  </div>
                  <p className='description'>One it’s set, your biopage will be available on “hostname.clapup.in”. Use a unique name that closely represent your brand. If it’s already taken you can try with other names. Word limit is 20, and no special character or any spaces are allowed. You can later configure your own domain by contacting our support.</p>
                  <div className='input-container'>
                    <input 
                      type='text' placeholder='eg: your company (microsoft)' 
                      autoFocus
                      value={host || ""}
                      onChange={(e) => setHost(e.target.value)} 
                    />
                  </div>
                  <div className='button-container'>
                    <button style={{cursor: `${!host ? "not-allowed" : "pointer"}`}} disabled={!host} className='next' onClick={() => handleNext()}>Next</button>
                  </div>
                </div>
              </div>
            }
            {current == 1 &&
              <div className={`modal-container`}>
                <div className="container-div">
                  <div className='header'>
                    <p className='title'>Setting Workspace</p>
                  </div>
                  <div className='sub-title'>
                    <p className='number'>2</p>
                    <span className=''>Workspace Name</span>
                  </div>
                  <p className='description'>Workspace is the space is like your organization. It’s where, you can access all our modules. Once it’s set, you can start inviting your users. Give a name representing your brand. You can add your brand logo and edit this information later from workspace settings. No spaces or special character are allowed.</p>
                  <div className='input-container'>
                    <input 
                      type='text' placeholder='eg: your company (microsoft)' 
                      autoFocus
                      value={workspace || ""}
                      onChange={(e) => setWorkspace(e.target.value)} 
                    />
                  </div>
                  <div className='button-container'>
                    <button className='back' onClick={() => handleBack()}>Back</button>
                    <button style={{cursor: `${!workspace ? "not-allowed" : "pointer"}`}} disabled={!workspace} className='next' onClick={() => handleNext()}>Next</button>
                  </div>
                </div>
              </div>
            }
          </div>
          : null
        }


        {current == 2 &&
          <div className={`custom-continue-modal`}>
            <div className="modal-container">
              <div className='modal-body'>
                <div className='left-image'>
                  <img src={getImage("error-warning-success")} alt="ews-icon" />
                </div>
                <div className='right-body'>
                  <h2 className='title'>Account Setup</h2>
                  <div className="description">We are setting up your account. It may take a minute or two, by the time you can go through our docs or browse dashboard.</div>
                  <div className='button-container'>
                    <button className='back' onClick={() => handleBack()}>Back</button>
                    <button className='continue' onClick={() => handleFinish()}>continue</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </React.Fragment>
  )
}

export default SignUpModalForm;