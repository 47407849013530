import React from 'react';
import prev_icon from '../../../../assets/icons/previous-icon.svg';
import next_icon from '../../../../assets/icons/next-icon.svg';
import refresh_icon from '../../../../assets/icons/refresh-icon-unified.svg';
import user_avatar from '../../../../assets/icons/user-avatar.svg';
import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
import back_btn from '../../../../assets/icons/back.svg';
import start from '../../../../assets/icons/star-icon.svg';
// import star_half from '../../../../assets/icons/star-half-icon.svg';
// import lock_icon from '../../../../assets/icons/lock-icon.svg';
// import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
// import reply_icon from '../../../../assets/icons/reply-icon.svg';
// import edit_icon from '../../../../assets/icons/edit-icon.svg';
// import send_icon from '../../../../assets/icons/send-icon.svg';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import ConversationDetails from '../AllDetails/ConversationsDetails';
import { useDispatch, useSelector } from 'react-redux';
// import { setSuccessMessage } from '../../../../dashboard/reducer';
import { getGeneratedVideoPresignedUrlByContentIdVideoTestimonial } from '../../../../utils/mediaService';
import ConversationHeader from './ConversationHeader';
import ConversationHeaderSD from './ConversationHeaderSD';
import { getGenerateApiImageByName } from '../../../../unified/image_service';
export default function VideoConversations(props: any) {
  const dispatch = useDispatch();
  const {conversation, handleReplaceConversation, getImage, handlePrevPath, isReply, setIsReply, setReplyText, textRef, handleReply, conversationId, isGoForDetails, handleDetailChange} = props;
  const {contentId, properties, details, from} = conversation || {};
  const { reviewedbyUserName, reviewerDisplayName, reviewerPhoteUrl, mentionedbyUserName, mentionedbyUserImageUrl, requestedDate, createdOn, requestorMessage,reviewComment, provider, starRating, ratingStars, likeCount, retweetCount, locationName, pagename, recieverCompany } = conversation || {};
  // console.log({ contentId, video_conversation: conversation });
  const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
  const { workspaces } = useSelector((state: any) => state?.headerState);
  const workSpaceName: any = workspaces?.find(
    (w: any) => w?.workSpaceId === localStorage.getItem("wuid")
  )?.workSpaceName || "";

  const [videoUrl, setVideoUrl] = React.useState<any>("");
  const get_video_url = async (contentId: any) => { 
    const data = await getGeneratedVideoPresignedUrlByContentIdVideoTestimonial(contentId);
    console.log({video_data: data });
    
    if (data?.contentUrl) {
      // setVideoUrl(data?.presignedUrl);
      setVideoUrl(data?.contentUrl);
    } else {
      setVideoUrl("");
    }
  };
  React.useEffect(() => {
    if (contentId) {
      get_video_url(contentId);
    }
  }, [contentId]);
  // console.log({ videoUrl, conversation });
  
  return (
    <React.Fragment>
      <div className="conversation-section-container-lg h-100">
        <ConversationHeader
          header_title={" submitted a video for "}
          workSpaceName={workSpaceName}
          workspaceSettings={workspaceSettings}
          conversation={conversation}
          handleReplaceConversation={handleReplaceConversation}
          prev_icon={prev_icon}
          next_icon={next_icon}
          refresh_icon={refresh_icon}
          user_avatar_2={user_avatar_2}
          user_avatar={user_avatar}
        />
        <div className="conversation-sectio conversation-section-video-feedback">
          <Scrollbars
            style={{
              paddingRight: 10,
              display: "flex",
              flexDirection: "column-reverse",
            }}
            // style={{ paddingRight: 10 }}
            autoHide
            autoHideTimeout={500}
            autoHideDuration={500}
            autoHeight
            // autoHeightMin={250}
            // autoHeightMax={250}
            // autoHeightMin={480}
            autoHeightMax={"100%"}
            thumbMinSize={10}
            universal={true}
          >
            <div className="conversation-body" id="scrollableDiv">
              {/* This will implement it feeds.tsx file */}
              {/* <InfiniteScroll
                dataLength={2}
                className="scrolling"
                next={() => console.log("next")}
                inverse={true}
                hasMore={false}
                loader={<h4 className="text-center">Loading...</h4>}
                scrollableTarget="scrollableDiv"
                height={320}
                
              > */}
              <div className="conversation-person-1">
                <div className="details">
                  {
                    reviewerPhoteUrl
                    ? <img
                        className="user-avatar"
                        src={reviewerPhoteUrl || user_avatar}
                        alt="avatar"
                      />
                    : <img
                          className="user-avatar"
                          src={getGenerateApiImageByName(reviewerDisplayName)}
                          alt="avatar"
                      />
                  }
                  <span className="description">
                    <div
                      className={`d-flex align-items-center user-ratings-container`}
                    >
                      <div className="user_name me-2 ">
                        {reviewerDisplayName || "User Name"}
                      </div>
                      {
                        ratingStars ? (
                          <div className="rating-social-email">
                            <div>
                              {Array.from(Array(Number(ratingStars)).keys()).map((_: any, index: number) => {
                                return (
                                  <img
                                    className="star"
                                    key={index}
                                    src={start}
                                    alt="star"
                                  />
                                );
                              })}
                            </div>
                            <div className="rating">
                              {ratingStars}/5
                            </div>
                          </div>
                        ) : null
                      }
                    </div>

                    <div className="client-container mb-2">
                      {
                        // new Date(createdOn).toDateString() + ' '
                        // + (new Date(createdOn).getHours() < 10 ? '0' + new Date(createdOn).getHours() : new Date(createdOn).getHours())
                        // + ':' + (new Date(createdOn).getMinutes() < 10 ? '0' + new Date(createdOn).getMinutes() : new Date(createdOn).getMinutes())
                        // + ':' + (new Date(createdOn).getSeconds() < 10 ? '0' + new Date(createdOn).getSeconds() : new Date(createdOn).getSeconds()) +
                        moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " - " + (recieverCompany || "Company Name")
                      }
                    </div>
                    <p>{reviewComment}</p>
                    {/* <p>{requestorMessage}</p> */}
                    <div className="my-1">
                      {/* <img src={video_link} alt="video-link" /> */}
                      <div
                        style={{
                          position: "relative",
                          paddingTop: "60.25%",
                          display: videoUrl ? "block" : "none",
                        }}
                      >
                        <iframe
                          // src={`https://iframe.mediadelivery.net/embed/${conversation?.LibraryId}/${conversation?.videoId}?autoplay=false`}
                          loading="lazy"
                          src={videoUrl?.replaceAll('@', "/")}
                          style={{
                            border: "none",
                            position: "absolute",
                            top: 0,
                            height: "100%",
                            width: "100%",
                            borderRadius: 5,
                          }}
                          allow="accelerometer; gyroscope; autoplay: false; encrypted-media; picture-in-picture;"
                          allowFullScreen={true}
                        ></iframe>
                      </div>
                    </div>
                    
                  </span>
                </div>
                <p className="text-start mt-2 date">
                  {"Video Testimonial"}{" "}
                  -{" "}
                  <span>
                    {
                      // new Date(createdOn).toDateString() + ' '
                      // + (new Date(createdOn).getHours() < 10 ? '0' + new Date(createdOn).getHours() : new Date(createdOn).getHours())
                      // + ':' + (new Date(createdOn).getMinutes() < 10 ? '0' + new Date(createdOn).getMinutes() : new Date(createdOn).getMinutes())
                      // + ':' + (new Date(createdOn).getSeconds() < 10 ? '0' + new Date(createdOn).getSeconds() : new Date(createdOn).getSeconds()) +
                      moment(requestedDate).format("DD MMM YYYY, hh:mm:ss A")
                      // moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + ("Tezla Inc")
                      // moment(details?.requested_time).format('Y-m-d')
                    }
                  </span>
                </p>
              </div>
              {/* {workspaceSettings && (
                <div className="conversation-person-2">
                  <div className="details">
                    <img
                      className="user-avatar"
                      src={workspaceSettings?.logo || user_avatar_2}
                      alt="user_avatar"
                    />
                    <span className="description">
                      <p>{reviewComment}</p>
                      
                    </span>
                  </div>
                  <p className="text-end mt-2 date">
                    {person_2?.date_title || "Replied"} -{" "}
                    <span>
                      {
                        moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " +(workspaceSettings?.workspaceName || "WS Name")
                        // moment(details?.requested_time).format('Y-m-d')
                      }
                    </span>
                  </p>
                </div>
              )} */}

              {/* </InfiniteScroll> */}
              <div className='py-5'></div>
            </div>
          </Scrollbars>
        </div>
        {/* <div className="conversation-footer">
          <p className="text-start my-2 px-3 sender">
            Sending as {workspaceSettings?.workspaceName || "WS Name"}
          </p>
          <div className="message-box mb-3">
            <div className="message">
              <div className="text-container">
                <textarea
                  ref={textRef}
                  className=""
                  defaultValue={person_2?.message}
                  placeholder="Write here..."
                  onChange={(e: any) => {
                    const { value } = e?.target;
                    setReplyText(value);
                  }}
                  style={{ resize: "none" }}
                  autoFocus={isReply}
                />
              </div>
              <div className="reply-btn">
                <button
                  style={{
                    background: `${isReply ? "#E4E7EC" : "#E4E7EC"}`,
                  }}
                  onClick={() => {
                    if (isReply) {
                      handleReply();
                    } else {
                      setIsReply(true);
                    }
                  }}
                  type="button"
                >
                  {person_2?.message && isReply
                    ? "Save"
                    : isReply
                    ? "Send"
                    : person_2?.message
                    ? "Edit"
                    : "Reply"}
                </button>
                <img
                  onClick={() => setIsReply(false)}
                  src={isReply ? lock_icon : unlock_icon}
                  alt="unlock"
                />
              </div>
              <p className={`click-reply-des ${isReply ? "d-none" : ""}`}>
                Click reply and start adding your reply.
              </p>
            </div>
          </div>
        </div> */}
      </div>
      <div className="conversation-container-mobile">
        <div className="conversation-header">
          <div className="header-top">
            <img
              onClick={handlePrevPath}
              className="back"
              src={back_btn}
              alt="back"
            />
            <img
              className="provider"
              src={getImage(provider)}
              alt="provider"
            />
          </div>
         
          <ConversationHeaderSD
            header_title={" submitted a video for "}
            workSpaceName={workSpaceName}
            workspaceSettings={workspaceSettings}
            conversation={conversation}
            handleReplaceConversation={handleReplaceConversation}
            prev_icon={prev_icon}
            next_icon={next_icon}
            refresh_icon={refresh_icon}
            user_avatar_2={user_avatar_2}
            user_avatar={user_avatar}
          />
          <div className="header-bottom">
            <span
              onClick={() => isGoForDetails && handleDetailChange()}
              style={{ color: !isGoForDetails ? "#1570EF" : "" }}
            >
              Messages
            </span>
            <span
              onClick={() => !isGoForDetails && handleDetailChange()}
              style={{ color: isGoForDetails ? "#1570EF" : "" }}
            >
              Details
            </span>
          </div>
        </div>
        {isGoForDetails ? (
          <div className="conversation-details-mobile-container">
            <ConversationDetails conversationId={conversationId} />
          </div>
        ) : (
          <>
            <div className="conversation-body">
              <Scrollbars
                style={{
                  paddingRight: 10,
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                // style={{ paddingRight: 10 }}
                autoHide
                autoHideTimeout={500}
                autoHideDuration={500}
                autoHeight
                // autoHeightMin={250}
                // autoHeightMax={250}
                // autoHeightMin={480}
                autoHeightMax={"100%"}
                thumbMinSize={10}
                universal={true}
              >
                <div className="conversation-body" id="scrollableDiv">
                  {/* This will implement it feeds.tsx file */}

                  <div className="conversation-person-1">
                    <div className="details">
                      {
                        reviewerPhoteUrl
                        ? <img
                            className="image-logo"
                            src={reviewerPhoteUrl || user_avatar}
                            alt="avatar"
                          />
                        : <img
                              className="user-avatar"
                              src={getGenerateApiImageByName(reviewerDisplayName)}
                              alt="avatar"
                          />
                      }
                      
                      <div className="description">
                        <div
                          className={`d-flex align-items-center user-ratings-container`}
                        >
                          <div className="user_name me-2">
                            {reviewerDisplayName || "User Name"}
                          </div>
                          {
                            ratingStars ? (
                              <div className="rating-social-email">
                                <div>
                                  {Array.from(Array(Number(ratingStars)).keys()).map((_: any, index: number) => {
                                    return (
                                      <img
                                        className="star"
                                        key={index}
                                        src={start}
                                        alt="star"
                                      />
                                    );
                                  })}
                                </div>
                                <div className="rating">
                                  {ratingStars}/5
                                </div>
                              </div>
                            ) : null
                          }
                        </div>

                        <div className="client-container mb-2">
                          {
                            // new Date(createdOn).toDateString() + ' '
                            // + (new Date(createdOn).getHours() < 10 ? '0' + new Date(createdOn).getHours() : new Date(createdOn).getHours())
                            // + ':' + (new Date(createdOn).getMinutes() < 10 ? '0' + new Date(createdOn).getMinutes() : new Date(createdOn).getMinutes())
                            // + ':' + (new Date(createdOn).getSeconds() < 10 ? '0' + new Date(createdOn).getSeconds() : new Date(createdOn).getSeconds()) +
                            moment(requestedDate).format("DD MMM YYYY, hh:mm:ss A") + " - " + (recieverCompany || "Company Name")
                          }
                        </div>
                        <p>{reviewComment}</p>
                        {/* <p>{requestorMessage}</p> */}
                        <div className="my-1">
                          {/* <img src={video_link} alt="video-link" /> */}
                          <div
                            style={{
                              position: "relative",
                              paddingTop: "60.25%",
                              display: videoUrl ? "block" : "none",
                            }}
                          >
                            <iframe
                              // src={`https://iframe.mediadelivery.net/embed/${conversation?.LibraryId}/${conversation?.videoId}?autoplay=false`}
                              loading="lazy"
                              src={videoUrl?.replaceAll('@', "/")}
                              style={{
                                border: "none",
                                position: "absolute",
                                top: 0,
                                height: "100%",
                                width: "100%",
                                borderRadius: 5,
                              }}
                              allow="accelerometer; gyroscope; autoplay: false; encrypted-media; picture-in-picture;"
                              allowFullScreen={true}
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="text-start mt-2 date">
                      {"Video Testimonial"}{" - "}
                      <span>
                        {
                          // new Date(createdOn).toDateString() + ' '
                          // + (new Date(createdOn).getHours() < 10 ? '0' + new Date(createdOn).getHours() : new Date(createdOn).getHours())
                          // + ':' + (new Date(createdOn).getMinutes() < 10 ? '0' + new Date(createdOn).getMinutes() : new Date(createdOn).getMinutes())
                          // + ':' + (new Date(createdOn).getSeconds() < 10 ? '0' + new Date(createdOn).getSeconds() : new Date(createdOn).getSeconds()) +
                          moment(requestedDate).format("DD MMM YYYY, hh:mm:ss A")
                          // moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + ("Tezla Inc")
                          // moment(details?.requested_time).format('Y-m-d')
                        }
                      </span>
                    </p>
                  </div>
                  {/* {workspaceSettings && (
                    <div className="conversation-person-2">
                      <div className="details">
                        <img
                          className="user-avatar"
                          src={workspaceSettings?.logo || user_avatar_2}
                          alt="user_avatar"
                        />
                        <span className="description">
                          <p>{reviewComment}</p>
                        </span>
                      </div>
                      <p className="text-end mt-2 date">
                        {person_2?.date_title || "Replied"} -{" "}
                        <span>
                          {
                            moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + (workspaceSettings?.workspaceName || "WS Name")
                            // moment(details?.requested_time).format('Y-m-d')
                          }
                        </span>
                      </p>
                    </div>
                  )} */}
                  <div className='py-5'></div>
                </div>
              </Scrollbars>
            </div>
            {/* <div className="conversation-footer">
              <div
                style={{ padding: "0 10px" }}
                className="d-flex align-items-center justify-content-between my-2"
              >
                <p className="text-start sender">
                  Sending as {workspaceSettings?.workspaceName || "WS Name"}
                </p>
                <img
                  onClick={() => setIsReply(false)}
                  src={isReply ? lock_icon : unlock_icon}
                  alt="unlock"
                />
              </div>
              <div className="message-box mb-3">
                <div className="message">
                  <div className="text-container">
                    <textarea
                      ref={textRef}
                      className=""
                      defaultValue={person_2?.message}
                      placeholder="Write here..."
                      onChange={(e: any) => {
                        const { value } = e?.target;
                        setReplyText(value);
                      }}
                      style={{ resize: "none" }}
                      autoFocus={isReply}
                    />
                  </div>
                  <div className="send-reply-action">
                    <div
                      onClick={() => {
                        if (isReply) {
                          handleReply();
                        } else {
                          setIsReply(true);
                        }
                      }}
                      // type="button"
                    >
                      {person_2?.message || isReply ? (
                        <img src={send_icon} alt="save-account" />
                      ) : person_2?.message ? (
                        <img src={edit_icon} alt="edit" />
                      ) : (
                        <img src={reply_icon} alt="reply" />
                      )}
                    </div>
                  </div>
                  <p
                    className={`click-reply-des ${isReply ? "d-none" : ""}`}
                  >
                    Click reply and start adding your reply.
                  </p>
                </div>
              </div>
            </div> */}
          </>
        )}
      </div>
    </React.Fragment>
  )
}
