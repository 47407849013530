import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./UserProfile.scss";
import Profile from "./Components/Profile";
import Notifications from "./Components/Notifications";
import { setUserProfileTab } from "./userProfileManageReducer";
import {
    getImage,
    // getGenerateApiImageByName
} from "../../../unified/image_service";
import { toggleShowMobileSettingsNavBar } from "../../../signup-login/loginReducer";

const UserProfile: React.FC = () => {
    const { userPermissions,is_show_mobile_settings_nav_bar } = useSelector((state: any) => state?.login);
    const dispatch = useDispatch();
    const history = useNavigate();
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "workspaceSettings") : null;
        // const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "generalSettings-Account") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions, history]);
    const { user_profile_tab } = useSelector((state: any) => state?.profileManageSettingState);
    const handleUserProfileTabChange = (tab?: any) => {
        dispatch(setUserProfileTab(tab));
    }
    return (
        <React.Fragment>
            <div className="button-container">
                <img className="mobile-setting-nav-bar"
                    onClick={() => dispatch(toggleShowMobileSettingsNavBar(!is_show_mobile_settings_nav_bar))}
                    src={getImage("mobile-nav-icon")}
                    alt='hum-burger'
                />
                <button>Save Changes</button>
            </div>
            <div className="body-container">
                <div className="user-profile-settings-container">
                    <div className="settings-header-title">
                        <h3>User Profile</h3>
                    </div>
                    <div className="settings-header-tab">
                        <div className={`tab ${user_profile_tab === "user-profile" ?  "active" : ""}`} onClick={() => handleUserProfileTabChange("user-profile")}>User Profile</div>
                        <div className={`tab ${user_profile_tab === "notifications" ?  "active" : ""}`} onClick={() => handleUserProfileTabChange("notifications")}>Notifications</div>
                    </div>
                    {
                        user_profile_tab === "user-profile"
                        ? <Profile/> : <Notifications/>
                    }
                </div>
            </div>
            
        </React.Fragment>
    );
};

export default UserProfile;