import Constants from "../constants";
// import Cookies from 'js-cookie';

//trigger notifications
export const triggerNotification = (data: any) => {
    return {
        type: Constants.TRIGGER_NOTIFICATIONS,
        payload: data
    };
}

//get notifications
export const getNotification = (data: any) => {
    return {
        type: Constants.SET_NOTIFICATIONS,
        payload: data
    };
}

//trigger set notifications
export const triggerSetNotification = (data: any) => {
    return {
        type: Constants.TRIGGER_SET_NOTIFICATION,
        payload: data
    };
}

//set notifications
export const setNotification = (data: any) => {
    return {
        type: Constants.SET_NOTIFICATION,
        payload: data
    };
}

// trigger create workspace modal show
export const toggleCreateWorkspaceModal = (data: any) => {
    return {
        type: Constants.Toggle_CREATE_WORKSPACE_MODAL,
        payload: data
    };
}


// open mobile drawer
export const openMobileDrawer = () => {
    return {
        type: "OPEN_MOBILE_DRAWER"
    };
}

// close mobile drawer
export const closeMobileDrawer = () => {
    return {
        type: "CLOSE_MOBILE_DRAWER"
    };
}

const initialState = {
    getNotification: [],
    setNotification: [],
    showSignUpModal: false,
    isOpenMobileDrawer: false,
}

// trigger for generating access token
export const generateAccessToken = (data?: any): any => {
    // console.log("access_user", sessionStorage.getItem("accessed_user_details"));

    if (sessionStorage.getItem("accessed_user_details")) {
        const user_details: any = JSON.parse(sessionStorage.getItem("accessed_user_details") || "");

        const token_obj = {
            // refreshToken: localStorage.getItem("refresh_token") || sessionStorage.getItem("refresh_token"),
            email: user_details?.email,
            clapupUserId: localStorage.getItem("cuid"),
            jwtToken: localStorage.getItem("access_token") || sessionStorage.getItem("access_token"),
        };
        console.log({ user_details, token_obj });
        return {
            type: Constants.GENERATE_ACCESS_TOKEN,
            payload: token_obj
        };
    } else {
        return {
            // type: Constants.GENERATE_ACCESS_TOKEN,
            // payload: token_obj
            type: null,
        }
    }
}

//Reducers

const profileReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case Constants.SET_NOTIFICATIONS:
            return { ...state, getNotification: action.payload };
        case Constants.SET_NOTIFICATION:
            return { ...state, setNotification: action.payload };
        case Constants.LOGOUT:
            return {
                ...state,
                getNotification: [],
                setNotification: [],
                showSignUpModal: false,
                isOpenMobileDrawer: false,
                access_token: "",
                refresh_token: "",
            }
        case "OPEN_MOBILE_DRAWER":
            return { ...state, isOpenMobileDrawer: true };
        case "CLOSE_MOBILE_DRAWER":
            return { ...state, isOpenMobileDrawer: false };
        default:
            return state;
    }
}

export default profileReducer;