import React, { useEffect, useState } from 'react';
import {
  // DownOutlined,
  SearchOutlined, CheckOutlined
} from "@ant-design/icons";
import { Dropdown, Input, Space, Divider, Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  // setWorkspaces,
  toggleChangeWorkspace, getRoles, setCurrentWorkspace
} from '../headerReducer';
import {
  getUserPermissions,
  // resetForChangeWorkspace
} from '../../../../signup-login/loginReducer';
import clapup_logo from "../../../../assets/icons/ClapUp-Icon.svg";
import './workspace.scss';
// import { getWorkspaceSettings } from '../../../../pages/workspace-management/workSpaceReducer';
// import { convertToClapUpUser } from '../../../../pages/user-management/userManagementReducer';
import { getGenerateApiImageByName } from '../../../../unified/image_service';
interface WorkspaceProps {
  height?: any;
  width?: any;
  marginTop?: any;
  marginRight?: any;
  isFromSearch?: any;
  isForWorkspaceLogo?: any;
}

const Workspace: React.FC<WorkspaceProps> = ({height, width, marginRight, marginTop, isFromSearch, isForWorkspaceLogo}: any) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openMobileDropdown, setOpenMobileDropdown] = useState(false);
  const [myWorkspaces, setMyWorkspaces] = useState<any[]>([]);
  const [curWorkspace, setCurtWorkspace] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const { converted_clapup_user } = useSelector((state: any) => state.userManagementState);

  const { userPermissions } = useSelector((state: any) => state?.login);
  const { workspaces, is_changed_workspace } = useSelector((state: any) => state?.headerState);
  const {workspaceSettings} = useSelector((state: any) => state?.workspaceManagementState);
  const dispatch = useDispatch();

  useEffect(() => {
    
    const clapupUserId = sessionStorage.getItem("cuid");
    const access_type = localStorage.getItem('appAccessType');
    const inviteId = localStorage.getItem("invitation_id");
    if(inviteId) {
      // console.log({workspaces});
      const user_details = sessionStorage.getItem("accessed_user_details");
      const user = JSON.parse(user_details || "{}");
      const workspaceId = localStorage.getItem("workspace_id");
      // console.log({ user, clapupUserId, inviteId, workspaceId });
      if(user && user?.email && clapupUserId && clapupUserId !== "null" && inviteId && workspaceId && access_type === 'google-login') {
        const convertTOuserDataObject: any = {
          userEmail: user?.email,
          workspaceId,
          inviteId,
          clapupUserId
        }
        console.log({ convertTOuserDataObject });
        // localStorage.removeItem("invitation_id");
        // dispatch(convertToClapUpUser(convertTOuserDataObject));
      }
      // if (!workspaces?.length) {
      //   dispatch(findWorkspaceList());
      // }
    }
    else {
      const sessionAuth = (sessionStorage.getItem('workspaceName') && sessionStorage.getItem("cuid") && sessionStorage.getItem("roleId") && sessionStorage.getItem("wuid")) ? true : false;
      // console.log({sessionAuth});
      if (sessionAuth) {
        const workspace: any = workspaces?.find(
          (w: any) => w?.roleName === "SuperAdmin"
        ) || {};
        // console.log({wuid: sessionStorage.getItem('wuid')});
        // console.log("=================", { workspace });

        const {
          // userId,
          // roleId,
          workSpaceId: workspaceId,
          roleName,
          workSpaceName,
        }: any = workspace || {};

        if (
          roleName === "SuperAdmin" &&
          sessionStorage.getItem("isWorkspaceChanged") !== "true"
        ) {
          // sessionStorage.setItem('roleName', roleName);
          // sessionStorage.setItem('roleId', roleId);
          sessionStorage.setItem('wuid', workspaceId);
          // sessionStorage.setItem('muid', googleUserId);
          sessionStorage.setItem("workspaceName", workSpaceName);
          // if (userPermissions?.length < 1) {
          //   dispatch(getUserPermissions({
          //       userId,
          //       roleId,
          //       workspaceId,
          //     })
          //   );
          //   // dispatch(getWorkspaceSettings());
          //   dispatch(getRoles({
          //     userId: workspace?.userId,
          //     roleId: workspace?.roleId,
          //     workspaceId: workspace?.workSpaceId,
          //   }));
          // }
          dispatch(setCurrentWorkspace(workspace));
          setMyWorkspaces(workspaces);
          setCurtWorkspace(workspace?.workSpaceName);
        }
        else {
          const workspace: any = workspaces?.find(
            (w: any) => w?.workSpaceId === sessionStorage.getItem("wuid")
          );
          // console.log({wuid: sessionStorage.getItem('wuid')});
          // console.log("================159=========", { workspace });

          const {
            userId,
            roleId,
            workSpaceId: workspaceId,
            // roleName,
            workSpaceName,
          }: any = workspace || {};

          if (workspace) {
            dispatch(setCurrentWorkspace(workspace));
            setMyWorkspaces(workspaces);
            setCurtWorkspace(workspace?.workSpaceName);
            // sessionStorage.setItem('roleName', roleName);
            // sessionStorage.setItem('roleId', roleId);
            sessionStorage.setItem('wuid', workspaceId);
            // sessionStorage.setItem('muid', googleUserId);
            sessionStorage.setItem("workspaceName", workSpaceName);
            if (!userPermissions?.length) {
              dispatch(getUserPermissions({
                  userId,
                  roleId,
                  workspaceId,
                })
              );
              // // dispatch(getWorkspaceSettings());
              dispatch(
                getRoles({
                  userId: workspace?.userId,
                  roleId: workspace?.roleId,
                  workspaceId: workspace?.workSpaceId,
                })
              );
            }
          }
        }
        // const workspace: any = workspaces?.find((w: any) => w?.workSpaceId === sessionStorage.getItem('wuid'));
        // setWorkspaces(workspaces);
      };
    }
  }, [workspaces?.length]);
  
  useEffect(() => {
    if(converted_clapup_user && workspaces?.length) {
      if (localStorage.getItem("workspace_id")) {
        const workspace: any = workspaces?.find(
          (w: any) => w?.workSpaceId === localStorage.getItem("workspace_id")
        );
        // console.log({wuid: sessionStorage.getItem('wuid')});
        // console.log("=================", { workspace });

        const {
          userId,
          roleId,
          workSpaceId: workspaceId,
          // roleName,
          workSpaceName,
        }: any = workspace || {};

        if (workspace) {
          // sessionStorage.setItem('roleName', roleName);
          // sessionStorage.setItem('roleId', roleId);
          sessionStorage.setItem('wuid', workspaceId);
          // sessionStorage.setItem('muid', googleUserId);
          sessionStorage.setItem("workspaceName", workSpaceName);
          if (userPermissions?.length < 1) {
            dispatch(getUserPermissions({
                userId,
                roleId,
                workspaceId,
              })
            );
            // dispatch(getWorkspaceSettings());
            dispatch(
              getRoles({
                userId: workspace?.userId,
                roleId: workspace?.roleId,
                workspaceId: workspace?.workSpaceId,
              })
              );
            }
          }
          dispatch(setCurrentWorkspace(workspace));
          setMyWorkspaces(workspaces);
          setCurtWorkspace(workspace?.workSpaceName);
      }
    }
  }, [workspaces?.length, converted_clapup_user]);
  // }, [workspaces?.length, workspaceSettings?.workspaceName, sessionStorage.getItem('cuid'), converted_clapup_user]);

  useEffect(() => {
    if (workspaces?.length && is_changed_workspace) {
      const workspace: any = workspaces.find(
        (w: any) => w?.workSpaceId === sessionStorage.getItem("wuid")
      );
      // console.log({wuid: sessionStorage.getItem('wuid')});
      // console.log("================159=========", { workspace });

      const {
        // userId,
        // roleId,
        workSpaceId: workspaceId,
        // roleName,
        workSpaceName,
      }: any = workspace || {};

      if (workspace) {
        dispatch(setCurrentWorkspace(workspace));
        setMyWorkspaces(workspaces);
        setCurtWorkspace(workspace?.workSpaceName);
        sessionStorage.setItem('wuid', workspaceId);
        sessionStorage.setItem("workspaceName", workSpaceName);
        setMyWorkspaces(workspaces);
        dispatch(toggleChangeWorkspace(false));
      }
    }
  }, [is_changed_workspace]);

  const handleWorkspaceChange = (workspace: any) => {
      // console.log({workspace});
      // const sessionAuth = (sessionStorage.getItem('workspaceName') && sessionStorage.getItem("cuid") && sessionStorage.getItem("roleId") && sessionStorage.getItem("wuid")) ? true : false;
      // console.log({sessionAuth});
      console.log({roleName: sessionStorage.getItem('workspaceName')});
      
      const {userId, roleId, workSpaceId: workspaceId, roleName, workSpaceName} = workspace;
      console.log({userId, roleId, workspaceId, workSpaceName});
      // local storage
      localStorage.setItem('wuid', workspaceId);
      localStorage.setItem('cuid', userId);
      localStorage.setItem('roleId', roleId);
      localStorage.setItem('roleName', roleName);
      localStorage.setItem('workspaceName', workSpaceName);
      // session storage
      sessionStorage.setItem('isWorkspaceChanged', "true");
      sessionStorage.setItem('cuid', userId);
      sessionStorage.setItem('roleId', roleId);
      sessionStorage.setItem('wuid', workspaceId);
      sessionStorage.setItem('roleName', roleName);
      sessionStorage.setItem('workspaceName', workSpaceName);
      // dispatch(resetForChangeWorkspace());

      // user permissions will not reset login reducer
      dispatch(getUserPermissions({
          userId,
          roleId,
          workspaceId,
      }));

      // nothing will reset header and workspace reducer 

      // dispatch(getWorkspaceSettings());
      setCurtWorkspace(workSpaceName);
      // dispatch(setCurrentWorkspace(workspace));
      // dispatch(getRoles({userId, roleId, workspaceId: workspaceId}));
      setOpenDropdown(!openDropdown);
      setOpenMobileDropdown(false);
      window.location.reload();
  }

  const handleSearch = (value: string) => {
    // console.log("value ==== ", value);
    const data = workspaces?.filter((item: any) => {
      return item?.workSpaceName?.toLowerCase().includes(value?.toLowerCase());
    });
    setMyWorkspaces(data);
  };

  const menu = (
    <div className="bg-white p-3 shadow w-100" style={{marginTop: 10}}>
      <p className="mobile-workspace">{curWorkspace}</p>
      <Divider style={{ margin: "10px 0" }} />
      <Input
        // status="warning"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          handleSearch(e?.target?.value);
        }}
        style={{ marginRight: "10px", width: "100%" }}
        placeholder="Search"
        prefix={<SearchOutlined />}
      />
      <Divider style={{ margin: "10px 0" }} />
      {myWorkspaces?.length
        ? myWorkspaces?.map((workspace: any, idx: number) => (
            <div
              key={idx}
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleWorkspaceChange(workspace);
              }}
            >
              <span>
                {workspace?.workSpaceName === curWorkspace ? (
                  <span
                    className="d-flex align-items-center gx-2"
                    style={{ color: "blue" }}
                  >
                    <span className="mb-1 me-2">
                      <CheckOutlined />
                    </span>
                    <span>{curWorkspace}</span>
                  </span>
                ) : (
                  workspace?.workSpaceName
                )}
              </span>
              <Divider style={{ margin: "10px 0" }} />
            </div>
          ))
        : null}
    </div>
  );

  // console.log({ workspaceSettings, workspaces, curWorkspace });

  return (
    <React.Fragment>
      {
        isFromSearch || isForWorkspaceLogo < 768
        ?
          <div className="workspace-mobile">
            <Space wrap>
              <Dropdown
                onOpenChange={(e: any) => {
                  setTimeout(() => {
                    setInputValue("");
                  }, 1000);
                  setMyWorkspaces(workspaces);
                  setOpenMobileDropdown(!openMobileDropdown);
                }}
                open={openMobileDropdown}
                trigger={["click"]}
                overlay={menu}
                placement="bottomLeft"
                className="ant-dropdown-workspace"
              >
                <span className="w-100 d-flex justify-content-center align-items-center">
                  {/* <img
                    className="rounded rounded-circle me-2"
                    style={{ height: "100%", width: "100%" }}
                    src={workspaceSettings?.logo || clapup_logo}
                    alt=""
                  /> */}
                  <img
                    className="rounded rounded-circle"
                    style={{ 
                      height: height || "28px",
                      width: width || "28px",
                      marginTop: marginTop || 0,
                      marginRight: marginRight || "inherit"
                    }}
                    src={workspaceSettings?.logo || getGenerateApiImageByName(curWorkspace || "WS") || clapup_logo}
                    alt=""
                  />
                </span>
              </Dropdown>
            </Space>
          </div>
        :
          <Space direction="vertical">
            <div className="workspace-lg">
              <Space wrap>
                <Dropdown
                  onOpenChange={(e: any) => {
                    setTimeout(() => {
                      setInputValue("");
                    }, 1000);
                    setMyWorkspaces(workspaces);
                    setOpenDropdown(!openDropdown);
                  }}
                  open={openDropdown}
                  trigger={["click"]}
                  overlay={menu}
                  placement="bottom"
                >
                  <button
                    title={curWorkspace || "Workspace Name"}
                    className='lg-workspace-btn'
                  >
                    <div className="w-100"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '15px',
                        overflow: 'hidden',
                      }}
                    >
                      <img
                        className="rounded rounded-circle"
                        style={{ height: 24, width: 24 }}
                        src={workspaceSettings?.logo || getGenerateApiImageByName(curWorkspace || "WS") || clapup_logo}
                        alt=""
                      />
                      {
                        curWorkspace?.length > 18 ? curWorkspace?.substr(0, 15) + "..." : curWorkspace
                      }
                    </div>
                  </button>
                </Dropdown>
              </Space>
            </div>
          </Space>
          
      }


    </React.Fragment>
  );
};

export default Workspace;