import Constants from "../../../constants";

const initialState = {
    GeneralSettings: null,
    SocialLinks: null,
    CustomLinks: null,
    all_links: [],
    isLoading: false,
    error: null,
    bioPageTemplates: [],
    is_selected_bio_page_template: false,
    embed_video_records: [],
    embed_video_next_date: [],
    embed_image_records: [],
    embed_image_next_date: [],
    Announcements: null,
    isGotBioPageSettings: false,
}


// get workspace settings
export const getBioPageSettings = () => {
    return {
        type: Constants.GET_BIO_PAGE_SETTINGS,
        payload: {
            clapupUserId: sessionStorage.getItem("cuid"),
            roleId: sessionStorage.getItem("roleId"),
            workspaceId: sessionStorage.getItem("wuid"),
        }
    };
}

export const getBioPageTemplates = () => {
    return {
        type: Constants.GET_BIO_PAGE_TEMPLATES,
        payload: {
            clapupUserId: sessionStorage.getItem("cuid"),
            roleId: sessionStorage.getItem("roleId"),
            workspaceId: sessionStorage.getItem("wuid"),
        }
    }
}

export const setBioPageTemplates = (data?: any) => {
    return {
        type: Constants.SET_BIO_PAGE_TEMPLATES,
        payload: data
    }
}

export const requestForSelectingBioPageTemplate = (templateId?: any) => {
    return {
        type: Constants.SELECT_BIO_PAGE_TEMPLATE,
        payload: {
            workspaceId: sessionStorage.getItem("wuid"),
            templateId,
        }
    }
}

export const toggleSelectedTemplate = (data: any) => {
    return {
        type: "TOGGLE_SELECT_TEMPLATE",
        payload: data
    }
}

// get bio-page links
export const getBioPageLinks = () => {
    const clapupUserId = localStorage.getItem("cuid") || sessionStorage.getItem("cuid") || null;
    const roleId = localStorage.getItem("roleId") || sessionStorage.getItem("roleId") || null;
    const workspaceId = localStorage.getItem("wuid") || sessionStorage.getItem("wuid") || null;
    return {
        type: Constants.GET_BIO_PAGE_LINKS,
        payload: {
            clapupUserId,
            roleId,
            workspaceId,
        }
    };
}

export const setBioPageSettings = (data: any) => {

    // const GeneralSettings = data?.reduce((filter_result: any, item: any) => {
    //     if (item?.linktype === "generalSettings" || item.linktype === "GeneralSettings") {
    //         filter_result.push(item);
    //     }
    //     return filter_result;

    // }, [])?.reduce((result: any, item: any) => {
    //     const { linkfield, linkfieldvalue, linktype } = item;
    //     result[linkfield] = { value: linkfieldvalue, linktype: linktype };
    //     return result;
    // }, {});

    // const SocialLinks = data?.filter((item: any) => item?.linktype === "socialLinks");
    // const CustomLinks = data?.filter((item: any) => item?.linktype === "customLinks");
    // console.log({ GeneralSettings, data });

    const dataObject = {
        GeneralSettings: data,
    };
    return {
        type: Constants.SET_BIO_PAGE_SETTINGS,
        payload: {
            ...dataObject,
            isLoading: false,
        }
    };
}

export const setBioPageLinks = (data: any) => {
    // const SocialLinks = data?.filter((item: any) => item?.linksCategory === "sociallinks");
    const SocialLinks = data?.reduce((result: any, item: any) => {
        if (item?.linksCategory === "sociallinks") {
            result.push(item);
        }
        return result;
    }, []);

    const parent_result = data?.filter((item: any) => item?.linksCategory === "customlinks" && item?.parentId === '0');
    const child_result = data?.filter((item: any) => item?.linksCategory === "customlinks" && item?.parentId !== '0');
    const CustomLinks = parent_result?.reduce((result: any, item: any) => {
        const children = child_result?.filter((child: any) => child.parentId === item.id);
        if (children.length > 0) {
            item.children = children;
        }
        result.push(item);
        return result;
    }, []);

    const parent_all_result = data?.filter((item: any) => item?.parentId === '0' || !item?.parentId);
    const all_links = parent_all_result?.reduce((result: any, item: any) => {
        const children = child_result?.filter((child: any) => child.parentId === item.id);
        if (children.length > 0) {
            item.children = children;
        }
        result.push(item);
        return result;
    }, []);
    // console.log({ SocialLinks, all_links, CustomLinks, parent_result, child_result });

    const dataObject = {
        SocialLinks,
        CustomLinks,
        all_links
    };

    return {
        type: Constants.SET_BIO_PAGE_LINKS,
        payload: {
            ...dataObject
        },
    };
}

// update bio-page settings
export const updateBioPageSettings = (data: any = null) => {
    return {
        type: Constants.UPDATE_BIO_PAGE_SETTINGS,
        payload: data
    };
}

export const setUpdatedBioPageSettingsData = (data?: any) => {
    return {
        type: Constants.SET_UPDATED_WORKSPACE_SETTING_DATA,
        payload: data
    };
}

export const addSocialLinks = (data: any, contentFile?: any) => {
    // console.log({ contentFile });
    return {
        type: Constants.ADD_EDIT_DELETE_SOCIAL_LINKS,
        payload: data,
        contentFile
    };
}

export const updateSocialLinks = (data: any, contentFile?: any) => {
    // console.log({ contentFile });

    return {
        type: Constants.ADD_EDIT_DELETE_SOCIAL_CUSTOM_LINKS,
        payload: data,
        contentFile
    };
}

export const removeSocialLinks = (data: any) => {
    return {
        type: Constants.ADD_EDIT_DELETE_SOCIAL_CUSTOM_LINKS,
        payload: data
    };
}

export const addCustomLinks = (data: any, contentFile?: any) => {
    return {
        type: Constants.ADD_EDIT_DELETE_SOCIAL_CUSTOM_LINKS,
        payload: data,
        contentFile
    };
}

export const updateCustomLinks = (data: any, contentFile?: any) => {
    return {
        type: Constants.ADD_EDIT_DELETE_SOCIAL_CUSTOM_LINKS,
        payload: data,
        contentFile
    };
}

export const removeCustomLinks = (data: any) => {
    return {
        type: Constants.ADD_EDIT_DELETE_SOCIAL_CUSTOM_LINKS,
        payload: data
    };
}

export const manageBioPageAnnouncements = (data: any, contentFile?: any) => {
    return {
        type: Constants.ADD_EDIT_DELETE_ANNOUNCEMENT,
        payload: data,
        contentFile
    };
};

export const getBioPageAnnouncements = () => {
    return {
        type: Constants.GET_BIO_PAGE_ANNOUNCEMENTS,
        payload: {
            clapupUserId: sessionStorage.getItem("cuid"),
            roleId: sessionStorage.getItem("roleId"),
            workspaceId: sessionStorage.getItem("wuid"),
        }
    };
}

export const setBioPageAnnouncements = (data: any) => {
    return {
        type: Constants.SET_BIO_PAGE_ANNOUNCEMENTS,
        payload: data
    };
}

export const getFirstTimeEmbedVideoRecords = (data?: any, nextDate?: any) => {
    let createdOn = new Date().toISOString().replace('Z', '');
    // if (nextDate?.createdOn) {
    //     createdOn = nextDate?.createdOn || new Date().toISOString().replace('Z', '');
    // }
    return {
        type: Constants.GET_FIRST_TIME_EMBED_VIDEO_RECORDS,
        payload: {
            // clapupUserId: sessionStorage.getItem("cuid"),
            // roleId: sessionStorage.getItem("roleId"),
            workspaceId: sessionStorage.getItem("wuid"),
            mediaType: "videos",
            createdOn: createdOn,
            ...data
        }
    };
}

export const setFirstTimeEmbedVideoRecords = (data: any, store_flag = "scroll") => {
    const { mediaFiles: files, nextDate } = data || {};
    // console.log({ files });

    return {
        type: Constants.SET_FIRST_TIME_EMBED_VIDEO_RECORDS,
        payload: {
            embed_video_records: files,
            embed_video_next_date: nextDate,
            store_flag
        }
    };
}

// get embed video records for media files
export const getEmbedVideoRecords = (data?: any, nextDate?: any) => {
    let createdOn = new Date().toISOString().replace('Z', '');
    if (nextDate?.createdOn) {
        createdOn = nextDate?.createdOn || new Date().toISOString().replace('Z', '');
    }
    return {
        type: Constants.GET_EMBED_VIDEO_RECORDS,
        payload: {
            // clapupUserId: sessionStorage.getItem("cuid"),
            // roleId: sessionStorage.getItem("roleId"),
            workspaceId: sessionStorage.getItem("wuid"),
            mediaType: "videos",
            createdOn: createdOn,
            ...data
        }
    };
}

export const setEmbedVideoRecords = (data?: any, store_flag = "scroll") => {
    const { mediaFiles: files, files: search_files, nextDate } = data || {};
    return {
        type: Constants.SET_EMBED_VIDEO_RECORDS,
        payload: {
            embed_video_records: files || search_files,
            embed_video_next_date: nextDate,
            store_flag
        }
    };
}

export const getFirstTimeEmbedImageRecords = (data?: any, nextDate?: any) => {
    let createdOn = new Date().toISOString().replace('Z', '');
    // if (nextDate?.createdOn) {
    //     createdOn = nextDate?.createdOn || new Date().toISOString().replace('Z', '');
    // }
    return {
        type: Constants.GET_FIRST_TIME_EMBED_IMAGE_RECORDS,
        payload: {
            // clapupUserId: sessionStorage.getItem("cuid"),
            // roleId: sessionStorage.getItem("roleId"),
            workspaceId: sessionStorage.getItem("wuid"),
            mediaType: "images",
            createdOn: createdOn,
            ...data
        }
    };
}

export const setFirstTimeEmbedImageRecords = (data: any, store_flag = "scroll") => {
    const { mediaFiles: files, nextDate } = data || {};
    // console.log({ files });

    return {
        type: Constants.SET_FIRST_TIME_EMBED_IMAGE_RECORDS,
        payload: {
            embed_image_records: files,
            embed_image_next_date: nextDate,
            store_flag
        }
    };
}

export const getEmbedImageRecords = (data?: any, nextDate?: any) => {
    let createdOn = new Date().toISOString().replace('Z', '');
    if (nextDate?.createdOn) {
        createdOn = nextDate?.createdOn || new Date().toISOString().replace('Z', '');
    }
    return {
        type: Constants.GET_EMBED_IMAGE_RECORDS,
        payload: {
            // clapupUserId: sessionStorage.getItem("cuid"),
            // roleId: sessionStorage.getItem("roleId"),
            workspaceId: sessionStorage.getItem("wuid"),
            mediaType: "images",
            createdOn: createdOn,
            ...data
        }
    };
}

export const setEmbedImageRecords = (data: any, store_flag = "scroll") => {
    const { mediaFiles: files, files: search_files, nextDate } = data || {};

    return {
        type: Constants.SET_EMBED_IMAGE_RECORDS,
        payload: {
            embed_image_records: files || search_files,
            embed_image_next_date: nextDate,
            store_flag
        }
    };
}

export const resetEmbedRecords = () => {
    return {
        type: "RESET_EMBED_RECORDS",
        payload: {
            embed_image_records: [],
            embed_image_next_date: [],
            embed_video_records: [],
            embed_video_next_date: [],
        }
    };
};

const bioPageManagementReducer = (state: any = initialState, action: any) => {
    switch (action.type) {

        // case Constants.ADD_SOCIAL_LINKS:
        //     console.log({ data: action?.payload });

        //     return {
        //         ...state,
        //         socialLinks: [...state.socialLinks, action?.payload],
        //     };
        // case Constants.UPDATE_SOCIAL_LINKS:
        //     return {
        //         ...state,
        //         socialLinks: state.socialLinks.map((item: any) => {
        //             if (item?.id === action?.payload?.id) {
        //                 return action?.payload;
        //             }
        //             return item;
        //         }),
        //     };
        // case Constants.REMOVE_SOCIAL_LINKS:
        //     return {
        //         ...state,
        //         socialLinks: state.socialLinks.filter((item: any) => item?.id !== action?.payload),
        //     };
        // case Constants.ADD_CUSTOM_LINKS:
        //     return {
        //         ...state,
        //         customLinks: [...state.customLinks, action?.payload],
        //     };
        // case Constants.UPDATE_CUSTOM_LINKS:
        //     return {
        //         ...state,
        //         customLinks: state.customLinks.map((item: any) => {
        //             if (item?.id === action?.payload?.id) {
        //                 return {
        //                     ...item,
        //                     ...action?.payload
        //                 };
        //             }
        //             return item;
        //         }),
        //     }
        case Constants.SET_BIO_PAGE_TEMPLATES:
            return {
                ...state,
                bioPageTemplates: action.payload,
            };

        case "TOGGLE_SELECT_TEMPLATE":
            return {
                ...state,
                is_selected_bio_page_template: action.payload,
            };

        case "RESET_EMBED_RECORDS":
            return {
                ...state,
                ...action.payload,
            };

        case Constants.SET_FIRST_TIME_EMBED_VIDEO_RECORDS:
            // const { embed_image_records: embed_files, embed_image_next_date: , store_flag: image_store_flag } = action?.payload || {};
            // console.log({ embed_images: action.payload.embed_image_records })
            return {
                ...state,
                embed_video_records: action.payload.embed_video_records,
                embed_video_next_date: action.payload.embed_video_next_date,
            };

        case Constants.SET_EMBED_VIDEO_RECORDS:
            const { embed_video_records, embed_video_next_date, store_flag: video_store_flag } = action?.payload || {};

            return {
                ...state,
                embed_video_records: video_store_flag === "scroll"
                    ? [...state.embed_video_records, ...embed_video_records]
                    : embed_video_records,
                embed_video_next_date: embed_video_next_date,
            };

        case Constants.SET_FIRST_TIME_EMBED_IMAGE_RECORDS:
            // const { embed_image_records: embed_files, embed_image_next_date: , store_flag: image_store_flag } = action?.payload || {};
            // console.log({ embed_images: action.payload.embed_image_records })
            return {
                ...state,
                embed_image_records: action.payload.embed_image_records,
                embed_image_next_date: action.payload.embed_image_next_date,
            };

        case Constants.SET_EMBED_IMAGE_RECORDS:
            const {
                embed_image_records,
                embed_image_next_date,
                store_flag: image_store_flag
            } = action?.payload || {};
            // console.log({ embed_image_records, embed_image_next_date, image_store_flag });

            return {
                ...state,
                embed_image_records: image_store_flag === "scroll"
                    ? [...state.embed_image_records, ...embed_image_records]
                    : embed_image_records,
                embed_image_next_date: embed_image_next_date,
            };

        case Constants.SET_BIO_PAGE_SETTINGS:
            // console.log("Bio Page Settings Data ===> ", { bio_page_setting_data: action?.payload });

            return {
                ...state,
                ...action.payload,
                isGotBioPageSettings: true,
            };

        case Constants.SET_BIO_PAGE_LINKS:
            return {
                ...state,
                ...action.payload,
            };

        case Constants.SET_BIO_PAGE_ANNOUNCEMENTS:
            return {
                ...state,
                Announcements: action.payload,
            };

        case Constants.SET_UPDATED_WORKSPACE_SETTING_DATA:
            return {
                ...state,
                isLoading: true,
            };

        case Constants.LOGOUT:
            return {
                ...state,
                isGotBioPageSettings: false,
                GeneralSettings: null,
                SocialLinks: null,
                CustomLinks: null,
                all_links: [],
                Announcements: null,
                isLoading: false,
                error: null,
                embed_video_records: [],
                embed_video_next_date: [],
                embed_image_records: [],
                embed_image_next_date: [],
                bioPageTemplates: [],
                is_selected_bio_page_template: false,
            };
        default:
            return state;
    }
}

export default bioPageManagementReducer;