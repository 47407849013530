import { Button, Modal, Switch } from 'antd';
import React, { useEffect } from 'react';
import save_icon from '../../../../assets/icons/settings/save-icon.svg';
import edit_icon from '../../../../assets/icons/settings/edit-icon.svg';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import './GeneralSettings.scss';
import { getProfileInfo, updateProfileInfoSetting } from '../../../Settings/UserProfile/userProfileManageReducer';
import { setSuccessMessage } from '../../../../unified/reducer';
import { Spinner } from 'react-bootstrap';
import Dialog from '../../../../components/Dialogs/Dialog';
const GeneralSettings: React.FC = () => {
    // Organization Name
    const [isOrganizationNameActive, setIsOrganizationNameActive] = React.useState(false);
    const [accountOrganizationName, setAccountOrganizationName] = React.useState('');
    const [isOrganizationNameError, setIsOrganizationNameError] = React.useState(false);

    // Billing Address
    const [isBillingAddressActive, setIsBillingAddressActive] = React.useState(false);
    const [accountBillingAddress, setAccountBillingAddress] = React.useState('');
    const [isBillingAddressError, setIsBillingAddressError] = React.useState(false);

    // accountType
    const [hostGuestAccountType, setHostGuestAccountType] = React.useState('Host');

    const [modal_visible, setModalVisible]: any = React.useState(false);
    const [loading, setLoading]: any = React.useState(false);
    const [pAccountType, setPAccountType]: any = React.useState(null);
    const [pAddress, setPAddress]: any = React.useState(null);
    const dispatch = useDispatch();
    const {personalInfo} = useSelector((state: any) => state?.profileManageSettingState);
    const {email, organizationName, address, accountType}: any = personalInfo || {};
    useEffect(() => {
        if (email || organizationName || address || accountType) {
            address && setAccountBillingAddress(address);
            organizationName && setAccountOrganizationName(organizationName);
            setPAccountType(accountType);
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        } else {
            setLoading(true);
            dispatch(getProfileInfo());
        }
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    } , [dispatch, personalInfo?.firstname, personalInfo?.lastname, personalInfo?.accountType, personalInfo?.email]);

    const updateGeneralSettings = (key: any, value: any) => {
        dispatch(updateProfileInfoSetting({ columnName: key, columnValue: value, clapupUserId: sessionStorage.getItem('cuid') }));
        setTimeout(() => dispatch(setSuccessMessage("Settings saved.")), 1000);
    }

    // console.log("=============",{personalInfo});
    const {userPermissions} = useSelector((state: any) => state?.login);

    const history = useNavigate();
    
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "generalSettings-Account") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions?.length]);
    
    return (
        <React.Fragment>
            <div className='lg-sub-menu h-100 p-0' style={{alignItems: 'flex-start'}}>
                {
                    loading
                    ? <div className='loading-container w-100 h-100 d-flex justify-content-center align-items-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : <div className='account-menu-container' style={{height: '90%'}}>
                        <div className="form-container h-100">
                            {/* Email */}
                            <div className="form-group">
                                <label>Registered Email</label>
                                <div className="input-container">
                                    <input
                                        defaultValue={email}
                                        readOnly={email}
                                        type="text"
                                        placeholder="Email"
                                        className={`border-none`}
                                    />
                                </div>
                            </div>

                            {/* Organization Name */}
                            <div className="form-group">
                                <label>Organization Name</label>
                                <div className='input-container'>
                                    {
                                        isOrganizationNameActive
                                        ? <input  
                                            defaultValue={accountOrganizationName || organizationName}
                                            
                                            readOnly={!isOrganizationNameActive}
                                            autoFocus={true}
                                            // onBlur={() => {
                                            //     setIsOrganizationNameActive(!isOrganizationNameActive);
                                            //     setIsOrganizationNameError(false);
                                            //     // setAccountOrganizationName("");
                                            // }}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value) {
                                                    setAccountOrganizationName(value);
                                                    setIsOrganizationNameError(false);
                                                } else {
                                                    setAccountOrganizationName("");
                                                    setIsOrganizationNameError(true);
                                                }
                                            }}
                                            type="text" 
                                            placeholder="Organization Name"
                                            className={`${isOrganizationNameError ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>{accountOrganizationName || organizationName || "Add Org. Name"}</span>
                                    }
                                    
                                    <img
                                        src={isOrganizationNameActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isOrganizationNameError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isOrganizationNameError) {
                                                if (isOrganizationNameActive && accountOrganizationName) {
                                                    setIsOrganizationNameActive(false);
                                                    updateGeneralSettings('OrganizationName', accountOrganizationName);
                                                } else {

                                                    setIsOrganizationNameActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                    
                                </div>
                            </div>

                            {/* Billing Address */}
                            <div className="form-group">
                                <label>Billing Address</label>
                                <div className='input-container'>
                                    {
                                        isBillingAddressActive
                                        ? <input  
                                            defaultValue={accountBillingAddress || address}
                                            readOnly={!isBillingAddressActive}
                                            autoFocus={true}
                                            // onBlur={() => {
                                            //     setIsBillingAddressActive(!isBillingAddressActive);
                                            //     setIsBillingAddressError(false);
                                            //     // setAccountBillingAddress("");
                                            // }}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value) {
                                                    setAccountBillingAddress(value);
                                                    setIsBillingAddressError(false);
                                                } else {
                                                    setAccountBillingAddress("");
                                                    setIsBillingAddressError(true);
                                                }
                                            }}
                                            type="text" 
                                            placeholder="Billing Address"
                                            className={`${isBillingAddressError ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>{accountBillingAddress || organizationName || "Add Billing Address"}</span>
                                    }
                                    
                                    <img
                                        src={isBillingAddressActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isBillingAddressError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isBillingAddressError) {
                                                if (isBillingAddressActive && accountBillingAddress) {
                                                    setIsBillingAddressActive(false);
                                                    updateGeneralSettings('address', accountBillingAddress);
                                                } else {
                                                    setIsBillingAddressActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                    
                                </div>
                            </div>
                            
                            {/* Account Type */}
                            <div className="form-group">
                                <label>Account Type</label>
                                <div className="host-guest-container">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            updateGeneralSettings('AccountType', 'Host');
                                            setHostGuestAccountType('Host');
                                        }}
                                        className={`host-button ${hostGuestAccountType === 'Host' ? 'button-active' : ''}`}
                                    >
                                        Host Account
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            updateGeneralSettings('AccountType', 'Guest');
                                            setHostGuestAccountType('Guest');
                                        }}
                                        className={`guest-button ${hostGuestAccountType === "Guest" ? "button-active" : ""}`}
                                    >
                                        Guest Account
                                    </button>
                                </div>
                            </div>
                        
                            {/* Delete Account */}
                            <div className='delete-account'>
                                <button onClick={() => setModalVisible(true)} type="button">Delete Account</button>
                            </div>

                            {/* <Modal
                                open={modal_visible}
                                centered
                                // style={{}}
                                // onOk={() => console.log('ok')}
                                onCancel={() => setModalVisible(false)}
                                footer={null}
                            >
                                <div className='p-5'>
                                    <h6 className='text-center py-3'>Are you sure you want to delete your account?</h6>
                                    <div className='d-flex justify-content-center'>
                                        <Button onClick={() => setModalVisible(false)} className='cancel-btn mr-2'>NO</Button>
                                        <Button onClick={() => setModalVisible(false)} className='common-btn mr-3'>OK</Button>
                                    </div>
                                </div>
                            </Modal> */}
                        </div>
                    </div>
                }
            </div>

            <div className='mobile-sub-menu w-100'>
                {
                    loading
                    ? <div className='loading-container w-100 h-100 d-flex justify-content-center align-items-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : <div className='account-menu-container'>
                        <div className="form-container">
                            {/* Email */}
                            <div className="form-group">
                                <label>Registered Email</label>
                                <div className="input-container">
                                    <input
                                        defaultValue={email}
                                        readOnly={email}
                                        type="text"
                                        placeholder="Email"
                                        className={`border-none`}
                                    />
                                </div>
                            </div>

                            {/* Organization Name */}
                            <div className="form-group">
                                <label>Organization Name</label>
                                <div className='input-container'>
                                    {
                                        isOrganizationNameActive
                                            ? <input
                                                defaultValue={accountOrganizationName || organizationName}
                                    
                                                readOnly={!isOrganizationNameActive}
                                                autoFocus={true}
                                                // onBlur={() => {
                                                //     setIsOrganizationNameActive(!isOrganizationNameActive);
                                                //     setIsOrganizationNameError(false);
                                                //     // setAccountOrganizationName("");
                                                // }}
                                                onChange={(e: any) => {
                                                    const { value } = e.target;
                                                    if (value) {
                                                        setAccountOrganizationName(value);
                                                        setIsOrganizationNameError(false);
                                                    } else {
                                                        setAccountOrganizationName("");
                                                        setIsOrganizationNameError(true);
                                                    }
                                                }}
                                                type="text"
                                                placeholder="Organization Name"
                                                className={`${isOrganizationNameError ? "input-error" : ""}`}
                                            />
                                            : <span className='input-value'>{accountOrganizationName || organizationName || "Add Org. Name"}</span>
                                    }
                            
                                    <img
                                        src={isOrganizationNameActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isOrganizationNameError ? "disabled" : ""}`}
                                        // onClick={() => {
                                        //     if (!isOrganizationNameError) {
                                        //         if (isOrganizationNameActive && accountOrganizationName) {
                                        //             setIsOrganizationNameActive(false);
                                        //             updateGeneralSettings('OrganizationName', accountOrganizationName);
                                        //         } else {

                                        //             setIsOrganizationNameActive(true);
                                        //         }
                                        //     }
                                        // }}
                                        alt='save-edit'
                                    />
                            
                                </div>
                            </div>

                            {/* Billing Address */}
                            <div className="form-group">
                                <label>Billing Address</label>
                                <div className='input-container'>
                                    {
                                        isBillingAddressActive
                                            ? <input
                                                defaultValue={accountBillingAddress || address}
                                                readOnly={!isBillingAddressActive}
                                                autoFocus={true}
                                                // onBlur={() => {
                                                //     setIsBillingAddressActive(!isBillingAddressActive);
                                                //     setIsBillingAddressError(false);
                                                //     // setAccountBillingAddress("");
                                                // }}
                                                onChange={(e: any) => {
                                                    const { value } = e.target;
                                                    if (value) {
                                                        setAccountBillingAddress(value);
                                                        setIsBillingAddressError(false);
                                                    } else {
                                                        setAccountBillingAddress("");
                                                        setIsBillingAddressError(true);
                                                    }
                                                }}
                                                type="text"
                                                placeholder="Billing Address"
                                                className={`${isBillingAddressError ? "input-error" : ""}`}
                                            />
                                            : <span className='input-value'>{accountBillingAddress || organizationName || "Add Billing Address"}</span>
                                    }
                            
                                    <img
                                        src={isBillingAddressActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isBillingAddressError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isBillingAddressError) {
                                                if (isBillingAddressActive && accountBillingAddress) {
                                                    setIsBillingAddressActive(false);
                                                    updateGeneralSettings('BillingAddress', accountBillingAddress);
                                                } else {
                                                    setIsBillingAddressActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                            
                                </div>
                            </div>
                    
                            {/* Account Type */}
                            <div className="form-group">
                                <label>Account Type</label>
                                <div className="host-guest-container">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            updateGeneralSettings('AccountType', 'Host');
                                            setHostGuestAccountType('Host');
                                        }}
                                        className={`host-button ${hostGuestAccountType === 'Host' ? 'button-active' : ''}`}
                                    >
                                        Host Account
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            updateGeneralSettings('AccountType', 'Guest');
                                            setHostGuestAccountType('Guest');
                                        }}
                                        className={`guest-button ${hostGuestAccountType === "Guest" ? "button-active" : ""}`}
                                    >
                                        Guest Account
                                    </button>
                                </div>
                            </div>
                
                            {/* Delete Account */}
                            <div className='delete-account'>
                                <button onClick={() => setModalVisible(true)} type="button">Delete Account</button>
                            </div>

                        </div>
                    </div>
                }
            </div>
            
            {/* <Modal
                open={modal_visible}
                centered
                // style={{}}
                // onOk={() => console.log('ok')}
                onCancel={() => setModalVisible(false)}
                footer={null}
            >
                <div className='p-5'>
                    <h6 className='text-center py-3'>Are you sure you want to delete your account?</h6>
                    <div className='d-flex justify-content-center'>
                        <Button onClick={() => setModalVisible(false)} className='cancel-btn mr-2'>NO</Button>
                        <Button onClick={() => setModalVisible(false)} className='common-btn mr-3'>OK</Button>
                    </div>
                </div>
            </Modal> */}
            {
                modal_visible ?
                  <Dialog
                    open={modal_visible}
                    parentCallback={(flag?: any) => {
                        console.log({flag});
                        
                        setModalVisible(flag);
                    }}
                    classes='dialog-container-content'
                    header='Delete Account'
                    warning_dialog_content={"By clicking delete button, your account will be deleted and all your data will be lost for ever."}
                    footer={true}
                    left_button_text='No, Cancel'
                    right_button_text='Yes, Continue'
                    // hover={true}
                  />
                : null
                
            }

        </React.Fragment>
    );
};
export default GeneralSettings;

{/* Account Type */}
{/* <div className='border-wrapper'>
    <div className='special-group border-custom'>
        <label>Account Type</label>
        <select
            disabled={true}
            defaultValue={pAccountType}
            name="accountType"
            style={{WebkitAppearance: 'none', MozAppearance: 'none', appearance: 'none'}} 
            className="form-input-field w-100 m-0 my-2" placeholder="Account Type"
        >
            <option key={1} value="host">Host Account</option>
            <option key={2} value="guest">Guest Account</option>
        </select>
        <button className='button' type="button">Convert to Guest</button>
    </div>
</div> */}

{/* Organization Address */}
{/* <div className='border-wrapper'>
    <div className='special-group border-custom'>
        <label>Address</label>
        <input 
            defaultValue={address}
            onChange={(e: any) => {
                setPAddress(e?.target?.value);
            }}
            readOnly={!pAddress ? true : false}
            type="text" 
            className="form-input-field w-100 m-0 my-2" 
            placeholder="Address" 
        />
        <button 
            onClick={() => {
                console.log("Click Edit");
                
                setPAddress(true);
                if (pAddress) {
                    updateGeneralSettings('address', pAddress);
                    setPAddress(null);
                }
            }}
            className='button' type="button"
        >
           {
                pAddress ? "Save" : "Edit"
           }
        </button>
    </div>
</div> */}

{/* Subscribe to news and updates */}
{/* <div className='border-wrapper'>
    <div className='special-group border-custom'>
        <label>Subscribe to news and updates</label>
        <input type="text" className="form-input-field w-100 m-0 my-2" placeholder="Product News and Updates" />
        <Switch className={`switch`} onChange={(value) => {
            console.log({value});
            setSwitchValue(value);
        }} defaultChecked={true}/>
    </div>
</div> */}