import React from 'react';
import moment from 'moment';
import user_avatar from '../../../../assets/icons/user-avatar.svg';
import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
import start from '../../../../assets/icons/star-icon.svg';
import star_half from '../../../../assets/icons/star-half-icon.svg';
import lock_icon from '../../../../assets/icons/lock-icon.svg';
import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
import { getGenerateApiImageByName } from '../../../../unified/image_service';

export default function FeedbackDetails({ workspaceSettings, getImage, conversation, unifiedDetailsData }: any) {
  const {reviewerDisplayName, reviewerPhoteUrl, requestedDate, responseTime, createdOn, reviewerEmail, starRating: ratingStars, provider} = conversation || {};
  // 1
  const { AverageRating: averageRating, Reviewer_daysFromFirstReview, ReviewsCount } = unifiedDetailsData || {};
  
  // 2 
  const {TotalRequestCount, Reviewer_AverageRating , ResponseCount } = unifiedDetailsData || {};
  console.log({ conversation, unifiedDetailsData });
  function getDateDifference(date1: any, date2: any) {
    if(!date1 || !date2) return " --- ";
    // Calculate the time difference in milliseconds
    const timeDifference = Math.abs(Number(new Date(date1)) - Number(new Date(date2)));
    console.log({ timeDifference, date1: Number(new Date(date1)), date2: Number(new Date(date2)) });
    
    // Calculate the number of days
    let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    // Calculate the remaining hours
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    // Calculate the remaining minutes
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

    let res = "";
    let years: any = 0;
    if (days > 365) {
      years = Math.floor(days / 365);
      res += years + "Years, ";
      days -= years * 365;
      res += days + "Days ";
      days = 0;
    }
    if (days > 0) {
      res += days + "Days, ";
    }
    if (!years) {
      res += hours + "Hours, " + minutes + "Minutes";
    }
    return res;
  }

  return (
    <React.Fragment>
      {/* Mobile */}
      <div className="w-100 h-100 conversation-details-mobile">
        <div className="inbox-wall w-100">
          {reviewerDisplayName ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  reviewerPhoteUrl
                  ? <img
                      className="user-avatar"
                      src={reviewerPhoteUrl || user_avatar}
                      alt="avatar"
                    />
                  : <img
                      className="user-avatar"
                      src={getGenerateApiImageByName(reviewerDisplayName)}
                      alt="avatar"
                    />
                }
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    {averageRating ? (
                      <div>
                        <span className="user_name">
                          {reviewerDisplayName || "Client"}
                        </span>
                        <span className="rating-social-email">
                          <div className='d-flex align-items-center'>
                            <div className='d-flex align-items-center'>
                              {Array.from(
                                Array(Math.floor(parseFloat(averageRating))).keys()
                              ).map((item: any, index: number) => {
                                return (
                                  <img
                                    className="start"
                                    key={index}
                                    src={start}
                                    alt="star"
                                  />
                                );
                              })}
                              <span className="ms-2">
                                {(Number(averageRating) || "0")}
                              </span>
                            </div>
                          </div>
                        </span>
                      </div>
                    ) : (
                      <span className="user_name mt-2">
                        {reviewerDisplayName ||
                          "User name"}
                      </span>
                    )}
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Reviewer_daysFromFirstReview || 0}
                    </span>
                    <span className="title">
                      Days
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {ReviewsCount || 0}
                    </span>
                    <span className="title">
                      ReviewsCount
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {averageRating || 0}
                    </span>
                    <span className="title">
                      AverageRating
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded mt-0 mb-3 d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="inbox-wall w-100">
          {conversation ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  workspaceSettings?.logo
                    ? <img
                        className="user-avatar"
                        src={workspaceSettings?.logo || user_avatar_2}
                        alt="user-2"
                      />
                    : <img
                        className="user-avatar"
                        src={getGenerateApiImageByName(workspaceSettings?.workspaceName)}
                        alt="avatar"
                      />
                      
                }
                {/* <img
                  className="user-avatar"
                  src={workspaceSettings?.logo || user_avatar_2}
                  alt="user_avatar"
                /> */}
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    <span className="user_name">
                      {workspaceSettings?.workspaceName || "Tezla Inc"}
                    </span>
                    <span className="rating-social-email">
                      <div className='d-flex align-items-center'>
                        {Array.from(Array(Math.floor(parseFloat(ratingStars)) || 1).keys()).map((item: any, index: number) => {
                          return (
                            <img
                              className="start"
                              key={index}
                              src={start}
                              alt="star"
                            />
                          );
                        })}
                        <span className="ms-2">
                          {Number(ratingStars ) || 1}
                        </span>
                      </div>
                    </span>
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100 mt-1">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(TotalRequestCount) || 0}
                    </span>

                    <span className="title">
                      Requests
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(ResponseCount || 0)}
                    </span>
                    <span className="title">
                      Response
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(Reviewer_AverageRating) || 0}
                    </span>
                    <span className="title">
                      Avg Rating
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="properties-wall w-100">
          {
              unifiedDetailsData ? (
              <div className="right-feed-card rounded p-2">
                <h4 className="text-start header-title">Properties</h4>
                <div className="content d-flex align-items-start justify-content-between">
                  <div className="type-to-lock d-flex flex-column">
                    <span>Type:</span>
                    <span>To:</span>
                    <span className="d-block">Lock:</span>
                  </div>
                  <div className="type-to-lock-value">
                    <span>
                      {"Customer Feedback"}
                    </span>
                    <span>
                      {workspaceSettings?.workspaceName || "Workspace Name"}
                    </span>
                    {
                      <span className="lock-unlock d-flex align-items-center justify-content-center pt-1 mt-1">
                        {provider === "customerFeedBack"? (
                          <span>N/A</span>
                        ) : (
                          <span>
                            Unlocked <img src={unlock_icon} alt="unlocked" />
                          </span>
                        )}
                      </span>
                    }
                  </div>
                  <div className="type-to-lock">
                    
                  </div>
                </div>
              </div>
            ) : (
              <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
                <p className="nothing-show">Nothing to show </p>
              </div>
            )
          }
        </div>
        <div className="details-wall w-100">
          {unifiedDetailsData ? (
            <div className="right-feed-card rounded p-2">
              <h4 className="text-start header-title">Timelines</h4>
              <div className="content d-flex align-items-start" style={{gap: "5%"}}>
                <div className="req-res">
                  <span>
                    Created:
                  </span>
                  <span>
                    Requested:
                  </span>
                  <span>Res. Time:</span>
                  {/* <span>
                    {conversation.hasOwnProperty("reviewerEmail")
                      ? "Replied By"
                      : null}
                    :
                  </span> */}
                </div>
                <div className="req-res-value">
                  <span>
                    {createdOn
                      ? moment(createdOn).format("ll") +
                        " " +
                        moment(createdOn).format("LT")
                      : " --- "}
                  </span>
                  <span>
                    {requestedDate
                      ? moment(requestedDate).format("ll") +
                        " " +
                        moment(requestedDate).format("LT")
                      : " --- "}
                  </span>
                  <span className="d-block">
                    {responseTime
                      ? responseTime
                      : " --- "}
                  </span>
                  {/* {reviewerEmail ? (
                    <span>{reviewerEmail}</span>
                  )  : (
                    <span>{"---"}</span>
                  )} */}
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
      </div>
      
      {/* Laptop */}
      <div className="w-100 h-100 conversation-details-lg">
        <div className="inbox-wall w-100">
          {unifiedDetailsData ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  reviewerPhoteUrl
                  ? <img
                      className="user-avatar"
                      src={reviewerPhoteUrl || user_avatar}
                      alt="avatar"
                    />
                  : <img
                      className="user-avatar"
                      src={getGenerateApiImageByName(reviewerDisplayName)}
                      alt="avatar"
                    />
                }
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    {averageRating ? (
                      <div>
                        <span className="user_name">
                          {reviewerDisplayName || "Client"}
                        </span>
                        <span className="rating-social-email">
                          <div className='d-flex align-items-center'>
                            <div className='d-flex align-items-center'>
                              {Array.from(
                                Array(Math.floor(parseFloat(averageRating))).keys()
                              ).map((item: any, index: number) => {
                                return (
                                  <img
                                    className="start"
                                    key={index}
                                    src={start}
                                    alt="star"
                                  />
                                );
                              })}
                              <span className="ms-2">
                                {(Number(averageRating) || "0")}
                              </span>
                            </div>
                          </div>
                        </span>
                      </div>
                    ) : (
                      <span className="user_name mt-2">
                        {reviewerDisplayName ||
                          "User name"}
                      </span>
                    )}
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Reviewer_daysFromFirstReview || 0}
                    </span>
                    <span className="title">
                      Days
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {
                        ReviewsCount || 0
                      }
                    </span>
                    <span className="title">
                      Reviews
                    </span>
                  </div>
                  <div className="footer-item d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {averageRating || 0}
                    </span>
                    <span className="title">
                      Avg Rating
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded mt-0 mb-3 d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="inbox-wall w-100">
          {unifiedDetailsData ? (
            <div className="right-feed-card rounded">
              <div className="d-flex align-items-start p-2">
                {
                  workspaceSettings?.logo
                    ? <img
                        className="user-avatar"
                        src={workspaceSettings?.logo || user_avatar_2}
                        alt="user-2"
                      />
                    : <img
                        className="user-avatar"
                        src={getGenerateApiImageByName(workspaceSettings?.workspaceName)}
                        alt="avatar"
                      />
                      
                }
                {/* <img
                  className="user-avatar"
                  src={workspaceSettings?.logo || user_avatar_2}
                  alt="user_avatar"
                /> */}
                <div className="user-name-provider-container">
                  <div className="ps-2 d-flex flex-column justify-content-start align-items-start">
                    <span className="user_name">
                      {workspaceSettings?.workspaceName || "Tezla Inc"}
                    </span>
                    <span className="rating-social-email">
                      <div className='d-flex align-items-center'>
                        {Array.from(Array(Math.floor(parseFloat(ratingStars)) || 1).keys()).map((item: any, index: number) => {
                          return (
                            <img
                              className="start"
                              key={index}
                              src={start}
                              alt="star"
                            />
                          );
                        })}
                        <span className="ms-2">
                          {Number(ratingStars ) || 1}
                        </span>
                      </div>
                    </span>
                  </div>
                  <img
                    className="social-logo"
                    src={getImage(provider)}
                    alt={provider}
                  />
                </div>
              </div>
              <div className="footer w-100 mt-1">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(TotalRequestCount) || 0}
                    </span>

                    <span className="title">
                      Requests
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(ResponseCount || 0)}
                    </span>
                    <span className="title">
                      Response
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <span className="value">
                      {Number(Reviewer_AverageRating) || 0}
                    </span>
                    <span className="title">
                      Avg Rating
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
        <div className="properties-wall w-100">
          {
              unifiedDetailsData ? (
              <div className="right-feed-card rounded p-2">
                <h4 className="text-start header-title">Properties</h4>
                <div className="content d-flex align-items-start justify-content-between">
                  <div className="type-to-lock d-flex flex-column">
                    <span>Type:</span>
                    <span>To:</span>
                    <span className="d-block">Lock:</span>
                  </div>
                  <div className="type-to-lock-value">
                    <span>
                      {"Customer Feedback"}
                    </span>
                    <span>
                      {workspaceSettings?.workspaceName || "Workspace Name"}
                    </span>
                    {
                      <span className="lock-unlock d-flex align-items-center justify-content-center pt-1 mt-1">
                        {provider === "customerFeedBack"? (
                          <span>N/A</span>
                        ) : (
                          <span>
                            Unlocked <img src={unlock_icon} alt="unlocked" />
                          </span>
                        )}
                      </span>
                    }
                  </div>
                  <div className="type-to-lock">
                    
                  </div>
                </div>
              </div>
            ) : (
              <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
                <p className="nothing-show">Nothing to show </p>
              </div>
            )
          }
        </div>
        <div className="details-wall w-100">
          {unifiedDetailsData ? (
            <div className="right-feed-card rounded p-2">
              <h4 className="text-start header-title">Timelines</h4>
              <div className="content d-flex align-items-start" style={{gap: "5%"}}>
                <div className="req-res">
                  <span>
                    {"createdOn" in conversation
                      ? "Created"
                      : null}
                    :
                  </span>
                  <span>
                    {conversation.hasOwnProperty("requestedDate")
                      ? "Requested"
                      : conversation.hasOwnProperty("repliedOn")
                      ? "Replied"
                      : null}
                    :
                  </span>
                  <span>Res. Time:</span>
                  {/* <span>
                    {conversation.hasOwnProperty("reviewerEmail")
                      ? "Replied By"
                      : null}
                    :
                  </span> */}
                </div>
                <div className="req-res-value">
                  <span>
                    {createdOn
                      ? moment(createdOn).format("ll") +
                        " " +
                        moment(createdOn).format("LT")
                      : " --- "}
                  </span>
                  <span>
                    {requestedDate
                      ? moment(requestedDate).format("ll") +
                        " " +
                        moment(requestedDate).format("LT")
                      : " --- "}
                  </span>
                  <span className="d-block">
                    {getDateDifference(requestedDate, createdOn)}
                  </span>
                  {/* {reviewerEmail ? (
                    <span>{reviewerEmail}</span>
                  )  : (
                    <span>{"---"}</span>
                  )} */}
                </div>
              </div>
            </div>
          ) : (
            <div className="right-feed-card rounded  d-flex align-items-center justify-content-center">
              <p className="nothing-show">Nothing to show here</p>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}
