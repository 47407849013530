import React, { useEffect, useState } from 'react';
import folder_icon from '../../../assets/icons/folder.svg';
import delete_icon from '../../../assets/icons/folder-delete-btn.svg';
// import delete_icon_pro from '../../../assets/icons/delete-btn-2.svg';
// import delete_icon_folder from '../../../assets/icons/delete-btn-2.svg';
// import '../Content.scss';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import moment from 'moment';
import { setErrorMessage } from '../../../unified/reducer';
import Dialog from '../../Dialogs/Dialog';
import { getFolders, deleteFolders } from '../mediaReducer';
import { Spinner } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { convertISODate } from '../../../utils/convertingFunction';
import { modifyCurrentString } from '../../../utils/mediaService';
export default function Folders() {
  const {
    bio_page_folders,
    all_folders,
    searched_folder_text,
    folderNextDate,
    folder_sort_type,
  }: any = useSelector((state: RootState) => state?.mediaState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route_path = window.location.pathname;
  const [folders, setFolders] = useState([]);
  const [bioPageFolders, setBioPageFolders] = useState([]);
  const [searchText, setSearchText] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  useEffect(() => {
    // console.log({folderName: all_folders?.length ? JSON.parse(all_folders[0]?.contentText || "{}")?.displayName: null});
    
    if (folders?.length) {
      if (folder_sort_type) {
        if (folder_sort_type === "Name") {
          const sorted_folder_list = folders.sort((a: any, b: any) => new Date(b?.createdOn).getTime() - new Date(a?.createdOn).getTime());
          setFolders(sorted_folder_list);
        } else {
          const sorted_folder_list = folders.sort((a: any, b: any) => {
            const aContent = JSON.parse(a?.contentText || "{}");
            const bContent = JSON.parse(b?.contentText || "{}");
            // Assuming the property you want to sort by is "folderName"
            return aContent?.folderName.localeCompare(bContent?.folderName);
          });
          setFolders(sorted_folder_list);
        }
      }
    }
    if (bioPageFolders?.length) {
      if (folder_sort_type) {
        if (folder_sort_type === "Name") {
          const sorted_folder_list = bioPageFolders.sort((a: any, b: any) => new Date(b?.createdOn).getTime() - new Date(a?.createdOn).getTime());
          setBioPageFolders(sorted_folder_list);
        } else {
          const sorted_folder_list = bioPageFolders.sort((a: any, b: any) => {
            const aContent = JSON.parse(a?.contentText || "{}");
            const bContent = JSON.parse(b?.contentText || "{}");
            // Assuming the property you want to sort by is "folderName"
            return aContent?.folderName.localeCompare(bContent?.folderName);
          });
          setBioPageFolders(sorted_folder_list);
        }
      }
    }
  }, [folder_sort_type]);

  useEffect(() => {
    // console.log({folderName: all_folders?.length ? JSON.parse(all_folders[0]?.contentText || "{}")?.displayName: null});
    
    if (all_folders?.length && all_folders?.length < 9) {
      setFolders(all_folders);
      setIsLoading(false);
    }
    setTimeout(() => setIsLoading(false), 3000);
  }, [all_folders?.length, folder_sort_type]);

  useEffect(() => {
    if (bio_page_folders?.length) {
      setBioPageFolders(bio_page_folders);
    }
  }, [bio_page_folders?.length]);

  useEffect(() => {
    if (searched_folder_text?.trim()) {
      const filtered_bio_page_folders = bio_page_folders?.filter((folder: any) => folder?.displayName?.toLowerCase().includes(searched_folder_text.toLowerCase()));
      setBioPageFolders(filtered_bio_page_folders);
      const filtered_folders = all_folders?.filter((folder: any) => folder?.displayName?.toLowerCase().includes(searched_folder_text.toLowerCase()));
      setFolders(filtered_folders);
    } else {
      if (bio_page_folders?.length) {
        setBioPageFolders(bio_page_folders);
      }
      if (all_folders?.length) {
        setFolders(all_folders);
      }
    }
  }, [searched_folder_text]);

  const [deleteFolderId, setDeleteFolderId] = useState(null);
  const handleDeleteFolder = (folderId: any) => {
    console.log({folderId});
    
    if (!folderId) {
      setDeleteFolderId(null);
    } else {
      dispatch(deleteFolders([folderId]));
      // dispatch(setInfoMessage('Folder deleted successfully.'));
      setDeleteFolderId(null);
    }
  };
  // const handleNavigate = (path: any) => {
  //   navigate(`${route_path}?${path}s`);
  // }; 

  const fetchMoreData = () => {
      setIsLoading(true);
      if (!folderNextDate) {
          setHasMore(false);
          setIsLoading(false);
          return;
      }
      setTimeout(() => {
        // // setFolders(folders.concat(folders));
        const folderPayload: any = {
            nextDate: folderNextDate,
        };

        if (searchText) {
          folderPayload.searchText = searchText;
          folderPayload.settingsId = "";
        }
        dispatch(getFolders(folderPayload));
        setIsLoading(false);
      }, 1500);
  };
  
  return (
    <React.Fragment>
      {
        deleteFolderId ?
          <Dialog
            open={deleteFolderId}
            parentCallback={(id: any) => handleDeleteFolder(id)}
            classes='dialog-container-content'
            title='Delete Folder'
            warning_dialog_content='By clicking delete button, the folder will be deleted. All the files inside this folder will be lost forever.'
            footer={true}
            left_button_text='No, Cancel'
            right_button_text='Yes, Continue'
          />
        : null
      }

      {
        !folders?.length && !bioPageFolders?.length && !isLoading ?
          <div className='d-flex justify-content-center align-items-center' style={{height: '70vh'}}>
            <p style={{fontSize: '1.5rem'}} className='text-center'>No folders found. Please create a folder</p>
          </div>
        : <div className="table-responsive">
            {/* bootstrap responsive table */}
            {/* <table className="folder-table table table-borderless sticky-top m-0">
              <thead className='folder-thead'>
                <tr>
                  <th>Name</th>
                  <th>Added Date</th>
                  <th>Created By</th>
                  <th>Delete</th>
                </tr>
                <tr style={{border: 'none !important'}}><th className='p-0'><br/></th></tr>
              </thead>
            </table> */}
            {/* <div className='infinity-scroll'> */}
            <div className='special-sm-device'>
              <InfiniteScroll
                dataLength={folders?.length}
                // className="border"
                scrollThreshold={0.9}
                // style={{ overflow: "scroll", paddingRight: 7 }}
                style={{ overflow: "scroll", paddingRight: 0 }}
                next={() => {
                    console.log(
                        `prev -> ${folders?.length}, next -> ${folders?.length + 20}`
                    );
                    fetchMoreData();
                }}
                inverse={false}
                hasMore={hasMore}
                loader={
                    isLoading && folders?.length ? (
                        <div className="d-flex justify-content-center align-items-center mt-1">
                        <Spinner
                            size="sm"
                            animation="border"
                            variant="primary"
                        />
                        </div>
                    ) : null
                }
                scrollableTarget="scrollableDiv"
                // endMessage={
                //   <p style={{ textAlign: "center" }}>
                //     <b>Yay! You have seen it all</b>
                //   </p>
                // }
                // height={"70vh"} // 43.5vh
                height={"71vh"} // 43.5vh
              >
                <table className="table table-borderless folder-table m-0">
                  {/* <thead></thead> */}
                  <thead className='folder-thead sticky-top special-lg-md-device'>
                    <tr>
                      <th>Name</th>
                      <th>Delete</th>
                    </tr>
                    {/* <tr style={{border: 'none !important'}}><th className='p-0'></th></tr> */}
                    <tr>
                      <th className='h-0 p-0'></th>
                      <th className='h-0 p-0'></th>
                      <th className='h-0 p-0'></th>
                      <th className='h-0 p-0'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      bioPageFolders?.map((folder: any, index: number) => (
                        <tr key={index} style={{verticalAlign: 'middle'}}>
                          <td
                            // style={{width: '25%', cursor: 'pointer'}}
                            style={{ cursor: 'pointer'}}
                            onClick={() => navigate(`${route_path}?${folder?.folderName}=folders`)}
                          >
                            <div className="table-image-container">
                              <img className='content-img' src={folder_icon} alt="folder-icon" />
                              <div className='title-container'>
                                <div className='content-name'>{folder?.displayName}</div>
                                <div className='content-date'>
                                  <span className='special-gen-sm-device'>{moment(convertISODate(folder?.createdOn)).format("DD MMM, YYYY")} By {folder?.createdBy}</span>
                                  <span className='special-ex-sm-device'>{moment(convertISODate(folder?.createdOn)).format("DD MMM, YYYY")}</span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <img
                              style={{ cursor: 'not-allowed' }}
                              onClick={() => dispatch(setErrorMessage("Error. Not allowed to delete"))}
                              src={delete_icon} alt="delete-icon" 
                            />
                          </td>
                        </tr>
                      ))
                    }

                    {
                      folders?.map((folder: any, index: number) => (
                        <tr key={index} style={{verticalAlign: 'middle', zIndex: 1}}>
                          <td style={{ width: '25%', cursor: 'pointer' }}
                            onClick={() => navigate(`${route_path}?${folder?.folderName}=folders`)}
                          >
                            <div className="table-image-container">
                              <img className='content-img' src={folder_icon} alt="folder-icon" />
                              <div className='title-container'>
                                <div className='content-name'>{folder?.displayName}</div>
                                <div className='content-date'>
                                <span className='special-gen-sm-device'>{moment(convertISODate(folder?.createdOn)).format("DD MMM, YYYY")} By {folder?.createdBy}</span>
                                  <span className='special-ex-sm-device'>{moment(convertISODate(folder?.createdOn)).format("DD MMM, YYYY")}</span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <img
                              onClick={() => setDeleteFolderId(folder?.id)}
                              style={{ cursor: 'pointer' }}
                              src={delete_icon} alt="delete-icon"
                            />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>

            <div className='special-lg-md-device'
              // style={{ border: '2px solid red' }}
            >
              <InfiniteScroll
                dataLength={folders?.length}
                // className="border"
                scrollThreshold={0.9}
                // style={{ overflow: "scroll", paddingRight: 7 }}
                style={{ overflow: "scroll", paddingRight: 0, paddingBottom: 70 }}
                next={() => {
                    console.log(
                        `prev -> ${folders?.length}, next -> ${folders?.length + 20}`
                    );
                    fetchMoreData();
                }}
                inverse={false}
                hasMore={hasMore}
                loader={
                    isLoading && folders?.length ? (
                        <div className="d-flex justify-content-center align-items-center mt-1">
                        <Spinner
                            size="sm"
                            animation="border"
                            variant="primary"
                        />
                        </div>
                    ) : null
                }
                scrollableTarget="scrollableDiv"
                // endMessage={
                //   <p style={{ textAlign: "center" }}>
                //     <b>Yay! You have seen it all</b>
                //   </p>
                // }
                // height={"70vh"} // 43.5vh
                // height={"73.8vh"} // 43.5vh
                // height={"65vh"} // 43.5vh
                height={"75vh"} // 43.5vh
              >
                <table className="table table-borderless folder-table m-0">
                  {/* <thead></thead> */}
                  <thead className='folder-thead sticky-top'>
                    <tr>
                      <th>Name</th>
                      <th>Added Date</th>
                      <th>Created By</th>
                      <th>Delete</th>
                    </tr>
                    {/* <tr style={{border: 'none !important'}}><th className='p-0'></th></tr> */}
                    <tr>
                      <th className='h-0 p-0'></th>
                      <th className='h-0 p-0'></th>
                      <th className='h-0 p-0'></th>
                      <th className='h-0 p-0'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      bioPageFolders?.map((folder: any, index: number) => (
                        <tr key={index} style={{verticalAlign: 'middle'}}>
                          <td
                            style={{width: '36%', cursor: 'pointer'}}
                            onClick={() => navigate(`${route_path}?${folder?.folderName}=folders`)}
                          >
                            <div className="d-flex align-items-center" style={{cursor: 'pointer'}}>
                              <img className='lg-device' src={folder_icon} alt="folder-icon" />
                              <img className='sm-device' src={folder_icon} alt="folder-icon" />
                              <span className="ms-2 mt-1">
                                {
                                  folder?.displayName?.length > 20
                                  ? modifyCurrentString(folder?.displayName, 20, "")
                                  : folder?.displayName
                                }
                              </span>
                              
                            </div>
                          </td>
                          <td
                            style={{ width: '27%', cursor: 'pointer'}}
                            onClick={() => navigate(`${route_path}?${folder?.folderName}=folders`)}
                          >
                            <span className='lg-md-device'>{moment(convertISODate(folder?.createdOn)).utc().format("hh:mm:ss A, DD MMM YYYY")}</span>
                            <span className='md-sm-device'>{moment(convertISODate(folder?.createdOn)).utc().format("DD MMM YYYY")}</span>
                          </td>
                          <td 
                            style={{ width: '32%', cursor: 'pointer' }}
                            onClick={() => navigate(`${route_path}?${folder?.folderName}=folders`)}
                          >{folder?.createdBy}</td>
                          <td style={{ width: '5%' }}>
                            <img style={{ cursor: 'not-allowed'}} onClick={() => dispatch(setErrorMessage("Error. Not allowed to delete"))} src={delete_icon} alt="delete-icon" />
                          </td>
                        </tr>
                      ))
                    }

                    {
                      folders?.map((folder: any, index: number) => (
                        <tr key={index} style={{verticalAlign: 'middle', zIndex: 1}}>
                          <td
                            style={{ width: '36%', cursor: 'pointer' }}
                            onClick={() => navigate(`${route_path}?${folder?.folderName}=folders`)}
                          >
                            <div className="d-flex align-items-center">
                              <img className='lg-device' src={folder_icon} alt="folder-icon" />
                              <img className='sm-device' src={folder_icon} alt="folder-icon" />
                              <span className="ms-2 mt-1">
                                {
                                  folder?.displayName?.length > 20
                                  ? modifyCurrentString(folder?.displayName, 20, "")
                                  : folder?.displayName
                                }
                              </span>
                            </div>
                          </td>
                          <td style={{width: '27%', cursor: 'pointer' }}
                            onClick={() => navigate(`${route_path}?${folder?.folderName}=folders`)}
                          >
                            <span className='lg-md-device'>{moment(convertISODate(folder?.createdOn)).utc().format("hh:mm:ss A, DD MMM YYYY")}</span>
                            <span className='md-sm-device'>{moment(convertISODate(folder?.createdOn)).utc().format("DD MMM YYYY")}</span>
                          </td>
                          <td style={{width: '32%', cursor: 'pointer'}} onClick={() => navigate(`${route_path}?${folder?.folderName}=folders`)} >{folder?.createdBy}</td>
                          <td style={{width: '5%'}}>
                            <img onClick={() => setDeleteFolderId(folder?.id)} style={{ cursor: 'pointer'}} src={delete_icon} alt="delete-icon" />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </div>
      }
    </React.Fragment>
  )
}
