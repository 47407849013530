// profile settings icons
import user_settings_icon from "../assets/icons/settings/user-profile/user-settings-icon.svg";
import personal_info_icon from "../assets/icons/settings/user-profile/personal-info-icon.svg";
import notifications_icon from "../assets/icons/settings/user-profile/notifications-icon.svg";
// account settings icons
import account_settings_icon from "../assets/icons/settings/user-profile/user-settings-icon.svg";
import general_settings_icon from "../assets/icons/settings/account/general-settings-icon.svg";
import feature_settings_icon from "../assets/icons/settings/account/feature-settings-icon.svg";
// workspace settings icons
import workspace_settings_icon from "../assets/icons/settings/workspace/workspace-settings-icon.svg";
import workspace_general_settings_icon from "../assets/icons/settings/workspace/general-settings-icon.svg";
import workspace_feature_settings_icon from "../assets/icons/settings/workspace/feature-settings-icon.svg";
import workspace_business_identities_icon from "../assets/icons/settings/workspace/business-identities-icon.svg";
import workspace_integrations_icon from "../assets/icons/settings/workspace/integrations-icon.svg";
// import workspace_location_icon from "../assets/icons/settings/workspace/location-icon.svg";
// workspace user management settings icons
// workspace biopage settings icons
import workspace_biopage_settings_icon from "../assets/icons/settings/workspace/biopage-settings-icon.svg";
import workspace_biopage_general_settings_icon from "../assets/icons/settings/workspace/general-settings-icon.svg";
import workspace_biopage_social_links_icon from "../assets/icons/settings/workspace/social-links-icon.svg";
import workspace_biopage_custom_links_icon from "../assets/icons/settings/workspace/custom-links-icon.svg";
import workspace_biopage_announcement_icon from "../assets/icons/settings/workspace/announcement.svg";
// bot management settings icons
import bot_management_icon from "../assets/icons/settings/bot/bot-icon.svg";

// profile management components
import ProfileManagement from "./profile-management/ProfileManagement";
import PersonalInfo from "./profile-management/Components/Personal-Info/Personal-Info";
import Notifications from "./profile-management/Components/Notifications/Notifications";
// account management components
import AccountManagement from "./account-management/AccountManagement";
// import Features from "./account-management/Components/Features/Features";
import AccountGeneralSettings from "./account-management/Components/GeneralSettings/GeneralSettings";
// workspace management components
import WorkspaceManagement from "./workspace-management/WorkspaceManagement";
import WorkspaceGeneralSettings from "./workspace-management/Components/GeneralSettings/GeneralSettings";
import WorkspaceFeatures from "./workspace-management/Components/Features/Features";
import WorkspaceBusinessIdentities from "./workspace-management/Components/BusinessIdentities/BusinessIdentities";
import WorkspaceIntegrations from "./workspace-management/Components/Integrations/Integrations";
// import Locations from "./workspace-management/Components/Locations/Locations";
// workspace user management components
// workspace biopage management components
import BiopageManagement from "./biopage-management/BiopageManagement";
import BiopageGeneralSettings from "./biopage-management/Components/GeneralSettings/GeneralSettings";
import BiopageSocialLinks from "./biopage-management/Components/SocialLinks/SocialLinks";
import BiopageCustomLinks from "./biopage-management/Components/CustomLinks/CustomLinks";
import BiopageAnnouncements from "./biopage-management/Components/Announcement/Announcements";
// bot management components
// import BotSettings from "./bot-management/Components/BotSettings/BotSettings";
import BotManagement from "./bot-management/BotManagement";
import ClapUpBotSettings from "./bot-management/Components/ClapupBotSettings/ClapUpBotSettings";


// avatar: "https://www.w3schools.com/howto/img_avatar.png"
const account_routes = [
    {
        name: "Account Settings",
        icon: account_settings_icon,
        path: "/account",
        permission: 'accountSettings',
        component: AccountManagement,
        route_children: [
            {
                name: "General Settings",
                icon: general_settings_icon,
                path: "/general-settings",
                permission: 'generalSettings-Account',
                component: AccountGeneralSettings
            },
            // {
            //     name: "Features",
            //     icon: feature_settings_icon,
            //     path: "/features",
            //     // permission: 'features-workspace',
            //     permission: 'generalSettings-Account',
            //     component: Features
            // }
        ]

    }
];

const user_routes = [
    {
        name: "User Settings",
        icon: user_settings_icon,
        path: "/user",
        permission: 'persionalSettings',
        component: ProfileManagement,
        route_children: [
            {
                name: "Personal Info",
                icon: personal_info_icon,
                path: "/personal-info",
                permission: 'persionalSettings',
                component: PersonalInfo
            },
            {
                name: "Notifications",
                icon: notifications_icon,
                path: "/notifications",
                permission: 'notifications',
                component: Notifications
            }
        ]

    }
];

const workspace_routes = [
    {
        name: "Workspace Settings",
        icon: workspace_settings_icon,
        path: "/workspace",
        permission: 'workspaceSettings',
        component: WorkspaceManagement,
        route_children: [
            {
                name: "General Settings",
                icon: workspace_general_settings_icon,
                path: "/general-settings",
                permission: 'generalSettings-WorkSpace',
                component: WorkspaceGeneralSettings
            },
            {
                name: "Features",
                icon: workspace_feature_settings_icon,
                path: "/features",
                // permission: 'features-WorkSpace',// permission should be added to workspace from the api
                permission: 'generalSettings-WorkSpace',
                component: WorkspaceFeatures
            },
            {
                name: "Business Identities",
                icon: workspace_business_identities_icon,
                path: "/business-identities",
                permission: 'businessIdentities',
                component: WorkspaceBusinessIdentities
            },
            {
                name: "Integrations",
                icon: workspace_integrations_icon,
                path: "/integrations",
                permission: 'integration',
                component: WorkspaceIntegrations
            },
            // {
            //     name: "Locations",
            //     icon: workspace_location_icon,
            //     path: "/locations",
            //     // permission: 'integration',// permission should be added to workspace from the api
            //     permission: 'generalSettings-WorkSpace',
            //     component: Locations
            // },


        ]
    },
    {
        name: "Biopage Settings",
        icon: workspace_biopage_settings_icon,
        path: "/biopage",
        permission: 'bioPageSettings',
        component: BiopageManagement,
        route_children: [
            {
                name: "General Settings",
                icon: workspace_biopage_general_settings_icon,
                path: "/general-settings",
                permission: 'generalSettings-bioPage',
                component: BiopageGeneralSettings
            },
            {
                name: "Social Links",
                icon: workspace_biopage_social_links_icon,
                path: "/social-links",
                permission: 'socialLinks',
                component: BiopageSocialLinks
            },
            {
                name: "Custom Links",
                icon: workspace_biopage_custom_links_icon,
                path: "/custom-links",
                permission: 'customLinks',
                component: BiopageCustomLinks
            },
            {
                name: "Announcement",
                icon: workspace_biopage_announcement_icon,
                path: "/announcement",
                permission: 'customLinks',// announcement permission should be added to workspace from the api
                component: BiopageAnnouncements
            }
        ]
    },
    {
        name: "Bot Management",
        icon: bot_management_icon,
        path: "/bot-management",
        permission: 'workspaceSettings',
        component: BotManagement,
        route_children: [
            {
                name: "Clapup Bot",
                icon: bot_management_icon,
                path: "/app-bot",
                permission: 'generalSettings-bioPage',
                component: ClapUpBotSettings
            },
        ]
    },
];


export { account_routes, user_routes, workspace_routes };