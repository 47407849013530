import React, { useEffect, useState } from 'react';
import edit_icon from '../../../../assets/icons/settings/edit-icon.svg';
import delete_icon from '../../../../assets/icons/settings/biopage/delete-icon.svg';
// import upload_icon from '../../../../assets/icons/settings/upload-icon.svg';
// import folder_icon from '../../../../assets/icons/settings/nested-folder.svg';
// import pinterest_icon from '../../../../assets/icons/settings/biopage/pinterest-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { manageBioPageAnnouncements, getBioPageLinks } from '../../../Settings/BioPageSettings/bioPageReducer';
// import { getProfileInfo } from '../../../Settings/UserProfile/profileManageReducer';
import { Spinner } from 'react-bootstrap';
import './Announcement.scss';
import Dialog from '../../../../components/Dialogs/Dialog';
import AnnouncementForm from './AnnouncementForm';
import { setSuccessMessage } from '../../../../unified/reducer';
import moment from 'moment';
const BiopageAnnouncements: React.FC = () => {
    const {Announcements} = useSelector((state: any) => state?.bioPageSettingState);
    const [loading, setLoading]: any = React.useState(false);
    const dispatch = useDispatch();
    const {personalInfo} = useSelector((state: any) => state?.profileManageSettingState);
    const {email}: any = personalInfo || {};
    // useEffect(() => {
    //     if (email) {
    //         setTimeout(() => {
    //             setLoading(false);
    //         }, 2000);
    //     } else {
    //         setLoading(true);
    //         dispatch(getProfileInfo());
    //     }
        // setTimeout(() => {
        //     setLoading(false);
        // }, 1000);
    // }, [dispatch, email]);
    
    // const [instagram, setInstagram] = useState<any>(null);
    // const [isInstagramFieldActivated, setIsInstagramFieldActivated] = useState<any>(false);
    // const [facebook, setFacebook] = useState<any>(null);
    // const [isFacebookFieldActivated, setIsFacebookFieldActivated] = useState<any>(false);
    // const [twitter, setTwitter] = useState<any>(null);
    // const [isTwitterFieldActivated, setIsTwitterFieldActivated] = useState<any>(false);
    // const [youtube, setYoutube] = useState<any>(null);
    // const [isYoutubeFieldActivated, setIsYoutubeFieldActivated] = useState<any>(false);
    // const [pinterest, setPinterest] = useState<any>(null);
    // const [isPinterestFieldActivated, setIsPinterestFieldActivated] = useState<any>(false);

    useEffect(() => {
        console.info("Announcement ", { Announcements});
        if (!Announcements?.length) {
            dispatch(getBioPageLinks());
            setLoading(true);
        } else {
            // console.info("Announcement ", { Announcements });
            setLoading(false);
        }

        setTimeout(() => {
            setLoading(false);
        }, 1500);

    } , [dispatch, Announcements?.length]);

    // const handleUpdateBioPageSettingField = (columnName: any, columnValue: any, callBack?: any, setState?: any) => {
    //     if(!columnValue){
    //         return setState("");
    //     }
    //     const dataObject = {
    //         columnName,
    //         columnValue,
    //         clapupId: sessionStorage.getItem('cuid'),
    //         workSpaceId: sessionStorage.getItem('wuid'),
    //         roleId: sessionStorage.getItem('roleId'),
    //         linktype: 'Announcements'
    //     }
    //     dispatch(updateBioPageSettings(dataObject));
    //     callBack && callBack();
    // }

    const {userPermissions} = useSelector((state: any) => state?.login);

    const history = useNavigate();

    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "Announcements") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions?.length]);

    const [showAddAnnouncement, setShowAddAnnouncement] = useState<boolean>(false);
    const [showEditAnnouncement, setShowEditAnnouncement] = useState<any>(null);
    const [showDeleteAnnouncement, setShowDeleteAnnouncement] = useState<any>(false);

    const handleCreateNewAnnouncement = (data: any, file?: any) => { 
        dispatch(manageBioPageAnnouncements(data, file));
        // dispatch(setSuccessMessage("Added Successfully"));
    };

    const handleUpdateAnnouncement = (data: any, file?: any) => {
        dispatch(manageBioPageAnnouncements(data, file));
        setShowEditAnnouncement(null);
        // dispatch(setSuccessMessage("Updated Successfully"));
    }

    const handleRemoveAnnouncement = (item: any) => {
        const data = {
            clapupUserId: sessionStorage.getItem('cuid'),
            workSpaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
            id: item?.id,
            contentId: item?.contentId,
            displayText: "",
            linkUrl: "",
            logoUrl: "",
            announcementText: "",
            startDate: new Date().toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0],
            // I want to use moment for 12 hours format
            startTime: moment().format('hh:mm:ss A'),
            endTime: moment().format('hh:mm:ss A'),
            mode: "delete",
        }
        // console.log({ data });
        
        dispatch(manageBioPageAnnouncements(data));
        setShowDeleteAnnouncement(false);
        // dispatch(setSuccessMessage("Deleted Successfully"));
    }

    React.useEffect(() => {
        // if(!Announcement?.length){
        //     dispatch(getBioPageSettings());
        // }
        setShowAddAnnouncement(false);
        setShowEditAnnouncement(null);
    }, [dispatch, Announcements?.length]);
    return (
        <React.Fragment>
            {
                showAddAnnouncement
                ?   <Dialog
                        open={showAddAnnouncement}
                        parentCallback={(flag?: any) => setShowAddAnnouncement(flag)}
                        classes='dialog-container-link-form'
                        header='Add Announcement'
                        footer={false}
                    >
                        <AnnouncementForm 
                            onCancel={() => setShowAddAnnouncement(false)}
                            onParentCallback={(data: any, file?: any) => {
                                handleCreateNewAnnouncement(data, file);
                            }}      
                        />
                    </Dialog>
                :   showEditAnnouncement !== null
                ?   <Dialog
                        open={showEditAnnouncement}
                        parentCallback={(flag?: any) => setShowEditAnnouncement(flag)}
                        classes='dialog-container-link-form'
                        header='Edit Announcement'
                        footer={false}
                    >
                        <AnnouncementForm 
                            link_item={showEditAnnouncement}
                            id={showEditAnnouncement?.id}
                            displayTitle={showEditAnnouncement?.displayTitle} linkUrl={showEditAnnouncement?.linkUrl}
                            announcementText={showEditAnnouncement?.announcementText}
                            from={showEditAnnouncement?.startDate} to={showEditAnnouncement?.endDate}
                            logo={showEditAnnouncement?.iconUrl} logoName={showEditAnnouncement?.iconName}
                            onCancel={() => setShowEditAnnouncement(false)}
                            onParentCallback={(data: any, file?: any) => {
                                // console.log({ data });
                                handleUpdateAnnouncement(data, file);

                            }}      
                        />
                    </Dialog>
                :   showDeleteAnnouncement 
                ?   <Dialog
                        open={showDeleteAnnouncement}
                        onCancel={() => setShowDeleteAnnouncement(false)}
                        parentCallback={(flag?: any) => {
                            handleRemoveAnnouncement(flag);
                        }}
                        classes='dialog-container-content'
                        header='Delete Announcement?'
                        warning_dialog_content={"By clicking delete button, the announcement will be deleted, and will not be visible in biopage."}
                        footer={true}
                        left_button_text='No, Cancel'
                        right_button_text='Yes, Continue'
                        // hover={true}
                    />
                : null
            }
            <div className='add-announcement-button'>
                <button
                    type="button"
                    onClick={() => { setShowAddAnnouncement(true) }}
                >
                    Add New +
                </button>
            </div>
            <div className='lg-sub-menu h-100 p-0' style={{ alignItems: 'flex-start' }}>
                {
                    loading
                    ? <div className='loading-container w-100 h-100 d-flex justify-content-center align-items-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : <div className='bio-page-menu-container'>
                        <div className="form-container announcement-link-gap">
                            {
                                Announcements?.length
                                ? Announcements?.map((item: any, index: number) => (
                                    <div key={index} className="form-group">
                                        <label>{item?.linkLabel || "Announcement"}</label>
                                        <div className='input-container'>
                                            {/* h-100 should be removed */}
                                            <div className='logo-url-container'>
                                                <img
                                                    src={item?.logoUrl?.replaceAll("@", "/")}
                                                    alt="item-icon"
                                                    // mmhk-icon-remove
                                                    // className={`rounded-circle`}
                                                />
                                                <p className='input-value'>{ item?.displayTitle }</p>
                                            </div>
                                            <div className='edit-delete-container'>
                                                <img
                                                    src={edit_icon}
                                                    alt="edit-icon"
                                                    className={``}
                                                    onClick={() => {
                                                        // console.log({ flag: "Click on edit icon" });
                                                        setShowEditAnnouncement(item);
                                                    }}
                                                />
                                                <img
                                                    src={delete_icon }
                                                    alt="delete-icon"
                                                    className={``}
                                                    onClick={() => {
                                                        console.log({ flag: "Click on delete icon" });
                                                        setShowDeleteAnnouncement(item);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : <div className='h-100 d-flex justify-content-center align-items-center'>
                                    <p>No Announcement Found</p>
                                </div>       
                            }
                        </div>
                    </div>
                }
            </div>

            <div className='mobile-sub-menu w-100'>
                {
                    loading
                    ? <div className='loading-container w-100 d-flex justify-content-center align-items-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    :  <div className='bio-page-menu-container'>
                        <div className="form-container announcement-link-gap">
                            {
                                Announcements?.length
                                ? Announcements?.map((item: any, index: number) => (
                                    <div key={index} className="form-group">
                                        <label>{item?.linkLabel || "Announcement"}</label>
                                        <div className='input-container'>
                                            {/* h-100 should be removed */}
                                            <div className='logo-url-container'>
                                                <img
                                                    src={item?.logoUrl?.replaceAll("@", "/")}
                                                    alt="item-icon"
                                                    // mmhk-icon-remove
                                                    // className={`rounded-circle`}
                                                />
                                                <p className='input-value'>{ item?.displayTitle }</p>
                                            </div>
                                            <div className='edit-delete-container'>
                                                <img
                                                    src={edit_icon}
                                                    alt="edit-icon"
                                                    className={``}
                                                    onClick={() => {
                                                        // console.log({ flag: "Click on edit icon" });
                                                        setShowEditAnnouncement(item);
                                                    }}
                                                />
                                                <img
                                                    src={delete_icon }
                                                    alt="delete-icon"
                                                    className={``}
                                                    onClick={() => {
                                                        console.log({ flag: "Click on delete icon" });
                                                        setShowDeleteAnnouncement(item);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : <div className='h-100 d-flex justify-content-center align-items-center'>
                                    <p>No Announcement Found</p>
                                </div>       
                            }
                        </div>
                    </div>
                }
            </div>
            
        </React.Fragment>
    );
};

export default BiopageAnnouncements;