import Constants from "../constants";
import Cookies from 'js-cookie';

//twitter
// mmhk-region
export const triggerTwitterLogin = () => {
    return {
        type: Constants.TWITTER_LOGIN_TRIGGER
    };
}

export const getRequestToken = (data: any) => {
    return {
        type: Constants.GET_TWITTER_REQUEST_TOKEN,
        payload: data
    };
}

export const getAccessToken = (data: any) => {
    return {
        type: Constants.TWITTER_ACCESS_TOKEN,
        payload: data
    };
}
//logout

export const triggerLogout = () => {
    return {
        type: Constants.LOGOUT
    };
}

// mmhk-region
export const setAppAccessTypeMessage = (data: any) => {
    console.log("setAppAccessType", data);
    return {
        type: Constants.SET_APP_ACCESS_TYPE_MESSAGE,
        payload: data
    };
}

export const getUserPermissions = (data: any) => {
    return {
        type: Constants?.GET_USER_PERMISSION_LIST,
        payload: data
    };
}

export const setUserPermissions = (data: any) => {
    return {
        type: Constants?.SET_USER_PERMISSION_LIST,
        payload: data
    };
}

export const triggerGoogleSignup = (data: any) => {
    return {
        type: Constants.TRIGGER_GOOGLE_SIGN_UP,
        payload: data
    };
}

export const triggerGoogleLogin = (data: any) => {

    return {
        type: Constants.TRIGGER_GOOGLE_LOGIN,
        payload: data
    };
}

export const setGoogleSignUpData = (data: any): any => {
    console.log("setGoogleSignUpData", data);

    const { success, errors, id: clapupUserId, token, refreshToken }: any = data;
    if (success) {
        if (errors?.length) {
            sessionStorage.setItem('error', errors[0]);
        }
        sessionStorage.setItem('cuid', clapupUserId);
        // sessionStorage.setItem('access_token', token);
        // sessionStorage.setItem('refresh_token', refreshToken);
        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + 60 * 60 * 1000); // 1 hour from now

        const cookieOptions: any = {
            expires: expirationDate,
            // httpOnly: true,
            httpOnly: false,
            secure: true,
            sameSite: 'strict'
        };

        Cookies.set('refreshToken', refreshToken, cookieOptions);
        Cookies.set('access_token', token, { ...cookieOptions, httpOnly: false });
        localStorage.setItem('cuid', clapupUserId);
        localStorage.setItem('access_token', token);
        localStorage.setItem('refresh_token', refreshToken);
        return {
            type: Constants.SET_GOOGLE_SIGN_UP_DATA,
            payload: data
        }
    } else {
        return {
            type: "NOTHINGS_TO_DO"
        }
    }
};

export const setLoginError = (data?: any) => {
    return {
        type: "SET_LOGIN_ERROR",
        payload: data
    }
}

export const setGoogleSignupPayload = (data: any) => {
    return {
        type: "SET_GOOGLE_SIGNUP_PAYLOAD",
        payload: data
    }
}

export const setGoogleAccessData = (data: any) => {
    // clapupUserId: "1ac61cf2-68e0-4223-b91f-d6d752162e72",
    // errors: null,
    // googleUserId: "100791156272027091046",
    // loginDetails: [{…}],
    // success: true
    const { success, errors, googleUserId, clapupUserId, loginDetails, token, refreshToken }: any = data || {};
    if (success) {
        console.log("Google Access Data", { success, errors, googleUserId, clapupUserId, loginDetails, token, refreshToken });
        if (localStorage.getItem('appAccessType') === 'google-login' || localStorage.getItem('appAccessType') === 'google-signup') {
            const details = loginDetails?.find((ld: any) => ld?.roleName === "SuperAdmin") || {};
            // const { roleId, roleName, workspaceId, workspaceName }: any = loginDetails[0] || {};
            function storeDetails(details: any) {
                console.log({ details });
                // ==== user details =====
                // After google login approval done properly this will be removed
                // const accessed_user_details: any = {
                //     "id": "100791156272027091046",
                //     "email": "3pottersmanager@gmail.com",
                //     "verified_email": true,
                //     "name": "Mehedi Hasan", "given_name": "3Potters",
                //     "family_name": "Manager",
                //     "picture": "https://lh3.googleusercontent.com/a/AAcHTtc03XaAgA8Tp5e2xit4H9RiSAP2aXucLo2gQU2hrAx6wA=s96-c",
                //     "locale": "en"
                // };
                // sessionStorage.setItem("accessed_user_details", JSON.stringify(accessed_user_details));
                // ============ X ===========
                const { roleId, roleName, workspaceId, workspaceName }: any = details;
                // I will not store there I should store after call workspace list and if super admin then store by default
                sessionStorage.setItem('cuid', clapupUserId);
                sessionStorage.setItem('roleName', roleName);
                sessionStorage.setItem('roleId', roleId);
                sessionStorage.setItem('workspaceName', workspaceName);
                // sessionStorage.setItem('access_token', token);
                // sessionStorage.setItem('refresh_token', refreshToken);
                const expirationDate = new Date();
                expirationDate.setTime(expirationDate.getTime() + 60 * 60 * 1000); // 1 hour from now

                const cookieOptions: any = {
                    // expires: expirationDate,
                    httpOnly: false,
                    secure: true,
                    sameSite: 'strict'
                };

                Cookies.set('refreshToken', refreshToken, cookieOptions);
                Cookies.set('access_token', token, { ...cookieOptions, httpOnly: false });
                localStorage.setItem('roleName', roleName);
                localStorage.setItem('cuid', clapupUserId);
                localStorage.setItem('roleId', roleId);
                localStorage.setItem('workspaceName', workspaceName);
                localStorage.setItem('access_token', token);
                localStorage.setItem('refresh_token', refreshToken);
                if (localStorage.getItem('workspace_id')) {
                    sessionStorage.setItem('workspace_id', localStorage.getItem('workspace_id') || '');
                    sessionStorage.setItem('wuid', localStorage.getItem('workspace_id') || '');
                    localStorage.setItem('wuid', localStorage.getItem('workspace_id') || '');
                } else {
                    sessionStorage.setItem('wuid', workspaceId);
                    localStorage.setItem('wuid', workspaceId);
                }
            }
            if (details?.workspaceId) {
                storeDetails(details);
            } else {
                storeDetails(loginDetails[0]);
            }
            sessionStorage.setItem('muid', googleUserId);

        }
        // localStorage.setItem('muid', googleUserId);
    }
    return {
        type: Constants.SET_GOOGLE_ACCESS_DATA,
        payload: data
    };
}

// integration
export const triggerDisconnectIntegrationConnection = (data: any) => {
    return {
        type: Constants.TRIGGER_DISCONNECT_INTEGRATION_CONNECTION,
        payload: data
    };
}

export const triggerDisconnectConnectedAccount = (data: any) => {
    return {
        type: "DISCONNECT_CONNECTED_ACCOUNT",
        payload: data
    };
}

export const getIntegrationConnectedAccounts = () => {
    const workspaceId = localStorage.getItem('wuid') || sessionStorage.getItem('wuid') || null;
    return {
        type: Constants.GET_CONNECTED_ACCOUNTS,
        payload: { workspaceId }
    };
}

export const setIntegrationConnectedAccounts = (data: any) => {
    return {
        type: Constants.SET_CONNECTED_ACCOUNTS,
        payload: data
    };
}

export const triggerGetIntegrationConnections = (data?: any) => {
    const workspaceId = localStorage.getItem('wuid') || sessionStorage.getItem('wuid') || null;
    return {
        type: Constants.TRIGGER_GET_CONNECTIONs,
        payload: {
            workspaceId
        }
    };
}

export const setIntegrationConnectionData = (data: any) => {
    return {
        type: Constants.SET_INTEGRATION_CONNECTION_DATA,
        payload: data
    };
}

export const triggerGoogleConnection = (data?: any) => {
    // console.log("triggerGoogleConnection", data);

    return {
        type: Constants.SET_GOOGLE_INTEGRATION_CONNECTION,
        payload: data
    }
}

export const triggerTwitterConnection = (data?: any) => {
    // console.log("triggerTwitterConnection", data);
    return {
        type: Constants.TRIGGER_TWITTER_INTEGRATION,
        payload: data
    }
}

export const setTwitterConnection = (data?: any) => {
    // console.log("setTwitterConnection", data);
    return {
        type: Constants.SET_TWITTER_INTEGRATION_CONNECTION,
        payload: data
    }
}

export const triggerTwitterAuthData = (data?: any) => {
    // console.log("triggerTwitterAuth", data);
    return {
        type: Constants.TRIGGER_TWITTER_AUTH_DATA,
        payload: data
    }
}

export const setTwitterAuthData = (data?: any) => {
    // console.log("setTwitterAuthData", data);
    return {
        type: Constants.SET_TWITTER_AUTH_DATA,
        payload: data
    }
}

export const triggerFacebookConnection = (data?: any) => {
    // console.log("trigger facebook Connection", data);
    return {
        type: Constants.TRIGGER_FACEBOOK_INTEGRATION,
        payload: data
    }
}

export const setFacebookConnection = (data?: any) => {
    // console.log("set facebook Connection", data);
    return {
        type: Constants.SET_FACEBOOK_INTEGRATION_CONNECTION,
        payload: data
    }
}

export const resetTwitterRequestToken = () => {
    return {
        type: Constants.RESET_TWITTER_REQUEST_TOKEN
    }
}


export const resetIntegrationConnections = () => {
    return {
        type: Constants.RESET_INTEGRATION_CONNECTIONS
    }
}

export const resetForChangeWorkspace = () => {
    return {
        type: Constants.RESET_FOR_CHANGE_WORKSPACE
    }
}

// trigger for generating access token
// export const generateAccessToken = (data?: any) => {
//     const user_details: any = JSON.parse(sessionStorage.getItem("accessed_user_details") || "");
//     console.log({ user_details });

//     const token_obj = {
//         refreshToken: localStorage.getItem("refresh_token") || sessionStorage.getItem("refresh_token"),
//         email: user_details?.email,
//         clapupUserId: localStorage.getItem("cuid"),
//         jwtToken: localStorage.getItem("access_token") || sessionStorage.getItem("access_token"),
//     };
//     return {
//         type: Constants.GENERATE_ACCESS_TOKEN,
//         payload: token_obj
//     };
// }

export const setAccessToken = (data: any) => {
    // sessionStorage.setItem("access_token", data);
    localStorage.setItem("access_token", data);
    // window.location.reload();
    return {
        type: Constants.SET_ACCESS_TOKEN,
        payload: data
    };
}

export const getWhatsAppConnectedUsers = (data: any) => {
    return {
        type: Constants.GET_WHATS_APP_CONNECTED_USERS,
        payload: data
    };
}

export const setWhatsAppConnectedUsers = (data: any) => {
    return {
        type: Constants.SET_WHATS_APP_CONNECTED_USERS,
        payload: data
    };
}

export const requestForDisconnectWhatsapp = (data: any) => {
    return {
        type: Constants.DISCONNECT_WHATS_APP,
        payload: data
    };
}

export const toggleShowMobileSettingsNavBar = (data: any) => {
    return {
        type: "TOGGLE_SHOW_MOBILE_SETTINGS_NAV_BAR",
        payload: data
    };
}


const initialState = {
    access_token: "",
    refresh_token: "",
    pagelistDetails: [],
    connectedAccounts: [],
    requestTokens: null,
    accessTokens: [],
    googleUserID: sessionStorage.getItem('muid'),
    clapupUserId: sessionStorage.getItem('clapupUserId'),
    loginUserDetails: sessionStorage.getItem('loginUserDetails'),
    // mmhk-review
    login_error: false,
    googleSignupData: null,
    googleSignupPayload: null,
    integration_type: '',
    integration_twitter: false,
    twitter_connection_data: null,
    twitter_auth_data: null,
    google_connection_data: null,
    facebook_connection_data: null,
    userPermissions: [],
    googleAccessData: null,
    accessTypeMessage: "",
    integrationConnections: [],
    whats_app_connected_users: [],
    sign_up_failed: false,
    is_show_mobile_settings_nav_bar: false,
};

//Reducers
const loginReducer = (state = initialState, action: any) => {

    switch (action.type) {
        case "TOGGLE_SHOW_MOBILE_SETTINGS_NAV_BAR":
            return { ...state, is_show_mobile_settings_nav_bar: action.payload };
        case Constants.SET_WHATS_APP_CONNECTED_USERS:
            return { ...state, whats_app_connected_users: action.payload };
        case Constants.SET_ACCESS_TOKEN:
            return { ...state, access_token: action.payload };
        case Constants.SET_TWITTER_AUTH_DATA:
            return {
                ...state,
                twitter_auth_data: action.payload
            };

        case Constants.SET_CONNECTED_ACCOUNTS:
            return {
                ...state,
                connectedAccounts: action.payload
            };

        case Constants.SET_INTEGRATION_CONNECTION_DATA:
            return {
                ...state,
                integrationConnections: action.payload
            };

        case Constants.RESET_INTEGRATION_CONNECTIONS:
            return {
                ...state,
                integrationConnections: []
            };

        case Constants.RESET_TWITTER_REQUEST_TOKEN:
            return {
                ...state,
                requestTokens: null,
                twitter_auth_data: null
            };

        case Constants.SET_FACEBOOK_INTEGRATION_CONNECTION:
            return {
                ...state,
                facebook_connection_data: action.payload,
            };

        case Constants.SET_TWITTER_INTEGRATION_CONNECTION:
            return {
                ...state,
                twitter_connection_data: action.payload,
            };

        case Constants.SET_GOOGLE_INTEGRATION_CONNECTION:
            return {
                ...state,
                google_connection_data: action.payload,
            };
        case Constants.GET_TWITTER_REQUEST_TOKEN:
            return { ...state, requestTokens: action.payload };
        case Constants.TWITTER_ACCESS_TOKEN:
            return { ...state, accessTokens: action.payload };
        // mmhk-region
        case "SET_INTEGRATION_TYPE":
            return { ...state, integration_type: action.payload };
        case Constants.SET_GOOGLE_ACCESS_DATA:
            return { ...state, googleAccessData: action.payload, access_token: action.payload?.token, refresh_token: action.payload?.refreshToken };
        case Constants?.SET_APP_ACCESS_TYPE_MESSAGE:
            return { ...state, accessTypeMessage: action.payload };
        case Constants.SET_USER_PERMISSION_LIST:
            return { ...state, userPermissions: action.payload };
        case "SET_GOOGLE_SIGNUP_PAYLOAD":
            console.log({ googleSignupPayload: action.payload });
            return {
                ...state,
                googleSignupPayload: action.payload,
            }
        case Constants.SET_GOOGLE_SIGN_UP_DATA:
            return {
                ...state,
                googleSignupData: action.payload,
                sign_up_failed: false,
            };
        case "SET_LOGIN_ERROR":
            return {
                ...state,
                login_error: true,
            }
        case Constants.LOGOUT:
            sessionStorage.clear();
            localStorage.clear();
            Cookies.remove('access_token');
            Cookies.remove('refresh_token');
            return {
                ...state,
                access_token: "",
                login_error: false,
                is_show_mobile_settings_nav_bar: false,
                refresh_token: "",
                pagelistDetails: [],
                connectedAccounts: [],
                requestTokens: null,
                accessTokens: [],
                clapupUserId: sessionStorage.getItem('clapupUserId'),
                loginUserDetails: sessionStorage.getItem('loginUserDetails'),
                // mmhk-review
                googleSignupData: null,
                googleSignupPayload: null,
                integration_type: '',
                integration_twitter: false,
                twitter_connection_data: null,
                twitter_auth_data: null,
                google_connection_data: null,
                facebook_connection_data: null,
                userPermissions: [],
                googleAccessData: null,
                accessTypeMessage: "",
                integrationConnections: [],
                sign_up_failed: false,
            };

        case "NOTHINGS_TO_DO":
            return {
                ...state,
                sign_up_failed: true,
            };
        default:
            return state;
    }
}

export default loginReducer;

