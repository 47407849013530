import React, { useEffect } from "react";
// import Layout from "../components/layout/layout";
// import FeedsComponent from "../components/feeds/feeds";
// import FeedsComponent from "../components/feeds-test/feeds";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
    changePage, toggleFirstTimeGetWallInboxData,
    triggerWallUnified, setWallUnifiedInboxPayload
} from "./reducer";
// import { useNavigate } from "react-router-dom";
import CustomerFeedbackFeeds from "../components/Feeds/feeds_customer_feedback";
// import TopTabs from "../components/layout/TopTabs";
import UnPermission from "../pages/un-permission/UnPermission";
const CustomerFeedbackComponent: React.FC = () => {
    const dispatch = useDispatch();
    const route_path = window?.location?.pathname;
    const query_string = window.location.search;
    const query_tag = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "qa" : "all";

    useEffect(() => {
        // dispatch(resetUnifiedInbox());
        
        if (!query_string?.includes("&cov=") && route_path == "/unified") {
            if (!query_string?.includes("=wall")) {
                console.log({query_string});
                dispatch(changePage(`/unified?${query_tag}=conversations`));
            }
            else {
                dispatch(changePage(`/unified?${query_tag}=wall`));
            }
        }
    }, [dispatch, route_path]);
    const { unifiedData: unified_data, wallUnifiedData: data, isWallUnifiedData, searchText, searchStartDate, searchEndDate, searchFilterStatus } = useSelector((state: RootState) => state?.unified);
    const tag = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "qa" : "all";
    const {
        // Customer Feedback
        CFAll_All, CFAll_nextDate,
        // CFReviews_CFReviews, CFReviews_CFReviewsNextDate,
    }: any = data|| {};
    
    const unifiedData:
        any[] = tag === "all" || tag === "reviews"
        ? CFAll_All
        // : tag === "reviews"
        // ? CFReviews_CFReviews
        : [];
        
    const unifiedDataKey:
        any = tag === "all"  || tag === "reviews"
        ? ['CFAll_All', 'CFAll_nextDate', 'lastSerialNoCFWall']
        // : tag === "reviews"
        // ? ['CFReviews_CFReviews', 'CFReviews_CFReviewsNextDate', 'lastSerialNoCFReviews']
        : ['CFAll_All', 'CFAll_nextDate', 'lastSerialNoCFWall'];
        
    

    const nextDate: any[]
        = tag === "all"  || tag === "reviews"
        ? CFAll_nextDate
        // : tag === "reviews"
        // ? CFReviews_CFReviewsNextDate
        : [];
        

    const { is_first_time_wall_unified_inbox } = useSelector((state: any) => state?.unified);
    // const [isFirstTime, setIsFirstTime] = React.useState(false);
    // console.log('unifiedData useEffect ===============> ', data);
    useEffect(() => {
        dispatch(toggleFirstTimeGetWallInboxData(true));
    }, [dispatch]);

    useEffect(() => {
        // console.log('unifiedPayload useEffect ===============> ', unifiedPayload);
        if(!is_first_time_wall_unified_inbox && !data && !searchText && !searchStartDate && !searchFilterStatus){
            // console.log({isFirstTime: 87, length: data, searchText, searchStartDate, searchEndDate, searchFilterStatus});
            // setIsFirstTime(true);
            dispatch(toggleFirstTimeGetWallInboxData(false));
            handleTriggerUnified(setWallUnifiedInboxPayload, true);
        }
        // else if (unifiedData?.length && !searchText && !searchStartDate && !searchFilterStatus) {
        //     // if (unifiedData?.length && (query_string?.includes("conversations") && !query_string?.includes("&"))) {
        //     // console.log("unifiedData?.length   32  ========", unifiedData?.length);
        //     // if (query_string?.includes("&")) {
        //     //     console.log("unifiedData?.length   32  ========", unifiedData?.length);
        //     //     handleTriggerUnified(triggerWallUnified, true, "32");
        //     // } 
        //     // else
        //     if (!query_string) {
        //         // console.log("unifiedData?.length 36  ========", unifiedData?.length);
        //         // handleTriggerUnified(triggerWallUnified, true, "35");
        //     }
        // }
        // else if (unifiedData?.length && searchText && searchStartDate && searchFilterStatus) {
        //     console.log({isFirstTime: 105, length: data, searchText, searchStartDate, searchEndDate, searchFilterStatus});
        //     const dataObj = {
        //         searchText,
        //         fromDate: searchStartDate,
        //         toDate: searchEndDate,
        //         readStatus: searchFilterStatus,
        //         isFirstTime: true,
        //     };
        //     handleTriggerUnified(triggerWallUnified, dataObj, "36");
        // }
        // const tag = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "googleQandA" : "all";
        // dispatch({ type: "FILTER_BY_SEARCH_TEXT", payload: "" });
    }, [
        is_first_time_wall_unified_inbox,
        // route_path, isFirstTime,
        // searchText, searchStartDate, searchEndDate, searchFilterStatus
    ]);

    const unifiedPayload:any = {
        // mappedGoogleUserId: sessionStorage.getItem('muid'),
        clapupUserId: sessionStorage.getItem('cuid'),
        roleId: sessionStorage.getItem('roleId'),
        workspaceId: sessionStorage.getItem('wuid'),
        lastSerialNoGoogleAll: 0,
        lastSerialNoGoogleReviews: 0,
        lastSerialNoGoogleQandA: 0,
        lastSerialNoFacebookAll: 0,
        lastSerialNoFacebookReviews: 0,
        lastSerialNoFacebookComments: 0,
        lastSerialNoFacebookMentions: 0,
        lastSerialNoCFReviews: 0,
        lastSerialNoVTReviews: 0,
        lastSerialNoInboxWall: 0,
        lastSerialNoCFWall: 0,
        lastSerialNoVTWall: 0,
        lastSerialNoGoogleWall: 0,
        lastSerialNoFacebookWall: 0,
        key: "CFAll_All",
        nextKey: 'CFAll_nextDate',
        googleReviewsCreatedOn: new Date().toISOString().replace('Z', ''),
        facebookReviewsCreatedOn: new Date().toISOString().replace('Z', ''),
        // twitterMentionsCreatedOn: new Date().toISOString().replace('Z', ''),
        customerFeedbackCreatedOn: new Date().toISOString().replace('Z', ''),
        videoTestimonialCreatedOn: new Date().toISOString().replace('Z', ''),
        googleQuestionsCreatedOn: new Date().toISOString().replace('Z', ''),
        facebookCommentsCreatedOn: new Date().toISOString().replace('Z', ''),
        facebookMentionsCreatedOn: new Date().toISOString().replace('Z', ''),
        
        // search
        providerType: "customerFeedback",
        searchText,
        fromDate: searchStartDate?.replace('Z', ''), 
        toDate: searchEndDate?.replace('Z', ''), 
        readStatus: searchFilterStatus,
    }

    const handleTriggerUnified = (callBack = triggerWallUnified, _?: any, line?: any) => {
        // console.log("====IsFirstTime===> ", { dataObj: _, searchText, searchStartDate });
                
        const dataObj = {
            searchText,
            fromDate: searchStartDate,
            toDate: searchEndDate,
            readStatus: searchFilterStatus,
            ..._,
            isFirstTime: _ ? true : false,
            key: unifiedDataKey[0],
            nextKey: unifiedDataKey[1],
            [unifiedDataKey[2]]: _?.isFirstTime ? 0 : unifiedData?.length ? unifiedData?.length : 0,
            lastSerialNo: _?.isFirstTime ? 0 : unifiedData?.length ? unifiedData?.length : 0
        };

        console.log("======Next line=====> ", { dataObj, searchText, searchStartDate });

        if(tag === "reviews"){
            if(unifiedData?.length && !searchText && !searchStartDate && !searchEndDate && !searchFilterStatus){
                // unifiedPayload.googleQuestionsCreatedOn = "";
                // unifiedPayload.twitterMentionsCreatedOn = "";
                delete unifiedPayload.googleQuestionsCreatedOn;
                delete unifiedPayload.twitterMentionsCreatedOn;
            }
            unifiedPayload.tag = "reviews";
            // unifiedPayload.providerType = "inboxReviews";
            const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null};
            dispatch(callBack(payload));
        } else if(tag === "qa"){
            if(unifiedData?.length && !searchText && !searchStartDate && !searchEndDate && !searchFilterStatus){
                delete unifiedPayload.googleReviewsCreatedOn;
                delete unifiedPayload.facebookReviewsCreatedOn;
                delete unifiedPayload.customerFeedbackCreatedOn;
                delete unifiedPayload.videoTestimonialCreatedOn;
                delete unifiedPayload.facebookCommentsCreatedOn;
                delete unifiedPayload.facebookMentionsCreatedOn;
                delete unifiedPayload.twitterMentionsCreatedOn;
            }
            unifiedPayload.tag = "qa";
            // unifiedPayload.providerType = "QandA";
            const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null};
            dispatch(callBack(payload));
        } else if(tag === "comments"){
            if(unifiedData?.length && !searchText && !searchStartDate && !searchEndDate && !searchFilterStatus){
                delete unifiedPayload.googleReviewsCreatedOn;
                delete unifiedPayload.facebookReviewsCreatedOn;
                delete unifiedPayload.facebookMentionsCreatedOn;
                delete unifiedPayload.customerFeedbackCreatedOn;
                delete unifiedPayload.videoTestimonialCreatedOn;
                delete unifiedPayload.googleQuestionsCreatedOn;
                delete unifiedPayload.twitterMentionsCreatedOn;
            }
            unifiedPayload.tag = "comments";
            // unifiedPayload.providerType = "comments";
            const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null}
            dispatch(callBack(payload));
        } else if(unifiedData?.length && tag === "mentions"){
            if(!searchText && !searchStartDate && !searchEndDate && !searchFilterStatus){
                delete unifiedPayload.googleReviewsCreatedOn;
                delete unifiedPayload.facebookReviewsCreatedOn;
                delete unifiedPayload.facebookCommentsCreatedOn;
                delete unifiedPayload.customerFeedbackCreatedOn;
                delete unifiedPayload.videoTestimonialCreatedOn;
                delete unifiedPayload.googleQuestionsCreatedOn;
            }
            unifiedPayload.tag = "mentions";
            // unifiedPayload.providerType = "mentions";
            const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null};
            dispatch(callBack(payload));
        } else {
            unifiedPayload.tag = "all";
            unifiedPayload.providerType = "customerFeedback";
            const payload = {...unifiedPayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null}
            dispatch(callBack(payload));
        }
    }

    const handleSearchData = (key?: any, value?: any) => {
        console.log("key, value ======> ", { key, value });
        let dataObj = {
            searchText,
            fromDate: searchStartDate,
            toDate: searchEndDate,
            readStatus: searchFilterStatus,
            isFirstTime: true,
        };
        if(key === "readStatus"){
            dispatch({ type: "FILTER_BY_READ_STATUS", payload: value });
            dataObj.readStatus = value;
            console.log({dataObj});
            handleTriggerUnified(triggerWallUnified, dataObj);
        } else if(key === "searchText"){
            dispatch({ type: "FILTER_BY_SEARCH_TEXT", payload: value });
            dataObj.searchText = value;
            console.log({dataObj});
            handleTriggerUnified(triggerWallUnified, dataObj);
        } else{
            dispatch({ type: "FILTER_BY_SEARCH_FROM_TO_DATE", payload: value });
            dataObj = {
                ...dataObj,
                ...value
            };
            console.log({dataObj});
            handleTriggerUnified(triggerWallUnified, dataObj);
        }
    };

    const {userPermissions} = useSelector((state: any) => state?.login);
    const [isPermissible, setIsPermissible] = React.useState(true);
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "customerFeedback") : null;
        if( permission?.value === 0){
            setIsPermissible(false);
        }
    }, [userPermissions?.length]);
    

    // console.log({nextDate: nextDate, data});
    
    return (
        <React.Fragment>
            {/* <TopTabs /> */}
            {
                isPermissible
                ?
                <CustomerFeedbackFeeds
                    handleTriggerData={handleTriggerUnified}
                    handleSearchData={handleSearchData}
                    triggerData={triggerWallUnified}
                    feedsContent={data}
                    type={'unified'}
                />
                : <UnPermission/>
            }
        </React.Fragment>
    )
}

export default CustomerFeedbackComponent;

