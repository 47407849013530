import React, { useEffect, useState } from 'react';
// import save_icon from '../../../../assets/icons/settings/save-icon.svg';
// import upload_icon from '../../../../assets/icons/settings/upload-icon.svg';
// import pinterest_icon from '../../../../assets/icons/settings/biopage/pinterest-icon.svg';
import edit_icon from '../../../../assets/icons/settings/edit-icon.svg';
import delete_icon from '../../../../assets/icons/settings/biopage/delete-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { addSocialLinks, getBioPageLinks, getBioPageSettings, removeSocialLinks, updateBioPageSettings, updateSocialLinks } from '../../../Settings/BioPageSettings/bioPageReducer';
// import { getProfileInfo } from '../../../Settings/UserProfile/profileManageReducer';
import { Spinner } from 'react-bootstrap';
import './SocialLinks.scss';
import Dialog from '../../../../components/Dialogs/Dialog';
import SocialLinkForm from './SocialLinkForm';
// import { setSuccessMessage } from '../../../../dashboard/reducer';
const SocialLinks: React.FC = () => {
    const {
        SocialLinks: socialLinks,
        isLoading,
        // socialLinks
    } = useSelector((state: any) => state?.bioPageSettingState);
    const [loading, setLoading]: any = React.useState(false);
    const dispatch = useDispatch();
    const {personalInfo} = useSelector((state: any) => state?.profileManageSettingState);
    const {email}: any = personalInfo || {};
    // useEffect(() => {
    //     if (email) {
    //         setTimeout(() => {
    //             setLoading(false);
    //         }, 2000);
    //     } else {
    //         setLoading(true);
    //         dispatch(getProfileInfo());
    //     }
        // setTimeout(() => {
        //     setLoading(false);
        // }, 1000);
    // }, [dispatch, email]);
    
    // const [instagram, setInstagram] = useState<any>(null);
    // const [isInstagramFieldActivated, setIsInstagramFieldActivated] = useState<any>(false);
    // const [facebook, setFacebook] = useState<any>(null);
    // const [isFacebookFieldActivated, setIsFacebookFieldActivated] = useState<any>(false);
    // const [twitter, setTwitter] = useState<any>(null);
    // const [isTwitterFieldActivated, setIsTwitterFieldActivated] = useState<any>(false);
    // const [youtube, setYoutube] = useState<any>(null);
    // const [isYoutubeFieldActivated, setIsYoutubeFieldActivated] = useState<any>(false);
    // const [pinterest, setPinterest] = useState<any>(null);
    // const [isPinterestFieldActivated, setIsPinterestFieldActivated] = useState<any>(false);

    useEffect(() => {
        console.info("SocialLinks ", { socialLinks});
        if (!socialLinks?.length) {
            dispatch(getBioPageLinks());
            setLoading(true);
        } else {
            // console.info("SocialLinks ", { socialLinks });
            setLoading(false);
        }

        setTimeout(() => {
            setLoading(false);
        }, 1500);

    } , [dispatch, SocialLinks?.length]);

    // const handleUpdateBioPageSettingField = (columnName: any, columnValue: any, callBack?: any, setState?: any) => {
    //     if(!columnValue){
    //         return setState("");
    //     }
    //     const dataObject = {
    //         columnName,
    //         columnValue,
    //         clapupId: sessionStorage.getItem('cuid'),
    //         workSpaceId: sessionStorage.getItem('wuid'),
    //         roleId: sessionStorage.getItem('roleId'),
    //         linktype: 'socialLinks'
    //     }
    //     dispatch(updateBioPageSettings(dataObject));
    //     callBack && callBack();
    // }

    const {userPermissions} = useSelector((state: any) => state?.login);

    const history = useNavigate();

    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "socialLinks") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions?.length]);

    const [showAddSocial, setShowAddSocial] = useState<boolean>(false);
    const [showEditSocial, setShowEditSocial] = useState<any>(null);
    const [showDeleteSocial, setShowDeleteSocial] = useState<any>(false);

    const handleCreateNewSocialLink = (linkData: any, file?: any) => { 
        dispatch(addSocialLinks(linkData, file));
        // dispatch(setSuccessMessage("Added Successfully"));
    };

    const handleUpdateSocialLink = (linkData: any, file?: any) => {
        dispatch(updateSocialLinks(linkData, file));
        setShowEditSocial(null);
        // dispatch(setSuccessMessage("Updated Successfully"));
    }

    const handleRemoveSocialLink = (link_item: any) => {
        // console.log({link_item});
        
        const data = {
            clapupUserId: sessionStorage.getItem('cuid'),
            workSpaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
            id: link_item?.id,
            contentId: link_item?.contentId,
            // displayText: "",
            // linkUrl: "",
            // label: "",
            // iconUrl: "",
            // iconName: "",
            // parentId: "",
            linkType: 7,
            mode: "delete",
            linkCategory: "sociallinks"
        }
        dispatch(removeSocialLinks(data));
        setShowDeleteSocial(false);
        // dispatch(setSuccessMessage("Deleted Successfully"));
    }

    React.useEffect(() => {
        // if(!SocialLinks?.length){
        //     dispatch(getBioPageSettings());
        // }
        setShowAddSocial(false);
        setShowEditSocial(null);
    }, [dispatch, socialLinks?.length]);
    return (
        <React.Fragment>
            {
                showAddSocial
                ?   <Dialog
                        open={showAddSocial}
                        parentCallback={(flag?: any) => setShowAddSocial(flag)}
                        classes='dialog-container-link-form'
                        header='Create New Social Link'
                        footer={false}
                    >
                        <SocialLinkForm 
                            label={''} url={''} logoName={''} logo={undefined}
                            onCancel={() => setShowAddSocial(false)}
                            onParentCallback={(data: any, file?: any) => {
                                handleCreateNewSocialLink(data, file);
                            }}      
                    />
                    </Dialog>
                :   showEditSocial !== null
                ?   <Dialog
                        open={showEditSocial}
                        parentCallback={(flag?: any) => setShowEditSocial(flag)}
                        classes='dialog-container-link-form'
                        header='Edit Social Link'
                        footer={false}
                    >
                        <SocialLinkForm 
                            link_item={showEditSocial}
                            id={showEditSocial?.id}
                            label={showEditSocial?.linkLabel} url={showEditSocial?.linkUrl} 
                            logo={showEditSocial?.iconUrl} logoName={showEditSocial?.iconName}
                            onCancel={() => setShowEditSocial(false)}
                            onParentCallback={(data: any, file?: any) => {
                                // handleCreateNewSocialLink(data);
                                // dispatch(updateSocialLinks(data));
                                handleUpdateSocialLink(data, file);
                                setShowEditSocial(null);

                            }}      
                        />
                    </Dialog>
                :   showDeleteSocial 
                ?   <Dialog
                        open={showDeleteSocial}
                        onCancel={() => setShowDeleteSocial(false)}
                        parentCallback={(flag?: any) => {
                            handleRemoveSocialLink(flag);
                        }}
                        classes='dialog-container-content'
                        header='Delete Link?'
                        warning_dialog_content={"By clicking delete button, the link will be deleted, and will not be visible in biopage."}
                        footer={true}
                        left_button_text='No, Cancel'
                        right_button_text='Yes, Continue'
                        // hover={true}
                    />
                : null
            }
            <div className='add-social-button'>
                <button
                    type="button"
                    onClick={() => { setShowAddSocial(true) }}
                >
                    Add a Social +
                </button>
            </div>
            <div className='lg-sub-menu h-100 p-0' style={{ alignItems: 'flex-start' }}>
                {
                    loading
                    ? <div className='loading-container w-100 h-100 d-flex justify-content-center align-items-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : <div className='bio-page-menu-container'>
                        <div className="form-container social-link-gap">
                            {
                                socialLinks?.length
                                ? socialLinks?.map((item: any, index: number) => (
                                    <div key={index} className="form-group">
                                        <label>{item?.linkLabel}</label>
                                        <div className='input-container'>
                                            {/* h-100 should be removed */}
                                            <div className='logo-url-container'>
                                                <img
                                                    src={item?.iconUrl?.replaceAll("@", "/")}
                                                    alt="item-icon"
                                                    // mmhk-icon-remove
                                                    // className={`rounded-circle`}
                                                />
                                                <p className='input-value'>{ item?.linkUrl }</p>
                                            </div>
                                            <div className='edit-delete-container'>
                                                <img
                                                    src={edit_icon}
                                                    alt="edit-icon"
                                                    className={``}
                                                    onClick={() => {
                                                        // console.log({ flag: "Click on edit icon" });
                                                        setShowEditSocial(item);
                                                    }}
                                                />
                                                <img
                                                    src={delete_icon }
                                                    alt="delete-icon"
                                                    className={``}
                                                    onClick={() => {
                                                        console.log({ flag: "Click on delete icon" });
                                                        setShowDeleteSocial(item);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : <div className='h-100 d-flex justify-content-center align-items-center'>
                                    <p>No Social Links Found</p>
                                </div>       
                            }
                        </div>
                    </div>
                }
            </div>
            <div className='mobile-sub-menu w-100'>
                {
                    loading
                        ? <div className='loading-container w-100 d-flex justify-content-center align-items-center'>
                            <Spinner animation="border" variant="primary" />
                        </div>
                        : <div className='bio-page-menu-container'>
                            <div className="form-container social-link-gap">
                                {
                                    socialLinks?.length
                                    ? socialLinks?.map((item: any, index: number) => (
                                        <div key={index} className="form-group">
                                            <label>{item?.linkLabel}</label>
                                            <div className='input-container'>
                                                {/* h-100 should be removed */}
                                                <div className='logo-url-container'>
                                                    <img
                                                        src={item?.iconUrl?.replaceAll("@", "/")}
                                                        alt="item-icon"
                                                        // mmhk-icon-remove
                                                        // className={`rounded-circle`}
                                                    />
                                                    <p className='input-value'>{ item?.linkUrl }</p>
                                                </div>
                                                <div className='edit-delete-container'>
                                                    <img
                                                        src={edit_icon}
                                                        alt="edit-icon"
                                                        className={``}
                                                        onClick={() => {
                                                            // console.log({ flag: "Click on edit icon" });
                                                            setShowEditSocial(item);
                                                        }}
                                                    />
                                                    <img
                                                        src={delete_icon }
                                                        alt="delete-icon"
                                                        className={``}
                                                        onClick={() => {
                                                            console.log({ flag: "Click on delete icon" });
                                                            setShowDeleteSocial(item);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    : <div className='h-100 d-flex justify-content-center align-items-center'>
                                        <p>No Social Links Found</p>
                                    </div>       
                                }
                            </div>
                        </div>
                }
            </div>
        </React.Fragment>
    );
};

export default SocialLinks;