import React from 'react'
import PermissionCheck from '../components/PermissionCheck';
import { Link, useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';

interface MyComponentProps {
    tab_items?: any[]; // Or you can use a more specific type instead of 'any[]'
    path_name?: string;
    parent_routes?: any;
  }

const ParentPageTabs: React.FC<MyComponentProps> = ({parent_routes, path_name }) => {
    return (
        parent_routes.map((route: any, index: number) => (
            route.route_children?.length > 0 
            ? <PermissionCheck key={index} permissionName={route?.permission}>
                <Link key={index} to={route.path + route.route_children[0].path}
                    className={`parent-tab-link ${path_name?.includes(route.path) ? "active" : ""}`}
                >
                    <img src={route.icon} alt="tab-icon" />
                    <p>{route.name}</p>
                </Link>
            </PermissionCheck>
            : <PermissionCheck key={index} permissionName={route?.permission}>
                <Link key={index} to={route.path}
                    className={`parent-tab-link ${path_name?.includes(route.path) ? "active" : ""}`}
                >
                    <img src={route.icon} alt="tab-icon" />
                    <p>{route.name}</p>
                </Link>
            </PermissionCheck>
        ))
    )
};

const ChildPageTabs: React.FC<MyComponentProps> = ({parent_routes, path_name }) => {
    return (
        parent_routes.map((route: any, index: number) => (
            path_name?.includes(route.path)
            ? <div className="child-tabs" key={index}>
                <div className={`child-tabs-container`} key={index}>
                    {
                        route.route_children.map((child: any, idx: number) => (
                            <PermissionCheck key={idx} permissionName={child?.permission}>
                                <Link key={idx} to={route.path + child.path}
                                    className={`child-tab-link ${path_name?.includes(child.path) ? "active" : ""}`}
                                >
                                    <img src={child.icon} alt="tab-icon" />
                                    <p>{child.name}</p>
                                </Link>
                            </PermissionCheck>
                        ))
                    }
                </div>
                <div
                    className="child-tab-components"
                    style={{
                        padding:
                            path_name?.includes('/user/personal-info') || path_name?.includes('/user/notifications')
                            ? '0'
                            // : path_name?.includes('/biopage') ? '0 3% 1% 2%'
                            : "0 2% 1% 2%"
                    }}
                >
                    <div className='scroll-section-hover'>
                        {
                            route.route_children.map((child: any) => (
                            path_name?.includes(child.path)
                            ? <PermissionCheck key={child.path} permissionName={child?.permission}><child.component/></PermissionCheck>
                            : null
                        ))}
                    </div>
                </div>
            </div>
            : null
        ))
    )
}

const MobilePageTabs: React.FC<MyComponentProps> = (props) => {
    const { tab_items, path_name } = props;
    const history = useNavigate();
    return (
        <Tabs
            defaultActiveKey={path_name}
            centered
            onChange={(k: any) => {
                // console.log({ key: k });
                history(`${k}`);
            }}
            items={tab_items?.map((item: any) => {
                const id = item?.name;
                return {
                    label: item?.label,
                    key: id,
                    children: <PermissionCheck permissionName={item?.permission}><item.component/></PermissionCheck>,
                }})
            }
        />
    )
}

export { ParentPageTabs, ChildPageTabs, MobilePageTabs };
