// Functional component
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCountryWithCode, resetRequestReviewData, setSuccessMessage, triggerRequestReview } from '../../unified/reducer';
import { Form, Input, Button, Checkbox, Select } from 'antd';
import { api_url } from '../../utils/server';
const { TextArea } = Input;
const { Option } = Select;
interface Props {
    children?: any;
    callback?: any;
}

const RequestReview: React.FC<Props> = ({ callback }) => {
    const dispatch = useDispatch();
    const { requestReviewData, locations } = useSelector(
      (state: any) => state?.unified
    );
    const [form] = Form.useForm();
    const [loginUser, setLoginUser] = React.useState<any>(null);
    // const [isSMS, setIsSMS] = React.useState(true);
    const login_user_details = sessionStorage.getItem("accessed_user_details");
    const path = window.location.pathname;
    useEffect(() => {
        console.log({ login_user_details: loginUser });
        if (login_user_details) {
            setLoginUser(JSON.parse(login_user_details));
        }

        console.log({ requestReviewData });
        if (requestReviewData) {
            dispatch(resetRequestReviewData());
            form.resetFields();
            callback(false);
            dispatch(setSuccessMessage("Request sent successfully!"));
            // setTimeout(() => {
            //     dispatch(resetSuccessMessage());
            // }, 3000);
            // form.setFieldsValue({
            //     email: requestReviewData?.email,
            //     phone: requestReviewData?.phone,
            //     name: requestReviewData?.name,
            //     location: requestReviewData?.location,
            //     message: requestReviewData?.message,
            // });
        }
    }, [requestReviewData]);
    
    useEffect(() => {
      dispatch(getCountryWithCode(path?.includes("google") ? "google" : path?.includes("facebook") ? "facebook" : null));
    }, [locations?.length]);

    const handleFinish = (values: any) => {
      // const {sms} = values;
      // if(!sms) return setIsSMS(false);

      // console.log("Form data:", values);
      const {
        email: recipientEmail,
        phone: recipientPhoneNumber,
        name: recipientName,
        location,
        company: recipientCompany,
        emailSubject: subject,
        message: messagetoRecipient,
      } = values;

      const payload: any = {
        senderEmail: loginUser?.email,
        // requestorId: loginUser?.id,
        requestorId: sessionStorage.getItem("cuid"),
        recipientName,
        recipientEmail,
        messagetoRecipient,
        recipientPhoneNumber,
        // countryId: location,
        recipientCompany: recipientCompany || "",
        role: "",
        isVideoTestimonialRequest: path?.includes("video-testimonial") ? true : false,
        subject,
        workSpaceId: sessionStorage.getItem("wuid"),
      };
      if(path?.includes("google")){
        payload.locationId = location;
        payload.url = api_url + "/api/v1.2/google/GoogleReviewRequest";
      } else if(path?.includes("facebook")){
        payload.pageId = location;
        payload.url = api_url + "/api/v1.2/facebook/FacebookReviewRequest";
      }
      console.log({ payload });
      
      dispatch(triggerRequestReview(payload));
    };

    return (
      <React.Fragment>
        <div className="d-flex flex-column justify-content-center align-items-center text-center request-review-title">
          <h4 className="m-0 p-0" style={{ lineHeight: "1rem" }}>
            Hi! {loginUser?.name}
          </h4>
          <p className="my-2" style={{fontSize: 13}}>To whom you want to ask feedback today?</p>
        </div>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleFinish}
          onFinishFailed={callback}
          form={form}
          layout="vertical"
          autoSave={"false"}
          autoComplete="off"
        >
          <Form.Item
            // label="Email"
            name="email"
            requiredMark={false}
            required={true}
            // email validation
            rules={[
              {
                required: true,
                message: "Please input your email!",
                type: "email",
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              },
            ]}
            className="mb-2 w-100"
          >
            <Input placeholder="Recipient's Email" size="large" />
          </Form.Item>

          <Form.Item
            // label="Phone Number"
            name="phone"
            requiredMark={false}
            required={true}
            rules={[
              {
                required: true,
                message: "Please input your phone number!",
                // type: 'text',
                pattern: /^[0-9]*$/,
              },
            ]}
            className="my-2"
          >
            <Input placeholder="Recipient's Phone Number" size="large" />
          </Form.Item>

          <Form.Item
            // label="Name"
            name="name"
            requiredMark={false}
            required={true}
            rules={[{ required: true, message: "Please input your name!" }]}
            className="my-2"
          >
            <Input placeholder="Recipient's Name" size="large" />
          </Form.Item>

          <Form.Item
            // label="Company Name"
            name="company"
            requiredMark={false}
            required={false}
            rules={[
              { required: false, message: "Please input your company name!" },
            ]}
            className="my-2"
          >
            <Input
              placeholder="Recipient's Company Name (Optional)"
              size="large"
            />
          </Form.Item>

          <Form.Item
            // label="Company Name"
            name="emailSubject"
            requiredMark={false}
            required={true}
            rules={[
              { required: true, message: "Please input your email subject!" },
            ]}
            className="my-2"
          >
            <Input placeholder="Email subject" size="large" />
          </Form.Item>

          {path?.includes("google") || path?.includes("facebook") ? (
            <Form.Item
              // label="Location"
              name="location"
              requiredMark={false}
              required={true}
              rules={[
                { required: true, message: "Please input your location!" },
              ]}
              className="my-2 w-100"
            >
              <Select
                placeholder="Type and Select Location"
                allowClear
                showSearch
                showArrow
                filterOption={(input: any, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                size="large"
              >
                {locations?.map((item: any, idx: number) => {
                  return (
                    <Option
                      key={idx}
                      value={
                        item?.countryCode || item?.locationId || item?.pageId
                      }
                    >
                      {item?.countryName ||
                        item?.locationName ||
                        item?.pagename}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          ) : null}

          <Form.Item
            // label="Message"
            name="message"
            requiredMark={false}
            required={true}
            rules={[{ required: true, message: "Please input your message!" }]}
            className="my-2"
          >
            <TextArea
              autoSize={{ minRows: 2, maxRows: 5 }}
              style={{ resize: "none" }}
              placeholder="Message"
            />
          </Form.Item>

          {/* <Form.Item name="sms" 
                    valuePropName="checked"
                    requiredMark={false}
                    required={true}
                    rules={[{ 
                        required: true, 
                        type: "boolean", 
                        message: 'Please agree to send sms!' 
                    }]}
                    // className="my-2"
                >
                    <>
                        <Checkbox
                            onChange={(value: any) => {
                                console.log({value: value.target.checked});
                                setIsSMS(value.target.checked);
                            }}
                            type="checkbox"
                            // checked={isSMS}
                        >
                            Send SMS
                        </Checkbox>
                        <p className='text-danger'>
                            {
                                !isSMS && "Please agree to send SMS"
                            }
                        </p>
                    </>
                </Form.Item> */}

          <Form.Item>
            <Button
              className="my-2"
              style={{ width: "100%" }}
              type="primary"
              htmlType="submit"
              size="large"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </React.Fragment>
    );
}

export default RequestReview;
