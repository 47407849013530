import React, {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

// import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
// import useAuth from "./hooks/useAuth";
// import { getUserPermissions } from "./login/loginReducer";
import Cookies from 'js-cookie';
// import { triggerLogout } from './signup-login/loginReducer';
const PrivateRoute = ({ children }: any) => {
    // const { userPermissions } = useSelector((state: any) => state?.login);
    let isLoggedIn = false;
    const dispatch = useDispatch();
    // sessionStorage.setItem('roleName', "Super Admin");
    // sessionStorage.setItem('cuid', "1edcb220-3cfa-45f5-8320-d7a3d64868e4");
    // sessionStorage.setItem('roleId', "2d88e4c1-fd0c-11ec-b7d3-e4e74965c2c3");
    // sessionStorage.setItem('wuid', "766a0d41-fd19-11ec-b7d3-e4e74965c2c3");

    // console.log("sessionAuth ===============> ", sessionAuth);
    // console.log("sessionStorage.getItem('cuid') ===============> ", sessionStorage.getItem("cuid"));
    // console.log("sessionStorage.getItem('roleId') ===============> ", sessionStorage.getItem("roleId"));
    // console.log("sessionStorage.getItem('wuid') ===============> ", sessionStorage.getItem("wuid"));
    // all session storage items show
    const sessionData = sessionStorage;
    for(let i = 0; i < sessionData?.length; i++) {
        const key: any = sessionData.key(i);
        const value: any = sessionData.getItem(key);
        const flag = localStorage.getItem(key) !== value &&
        key !== undefined && key !== "message_type"
        // console.log(key, "=== key ==> ", value);
        if (flag) {
            localStorage.setItem(key, value);
        }
    }
    
    const localStorageData = localStorage;
    for (let i = 0; i < localStorageData?.length; i++) {
        const key: any = localStorageData.key(i);
        const value: any = localStorageData.getItem(key);
        // console.log(key, "=== key ==> ", value);
        const flag = sessionStorage.getItem(key) !== value &&
            key !== undefined && key !== "access_token" &&
            key !== "refresh_token"
        if (flag) {
            sessionStorage.setItem(key, value);
        }
    }
    const clearAllCookies = () => {
        const cookies = Cookies.get(); // Get all cookies
        for (const cookie in cookies) {
          Cookies.remove(cookie); // Remove each cookie
        }
      
        // Additional logic or actions after clearing the cookies
    };
    // const refreshToken = Cookies.get('refresh_token');
    // const refreshToken = `Bearer ${document.cookie.replace(
    //     /(?:(?:^|.*;\s*)refresh_token\s*=\s*([^;]*).*$)|^.*$/,
    //     '$1'
    // )}`;
    // console.log({refreshToken});
    
    
    const sessionAuth = sessionStorage.getItem("cuid") && sessionStorage.getItem("roleId") && sessionStorage.getItem("wuid");
    // const localAuth = localStorage.getItem("access_token");
    // if (localAuth) {
    if (sessionAuth) {
        // if(!userPermissions?.length){
        //     dispatch(getUserPermissions({
                // userId: sessionStorage.getItem("cuid"),
                // roleId: sessionStorage.getItem("roleId"),
                // workspaceId: sessionStorage.getItem("wuid")
                //     }));
                // }
                isLoggedIn = true;
    }
            
    // useEffect(() => {
    //     try {
    //         if (sessionAuth) {
    //             const local_userId = localStorage.getItem("cuid");
    //             const session_userId = sessionStorage.getItem("cuid");
    //             const local_roleId = localStorage.getItem("roleId");
    //             const session_roleId = sessionStorage.getItem("roleId");
    //             const local_workspaceId = localStorage.getItem("wuid");
    //             const session_workspaceId = sessionStorage.getItem("wuid");
    //             const local_accessed_user_details = localStorage.getItem("accessed_user_details");
    //             const session_accessed_user_details = sessionStorage.getItem("accessed_user_details");
    //             const local_access_token = localStorage.getItem("access_token");
    //             const session_access_token = sessionStorage.getItem("access_token");
    //             const local_refresh_token = localStorage.getItem("refresh_token");
    //             const session_refresh_token = sessionStorage.getItem("refresh_token");
    //             if(
    //                 !local_userId ||
    //                 !session_userId ||
    //                 !local_roleId ||
    //                 !session_roleId ||
    //                 !local_workspaceId ||
    //                 !session_workspaceId ||
    //                 !local_accessed_user_details ||
    //                 !session_accessed_user_details ||
    //                 !local_access_token ||
    //                 !session_access_token ||
    //                 !local_refresh_token ||
    //                 !session_refresh_token
    //             ) {
    //                 dispatch(triggerLogout());
    //             }
                
    //         }  
    //     } catch (err: any) {
    //         dispatch(triggerLogout());
    //     }
    // }, [dispatch]);

    return isLoggedIn 
        ? children 
        : <Navigate to={`/login`}/>
}

export default PrivateRoute; 