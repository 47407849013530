import React, { useState } from 'react';
import { Button, DatePicker, Form, Input, Select, TimePicker } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { getImage, testCollaboratorImage } from '../../../../../../unified/image_service';
// import { getImage, getTemplateImage } from '../../../../dashboard/image_service';
// import './Templates.scss';

type PreviewEditionProps = {
    onCancel?: () => void;
    onParentCallback?: (data: any, file?: any) => void;
};

const PreviewEdition: React.FC<any> = () => {

    const handleChange = (value: any, setState: any) => {
        setState(value);
    }

    const getCustomState = (state: any) => {
        switch (state) {
          case 'active':
            return 'Active';
          case 'inactive':
            return 'Inactive';
          case 'draft':
            return <div style={{padding: 10, borderRadius: '50%', background: '#F79009'}}></div>;
        case 'approved':
            return <div style={{padding: 10, borderRadius: '50%', background: '#5CCE99'}}></div>;
        case 'in-progress':
            return <div style={{padding: 10, borderRadius: '50%', background: '#F79009'}}></div>;
          default:
            return state;
        }
    };

    return (
        <React.Fragment>
        </React.Fragment>
    )
};

export default PreviewEdition;
