import { takeEvery, put, call, all } from 'redux-saga/effects';
import axios from "axios";

import { api_url } from '../../../utils/server';
import { generateAccessToken } from '../../../profile/reducer';
import Constants from '../../../constants';

// Worker Saga:
function* findList(action: any): Generator<any> {
    // console.log("header -> Worker saga => findUsersSaga action===>", action);
    const { clapupUserId, roleId, workspaceId } = action?.payload;
    try {
        let url = api_url + `/api/v1.2/ClapupIdentity/GetWorkspaceUsers?clapupUserId=${clapupUserId}&roleId=${roleId}&workspaceId=${workspaceId}`;
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });
        // console.log('findUsers===user manager saga==>', data);
        if (status === 200) {
            // yield put(findUserList(data?.map((user: any, idx: number) => {
            //     return {
            //         ...user,
            //         idx: (idx + 1)
            //     }
            // })));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* updateUserRole(action: any): Generator<any> {
    // console.log("header -> Worker saga => updateUserRole action===>");
    let url = api_url + `/api/v1.2/ClapupIdentity/AddUsersToRoles`;
    try {
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            // console.log('updateUserRole===user manager saga==>', data)
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}


// Watcher Saga:
function* integrationSaga() {

    yield all([
        // takeEvery(Constants.GET_TEAM_LIST, findTeamList),
        //   takeEvery(Constants.UPDATE_USER_ROLE, updateUserRole),
        //   takeEvery(Constants.UPDATE_STATUS, updateUserStatus),
        //   takeEvery(Constants.INVITE_USER, inviteUser),
    ]);
}

export default integrationSaga;

