import React, { Fragment, useEffect } from 'react';
import { Input, Button } from 'antd';
// import { DeleteOutlined } from '@ant-design/icons';
import delete_icon from '../../../../assets/icons/settings/user-profile/personal-info/delete-icon.svg';
import save_icon from '../../../../assets/icons/settings/save-icon.svg';
import edit_icon from '../../../../assets/icons/settings/edit-icon.svg';
import { 
    getProfileInfo,
    updateProfileInfoSetting,
    setProfileInfo,
    updateProfileImage,
} from '../../../Settings/UserProfile/userProfileManageReducer';
import { useDispatch, useSelector } from 'react-redux';
import { convertImageToBase64 } from '../../../../utils/convertingFunction';
import { useNavigate } from 'react-router';
import { Spinner } from 'react-bootstrap';
import { setSuccessMessage } from '../../../../unified/reducer';
// import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';
const PersonalInfo: React.FC = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const { personalInfo } = useSelector((state: any) => state?.profileManageSettingState);
    // const [accountType, setAccountType] = React.useState('');
    const [beforeUploadImg, setBeforeUploadImg]: any = React.useState(null);
    const [imageUrl, setImageUrl]: any = React.useState(null);
    const [infoData, setInfoData] = React.useState<any>(null);
    const query_string = window.location.search;
    // const [imageUrl , setImageUrl] = React.useState('');
    // firstName
    const [isFirstNameActive, setIsFirstNameActive] = React.useState(false);
    const [profileFirstName, setProfileFirstName] = React.useState('');
    const [isFirstNameError, setIsFirstNameError] = React.useState(false);
    // lastName
    const [isLastNameActive, setIsLastNameActive] = React.useState(false);
    const [profileLastName, setProfileLastName] = React.useState('');
    const [isLastNameError, setIsLastNameError] = React.useState(false);
    // email
    // const [isEmailActive, setIsEmailActive] = React.useState(false);
    const [profileEmail, setProfileEmail] = React.useState('');
    // const [isProfileEmailError, setIsProfileEmailError] = React.useState(false);
    // accountType
    const [hostGuestAccountType, setHostGuestAccountType] = React.useState('Host');
    useEffect(() => {
        const { email, firstname, lastname, accountType, photo }: any = personalInfo || {};
        
        firstname && setProfileFirstName(firstname);
        lastname && setProfileLastName(lastname);
        email && setProfileEmail(email);
        accountType && setHostGuestAccountType(accountType);
    }, [query_string]);
    
    useEffect(() => {
        const { email, firstname, lastname, accountType, photo }: any = personalInfo || {};
        // console.log({ email, firstname,});
        
        if(email || firstname || lastname || accountType || photo){
            photo && setImageUrl(photo);
            setInfoData({email, firstname, lastname, accountType, photo: imageUrl});
            setTimeout(() => setLoading(false), 2000);
        }else{
            setInfoData({...personalInfo})
            // dispatch(getProfileInfo());
            setLoading(true);
        }
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    } , [dispatch, query_string, personalInfo?.email, personalInfo?.firstname, personalInfo?.lastname, personalInfo?.accountType, personalInfo?.email]);

    const updateProfileInfo = (key: any, value: any) => {
        const data = {
            ...infoData,
            [key]: value,
        };
        
        setInfoData(data);
        if(key !== "photo"){
            dispatch(updateProfileInfoSetting({ColumnName: key, ColumnValue: value, clapupUserId: sessionStorage.getItem('cuid')}));
        }else{
            setImageUrl(value);
            dispatch(updateProfileImage({clapupUserId: sessionStorage.getItem('cuid'), photo: value}));
        }
        dispatch(setProfileInfo(data));
        setTimeout(() => dispatch(setSuccessMessage("Settings saved.")), 1000);
    }

    const updateMobileProfileInfo = (key: any, value: any) => {
        const data = {
            ...infoData,
            [key]: value,
        };
        setInfoData(data);
    }

    const handleMobileProfileInfoUpdate = () => {
        const {email, firstname, lastname, accountType}: any = infoData || {};
        dispatch(updateProfileInfoSetting({columnName: "email", columnValue: email, clapupUserId: sessionStorage.getItem('cuid')}));
        dispatch(updateProfileInfoSetting({columnName: "firstname", columnValue: firstname, clapupUserId: sessionStorage.getItem('cuid')}));
        dispatch(updateProfileInfoSetting({columnName: "lastname", columnValue: lastname, clapupUserId: sessionStorage.getItem('cuid')}));
        dispatch(updateProfileInfoSetting({columnName: "accountType", columnValue: accountType, clapupUserId: sessionStorage.getItem('cuid')}));
        setIsMobileUpdate(false);
    }

    const handleBeforeUpload = (file: any) => {
        setBeforeUploadImg(file);
        convertImageToBase64(file).then((base64Url: any) => {
            updateProfileInfo('photo', base64Url);
        })
        .catch((error: any) => {
            console.log("error", error);
        });
    }

    const {email, firstname, lastname, accountType, photo}: any = infoData || {};
    // console.log({infoData});

    const {userPermissions} = useSelector((state: any) => state?.login);
    const history = useNavigate();
    
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "persionalSettings") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions?.length]);
    
    const [isMobileUpdate, setIsMobileUpdate] = React.useState(false);
    // console.log({personalInfo, isFirstNameActive, profileFirstName});
    
    return (
        <React.Fragment>
            <div className='lg-sub-menu h-100 p-0'>
                {
                    loading
                    ? <div className='loading-container w-100 h-100 d-flex justify-content-center align-items-center'>
                        <Spinner animation="border" variant="primary" />
                     </div>
                    // : (profileEmail || email ) ? 
                    : <div className='profile-menu-container h-100'>
                            <div className='profile-image'>
                                <div className="form-group">
                                    {
                                        beforeUploadImg || imageUrl
                                        ? <img src={delete_icon} onClick={() => {
                                            setImageUrl("");
                                            setBeforeUploadImg("")
                                            updateProfileInfo('photo', "");
                                        }} className="delete" alt='delete' />
                                        : null
                                    }
                                    {/* {
                                        beforeUploadImg || imageUrl
                                        ? <DeleteOutlined onClick={() => {
                                            setImageUrl("");
                                            setBeforeUploadImg("")
                                            updateProfileInfo('photo', "");
                                        }} className="delete" />
                                        : null
                                    } */}
                                    
                                    <label htmlFor="image"
                                        className={`${(!beforeUploadImg && !imageUrl) ? "upload-button" : "border"} rounded-circle mx-auto align-items-center justify-content-center`}
                                        style={{
                                            display: 'flex',
                                            width: 'fit-content',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {
                                            beforeUploadImg || imageUrl
                                                ? <img className={`profile-avatar rounded-circle`}
                                                    src={beforeUploadImg ? URL.createObjectURL(beforeUploadImg) : imageUrl}
                                                    alt="avatar"
                                                />
                                                : <span className='upload-image-text'>
                                                    {/* <UploadOutlined /><span className='mb-t'> UPLOAD</span> */}
                                                    Click here to upload your Profile Avatar
                                                </span>
                                        }
                                    </label>
                                    <input disabled={imageUrl || beforeUploadImg ? true : false} id="image" type="file" onChange={(e: any) => {
                                        // console.log({img: e?.target?.files[0]});
                                        handleBeforeUpload(e?.target?.files[0]);
                                    }} className="form-input-field d-none w-100 m-0" placeholder="Business Image" />
                                </div>
                            </div>
                            <div className="form-container h-100">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <div className='input-container'>
                                        {
                                            isFirstNameActive
                                            ? <input  
                                                defaultValue={profileFirstName || firstname}
                                                readOnly={!isFirstNameActive}
                                                autoFocus={true}
                                                // onBlur={() => {
                                                //     setIsFirstNameActive(!isFirstNameActive);
                                                //     setIsFirstNameError(false);
                                                //     setProfileFirstName("");
                                                // }}
                                                onChange={(e: any) => {
                                                    const { value } = e.target;
                                                    if (value) {
                                                        setProfileFirstName(value);
                                                        setIsFirstNameError(false);
                                                    } else {
                                                        setProfileFirstName("");
                                                        setIsFirstNameError(true);
                                                    }
                                                }}
                                                type="text" 
                                                placeholder="First Name"
                                                className={`${isFirstNameError ? "input-error" : ""}`}
                                            />
                                            : <span className='input-value'>{profileFirstName || firstname}</span>
                                        }
                                        
                                        <img
                                            src={isFirstNameActive ? save_icon : edit_icon}
                                            className={`add-edit-save-icon ${isFirstNameError ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!isFirstNameError) {
                                                    if (isFirstNameActive && profileFirstName) {
                                                        setIsFirstNameActive(false);
                                                        updateProfileInfo('FirstName', profileFirstName);
                                                    } else {
                                                        setIsFirstNameActive(true);
                                                    }
                                                }
                                            }}
                                            alt='save-edit'
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <div className='input-container'>
                                        {
                                            isLastNameActive
                                            ? <input
                                                readOnly={!isLastNameActive}
                                                defaultValue={lastname}
                                                autoFocus={true}
                                                // onBlur={() => {
                                                //     setIsLastNameActive(!isLastNameActive);
                                                //     setIsLastNameError(!isLastNameError);
                                                //     setProfileLastName("");
                                                // }}
                                                onChange={(e: any) => {
                                                    const { value } = e.target;
                                                    if (value) {
                                                        setProfileLastName(value);
                                                        setIsLastNameError(false);
                                                    } else {
                                                        setProfileLastName("");
                                                        setIsLastNameError(true);
                                                    }
                                                }}
                                                type="text"
                                                placeholder="Last Name"
                                                className={`${isLastNameError ? "input-error" : ""}`}
                                            />
                                            : <span className='input-value'>{profileLastName || lastname}</span>
                                        }
                                        
                                        <img
                                            src={isLastNameActive ? save_icon : edit_icon}
                                            className={`add-edit-save-icon ${isLastNameError ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (!isLastNameError) {
                                                    if (isLastNameActive && profileLastName) {
                                                        setIsLastNameActive(false);
                                                        updateProfileInfo('LastName', profileLastName);
                                                    } else {
                                                        setIsLastNameActive(true);
                                                    }
                                                }
                                            }}
                                            alt='save-edit'
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Email Address</label>
                                    <div className="input-container">
                                        <input
                                            defaultValue={profileEmail || email}
                                            readOnly={true}
                                            // readOnly={!isEmailActive}
                                            // autoFocus={isEmailActive}
                                            // onChange={(e: any) => {
                                            //     const emailCheckerRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                                            //     const emailChecker = emailCheckerRegex?.test(e.target.value);
                                            //     if (emailChecker) {
                                            //         setProfileEmail(e.target.value);
                                            //         setIsProfileEmailError(false);
                                            //     } else {
                                            //         setProfileEmail('');
                                            //         setIsProfileEmailError(true);
                                            //     }
                                            // }} 
                                            type="text"
                                            placeholder="Email"
                                            className={`border-none`}
                                            // className={`${isProfileEmailError ? 'input-error' : ''} border-none`}
                                        />
                                        {/* <button
                                            type="button"
                                            onClick={() => {
                                                setIsEmailActive(!isEmailActive);
                                                updateProfileInfo('Email', profileEmail);
                                            }}
                                            disabled={isProfileEmailError}    
                                            className={`add-edit-save-btn`}
                                        >
                                            {
                                                isEmailActive ? 'Save'
                                                : email ? 'Edit'
                                                : "Add"      
                                            }
                                        </button> */}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Account Type</label>
                                    <div className="host-guest-container">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                updateProfileInfo('AccountType', 'Host');
                                                setHostGuestAccountType('Host');
                                            }}
                                            className={`host-button ${hostGuestAccountType === 'Host' ? 'button-active' : ''}`}
                                        >
                                            Host Account
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                updateProfileInfo('AccountType', 'Guest');
                                                setHostGuestAccountType('Guest');
                                            }}
                                            className={`guest-button ${hostGuestAccountType === "Guest" ? "button-active" : ""}`}
                                        >
                                            Guest Account
                                        </button>
                                    </div>
                                </div>
                            </div>
                    </div>
                    // : null
                }
                
            </div>
            <div className='mobile-sub-menu w-100'>
                {
                    loading
                    ? <div className='loading-container'>
                        {/* <Spin /> */}
                        <Spinner animation="border" variant="primary" />
                     </div>
                    : <Fragment>
                        <div className='form-item px-2 mb-3'>
                            <p className='text-start'>First Name</p>
                            <Input
                                defaultValue={firstname}
                                onChange={(e: any) => updateMobileProfileInfo('firstname', e.target.value)}
                                type="text"
                                size='large'
                                className="form-control w-100 my-2"
                                style={{borderRadius: 5}}
                                placeholder="First Name"
                                readOnly={!isMobileUpdate}
                            />
                        </div>
                        <div className='form-item px-2 mb-3'>
                            <p className='text-start'>Last Name</p>
                            <Input
                                defaultValue={lastname}
                                onChange={(e: any) => updateMobileProfileInfo('lastname', e.target.value)}
                                type="text"
                                size='large'
                                className="form-control w-100 my-2"
                                style={{borderRadius: 5}}
                                placeholder="Last Name"
                                readOnly={!isMobileUpdate}
                            />
                        </div>
                        <div className='form-item px-2 mb-3'>
                            <p className='text-start'>Email Address</p>
                            <Input
                                defaultValue={email}
                                readOnly
                                onChange={(e: any) => updateMobileProfileInfo('email', e.target.value)}
                                type="email"
                                size='large'
                                className="form-control w-100 my-2"
                                style={{backgroundColor: '#D3E0FF', borderRadius: 5}}
                                placeholder="Email"
                            />
                        </div>
                        <div className='form-item px-2 mb-3 position-relative'>
                            <p className='text-start'>Account Type</p>
                            {/* <Select
                                // showSearch
                                size='large'
                                className="w-100 my-2 text-start"
                                placeholder="Select Type"
                                defaultValue={accountType || 'Select Type'}
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                // allowClear
                                showArrow={false}
                                clearIcon={null}
                                disabled={true}
                                onChange={(value: any) => updateMobileProfileInfo('accountType', value)}
                            >
                                <Option key={1} value="Guest">Guest Account</Option>
                                <Option key={2} value="Host">Host Account</Option>
                            </Select> */}
                            <Input
                                value={accountType + " Account"}
                                readOnly
                                type="text"
                                size='large'
                                className="form-control w-100 my-2"
                                style={{borderRadius: 5}}
                                placeholder="Account Type"
                            />
                            <button 
                                className='convert-button' 
                                type="button"
                                onClick={() => updateMobileProfileInfo('accountType', accountType === 'Guest' ? 'Host' : 'Guest' )}
                                disabled={isMobileUpdate ? false : true}
                            >
                                Convert to {accountType === 'Guest' ? 'host' : 'guest'}
                            </button>
                        </div>
                        <div className='form-item px-2 mx-2 my-4 text-start'>
                            <Button 
                                className='save-change-button' 
                                onClick={() => {
                                    isMobileUpdate 
                                    ? handleMobileProfileInfoUpdate()
                                    : setIsMobileUpdate(true)
                                }} 
                                // type="button"
                            >
                                {
                                    isMobileUpdate
                                    ? 'Save'
                                    : 'Change'
                                }
                            </Button>
                        </div>
                    </Fragment>
                }
                
            </div>
        </React.Fragment>
    );
};

export default PersonalInfo;