import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./WorkspaceSettings.scss";
import { getImage, getGenerateApiImageByName } from "../../../unified/image_service";
import { toggleShowMobileSettingsNavBar } from "../../../signup-login/loginReducer";
import { updateWorkSettings } from "./workspaceReducer";
import {
    // setSuccessMessage,
    setErrorMessage
} from "../../../unified/reducer";
import { convertImageToBase64 } from "../../../utils/convertingFunction";

const WorkspaceSettings: React.FC = () => {
    // const parent_routes = workspace_routes;
    // const { personalInfo } = useSelector((state: any) => state?.profileManageSettingState);
    const dispatch = useDispatch();
    // const path_name = window.location.pathname;
    // Workspace Name
    const {
        workspaceSettings,
        // isUpdatedWorkspace 
    } = useSelector((state: any) => state?.workspaceManagementState);
    const [workspaceName, setWorkspaceName] = useState<any>("");
    const [workspaceType, setWorkspaceType] = useState<any>("");
    const [imageURL, setImageURL] = useState<any>("");
    const [videoTestimonialRedirectURL, setVideoTestimonialRedirectURL] = useState<any>("");
    const [feedBackRedirectURL, setFeedBackRedirectURL] = useState<any>("");

    const [videoPageUrl, setVideoPageUrl] = useState<any>("");
    const [feedbackPageUrl, setFeedbackPageUrl] = useState<any>("");

    useEffect(() => {
        // const {workspaceName, workspaceType, businessName, logo, subDomainName, customerFeedBackRedirectURL, videoTestimonialRedirectURL}: any = workspaceSettings || {};
        const {workspaceName, workspaceType, logo, subDomainName, customerFeedBackRedirectURL, videoTestimonialRedirectURL}: any = workspaceSettings || {};
        // setBusinessName(businessName);
        // setSubDomain(subDomainName);
        setWorkspaceName(workspaceName || "");
        setWorkspaceType(workspaceType || "");
        setImageURL(logo || "");
        setVideoTestimonialRedirectURL(videoTestimonialRedirectURL || "");
        setFeedBackRedirectURL(customerFeedBackRedirectURL || "");
    }, [dispatch, workspaceSettings?.businessName, workspaceSettings?.subDomain, workspaceSettings?.customerFeedBackRedirectURL, workspaceSettings?.videoTestimonialRedirectURL, workspaceSettings?.logo]);
    
    const handleUpdateWorkspaceSettingsField = (columnName: any, columnValue: any) => {
        console.log({columnValue});
        if(!columnValue){
            // setWorkspaceState && setWorkspaceState("");
            dispatch(setErrorMessage("Value is required"));;
        }else {
            const dataObject = {
                columnName,
                columnValue,
                clapupId: sessionStorage.getItem('cuid'),
                workSpaceId: sessionStorage.getItem('wuid'),
            }
            console.log({dataObject});
            dispatch(updateWorkSettings(dataObject));
        }
    }

    const handleLogoSubmit = (imageBase64: any) => {
        console.log({imageBase64});
        
        if(imageBase64){
            const dataObject = {
                logo: imageBase64 || "",
                clapupId: sessionStorage.getItem('cuid'),
                workSpaceId: sessionStorage.getItem('wuid'),
            }
            dispatch(updateWorkSettings(dataObject));
        }
    }

    const handleImageChange = (e: any) => {
        // file local path
        // const image_path
        const file = e.target.files[0];
        const path = e.target.value;
        const image_url = URL.createObjectURL(file);
        console.log({ path, file });
        setImageURL(image_url);
        // setImageFile(file);
        
        // ======== 1st way to convert image to base64 ========
        convertImageToBase64(file).then((base64Url: any) => {
            // console.log({base64Url});
            // setLogoBaseUrl(base64Url);
            handleLogoSubmit(base64Url);
            // handleLogoSubmit(base64Url);

        })
        .catch((error: any) => {
            console.log({error});
        });

        // ======== 2nd way to convert image to base64 ========
        // const formData = new FormData();
        // const body = {
        //     "code": Number(new Date().getTime() + (1000*1000)),
        //     "AccessKey": "746def78-473d-4b9a-b230976d1e62-6dd3-46de",
        //     "workspaceId": sessionStorage.getItem('wuid'),
        // }
        // formData.append('file', file);
        // formData.append('body', btoa(JSON.stringify(body)));
        // console.log({ formBody: body });
        // axios((auth_url) + "/api/v1/upload-workspace-image", {
        //     method: 'POST',
        //     data: formData,
        // }).then(response => {
        //     console.log({response});
        //     // setImageURL(response?.data?.data?.url);
        // }).catch(error => {
        //     console.log({error});
        // });
        
    }

    const {userPermissions, is_show_mobile_settings_nav_bar } = useSelector((state: any) => state?.login);
    const history = useNavigate();
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "workspaceSettings") : null;
        // const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "generalSettings-Account") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions, history]);

    return (
        <React.Fragment>
            <div className="button-container">
                <img className="mobile-setting-nav-bar"
                    onClick={() => dispatch(toggleShowMobileSettingsNavBar(!is_show_mobile_settings_nav_bar))}
                    src={getImage("mobile-nav-icon")}
                    alt='hum-burger'
                />
                <button>Save Changes</button>
            </div>
            <div className="body-container">
                <div className="general-settings-container">
                    <div className="settings-header-title">
                        <h3>General Setting</h3>
                    </div>
                    <div className="settings-form-item">
                        <div className="title">Workspace Name</div>
                        <div className="action-body">
                            <div className="input-container">
                                <input
                                    className=""
                                    defaultValue={workspaceName || ""}
                                    onChange={(e: any) => setWorkspaceName(e.target.value)}
                                    placeholder={"Workspace Name"}
                                />
                                <img
                                    onClick={() => handleUpdateWorkspaceSettingsField("WorkspaceName", workspaceName)}
                                    className="tik"
                                    src={getImage("fill-success-tik")}
                                    alt="tik"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="settings-form-item">
                        <div className="title">Workspace Type</div>
                        <div className="action-body">
                            <div className="input-container">
                                <input
                                    className="disable-input"
                                    readOnly={true}
                                    defaultValue={workspaceType || "Basic"}
                                    onChange={(e: any) => setWorkspaceType(e.target.value)}
                                    placeholder={"Workspace Type"}
                                />
                                {/* <img className="tik" src={getImage("fill-success-tik")} alt="tik"/> */}
                            </div>
                        </div>
                    </div>
                    <div className="settings-form-item">
                        <div className="title">Workspace Icon</div>
                        <div className="action-body">
                            <div className="input-container">
                                <div className="logo-container">
                                    <img
                                        className='logo' 
                                        src={imageURL || getGenerateApiImageByName(workspaceName)} 
                                        alt={"logo"} 
                                    />
                                    <div className="action-container">
                                        <div
                                            onClick={() => {
                                                handleLogoSubmit("");
                                                setImageURL("");
                                            }}
                                        >
                                            Delete
                                        </div>
                                        <input
                                            id="image" type="file"
                                            // accept='*'
                                            accept='image/png, image/jpg, image/jpeg, image/svg'
                                            onChange={(e: any) => {
                                                // console.log({ img: e?.target?.files[0] });
                                                // setImageURL(URL.createObjectURL(e?.target?.files[0]));
                                                // setImage(e?.target?.files[0]?.name);
                                                handleImageChange(e);
                                                // if (e?.target?.files[0]?.size < 25000) {
                                                // }

                                            }}
                                            className="d-none"
                                        />
                                        <label htmlFor="image">Upload New</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="settings-form-item">
                        <div className="title">Redirect URL for Feedback</div>
                        <div className="action-body">
                            <div className="input-container">
                                <input
                                    className=""
                                    value={feedBackRedirectURL || ""}
                                    onChange={(e: any) => setFeedBackRedirectURL(e.target.value)}
                                    placeholder={"URL"}
                                />
                                <img
                                    onClick={() => handleUpdateWorkspaceSettingsField("FeedBackRedirectURL", feedBackRedirectURL)}
                                    className="tik"
                                    src={getImage("fill-success-tik")}
                                    alt="tik"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="settings-form-item">
                        <div className="title">Redirect URL for Video</div>
                        <div className="action-body">
                            <div className="input-container">
                                <input
                                    className=""
                                    value={videoTestimonialRedirectURL || ""}
                                    onChange={(e: any) => setVideoTestimonialRedirectURL(e.target.value)}
                                    placeholder={"URL"}
                                />
                                <img
                                    onClick={() => handleUpdateWorkspaceSettingsField("VideoTestimonialRedirectURL", videoTestimonialRedirectURL)}
                                    className="tik"
                                    src={getImage("fill-success-tik")}
                                    alt="tik"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="settings-form-item">
                        <div className="title">Feedback Page URL</div>
                        <div className="action-body">
                            <div className="input-container">
                                <input
                                    className="disable-input"
                                    readOnly={true}
                                    value={feedbackPageUrl || ""}
                                    placeholder={"URL"}
                                />
                                {/* <img className="tik" src={getImage("fill-success-tik")} alt="tik"/> */}
                            </div>
                        </div>
                    </div>
                    <div className="settings-form-item">
                        <div className="title">Video testimonial Page URL</div>
                        <div className="action-body">
                            <div className="input-container">
                                <input
                                    className="disable-input"
                                    readOnly={true}
                                    value={videoPageUrl || ""}
                                    placeholder={"URL"}
                                />
                                {/* <img className="tik" src={getImage("fill-success-tik")} alt="tik"/> */}
                            </div>
                        </div>
                    </div>
                    <div className="extra"></div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default WorkspaceSettings;