import SimpleLinkForm from "./CustomLinkForms/SimpleLinks/SimpleLinkForm";
import NestedParentLinkForm from "./CustomLinkForms/NestedLinks/NestedParentLinkForm";
import AnnouncementForm from "./CustomLinkForms/Announcement/AnnouncementForm";
import SocialLinkForm from "./CustomLinkForms/SocialLinks/SocialLinkForm";
import EmbedImageForm from "./CustomLinkForms/EmbedImages/EmbedImageForm";
import EmbedVideoLinkForm from "./CustomLinkForms/EmbedVideoLinks/EmbedVideoLinkForm";
import EmbedVideoForm from "./CustomLinkForms/EmbedVideos/EmbedVideoForm";

const link_types = [
    {
        id: 1,
        key: "simple_link",
        title: 'Simple Link',
        header_add: 'Create Simple Link',
        form: SimpleLinkForm,
    },
    {
        id: 2,
        key: "nested_link",
        title: 'Nested Link',
        header_add: 'Nested Link',
        form: NestedParentLinkForm,
    },
    {
        id: 6,
        title: 'Announcements',
        header_add: 'Announcement',
        form: AnnouncementForm
    },
    {
        id: 7,
        title: 'Social Link',
        header_add: 'Social Link',
        form: SocialLinkForm,
    },
    {
        id: 3,
        title: 'Embed Videos',
        header_add: 'Video Embed',
        form: EmbedVideoForm,
    },
    {
        id: 4,
        title: 'Embed Video Links',
        header_add: 'Video Link',
        form: EmbedVideoLinkForm,
    },
    {
        id: 5,
        title: 'Embed Images',
        header_add: 'Image Embed',
        form: EmbedImageForm,
    },

];

export { link_types, };