import * as signalR from "@microsoft/signalr";
import { signalR_api_url } from "./server";

const hubUrl = signalR_api_url || "https://signalrapi-dev.clapup.me/RecordHub";

let connection = new signalR.HubConnectionBuilder()
    .withUrl(
        hubUrl, // + "/RecordHub",
        {
            withCredentials: true,
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        }
    )
    .configureLogging(signalR.LogLevel.Information)
    .build();

// connection.on("ReceiveNewCustomerFeedbackRecord", (record) => {
//     console.log("New customer feedback received:", record);
//     // You can dispatch an action or update your state here
// });

connection.start()
    .then(() => {
        console.log("SignalR: connected");
    })
    .catch((error) => {
        console.error("SignalR connection error:", error);
    });

export default connection;