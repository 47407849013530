// import AnnouncementForm from "./CustomLinkForms/Announcements/AnnouncementForm";
import EmbedImageForm from "./CustomLinkForms/EmbedImages/EmbedImageForm";
import EmbedVideoLinkForm from "./CustomLinkForms/EmbedVideoLinks/EmbedVideoLinkForm";
import EmbedVideoForm from "./CustomLinkForms/EmbedVideos/EmbedVideoForm";
import NestedParentLinkForm from "./CustomLinkForms/NestedLinks/NestedParentLinkForm";
import SimpleLinkForm from "./CustomLinkForms/SimpleLinks/SimpleLinkForm";

const link_types = [
    {
        id: 1,
        key: "simple_link",
        title: 'Simple Link',
        header_add: 'Create Simple Link',
        form: SimpleLinkForm,
    },
    {
        id: 2,
        key: "nested_link",
        title: 'Nested Link',
        header_add: 'Create Nested Link',
        form: NestedParentLinkForm,
    },
    {
        id: 3,
        title: 'Embed Videos',
        header_add: 'Add Video Embed',
        form: EmbedVideoForm,
    },
    {
        id: 4,
        title: 'Embed Video Links',
        header_add: 'Add Video Link',
        form: EmbedVideoLinkForm,
    },
    {
        id: 5,
        title: 'Embed Images',
        header_add: 'Add Image Embed',
        form: EmbedImageForm,
    },
    // {
    //     id: 6,
    //     title: 'Announcements',
    //     header_add: 'Add Announcement',
    //     form: AnnouncementForm
    // }
];

export { link_types, };