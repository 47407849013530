import React, {useState, useEffect} from "react";
// import {Select} from 'antd';
import { useDispatch, useSelector } from "react-redux";
// import { getImage, getGenerateApiImageByName } from "../../../../Unified/image_service";
import ColorPicker from "../../../../components/ColorPicker/ColorPicker";
import { setErrorMessage } from "../../../../unified/reducer";
import { requestForCreatingTeam } from "../teamsReducer";
import { takingCharacterAndUnderScoreAndNumber } from "../../../../unified/my_data_service";

const color_values = {
    HEX: [
        '#F0BB40', '#F04438', '#B9BFCB', '#4276F0', '#FEC84B', '#027A48', '#F6D78E', '#4A1FB8', '#000000', '#758299', '#B42318', '#07225f', '#1354ec', '#1354ec', '#F0BB40', '#F04438', '#B9BFCB', '#4276F0', '#FEC84B', '#027A48', '#F6D78E', '#4A1FB8', '#000000', '#758299', '#B42318', '#07225f', '#1354ec', '#1354ec',
        '#F0BB40', '#F04438', '#B9BFCB', '#4276F0', '#FEC84B', '#027A48', '#F6D78E', '#4A1FB8', '#000000', '#758299', '#B42318', '#07225f', '#1354ec', '#1354ec', '#F0BB40', '#F04438', '#B9BFCB', '#4276F0', '#FEC84B', '#027A48', '#F6D78E', '#4A1FB8', '#000000', '#758299', '#B42318', '#07225f', '#1354ec', '#1354ec',
    ],
    HSL: [
        'hsl(42, 85%, 60%)', 'hsl(4, 86%, 58%)', 'hsl(220, 15%, 76%)', 'hsl(222, 85%, 60%)', 'hsl(42, 99%, 65%)', 'hsl(155, 97%, 24%)', 'hsl(42, 85%, 76%)', 'hsl(257, 71%, 42%)', 'hsl(0, 0%, 0%)', 'hsl(218, 15%, 53%)', 'hsl(4, 76%, 40%)', 'hsl(222, 86%, 20%)', 'hsl(222, 85%, 50%)', 'hsl(222, 85%, 50%)', 'hsl(42, 85%, 60%)', 'hsl(4, 86%, 58%)', 'hsl(220, 15%, 76%)', 'hsl(222, 85%, 60%)', 'hsl(42, 99%, 65%)', 'hsl(155, 97%, 24%)', 'hsl(42, 85%, 76%)', 'hsl(257, 71%, 42%)', 'hsl(0, 0%, 0%)', 'hsl(218, 15%, 53%)', 'hsl(4, 76%, 40%)', 'hsl(222, 86%, 20%)', 'hsl(222, 85%, 50%)', 'hsl(222, 85%, 50%)',
        'hsl(42, 85%, 60%)', 'hsl(4, 86%, 58%)', 'hsl(220, 15%, 76%)', 'hsl(222, 85%, 60%)', 'hsl(42, 99%, 65%)', 'hsl(155, 97%, 24%)', 'hsl(42, 85%, 76%)', 'hsl(257, 71%, 42%)', 'hsl(0, 0%, 0%)', 'hsl(218, 15%, 53%)', 'hsl(4, 76%, 40%)', 'hsl(222, 86%, 20%)', 'hsl(222, 85%, 50%)', 'hsl(222, 85%, 50%)', 'hsl(42, 85%, 60%)', 'hsl(4, 86%, 58%)', 'hsl(220, 15%, 76%)', 'hsl(222, 85%, 60%)', 'hsl(42, 99%, 65%)', 'hsl(155, 97%, 24%)', 'hsl(42, 85%, 76%)', 'hsl(257, 71%, 42%)', 'hsl(0, 0%, 0%)', 'hsl(218, 15%, 53%)', 'hsl(4, 76%, 40%)', 'hsl(222, 86%, 20%)', 'hsl(222, 85%, 50%)', 'hsl(222, 85%, 50%)',
    ],
    RGB: [
        "rgb(240, 187, 64)", "rgb(240, 68, 56)", "rgb(185, 191, 203)", "rgb(66, 118, 240)", "rgb(254, 200, 75)", "rgb(2, 122, 72)", "rgb(246, 215, 142)", "rgb(74, 31, 184)", "rgb(0, 0, 0)", "rgb(117, 130, 153)", "rgb(180, 35, 24)", "rgb(7, 34, 95)", "rgb(19, 84, 236)", "rgb(19, 84, 236)", "rgb(240, 187, 64)", "rgb(240, 68, 56)", "rgb(185, 191, 203)", "rgb(66, 118, 240)", "rgb(254, 200, 75)", "rgb(2, 122, 72)", "rgb(246, 215, 142)", "rgb(74, 31, 184)", "rgb(0, 0, 0)", "rgb(117, 130, 153)", "rgb(180, 35, 24)", "rgb(7, 34, 95)", "rgb(19, 84, 236)", "rgb(19, 84, 236)",
        "rgb(240, 187, 64)", "rgb(240, 68, 56)", "rgb(185, 191, 203)", "rgb(66, 118, 240)", "rgb(254, 200, 75)", "rgb(2, 122, 72)", "rgb(246, 215, 142)", "rgb(74, 31, 184)", "rgb(0, 0, 0)", "rgb(117, 130, 153)", "rgb(180, 35, 24)", "rgb(7, 34, 95)", "rgb(19, 84, 236)", "rgb(19, 84, 236)", "rgb(240, 187, 64)", "rgb(240, 68, 56)", "rgb(185, 191, 203)", "rgb(66, 118, 240)", "rgb(254, 200, 75)", "rgb(2, 122, 72)", "rgb(246, 215, 142)", "rgb(74, 31, 184)", "rgb(0, 0, 0)", "rgb(117, 130, 153)", "rgb(180, 35, 24)", "rgb(7, 34, 95)", "rgb(19, 84, 236)", "rgb(19, 84, 236)",
    ],
};

const AddTeam: React.FC<any> = ({handleCancel}: any) => {
    const dispatch = useDispatch();
    const { is_got_new_setting_team_list } = useSelector((state: any) => state?.teamState);
    const [teamName, setTeamName] = useState<any>("");
    const [color, setColor] = useState<any>("");

    useEffect(() => {
        if (is_got_new_setting_team_list) {
            setColor("");
            setTeamName("");
        }
     }, [is_got_new_setting_team_list]);

    const handleCreateTeam = () => {
        const data_object = {
            teamName,
            color,
        }
        console.log({ data_object });
        if (!teamName) {
            dispatch(setErrorMessage("Team name is required"));
            return;
        }

        if (!color) {
            dispatch(setErrorMessage("Color is required"));
            return;
        }

        dispatch(requestForCreatingTeam(data_object));
    }
    
    return (
        <React.Fragment>
            <div className="team-setting-form">
                <h3>Create Team</h3>
                <div className="settings-form-item">
                    <input
                        className=""
                        value={teamName || ""}
                        autoFocus={true}
                        onChange={(e: any) => {
                            const { value } = e.target;
                            if (value?.length > 15) {
                                dispatch(setErrorMessage("Team name should be less than 15 characters"));
                                return;
                            }
                            const name_value = takingCharacterAndUnderScoreAndNumber(value);
                            setTeamName(name_value);
                        }}
                        placeholder={"Add your team name here"}
                    />
                </div>
                <div className="settings-form-item">
                    <div className="label">Select Color</div>
                    <div className="color-picker-input-container">
                        <ColorPicker
                            palette_label={"Presets"}
                            color_values={color_values}
                            color_picker_container_class_name={"add-team-color-picker-container"}
                            value={color}
                            onChange={(value: any) => {
                                // console.log({ color_value: value });
                                setColor(value);
                            }}
                        />
                    </div>
                </div>
                <div className="action-buttons">
                    <div
                        className="text-button"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </div>
                    <div
                        className="text-button"
                        onClick={() => handleCreateTeam()}
                    >
                        Create Now
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddTeam;