import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import Reducer from "./sagas/reducers/combineReducer";
import { rootSaga } from './sagas/index';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware, thunk];
const store = createStore(Reducer, composeWithDevTools(applyMiddleware(...middleware)));
sagaMiddleware.run(rootSaga)
export default store;
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
