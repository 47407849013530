const dummy_notifications:any[] = [
    {
        id: 1,
        as: 'google',
        description: "The tariff is reasonable and cannot be called exorbitant. Package includes complimentary breakfast.",
        isRead: false,
        date: new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000),
    },
    {
        id: 2,
        as: 'facebook',
        description: "The tariff is reasonable and cannot be called exorbitant. Package includes complimentary breakfast.",
        isRead: false,
        date: new Date(new Date().getTime() -  2 * 60 * 60 * 1000),
    },
    {
        id: 3,
        as: 'feedback',
        description: "The tariff is reasonable and cannot be called exorbitant. Package includes complimentary breakfast.",
        isRead: false,
        date: new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000),
    },
    {
        id: 4,
        as: 'video',
        description: "The tariff is reasonable and cannot be called exorbitant. Package includes complimentary breakfast.",
        isRead: false,
        date: new Date(new Date().getTime() - 6 * 60 * 60 * 1000),
    },
    {
        id: 5,
        as: 'twitter',
        description: "The tariff is reasonable and cannot be called exorbitant. Package includes complimentary breakfast.",
        isRead: false,
        date: new Date(new Date().getTime() - 4 * 24 * 60 * 60 * 1000),
    },
    {
        id: 6,
        as: 'google',
        description: "The tariff is reasonable and cannot be called exorbitant. Package includes complimentary breakfast.",
        isRead: false,
        date: new Date(new Date().getTime() -  5 * 60 * 1000),
    },
    
];

export default dummy_notifications;