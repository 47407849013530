import React from 'react';
import './WallConversations.scss';
import emptyBox from '../../../assets/img/Empty.svg';
import { useEffect, useState } from 'react';
// import WallConversation from './WallData/WallConversation';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
// import Scrollbars from 'react-custom-scrollbars';
import GoogleData from './WallData/GoogleData';
import FacebookData from './WallData/FacebookData';
import TwitterData from './WallData/TwitterData';
import FeedbackData from './WallData/FeedbackData';
import VideoData from './WallData/VideoData';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { triggerWallUnified } from '../../../unified/reducer';
interface Props {
    wallData?: any[];
    handleDelete?: any;
}

const WallConversations: React.FC<Props> = ({ handleDelete }) => {
  const route_path = window.location.pathname;
  const query_string = window.location.search;
  const tag = query_string?.includes("reviews") ? "reviews" : query_string?.includes("mentions") ? "mentions" : query_string?.includes("comments") ? "comments" : query_string?.includes("qa") ? "qa" : "all";
  const [conversations, setConversations] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { wallUnifiedData: wall_unified_data, searchWallText}: any = useSelector((state: RootState) => state?.unified);
  const dispatch = useDispatch();

  const {
    InboxWall, InboxWall_nextDate,
    CFWall, CFWall_nextDate,
    VTWall, VTWall_nextDate,
    GoogleWall, GoogleWall_nextDate,
    FacebookWall, FacebookWall_nextDate,
    TwitterWall, TwitterWall_nextDate,

  }: any = wall_unified_data || {};

  const wallData: any[] =
    route_path?.includes('/unified')
    ? InboxWall
    : route_path?.includes('/customer-feedback')
    ? CFWall
    : route_path?.includes('/video-testimonial')
    ? VTWall
    : route_path?.includes('/google')
    ? GoogleWall
    : route_path?.includes('/facebook')
    ? FacebookWall
    : route_path?.includes('/twitter')
    ? TwitterWall
    : [];
  
  const wallDataKey: any =
    route_path?.includes('/unified')
    ? ['InboxWall', 'InboxWall_nextDate', 'lastSerialNoInboxWall', 'inbox']
    : route_path?.includes('/customer-feedback')
    ? ['CFWall', 'CFWall_nextDate', 'lastSerialNoCFWall', 'customerFeedback']
    : route_path?.includes('/video-testimonial')
    ? ['VTWall', 'VTWall_nextDate', 'lastSerialNoVTWall', 'videoTestimonial']
    : route_path?.includes('/google')
    ? ['GoogleWall', 'GoogleWall_nextDate', 'lastSerialNoGoogleWall', 'google']
    : route_path?.includes('/facebook')
    ? ['FacebookWall', 'FacebookWall_nextDate', 'lastSerialNoFacebookWall', 'facebook']
    : route_path?.includes('/twitter')
    ? ['TwitterWall', 'TwitterWall_nextDate', 'lastSerialNoTwitterWall', 'twitter']
    : ['InboxWall', 'InboxWall_nextDate', 'lastSerialNoInboxWall', 'inbox'];
    
  const wallNextDate: any[] =
    route_path?.includes('/unified')
    ? InboxWall_nextDate
    : route_path?.includes('/customer-feedback')
    ? CFWall_nextDate
    : route_path?.includes('/video-testimonial')
    ? VTWall_nextDate
    : route_path?.includes('/google')
    ? GoogleWall_nextDate
    : route_path?.includes('/facebook')
    ? FacebookWall_nextDate
    : route_path?.includes('/twitter')
    ? TwitterWall_nextDate
    : [];
            

  useEffect(() => {
    const isFetchedMoreWallData: any = wallNextDate?.filter((item: any) => item?.createdOn || item?.CreatedOn);
    // console.log({isFetchedMoreWallData, wallNextDate});
    if (isFetchedMoreWallData?.length) {
      // setIsLoading(true);
      setHasMore(true);
    } else {
      // setIsLoading(false);
      setHasMore(false);
    }
  }, [wallNextDate?.length]);

  const wallPayload: any = {
    clapupUserId: sessionStorage.getItem('cuid'),
    roleId: sessionStorage.getItem('roleId'),
    workspaceId: sessionStorage.getItem('wuid'),
    lastSerialNoGoogleAll: 0,
    lastSerialNoGoogleReviews: 0,
    lastSerialNoGoogleQandA: 0,
    lastSerialNoFacebookAll: 0,
    lastSerialNoFacebookReviews: 0,
    lastSerialNoFacebookComments: 0,
    lastSerialNoFacebookMentions: 0,
    lastSerialNoCFReviews: 0,
    lastSerialNoVTReviews: 0,
    lastSerialNoInboxWall: 0,
    lastSerialNoCFWall: 0,
    lastSerialNoVTWall: 0,
    lastSerialNoGoogleWall: 0,
    lastSerialNoFacebookWall: 0,
    key: wallDataKey[0],
    nextKey: wallDataKey[1],
    [wallDataKey[2]]: wallData?.length ? wallData?.length : 0,
    // mappedGoogleUserId: sessionStorage.getItem('muid'),
    // search
    providerType: wallDataKey[3],
    tag: "all",
    searchText: searchWallText,
    fromDate: "2000-01-01T00:00:00.00000", 
    toDate: new Date().toISOString().replace('Z', ''), 
    readStatus: "All",
    lastSerialNo: wallData?.length ? wallData?.length : 0,
  }
  
  useEffect(() => {  
    // console.log({wallData, conversations});  
    setConversations(wallData);
  }, [wallData?.length, route_path, conversations?.length]);

  const fetchMoreData = () => { 
    console.log({ conversations, wallNextDate });
    // if (route_path?.includes('/customer-feedback')) {
    //   wallPayload.customerFeedbackCreatedOn = new Date().toISOString().replace('Z', '');
    // } else if (route_path?.includes('/video-testimonial')) {
    //   wallPayload.videoTestimonialCreatedOn = new Date().toISOString().replace('Z', '');
    // } else if (route_path?.includes('/google')) {
    //   wallPayload.googleReviewsCreatedOn = new Date().toISOString().replace('Z', '');
    //   wallPayload.googleQuestionsCreatedOn = new Date().toISOString().replace('Z', '');
    // } else if (route_path?.includes('/facebook')) {
    //   wallPayload.facebookReviewsCreatedOn = new Date().toISOString().replace('Z', '');
    //   wallPayload.facebookCommentsCreatedOn = new Date().toISOString().replace('Z', '');
    //   wallPayload.facebookMentionsCreatedOn = new Date().toISOString().replace('Z', '');
    // } else if (route_path?.includes('/twitter')) {
    //   wallPayload.twitterMentionsCreatedOn = new Date().toISOString().replace('Z', '');
    // } else {
    //   wallPayload.googleReviewsCreatedOn = new Date().toISOString().replace('Z', '');
    //   wallPayload.facebookReviewsCreatedOn = new Date().toISOString().replace('Z', '');
    //   wallPayload.twitterMentionsCreatedOn = new Date().toISOString().replace('Z', '');
    //   wallPayload.customerFeedbackCreatedOn = new Date().toISOString().replace('Z', '');
    //   wallPayload.videoTestimonialCreatedOn = new Date().toISOString().replace('Z', '');
    //   wallPayload.googleQuestionsCreatedOn = new Date().toISOString().replace('Z', '');
    //   wallPayload.facebookCommentsCreatedOn = new Date().toISOString().replace('Z', '');
    //   wallPayload.facebookMentionsCreatedOn = new Date().toISOString().replace('Z', '');
    // }
    setIsLoading(true);
    dispatch(triggerWallUnified({ ...wallPayload, nextDate: wallNextDate }))
    // if (conversations?.length >= 100) {
    //   setHasMore(false);
    //   return;
    // }
    setTimeout(() => {
      // setConversations((prevConversations) => [...prevConversations, ...wallData]);
      setIsLoading(false);
    }, 3000);

    

  };
  return (
    <React.Fragment>
    {conversations?.length ? (
      
      <div className="wall-conversations-container w-100">
        {/* <Scrollbars
          style={{ display: 'flex', flexDirection: 'column', padding: '0 1.5% 0 0'}}
          // style={{ paddingRight: 10 }}
          autoHide
          autoHideTimeout={500}
          autoHideDuration={500}
          autoHeight
          // autoHeightMin={250}
          // autoHeightMax={250}
          autoHeightMin={"77vh"}
          autoHeightMax={"77vh"}
          thumbMinSize={10}
        > */}
          <InfiniteScroll
            dataLength={conversations?.length}
            // className="border"
            scrollThreshold={0.9}
            style={{ overflow: "scroll" }}
            next={() => {
              // console.log(
              //   // `prev -> ${conversations?.length}, next -> ${conversations?.length + 20}`
              // );
              fetchMoreData();

            }}
            inverse={false}
            hasMore={hasMore}
            loader={
              isLoading && conversations?.length ? (
                <div className="d-flex justify-content-center align-items-center mt-1">
                  <Spinner
                    size="sm"
                    animation="border"
                    variant="primary"
                  />
                </div>
              ) : null
            }
            scrollableTarget="scrollableDiv"
            // endMessage={
            //   <p style={{ textAlign: "center" }}>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }

            height={"79vh"}
          >
            <ResponsiveMasonry
                columnsCountBreakPoints={{576: 1, 750: 2, 900: 3}}
            >
              <Masonry gutter="10px">
                {
                  conversations?.map(
                    (conversation: any, idx: number) =>
                      conversation?.provider?.includes("google")
                      ? <GoogleData key={idx} handleDelete={handleDelete} wall_conversation={conversation} />
                      : conversation?.provider?.includes("facebook")
                      ? <FacebookData key={idx} handleDelete={handleDelete} wall_conversation={conversation} />
                      : conversation?.provider?.includes("twitter")
                      ? <TwitterData key={idx} handleDelete={handleDelete} wall_conversation={conversation} />
                      : conversation?.provider?.includes("customer")
                      ? <FeedbackData key={idx} handleDelete={handleDelete} wall_conversation={conversation} />
                      : conversation?.provider?.includes("video")
                      ? <VideoData key={idx} handleDelete={handleDelete} wall_conversation={conversation} />
                      : null
                      // : <WallConversation key={conversation?.idx} handleDelete={handleDelete} wall_conversation={conversation} />
                  )
                }
              </Masonry>
            </ResponsiveMasonry>
            
          </InfiniteScroll>
        {/* </Scrollbars> */}
      </div>
    ) : (
      <div className="empty-wall-section">
        <img src={emptyBox} alt="" />
        <h4>Your Wall is Empty.</h4>
        <span>Select an item from inbox to display!</span>
      </div>
    )}
    </React.Fragment>
  );
}

export default WallConversations;