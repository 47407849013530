import React, { useEffect, useState } from 'react';
import save_icon from '../../../../assets/icons/settings/save-icon.svg';
import edit_icon from '../../../../assets/icons/settings/edit-icon.svg';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import './GeneralSettings.scss';
import { setSuccessMessage } from '../../../../unified/reducer';
import { Spinner } from 'react-bootstrap';
import { toggleWorkspaceUpdate, updateWorkSettings } from '../../../Settings/WorkspaceSettings/workspaceReducer';
const GeneralSettings: React.FC = () => {
    // Workspace Name
    const { workspaceSettings, isUpdatedWorkspace } = useSelector((state: any) => state?.workspaceManagementState);
    const [workspaceName, setWorkspaceName] = useState<any>(null);
    // const [workspaceType, setWorkspaceType] = useState<any>(null);

    const [isWorkspaceNameActive, setIsWorkspaceNameActive] = React.useState(false);
    const [isWorkspaceNameError, setIsWorkspaceNameError] = React.useState(false);

    const [loading, setLoading]: any = React.useState(false);
    const dispatch = useDispatch();
    const {personalInfo} = useSelector((state: any) => state?.profileManageSettingState);
    const { email }: any = personalInfo || {};
    
    useEffect(() => {
        // console.log({ isUpdatedWorkspace });
        if (isUpdatedWorkspace) {
            // setWorkspaceName(workspaceSettings?.workspaceName);
            // dispatch(findWorkspaceList());
            dispatch(toggleWorkspaceUpdate(false));
        }
    }, [isUpdatedWorkspace]);

    // useEffect(() => {
    //     if (email) {
    //         setTimeout(() => {
    //             setLoading(false);
    //         }, 2000);
    //     } else {
    //         setLoading(true);
    //         dispatch(getProfileInfo());
    //     } 
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 5000);
    // } , [dispatch, email]);

    // useEffect(() => {
    //     console.log({workspaceManagementState});
    //     dispatch(getWorkspaceSettings());
    // } , [dispatch]);

    const handleUpdateWorkspaceSettingField = (columnName: any, columnValue: any, setWorkspaceState: any) => {
        // console.log({columnValue});
        if(!columnValue){
            setWorkspaceState("error");
        }else {
            const dataObject = {
                columnName,
                columnValue,
                clapupId: sessionStorage.getItem('cuid'),
                workSpaceId: sessionStorage.getItem('wuid'),
            }
            console.log({dataObject});
            dispatch(updateWorkSettings(dataObject));
            // setWorkspaceState(null);
            dispatch(setSuccessMessage("Settings saved."))
        }
        
    }

    const {userPermissions} = useSelector((state: any) => state?.login);
    const history = useNavigate();
    
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "generalSettings-WorkSpace") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions?.length]);
    
    return (
        <React.Fragment>
            <div className='lg-sub-menu h-100 p-0' style={{alignItems: 'flex-start'}}>
                {
                    loading
                    ? <div className='loading-container w-100 h-100 d-flex justify-content-center align-items-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : <div className='workspace-menu-container'>
                        <div className="form-container h-100 general-settings-gap">

                            {/* Workspace Name */}
                            <div className="form-group">
                                <div className='input-container general-input-container'>
                                    {
                                        isWorkspaceNameActive
                                        ? <input  
                                            defaultValue={workspaceName || workspaceSettings?.workspaceName || ""}
                                            
                                            readOnly={!isWorkspaceNameActive}
                                            autoFocus={true}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value) {
                                                    setWorkspaceName(value);
                                                    setIsWorkspaceNameError(false);
                                                } else {
                                                    setWorkspaceName("");
                                                    setIsWorkspaceNameError(true);
                                                }
                                            }}
                                            type="text" 
                                            placeholder="Workspace Name"
                                            className={`${isWorkspaceNameError ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>Workspace Name: {workspaceName || workspaceSettings?.workspaceName || ""}</span>
                                    }
                                    
                                    <img
                                        src={isWorkspaceNameActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isWorkspaceNameError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isWorkspaceNameError) {
                                                if (isWorkspaceNameActive && workspaceName) {
                                                    setIsWorkspaceNameActive(false);
                                                    handleUpdateWorkspaceSettingField("WorkspaceName", workspaceName === true ? workspaceSettings?.workspaceName : workspaceName, setWorkspaceName);
                                                } else {
                                                    setIsWorkspaceNameActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                </div>
                            </div>

                            {/* Workspace Type */}
                            <div className="form-group">
                                <div className="input-container general-input-container">
                                    <input
                                        value={`Workspace Type: ${workspaceSettings?.workspaceType || ""}`}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Workspace Type"
                                        className={`border-none`}
                                    />
                                </div>
                            </div>
                            
                            {/* Users Limit: 10 */}
                            <div className="form-group">
                                <div className="input-container general-input-container">
                                    <input
                                        value={`Users Limit: ${workspaceSettings?.usersLimit || 10}`}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Users Limit"
                                        className={`border-none`}
                                    />
                                </div>
                            </div>
                            
                            {/* Locations Limit: 10 */}
                            <div className="form-group">
                                <div className="input-container general-input-container">
                                    <input
                                        value={`Locations Limit: ${workspaceSettings?.locationsLimit || 10}`}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Locations Limit"
                                        className={`border-none`}
                                    />
                                </div>
                            </div>
                            
                            {/* Facebook pages Limit: 10 */}
                            <div className="form-group">
                                <div className="input-container general-input-container">
                                    <input
                                        value={`Facebook pages Limit: ${workspaceSettings?.fbPagesLimit || 10}`}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Facebook pages Limit"
                                        className={`border-none`}
                                    />
                                </div>
                            </div>
                            
                            {/* Biopages Limit: 10 */}
                            <div className="form-group">
                                <div className="input-container general-input-container">
                                    <input
                                        value={`Biopages Limit: ${workspaceSettings?.biopagesLimit || 1}`}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Biopages Limit"
                                        className={`border-none`}
                                    />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                }
            </div>

            <div className='mobile-sub-menu w-100'>
                {
                    loading
                    ? <div className='loading-container w-100 d-flex justify-content-center align-items-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : <div className='workspace-menu-container'>
                        <div className="form-container h-100 general-settings-gap">

                            {/* Workspace Name */}
                            <div className="form-group">
                                <div className='input-container general-input-container'>
                                    {
                                        isWorkspaceNameActive
                                        ? <input  
                                            defaultValue={workspaceName || workspaceSettings?.workspaceName || ""}
                                            
                                            readOnly={!isWorkspaceNameActive}
                                            autoFocus={true}
                                            onChange={(e: any) => {
                                                const { value } = e.target;
                                                if (value) {
                                                    setWorkspaceName(value);
                                                    setIsWorkspaceNameError(false);
                                                } else {
                                                    setWorkspaceName("");
                                                    setIsWorkspaceNameError(true);
                                                }
                                            }}
                                            type="text" 
                                            placeholder="Workspace Name"
                                            className={`${isWorkspaceNameError ? "input-error" : ""}`}
                                        />
                                        : <span className='input-value'>Workspace Name: {workspaceName || workspaceSettings?.workspaceName || " "}</span>
                                    }
                                    
                                    <img
                                        src={isWorkspaceNameActive ? save_icon : edit_icon}
                                        className={`add-edit-save-icon ${isWorkspaceNameError ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (!isWorkspaceNameError) {
                                                if (isWorkspaceNameActive && workspaceName) {
                                                    setIsWorkspaceNameActive(false);
                                                    handleUpdateWorkspaceSettingField("WorkspaceName", workspaceName === true ? workspaceSettings?.workspaceName : workspaceName, setWorkspaceName);
                                                } else {
                                                    setIsWorkspaceNameActive(true);
                                                }
                                            }
                                        }}
                                        alt='save-edit'
                                    />
                                </div>
                            </div>

                            {/* Workspace Type */}
                            <div className="form-group">
                                <div className="input-container general-input-container">
                                    <input
                                        value={`Workspace Type: ${workspaceSettings?.workspaceType || ""}`}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Workspace Type"
                                        className={`border-none`}
                                    />
                                </div>
                            </div>

                            {/* Users Limit: 10 */}
                            <div className="form-group">
                                <div className="input-container general-input-container">
                                    <input
                                        value={`Users Limit: ${workspaceSettings?.usersLimit || 10}`}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Users Limit"
                                        className={`border-none`}
                                    />
                                </div>
                            </div>

                            {/* Locations Limit: 10 */}
                            <div className="form-group">
                                <div className="input-container general-input-container">
                                    <input
                                        value={`Locations Limit: ${workspaceSettings?.locationsLimit || 10}`}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Locations Limit"
                                        className={`border-none`}
                                    />
                                </div>
                            </div>

                            {/* Facebook pages Limit: 10 */}
                            <div className="form-group">
                                <div className="input-container general-input-container">
                                    <input
                                        value={`Facebook pages Limit: ${workspaceSettings?.fbPagesLimit || 10}`}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Facebook pages Limit"
                                        className={`border-none`}
                                    />
                                </div>
                            </div>

                            {/* Biopages Limit: 10 */}
                            <div className="form-group">
                                <div className="input-container general-input-container">
                                    <input
                                        value={`Biopages Limit: ${workspaceSettings?.biopagesLimit || 1}`}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Biopages Limit"
                                        className={`border-none`}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                }
            </div>
           
        </React.Fragment>
    );
};
export default GeneralSettings;
