import { takeEvery, put, call, all } from 'redux-saga/effects';
import Constants from '../../../constants';
import axios from "axios";

import { findRoleList, setWorkspaces } from './headerReducer';
import { generateAccessToken } from '../../../profile/reducer';
import { api_url } from '../../../utils/server';

// Worker Saga:
function* findWorkspaces(): Generator<any> {
    
    // console.log("header -> Worker saga => getWorkspacesSaga action===>");
    // const cuid = sessionStorage.getItem('cuid') || "1edcb220-3cfa-45f5-8320-d7a3d64868e4";
    const cuid = sessionStorage.getItem('cuid') || localStorage.getItem('cuid');
    if (!cuid) {
        return;
    }
    let url = api_url + '/api/v1.2/Clapup/GetWorkspaceList?clapupId=' + cuid;
    try {
        const { data, status }: any = yield call(axios.get, url, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(setWorkspaces(data));
        }
    } catch (error) {
        const { response }: any = error;
        console.log('setWorkspaces error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

function* findUserRoles(action: any): Generator<any> {
    // console.log("header -> Worker saga => findUserRoles action===>", action);
    // const cuid = sessionStorage.getItem('cuid');
    let url = api_url + '/api/v1.2/ClapupIdentity/AccessLevelwiseRoleList';
    try {
        const { data, status }: any = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        if (status === 200) {
            yield put(findRoleList(data));
        } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        (status === 403 || status === 401) && (yield put(generateAccessToken()));
    }
}

// Watcher Saga:
function* headerSaga() {    
    yield all([
        takeEvery(Constants.FIND_WORKSPACE_LIST, findWorkspaces),
        takeEvery(Constants.GET_ROLES, findUserRoles),
    ]);
}

export default headerSaga;