import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import ContentImageAndVideo from './ContentImageAndVideo';
import {
  // removeContentsFromFolder, setFolderContents, getOneTimeFolderContents, setOneTimeFolderContents,
  setDefaultContentIdx, setSelectedDeletableContents,
  // getFolderContents, setIsUpdatedContent, deleteContents,
  getContentVersions,
  // setShowingUploadingProgress,
  // // setIsContentUploadedError,
  // setUploadProgressCount
} from '../mediaReducer';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import { Spinner } from 'react-bootstrap';
// import { download2Content, modifyString } from '../../../utils/mediaService';
// import CustomDropdown from '../../CustomDropdown/CustomDropdown';
import BigDeviceContents from './DeviceContents/BigDeviceContents';
import SmallDeviceContents from './DeviceContents/SmallDeviceContents';
const Contents = ({activeFolder, setEditableContent, setNewVersionVideoFile, handleUploadNewVersionFile}: any) => {
  const {
    searched_content_text,
    folder_contents, contentNextDate, is_updated_folder_content,
    selected_deletable_contents, default_content_idx, show_uploading_progress,
  }: any = useSelector((state: RootState) => state?.mediaState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route_path = window.location.pathname;
  const query_string = window.location.search;
  const path = query_string.replace('?', '')?.replace('&', "=");
  const path_array = path.split("=");
  // const [showDropdown, setShowDropdown] = React.useState<any>(null);
  // // const [selectedImages, setSelectedImages] = React.useState<any[]>([]);
  // const [isSelectAll, setIsSelectAll] = React.useState<boolean>(false);
  // const [updatableContent, setUpdatableContent] = useState<any>(null);
  // const [isStateChange, setIsStateChange] = useState<any>(null);

  useEffect(() => {
    console.log({ path_array });
    
    if (query_string?.includes("=folders") && default_content_idx !== null) {
      dispatch(setDefaultContentIdx(null));
      dispatch(setSelectedDeletableContents([]));
    } else if (query_string?.includes("=contents") && path_array?.length > 3 && folder_contents?.length) {
      try {
        const idx = Number(path_array[3]);
        if (contents?.length > idx || contents?.length === idx) {
          if (contents[idx]?.id) {
            dispatch(setDefaultContentIdx(idx));
            // get all versions
            // console.log({content_id: contents[idx]?.id});
            
            dispatch(getContentVersions(contents[idx]?.id));
          }else {
            navigate(`${route_path}?${activeFolder}=folders`);
          }
          // dispatch(setSelectedDeletableContents([contents[idx]?.id]));
        } else {
          navigate(`${route_path}?${activeFolder}=folders`);
        }
      } catch (error) {
        console.log({ error });
        navigate(`${route_path}?${activeFolder}=folders`);
      }
    } else if (query_string?.includes("=contents") && path_array?.length < 4 && folder_contents?.length) { 
      navigate(`${route_path}?${activeFolder}=folders`);
    }
  }, [query_string, default_content_idx]);

  const [contents, setContents] = useState<any[]>(folder_contents);
  useEffect(() => {
    if (searched_content_text) {
      const filtered_contents = folder_contents?.filter((content: any) => content?.contentFileName?.toLowerCase().includes(searched_content_text.toLowerCase()));
      setContents(filtered_contents);
    } else {
      if(folder_contents?.length) {
        setContents(folder_contents); 
      }
    }
  // }, [query_string]);
  }, [searched_content_text]);
  
  useEffect(() => {
    if(folder_contents?.length || is_updated_folder_content) {
      // dispatch(setIsUpdatedContent(false));
      // setUpdatableContent(null);
      // setIsStateChange(null);
      // setShowDropdown(null);
      setTimeout(() => {
        setContents(folder_contents);
      }, 1000);
    }
  }, [folder_contents?.length, is_updated_folder_content]);

  // useEffect(() => {
  //   // console.log({selected_deletable_contents});
    
  //   if (selected_deletable_contents?.length) {
  //     setIsSelectAll(true);
  //     // dispatch(setSelectedDeletableContents(selectedImages));
  //   } else {
  //     setIsSelectAll(false);
  //     if (query_string?.includes("=folders")) {
  //       dispatch(setSelectedDeletableContents([]));
  //     }
  //   }
    
  //   // if(isSelectAll && !selected_deletable_contents?.length) {
  //   //   setSelectedImages([]);
  //   // }
    
  // }, [selected_deletable_contents?.length]);

  // const handleSelectAll = (e: any) => {
  //   const { checked } = e.target;
  //   if (checked) {
  //     const ids = folder_contents?.map((item: any) => item?.id);
  //     // setSelectedImages(ids);
  //     dispatch(setSelectedDeletableContents(ids));
  //   } else {
  //     // setSelectedImages([]);
  //     dispatch(setSelectedDeletableContents([]));
  //   }
    
  //   console.log({checked});
  //   setIsSelectAll(checked);
  // };

  // const handleSelectImage = (e: any, imageId: any) => {
  //   if (e) {
  //     // setSelectedImages([...selectedImages, imageId]);
  //     dispatch(setSelectedDeletableContents([...selected_deletable_contents, imageId]));
  //   } else {
  //     // setSelectedImages(selectedImages.filter((item: any) => item !== imageId));
  //     dispatch(setSelectedDeletableContents(selected_deletable_contents.filter((item: any) => item !== imageId)));
  //   }
  // };

  // const [deleteFolderContentId, setDeleteFolderContentId] = useState(null);
  // const handleDeleteFolderContent = (contentId: any) => {
  //   console.log({contentId});
    
  //   if (!contentId) {
  //     setDeleteFolderContentId(null);
  //   } else {
  //     dispatch(deleteContents([contentId]));
  //     // dispatch(setSelectedDeletableContents([]));
  //     // dispatch(setInfoMessage('File deleted successfully.'));
  //     setTimeout(() => {
  //       setDeleteFolderContentId(null);
  //       setShowDropdown(null);
  //     }, 2000);
  //   }
  // };
  
  // const handleUpdateContent = (content?: any, key?: string) => {
  //   if (!content) {
  //     setUpdatableContent(null);
  //   } else {
  //     const payloadObject: any = {
  //       contentId: content?.id,
  //       clapupUserId: sessionStorage.getItem('cuid'),
  //       workspaceId: sessionStorage.getItem('wuid'),
  //       roleId: sessionStorage.getItem('roleId')
  //     };

  //     if (key === "sticky") {
  //       payloadObject.stageName = "sticky";
  //       payloadObject.stageValue = content?.isSticky ? "0" : "1";
  //       content.isSticky = !content?.isSticky;
  //     } else if(key === "biopage") {
  //       payloadObject.stageName = "biopage";
  //       payloadObject.stageValue = content?.addedToBioPage ? "0" : "1";
  //       content.addedToBioPage = !content?.addedToBioPage;
  //     } else if (key === "state") {
  //       payloadObject.stageName = "publish";
  //       payloadObject.stageValue = content?.stage === "publish" ? "0" : "1";
  //       content.stage = content?.stage === "publish" ? null : "publish";
  //     }
  //     console.log({ payloadObject, content});
  //     dispatch(updateFolderContent(payloadObject, content));
  //   }
  // };

  // const handleStateChange = (content?: any) => {
  //   console.log({content});
  //   if (!content) {
  //     setIsStateChange(null);
  //   } else {

  //     handleUpdateContent(content, "state");
  //     // dispatch(setInfoMessage('File state changed successfully.'));
  //     setIsStateChange(null);
  //   }
  // };

  // const [searchText, setSearchText] = React.useState<string>('');
  // const [isLoading, setIsLoading] = React.useState<boolean>(true);
  // const [hasMore, setHasMore] = React.useState<boolean>(true);

  // const fetchMoreData = () => {
  //   setIsLoading(true);
  //   if (!contentNextDate) {
  //       setHasMore(false);
  //       setIsLoading(false);
  //       return;
  //   }
  //   setTimeout(() => {
  //     // // setContents(contents.concat(contents));
  //     const contentPayload: any = {
  //       folderName: activeFolder,
  //       nextDate: contentNextDate,
  //     };
  //     dispatch(getFolderContents(contentPayload));
  //     setIsLoading(false);
  //   }, 1500);
  // };

  // console.log({ contents });

  return (
    <React.Fragment>
      {/* New Version Upload  -----  start */}
      {/* New Version Upload  -----  end */}

      
      {/* {
        deleteFolderContentId ?
          <Dialog
            open={deleteFolderContentId}
            parentCallback={(id: any) => handleDeleteFolderContent(id)}
            classes='dialog-container-content'
            title='Delete File?'
            warning_dialog_content='If you click Yes, file will be deleted forever. This action is irreversible.'
            footer={true}
            left_btn_hover='No, Cancel'
            right_btn_hover='Yes, Delete'
          />
        : updatableContent ?
          <Dialog
            open={updatableContent}
              parentCallback={(id: any) => {
                // console.log({id});
                if (id) {
                  handleUpdateContent(updatableContent, "biopage");
                } else {
                  setUpdatableContent(null);
                }
              }}
            classes='dialog-container-content'
            title='Continue?'
            right_button_text='Yes, Continue'
            warning_dialog_content={
              !updatableContent?.addedToBioPage
              ? 'If you click Yes, image will be added to your biopage gallery section.'
              : 'If you click Yes, image will be removed from your biopage gallery section.'
            }
            footer={true}
          />
        : isStateChange ?
            <Dialog
              open={isStateChange}
              parentCallback={(content: any) => {
                console.log({isStateChange, content});
                if (content) {
                  handleStateChange(content);
                } else {
                  setIsStateChange(null);
                }
              }}
              classes='dialog-container-content'
              title='Continue?'
              right_button_text='Yes, Continue'
              warning_dialog_content={
                isStateChange?.stage === "publish"
                ? 'If you click Yes, image will become private and will not be available through a unique url.'
                : 'If you click Yes, image will become public and will be available through a unique url.'
              }
              footer={true}
            />
        : null
      } */}

      {
        query_string?.includes('=contents') && contents?.length && default_content_idx !== null
        ? <ContentImageAndVideo activeFolder={ activeFolder } />
        : <>
          {
            !contents?.length
            ? <div className='d-flex justify-content-center align-items-center' style={{height: '70vh'}}>
                <p style={{fontSize: '1.5rem'}} className='text-center'>No content found. Please upload a content</p>
              </div>
            : <div className="table-responsive">
                {/* bootstrap responsive table */}
                {/* <table className="table table-borderless sticky-top m-0">
                  <thead className='content-thead'>
                    <tr>
                      <th style={{width: '20%', textAlign: 'start'}}>Name</th>
                      <th style={{width: '20%'}}>Updated Date</th>
                      <th style={{width: '20%'}}>Created By</th>
                      <th style={{width: '12%'}}>Biopage</th>
                      <th style={{width: '5%', color: 'transparent'}}>Ellipse</th>
                      <th style={{width: '9%'}}>State</th>
                      <th style={{ width: '6%', textAlign: 'center' }}>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={isSelectAll}
                          onChange={handleSelectAll}
                        />
                      </th>
                    </tr>
                    <tr style={{border: 'none !important'}}>
                      <th className='p-0'><br/></th>
                    </tr>
                  </thead>
                </table> */}
                {/* <div className='infinity-scroll'> */}
                <div className='md-sm-device'>
                  <SmallDeviceContents
                    activeFolder={activeFolder}
                    setEditableContent={setEditableContent}
                    setNewVersionVideoFile={setNewVersionVideoFile}
                    handleUploadNewVersionFile={handleUploadNewVersionFile}
                  />
                </div>
                  
                <div className='lg-md-device'>
                  <BigDeviceContents
                    activeFolder={activeFolder}
                    setEditableContent={setEditableContent}
                    setNewVersionVideoFile={setNewVersionVideoFile}
                    handleUploadNewVersionFile={handleUploadNewVersionFile}
                  />
                </div>
              </div>
          }
            
          </>
      }
      
    </React.Fragment>
  )
}


export default Contents;

