import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getImage } from "../../../unified/image_service";
import { setTeamTab, setEditableTeam, setEditableTeamChannel, setReadyToTeamMemberUser, setReadyToAddChannel, resetTeamTab } from "./teamsReducer";
import TeamMembers from "./Components/TeamMembers";
import Channels from "./Components/Channels";
import SettingsTeam from "./Components/SettingsTeam";
import { toggleShowMobileSettingsNavBar } from "../../../signup-login/loginReducer";

const TeamManagement: React.FC = () => {
    const { userPermissions, is_show_mobile_settings_nav_bar } = useSelector((state: any) => state?.login);

    const dispatch = useDispatch();
    const history = useNavigate();
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "workspaceSettings") : null;
        // const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "generalSettings-Account") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions, history]);
    const { team_tab, editable_team_channel, editable_team } = useSelector((state: any) => state?.teamState);
    const handleTabChange = (tab?: any) => {
        dispatch(setTeamTab(tab));
    }
    return (
        <React.Fragment>
            <div className="button-container">
                <img className="mobile-setting-nav-bar"
                    onClick={() => dispatch(toggleShowMobileSettingsNavBar(!is_show_mobile_settings_nav_bar))}
                    src={getImage("mobile-nav-icon")}
                    alt='hum-burger'
                />
                {
                    editable_team_channel
                    ?   <button>
                            <div>Save Changes</div>
                        </button>
                    : team_tab === "Team Members"
                    ?   <button onClick={() => dispatch(setReadyToTeamMemberUser(true))}>
                            <div>Add User</div>
                            <img src={getImage("add-plus")} alt="invite-icon" />
                        </button>
                    : team_tab === "Channels"
                    ?   <button onClick={() => dispatch(setReadyToAddChannel(true))}>
                            <div>Add Channel</div>
                            <img src={getImage("add-plus")} alt="invite-icon" />
                        </button>
                    : team_tab === "Settings"
                    ?   <button>
                            <div>Save Changes</div>
                        </button>
                    :   <button>
                            <div>Save Changes</div>
                        </button>
                }
            </div>
            <div className="body-container">
            <div className="team-management-settings-container">
                <div className="settings-header-title">
                    <div 
                        className="back-path-container"
                        onClick={() => {
                            dispatch(setEditableTeam(null));
                            dispatch(resetTeamTab(null));
                        }}
                    >
                        <img className="back-arrow" src={getImage("back-arrow")} alt="back-arrow" />
                        <h3>{editable_team?.teamName}</h3>
                    </div>
                </div>
                {
                    editable_team_channel
                    ?   <div className="settings-sub-header-title">
                            <div 
                                className="back-path-container"
                                onClick={() => dispatch(setEditableTeamChannel(null))}
                            >
                                <img className="back-arrow" src={getImage("back-arrow")} alt="back-arrow" />
                                <h3>Channel Settings</h3>
                            </div>
                        </div>
                    :   <div className="settings-header-tab">
                            <div className={`tab ${team_tab === "Team Members" ?  "active" : ""}`} onClick={() => handleTabChange("Team Members")}>Team Members</div>
                            <div className={`tab ${team_tab === "Channels" ?  "active" : ""}`} onClick={() => handleTabChange("Channels")}>Channels</div>
                            <div className={`tab ${team_tab === "Settings" ?  "active" : ""}`} onClick={() => handleTabChange("Settings")}>Settings</div>
                        </div>
                }
                
                {
                    team_tab === "Team Members" ? <TeamMembers/> 
                    : team_tab === "Channels" ? <Channels/> 
                    : <SettingsTeam/>
                }
                </div>
            </div>
        </React.Fragment>
    );
};

export default TeamManagement;