import { Select, Switch } from 'antd';
// import SearchOutlined from '@ant-design/icons/SearchOutlined';
import React, { Fragment, useEffect, useState } from 'react';
import './UserManagementSettings.scss';
// import { DebounceInput } from 'react-debounce-input';
import { useSelector, useDispatch } from 'react-redux';
import delete_icon from '../../../assets/icons/settings/user-management/user-delete.svg';
// import search_icon from '../../assets/icons/settings/user-management/search-icon.svg';
import arrow_icon from '../../../assets/icons/settings/user-management/arrow-down.svg';
import InviteForm from './components/InviteForm';
import UserTable from './components/UserTable/UserTable';
import { useNavigate } from 'react-router';
import { deleteUser, sendInvitationToUser, setIsInvitationSent, toggleIsReadyToGetUsers, updateUserRole, updateUserStatus } from '../../Settings/UserManagementSettings/userManagementReducer';
import useDebounce from '../../../hooks/useDebounce';
import Dialog from '../../../components/Dialogs/Dialog';
import CommonModalCustomWidth from '../../../components/CommonModal/CommonModalCustomWidth';
import { getImage } from '../../../unified/image_service';
import { setErrorMessage } from '../../../unified/reducer';
import { toggleShowMobileSettingsNavBar } from '../../../signup-login/loginReducer';
// import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
// import { getRoles } from '../../components/layout/header/headerReducer';
// import { dummy_data, roles } from './dummy_data';

const UserManagementSettings: React.FC = () => {
    const [selectedRows, setSelectedRows] = React.useState([]);
    const {users, isInvitationSent, error} = useSelector((state: any) => state?.userManagementState);
    const {roles, workspaces} = useSelector((state: any) => state?.headerState);
    const dispatch = useDispatch();
    const [modal_visible, setModalVisible]: any = React.useState(false);
    const [table_data, setTableData]: any = React.useState([]);
    const [table_columns, setTableColumns]: any = React.useState([]);
    const [isLoading, setIsLoading]: any = React.useState(true);
    // rowSelection object indicates the need for row selection
    
    const [selectionType, setSelectionType] = React.useState<'checkbox' | 'radio'>('checkbox');
    // console.log("Workspace ==== ", {headerState});
    const updateRole = (dataObj: any) => {
        console.log("dataObj ==== ", {dataObj});
        dispatch(updateUserRole(dataObj));
    }

    // userManagementState theke data update hobe + api call kore db theke update hobe
    const updateStatus = (dataObj: any) => {
        console.log("dataObj ==== ", {dataObj});
        dispatch(updateUserStatus(dataObj));
    }

    const [showDeleteUser, setShowDeleteUser] = useState<any>(null);

    // userManagementState theke data delete korte hobe + api call kore db theke delete hobe
    const deleteFromTable = (dataObj: any) => {
        const {userId} = dataObj;
        console.log("dataObj ==== ", {dataObj});
        // const data = userManagementState?.users?.filter((item: any) => item?.userId !== userId);
        const data = table_data?.filter((item: any) => item?.userId !== userId);
        console.log({table_data: data});
        dispatch(deleteUser(userId));
        setTimeout(() => { 
            setTableData(data);
            // dispatch(updateUserStatus(dataObj));
            setShowDeleteUser(null);

        }, 2000);
    }

    useEffect(() => {
        if(users?.length){
            const columns = [
                // {
                //     title: 'Name',
                //     dataIndex: 'name',
                //     key: 'name',
                //     render: (text: any, _: any) => <span>{text}</span>,
                //     width: '15%',
                //     // align: 'center',
                // },
                // {
                //     title: '#',
                //     dataIndex: 'idx',
                //     key: 'idx',
                //     render: (text: any, record: any) => {
                //         // console.log('users idx === ', record);
                //         return <span>{text}</span>
                //     },
                //     width: '10%',
                // },
                {
                    title: 'User Details',
                    dataIndex: 'email',
                    key: 'email',
                    render: (text: any, _: any) => <span>{text}</span>,
                    // width: '33%',
                    // align: 'center',
                },
                {
                    title: 'Role',
                    dataIndex: 'role',
                    key: 'role',
                    render: (text: any, record: any) => {
                        // console.log('record === ', record);
                        // console.log('roles', roles);
                        return (
                            <Fragment>
                                {
                                    record?.role?.includes("Super")
                                    ? <p className='super-admin'>Super Admin</p>
                                    : <Select
                                        defaultValue={record?.role?.includes("Super") ? "Super Admin" : text}
                                        style={{
                                            fontSize: 10
                                        }}
                                        onChange={(value: any) => {
                                            updateRole({userId: record?.userId, roleId: value, workspaceId: sessionStorage.getItem('wuid')});
                                        }}
                                        placeholder="Select"
                                        size='small'
                                        // allowClear
                                        disabled={record?.role?.includes("Super") || false}
                                        suffixIcon={record?.role?.includes("Super") ? false : <img src={arrow_icon} className='arrow-icon' alt='arrow-down' />}
                                    >
                                        {
                                            roles?.map((item: any, idx: number) => {
                                                return (
                                                    <Select.Option key={idx} value={item?.id}>{item.roleName}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                }
                                
                            </Fragment>
                        );
                        
                    },
                    align: 'center',
                    width: '18%',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    render: (text: any, _: any) => <span>{text}</span>,
                    align: 'center',
                    width: '15%',
                },
                {
                    title: 'Disable',
                    dataIndex: 'action',
                    key: 'action',
                    render: (text: any, record: any) => {
                        // console.log({text, record});
                        
                        return (
                            <Fragment>
                                <Switch
                                    // checkedChildren={<CheckOutlined />}
                                    // unCheckedChildren={<CloseOutlined />}
                                    size='small'
                                    defaultChecked={record?.role === "Super Admin" ? true : record?.status === "Active" ? true : false}
                                    onChange={(checked: any) => {
                                        console.log('checked', checked);
                                        updateStatus({ userId: record?.userId, status: checked ? "Active" : "Inactive", workspaceId: sessionStorage.getItem('wuid') });
                                    }}
                                    disabled={record?.role?.includes("Super") || false}
                                />
                                {/* <Select
                                    defaultValue={record?.role === "Super Admin" ? "Enabled" : record?.status === "Active" ? "Enabled" : "Disabled"}
                                    style={{ width: 120, fontSize: 10 }}
                                    onChange={(value: any) => {
                                        console.log('value', value);
                                        updateStatus({userId: record?.userId, status: value, workspaceId: sessionStorage.getItem('wuid')});
                                    }}
                                    placeholder="Select"
                                    allowClear
                                    disabled={record?.role?.includes("Super") || false}
                                >
                                    <Select.Option key={"enable"} value="Enable"><span style={{height: 3, width: 3, padding: "0 6px", margin: "2px 8px 2px 5px"}} className='border border-success rounded-circle'></span> Enable</Select.Option>
                                    <Select.Option key={"disable"} value="Disable"><span style={{height: 3, width: 3, padding: "0 6px", margin: "2px 8px 2px 5px"}} className='border border-danger rounded-circle'></span> Disable</Select.Option>
                                </Select> */}
                            </Fragment>
                        );
                    },
                    align: 'center',
                    width: '20%',
                },
                {
                    title: 'Delete',
                    key: 'delete',
                    render: (_: any, record: any) => (
                        <span
                        onClick={() => {
                                if (!record?.role?.includes("Super")) {
                                    setShowDeleteUser(record);
                                    // deleteFromTable({userId: record?.userId, status: "Delete", workspaceId: sessionStorage.getItem('wuid')});
                                }
                            }}
                        >
                            {
                                record?.role?.includes("Super")
                                ? null
                                : <img 
                                    width={11} 
                                    src={delete_icon} 
                                    className="img-fluid" 
                                    style={{cursor: 'pointer'}} 
                                />
                            }
                        </span>
                    ),
                    align: 'center',
                    width: '10%',
                },
            ];
            
            setIsLoading(false);
            
            setTableData(users);
            setTableColumns(columns);
        }
    }, [setSelectionType, users?.length]);

    // useEffect(() => {
    //     if(isInvitationSent){
    //         form?.resetFields();
    //         setModalVisible(false);
    //         dispatch(toggleIsReadyToGetUsers(true));
    //         dispatch(setIsInvitationSent(false));
    //     }
    // }, [isInvitationSent]);

    // Here are many works
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record: any) => ({
            disabled: record?.role?.includes("Super"), // Column configuration not to be checked
            name: record.name,
        }),
    };

    const handleSubmitForm = (values: any) => {
        // form.preventDefault();
        const currentUser: any = workspaces?.find((item: any) => item?.userId === sessionStorage.getItem('cuid'));
        console.log("currentUser ==== ", currentUser, values);
        const dataObject: any = {
            ...values,
            workspaceId: sessionStorage.getItem('wuid'),
            invitedBy: currentUser?.userId,
            senderEmailId: currentUser?.email,
        };
        // console.log("formData ==== ", {formData: dataObject});
        dispatch(setErrorMessage(null));
        dispatch(sendInvitationToUser(dataObject));
        // form?.resetFields()
    }

    const handleSearchForTableColumn = (value: any) => {
        // console.log("value ==== ", value);
        const isEmailCheck = value?.includes('@');
        // const isNameCheck = value.includes(' ');
        // const isNumberCheck = value.includes('0') || value.includes('1') || value.includes('2') || value.includes('3') || value.includes('4') || value.includes('5') || value.includes('6') || value.includes('7') || value.includes('8') || value.includes('9');
        // console.log({userManagementState})
        if(isEmailCheck){
            const data = users?.filter((item: any) => item?.email.includes(value));
            setTableData(data);
            if(!data?.length){
                setIsLoading(true);
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
            }
        } else{
            const data = users?.filter((item: any) => {
                return item?.name?.toLowerCase().includes(value?.toLowerCase());
            })
            // .map((item: any) => {
            //     return {
            //         ...item,
            //         key: item.userId,
            //     }
            // }).slice(0, 10);
            // console.log("data ==== ", data);
        
            if(!data?.length){
                const data = users?.filter((item: any) => item?.email.includes(value));
                setTableData(data);
                if(!data?.length){
                    setIsLoading(true);
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 1000);
                }
            }else{
                setTimeout(() => {
                    setIsLoading(false);
                }, 5000);
                setTableData(data);
            }
        }
    }
    const { userPermissions, is_show_mobile_settings_nav_bar } = useSelector((state: any) => state?.login);
    const history = useNavigate();
    
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "userManagement") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions?.length]);
    
    const [text, setText] = React.useState("")

    const debounce = useDebounce(text, 2000);
    React.useEffect(() => {
        handleSearchForTableColumn(debounce);
    }, [debounce]);
    return (
        <React.Fragment>
            {
                showDeleteUser !== null
                ?  <Dialog
                        open={showDeleteUser}
                        onCancel={() => setShowDeleteUser(null)}
                        parentCallback={(record?: any) => {
                            deleteFromTable( { userId: record?.userId, status: "Delete", workspaceId: sessionStorage.getItem('wuid') });
                        }}
                        classes='dialog-container-content'
                        header='Delete User?'
                        warning_dialog_content={"If you continue, the user account will be deleted. All the data related to the user will be deleted for ever"}
                        footer={true}
                        left_button_text='No, Cancel'
                        right_button_text='Yes, Continue'
                        // hover={true}
                    />
                : null
            }
            <div className="button-container">
                <img className="mobile-setting-nav-bar"
                    onClick={() => dispatch(toggleShowMobileSettingsNavBar(!is_show_mobile_settings_nav_bar))}
                    src={getImage("mobile-nav-icon")}
                    alt='hum-burger'
                />
                <button onClick={() => setModalVisible(true)}>
                    <div>Invite User</div>
                    <img
                        src={getImage("add-plus")}
                        alt="invite-icon"
                    />
                </button>
            </div>
            <div className="body-container">
                
                <div className='user-management-settings-container'>
                    <div className="settings-header-title">
                        <h3>User Management</h3>
                    </div>
                    
                    {/* <Input 
                        // status="warning"
                        className='search-input'
                        onChange={(e: any) => {
                            setText(e?.target?.value);
                        }}
                        placeholder="Search Users"
                        prefix={<img src={search_icon} className='search-icon' alt='search-icon'/>} 
                        // prefix={<SearchOutlined className='search-icon' />} 
                    /> */}
                    <UserTable 
                        // width={750} 
                        width={550} 
                        // height={450}
                        rowSelection_data={{
                            type: selectionType,
                            ...rowSelection,
                        }}
                        loading={(isLoading) ? true : false}
                        table_data={table_data} 
                        table_columns={table_columns}
                        roles={roles}
                        updateRole={updateRole}
                        updateStatus={updateStatus}
                        deleteFromTable={deleteFromTable}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                    />
                </div>
            </div>
            {/* Invite User */}
            <CommonModalCustomWidth 
                modal_visible={modal_visible} 
                setModalVisible={setModalVisible}
                centered={true}
                padding={"0px"}
                class_name="invite-form-container"
                footer={null}
                // footer={[
                //     <div style={{display: 'flex', justifyContent: 'space-between', padding: "0 5% 5% 5%"}} key="1">
                //         <Button className='common-btn' onClick={() => setModalVisible(false)} key="reset" form="myForm" htmlType="reset">Cancel</Button>
                //         <Button className='common-btn' key="submit" form="myForm" htmlType="submit">
                //             Continue
                //         </Button>
                //     </div>
                // ]}
            >
                <InviteForm error={error} handleCancel={() => setModalVisible(false)} handleSubmitForm={handleSubmitForm}/>
            </CommonModalCustomWidth>
        </React.Fragment>
    );
};

export default UserManagementSettings;