import React, { useEffect, useState } from 'react';
// import { fbq } from 'facebook-pixel'; // Import the 'fbq' object from the 'facebook-pixel' package.
import cross_icon from "../../../../../assets/icons/cross-svgrepo.svg";
import WhatsApp from "../../../../../assets/icons/Whatsapp.svg";
import CommonModal from '../../../../../components/CommonModal/CommonModal';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { RootState } from '../../../../../store';
import { useDispatch } from 'react-redux';
import { api_url, fb_whatsapp_app_id, fb_whatsapp_config_id, fb_whatsapp_solution_id } from '../../../../../utils/server';
// import Dialog from '../../../../../components/dialogs/dialog';
import { generateAccessToken } from '../../../../../profile/reducer';
import {
  requestForDisconnectWhatsapp,
  // setWhatsAppConnectedUsers
} from '../../../../../signup-login/loginReducer';
import { setErrorMessage } from '../../../../../unified/reducer';

declare global {
  interface Window {
    FB: any; // Assuming 'FB' has any type
  }
}

const WhatsAppIntegration: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  // const { whats_app_connected_users, } = useSelector((state: any) => state?.workspaceManagementState);
  const {
    whats_app_connected_users,
    userPermissions,
  }: any = useSelector((state: RootState) => state?.login);
  // const path = window.location.pathname;
  // const redirect = auth_url;
  // const fb_whatsapp_access_token = sessionStorage.getItem("fb_whatsapp_access_token");
  // const [loading, setLoading]: any = React.useState(false);
  // useEffect(() => {
  //   if (whats_app_connected_users?.length) {
  //     console.log({ whats_app_connected_users });
  //     // const workspaceId = sessionStorage.getItem('wuid') || localStorage.getItem('wuid') || "";
  //     // dispatch(getWhatsAppConnectedUsers(workspaceId));
  //   }
  // }, [whats_app_connected_users?.length]);
  
  useEffect(() => {
    const initializeFacebookSDK = () => {
      if (window.FB) {
        window.FB.init({
          // // appId: '{YOUR-FACEBOOK-APP-ID}',
          appId: fb_whatsapp_app_id || '254750246571176',
          cookie: true,
          xfbml: true,
          version: 'v18.0'
        });
      }
    };
  
    const loadFacebookSDK = () => {
      const script = document.createElement('script');
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.defer = true;
      script.crossOrigin = "anonymous";
      script.onload = initializeFacebookSDK;
      document.body.appendChild(script);
    };
  
    if (!window.FB) {
      loadFacebookSDK();
    } else {
      // console.log({window});
      initializeFacebookSDK();
    }
  }, []);
  
  const handleWhatsAppConnect = () => {
    sessionStorage.setItem("isLoading", "true");
    sessionStorage.setItem("app_connect", "whatsapp-connection");

    // const redirectUri = `${window.location.origin}/workspace/integrations`;
    
    // console.log({ FB: window.FB });
    // Launch Facebook login
    if (window.FB) {
      // Track WhatsApp onboarding start event
      // ReactPixel.trackCustom('WhatsAppOnboardingStart', {
      //   appId: 'your-facebook-app-id',
      //   feature: 'whatsapp_embedded_signup'
      // });
      // // fbq && fbq('trackCustom', 'WhatsAppOnboardingStart', {appId: 'your-facebook-app-id', feature: 'whatsapp_embedded_signup'});
      // // If FB is available, directly call FB.login
      window.FB.login(function (response: any, error: any) {
        // console.log({response, error});
        
        if (response?.authResponse) {
          const code = response?.authResponse?.code;
          console.log('User authResponse code:', code);
          // The returned code must be transmitted to your backend
          // which will perform a server-to-server call from there to WhatsApp servers for an access token

        } else {
          console.log('User cancelled login or did not fully authorize.', {response});
          dispatch(setErrorMessage("Connection Cancelled"));
        }
      }, {
        // Your login options here
        config_id: fb_whatsapp_config_id || '939680554350603', // configuration ID goes here
        response_type: 'code',    // must be set to 'code' for System User access token
        override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
        extras: {
          sessionInfoVersion: 2, // must be set to 2 for WhatsApp Business API
          setup: {
            // Prefilled data can go here
            // solutionID: '{YOUR-SOLUTION-ID}'
            solutionID: fb_whatsapp_solution_id || '1106455250783359', // solution ID goes here
          }
        }
      });
    } else {
      console.log('Facebook SDK not initialized yet.');
      // You might want to handle this case accordingly
    }
    
  }
  
  const sessionInfoListener = (event: any) => {
    console.log('message event received:', event);
    if (event?.origin !== "https://www.facebook.com") {
      return;
    }
    try {
      const data = JSON.parse(event?.data || "{}");
      console.log('event data: ==> ', data);
      if (data?.type === 'WA_EMBEDDED_SIGNUP') {
        // if user finishes the Embedded Signup flow
        if (data?.event === 'FINISH') {
          const { phone_number_id, waba_id } = data?.data;
          // const phone_number_id = "208327472375018";
          // const waba_id = "210212982185873";
          // authorized to token request
          axios.post(
            `${api_url}/api/v1.2/WhatsApp/RegisterPhoneNumber`,
            {
              workspaceId: sessionStorage.getItem('wuid') || localStorage.getItem('wuid') || "",
              phoneNumberId: phone_number_id,
              wabaId: waba_id
            },
            {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                'Content-Type': 'application/json'
              }
            }
          ).then((response: any) => {
            console.log({ response });
            
          }).catch(error => {
            // console.log('error: ', error);
            const { response } = error;
            const { status }: any = response;
            if((status === 403 || status === 401)) {
              dispatch(generateAccessToken());
            }
          });
        }
        // if user cancels the Embedded Signup flow
        else {
          const { current_step } = data?.data;
        }
      }
    } catch (error: any) {
      // Don’t parse info that’s not a JSON
      console.log({error});
      dispatch(setErrorMessage("Connection Failed"));
      
      console.log('Non JSON Response', event.data);
    }
  };
  
  useEffect(() => {
    window.addEventListener('message', sessionInfoListener);
    return () => {
      window.removeEventListener('message', sessionInfoListener);
    };
  }, []);

  useEffect(() => {
    // console.log({userPermissions});
    if (userPermissions?.length) {
      const permission = userPermissions?.find(
        (item: any) => item?.name === "integration"
      );
      if (permission) {
        permission?.value === 0 && history("/not-found");
      }
    }
  }, [history, userPermissions?.length]);

  // const user = JSON.parse(localStorage.getItem("integrated_user_details") || "{}");

  const handleWhatsAppDisconnect = () => {
    console.log('Connect Warning: Disconnect');
    const workspaceId = sessionStorage.getItem('wuid') || localStorage.getItem('wuid') || "";
    const disconnect_user = whats_app_connected_users?.length ? whats_app_connected_users[0] : {};
    const disconnect_object = {
      Provider: "whatsApp",
      ProviderUserId: disconnect_user?.wabaId,
      WorkspaceId: workspaceId,
    }
    dispatch(requestForDisconnectWhatsapp(disconnect_object));
    // dispatch(setWhatsAppConnectedUsers([]));
  }

  const [show_whatsapp_info, setShowWhatsappInfo] = useState(false);
  return (
    <React.Fragment>
      {/* {
        loading
        ? <div className='loading-container w-100 d-flex justify-content-center align-items-center'>
            <Spinner animation="border" variant="primary" />
          </div>
          :
      } */}

      <div className='integration-container'>
          
        {/* What's App */}
        <div className="card-container">
          <div className='logo-container'>
            <img
              className="logo-icon"
              src={WhatsApp}
              onClick={() => setShowWhatsappInfo(true)}
              alt="whats-app"
            />
          </div>
          <div className='card-content'>
            <h4 className='card-title' onClick={() => setShowWhatsappInfo(true)}>WhatsApp</h4>
            <div className='description-container'>
              <div className='card-description' onClick={() => setShowWhatsappInfo(true)}>
                <p >A simple way to connect to your Meta business manager for creating WhatsApp business account</p>
              </div>
              <div className='button-container'>
                <button
                  type="button"
                  className="card-button"
                  onClick={() => {
                    if (whats_app_connected_users?.length) {
                      handleWhatsAppDisconnect();
                      return;
                    }
                    handleWhatsAppConnect();
                  }}
                >
                  {
                    whats_app_connected_users?.length
                    ? "Disconnect"
                    : "Connect"
                  }
                </button>
              </div>
            </div>
            {/* {
              integrationConnections[2]?.UserId
              ? <div className='card-footer'>
                    <button
                      type='button' className='card-footer-button'
                      onClick={() => setFacebookAccountInfo(integrationConnections[2])}>
                      Configure Pages</button>
                    <p>{integrationConnections[2]?.DisplayName || integrationConnections[2]?.UserName || ""}</p>
                </div>
              : null
            } */}
            
          </div>
        </div>
        {/* Whats app user info modal */}
        <CommonModal
          padding={"10px 5px"}
          class_name={"whats-app-info-modal"}
          title={
            <div className="d-flex flex-row justify-content-end align-items-center py-2">
              <button
                onClick={() => setShowWhatsappInfo(false)}
                className={
                  "m-2 rounded rounded-circle btn btn-outline-light px-2 py-0"
                }
                style={{ color: "#FAFBFC", backgroundColor: "#ABB9CC" }}
              >
                <img
                  className="mb-1 p-0"
                  height={10}
                  src={cross_icon}
                  alt=""
                />
              </button>
            </div>
          }
          closeIcon={true}
          modal_visible={ show_whatsapp_info }
          centered={true}
          setModalVisible={setShowWhatsappInfo}
          footer={null}
        >
          {
            whats_app_connected_users?.map((user: any, idx: any) => (
              <div key={user?.id} className="card-container" style={{cursor: 'pointer'}}>
                <div className='logo-container'>
                  <img
                    className="logo-icon"
                    src={WhatsApp}
                    alt="whats-app"
                  />
                </div>
                <div className='card-content'>
                  <h4 className='card-title'>{user?.verifiedName || "N/A"}</h4>
                  <h4 className='card-title'>{user?.phoneDisplayNumber || "N/A"}</h4>
                  <div className='description-container'>
                    <div className='card-description'>
                      <p >WhatsApp Business Account Profile description can be written here.</p>
                    </div>
                  </div>              
                </div>
              </div>
            ))
          }

          {/* <div className="card-container">
            <div className='logo-container'>
              <img
                className="logo-icon"
                src={WhatsApp}
                alt="whats-app"
              />
            </div>
            <div className='card-content'>
              <h4 className='card-title'>Sreekanth P M</h4>
              <h4 className='card-title'>+919074021484</h4>
              <div className='description-container'>
                <div className='card-description'>
                  <p >WhatsApp Business Account Profile description can be written here.</p>
                </div>
              </div>              
            </div>
          </div> */}
        </CommonModal>
      </div>
      
    </React.Fragment>
  );
};

export default WhatsAppIntegration;