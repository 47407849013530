import { takeEvery, put, call, all, takeLatest, delay } from 'redux-saga/effects';
import Constants from '../constants';
import axios from "axios";
import {
    receiveDashboard, setUnifiedData,
    setWallUnifiedData, setUnifiedDetailsData,
    resetWallUnifiedInbox, setRequestReviewData, setConversationDetailsData,
    addToWallDataByKey, removeFromWallDataByKey,
    setUpdatedWhatsappPhoneOrEmail,
    setErrorMessage, setBotData,
    setWhatsAppInboxData, setClosedWhatsApp,
    setSuccessMessage
} from './reducer';
import { generateAccessToken } from '../profile/reducer';
import { api_url } from '../utils/server';

export async function replyToWhatsAppMessage(data?: any) {
    console.log('replyToWhatsAppMessage', data);
    const url = api_url + `/api/v1.2/WhatsApp/ReplyToMessage`;
    try {
        const response = await axios.post(url, data, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}

function* dashboardRequest(action: any) {
    try {
        const { data, status } = yield call(
          axios.post,
          api_url + `/api/v1.2/dashboard/getdashboarddata`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            yield put(receiveDashboard(data));
            // yield put(generateAccessToken());
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// mmhk-region
// unified inbox - old version
// function* unifiedInboxRequest(action: any) {
//     // console.log('unifiedInboxRequest=====', action.payload)
//     const {isFirstTime, searchText, readStatus, toDate, fromDate} = action.payload;
//     let url = api_url + `/api/v1.2/clapup/getDataForInboxAndMenu`;
//     const dataObject: any = {
//         ...action.payload,
//     }
//     if(!readStatus){
//         dataObject.readStatus = 'All';
//     }
//     if(searchText || readStatus || toDate || fromDate){
//         url = api_url + `/api/v1.2/clapup/searchData`;
//         delete dataObject.includeGoogleReviews;
//         delete dataObject.includeFacebookReviews;
//         delete dataObject.includeTwitterMentions;
//         delete dataObject.includeCustomerFeedback;
//         delete dataObject.includeVideoTestimonials;
//         delete dataObject.includeFacebookComments;
//         delete dataObject.includeFacebookMentions;
//         delete dataObject.includeGoogleQandA;
//         delete dataObject.mappedGoogleUserId;  
//     } else {
//         delete dataObject.searchText;
//         delete dataObject.readStatus;
//         delete dataObject.toDate;
//         delete dataObject.fromDate;
//         delete dataObject.providerType;
//     }
//     delete dataObject.isFirstTime;
//     try {
//         const { data, status } = yield call(axios.post, url, dataObject, {
//             headers: {
//               'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
//               'Content-Type': 'application/json'
//             }
//         });
//         // const { data } = yield call(axios.post, api_url + `/api/v1.2/clapup/unifiedInbox`, action.payload);
//         if (status === 200) {
//             if (data === "Error while fetching data!") {
//                 yield put(setUnifiedData([], [], isFirstTime));
//             } else {
//                 const unifiedData = data?.data;
//                 yield put(
//                   setUnifiedData(unifiedData, data?.nextDate, isFirstTime, {
//                     ...dataObject,
//                     readStatus: action.payload.readStatus,
//                     toDate: action.payload.toDate,
//                     fromDate: action.payload.fromDate,
//                     providerType: action.payload.providerType,
//                   })
//                 );
//             }
//         }  
//     } catch (error) {
//         const { response }: any = error;
//         console.log('error', response);
//         const { status } = response || {};
//         if (status === 403) {
//             yield put(generateAccessToken());
//         }
//     }
// }

// unified inbox
function* getInboxAndWallRequest(action: any) {
    const {isFirstTime, searchText, readStatus, toDate, fromDate, key, nextKey, providerType, tag} = action.payload;
    let url = api_url + `/api/v1.2/clapup/ConsolidatedInboxAndMenu`;
    const dataObject: any = {
        ...action.payload,
    }
    // console.log({searchText, readStatus, toDate, fromDate, providerType, tag});
    if(!readStatus){
        dataObject.readStatus = 'All';
    }
    if (searchText || readStatus || toDate || fromDate) {
        
        delete dataObject.lastSerialNoCommentsAll;
        delete dataObject.lastSerialNoInboxAll;
        delete dataObject.lastSerialNoMentionsAll;
        delete dataObject.lastSerialNoQandAAll;
        delete dataObject.lastSerialNoReviewsAll;
        //
        delete dataObject.lastSerialNoGoogleAll;
        delete dataObject.lastSerialNoGoogleReviews;
        delete dataObject.lastSerialNoGoogleQandA;
        delete dataObject.lastSerialNoFacebookAll;
        delete dataObject.lastSerialNoFacebookReviews;
        delete dataObject.lastSerialNoFacebookComments;
        delete dataObject.lastSerialNoFacebookMentions;
        delete dataObject.lastSerialNoCFReviews;
        delete dataObject.lastSerialNoVTReviews;
        delete dataObject.lastSerialNoInboxWall;
        delete dataObject.lastSerialNoCFWall;
        delete dataObject.lastSerialNoVTWall;
        delete dataObject.lastSerialNoGoogleWall;
        delete dataObject.lastSerialNoFacebookWall;
        // url = api_url + `/api/v1.2/clapup/searchData`; 
        url = api_url + `/api/v1.2/clapup/ConsolidatedSearchForInboxAndMenu`; 
    } else {
        delete dataObject.searchText;
        delete dataObject.readStatus;
        delete dataObject.toDate;
        delete dataObject.fromDate;
        delete dataObject.providerType;
        delete dataObject.tag;
        delete dataObject.lastSerialNo;
    }
    delete dataObject.includeGoogleReviews;
    delete dataObject.includeFacebookReviews;
    delete dataObject.includeTwitterMentions;
    delete dataObject.includeCustomerFeedback;
    delete dataObject.includeVideoTestimonials;
    delete dataObject.includeFacebookComments;
    delete dataObject.includeFacebookMentions;
    delete dataObject.includeGoogleQandA;
    delete dataObject.mappedGoogleUserId;  
    delete dataObject.isFirstTime;
    delete dataObject.key;
    delete dataObject.nextKey;
    try {
        const { data, status } = yield call(axios.post, url, dataObject, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        // const { data } = yield call(axios.post, api_url + `/api/v1.2/clapup/unifiedInbox`, action.payload);
        if (status === 200) {
            if (data === "Error while fetching data!") {
                yield put(setUnifiedData([], [], isFirstTime));
            } else {
                const unifiedData = data;
                // to lower case
                if (providerType?.includes("inbox")) {
                    yield put(
                      setUnifiedData(unifiedData, {
                        ...dataObject,
                        readStatus: action.payload.readStatus,
                        toDate: action.payload.toDate,
                        fromDate: action.payload.fromDate,
                        providerType: action.payload.providerType,
                      }, key, nextKey)
                    );
                } else {
                    yield put(
                        setWallUnifiedData(unifiedData, {
                        ...dataObject,
                        readStatus: action.payload.readStatus,
                        toDate: action.payload.toDate,
                        fromDate: action.payload.fromDate,
                        providerType: action.payload.providerType,
                      }, key, nextKey)
                    );
                }
            }
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// search wall data
function* searchWallDataRequest(action: any) {
    const { key, nextKey } = action.payload;
    delete action.payload.key;
    delete action.payload.nextKey;
    const dataObject: any = {
        ...action.payload,
    }
    delete dataObject.includeGoogleReviews;
    delete dataObject.includeFacebookReviews;
    delete dataObject.includeTwitterMentions;
    delete dataObject.includeCustomerFeedback;
    delete dataObject.includeVideoTestimonials;
    delete dataObject.includeFacebookComments;
    delete dataObject.includeFacebookMentions;
    delete dataObject.includeGoogleQandA;
    delete dataObject.mappedGoogleUserId;
    try {
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/v1.2/clapup/searchWallData`,
            dataObject,
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            const wallUnifiedData = data;
            yield put(setWallUnifiedData(wallUnifiedData, action.payload, key, nextKey));
        } 
        // if (status === 200) {
        //     const wallUnifiedData = data?.data?.map((item: any, index: number) => {
        //         return {
        //             ...item,
        //             idx: index
        //         }
        //     });
        //     yield put(setWallUnifiedData(wallUnifiedData, dataObject));
        // } 
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// whats-app inbox messages
function* whatsAppDataInboxRequest(action: any) {
    console.log('Whatsapp Data Object: ', action.payload);
    const {searchText, readStatus, toDate, fromDate, workspaceId, createdOn, isForSearched, key, nextKey, providerType, tag} = action.payload;
    let url = api_url + `/api/v1.2/WhatsApp/GetWhatsAppMessages`;
    delete action.payload.key;
    delete action.payload.nextKey;
    let sagaDataObject: any = {
        workspaceId,
        createdOn,
        // status: tag || "all"
    }
    
    searchText && (sagaDataObject.searchText = searchText);
    if (searchText && toDate && fromDate) {
        sagaDataObject.fromDate = fromDate;
        sagaDataObject.toDate = toDate;
    }
    console.log({sagaDataObject, searchText, readStatus, toDate, fromDate, providerType, tag });
    // if (searchText || readStatus) {
    if (isForSearched) {
        sagaDataObject = {
            ...sagaDataObject,
            searchText: searchText,
            workspaceId: sagaDataObject?.workspaceId,
            // status: tag || "all",
            status: "",
            createdOn: createdOn,
            fromDate: fromDate,
            toDate: toDate,
            assignedTo: readStatus || "All"

        }

        console.log({ sagaDataObject });
        // return;
        
        
        url = api_url + `/api/v1.2/WhatsApp/SearchMessageDetails`; 
        // if (key?.includes("Wall") && searchText) {
        //     url = api_url + `/api/v1.2/clapup/ConsolidatedWallSearchForInboxAndMenu`;
        // } else if (key?.includes("Wall") && !searchText) { 
        //     url = api_url + `/api/v1.2/clapup/ConsolidatedWallDataForInboxAndMenu`;
        // }
        
    } 

    
    try {
        const { data, status } = yield call(
            axios.post,
            url,
            sagaDataObject,
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            // const {main} = data;
            console.log('Whatsapp Data: ', data);
            yield put(setWhatsAppInboxData(data));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

function* whatsAppConversationClosedRequest(action: any) {
    console.log('Whatsapp Closed Data Object: ', action.payload);
    const {
        userName, 
        ticketNo, 
        // status 
    } = action.payload || {};
    const payload_object = {
        ticketNo: ticketNo,
        status: "closed",
    }
    let url = api_url + `/api/v1.2/WhatsApp/UpdatewhatsAppMessageStatus`;
 
    try {
        const { data, status } = yield call(
            axios.post,
            url,
            payload_object,
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            console.log('closed whatsapp Data: ', data, {...payload_object, userName});
            yield put(setClosedWhatsApp({...payload_object, userName}));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', error);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// bot inbox messages
function* botDataInboxRequest(action: any) {
    console.log('Bot Data Object: ', action.payload);
    const {searchText, readStatus, toDate, fromDate, workspaceId, createdOn, isForSearched, key, nextKey, providerType, tag} = action.payload;
    let url = api_url + `/api/v1.2/AIChatBot/GetAIChatBotMessages`;
    delete action.payload.key;
    delete action.payload.nextKey;
    const sagaDataObject: any = {
        workspaceId,
        createdOn,
        status: tag || "all"
    }
    
    searchText && (sagaDataObject.searchText = searchText);
    if (searchText && toDate && fromDate) {
        sagaDataObject.fromDate = fromDate;
        sagaDataObject.toDate = toDate;
    }
    console.log({sagaDataObject, searchText, readStatus, toDate, fromDate, providerType, tag });
    if (isForSearched) {
        
        // delete dataObject.lastSerialNoCommentsAll;
        // delete dataObject.lastSerialNoInboxAll;
        // delete dataObject.lastSerialNoMentionsAll;
        // delete dataObject.lastSerialNoQandAAll;
        // delete dataObject.lastSerialNoReviewsAll;
        
        url = api_url + `/api/v1.2/AIChatBot/SearchChatDetails`; 
        // if (key?.includes("Wall") && searchText) {
        //     url = api_url + `/api/v1.2/clapup/ConsolidatedWallSearchForInboxAndMenu`;
        // } else if (key?.includes("Wall") && !searchText) { 
        //     url = api_url + `/api/v1.2/clapup/ConsolidatedWallDataForInboxAndMenu`;
        // }
        
    } 

    
    try {
        const { data, status } = yield call(
            axios.post,
            url,
            sagaDataObject,
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            const botData = data;
            console.log('Bot Data: ', botData);
            yield put(setBotData(botData));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// wall unified inbox
function* wallUnifiedInboxRequest(action: any) {
    console.log('Unified WallInbox Data Object: ', action.payload);
    const {searchText, readStatus, toDate, fromDate, key, nextKey, providerType, tag} = action.payload;
    let url = api_url + `/api/v1.2/clapup/ConsolidatedWallDataForInboxAndMenu`;
    delete action.payload.key;
    delete action.payload.nextKey;
    const dataObject: any = {
        ...action.payload,
    }
    // console.log({ searchText, readStatus, toDate, fromDate, providerType, tag });
    
    if(!readStatus){
        dataObject.readStatus = 'All';
    }
    if (searchText || readStatus || toDate || fromDate) {
        
        delete dataObject.lastSerialNoCommentsAll;
        delete dataObject.lastSerialNoInboxAll;
        delete dataObject.lastSerialNoMentionsAll;
        delete dataObject.lastSerialNoQandAAll;
        delete dataObject.lastSerialNoReviewsAll;
        //
        
        // url = api_url + `/api/v1.2/clapup/searchData`; 
        url = api_url + `/api/v1.2/clapup/ConsolidatedSearchForInboxAndMenu`; 
        if (key?.includes("Wall") && searchText) {
            url = api_url + `/api/v1.2/clapup/ConsolidatedWallSearchForInboxAndMenu`;
        } else if (key?.includes("Wall") && !searchText) { 
            url = api_url + `/api/v1.2/clapup/ConsolidatedWallDataForInboxAndMenu`;
        }
        
    } 
    if (url?.includes("ConsolidatedWallDataForInboxAndMenu")) {
        delete dataObject.searchText;
        delete dataObject.readStatus;
        delete dataObject.toDate;
        delete dataObject.fromDate;
        delete dataObject.providerType;
        delete dataObject.tag;
        delete dataObject.lastSerialNo;
    } else {
        delete dataObject.lastSerialNoGoogleAll;
        delete dataObject.lastSerialNoGoogleReviews;
        delete dataObject.lastSerialNoGoogleQandA;
        delete dataObject.lastSerialNoFacebookAll;
        delete dataObject.lastSerialNoFacebookReviews;
        delete dataObject.lastSerialNoFacebookComments;
        delete dataObject.lastSerialNoFacebookMentions;
        delete dataObject.lastSerialNoCFReviews;
        delete dataObject.lastSerialNoVTReviews;
        delete dataObject.lastSerialNoInboxWall;
        delete dataObject.lastSerialNoCFWall;
        delete dataObject.lastSerialNoVTWall;
        delete dataObject.lastSerialNoGoogleWall;
        delete dataObject.lastSerialNoFacebookWall;
    }
    
    try {
        // const { data } = yield call(axios.post, api_url + `/api/v1.2/clapup/unifiedWallInbox`, action.payload);
        const { data, status } = yield call(
            axios.post,
            url,
            // api_url + `/api/v1.2/clapup/GetWallDataForInboxAndMenu`,
            dataObject,
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            const wallUnifiedData = data;
            yield put(setWallUnifiedData(wallUnifiedData, dataObject, key, nextKey));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// old version
// function* wallUnifiedInboxRequest(action: any) {
//     console.log('Unified WallInbox Data Object: ', action.payload)
//     try {
//         // const { data } = yield call(axios.post, api_url + `/api/v1.2/clapup/unifiedWallInbox`, action.payload);
//         const { data, status } = yield call(
//             axios.post,
//             api_url + `/api/v1.2/clapup/ConsolidatedWallDataForInboxAndMenu`,
//             // api_url + `/api/v1.2/clapup/GetWallDataForInboxAndMenu`,
//             action.payload,
//             {
//                 headers: {
//                   'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
//                   'Content-Type': 'application/json'
//                 }
//             }
//         );
//         if (status === 200) {
//             const wallUnifiedData = data?.data?.map((item: any, index: number) => {
//                 return {
//                     ...item,
//                     idx: index
//                 }
//             });
//             yield put(setWallUnifiedData(wallUnifiedData, action.payload));
//         }  
//     } catch (error) {
//         const { response }: any = error;
//         console.log('error', response);
//         const { status } = response || {};
//         if (status === 403) {
//             yield put(generateAccessToken());
//         }
//     }
// }

// set google review data according to the selected unified inbox
function* getGoogleReviewDataDetails(action: any) {
    // console.log('getGoogleReviewDataDetails', action.payload)
    try {
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/v1.2/google/ReviewsConversationDetails`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        
        if (status === 200) {
            const {location, reviewer, details, properties} = data
            yield put(setUnifiedDetailsData({location: location[0], reviewer: reviewer[0], details: details[0], properties: properties[0]}));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// set facebook review data according to the selected unified inbox
function* getFacebookCommentDataDetails(action: any) {
    try {
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/v1.2/facebook/CommentsConversationDetails`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        
        if (status === 200) {
            const {location, reviewer, page, details, properties}: any = data || {};
            const facebookData: any = {};
            reviewer?.length && (facebookData.reviewer = reviewer[0]);
            location?.length && (facebookData.location = location[0]);
            page?.length && (facebookData.page = page[0]);
            properties?.length && (facebookData.properties = properties[0]);
            details?.length && (facebookData.details = details[0]);
            yield put(setUnifiedDetailsData(facebookData));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

function* getFacebookMentionDataDetails(action: any) {
    // console.log('getFacebookReviewDataDetails', action.payload)
    try {
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/v1.2/facebook/MentionsConversationDetails`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        
        if (status === 200) {
            const {location, reviewer, page, details, properties}: any = data || {};
            const facebookData: any = {};
            reviewer.length && (facebookData.reviewer = reviewer[0]);
            location?.length && (facebookData.location = location[0]);
            page?.length && (facebookData.page = page[0]);
            properties?.length && (facebookData.properties = properties[0]);
            details?.length && (facebookData.details = details[0]);
            yield put(setUnifiedDetailsData(facebookData));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

function* getFacebookReviewDataDetails(action: any) {
    // console.log('getFacebookReviewDataDetails', action.payload)
    try {
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/v1.2/facebook/ReviewsConversationDetails`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        
        if (status === 200) {
            const {location, reviewer, page, details, properties}: any = data || {};
            const facebookData: any = {};
            reviewer.length && (facebookData.reviewer = reviewer[0]);
            location?.length && (facebookData.location = location[0]);
            page?.length && (facebookData.page = page[0]);
            properties?.length && (facebookData.properties = properties[0]);
            details?.length && (facebookData.details = details[0]);
            yield put(setUnifiedDetailsData(facebookData));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// set customer feedback review data according to the selected unified inbox
function* getCustomerFeedbackReviewDataDetails(action: any) {
    // console.log('getCustomerFeedbackReviewDataDetails', action.payload)
    try {
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/v1.2/clapup/cFReviewsConversationDetails`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );

        if (status === 200) {
            const {location, reviewer, details, properties}: any = data || {};
            const customerFeedbackData: any = {};
            reviewer.length && (customerFeedbackData.reviewer = reviewer[0]);
            location?.length && (customerFeedbackData.location = location[0]);
            details?.length && (customerFeedbackData.details = details[0]);
            properties?.length && (customerFeedbackData.properties = properties[0]);
            yield put(setUnifiedDetailsData(customerFeedbackData));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// set video testimonial review data according to the selected unified inbox
function* getVideoTestimonialReviewDataDetails(action: any) {
    // console.log('getVideoTestimonialReviewDataDetails', action.payload)
    try {
        // const { data } = yield call(axios.post, api_url + `/api/v1.2/facebook/vTReviewsConversationDetails`, action.payload);
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/v1.2/clapup/vTReviewsConversationDetails`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        
        if (status === 200) {
            const {location, reviewer, details, properties}: any = data || {};
            const videoData: any = {};
            reviewer.length && (videoData.reviewer = reviewer[0]);
            location?.length && (videoData.location = location[0]);
            details?.length && (videoData.details = details[0]);
            properties?.length && (videoData.properties = properties[0]);
            yield put(setUnifiedDetailsData(videoData));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// *******Implement will be later *******
// set twitter review data according to the selected unified inbox
function* getTwitterReviewDataDetails(action: any) {
    // console.log('getTwitterReviewDataDetails', action.payload)
    try {
        const { data, status } = yield call(
            axios.post,
            api_url + `/api/v1.2/twitter/MentionsConversationDetails`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        
        if (status === 200) {
            const {location, reviewer, details, properties}: any = data || {};
            const twitterData: any = {};
            reviewer.length && (twitterData.reviewer = reviewer[0]);
            location?.length && (twitterData.location = location[0]);
            properties?.length && (twitterData.properties = properties[0]);
            details?.length && (twitterData.details = details[0]);
            yield put(setUnifiedDetailsData(twitterData));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// add to wall data
function* addToWallDataRequest(action: any) {
    const { data, key } = action;
    // console.log('Add To Wall Data', action.addToWallPayload);
    // return;
    const { providerType }: any = action?.addToWallPayload;
    
    try {
        // const {res}  = yield call(axios.post, api_url + `/api/v1.2/clapup/addToWall`, action.addToWallPayload);
        const common_payload: any = {
            clapupUserId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
        };
        if (providerType === "videoTestimonial") {
            const stage_url = api_url + "/api/v1.2/gallery/UpdateContentStages";
            const { data: stage_response, status: stage_status } = yield call(
                axios.post,
                stage_url,
                {
                    ...common_payload,
                    contentId: data?.contentId,
                    stageName: "publish",
                    stageValue: "1"
                },
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (stage_status !== 200) {
                yield put(setErrorMessage("Update publish stage failed"))
                return { success: false, message: "Update stage error" };
            }
        }
        const { status } = yield call(
            axios.post,
            api_url + `/api/v1.2/clapup/updateWall`,
            action.addToWallPayload,
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        
        if (status === 200) {
            // add to wall directly from frontend
            yield put(addToWallDataByKey(key, data));
            // yield put(resetWallUnifiedInbox());
        }

    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// delete from wall data
function* deleteFromWallDataRequest(action: any) {
    // console.log('Delete From Wall Data', action.deleteWallPayload);
    const { deleteWallPayload, data, key } = action;
    try {
        // console.log({ deleteWallPayload, data });
        const { providerType } = deleteWallPayload;
        const common_payload: any = {
            clapupUserId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
        };
        if (providerType === "videoTestimonial") {
            const stage_url = api_url + "/api/v1.2/gallery/UpdateContentStages";
            const { data: stage_response, status: stage_status } = yield call(
                axios.post,
                stage_url,
                {
                    ...common_payload,
                    contentId: data?.contentId,
                    stageName: "publish",
                    stageValue: "0"
                },
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (stage_status !== 200) {
                yield put(setErrorMessage("Update publish stage failed"))
                return { success: false, message: "Update stage error" };
            }
        }
        const { status } = yield call(
            axios.post, api_url + `/api/v1.2/clapup/updateWall`,
            deleteWallPayload, 
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            yield put(removeFromWallDataByKey(key, data));
            // yield put(resetWallUnifiedInbox());
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// request for review data
function* requestForReviewData(action: any) {
    const {url} = action.payload;
    delete action.payload.url;
    try {
        const { data, status } = yield call(
            axios.post,
            url || api_url + `/api/v1.2/clapup/requestForCustomerFeedback`,
            action.payload,
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            yield put(setRequestReviewData(data));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// request for locations data
function* getLocations(action?: any) {
    const { url} = action?.payload || {};
    try {
        const { data, status } = yield call(
            axios.get,
            url || api_url + `/GetCountryListwithCode`,
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            yield put({ type: Constants.SET_LOCATIONS, payload: data });
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}

// conversational details data
function* getConversationDataDetailsData(action: any) {
    const { url, payload, method } = action;
    try {
        if (method === 'GET') {
            const { data, status } = yield call(
                axios.get, url,
                {
                    headers: {
                      'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                      'Content-Type': 'application/json'
                    }
                }
            );
            if (status === 200) {
                yield put(setConversationDetailsData(data?.Table));
            }  
            
        } else {
            const { data, status } = yield call(axios.post, url, payload, {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            });
            if (status === 200) {
                yield put(setConversationDetailsData(data));
            }  
        }
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
}
        
// update whatsapp details for phone number and email
function* updateForWhatsappPhoneNumberOrEmailData(action: any) {
    const { updated_state, whatsapp_stat_details_object} = action.payload;
    const url = api_url + `/api/v1.2/WhatsApp/UpdateWhatsAppContactDetails`
    try {
        const { data, status } = yield call(
            axios.post,
            url,
            whatsapp_stat_details_object,
            {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                  'Content-Type': 'application/json'
                }
            }
        );
        if (status === 200) {
            yield put(setUpdatedWhatsappPhoneOrEmail(updated_state));
            delay(1500);
            yield put(setSuccessMessage("Saved Successfully"));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403 || status === 401) {
            yield put(generateAccessToken());
        }
    }
};

// request for facebook like-unlike
function* likeUnlikeFacebook(action?: any) {
    const url = api_url + "/api/v1.2/facebook/LikeUnlike";
    try {
        const { data, status } = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        console.log({facebook_res: data, status});
        if (status === 200) {
            console.log({facebook_message: "Successfully hit the fb like & unlike api"});
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403) {
            yield put(generateAccessToken() as any);
        }
    }
}

// request for facebook replying post
function* replyingFacebookPost(action?: any) {
    const url = api_url + "/api/v1.2/facebook/replytopagereview";
    try {
        const { data, status } = yield call(axios.post, url, action.payload, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
              'Content-Type': 'application/json'
            }
        });
        console.log({facebook_res: data, status});
        if (status === 200) {
            // console.log({facebook_message: "Successfully hit the fb replying api"});
            yield put(setSuccessMessage(data));
        }  
    } catch (error) {
        const { response }: any = error;
        console.log('error', response);
        const { status } = response || {};
        if (status === 403) {
            yield put(generateAccessToken() as any);
        }
    }
}


//watcher saga
function* unifiedSaga() {
    yield all([
        // mmhk-region
        takeEvery(Constants.TRIGGER_DASHBOARD_DATA, dashboardRequest),
        takeEvery(Constants.GET_LOCATIONS, getLocations),
        takeEvery(Constants.TRIGGER_REQUEST_REVIEW, requestForReviewData),
        takeEvery(Constants.TRIGGER_ADD_TO_WALL, addToWallDataRequest),
        takeEvery(Constants.TRIGGER_DELETE_FROM_WALL, deleteFromWallDataRequest),
        // filtering start
        takeEvery(Constants.TRIGGER_INBOX_AND_WALL, getInboxAndWallRequest),
        // filtering end
        // messages
        takeEvery(Constants.GET_FIRST_TIME_WHATSAPP_DATA_INBOX, whatsAppDataInboxRequest),
        takeEvery(Constants.TRIGGER_CLOSED_WHATSAPP, whatsAppConversationClosedRequest),
        takeEvery(Constants.TRIGGER_UPDATE_WHATSAPP_PHONE_OR_EMAIL, updateForWhatsappPhoneNumberOrEmailData),
        // bot
        takeEvery(Constants.GET_FIRST_TIME_BOT_DATA_INBOX, botDataInboxRequest),
        // wall unified inbox
        takeEvery(Constants.TRIGGER_WALL_UNIFIED_INBOX, wallUnifiedInboxRequest),
        takeEvery(Constants.TRIGGER_SEARCH_WALL_DATA, searchWallDataRequest),
        takeEvery(Constants.TRIGGER_GOOGLE_DATA, getGoogleReviewDataDetails),
        takeEvery(Constants.TRIGGER_FACEBOOK_COMMENT_DATA, getFacebookCommentDataDetails),
        takeEvery(Constants.TRIGGER_FACEBOOK_MENTION_DATA, getFacebookMentionDataDetails),
        takeEvery(Constants.TRIGGER_FACEBOOK_REVIEW_DATA, getFacebookReviewDataDetails),
        takeEvery(
            Constants.TRIGGER_CUSTOMER_FEEDBACK_DATA,
            getCustomerFeedbackReviewDataDetails
        ),
        takeEvery(
            Constants.TRIGGER_VIDEO_TESTIMONIAL_DATA,
            getVideoTestimonialReviewDataDetails
        ),
        // implement twitter will be done later
        takeEvery(Constants.TRIGGER_TWITTER_DATA, getTwitterReviewDataDetails),
        // conversational details
        // takeEvery(Constants.TRIGGER_CONVERSATION_DETAILS_DATA, getConversationDataDetailsData),
    ]);
    
    
    //   only one time call
    yield takeLatest(Constants.TRIGGER_CONVERSATION_DETAILS_DATA, getConversationDataDetailsData);
    yield takeLatest(Constants.LIKE_UNLIKE_FACEBOOK_POST, likeUnlikeFacebook);
    yield takeLatest(Constants.REPLYING_FACEBOOK_POST, replyingFacebookPost);
    // yield takeLatest(Constants.GET_COUNTRIES, getCountries);
}

export default unifiedSaga;