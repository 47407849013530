import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {  setBotInboxMessagePayload, triggerGetBotData } from "../reducer";
// import { useNavigate } from "react-router-dom";
// import TopTabs from "../../components/layout/TopTabs";
// import WhatsAppMessageFeeds from "../../components/feeds/feed-messages/feeds_whats_app_messages";
// import RightBottomMessagesTabs from "../../components/layout/RightBottomMessagesTabs";
import BotMessageFeeds from "../../components/Feeds/feed-messages/feeds_bot_messages";
import UnPermission from "../../pages/un-permission/UnPermission";
const BotMessagesManagementComponent: React.FC = () => {
    // const history = useNavigate();
    const dispatch = useDispatch();
    const route_path = window?.location?.pathname;
    const query_string = window.location.search;

    const { botInboxData: data, searchText, searchStartDate, searchEndDate, searchFilterStatus } = useSelector((state: RootState) => state?.unified);
    const tag = query_string?.includes("open") ? "open"
        : query_string?.includes("in-progress") ? "inProgress"
        : query_string?.includes("closed") ? "closed" 
        : query_string?.includes("spam") ? "spam" 
        : "all";
    
        
    // const botDataKey:
    //     any = tag === "all"  || tag === "open"
    //     ? ['CFAll_All', 'CFAll_nextDate', 'lastSerialNoCFWall']
    //     // : tag === "reviews"
    //     // ? ['CFReviews_CFReviews', 'CFReviews_CFReviewsNextDate', 'lastSerialNoCFReviews']
    //     : ['CFAll_All', 'CFAll_nextDate', 'lastSerialNoCFWall'];
        
    

    const nextDate: any[]
        = tag === "all"
        ? [new Date().toISOString()?.replace('Z', '')]
        // : tag === "reviews"
        // ? CFReviews_CFReviewsNextDate
        : [];
        

    // const [isFirstTime, setIsFirstTime] = React.useState(false);
    useEffect(() => {
        // console.log('messagePayload useEffect ===============> ', messagePayload);
        // if(!isFirstTime && !data && !searchText && !searchStartDate && !searchFilterStatus){
        //     console.log({isFirstTime: 87, length: data, searchText, searchStartDate, searchEndDate, searchFilterStatus});
        //     setIsFirstTime(true);
        //     handleTriggerUnified(triggerGetBotData, true, "26");
        // }
        if (!data) {
            // handleTriggerUnified(triggerGetBotData, true);
            handleTriggerUnified(setBotInboxMessagePayload, true);
        }
        dispatch({ type: "FILTER_BY_SEARCH_TEXT", payload: "" });
    }, [
        route_path, 
        // searchText, searchStartDate, searchEndDate, searchFilterStatus
    ]);

    const messagePayload:any = {
        // mappedGoogleUserId: sessionStorage.getItem('muid'),
        // clapupUserId: sessionStorage.getItem('cuid'),
        // roleId: sessionStorage.getItem('roleId'),
        workspaceId: sessionStorage.getItem('wuid'),
        createdOn: new Date().toISOString().replace('Z', ''),
        // providerType: "customerFeedback",
        // search
        // searchText,
        // fromDate: searchStartDate?.replace('Z', ''), 
        // toDate: searchEndDate?.replace('Z', ''), 
        // readStatus: searchFilterStatus,
    }

    const handleTriggerUnified = (callBack = triggerGetBotData, _?: any, line?: any) => {
        // console.log("====IsFirstTime===> ", { dataObj: _, searchText, searchStartDate });
                
        const dataObj = {
            searchText,
            fromDate: searchStartDate,
            toDate: searchEndDate,
            readStatus: searchFilterStatus,
            ..._,
            isFirstTime: _ ? true : false,
            
        };

        console.log("======Next line=====> ", { dataObj, searchText, searchStartDate });

        if(tag === "open"){
            // messagePayload.tag = "reviews";
            // messagePayload.providerType = "inboxReviews";
            const payload = {...messagePayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null};
            dispatch(callBack(payload));
        } else if(tag === "inProgress"){
            messagePayload.tag = "inProgress";
            // messagePayload.providerType = "QandA";
            const payload = {...messagePayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null};
            dispatch(callBack(payload));
        } else if(tag === "closed"){
            messagePayload.tag = "closed";
            // messagePayload.providerType = "comments";
            const payload = {...messagePayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null}
            dispatch(callBack(payload));
        } else if( tag === "spam"){
            messagePayload.tag = "spam";
            // messagePayload.providerType = "mentions";
            const payload = {...messagePayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null};
            dispatch(callBack(payload));
        } else {
            messagePayload.tag = "all";
            // messagePayload.providerType = "customerFeedback";
            const payload = {...messagePayload, ...dataObj, nextDate: _?.isFirstTime ? null : nextDate?.length ? nextDate : null}
            dispatch(callBack(payload));
        }
    }

    const handleSearchData = (key?: any, value?: any) => {
        console.log("key, value ======> ", { key, value });
        let dataObj: any = {
            searchText,
            fromDate: searchStartDate,
            toDate: searchEndDate,
            readStatus: searchFilterStatus,
            isFirstTime: true,
        };
        // console.log({ dataObj });
        
        // if(key === "readStatus"){
        //     dispatch({ type: "FILTER_BY_READ_STATUS", payload: value });
        //     dataObj.readStatus = value;
        //     console.log({dataObj});
        //     // handleTriggerUnified(triggerGetBotData, dataObj);
        // } else
        if (key === "searchText") {
            dataObj.searchText = value;
            dataObj.isForSearched = value ? true : false;
            console.log({dataObj});
            dispatch({ type: "FILTER_BY_SEARCH_TEXT", payload: value });
            handleTriggerUnified(triggerGetBotData, dataObj);
        } else{
            dataObj = {
                ...dataObj,
                isForSearched: (value?.fromDate && value?.toDate) ? true : false,
                ...value
            };
            console.log({dataObj}, { key, value });
            dispatch({ type: "FILTER_BY_SEARCH_FROM_TO_DATE", payload: value });
            handleTriggerUnified(triggerGetBotData, dataObj);
        }
    };

    const {userPermissions} = useSelector((state: any) => state?.login);
    const [isPermissible, setIsPermissible] = React.useState(true);
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "AIChatBot") : null;
        if( permission?.value === 0){
            setIsPermissible(false);
        }
    }, [userPermissions?.length]);
    

    // console.log({ data});
    
    return (
        <React.Fragment>
            {
                isPermissible
                ?
                <BotMessageFeeds
                    handleTriggerData={handleTriggerUnified}
                    handleSearchData={handleSearchData}
                    triggerData={triggerGetBotData}
                    feedsContent={data}
                    type={'bot-messages'}
                />
                
                : <UnPermission/>
            }
        </React.Fragment>
    )
}

export default BotMessagesManagementComponent;

