import React, { useEffect, useState } from 'react';
// import save_icon from '../../../../assets/icons/settings/save-icon.svg';
// import edit_icon from '../../../../assets/icons/settings/edit-icon.svg';
// import upload_icon from '../../../../assets/icons/settings/upload-icon.svg';
// import delete_icon from '../../../../assets/icons/settings/biopage/delete-icon.svg';
import arrow_down_icon from '../../../../assets/icons/settings/arrow-down.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { addCustomLinks, getBioPageLinks, getBioPageSettings, removeCustomLinks, updateBioPageSettings, updateCustomLinks, resetEmbedRecords } from '../../../Settings/BioPageSettings/bioPageReducer';
// import { getProfileInfo } from '../../../Settings/UserProfile/profileManageReducer';
import { Spinner } from 'react-bootstrap';
import './CustomLinks.scss';
import Dialog from '../../../../components/Dialogs/Dialog';
import { Dropdown, Space } from 'antd';
import { link_types } from './custom.link.types';
import SimpleLink from './CustomLinkForms/SimpleLinks/SimpleLink';
import SimpleLinkForm from './CustomLinkForms/SimpleLinks/SimpleLinkForm';
import NestedLink from './CustomLinkForms/NestedLinks/NestedLink';
import NestedParentLinkForm from './CustomLinkForms/NestedLinks/NestedParentLinkForm';
import NestedChildLinkForm from './CustomLinkForms/NestedLinks/NestedChildLinkForm';
import { setSuccessMessage } from '../../../../unified/reducer';
import EmbedVideoForm from './CustomLinkForms/EmbedVideos/EmbedVideoForm';
import EmbedVideo from './CustomLinkForms/EmbedVideos/EmbedVideo';
import EmbedImageForm from './CustomLinkForms/EmbedImages/EmbedImageForm';
import EmbedImage from './CustomLinkForms/EmbedImages/EmbedImage';
import EmbedVideoLinkForm from './CustomLinkForms/EmbedVideoLinks/EmbedVideoLinkForm';
import EmbedVideoLink from './CustomLinkForms/EmbedVideoLinks/EmbedVideoLink';
// import AnnouncementForm from './CustomLinkForms/Announcements/AnnouncementForm';
// import Announcement from './CustomLinkForms/Announcements/Announcement';
const CustomLinks: React.FC = () => {
    const { CustomLinks: customLinks, isLoading } = useSelector((state: any) => state?.bioPageSettingState);
    const [loading, setLoading]: any = React.useState(false);
    const dispatch = useDispatch();
    const {personalInfo} = useSelector((state: any) => state?.profileManageSettingState);
    const {email}: any = personalInfo || {};
    // useEffect(() => {
    //     if (email) {
    //         setTimeout(() => {
    //             setLoading(false);
    //         }, 2000);
    //     } else {
    //         setLoading(true);
    //         dispatch(getProfileInfo());
    //     }
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 1000);
    // }, [dispatch, email]);

    // const handleUpdateBioPageSettingField = (columnName: any, columnValue: any, callBack?: any, setState?: any) => {
    //     if(!columnValue){
    //         return setState("");
    //     }
    //     const dataObject = {
    //         columnName,
    //         columnValue,
    //         clapupId: sessionStorage.getItem('cuid'),
    //         workSpaceId: sessionStorage.getItem('wuid'),
    //         roleId: sessionStorage.getItem('roleId'),
    //         linktype: 'customLinks'
    //     }
    //     dispatch(updateBioPageSettings(dataObject));
    //     callBack && callBack();
    // }

    const {userPermissions} = useSelector((state: any) => state?.login);
    const history = useNavigate();

    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "customLinks") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions?.length]);

    const [openDropdown, setOpenDropdown] = useState<boolean>(false);
    const [CustomLinkModal, setCustomLinkModal] = useState<any>(null);
    const [showEditCustom, setShowEditCustom] = useState<any>(null);
    const [showDeleteCustom, setShowDeleteCustom] = useState<any>(false);
    const [showCustomNestedLink, setShowCustomNestedLink] = useState<any>(null);
    const handleCreateNewCustomLink = (linkData: any, file?: any) => { 
        // console.log({ linkData });
        // dispatch(addCustomLinks(linkData));
        dispatch(addCustomLinks(linkData, file));
        setCustomLinkModal(null);
        // dispatch(setSuccessMessage("Added Successfully"));
    };

    const handleUpdateCustomLink = (linkData: any, file?: any) => {
        if (linkData !== "NestedLinks") { 
            dispatch(updateCustomLinks(linkData, file));
        } else {
            dispatch(setSuccessMessage("Saved Successfully"));
            dispatch(getBioPageLinks());
        }
        setShowEditCustom(null);
        setShowCustomNestedLink(null);
        // dispatch(setSuccessMessage("Saved Successfully"));
    };

    const handleRemoveCustomLink = (item: any) => {
        const data = {
            clapupUserId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
            roleId: sessionStorage.getItem('roleId'),
            id: item?.id,
            contentId: item?.contentId,
            linkType: item?.linkType,
            videoLink: '',
            mediaType: item?.linkType === 3 ? "videos" : item?.linkType === 5 ? "images" : '',
            contentIds: '',
            mode: "delete",
            linkCategory: "customlinks",
            displayText: '',
            linkUrl: '',
            label: '',
            iconUrl: '',
            iconName: '',
            parentId: 0
        }
        dispatch(removeCustomLinks(data));
        setShowDeleteCustom(false);
        dispatch(setSuccessMessage("Deleted Successfully"));
    };

    React.useEffect(() => {
        if(!customLinks?.length){
            dispatch(getBioPageLinks());
            setLoading(true);
        } else {
            console.info("CustomLinks ", { customLinks });
            setLoading(false);
        }

        setTimeout(() => {
            setLoading(false);
        }, 1500);
        setShowEditCustom(null);
    }, [dispatch, customLinks?.length]);
    // const [CustomLinkEditModal, setCustomLinkEditModal] = useState<any>(null);
    const menu = (
        <div className='dropdown-custom-link-types'>
            <ul>
                {
                    link_types?.map((item: any, idx: number) => (
                        <li key={idx} onClick={() => {
                            setOpenDropdown(false);
                            setCustomLinkModal(item);
                        }}>
                            {item?.title}
                        </li>
                    ))
                }
            </ul>
        </div>
    );
    
    return (
        <React.Fragment>
            {/* Dialog */}
            <>
            {
                CustomLinkModal !== null // Add Dialog
                ?   <Dialog
                        open={CustomLinkModal}
                        parentCallback={(flag?: any) => setCustomLinkModal(flag)}
                        classes='dialog-container-link-form'
                        header={CustomLinkModal?.header_add}
                        footer={false}
                    >
                        <CustomLinkModal.form 
                            // {...CustomLinkModal}
                            onCancel={() => {
                                setCustomLinkModal(null);
                                dispatch(resetEmbedRecords());
                            }}
                            onParentCallback={(data: any, file?: any) => {
                                handleCreateNewCustomLink(data, file);
                            }}      
                        />
                    </Dialog>
                : showEditCustom !== null // Edit Dialog
                ?   <Dialog
                        open={showEditCustom}
                        parentCallback={(flag?: any) => {
                            setShowEditCustom(flag);
                            dispatch(resetEmbedRecords());
                        }}
                        classes='dialog-container-link-form'
                        header={
                            showEditCustom?.linkType === 1
                            ? "Edit Simple Link"
                            : showEditCustom?.linkType === 2
                            ? "Edit Nested Link"
                            : showEditCustom?.linkType === 3
                            ? "Edit Video Embed"
                            : showEditCustom?.linkType === 4
                            ? "Edit Video Link"
                            : showEditCustom?.linkType === 5
                            ? "Edit Image Embed"
                            // : showEditCustom?.linkType === 6
                            // ? "Edit Announcement"
                            : null
                        }
                        footer={false}
                    >
                        {
                            // showEditCustom?.linkType?.includes('simple-link')
                            showEditCustom?.linkType === 1
                            ? <SimpleLinkForm
                                link_item={showEditCustom}
                                id={showEditCustom?.id} displayText={showEditCustom?.linkDisplayText}
                                label={showEditCustom?.linkLabel} url={showEditCustom?.linkUrl}
                                logo={showEditCustom?.iconUrl} logoName={showEditCustom?.iconName}
                                onCancel={() => setShowEditCustom(null)}
                                onParentCallback={(data: any, file?: any) => {
                                    // console.log({ data });
                                    // handleCreateNewCustomLink(data);
                                    handleUpdateCustomLink(data, file);

                                }}
                            />
                            // : showEditCustom?.id?.includes('nested')
                            :  showEditCustom?.linkType === 2
                            ? <NestedParentLinkForm
                                link_item={showEditCustom}
                                id={showEditCustom?.id} displayText={showEditCustom?.linkDisplayText}
                                label={showEditCustom?.linkLabel} url={showEditCustom?.linkUrl}
                                logo={showEditCustom?.iconUrl} logoName={showEditCustom?.iconName}
                                onCancel={() => setShowEditCustom(null)}
                                onParentCallback={(data: any, file?: any) => {
                                    // console.log({ data });
                                    // handleCreateNewCustomLink(data);
                                    // dispatch(updateCustomLinks(data));
                                    // setShowEditCustom(null);
                                    handleUpdateCustomLink(data, file);

                                }}
                            />
                            // : showEditCustom?.id?.includes('embed-video-')
                            : showEditCustom?.linkType === 3
                            ? <EmbedVideoForm
                                link_item={showEditCustom}
                                id={showEditCustom?.id} displayText={showEditCustom?.linkDisplayText}
                                label={showEditCustom?.linkLabel} 
                                logo={showEditCustom?.iconUrl} logoName={showEditCustom?.iconName}
                                children={showEditCustom?.result ? JSON.parse(showEditCustom?.result) : undefined}
                                onCancel={() => setShowEditCustom(null)}
                                onParentCallback={(data: any, file?: any) => {
                                    // console.log({ data });
                                    // handleCreateNewCustomLink(data);
                                    // dispatch(updateCustomLinks(data));
                                    // setShowEditCustom(null);
                                    handleUpdateCustomLink(data, file);

                                }}
                            />
                            // : showEditCustom?.id?.includes('embed-video-link')
                            : showEditCustom?.linkType === 4
                            ? <EmbedVideoLinkForm
                                link_item={showEditCustom}
                                id={showEditCustom?.id} displayText={showEditCustom?.linkDisplayText}
                                label={showEditCustom?.linkLabel} url={showEditCustom?.linkUrl}
                                logo={showEditCustom?.iconUrl} logoName={showEditCustom?.iconName}
                                children={showEditCustom?.videoLink?.split(',').map((link: any, idx: number) => ({
                                    idx, // Adding 1 to make the index 1-based
                                    youtubeLink: `${link.trim()}`,
                                }))}
                                onCancel={() => {
                                    setShowEditCustom(null);
                                    dispatch(resetEmbedRecords());
                                }}
                                onParentCallback={(data: any, file?: any) => {
                                    // console.log({ data });
                                    // handleCreateNewCustomLink(data);
                                    // dispatch(updateCustomLinks(data));
                                    // setShowEditCustom(null);
                                    handleUpdateCustomLink(data, file);

                                }}
                            />
                            
                            // : showEditCustom?.id?.includes('embed-image-')
                            : showEditCustom?.linkType === 5
                            ? <EmbedImageForm
                                link_item={showEditCustom}
                                id={showEditCustom?.id} displayText={showEditCustom?.linkDisplayText}
                                label={showEditCustom?.linkLabel} 
                                logo={showEditCustom?.iconUrl} logoName={showEditCustom?.iconName}
                                children={showEditCustom?.result ? JSON.parse(showEditCustom?.result) : undefined}
                                onCancel={() => setShowEditCustom(null)}
                                onParentCallback={(data: any, file?: any) => {
                                    // console.log({ data });
                                    // handleCreateNewCustomLink(data);
                                    // dispatch(updateCustomLinks(data));
                                    // setShowEditCustom(null);
                                    handleUpdateCustomLink(data, file);

                                }}
                            />
                            // : showEditCustom?.id?.includes('announcement')
                            // : showEditCustom?.linkType === 6
                            // ? <AnnouncementForm
                            //     id={showEditCustom?.id} displayText={showEditCustom?.displayText}
                            //     label={showEditCustom?.label} url={showEditCustom?.url}
                            //     logo={showEditCustom?.logo} logoName={showEditCustom?.logoName}
                            //     {...showEditCustom}
                            //     onCancel={() => setShowEditCustom(null)}
                            //     onParentCallback={(data: any) => {
                            //         // console.log({ data });
                            //         // handleCreateNewCustomLink(data);
                            //         // dispatch(updateCustomLinks(data));
                            //         // setShowEditCustom(null);
                            //         handleUpdateCustomLink(data);

                            //     }}
                            // />
                            
                            : null
                        }
                    </Dialog>
                :  showDeleteCustom // Delete Dialog
                ?   <Dialog
                        open={showDeleteCustom}
                        onCancel={() => setShowDeleteCustom(false)}
                        parentCallback={(flag?: any) => {
                            handleRemoveCustomLink(flag);
                        }}
                        classes='dialog-container-content'
                        header='Delete Link?'
                        warning_dialog_content={"By clicking delete button, the link will be deleted, and will not be visible in biopage."}
                        footer={true}
                        left_button_text='No, Cancel'
                        right_button_text='Yes, Continue'
                        // hover={true}
                    />
                : null
            }

            {/* Nested Custom Form - Dialog */}
            {
                showCustomNestedLink !== null
                ?   <Dialog
                        open={showCustomNestedLink}
                        parentCallback={(flag?: any) => setShowCustomNestedLink(flag)}
                        classes='dialog-container-link-form'
                        header={"Add a Nested item"}
                        footer={false}
                    >
                        <NestedChildLinkForm
                            {...showCustomNestedLink}
                            link_item={showCustomNestedLink}
                            onCancel={() => setShowCustomNestedLink(null)}
                            onParentCallback={(data: any, file?: any) => {
                                // console.log({ data });
                                // handleCreateNewCustomLink(data);
                                // dispatch(updateCustomLinks(data));
                                // setShowCustomNestedLink(null);
                                handleUpdateCustomLink(data, file);
                            }}
                        />
                    </Dialog>
                    : null
            }
            </>

            <div className='add-custom-button'>
                <Dropdown
                    onOpenChange={(e: any) => {
                        setOpenDropdown(!openDropdown);
                    }}
                    open={openDropdown}
                    trigger={["click"]}
                    overlay={menu}
                    placement="bottomRight"
                >
                    <button
                        type="button"
                        onClick={() => {
                            setOpenDropdown(!openDropdown);
                            dispatch(resetEmbedRecords());
                        }}
                        className="d-flex"
                        style={{ alignItems: 'center', gap: '10%'}}
                    >
                        <span>Add a Link</span> <img src={arrow_down_icon} alt="arrow-down-icon" />
                    </button>
                </Dropdown>
            </div>
            
            <div className='lg-sub-menu h-100 p-0' style={{ alignItems: 'flex-start' }}>
                {
                    loading
                    ? <div className='loading-container w-100 h-100 d-flex justify-content-center align-items-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : <div className='bio-page-menu-container'>
                        <div className="form-container custom-link-gap">
                            {
                                customLinks?.length
                                ? customLinks?.map((item: any, index: number) => (
                                    // item?.id?.includes('simple-link')
                                    item?.linkType === 1
                                    ? <SimpleLink
                                        key={index}
                                        item={item}
                                        setShowEditCustom={(data: any) => setShowEditCustom(data)}   
                                        setShowDeleteCustom={(data: any) => setShowDeleteCustom(data)}
                                    />
                                    // : item?.id?.includes('nested')
                                    : item?.linkType === 2
                                    ? <NestedLink
                                        key={index}
                                        item={item}
                                        setShowCustomNestedLink={(data: any) => setShowCustomNestedLink(data)}   
                                        setShowEditCustom={(data: any) => setShowEditCustom(data)}   
                                        setShowDeleteCustom={(data: any) => setShowDeleteCustom(data)}
                                    />
                                    // : item?.id?.includes('embed-video-')
                                    : item?.linkType === 3
                                    ? <EmbedVideo
                                        key={index}
                                        item={item}
                                        setShowCustomNestedLink={(data: any) => setShowCustomNestedLink(data)}   
                                        setShowEditCustom={(data: any) => setShowEditCustom(data)}   
                                        setShowDeleteCustom={(data: any) => {
                                            setShowDeleteCustom(data);
                                        }}
                                    />
                                    // : item?.id?.includes('embed-video-link')
                                    : item?.linkType === 4
                                    ? <EmbedVideoLink
                                        key={index}
                                        item={item}
                                        setShowCustomNestedLink={(data: any) => setShowCustomNestedLink(data)}   
                                        setShowEditCustom={(data: any) => setShowEditCustom(data)}   
                                        setShowDeleteCustom={(data: any) => {
                                            setShowDeleteCustom(data);
                                        }}
                                    />
                                    // : item?.id?.includes('embed-image-')
                                    : item?.linkType === 5
                                    ? <EmbedImage
                                        key={index}
                                        item={item}
                                        setShowCustomNestedLink={(data: any) => setShowCustomNestedLink(data)}   
                                        setShowEditCustom={(data: any) => setShowEditCustom(data)}   
                                        setShowDeleteCustom={(data: any) => {
                                            setShowDeleteCustom(data);
                                        }}
                                    />
                                    // : item?.id?.includes('announcement')
                                    // : item?.linkType === 6
                                    // ? <Announcement
                                    //     key={index}
                                    //     item={item}
                                    //     setShowCustomNestedLink={(data: any) => setShowCustomNestedLink(data)}   
                                    //     setShowEditCustom={(data: any) => setShowEditCustom(data)}   
                                    //     setShowDeleteCustom={(data: any) => {
                                    //         setShowDeleteCustom(data);
                                    //     }}
                                    // />
                                    : null
                                ))
                                : <div className='h-100 d-flex justify-content-center align-items-center'>
                                    <p>No Custom Links Found</p>
                                </div>       
                            }
                        </div>
                    </div>
                }
            </div>

            <div className='mobile-sub-menu w-100'>
                {
                    loading
                        ? <div className='loading-container w-100 d-flex justify-content-center align-items-center'>
                            <Spinner animation="border" variant="primary" />
                        </div>
                        : <div className='bio-page-menu-container'>
                            <div className="form-container custom-link-gap">
                                {
                                    customLinks?.length
                                    ? customLinks?.map((item: any, index: number) => (
                                        // item?.id?.includes('simple-link')
                                        item?.linkType === 1
                                        ? <SimpleLink
                                            key={index}
                                            item={item}
                                            setShowEditCustom={(data: any) => setShowEditCustom(data)}   
                                            setShowDeleteCustom={(data: any) => setShowDeleteCustom(data)}
                                        />
                                        // : item?.id?.includes('nested')
                                        : item?.linkType === 2
                                        ? <NestedLink
                                            key={index}
                                            item={item}
                                            setShowCustomNestedLink={(data: any) => setShowCustomNestedLink(data)}   
                                            setShowEditCustom={(data: any) => setShowEditCustom(data)}   
                                            setShowDeleteCustom={(data: any) => setShowDeleteCustom(data)}
                                        />
                                        // : item?.id?.includes('embed-video-')
                                        : item?.linkType === 3
                                        ? <EmbedVideo
                                            key={index}
                                            item={item}
                                            setShowCustomNestedLink={(data: any) => setShowCustomNestedLink(data)}   
                                            setShowEditCustom={(data: any) => setShowEditCustom(data)}   
                                            setShowDeleteCustom={(data: any) => {
                                                setShowDeleteCustom(data);
                                            }}
                                        />
                                        // : item?.id?.includes('embed-video-link')
                                        : item?.linkType === 4
                                        ? <EmbedVideoLink
                                            key={index}
                                            item={item}
                                            setShowCustomNestedLink={(data: any) => setShowCustomNestedLink(data)}   
                                            setShowEditCustom={(data: any) => setShowEditCustom(data)}   
                                            setShowDeleteCustom={(data: any) => {
                                                setShowDeleteCustom(data);
                                            }}
                                        />
                                        // : item?.id?.includes('embed-image-')
                                        : item?.linkType === 5
                                        ? <EmbedImage
                                            key={index}
                                            item={item}
                                            setShowCustomNestedLink={(data: any) => setShowCustomNestedLink(data)}   
                                            setShowEditCustom={(data: any) => setShowEditCustom(data)}   
                                            setShowDeleteCustom={(data: any) => {
                                                setShowDeleteCustom(data);
                                            }}
                                        />
                                        // : item?.id?.includes('announcement')
                                        // : item?.linkType === 6
                                        // ? <Announcement
                                        //     key={index}
                                        //     item={item}
                                        //     setShowCustomNestedLink={(data: any) => setShowCustomNestedLink(data)}   
                                        //     setShowEditCustom={(data: any) => setShowEditCustom(data)}   
                                        //     setShowDeleteCustom={(data: any) => {
                                        //         setShowDeleteCustom(data);
                                        //     }}
                                        // />
                                        : null
                                    ))
                                    : <div className='h-100 d-flex justify-content-center align-items-center'>
                                        <p>No Custom Links Found</p>
                                    </div>       
                                }
                            </div>
                        </div>
                }
            </div>
            
        </React.Fragment>
    );
};

export default CustomLinks;