import React from "react";
import { useNavigate } from "react-router-dom";

interface SetupCardProps {
    card?: any;
}

const SetupCard: React.FC<SetupCardProps> = ({ card }: any) => {
    const navigate = useNavigate();
    const handleRoute = (route: any) => {
        navigate(route);
    }
    return (
        <React.Fragment>
            <div className='custom-card'>
                <div className='title-description'>
                    <h2 className='title'>{card?.title}</h2>
                    <div className='special-description'>
                        {card?.description}
                    </div>
                    <div className='description'>
                        {card?.description?.substr(0, 82)}
                    </div>
                </div>
                <div className='button-container'>
                    <button onClick={() => handleRoute(card?.route)}>{card?.button_text}</button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SetupCard;