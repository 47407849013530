import { Switch } from 'antd';
import React, { useEffect } from 'react';
// import save_icon from '../../../../assets/icons/settings/save-icon.svg';
// import edit_icon from '../../../../assets/icons/settings/edit-icon.svg';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import './Features.scss';
// import { getProfileInfo } from '../../../Settings/UserProfile/profileManageReducer';
import { setSuccessMessage } from '../../../../unified/reducer';
import { Spinner } from 'react-bootstrap';
const Features: React.FC = () => {
    const dispatch = useDispatch();
    const [loading, setLoading]: any = React.useState(false);
    // const {personalInfo} = useSelector((state: any) => state?.profileManageSettingState);
    // const {email}: any = personalInfo || {};
    // useEffect(() => {
    //     if (email) {
    //         setTimeout(() => {
    //             setLoading(false);
    //         }, 2000);
    //     } else {
    //         setLoading(true);
    //         dispatch(getProfileInfo());
    //     } 
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 5000);
    // } , [dispatch, email]);

    const updateFeaturesSettings = (key: any, value: any) => {
        // dispatch(updateProfileInfoSetting({ columnName: key, columnValue: value, clapupUserId: sessionStorage.getItem('cuid') }));
        setTimeout(() => dispatch(setSuccessMessage("Settings saved.")), 1000);
    }

    // console.log("=============",{personalInfo});
    const {userPermissions} = useSelector((state: any) => state?.login);

    const history = useNavigate();
    
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "generalSettings-Account") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions?.length]);
    
    return (
        <React.Fragment>
            <div className='lg-sub-menu h-100 p-0'>
                {
                    loading
                    ? <div className='loading-container w-100 h-100 d-flex justify-content-center align-items-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : <div className='workspace-menu-container h-100'>
                        <div className="form-container h-100">
                            {/* Video Testimonial */}
                            <div className="form-group">
                                <div className="input-container feature-input-container">
                                    <input
                                        defaultValue={"Video Testimonial"}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Email"
                                        className={`border-none`}
                                    />
                                    <Switch
                                        defaultChecked={true}
                                        size='small'
                                        onChange={(value) => {
                                            console.log({ videoTestimonial: value });
                                            
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Customer Feedback */}
                            <div className="form-group">
                                <div className="input-container feature-input-container">
                                    <input
                                        defaultValue={"Customer Feedback"}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Email"
                                        className={`border-none`}
                                    />
                                    <Switch
                                        defaultChecked={true}
                                        size='small'
                                        onChange={(value) => {
                                            console.log({ customerFeedback: value });
                                            
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Bio Links */} 
                            <div className="form-group">
                                <div className="input-container feature-input-container">
                                    <input
                                        defaultValue={"Bio Links"}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Email"
                                        className={`border-none`}
                                    />
                                    <Switch
                                        defaultChecked={true}
                                        size='small'
                                        onChange={(value) => {
                                            console.log({ bioLinks: value });
                                            
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Bio Gallery */}
                            <div className="form-group">
                                <div className="input-container feature-input-container">
                                    <input
                                        defaultValue={"Bio Gallery"}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Email"
                                        className={`border-none`}
                                    />
                                    <Switch
                                        defaultChecked={true}
                                        size='small'
                                        onChange={(value) => {
                                            console.log({ bioGallery: value });
                                            
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Bio Chat */}
                            <div className="form-group">
                                <div className="input-container feature-input-container">
                                    <input
                                        defaultValue={"Bio Chat"}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Email"
                                        className={`border-none`}
                                    />
                                    <Switch
                                        defaultChecked={true}
                                        size='small'
                                        onChange={(value) => {
                                            console.log({ bioChat: value });
                                            
                                        }}
                                    />
                                </div>
                            </div>    
                        </div>
                    </div>
                }
            </div>

            <div className='mobile-sub-menu w-100'>
                {
                    loading
                    ? <div className='loading-container w-100 d-flex justify-content-center align-items-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : <div className='workspace-menu-container'>
                        <div className="form-container">
                            {/* Video Testimonial */}
                            <div className="form-group">
                                <div className="input-container ">
                                    <input
                                        defaultValue={"Video Testimonial"}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Email"
                                        className={`border-none`}
                                    />
                                    <Switch
                                        defaultChecked={true}
                                        size='small'
                                        onChange={(value) => {
                                            console.log({ videoTestimonial: value });
                                            
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Customer Feedback */}
                            <div className="form-group">
                                <div className="input-container">
                                    <input
                                        defaultValue={"Customer Feedback"}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Email"
                                        className={`border-none`}
                                    />
                                    <Switch
                                        defaultChecked={true}
                                        size='small'
                                        onChange={(value) => {
                                            console.log({ customerFeedback: value });
                                            
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Bio Links */} 
                            <div className="form-group">
                                <div className="input-container">
                                    <input
                                        defaultValue={"Bio Links"}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Email"
                                        className={`border-none`}
                                    />
                                    <Switch
                                        defaultChecked={true}
                                        size='small'
                                        onChange={(value) => {
                                            console.log({ bioLinks: value });
                                            
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Bio Gallery */}
                            <div className="form-group">
                                <div className="input-container">
                                    <input
                                        defaultValue={"Bio Gallery"}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Email"
                                        className={`border-none`}
                                    />
                                    <Switch
                                        defaultChecked={true}
                                        size='small'
                                        onChange={(value) => {
                                            console.log({ bioGallery: value });
                                            
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Bio Chat */}
                            <div className="form-group">
                                <div className="input-container">
                                    <input
                                        defaultValue={"Bio Chat"}
                                        readOnly={true}
                                        type="text"
                                        placeholder="Email"
                                        className={`border-none`}
                                    />
                                    <Switch
                                        defaultChecked={true}
                                        size='small'
                                        onChange={(value) => {
                                            console.log({ bioChat: value });
                                            
                                        }}
                                    />
                                </div>
                            </div>    
                        </div>
                    </div>
                }
            </div>
           
        </React.Fragment>
    );
};
export default Features;
