import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CustomButton from '../components/CustomButton/Button';
// import GoogleLogo from '../assets/logo/google_round_icon.svg';
// import EmailLogo from '../assets/logo/mail_icon.svg';
// import MicrosoftLogo from '../assets/logo/microsoft_icon.svg';
import GoogleLogo from '../assets/logo/Google-Official-Logo.svg';
// import GoogleLogo from '../assets/logo/Google-Official-Logo-Final.png';
// import GoogleLogo from '../assets/logo/btn_google_light_normal_ios.svg';
// import EmailLogo from '../assets/logo/Email-Official-Logo.svg';
// import MicrosoftLogo from '../assets/logo/MS-Official-Logo.svg';
import clap_up_logo from '../assets/icons/ClapUp-Icon-2.png';
// import clap_up_logo from '../assets/icons/ClapUp-Icon.png';
// import clap_up_logo from '../assets/icons/ClapUp-Icon.svg';
import clapUp_sidebar_image from '../assets/bg-img/clap-up-signup-img.svg';
import './signup-login.scss';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RootState } from '../store';
import { api_url, auth_url } from '../utils/server';
import { setErrorMessage } from '../unified/reducer';
import { setGoogleAccessData, setGoogleSignUpData } from './loginReducer';

// const GoogleLogo = 'https://images.clapup.io/clapupapp/btn_google_light_normal_ios.svg';
const EmailLogo = 'https://images.clapup.io/clapupapp/email.svg';
const MicrosoftLogo = 'https://images.clapup.io/clapupapp/ms-symbollockup_mssymbol_19%201.svg';
const SignUpLogin = () => {
    const {
        accessTypeMessage,
        // userPermissions
    }: any = useSelector((state: RootState) => state?.login);
    const path = window.location.pathname;
    const [loginData, setLoginData] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const redirect = auth_url;
    // const host = "https://"?.replace("//", "%2F%2F")?.replace(":", "%3A")+window.location.host?.replace("/", "%2F")?.replace(":", "%3A")+"/profile"?.replace("/", "%2F")?.replace(":", "%3A");
    // old one - v1
    const handleGoogleLogin = () => {
        console.log({link: window.location.host});
        
        sessionStorage.setItem('appAccessType', 'google-login');
        localStorage.setItem('appAccessType', 'google-login');

        // ========== it will be removed after recognition =======
        // const accessed_user_details: any = {
        //     "id": "100791156272027091046",
        //     "email": "3pottersmanager@gmail.com",
        //     "verified_email": true,
        //     "name": "Mehedi Hasan", "given_name": "3Potters",
        //     "family_name": "Manager",
        //     "picture": "https://lh3.googleusercontent.com/a/AAcHTtc03XaAgA8Tp5e2xit4H9RiSAP2aXucLo2gQU2hrAx6wA=s96-c",
        //     "locale": "en"
        // };
        // sessionStorage.setItem("accessed_user_details", JSON.stringify(accessed_user_details));
        
        // const manual_payload_for_token = {
        //     "email": "3pottersmanager@gmail.com",
        //     "clapupUserId": "440f98db-b99b-4d20-b3c1-dadd43d59aab",
        //     "jwtToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IjNwb3R0ZXJzbWFuYWdlckBnbWFpbC5jb20iLCJuYmYiOjE3MTczMDE3NTQsImV4cCI6MTcxNzczMzc1NCwiaWF0IjoxNzE3MzAxNzU0LCJpc3MiOiJodHRwczovL2FwaS10ZXN0LmNsYXB1cC5tZSIsImF1ZCI6Imh0dHBzOi8vYXBpLXRlc3QuY2xhcHVwLm1lIn0.igliAfu2jUcwx3TU344-b9q8iM5FloxVy7W6t0E8Z9E",
        //     "refreshToken": "cdb930b0-2096-11ef-8382-6adf3b91f978-cdb930f9-2096-11ef-8382-6adf3b91f978"
        // };
        

        // dispatch(setGoogleAccessData({
        //     "success": true,
        //     "googleUserId": "100791156272027091046",
        //     "clapupUserId": "440f98db-b99b-4d20-b3c1-dadd43d59aab",
        //     "loginDetails": [
        //       {
        //         "roleId": "2d88e4c1-fd0c-11ec-b7d3-e4e74965c2c3",
        //         "roleName": "SuperAdmin",
        //         "workspaceId": "dc474190-f363-4e8e-9949-8cdac65b4d44",
        //         // "workspaceId": "2b1d2ced-1188-11ee-92c8-9600022a1805",
        //         "workspaceName": "Clapup"
        //       }
        //     ],
        //     "errors": null,
        //     "token": manual_payload_for_token?.jwtToken,
        //     "refreshToken": manual_payload_for_token?.refreshToken,
        // }));
        // setTimeout(() => { 
        //     window.location.reload();
        // }, 2000);
        // ============= X ===============
        
        // // unused
        // // window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/drive.metadata.readonly&include_granted_scopes=true&response_type=token&redirect_uri=https://${window.location.host}/profile&client_id=842696894889-e3dc6hadel9a1f5j4ll6v8i5d0m28o2l.apps.googleusercontent.com`;
        // // window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://${window.location.host}/profile&response_type=token&client_id=651003914717-81c4av16ki35tcfmv89ig7ku204j57q2.apps.googleusercontent.com&token_uri=https://oauth2.googleapis.com/token&include_granted_scopes=true&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fplus.business.manage`; 
        // // window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${host}&prompt=consent&response_type=code&client_id=651003914717-81c4av16ki35tcfmv89ig7ku204j57q2.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+openid&access_type=offline`;
        axios.get(
            `${api_url}/api/v1.2/clapup/getKeys?provider=Google`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json'
                }
            }
        ).then((response: any) => {
            console.log({ response });
            // const { clientId, clientSecret } = response?.data;
            const { clientId } = response?.data;
            sessionStorage.setItem("client_details", JSON.stringify({ clientId }));
            // it will work
            axios.post(`${redirect}/auth/google/url?code=${window.location.host}/profile`, process.env.NODE_ENV === "development" ? { clientId } : {clientId})
            .then((res: any) => {
                // console.log('res data  =====34====>', res);
                setLoginData(res?.data);
            })
            .catch((err) => {
                // console.log('err data  =====57====>', err);
                // ====== After google approval this useEffect block will be commented =========
                // const accessed_user_details: any = {
                //     "id": "100791156272027091046",
                //     "email": "pottersmanager@gmail.com",
                //     "verified_email": true,
                //     "name": "Mehedi Hasan", "given_name": "3Potters",
                //     "family_name": "Manager",
                //     "picture": "https://lh3.googleusercontent.com/a/AAcHTtc03XaAgA8Tp5e2xit4H9RiSAP2aXucLo2gQU2hrAx6wA=s96-c",
                //     "locale": "en"
                // };
                // sessionStorage.setItem("accessed_user_details", JSON.stringify(accessed_user_details));
                
                // const manual_payload_for_token = {
                //     "email": "3pottersmanager@gmail.com",
                //     "clapupUserId": "440f98db-b99b-4d20-b3c1-dadd43d59aab",
                //     "jwtToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IjNwb3R0ZXJzbWFuYWdlckBnbWFpbC5jb20iLCJuYmYiOjE3MTMxNTA3MTMsImV4cCI6MTcxMzU4MjcxMywiaWF0IjoxNzEzMTUwNzEzLCJpc3MiOiJodHRwczovL2FwaS10ZXN0LmNsYXB1cC5tZSIsImF1ZCI6Imh0dHBzOi8vYXBpLXRlc3QuY2xhcHVwLm1lIn0.tl0g-pICNDPXEaJ8t8L-HYTazvP1gYymJnOT6rQ6ZbQ",
                //     "refreshToken": "e8fbb1d6-fad5-11ee-8f53-fa163e3fc773-e8fbb20d-fad5-11ee-8f53-fa163e3fc773"
                // };
                

                // dispatch(setGoogleAccessData({
                //     "success": true,
                //     "googleUserId": "100791156272027091046",
                //     "clapupUserId": "440f98db-b99b-4d20-b3c1-dadd43d59aab",
                //     "loginDetails": [
                //         {
                //         "roleId": "2d88e4c1-fd0c-11ec-b7d3-e4e74965c2c3",
                //         "roleName": "SuperAdmin",
                //         "workspaceId": "dc474190-f363-4e8e-9949-8cdac65b4d44",
                //         // "workspaceId": "2b1d2ced-1188-11ee-92c8-9600022a1805",
                //         "workspaceName": "Clapup"
                //         }
                //     ],
                //     "errors": null,
                //     "token": manual_payload_for_token?.jwtToken,
                //     "refreshToken": manual_payload_for_token?.refreshToken,
                // }));
                dispatch(setErrorMessage(err?.message || "Login Failed"));
                // window.location.reload();
            });
        }).catch(error => {
            console.log({ error });
        }); 
        
    }

    const handleGoogleSignUp = () => {
        sessionStorage.setItem('appAccessType', 'google-signup');
        localStorage.setItem('appAccessType', 'google-signup');
        axios.get(
            `${api_url}/api/v1.2/clapup/getKeys?provider=Google`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token') || sessionStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json'
                }
            }
        ).then(response => {
            // console.log({ response });
            const { clientId, clientSecret } = response?.data;
            sessionStorage.setItem("client_details", JSON.stringify({ clientId, clientSecret }));
            axios.post(`${redirect}/auth/google/url/?code=${window.location.host}/profile`, { clientId })
            .then((res) => {
                // console.log('res data  =====43====>', res);
                localStorage.setItem('isCreatedWorkspace', "true");
                setLoginData(res?.data);
            })
            .catch((err) => {
                dispatch(setErrorMessage(err?.message || "Signup Failed"));
            });
        }).catch(error => {
            console.log({ error });
        }); 
    }

    const handleGoogleLoginFromLocal = () => {
        console.log({link: window.location.host});
        
        sessionStorage.setItem('appAccessType', 'google-login');
        localStorage.setItem('appAccessType', 'google-login');

        // ========== it will be removed after recognition =======
        const accessed_user_details: any = {
            "id": "100791156272027091046",
            "email": "3pottersmanager@gmail.com",
            "verified_email": true,
            "name": "Mehedi Hasan", "given_name": "3Potters",
            "family_name": "Manager",
            "picture": "https://lh3.googleusercontent.com/a/AAcHTtc03XaAgA8Tp5e2xit4H9RiSAP2aXucLo2gQU2hrAx6wA=s96-c",
            "locale": "en"
        };
        sessionStorage.setItem("accessed_user_details", JSON.stringify(accessed_user_details));
        
        const manual_payload_for_token = {
            "email": "3pottersmanager@gmail.com",
            "clapupUserId": "440f98db-b99b-4d20-b3c1-dadd43d59aab",
            "jwtToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IjNwb3R0ZXJzbWFuYWdlckBnbWFpbC5jb20iLCJuYmYiOjE3MjYxMzg1NjEsImV4cCI6MTcyNjU3MDU2MSwiaWF0IjoxNzI2MTM4NTYxLCJpc3MiOiJodHRwczovL2FwaS10ZXN0LmNsYXB1cC5tZSIsImF1ZCI6Imh0dHBzOi8vYXBpLXRlc3QuY2xhcHVwLm1lIn0.SINkbsGfoOKQzhWl-e_NuVvz3lYoYV5Pw0N4AAZ_k6s",
            "refreshToken": "996e5185-70f5-11ef-bcb6-46a84b0cc21e-996e51b8-70f5-11ef-bcb6-46a84b0cc21e"
        };
        

        dispatch(setGoogleAccessData({
            "success": true,
            "googleUserId": "100791156272027091046",
            "clapupUserId": "440f98db-b99b-4d20-b3c1-dadd43d59aab",
            "loginDetails": [
              {
                "roleId": "2d88e4c1-fd0c-11ec-b7d3-e4e74965c2c3",
                "roleName": "SuperAdmin",
                "workspaceId": "dc474190-f363-4e8e-9949-8cdac65b4d44",
                // "workspaceId": "2b1d2ced-1188-11ee-92c8-9600022a1805",
                "workspaceName": "Clapup"
              }
            ],
            "errors": null,
            "token": manual_payload_for_token?.jwtToken,
            "refreshToken": manual_payload_for_token?.refreshToken,
        }));
        setTimeout(() => { 
            window.location.reload();
        }, 2000);
    }

    // Local SignUp
    const handleGoogleSignUpFromLocal = () => {
        console.log({link: window.location.host});
        sessionStorage.setItem('appAccessType', 'google-signup');
        localStorage.setItem('appAccessType', 'google-signup');
        
        // ========== it will be removed after recognition =======
        const accessed_user_details: any = {
            "id": "100791156272027091046",
            "email": "3pottersmanager@gmail.com",
            "verified_email": true,
            "name": "Mehedi Hasan", "given_name": "3Potters",
            "family_name": "Manager",
            "picture": "https://lh3.googleusercontent.com/a/AAcHTtc03XaAgA8Tp5e2xit4H9RiSAP2aXucLo2gQU2hrAx6wA=s96-c",
            "locale": "en"
        };
        sessionStorage.setItem("accessed_user_details", JSON.stringify(accessed_user_details));
        
        const manual_payload_for_token = {
            "email": "3pottersmanager@gmail.com",
            "clapupUserId": "440f98db-b99b-4d20-b3c1-dadd43d59aab",
            "jwtToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IjNwb3R0ZXJzbWFuYWdlckBnbWFpbC5jb20iLCJuYmYiOjE3MjYxMzg1NjEsImV4cCI6MTcyNjU3MDU2MSwiaWF0IjoxNzI2MTM4NTYxLCJpc3MiOiJodHRwczovL2FwaS10ZXN0LmNsYXB1cC5tZSIsImF1ZCI6Imh0dHBzOi8vYXBpLXRlc3QuY2xhcHVwLm1lIn0.SINkbsGfoOKQzhWl-e_NuVvz3lYoYV5Pw0N4AAZ_k6s",
            "refreshToken": "996e5185-70f5-11ef-bcb6-46a84b0cc21e-996e51b8-70f5-11ef-bcb6-46a84b0cc21e"
        };
        
        const query = new URLSearchParams(window.location.search);
        const workspace_id = query.get('ws');
        const invitation_id = query.get('iid');
        if (workspace_id && invitation_id) {
            console.log('workspace_id ===== ===== ==> ', { workspace_id });
            console.log('invitation_id ===== ===== ==> ', { invitation_id });
            localStorage.setItem('workspace_id', workspace_id);
            localStorage.setItem('invitation_id', invitation_id);
            localStorage.setItem('wuid', workspace_id);
            // localStorage.setItem('wuid', invitation_id);
            sessionStorage.setItem('wuid', workspace_id);
            // sessionStorage.setItem('wuid', invitation_id);
        }  

        // dispatch(setGoogleAccessData({
        dispatch(setGoogleSignUpData({
            "success": true,
            "googleUserId": "100791156272027091046",
            "clapupUserId": "440f98db-b99b-4d20-b3c1-dadd43d59aab",
            "loginDetails": [
              {
                "roleId": "2d88e4c1-fd0c-11ec-b7d3-e4e74965c2c3",
                "roleName": "SuperAdmin",
                "workspaceId": "dc474190-f363-4e8e-9949-8cdac65b4d44",
                // "workspaceId": "2b1d2ced-1188-11ee-92c8-9600022a1805",
                "workspaceName": "Clapup"
              }
            ],
            "errors": null,
            "token": manual_payload_for_token?.jwtToken,
            "refreshToken": manual_payload_for_token?.refreshToken,
        }));
        navigate("/profile");
        // setTimeout(() => { 
        //     window.location.reload();
        // }, 2000);
    }

    useEffect(() => {
        const uid = sessionStorage.getItem("cuid");
        // const roleId = sessionStorage.getItem("roleId");
        const wuid = sessionStorage.getItem("wuid");
        const sessionAuth = uid && wuid;
        console.log("Access Type Message", {accessTypeMessage, sessionAuth});
        const query = new URLSearchParams(window.location.search);
        const workspace_id = query.get('ws');
        const invitation_id = query.get('iid');
        
        if (sessionAuth) {
            navigate('/');
        } else if (loginData) {
            let googleURL: any = loginData;
            // console.log('google-url ===== ===== ==> ', {googleURL});
            if(workspace_id && invitation_id) {
                console.log('workspace_id ===== ===== ==> ', {workspace_id});
                console.log('invitation_id ===== ===== ==> ', {invitation_id});
                localStorage.setItem('workspace_id', workspace_id);
                localStorage.setItem('invitation_id', invitation_id);
                localStorage.setItem('wuid', workspace_id);
                sessionStorage.setItem('wuid', workspace_id);
                // localStorage.setItem('isCreatedWorkspace', "true");
            } else {
                localStorage.removeItem('workspace_id');
                localStorage.removeItem('invitation_id');
            }
            //  window.location.href = "https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https%3A%2F%2Flocalhost%3A4000%2Fprofile&client_id=651003914717-81c4av16ki35tcfmv89ig7ku204j57q2.apps.googleusercontent.com&client_id=diIqDDpUqY0EP6m6nYQuuGEl&include_granted_scopes=true&access_type=offline&response_type=code&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fplus.business.manage"
            sessionStorage.setItem("isLoading", "true");
            if (googleURL) {
                window.location.href = googleURL;
            }
        } else{
            dispatch({type: "LOGOUT"});
            sessionStorage.clear();
            localStorage.clear();
        }
    }, [loginData, accessTypeMessage]);

    const [username, setUsername] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    
    const handleLoginByUserInput = async() => { 
        console.log({ username, password });
        sessionStorage.setItem('appAccessType', 'google-login');
        localStorage.setItem('appAccessType', 'google-login');
        if (!username?.trim()) {
            dispatch(setErrorMessage("Username is required"));
            return;
        }
        if (!password?.trim()) {
            dispatch(setErrorMessage("Password is required"));
            return;
        }
        // const user_name = username;
        // const user_password = password;
        try {
            // username: clapuptestuser, pass: clapupTestUser@123
            const payload = {
                username,
                password
            };

            const { data, status } = await axios({
                method: "POST",
                // url: `${api_url}/api/v1.2/ClapupIdentity/LoginWithClaup?Username=${user_name}&Password=${user_password}`,
                url: `${api_url}/api/v1.2/ClapupIdentity/LoginWithClaup`,
                data: payload,
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            console.log({ data, status });
            if (status === 200) {
                const accessed_user_details: any = {
                    "id": "100791156272027091046",
                    "email": "3pottersmanager@gmail.com",
                    "verified_email": true,
                    "name": "Mehedi Hasan", "given_name": "3Potters",
                    "family_name": "Manager",
                    "picture": "https://lh3.googleusercontent.com/a/AAcHTtc03XaAgA8Tp5e2xit4H9RiSAP2aXucLo2gQU2hrAx6wA=s96-c",
                    "locale": "en"
                };
                sessionStorage.setItem("accessed_user_details", JSON.stringify(accessed_user_details));
                
                dispatch(setGoogleAccessData(data));
                setTimeout(() => { 
                    // navigate('/dashboard');
                    window.location.reload();
                }, 2000);
            } else {
                dispatch(setErrorMessage("Your username or password is incorrect"));
            }
        } catch (error: any) {
            // console.log({ error });
            dispatch(setErrorMessage("Your username or password is incorrect"));
        }
    };
    
    const handleLoginByUserInputFromLocal = async () => { 
        console.log({ username, password });
        sessionStorage.setItem('appAccessType', 'google-login');
        localStorage.setItem('appAccessType', 'google-login');
        if (!username?.trim()) {
            dispatch(setErrorMessage("Username is required"));
            return;
        }
        if (!password?.trim()) {
            dispatch(setErrorMessage("Password is required"));
            return;
        }
        // const user_name = username;
        // const user_password = password;
        try {
            // username: clapuptestuser, pass: clapupTestUser@123
            const payload = {
                username,
                password
            };

            const { data, status } = await axios({
                method: "POST",
                // url: `${api_url}/api/v1.2/ClapupIdentity/LoginWithClaup?Username=${user_name}&Password=${user_password}`,
                url: `${api_url}/api/v1.2/ClapupIdentity/LoginWithClaup`,
                data: payload,
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            console.log({ data, status });
            if (status === 200) {
                const accessed_user_details: any = {
                    "id": "100791156272027091046",
                    "email": "3pottersmanager@gmail.com",
                    "verified_email": true,
                    "name": "Mehedi Hasan", "given_name": "3Potters",
                    "family_name": "Manager",
                    "picture": "https://lh3.googleusercontent.com/a/AAcHTtc03XaAgA8Tp5e2xit4H9RiSAP2aXucLo2gQU2hrAx6wA=s96-c",
                    "locale": "en"
                };
                sessionStorage.setItem("accessed_user_details", JSON.stringify(accessed_user_details));
                
                dispatch(setGoogleAccessData(data));
                setTimeout(() => { 
                    // navigate('/profile');
                    // navigate('/dashboard');
                    window.location.reload();
                }, 2000);
            } else {
                dispatch(setErrorMessage("Your username or password is incorrect"));
            }
        } catch (error: any) {
            // console.log({ error });
            dispatch(setErrorMessage("Your username or password is incorrect"));
        }
    };
    return (
        <React.Fragment>
            <div className="signup-login-container-mobile align-items-center">
                <div className="signup-login-wrapper">
                    <div className='signup-login-container'>
                        <img className="logo mb-3" src={clap_up_logo} alt="logo" />
                        <div className="signup-login-btn-container">
                            <button className={path?.includes('login') ? "signup-login-btn-active" : ''} onClick={() => navigate('/login')}>Login</button>
                            <button className={path?.includes('signup') ? "signup-login-btn-active" : ''} onClick={() => navigate('/signup')}>Sign up</button>
                        </div>
                        <div className="login-flex">
                            <div className="login-wrapper">
                                {
                                    path === "/email-login" || path === "/email-signup"
                                    ? 
                                        <>
                                            <div className="input-wrapper">
                                                <input value={username || ""}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    className="sm-input" type="text" placeholder="Enter your User Name"
                                                />
                                            </div>

                                            <div className="input-wrapper">
                                                <input value={password || ""}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    className="sm-input" type="password" placeholder="Enter your Password"
                                                />
                                            </div>

                                            <div className="input-wrapper">
                                                <button
                                                    onClick={() => {
                                                        if (path === "/email-login") {
                                                            if (process.env.NODE_ENV === "development") {
                                                                handleLoginByUserInputFromLocal();
                                                                return;
                                                            }
                                                            handleLoginByUserInput();
                                                        }
                                                    }}
                                                    type='button' className="user-input-signup-login"
                                                >
                                                    {path === "/email-login" ? "Sign in" : "Sign up"}
                                                </button>
                                            </div>
                                        </>
                                    :
                                        <>
                                            {/* Google */}
                                            <div className="button-wrapper">
                                                <CustomButton
                                                    // hover={true}
                                                    iconComponent={<img src={GoogleLogo} alt="google"/>}
                                                    // iconComponent={<GoogleOutlined style={{fontSize: 21, display: 'block'}} />}
                                                    classes="social-btn"
                                                    onClick={() =>{
                                                        if (path === '/login') {
                                                            // if (process.env.NODE_ENV === "development") {
                                                            //     handleGoogleLoginFromLocal();
                                                            //     return;
                                                            // }
                                                            handleGoogleLogin();
                                                        } else {
                                                            if (process.env.NODE_ENV === "development") {
                                                                handleGoogleSignUpFromLocal()
                                                                return;
                                                            }
                                                            handleGoogleSignUp();
                                                        }
                                                    }}
                                                    children={<span className="btn-content">
                                                        {path === "/login" ? "Sign in" : "Sign up"} with Google
                                                    </span>}
                                                />
                                            </div>

                                            {/* Email */}
                                            <div className="button-wrapper">
                                                <CustomButton
                                                    // hover={true}
                                                    onClick={() => {
                                                        if(path === "/login"){
                                                            navigate(path === "/login" ? '/email-login' : '/email-signup');
                                                        }
                                                    }}
                                                    iconComponent={<img src={EmailLogo} alt="email"/>}
                                                    classes='social-btn'
                                                    // iconPath={GoogleLogo}
                                                    // image={true}
                                                    children={
                                                        <div className='btn-text'>
                                                            {path === "/login" ? "Sign in" : "Sign up"} with Email and Password
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        </>
                                    
                                }


                                {/* Microsoft */}
                                {/* <div className="button-wrapper">
                                    <CustomButton
                                        // onClick={handleGoogleLogin}
                                        // hover={true}
                                        iconComponent={<img src={MicrosoftLogo} alt="microsoft"/>}
                                        classes="social-btn"
                                        children={<span className="btn-content">
                                            {path === "/login" ? "Login" : "Sign up"} with Microsoft
                                        </span>}
                                    />
                                </div> */}
                                {/* Email */}
                                {/* <div className="button-wrapper">
                                    <CustomButton
                                        // hover={true}
                                        // onClick={handleGoogleLogin}
                                        iconComponent={<img src={EmailLogo} alt="email"/>}
                                        // iconComponent={<MailOutlined style={{fontSize: 21, display: 'block'}} />}
                                        classes="social-btn"
                                        children={
                                            <span className="btn-content">
                                                {path === "/login" ? "Login" : "Sign up"} with Email
                                            </span>
                                        }
                                    />
                                </div> */}

                                <div className="login-footer">
                                    {
                                        accessTypeMessage ? (
                                            <p className="access-type-message text-primary font-italic">{accessTypeMessage}</p>
                                        ) : (
                                            null
                                        )
                                    }
                                    {
                                        path === "/email-login" || path === "/email-signup"
                                        ? (
                                            <>
                                                <p className="text-center">Click <Link to="/login">here</Link> for Google Sign in instead of Password</p>
                                                <p className="text-center">Don't have account? <Link to="/signup">Sign up</Link></p>
                                            </>
                                        )
                                        : path === '/login' ? (
                                            <>
                                                <p className="text-center">Don't have account?</p>
                                                <p className="text-center"><Link to="/signup">Sign up</Link></p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="text-center">Already have an account?</p>
                                                <p className="text-center"><Link to="/login">Login</Link></p>
                                            </>
                                        )
                                    }
                                </div>

                                <div className="terms-conditions">
                                    <p>
                                        By signing up, you are agreeing to our <a target={"_blank"} rel="noreferrer" href='https://clapup.io/terms-and-conditions'>terms of service</a> and <a target={"_blank"} rel="noreferrer" href='https://clapup.io/privacy-policy'>privacy policy</a>.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Container fluid className="login-container-lg">
                <Row>
                    <Col xs={6} className="no-gutter">
                        <div className="side-wrapper">
                            {/* <Carousels /> */}
                            {/* {
                                path === '/login' ? (
                                    <div>
                                        <h1 className="welcome">Welcome back,</h1>
                                        <p className="log-in-des">Log in to your account</p>
                                    </div>
                                ) : (
                                    <img className='image-fluid signup-img' src={clapUp_sidebar_image} alt="Signup-Img" />
                                )
                            } */}
                            <img className='image-fluid signup-img' src={clapUp_sidebar_image} alt="Signup-Img" />
                        </div>
                    </Col>
                    <Col xs={6} className="no-gutter">
                        <div className="login-flex">
                            <div className="login-wrapper">
                                <img className="clapup-logo img-flui mb-3" src={clap_up_logo} alt="logo" />
                                {/* Google */}
                                {
                                    path === "/email-login" || path === "/email-signup"
                                    ?
                                        <>
                                            <div className="input-wrapper">
                                                <input value={username || ""}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    className="sm-input" type="text" placeholder="Enter your User Name"
                                                />
                                            </div>

                                            <div className="input-wrapper">
                                                <input value={password || ""}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    className="sm-input" type="password" placeholder="Enter your Password"
                                                />
                                            </div>

                                            <div className="input-wrapper">
                                                <button
                                                    onClick={() => {
                                                        if (path === "/email-login") {
                                                            if (process.env.NODE_ENV === "development") {
                                                                handleLoginByUserInputFromLocal();
                                                                return;
                                                            }
                                                            handleLoginByUserInput();
                                                        }
                                                    }}
                                                    type='button' className="user-input-signup-login"
                                                >
                                                    {path === "/email-login" ? "Sign in" : "Sign up"}
                                                </button>
                                            </div>
                                        </>
                                    :
                                        <>
                                            {/* Google */}
                                            <div className="button-wrapper">
                                                <CustomButton
                                                    // hover={true}
                                                    iconComponent={<img src={GoogleLogo} alt="google" />}
                                                    iconPath={GoogleLogo}
                                                    classes='social-btn'
                                                    // image={true}
                                                    onClick={() => {
                                                        if (path === '/login') {
                                                            // if (process.env.NODE_ENV === "development") {
                                                            //     handleGoogleLoginFromLocal();
                                                            //     return;
                                                            // }
                                                            handleGoogleLogin();
                                                        } else {
                                                            if (process.env.NODE_ENV === "development") {
                                                                handleGoogleSignUpFromLocal()
                                                                return;
                                                            }
                                                            handleGoogleSignUp();
                                                        }
                                                    }}
                                                    children={<div className='btn-text'>
                                                        {path === "/login" ? "Sign in" : "Sign up"} with Google
                                                    </div>}
                                                />
                                            </div>
                                            {/* Email */}
                                            <div className="button-wrapper">
                                                <CustomButton
                                                    // hover={true}
                                                    onClick={() => {
                                                        if(path === "/login"){
                                                            navigate(path === "/login" ? '/email-login' : '/email-signup');
                                                        }
                                                    }}
                                                    iconComponent={<img src={EmailLogo} alt="email"/>}
                                                    classes='social-btn'
                                                    // iconPath={GoogleLogo}
                                                    // image={true}
                                                    children={
                                                        <div className='btn-text'>
                                                            {path === "/login" ? "Sign in" : "Sign up"} with Email and Password
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        </>
                                }
                                            
                                {/* Microsoft */}
                                {/* <div className="button-wrapper">
                                    <CustomButton
                                        // onClick={handleGoogleLogin}
                                        // hover={true}
                                        iconComponent={<img src={MicrosoftLogo} alt="microsoft"/>}
                                        classes='social-btn'
                                        iconPath={GoogleLogo}
                                        // image={true}
                                        children={<div className='btn-text'>
                                            {path === "/login" ? "Login" : "Sign up"} with Microsoft (Coming Soon)
                                        </div>}
                                    />
                                </div> */}
                                {/* Email */}
                                {/* <div className="button-wrapper">
                                    <CustomButton
                                        // hover={true}
                                        // onClick={handleGoogleLogin}
                                        iconComponent={<img src={EmailLogo} alt="email"/>}
                                        classes='social-btn'
                                        iconPath={GoogleLogo}
                                        // image={true}
                                        children={
                                            <div className='btn-text'>
                                                {path === "/login" ? "Login" : "Sign up"} with Email (Coming Soon)
                                            </div>
                                        }
                                    />
                                </div> */}

                                <div className="login-footer">
                                    {
                                        accessTypeMessage ? (
                                            <p className="access-type-message text-primary font-italic">{accessTypeMessage}</p>
                                        ) : (
                                            null
                                        )
                                    }
                                    <div className="w-100" style={{marginBottom: 10}}>
                                        {
                                            path === "/email-login" || path === "/email-signup"
                                            ? (
                                                <>
                                                    <p className="text-center">Click <Link to="/login">here</Link> for Google Sign in instead of Password</p>
                                                    <p className="text-center">Don't have account? <Link to="/signup">Sign up</Link></p>
                                                </>
                                            )
                                            : path === '/login' ? (
                                                <>
                                                    <p className="text-center">Don't have account?</p>
                                                    <p className="text-center"><Link to="/signup">Sign up</Link></p>
                                                </>
                                            ) : (
                                                <>
                                                    <p className="text-center">Already have an account?</p>
                                                    <p className="text-center"><Link to="/login">Login</Link></p>
                                                </>
                                            )
                                        }
                                    </div>
                                    <div className="terms-conditions">
                                        <p>
                                            By signing up, you are agreeing to our <a target={"_blank"} rel="noreferrer" href='https://clapup.io/terms-and-conditions'>terms of service</a> and acknowledge 
                                            that you read our <a target={"_blank"} rel="noreferrer" href='https://clapup.io/privacy-policy'>privacy policy</a>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default SignUpLogin;