import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { getImage, getGenerateApiImageByName } from "../../../../unified/image_service";
import { updateNotification, toggleUpdateNotification } from "../userProfileManageReducer";

const Notifications: React.FC = () => {
    const dispatch = useDispatch();
    const { notifications, is_updated_notification } = useSelector((state: any) => state?.profileManageSettingState);
    const [notification_list, setNotifications] = useState<any>([]);
    
    // const [notification_list, setNotification] = useState<any>([
    //     {
    //         title: "Video Testimonial",
    //         IsSystemNotificationRequired: false,
    //         IsEmailNotificationRequired: true,
    //     },
    //     {
    //         title: "Customer Feedback",
    //         IsSystemNotificationRequired: true,
    //         IsEmailNotificationRequired: true,
    //     },
    //     {
    //         title: "Google",
    //         IsSystemNotificationRequired: true,
    //         IsEmailNotificationRequired: true,
    //     },
    //     {
    //         title: "Facebook",
    //         IsSystemNotificationRequired: false,
    //         IsEmailNotificationRequired: true,
    //     },
    //     {
    //         title: "Product Updates",
    //         IsSystemNotificationRequired: false,
    //         IsEmailNotificationRequired: true,
    //     },
    // ]);
    useEffect(() => {
        if (notifications?.length) {
            console.log({ notifications });
            setNotifications(notifications);
            dispatch(toggleUpdateNotification(false));
        }
    }, [notifications?.length, is_updated_notification]);

    const updateNotificationPreference = (dataObject: any) => {
        console.log("updateNotificationPreference ==== ", dataObject);
        dispatch(updateNotification({
            ...dataObject,
            // clapupUserId: sessionStorage.getItem('cuid'),
            clapupId: sessionStorage.getItem('cuid'),
            workspaceId: sessionStorage.getItem('wuid'),
        }));
    }

    return (
        <React.Fragment>
            {
                notification_list?.map((notification: any, idx: any) => (
                    <div key={idx} className="notification-settings-form-item">
                        <div className="title">{notification?.title}</div>
                        <div className="action-body">
                            <img
                                className="switch-icon"
                                onClick={() => {
                                    const data_object = {
                                        id: notification?.Id,
                                        needEmailNotification: notification?.needEmailNotification,
                                        needSystemNotification: notification?.IsSystemNotificationRequired === 'false' ? true : false
                                    };
                                    updateNotificationPreference(data_object);
                                }}
                                src={getImage(notification?.IsSystemNotificationRequired === "true" ? "active-notification" : "in-active-notification")} 
                                alt="tik"
                            />
                        </div>
                    </div>
                ))
            }
            <div className="extra"></div>
        </React.Fragment>
    );
};

export default Notifications;