import React, { useState } from 'react';
import { Button, DatePicker, Form, Input, Select, TimePicker } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { getImage, testCollaboratorImage } from '../../../../../../unified/image_service';
import GeneralEdition from './GeneralEdition';
import PreviewEdition from './PreviewEdition';
// import { getImage, getTemplateImage } from '../../../../dashboard/image_service';
// import './Templates.scss';

type GeneralPreviewEditionProps = {
    onCancel?: () => void;
    onParentCallback?: (data: any, file?: any) => void;
};

const GeneralPreviewEdition: React.FC<any> = () => {

    const handleChange = (value: any, setState: any) => {
        setState(value);
    }

    const [isGeneralNonPreviewEdition, setIsGeneralNonPreviewEdition] = useState(true);

    return (
        <React.Fragment>
            <div className='general-preview-edition'>
                <div className='header-container'>
                    <div onClick={() => setIsGeneralNonPreviewEdition(!isGeneralNonPreviewEdition)} className={`header-tab${isGeneralNonPreviewEdition ? "-active" : ""}`}>General</div>
                    <div onClick={() => setIsGeneralNonPreviewEdition(!isGeneralNonPreviewEdition)} className={`header-tab${!isGeneralNonPreviewEdition ? "-active" : ""}`}>Preview</div>
                </div>
                {
                    isGeneralNonPreviewEdition
                    ? <GeneralEdition/>
                    : <PreviewEdition/>
                }
            </div>
        </React.Fragment>
    )
};

export default GeneralPreviewEdition;
