import React from "react";
// import { useNavigate } from "react-router-dom";

interface DashboardTeamCardProps {
    card?: any;
}

const DashboardTeamCard: React.FC<DashboardTeamCardProps> = ({ card }: any) => {
    // const navigate = useNavigate();
    // const handleRoute = (route: any) => {
    //     navigate(route);
    // }
    return (
        <React.Fragment>
            <div className='custom-card'>
                <div className='header-title'>
                    <div style={{background: `${card?.color}`}} className='title-icon'></div>
                    <div>{card?.teamName}</div>
                </div>
                <div className='description'>
                    <div>{card?.members}</div>
                    <div>Members</div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default DashboardTeamCard;