import React, { useEffect, useState } from 'react';
import star_active from "../../../assets/icons/active-star.svg";
import star_inactive from "../../../assets/icons/inactive-star.svg";
import './CustomRating.scss';

type CustomRatingProps = {
    rating?: any;
    onRating?: (rating: number) => void;
    customStyle?: any;
};
const rats = [1, 2, 3, 4, 5];
const CustomRating: React.FC<CustomRatingProps> = ({ onRating, rating, customStyle }: any) => {
    const [ratings, setRatings] = useState<number>(Number(rating) || 0);
    useEffect(() => {
        if (onRating) {
            onRating(ratings);
        }
    }, [ratings]);
    return (
        <div
            className={`rating-container `}
            style={{ display: 'flex', gap: "0.5rem",...customStyle }}
        >
            {rats.map((rat, index) => (
                <img
                    key={index}
                    style={{ cursor: 'pointer' }}
                    src={ratings >= rat ? star_active : star_inactive}
                    alt="star"
                    onClick={() => {
                        // console.log({loop_r: rat, compo_r: ratings, parent_r: rating});
                        
                        if (rat === ratings) {
                            setRatings(rat -1);
                        } else {
                            setRatings(rat);
                        }
                    }}
                />
            ))}
        </div>
    );
};

export default CustomRating;