import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { getImage, getGenerateApiImageByName } from "../../../../unified/image_service";

// import active_icon from '../../../../../assets/icons/settings/biopage/active.svg';
// import inactive_icon from '../../../../../assets/icons/settings/biopage/inactive.svg';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import './TemplateSelection.scss';
const TemplateSelection = ({handleSubmitSelectedTemplate, isReadyToSelectTemplate, handleCancel}: any) => {
    const { bioPageTemplates } = useSelector((state: any) => state?.bioPageSettingState);
    
    const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
    useEffect(() => {
        // console.log({ isReadyToSelectTemplate });
        if (bioPageTemplates?.length && isReadyToSelectTemplate) {
            let current_default_template = bioPageTemplates?.find((item: any) => (item?.isCurrentTemplate));
            if (!current_default_template) {
                current_default_template = bioPageTemplates?.find((item: any) => (item?.isDefault));
            }
            setSelectedTemplate(current_default_template);
        }
    }, [bioPageTemplates?.length, isReadyToSelectTemplate])
    return (
        <React.Fragment>
            <div className="selection-container">
                <div className="selection-header">
                    <p>Select Template</p>
                </div>
                <div className='selection-body'>
                <ResponsiveMasonry
                    columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                >
                    <Masonry gutter="10px">
                        {
                            bioPageTemplates?.map((item: any, idx: number) =>  (
                                <div key={idx}
                                    className="template-container"
                                    onClick={() => setSelectedTemplate(item)}
                                    style={{cursor: 'pointer'}}
                                >
                                    <img className="template-image" src={item?.templateUrl} alt='template' />
                                    <div className='selection-place'>
                                        <p>{item?.templateName}</p>
                                        <img
                                            src={selectedTemplate?.id === item?.id ? getImage("template_active_icon") : getImage("template_inactive_icon")}
                                            alt="select-icon"
                                        />
                                    </div>
                                </div>
                            ))
                        }
                    </Masonry>
                </ResponsiveMasonry>
                </div>
                <div className="selection-footer">
                    <button
                        className="footer-button"
                        onClick={() => {
                            handleCancel();
                            setSelectedTemplate(null);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="footer-button"
                        disabled={!selectedTemplate}
                        onClick={() => {
                            if (selectedTemplate) {
                                handleSubmitSelectedTemplate(selectedTemplate);
                            }
                        }}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default TemplateSelection;