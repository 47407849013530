import React, {useState} from 'react';
import './Settings.scss';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SettingsMenu from '../../components/Layout/global-menu/SettingsMenu';
import { openMobileDrawer } from '../../profile/reducer';
import Workspace from '../../components/Layout/header/components/workspace';
import { getImage } from '../../unified/image_service';

interface SettingsProps {
    children?: React.ReactNode;
}

const Settings: React.FC<SettingsProps> = ({children}) => {
    const {userPermissions, is_show_mobile_settings_nav_bar} = useSelector((state: any) => state?.login);
    const history = useNavigate();
    const dispatch = useDispatch();
    React.useEffect(() => {
        const permission = userPermissions?.length ? userPermissions?.find((item: any) => item?.name === "workspaceSettings") : null;
        if(permission){
            permission?.value === 0 && history('/not-found');
        }
    }, [userPermissions?.length]);
    return (
        <React.Fragment>
            <div className="custom-settings">
                <div className='mobile-dashboard-nav-container'>
                    <div className='mobile-nav'>
                        <img onClick={() => dispatch(openMobileDrawer())} src={getImage("mobile-nav-icon")} alt='hum-burger' />
                        <div>
                            <Workspace isFromSearch={true} height={40} width={40} />
                        </div>
                    </div>
                </div>
                <div className="custom-settings-container">
                    <div className={`settings-menu-bar ${is_show_mobile_settings_nav_bar ? "d-block setting-animation-menu-bar": ""}`}>
                        <SettingsMenu/>
                    </div>
                    <div className="settings-body">
                        <div className={`settings-body-container ${is_show_mobile_settings_nav_bar ? "settings-mobile-body-container" : ""}`}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Settings;