import React, { useEffect, useState } from 'react';
import lock_icon from '../../../../assets/icons/lock-icon.svg';
import unlock_icon from '../../../../assets/icons/unlock-icon.svg';
import back_btn from '../../../../assets/icons/back.svg';
import reply_icon from '../../../../assets/icons/reply-icon.svg';
// import edit_icon from '../../../../assets/icons/edit-icon.svg';
// import send_icon from '../../../../assets/icons/send-icon.svg';
// import prev_icon from '../../../../assets/icons/previous-icon.svg';
// import next_icon from '../../../../assets/icons/next-icon.svg';
import emptyBox from '../../../../assets/img/Empty.svg';
// import clapup_whatsapp_icon from '../../../../assets/icons/bot-icons/clapup-bot-icon.svg';
import user_avatar from '../../../../assets/icons/user-avatar.svg';
import user_avatar_2 from '../../../../assets/icons/user-avatar-2.svg';
// import start from '../../../../assets/icons/star-icon.svg';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
// import ConversationDetails from '../AllDetails/ConversationsDetails';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSuccessMessage,
  setWhatsAppReplyingText,
  toggleReplyingOn,
  triggerClosedWhatsApp,
  setIsCountDownOn,
} from '../../../../unified/reducer';
import CustomCountdown from '../../../CustomCountdown/CustomCountdown';
import BlinkingOneDot from '../../../Loaders/BlinkingOneDot/BlinkingOneDot';
import MessagesDetails from '../AllDetails/MessagesDetails';
import CustomRating from '../../../Loaders/CustomRatting/CustomRating';
import { replyToWhatsAppMessage } from '../../../../unified/saga';
import WhatsAppInfo from './WhatsAppInfo/WhatsAppInfo';
import { RootState } from '../../../../store';
import { getGenerateApiImageByName } from '../../../../unified/image_service';

const WhatsAppMemberConversation = (props: any) => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState<any[]>([]);
  // const [isCountDownOn, setIsCountDownOn] = useState(false);
  const [rating, setRating] = useState<any>(4);
  const query_string = window.location.search;
  const { isClosedWhatsAppInboxThread, isCountDownOn, isReplyingOn, whats_app_sender_state}: any = useSelector((state: RootState) => state?.unified);
  const {
    conversation, type, statDetails,
    handleReplaceConversation, getImage,
    handlePrevPath, isReply, setIsReply, textRef, handleReply, conversationId, isGoForDetails,
    handleDetailChange } = props;
  const { workspaceSettings } = useSelector((state: any) => state?.workspaceManagementState);
  const { workspaces } = useSelector((state: any) => state?.headerState);
  const workSpaceName: any = workspaces?.find(
    (w: any) => w?.workSpaceId === localStorage.getItem("wuid")
  )?.workSpaceName ||  "";
  const { reviewerPhoteUrl, other_user, messages: whatsapp_messages } = conversation || {};
  // console.log("Conversations Data ===> ", {conversation});
  const [inputValue, setInputValue] = useState('');

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent the default behavior (form submission)
      handleSubmit();
    }
  };

  useEffect(() => { 
    if (isCountDownOn) {
      setTimeout(() => { 
        dispatch(setIsCountDownOn(false));
        dispatch(setWhatsAppReplyingText(''));
      }, 600000); // 10 mins = 600 sec
    }
  }, [isCountDownOn]);

  const handleInputChange = (value: any) => {
    // const { value } = e.target;
    setInputValue(value);
    dispatch(setWhatsAppReplyingText(value));
    dispatch(setIsCountDownOn(true));
    // setReplyText(e.target.value);
  };

  const handleToReplyMessage = async(replyData?: any) => {
    const {
      rating, ticketNo,
      templateId, type,
      inputValue: replyMessage,
      fromPhoneNumber, toPhoneNumber,
    }: any = replyData;
    // console.log({ replyData });
    // return;
    if (replyMessage?.trim()) {
      const replyObject: any = {
        // fromPhoneNumber: fromPhoneNumber || "",
        // toPhoneNumber: toPhoneNumber || "",
        fromPhoneNumber: toPhoneNumber || "",
        toPhoneNumber: fromPhoneNumber || "",
        type: "text",
        replyMessage: replyMessage || "",
        workspaceId: sessionStorage.getItem("wuid"),
        clapupUserId: sessionStorage.getItem("cuid"),
        templateId: "",
        rating: rating,
        ticketNo: ticketNo || "",
      };
      // console.log({ replyObject });
      const result = await replyToWhatsAppMessage(replyObject);
      // const result = true;
      console.log({result});
      if (result) {
        // setReplyText("");
        setIsReply(false);
        dispatch(toggleReplyingOn(false));
        const { ticketNo, userName } = conversation;
        const newMessage = {
          ticketNo,
          // userName,
          userName: workspaceSettings?.workSpaceName || workSpaceName,
          message: inputValue,
          rating,
          createdOn: new Date().toISOString(),
        };
        setMessages([newMessage, ...messages]);

        // Reset the input value or update your state as needed
        setInputValue('');
        dispatch(setWhatsAppReplyingText(''));
        dispatch(setIsCountDownOn(false));
      }
    } 
  }
  
  const closeReplying = () => {
    const closed_object = {
      userName: conversation?.userName,
      ticketNo: conversation?.ticketNo,
      status: "closed",
    };
    dispatch(setIsCountDownOn(false));
    dispatch(setWhatsAppReplyingText(''));
    dispatch(triggerClosedWhatsApp(closed_object));
  }

  const handleSubmit = () => {
    // Perform your submit logic here
    // console.log('Submitted: ', { inputValue, conversation, messages });
    if (!inputValue?.trim()) {
      return;
    }
    handleToReplyMessage({...conversation, inputValue, rating});
    // handleReply({...conversation, inputValue, rating});
  };

  useEffect(() => {
    // console.log("====== whatsapp_messages ========");
    
    console.log("====== whatsapp_messages ========");
    // console.log({whatsapp_messages: conversation?.messages});
    if(conversation?.messages?.length) {
    // if(conversation?.ticketNo && conversation?.messages?.length) {
      setMessages(conversation?.messages?.reverse())
      dispatch(setWhatsAppReplyingText(null));
      setInputValue("");
    } else {
      setMessages([]);
      dispatch(setWhatsAppReplyingText(null));
      setInputValue("");
    }
  }, [conversation?.ticketNo, query_string]);

  const [sender, setSender] = useState<any>(null);
  useEffect(() => {
    if (whats_app_sender_state) {
      setSender(whats_app_sender_state?.sender_name);
    } else {
      setSender(null);
    }
  }, [whats_app_sender_state]);

  const test_data = {
    "ticketNo": "69b1aa46-1a93-11ef-8382-6adf3b91f978",
    "userName": "Clapup",
    "message": "{\"header\":\"Hi {{Kuber}}\",\"body\":\"<p>Hello Kuber</p><p><br></p><p>Festival of Kerala is Nearing! You will be excited to shop with our fabulous products and avail 20% discount on all new arrival products. Use the code SYSINF123. </p><p><br></p><p>Happy purchaing!</p>\"}",
    "createdOn": "25-05-2024 12:36:30",
    "messageType": "campaign",
    "campaignString": {
      "header": "https://oss-ycloud-publicread.oss-ap-southeast-1.aliyuncs.com/api-docs/sample/sample.jpg",
      "body": "<p>Hello Kuber</p><p><br></p><p>Festival of Kerala is Nearing! You will be excited to shop with our fabulous products and avail 20% discount on all new arrival products. Use the code SYSINF123. </p><p><br></p><p>Happy purchaing!</p>"
    }
  };

  return (
    <React.Fragment>
      <div className="conversation-section-container-lg h-100">
        <div className="conversation-header">
          <div className="person-container">
            <div className="person-1">
              {
                reviewerPhoteUrl
                ? <img
                    className="user-avatar"
                    src={reviewerPhoteUrl || user_avatar}
                    alt="avatar"
                  />
                : <img
                      className="user-avatar"
                      src={getGenerateApiImageByName(conversation?.userName)}
                      alt="avatar"
                  />
              }
              <span className="description">
                {conversation?.userName?.substr(0, 13) ||
                  conversation?.userName?.substr(0, 13) ||
                  "User Name"}{" "}
                {/* <span className="review">
                  {"submitted a feedback for"}
                </span>{" "}
                {workspaceSettings?.workspaceName  || workSpaceName ||  "WS Name"} */}
              </span>
            </div>
            <div className="person-2" style={{width: 320}}>
              {
                workspaceSettings?.logo
                // other_user?.logo
                ? <img
                    src={workspaceSettings?.logo || user_avatar_2}
                    alt="user-2"
                  />
                : <img
                    className="user-avatar"
                    src={getGenerateApiImageByName((workspaceSettings?.workspaceName  || workSpaceName || other_user?.userName?.trim() || "Others User")?.userName?.substr(0, 2).toUpperCase())}
                    alt="avatar"
                  />
              }
              <p className="user-2">
                {workspaceSettings?.workspaceName  ||  "WS Name"}
                {/* {other_user?.userName ||  "Others User"} */}
              </p>
            </div>
          </div>
          <div className="conversation-features-media"
            style={{justifyContent: 'flex-end', width: '40%' }}
          >
            <div className="features whats-app-features">
              <div className='count-down-container'>
                <BlinkingOneDot
                  isStarted={isCountDownOn}
                />
                <CustomCountdown
                  customClass={"countdown"}
                  onChange={(val: string) => {
                    // console.log(val);
                  }}
                  isStarted={isCountDownOn}
                />
              </div>

              <div className='whats-app-close-container'
                onClick={() => closeReplying()}
              >
                Close
              </div>
              {/* <img
                onClick={() =>
                  handleReplaceConversation("prev", conversation?.SlNo)
                }
                src={prev_icon}
                alt="prev"
                title='Previous'
              />
              <img
                onClick={() =>
                  handleReplaceConversation("next", conversation?.SlNo)
                }
                src={next_icon}
                alt="next"
                title='Next'
              />
              <img
                onClick={() => dispatch(setSuccessMessage("Refreshed"))}
                src={refresh_icon}
                alt="refresh"
                title='Refresh'
              /> */}
            </div>
            <div className="media">
              <img src={getImage(type)} alt="type-icon" />
            </div>
          </div>
        </div>
        {
          messages?.length
          ?
          <div className="conversation-section-video-feedback">
              <Scrollbars
                style={{
                  paddingRight: 12,
                  // display: "flex",
                  // flexDirection: "column-reverse",
                  // overflow: 'auto'
                }}
                autoHide
                autoHideTimeout={500}
                autoHideDuration={500}
                autoHeight
                // autoHeightMin={250}
                // autoHeightMax={250}
                // autoHeightMin={480}
                autoHeightMax={"62vh"}
                thumbMinSize={10}
                universal={true}
                id="scrollableDiv"
              >
                <div className="ai-bot-conversation-body">
                  {/* This will implement it feeds.tsx file */}
                  
                  {
                    messages.map((item: any, idx: any) => (
                      item?.userName !== conversation?.userName
                      // item?.userName !== other_user?.userName
                      || item?.messageType?.toLowerCase() === "campaign"
                      ?
                      <div key={idx} className={`conversation-person-2 right-side`}>
                        <div className="details">
                          {
                            workspaceSettings?.logo
                            ? <img
                                className="user-avatar"
                                src={workspaceSettings?.logo || user_avatar}
                                alt="avatar"
                              />
                            : <img
                                className="user-avatar"
                                src={getGenerateApiImageByName((workspaceSettings?.workspaceName  || workSpaceName || other_user?.userName?.trim() || "Others User")?.userName?.substr(0, 2).toUpperCase())}
                                alt="avatar"
                              />
                          }
                          <div className="description">
                            {
                              item?.messageType?.toLowerCase() !== "campaign"
                              ? <p style={{ whiteSpace: 'pre-line', margin: 0 }}>{item?.message}</p>
                              : !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("jpg") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("jpeg") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("png") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("pdf") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("mp4")
                              ? <p style={{ whiteSpace: 'pre-line', margin: 0 }}
                                dangerouslySetInnerHTML={{ __html: (item || test_data)?.campaignString?.header + "\n" + (item || test_data)?.campaignString?.body }}
                                />
                              // : (item || test_data)?.campaignString?.header?.toLowerCase() !== "image" 
                              // ? <img src={(item || test_data)?.campaignString?.body} alt="media" />
                                : <WhatsAppInfo data={(item || test_data)} />
                            }
                            
                          </div>
                        </div>
                        <p className="text-end mt-2 date">{"Replied - " + moment(item?.createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + (workspaceSettings?.workspaceName || workSpaceName || other_user?.userName || "Other User")}</p>
                      </div>
                      :
                      <div className="conversation-person-1 left-side" key={idx}>
                        <div className="details">
                          {
                            reviewerPhoteUrl
                            ? <img
                                className="user-avatar"
                                src={reviewerPhoteUrl || user_avatar}
                                alt="avatar"
                              />
                            : <img
                                className="user-avatar"
                                src={getGenerateApiImageByName(item?.userName)}
                                alt="avatar"
                              />
                          }
                          <div className="description">
                            {
                              item?.messageType?.toLowerCase() !== "campaign"
                              ? <p style={{ whiteSpace: 'pre-line', margin: 0 }}>{item?.message}</p>
                              : !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("jpg") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("jpeg") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("png") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("pdf") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("mp4")
                              ? <p style={{ whiteSpace: 'pre-line', margin: 0 }}
                                dangerouslySetInnerHTML={{ __html: (item || test_data)?.campaignString?.header + "\n" + (item || test_data)?.campaignString?.body }}
                                />
                              // : (item || test_data)?.campaignString?.header?.toLowerCase() !== "image" 
                              // ? <img src={(item || test_data)?.campaignString?.body} alt="media" />
                                : <WhatsAppInfo data={(item || test_data)} />
                            }
                          </div>
                        </div>
                        <p className="text-start mt-2 date">
                            {"Messaged - " + moment(item?.createdOn).format("DD MMM YYYY, hh:mm:ss A")}
                        </p>
                      </div>
                    ))
                  }
                </div>
              </Scrollbars>
          </div>
          :
          <div className="empty-feeds-section">
            <div
              className="empty-wall-section position-absolute"
              style={{ top: "45%" }}
            >
              <div>
                <img src={emptyBox} alt="" />
                <h4>Empty Feeds</h4>
              </div>
            </div>

          </div>
        }

        <div className="conversation-footer" style={{bottom: 44}}>
          <div className='d-flex justify-content-between my-2' style={{padding: '0 2.9% 0 2.5%'}}>
            <p className="text-start sender">
              {
                sender
                ? `${sender} is typing...`
                : `Sending as ${workspaceSettings?.workspaceName || workSpaceName || "WS Name"}`
                // `Sending as ${other_user?.userName || "Other User"}`
              }
            </p>
            <CustomRating rating={rating || 4} 
              onRating={(rating: number) => {
                // console.log(rating);
                setRating(rating);
              }}
            />
          </div>
          <div className="message-box">
            <div className="message">
              <div className="text-container">
                <textarea
                  ref={textRef}
                  className=""
                  placeholder="Write here..."
                  value={inputValue || ""}
                  onChange={(e: any) => handleInputChange(e.target.value)}
                  onKeyDown={handleKeyPress}
                  style={{ resize: "none" }}
                  autoFocus={isReply}
                />
              </div>
              <div className="reply-btn">
                <button
                  style={{
                    background: `${isReply ? "#E4E7EC" : "#E4E7EC"}`,
                  }}
                  onClick={() => {
                    // if (isReply) {
                    if (isReplyingOn) {
                      handleSubmit();
                    } else {
                      // console.log("Click Reply");
                      setIsReply(true);
                      dispatch(toggleReplyingOn(true));
                      // dispatch(toggleReplyingOn(true));
                      // dispatch(toggleIsInReplyingMode(true));
                    }
                  }}
                  type="button"
                  disabled={sender ? true : false}
                >
                  {/* {replyText && isReply */}
                  {isReplyingOn
                    ? "Send"
                    // : isReplyingOn
                    // ? "Save"
                    : inputValue
                    ? "Edit"
                    : "Reply"}
                </button>
                <img
                  onClick={() => {
                    setIsReply(false);
                    dispatch(toggleReplyingOn(false));
                    // dispatch(toggleIsInReplyingMode(false));
                  }}
                  src={(isReplyingOn || sender) ? lock_icon : unlock_icon}
                  // src={isReply ? lock_icon : unlock_icon}
                  // src={isReplyingOn ? lock_icon : unlock_icon}
                  alt="lock-unlock"
                />
              </div>
              {/* <p className={`click-reply-des ${(isReply && !sender) ? "d-none" : ""}`}> */}
              {/* <p className={`click-reply-des ${(isReply) ? "d-none" : ""}`}> */}
              {/* <p className={`click-reply-des ${(isReplyingOn ) ? "d-none" : ""}`}> */}
              <p className={`click-reply-des ${(isReplyingOn && !sender) ? "d-none" : ""}`}>
                {
                  // sender
                  // ? "You can not reply now. Editor is locked"
                  // : "Click reply and start adding your reply."
                  "Click reply and start adding your reply."
                }
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="conversation-container-mobile">
        <div className="conversation-header">
          <div className="header-top">
            {/* Back Button */}
            <img
              onClick={handlePrevPath}
              className="back"
              src={back_btn}
              alt="back"
            />
            <img
              className="provider"
              src={getImage(type)}
              alt="type-messages"
            />
          </div>
          <div className="header-body">
            <div className="bot-body-left">
              <div className="first-ws">
                {
                  reviewerPhoteUrl
                  ? <img
                      className="user-avatar"
                      src={reviewerPhoteUrl || user_avatar}
                      alt="avatar"
                    />
                  : <span className='border text-light image-logo rounded-circle d-flex align-items-center justify-content-center p-1' style={{fontSize: "0.75rem", background: '#14B0BF'}}>
                      {
                        (conversation?.userName || "User Name")?.split(" ")?.slice(0, 2)?.length === 2
                        ? (conversation?.userName || "User Name")?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                        : (conversation?.userName || "User Name")?.substr(0, 2).toUpperCase()
                      }
                    </span>
                }
                <div className="user-info">
                  <p className="user-name">
                    {(conversation?.userName)?.substr(0, 6) || "User Name"}
                  </p>
                </div>
              </div>
              <div className="second-ws">
                {
                  workspaceSettings?.logo || other_user?.logo
                  ? <img
                      className="image-logo"
                      src={ workspaceSettings?.logo || other_user?.logo || user_avatar_2}
                      alt="user-2"
                    />
                  : <span className='border rounded-circle text-light image-logo d-flex align-items-center justify-content-center'>
                      {
                        (workspaceSettings?.workspaceName  || workSpaceName || other_user?.userName)?.split(" ")?.slice(0, 2)?.length === 2
                        ? (workspaceSettings?.workspaceName  || workSpaceName || other_user?.userName)?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                        : (workspaceSettings?.workspaceName  || workSpaceName || other_user?.userName)?.substr(0, 2).toUpperCase()
                      }
                    </span>
                }
                <p className="ws-name">
                  {workspaceSettings?.workspaceName || workSpaceName || other_user?.userName || "Other User"}
                </p>
              </div>
            </div>
            <div className="conversation-features-media"
              style={{
                justifyContent: 'center',
                // width: '40%'
              }}
            >
              <div className="features whats-app-features">
                <div className='count-down-container'>
                  <BlinkingOneDot
                    isStarted={isCountDownOn}
                  />
                  <CustomCountdown
                    customClass={"countdown"}
                    onChange={(val: string) => {
                      // console.log(val);
                    }}
                    isStarted={isCountDownOn}
                  />
                </div>

                <div
                  onClick={() => closeReplying()}
                  className='whats-app-close-container'
                >
                  Close
                </div>
                {/* <img
                  onClick={() =>
                    handleReplaceConversation("prev", conversation?.SlNo)
                  }
                  src={prev_icon}
                  alt="prev"
                  title='Previous'
                />
                <img
                  onClick={() =>
                    handleReplaceConversation("next", conversation?.SlNo)
                  }
                  src={next_icon}
                  alt="next"
                  title='Next'
                />
                <img
                  onClick={() => dispatch(setSuccessMessage("Refreshed"))}
                  src={refresh_icon}
                  alt="refresh"
                  title='Refresh'
                /> */}
              </div>
              <div className="media">
                <img src={getImage(type)} alt="type-icon" />
              </div>
            </div>
            {/* <div className="body-right">
              <img
                onClick={() => dispatch(setSuccessMessage("Refreshed"))}
                src={getImage(type)}
                alt="type-icon"
                title='Bot'
              />
            </div> */}
          </div>
        
        {/* Messages and Details */}
        <div className="header-bottom">
          <span
            onClick={() => isGoForDetails && handleDetailChange()}
            style={{ color: !isGoForDetails ? "#1570EF" : "" }}
          >
            Messages
          </span>
          <span
            onClick={() => !isGoForDetails && handleDetailChange()}
            style={{ color: isGoForDetails ? "#1570EF" : "" }}
          >
            Details
          </span>
        </div>
        </div>
        {isGoForDetails ? (
          <div className="conversation-details-mobile-container">
            <MessagesDetails type={type} statDetails={statDetails} />
          </div>
        ) : (
          <>
            <div className="conversation-body">
              <Scrollbars
                style={{
                  paddingRight: 10,
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                // style={{ paddingRight: 10 }}
                autoHide
                autoHideTimeout={500}
                autoHideDuration={500}
                autoHeight
                // autoHeightMin={250}
                // autoHeightMax={250}
                // autoHeightMin={480}
                autoHeightMax={"100%"}
                thumbMinSize={10}
                universal={true}
                id="scrollableDiv"
              >
                <div className="conversation-body" style={{height: '62%', paddingRight: '1%'}}>
                  {/* This will implement it feeds.tsx file */}
                  {
                    messages?.map((item: any, idx: any) => (
                      item?.userName !== conversation?.userName
                      // item?.userName !== other_user?.userName
                      || item?.messageType?.toLowerCase() === "campaign"
                      ?
                      <div key={idx} className={`conversation-person-2 right-side`}>
                        <div className="details">
                          {
                            workspaceSettings?.logo
                            ? <img
                                className="user-avatar"
                                src={workspaceSettings?.logo || user_avatar}
                                alt="avatar"
                              />
                            : <img
                                className="user-avatar"
                                src={getGenerateApiImageByName((workspaceSettings?.workspaceName  || workSpaceName || other_user?.userName?.trim() || "Others User")?.userName?.substr(0, 2).toUpperCase())}
                                alt="avatar"
                              />
                          }
                          <div className="description">
                            {
                              item?.messageType?.toLowerCase() !== "campaign"
                              ? <p style={{ whiteSpace: 'pre-line', margin: 0 }}>{item?.message}</p>
                              : !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("jpg") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("jpeg") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("png") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("pdf") &&
                              !(item || test_data)?.campaignString?.header?.toLowerCase()?.includes("mp4")
                              ? <p style={{ whiteSpace: 'pre-line', margin: 0 }}
                                dangerouslySetInnerHTML={{ __html: (item || test_data)?.campaignString?.header + "\n" + (item || test_data)?.campaignString?.body }}
                                />
                              // : (item || test_data)?.campaignString?.header?.toLowerCase() !== "image" 
                              // ? <img src={(item || test_data)?.campaignString?.body} alt="media" />
                                : <WhatsAppInfo data={(item || test_data)} />
                            }
                            
                          </div>
                        </div>
                        <p className="text-end mt-2 date">{"Replied - " + moment(item?.createdOn).format("DD MMM YYYY, hh:mm:ss A") + " by " + (workspaceSettings?.workspaceName || workSpaceName || other_user?.userName || "Other User")}</p>
                      </div>
                      :
                      <div className="conversation-person-1 left-side" key={idx}>
                        <div className="details">
                          {
                            reviewerPhoteUrl
                            ? <img
                                className="user-avatar"
                                src={reviewerPhoteUrl || user_avatar}
                                alt="avatar"
                              />
                            : <img
                                className="user-avatar"
                                src={getGenerateApiImageByName(conversation?.userName)}
                                alt="avatar"
                              />
                          }
                          <div className="description">
                            {/* <div
                              className={`d-flex align-items-center user-ratings-container`}
                            >
                              <div className="user_name me-2 ">
                                {conversation?.userName || "User Name"}
                              </div>
                            </div> */}
                            {/* <div className="client-container mb-2">
                              {
                                moment(createdOn).format("DD MMM YYYY, hh:mm:ss A") + (recieverCompany ? (" - " + (recieverCompany || "Company Name")) : "")
                              }
                            </div> */}
                            {/* <p style={{ whiteSpace: 'pre-line' }}>{reviewComment}</p> */}
                            {/* <p style={{ whiteSpace: 'pre-line' }}>As the brand, so is the service. They are extremely well verse with in quality and keeping up with their users. Prices are definitely high, but quality comes with a price.</p> */}
                            <p>{item?.message}</p>
                          </div>
                        </div>
                        <p className="text-start mt-2 date">
                            {"Messaged - " + moment(item?.createdOn).format("DD MMM YYYY, hh:mm:ss A")}
                        </p>
                      </div>
                      
                    ))
                  }
                  {/* <div className="conversation-person-1">
                    <div className="details">
                      {
                        reviewerPhoteUrl
                        ? <img
                            className="user-avatar"
                            src={reviewerPhoteUrl || user_avatar}
                            alt="avatar"
                          />
                        : <span className='user-name-avatar' style={{fontSize: "0.75rem", background: '#14B0BF'}}>
                            {
                              (conversation?.userName || "User Name")?.split(" ")?.slice(0, 2)?.length === 2
                              ? (conversation?.userName || "User Name")?.split(" ")?.slice(0, 2)?.map((word: any) => word.substring(0, 1)).join("").toUpperCase()
                              : (conversation?.userName || "User Name")?.substr(0, 2).toUpperCase()
                            }
                          </span>
                      }
                      <div className="description">
                        <div className={`d-flex align-items-center user-ratings-container mb-0`}>
                          <div className="user_name">
                            {conversation?.userName || "User Name"}
                          </div>

                        </div>
                        <p style={{whiteSpace: 'pre-line'}}>As the brand, so is the service. They are extremely well verse with in quality and keeping up with their users. Prices are definitely high, but quality comes with a price.</p>
                      </div>
                    </div>
                    <p className="text-start mt-2 date">
                      Messaged - <span>{ moment(new Date()).format("DD MMM YYYY, hh:mm:ss A")}</span>
                    </p>
                  </div>
                  {
                    workspaceSettings
                    ? <div className="conversation-person-2">
                        <div className="details">
                          <img
                            className="user-avatar"
                            src={workspaceSettings?.logo}
                            alt="user_avatar"
                          />
                          <div className="description">
                            <p style={{ whiteSpace: 'pre-line', margin: 0 }}>{"Hi Kuber Mannur, \nThank you for your message. Creating account in our platform is very easy. You can do this from user management. Pls don't forget to ping message. Creating account in our platform is very easy. You can do this from user management. Pls don't forget to ping us at support@tezla.com for any assistance."}</p>
                          </div>
                        </div>
                        <p className="text-end mt-2 date">{"Messaged - " + moment(new Date()).format("DD MMM YYYY, hh:mm:ss A") + " by " + (workspaceSettings?.workspaceSettings?.workspaceName  || workSpaceName ||  "WS Name")}</p>
                      </div>
                    : null
                  } */}
                  
                  {/* <div className='py-5'></div> */}
                </div>
              </Scrollbars>
            </div>
            <div className="conversation-footer">
              <div
                style={{ padding: "0 10px" }}
                className="d-flex align-items-center justify-content-between my-2"
              >
                <p className="text-start sender">
                  {
                    sender
                    ? `${sender} is typing...`
                    : `Sending as ${workspaceSettings?.workspaceName || workSpaceName || "WS Name"}`
                  }
                </p>
                <div style={{display: 'flex', gap: '0.8rem'}}>
                  <CustomRating rating={rating || 4} 
                    onRating={(rating: number) => {
                      // console.log(rating);
                      setRating(rating);
                    }}
                  />
                  <img
                    onClick={() => {
                      setIsReply(false);
                      dispatch(toggleReplyingOn(false));
                      // dispatch(toggleIsInReplyingMode(false));
                    }}
                    // src={(sender || isReplyingOn || isInReplyingMode) ? lock_icon : unlock_icon}
                    src={isReply ? lock_icon : unlock_icon}
                    alt="lock-unlock"
                  />
                </div>
              </div>
              <div className="message-box">
                <div className="message">
                  <div className="text-container">
                    <textarea
                      ref={textRef}
                      className=""
                      placeholder="Write here..."
                      value={inputValue || ""}
                      onChange={(e: any) => handleInputChange(e.target.value)}
                      onKeyDown={handleKeyPress}
                      style={{ resize: "none" }}
                      autoFocus={isReply}
                    />
                  </div>
                  <div className="send-reply-action">
                    <div
                      onClick={() => {
                        if (!sender) {
                          if (isReply) {
                            handleSubmit();
                          } else {
                            console.log("Click Reply");
                            setIsReply(true);
                            dispatch(toggleReplyingOn(true));
                            // dispatch(toggleIsInReplyingMode(true));
                          }
                        }
                      }}
                      // type="button"
                      // disabled={sender ? true : false}
                    >
                      {/* {replyText || isReply ? (
                        <img src={send_icon} alt="save-account" />
                      ) : replyText ? (
                        <img src={edit_icon} alt="edit" />
                      ) : (
                        <img src={reply_icon} alt="reply" />
                      )} */}
                      <img src={reply_icon} alt="reply" />
                    </div>
                  </div>
                  {/* <p className={`click-reply-des ${(isReply && !sender) ? "d-none" : ""}`}> */}
                  {/* <p className={`click-reply-des ${(isReply) ? "d-none" : ""}`}> */}
                  <p className={`click-reply-des ${(isReplyingOn && !sender) ? "d-none" : ""}`}>
                      {
                        // sender
                        // ? "You can not reply now. Editor is locked"
                        // : "Click reply and start adding your reply."
                        "Click reply and start adding your reply."
                      }
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="bot-conversation-footer">
              <p className="text-start my-2 px-3 sender">
                Sending as {workspaceSettings?.workspaceSettings?.workspaceName  || workSpaceName ||  "WS Name"}
              </p>
              <div className="message-box mb-3">
                <div className="message">
                  <div className="text-container click-reply-des">
                    <textarea
                      ref={textRef}
                      className="bg-transparent"
                      placeholder="This conversation is managed by Bot"
                      value={inputValue || ""}           
                      onChange={(e: any) => handleInputChange(e.target.value)}
                      onKeyDown={handleKeyPress}
                      // onChange={(e: any) => {
                      //   const { value } = e?.target;
                      //   setReplyText(value);
                      // }}
                      style={{ resize: "none", width: '100%' }}
                      autoFocus={isReply} 
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default WhatsAppMemberConversation;
