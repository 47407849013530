import Constants from "../../../constants";

const initialState = {
    converted_clapup_user: null,
    users: [],
    isInvitationSent: false,
    loading: false,
    isReadyToGetUsers: false,
    error: null

}

// After the invitation, convert to clap up user....
export const toggleIsReadyToGetUsers = (data: any = null) => {
    return {
        type: "TOGGLE_IS_READY_TO_GET_USER",
        payload: data,
    };
}

export const convertToClapUpUser = (data: any = null) => {
    return {
        type: Constants.CONVERT_TO_CLAPUP_USER,
        payload: data,
    };
}

export const setConvertedClapUpUser = (data: any = null) => {
    return {
        type: Constants.SET_CONVERTED_CLAPUP_USER,
        payload: data
    };
}

// get users
export const getUsers = (data?: any) => {
    const dataObject: any = {
        clapupUserId: sessionStorage.getItem('cuid') || sessionStorage.getItem('cuid') || null,
        roleId: sessionStorage.getItem('roleId') || sessionStorage.getItem('roleId') || null,
        workspaceId: sessionStorage.getItem('wuid') || sessionStorage.getItem('wuid') || null,
    };
    return {
        type: Constants.GET_USER_REQUEST,
        payload: dataObject
    };
}

export const setUserList = (data: any = null) => {
    return {
        type: Constants.FIND_USER_LIST,
        payload: data
    };
}

// user role update
export const updateUserRole = (data: any = null) => {
    return {
        type: Constants.UPDATE_USER_ROLE,
        payload: data
    };
}

// invite user
export const sendInvitationToUser = (data: any = null) => {
    return {
        type: Constants.INVITE_USER,
        payload: data
    };
}

export const setIsInvitationSent = (data: any = null) => {
    return {
        type: Constants.AFTER_INVITE_USER,
        payload: data || false,
    };
}

// update user status
export const updateUserStatus = (data: any = null) => {
    return {
        type: Constants.UPDATE_STATUS,
        payload: data
    };
}

export const setUpdateStatus = (data: any = null) => {
    return {
        type: Constants.SET_UPDATE_STATUS,
        payload: data
    };
}

export const deleteUser = (data: any = null) => {
    return {
        type: Constants.DELETE_USER,
        payload: data
    };
}

const userManagementReducer = (state: any = initialState, action: any) => {
    // console.log({user_reducer_action_data: action});

    switch (action.type) {
        case "TOGGLE_IS_READY_TO_GET_USER":
            return { ...state, isReadyToGetUsers: action.payload };
        case Constants.SET_CONVERTED_CLAPUP_USER:
            return {
                ...state,
                converted_clapup_user: action.payload
            };
        case Constants.FIND_USER_LIST:
            // console.log("user manager reducer ===> ", action.payload);

            return {
                ...state,
                error: null,
                users: action.payload,
            };
        case Constants.SET_UPDATE_STATUS:
            return {
                ...state,
                error: null,
                users: state.users.map((user: any, idx: number) => {
                    if (user.userId === action.payload.userId) {
                        return {
                            ...user,
                            status: action.payload.status
                        }
                    }
                    return user;
                }),
            };

        case Constants.DELETE_USER:
            return {
                ...state,
                error: null,
                users: state.users.filter((user: any) => user.userId !== action.payload),
            };
        case Constants.UPDATE_USER_ROLE:
            return {
                ...state,
                error: null,
                users: state.users.map((user: any, idx: number) => {
                    if (user.userId === action.payload.userId) {
                        return {
                            ...user,
                            role: action.payload.roleId
                        }
                    }
                    return user;
                }),
            };

        case Constants.AFTER_INVITE_USER:
            return {
                ...state,
                error: null,
                isInvitationSent: action.payload
            };

        case Constants.LOGOUT:
            return {
                ...state,
                users: [],
                isInvitationSent: false,
                loading: false,
                error: null,
                isReadyToGetUsers: false,
                converted_clapup_user: null,
            };

        default:
            return state;
    }
}

export default userManagementReducer;